import styled from "styled-components";
import COLOR from "~/views/utilities/layout/color";
import { Background } from "~/static/images";

export const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  height: 800px;
  position: relative;
`;

export const LogoWrapper = styled.div`
  text-align: center;
  padding: 20px 0;
  a svg {
    color: ${COLOR.red};
    width: 153px;
    height: 45px;
  }
`;

export const Content = styled.div`
  width: 450px;
  padding: 20px;
`;

export const Footer = styled.footer`
  text-align: center;
  color: #828384;
  padding: 20px;
`;

export const ContentWrapper = styled.div`
  @media (max-width: 1000px) {
    background-image: none;
  }
  display: flex;
  flex: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url(${Background});

  .title {
    @media (min-width: 1000px) {
      display: none;
    }
    position: absolute;
    width: 282px;
    height: 41px;
    left: 28px;
    top: 106px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;

    color: #043a34;
  }
  .des {
    @media (min-width: 1000px) {
      display: none;
    }
    position: absolute;
    width: 324px;
    height: 22px;
    left: 28px;
    top: 155px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-transform: uppercase;

    color: #202020;
  }
`;
export const LeftContent = styled.div`
  @media (min-width: 1000px) {
    background: #ffffff;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
  }
  padding: 0px !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  position: relative;

  h3.login {
    @media (max-width: 1000px) {
      text-align: left;
    }
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #043a34;
  }

  .forgot-text-moblie {
    @media (min-width: 1000px) {
      display: none;
    }
    display: flex;
    justify-content: right;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height */

    color: #202020;
  }

  .forgot-text-desktop {
    @media (max-width: 1000px) {
      display: none;
    }
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height */

    color: #202020;
  }
  .button-submit {
    @media (max-width: 1000px) {
      margin-top: 60px;
    }
    margin-top: 40px;
  }

  .register-text {
    @media (max-width: 1000px) {
      margin-top: 60px;
    }
    margin-top: 20px;
  }
`;

export const ContainerContent = styled.div`
  @media (max-width: 1000px) {
    padding: 0;
  }
  height: 600px;
  width: 1200px;
  padding-left: 75px;
  padding-top: 50px;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 80px;
    /* identical to box height */

    color: #043a34;
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 34px;
    /* identical to box height */

    color: #202020;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: justify;

    color: #202020;
  }
`;

export const TextContent = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
  padding: 0px 100px 0px 100px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const LogoStyle = styled.div`
  @media (min-width: 1000px) {
    display: none;
  }
  margin: 25px 0 0 28px;
  img {
    width: 121px;
  }
`;

export const FooterStyle = styled.div`
  .coppyright {
    @media (max-width: 1000px) {
      color: #9b9b9b;
      text-align: center;
    }
  }
  .rules {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  @media (min-width: 1000px) {
    bottom: 3.37%;
    justify-content: space-between;
  }

  .breakword {
    @media (max-width: 1000px) {
      display: block;
      height: 0;
      visibility: hidden;
    }
  }

  .decoration {
    @media (max-width: 1000px) {
      text-decoration: underline;
    }
  }
  justify-content: center;
  display: flex;
  bottom: 3.98%;
  position: absolute;
  width: 100%;
  padding: 20px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: white;
`;
