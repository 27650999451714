import React from "react";

import _ from "lodash";
import moment from "moment";
import {connect} from "react-redux";
import {compose, lifecycle, withHandlers, withState} from "recompose";
import {showError} from "~/configs/ServerErrors";
import strings from "~/localization";
import {getAllCultivationProcesses} from "~/state/ducks/appApis/actions";
import {TableEditable} from "~/views/presentation/ui/tables";
import {getArray, getNumber, getString} from "~/views/utilities/helpers/utilObject";
import {message} from "antd";

class ProcedureForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {dataSource: this.props.seasonProcesses || []};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.seasonProcesses != this.state.dataSource) {
      this.setState({dataSource: nextProps.seasonProcesses || []});
    }
  }
  handleChangedData = (index, dataIndex, dataSource) => {
    this.props.onChange && this.props.onChange(dataSource);
    if (dataIndex == "processId") {
      const {cultivationProcesses} = this.props;
      let processId = getNumber(dataSource[index], dataIndex, undefined);
      let procedure = _.find(cultivationProcesses, (item) => item.id === processId);
      let steps = getArray(procedure, "steps", []);
      if (processId !== "" && processId !== undefined && processId !== null && procedure) {
        let row = dataSource[index];
        row.processName = getNumber(procedure, "name");
        row.procedureSteps = steps.map((item) => ({
          value: item.id,
          label: item.name || "",
        }));
        row.procedureStepId = getString(_.head(steps), "name", "");
        row.ratings = getString(procedure, "ratings", strings.rating_default);
        row.statusView = `${strings.not_run} (0/${steps.length})`;
        row.status = "WAITING";
        this.setState({dataSource});
      }
    }
  };

  render() {
    const {getAllCultivationProcesses, editable, cultivationProcesses} = this.props;
    let columns = [
      {
        title: strings.name_procedure,
        dataIndex: "processId",
        width: "20%",
        editable: editable,
        required: true,
        placeholder: strings.name_procedure,
        type: "selectSuggestion",
        isSearch: false,
        options: cultivationProcesses || [],
        handleFetch: getAllCultivationProcesses,
        nameData: "value",
        filterOption: true,
        optionFilterProp: "label",
        renderOpts: {
          value: "value",
          labelSelect: "label",
          disabled: "disabled",
        },
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },

      {
        title: strings.start_date,
        dataIndex: "startDate",
        editable: editable,
        width: "15%",
        required: true,
        disabled: false,
        placeholder: strings.start_date,
        min: 0,
        type: "date",
        disabledDate: (current) => {
          return current && current <= moment().endOf("day").subtract(1, "d");
        },
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.status,
        dataIndex: "statusView",
        disabled: false,
        editable: false,
        required: false,
        width: "15%",
        placeholder: strings.status,
        type: "text",
        min: 0,
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.procedure_step,
        dataIndex: "procedureStepId",
        editable: false,
        width: "20%",
        required: false,
        placeholder: strings.procedure_step,
        type: "text",
        optionIndex: "procedureSteps",
      },
      {
        dataIndex: "ratings",
        title: strings.rate,
        editable: false,
        disabled: true,
        width: "15%",
        required: false,
        // options: this.props.rate || [],
        placeholder: strings.rate,
        type: "text",
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        dataIndex: "note",
        title: strings.note,
        editable: editable,
        disabled: false,
        width: "15%",
        required: false,
        placeholder: strings.note,
        type: "text",
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      // {
      //   title: strings.action,
      //   dataIndex: "action",
      //   width: "15%",
      //   editable: true,
      //   required: false,
      //   placeholder: strings.action,
      //   type: "link",
      //   action: () => {
      //     message.info(strings.functions_are_under_development);
      //   },
      // },
    ];

    return (
      <div>
        <TableEditable
          maxRowCount={getArray(cultivationProcesses, undefined, []).length}
          editable={editable}
          changedData={this.handleChangedData}
          dataSource={this.state.dataSource || []}
          columns={columns}
        />
      </div>
    );
  }
}

export default compose(
  connect(null, {
    getAllCultivationProcesses,
  }),
  withState("dataSource", "setDataSource", []),
  withState("cultivationProcesses", "setCultivationProcesses", []),
  withHandlers({
    fetchAllCultivationProcesses: (props) => () => {
      const {setCultivationProcesses, getAllCultivationProcesses, setProcedureSteps} = props;
      getAllCultivationProcesses()
        .then(({res}) => {
          setCultivationProcesses(
            getArray(res, undefined, []).map((item) => {
              return {
                ...item,
                value: item.id,
                label: item.name || "",
              };
            })
          );
        })
        .catch((err) => {
          console.error("fetchAllCultivationProcesses -> err", err);
          showError(err);
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      const {fetchAllCultivationProcesses} = this.props;
      fetchAllCultivationProcesses();
    },
  })
)(ProcedureForm);
