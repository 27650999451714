import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import strings from "~/localization";
import TableAll from "./AllForm";
import {
  getFarmProfile,
  deleteFarmProfile,
} from "~/state/ducks/appApis/actions";

import { getArray } from "~/views/utilities/helpers/utilObject";
import TreeMenu from "~/views/presentation/ui/navigation/Sidebar";
import { withRouterInnerRef } from "~/views/container/commons";
import { ContainerNavStyled } from "~/views/container/Navigation/styles";

class AllFormPost extends React.Component {
  render() {
    return (
      <ContainerNavStyled>
        <div>
          <TreeMenu
            className={this.props.className}
            menus={this.props.treeMenu}
          />
        </div>
        <div className="d-flex justify-content-end w-100">
          <div className="col-12 col-lg-9 col-md-8">
            <TableAll />
          </div>
        </div>
      </ContainerNavStyled>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      getFarmProfile,
      deleteFarmProfile,
    },
    null,
    {
      forwardRef: true,
    }
  )(AllFormPost)
);
