import React from "react";
import _ from "lodash";
import {TableView} from "~/views/presentation/ui/tables";
import {compose, lifecycle, withHandlers} from "recompose";
import {connect} from "react-redux";
import {getAllFarmSeasonByYear} from "~/state/ducks/appApis/actions";
import {withRouter} from "react-router-dom";

class FarmSeasonHarvestTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {dataSource: this.props.harvestSeason || []};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.harvestSeason != this.state.dataSource) {
      this.setState({dataSource: nextProps.harvestSeason || []});
    }
  }
  render() {
    const {columns} = this.props;
    return <TableView style={{width: "100%"}} dataSource={this.state.dataSource || []} columns={columns} scroll={{x: 1800}} />;
  }
}

export default compose(
  withRouter,
  connect(null, {
    getAllFarmSeasonByYear,
  }),
  withHandlers({}),
  lifecycle({
    componentDidMount() {
      const {} = this.props;
    },
  })
)(FarmSeasonHarvestTables);
