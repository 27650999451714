import React from "react";
import { compose, withState, withHandlers, pure, lifecycle } from "recompose";
import { GridStyled } from "~/views/container/styles";
import CardItem from "./CardItem";
const ProfileItem = (props) => {
  const { profiles, isFarmManage = false } = props;
  return (
    <GridStyled className="row p-0">
      {(profiles || []).map((item) => {
        return (
          // ! WARNING: none-menu-item for without menu
          // ! WARNING: item for with menu
          <CardItem item={item} {...props} isFarmManage={isFarmManage} />
        );
      })}
    </GridStyled>
  );
};

const initState = {
  data: [],
  value: undefined,
  fetching: false,
};

export default compose(
  pure,
  withState("state", "setState", (props) =>
    !!props.defaultValue ? props.defaultValue : initState
  ),
  withHandlers({
    handleChange: () => () => {},
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps() {},
    componentDidUpdate() {},
    componentDidMount() {},
  })
)(ProfileItem);
