import React from "react";

import { Breadcrumb, Button, Dropdown, Menu, message, Popover } from "antd";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { SegmentedControl } from "segmented-control-react";
import * as PATH from "~/configs/routesConfig";
import strings from "~/localization";
import {
  SearchStyled,
  ActionStyled,
  SegmentedControlContainer,
  ContainerStyled,
  GroupButtonStyled,
  FilterGroup,
} from "./styles";

import ConfirmModal from "./ConfirmModal";
import {
  CloseOutlined,
  EditOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  UploadOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import COLOR from "~/views/utilities/layout/color";

class FarmNav extends React.PureComponent {
  constructor(props) {
    super(props);
    if (global.viewAsBlocks === undefined) {
      global.viewAsBlocks = true;
    }
    this.state = {
      viewAsBlocks: global.viewAsBlocks,
      isChooseProfileType: false,
      showAction: false,
    };
  }

  componentDidMount() {
    this.handleUpdateViewStyle(this.state.viewAsBlocks);
  }

  handleShowAction = (e) => {
    this.setState({ showAction: e });
  };

  handleChangeView = () => {
    let viewAsBlocks = this.state.viewAsBlocks;
    this.setState({ viewAsBlocks: !viewAsBlocks });
    global.viewAsBlocks = !viewAsBlocks;
    this.handleUpdateViewStyle(!viewAsBlocks);
  };

  handleUpdateViewStyle = (viewAsBlocks) => {
    const { changeViewStyle } = this.props;
    changeViewStyle && changeViewStyle(viewAsBlocks);
  };

  mReplaceId(action) {
    let resultAction = action;
    const { match } = this.props;
    const id = match.params.id || undefined;
    const idSeason = match.params.idSeason || undefined;
    const idProcedure = match.params.idProcedure || undefined;
    const idForm = match.params.idForm || undefined;
    const idPackagingCost = match.params.idPackagingCost || undefined;
    const idProductCost = match.params.idProductCost || undefined;
    const idsalePrice = match.params.idsalePrice || undefined;
    if (id) resultAction = resultAction.replace(":id", id ? id : "");
    if (idSeason)
      resultAction = resultAction.replace(
        ":idSeason",
        idSeason ? idSeason : ""
      );
    if (idProcedure)
      resultAction = resultAction.replace(
        ":idProcedure",
        idProcedure ? idProcedure : ""
      );
    if (idForm)
      resultAction = resultAction.replace(":idForm", idForm ? idForm : "");
    if (idPackagingCost)
      resultAction = resultAction.replace(
        ":idPackagingCost",
        idPackagingCost ? idPackagingCost : ""
      );
    if (idsalePrice)
      resultAction = resultAction.replace(
        ":idsalePrice",
        idsalePrice ? idsalePrice : ""
      );
    if (idProductCost)
      resultAction = resultAction.replace(
        ":idProductCost",
        idProductCost ? idProductCost : ""
      );
    return resultAction;
  }
  /**
   * Des: render button name and action of it
   * Input:
   * @param {*} namePrimary name button
   * @param {*} actionPrimary => history.push(action) always replace ":id" to {match.params.id} if exist
   * @param {*} name nameSecond button
   * @param {*} action => history.push(actionSecondary)
   * @param {*} iconButton => icon ???
   * Output: 2 ReactNode Button
   * Author: ithoangtan (tan.hoang@vslsoft.com)
   * Date: 9:10 Sep 18, 2020
   */
  mRenderButton(
    name = "strings.create",
    action = "/",
    nameSecond = strings.CANCELED,
    actionSecondary = "/",
    buttonIcon = "",
    disablePrimary = false,
    disableSecondary = false
  ) {
    const { history, onSave, onCreate } = this.props;

    const { innerWidth } = window;

    let actionReplaceId = this.mReplaceId(action);
    let actionSecondReplaceId = this.mReplaceId(actionSecondary);

    let iconPrimary = <EditOutlined />;
    if (name === strings.save) iconPrimary = <SaveOutlined />;
    if (name === strings.create || buttonIcon === "create")
      iconPrimary = <PlusCircleOutlined />;
    if (action === "") iconPrimary = "";
    let iconSecondary = <PlusCircleOutlined />;
    if (nameSecond === strings.cancel) iconSecondary = <CloseOutlined />;
    if (nameSecond === strings.import) iconSecondary = <UploadOutlined />;

    return (
      <>
        <Button
          className="ml-3"
          type="primary"
          block
          icon={iconPrimary}
          disabled={disablePrimary}
          onClick={
            action === "onSave"
              ? onSave
              : action == "onCreate"
              ? onCreate
              : () => history.push(actionReplaceId)
          }>
          {innerWidth >= 600 && name}
        </Button>
        {/* <Button
          icon={iconSecondary}
          disabled={disableSecondary}
          className="ml-2"
          block
          onClick={() => {
            if (actionSecondReplaceId === "/" || nameSecond === strings.cancel)
              return history.goBack();
            else return history.push(actionSecondReplaceId);
          }}>
          {innerWidth >= 600 && nameSecond}
        </Button> */}
      </>
    );
  }

  renderMenuAction(stringDelete, onDelete) {
    const menu = (
      <Menu style={{ width: "100px" }}>
        <Menu.Item>
          <span>
            <i className="fa fa-floppy-o mr-2" />
            {strings.storage}
          </span>
        </Menu.Item>
        <Menu.Item>
          <ConfirmModal
            title={strings.confirmation}
            message={stringDelete}
            okText={strings.yes}
            cancelText={strings.no}
            onOk={() => {
              onDelete && onDelete();
            }}>
            <span>
              <i className="fa fa-trash-o mr-2" />
              {strings.delete}
            </span>
          </ConfirmModal>
        </Menu.Item>
        <Menu.Item>
          <span>
            <i className="fa fa-files-o mr-2" />
            {strings.copy}
          </span>
        </Menu.Item>
      </Menu>
    );
    return (
      <ActionStyled className="col-12">
        <Dropdown overlay={menu} placement="bottomRight" className="m-0">
          <span>
            {strings.action} <i className="fa fa-caret-down" />
          </span>
        </Dropdown>
      </ActionStyled>
    );
  }

  renderFilterGroup() {
    // TODO Chỗ này cần phải làm "Bộ lọc" cho trang
    // Sắp xếp theo tên A-Z Z-A theo icon của ant-design
    return (
      <FilterGroup className="col-12">
        <span className="filter">
          <i className="fa fa-filter" /> {strings.filter}
        </span>
        <div className="mr-3">
          <SegmentedControlContainer>
            <SegmentedControl
              segments={[
                { name: <i className="fa fa-th-large" /> },
                { name: <i className="fa fa-list" /> },
              ]}
              selected={this.state.viewAsBlocks ? 0 : 1}
              variant="base"
              onChangeSegment={this.handleChangeView}
            />
          </SegmentedControlContainer>
        </div>
      </FilterGroup>
    );
  }

  renderBreadcrumbAndSearch() {
    const { history, breadcrumb = [{ title: "", href: "" }] } = this.props;
    // TODO Chỗ này cần search bên trong danh sách profiles, truyền key xuống profile khi onChange, bounce 300ms"
    return (
      <>
        <div className="col-12 col-lg-6 col-md-6">
          <Breadcrumb className="ml-3" separator=">">
            {(breadcrumb || []).map((item, index) => {
              const title =
                item.title.length <= 27
                  ? item.title
                  : item.title.substring(0, 27) + "...";
              return (
                <Breadcrumb.Item
                  key={index}
                  style={{ cursor: item.href ? "pointer" : "default" }}
                  onClick={() => {
                    item.href && history.push(item.href);
                  }}>
                  <Popover content={item.title}>{title || ""}</Popover>
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </div>
        <div className="col-9 col-lg-6 col-md-6">
          <SearchStyled
            className="mr-3"
            suffix={<i className="fa fa-search" />}
            placeholder={strings.search_placeholder}
            enterButton
            onClick={() => message.info(strings.feature_development)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) message.info(strings.feature_development);
            }}
          />
        </div>
      </>
    );
  }

  renderButtons = () => {
    const { match } = this.props;
    switch (match.path) {
      // All farm
      case PATH.ALL_FARM_FORM_PATH:
        return this.mRenderButton(
          strings.create_post_sale,
          PATH.CREATE_FARM_FORM_PATH,
          strings.import,
          PATH.CREATE_FARM_FORM_PATH,
          "create"
        );
      case PATH.FARM_HOME_PATH:
      case PATH.ALL_FARM_PATH:
        return this.mRenderButton(
          `${strings.create_profile} ${strings.farm}`,
          PATH.CREATE_FARM_PROFILE_PATH,
          strings.import,
          PATH.CREATE_FARM_PROCEDURE_PATH,
          "create"
        );
      // Farm Procedure
      case PATH.CREATE_FARM_PROCEDURE_PATH:
        return this.mRenderButton(
          strings.save,
          "onCreate",
          strings.cancel,
          PATH.ALL_FARM_PROCEDURE_PATH.replace(":type", "ALL")
        );
      case PATH.CREATE_FARM_FORM_PATH:
        return this.mRenderButton(
          strings.save,
          "onCreate",
          strings.cancel,
          PATH.ALL_FARM_FORM_PATH
        );
      case PATH.ALL_FARM_PROCEDURE_PATH:
      case PATH.ALL_FARM_SEASON_PROCEDURE_PATH:
      case PATH.FARM_PROCEDURE_PATH:
        return this.mRenderButton(
          `${strings.create_on} ${strings.procedure}`,
          PATH.CREATE_FARM_PROCEDURE_PATH,
          strings.import,
          "",
          "create"
        );
      case PATH.VIEW_FARM_SEASON_PROCEDURE_PATH:
        return this.mRenderButton(
          strings.edit_procedure,
          PATH.EDIT_FARM_SEASON_PROCEDURE_PATH,
          strings.add_procedure,
          PATH.EDIT_SEASON_CRUD_FARM_PATH,
          "",
          true,
          false
        );
      case PATH.VIEW_FARM_PROCEDURE_PATH:
        return this.mRenderButton(
          strings.edit_procedure,
          PATH.EDIT_FARM_PROCEDURE_PATH,
          strings.add_procedure,
          PATH.CREATE_FARM_PROCEDURE_PATH
        );
      case PATH.EDIT_FARM_SEASON_PROCEDURE_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_FARM_SEASON_PROCEDURE_PATH,
          "",
          true,
          false
        );
      case PATH.EDIT_FARM_PROCEDURE_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_FARM_PROCEDURE_PATH
        );
      // Farm Season
      case PATH.FARM_DETAIL_PATH:
        return this.mRenderButton(
          `${strings.create_on} ${strings.seasons}`,
          PATH.CREATE_SEASON_CRUD_FARM_PATH,
          strings.import,
          PATH.CREATE_SEASON_CRUD_FARM_PATH,
          "create"
        );
      case PATH.FARM_SEASON_CRUD_PATH:
        return this.mRenderButton(
          `${strings.create_on} ${strings.seasons}`,
          PATH.CREATE_SEASON_CRUD_FARM_PATH
        );

      case PATH.VIEW_SEASON_CRUD_FARM_PATH:
        return this.mRenderButton(
          strings.edit_farm_season,
          PATH.EDIT_SEASON_CRUD_FARM_PATH,
          strings.create_farm_season,
          PATH.CREATE_SEASON_CRUD_FARM_PATH
        );
      case PATH.EDIT_SEASON_CRUD_FARM_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_SEASON_CRUD_FARM_PATH
        );
      case PATH.CREATE_SEASON_CRUD_FARM_PATH:
        return this.mRenderButton(
          strings.save,
          "onCreate",
          strings.cancel,
          PATH.FARM_DETAIL_PATH
        );

      // ledger
      case PATH.OVERVIEW_LEDGER_FARM_PATH:
        return this.mRenderButton(
          strings.edit_farm_season,
          PATH.EDIT_SEASON_CRUD_FARM_PATH,
          strings.create_farm_season,
          PATH.CREATE_SEASON_CRUD_FARM_PATH
        );
      // Farm season harvest
      case PATH.ENTER_HARVEST_FARM_PATH:
        return this.mRenderButton(
          strings.import_exit,
          PATH.EDIT_SEASON_HARVEST_FARM_PATH,
          strings.import,
          PATH.VIEW_SEASON_CRUD_FARM_PATH
        );
      case PATH.OVERVIEW_SEASON_HARVEST_FARM_PATH:
        return this.mRenderButton(
          strings.inventory,
          PATH.ENTER_HARVEST_FARM_PATH,
          strings.import_exit,
          PATH.EDIT_SEASON_HARVEST_FARM_PATH
        );
      case PATH.EDIT_SEASON_HARVEST_FARM_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.ENTER_HARVEST_FARM_PATH
        );
      case PATH.EDIT_SEASON_EXPECTED_HARVEST_FARM_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_SEASON_CRUD_FARM_PATH
        );

      // Input, Product
      case PATH.FARM_INPUT_PATH:
        return this.mRenderButton(
          strings.import_input,
          "/",
          strings.import,
          "/"
        );
      case PATH.FARM_PRODUCT_PATH:
        return this.mRenderButton(strings.import_exit, "/");
      /**viet */

      case PATH.EDIT_FARM_PACKAGING_COST_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_FARM_PACKAGING_COST_PATH
        );
      case PATH.ALL_FARM_PRODUCT_COST_PATH:
        return this.mRenderButton(
          `${strings.CREATE_PRODUCT_COST}`,
          PATH.CREATE_FARM_PRODUCT_COST_PATH,
          strings.import
        );
      case PATH.ALL_FARM_SALE_PRICE_PATH:
        return this.mRenderButton(
          `${strings.CREATE_SALE_PRICE}`,
          PATH.CREATE_FARM_SALE_PRICE_PATH,
          strings.import
        );
      case PATH.VIEW_FARM_FORM_PATH:
        return this.mRenderButton(
          strings.edit_form_post,
          PATH.EDIT_FARM_FORM_PATH,
          strings.create_post_sale,
          PATH.CREATE_FARM_FORM_PATH
        );
      case PATH.EDIT_FARM_FORM_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_FARM_FORM_PATH
        );
      //
      case PATH.CREATE_FARM_PRODUCT_COST_PATH:
        return this.mRenderButton(
          strings.save,
          "onCreate",
          strings.cancel,
          PATH.ALL_FARM_PRODUCT_COST_PATH
        );
      case PATH.CREATE_FARM_SALE_PRICE_PATH:
        return this.mRenderButton(
          strings.save,
          "onCreate",
          strings.cancel,
          PATH.ALL_FARM_SALE_PRICE_PATH
        );
      //
      case PATH.CREATE_FARM_PACKAGING_COST_PATH:
        return this.mRenderButton(
          strings.save,
          "onCreate",
          strings.cancel,
          PATH.ALL_FARM_PACKAGING_COST_PATH
        );
      case PATH.ALL_FARM_PACKAGING_COST_PATH:
        return this.mRenderButton(
          `${strings.CREATE_PAKAGING_COST}`,
          PATH.CREATE_FARM_PACKAGING_COST_PATH,
          strings.import
        );
      case PATH.VIEW_FARM_FORM_PATH:
        return this.mRenderButton(
          strings.edit_form_post,
          PATH.EDIT_FARM_FORM_PATH,
          strings.create_post_sale,
          PATH.CREATE_FARM_FORM_PATH
        );
      case PATH.EDIT_FARM_FORM_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_FARM_FORM_PATH
        );
      case PATH.VIEW_FARM_PACKAGING_COST_PATH:
        return this.mRenderButton(
          strings.EDIT_PAKAGING_COST,
          PATH.EDIT_FARM_PACKAGING_COST_PATH,
          strings.CREATE_PAKAGING_COST,
          PATH.CREATE_FARM_PACKAGING_COST_PATH
        );
      case PATH.VIEW_FARM_PRODUCT_COST_PATH:
        return this.mRenderButton(
          strings.EDIT_PRODUCT_COST,
          PATH.EDIT_FARM_PRODUCT_COST_PATH,
          strings.CREATE_PRODUCT_COST,
          PATH.CREATE_FARM_PRODUCT_COST_PATH
        );

      case PATH.EDIT_FARM_PRODUCT_COST_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_FARM_PRODUCT_COST_PATH
        );

      case PATH.VIEW_FARM_SALE_PRICE_PATH:
        return this.mRenderButton(
          strings.edit_sale_price,
          PATH.EDIT_FARM_SALE_PRICE_PATH,
          strings.CREATE_SALE_PRICE,
          PATH.CREATE_FARM_SALE_PRICE_PATH
        );

      case PATH.EDIT_FARM_SALE_PRICE_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_FARM_SALE_PRICE_PATH
        );
      /**viet!end --- ♥♥♥ */

      default:
        return this.mRenderButton();
    }
  };

  renderRightContent = () => {
    const { match, onDelete } = this.props;
    let stringDelete = strings.remove_profile_confirmation;
    switch (match.path) {
      case PATH.EDIT_FARM_PROCEDURE_PATH:
      case PATH.VIEW_FARM_PROCEDURE_PATH:
        stringDelete = strings.delete_procedure;
      case PATH.EDIT_FARM_FORM_PATH:
      case PATH.VIEW_FARM_FORM_PATH:
        stringDelete = strings.delete_form_post;
      case PATH.EDIT_SEASON_CRUD_FARM_PATH:
      case PATH.VIEW_SEASON_CRUD_FARM_PATH:
        stringDelete = strings.delete_this_season;
      case PATH.EDIT_FARM_PACKAGING_COST_PATH:
      case PATH.VIEW_FARM_PACKAGING_COST_PATH:
        stringDelete = strings.delete_pakaing_cost;
      case PATH.EDIT_FARM_PRODUCT_COST_PATH:
      case PATH.VIEW_FARM_PRODUCT_COST_PATH:
        stringDelete = strings.delete_product_cost;
      case PATH.EDIT_FARM_SALE_PRICE_PATH:
      case PATH.VIEW_FARM_SALE_PRICE_PATH:
        stringDelete = strings.delete_sale_price;
    }
    switch (match.path) {
      case PATH.EDIT_FARM_SALE_PRICE_PATH:
      case PATH.VIEW_FARM_SALE_PRICE_PATH:
      case PATH.EDIT_FARM_PRODUCT_COST_PATH:
      case PATH.VIEW_FARM_PRODUCT_COST_PATH:
      case PATH.EDIT_FARM_PACKAGING_COST_PATH:
      case PATH.VIEW_FARM_PACKAGING_COST_PATH:
      case PATH.ALL_FARM_PATH:
      case PATH.EDIT_FARM_PROCEDURE_PATH:
      case PATH.VIEW_FARM_PROCEDURE_PATH:
      case PATH.FARM_DETAIL_PATH:
      case PATH.EDIT_SEASON_CRUD_FARM_PATH:
      case PATH.EDIT_FARM_FORM_PATH:
      case PATH.VIEW_FARM_FORM_PATH:
        return this.renderMenuAction(stringDelete, onDelete);
      case PATH.ALL_FARM_PROCEDURE_PATH:
      case PATH.FARM_HOME_PATH:
      case PATH.ALL_FARM_PRODUCT_COST_PATH:
      case PATH.ALL_FARM_SALE_PRICE_PATH:
      case PATH.VIEW_SEASON_CRUD_FARM_PATH:
      /**viet */
      case PATH.ALL_FARM_PACKAGING_COST_PATH:
      case PATH.ALL_FARM_PRODUCT_COST_PATH:
      case PATH.ALL_FARM_SALE_PRICE_PATH:
        /**end!viet---♥ */
        return this.renderFilterGroup();
    }
  };

  render() {
    return (
      <div>
        <ContainerStyled
          className={this.props.className}
          showAction={this.state.showAction}
          hiddenAction>
          <div className="row">{this.renderBreadcrumbAndSearch()}</div>
          <div className="row ">
            <GroupButtonStyled className="col-6">
              {this.renderButtons()}
            </GroupButtonStyled>
            <div className="col-6 d-flex justify-content-end">
              {this.renderRightContent()}
            </div>
            <div className="show-action">
              <Button
                className="d-lg-none"
                type="text"
                onClick={() => this.handleShowAction(!this.state.showAction)}>
                {this.state.showAction ? (
                  <CaretUpOutlined />
                ) : (
                  <CaretDownOutlined />
                )}
              </Button>
            </div>
          </div>
        </ContainerStyled>
      </div>
    );
  }
}
export default compose(withRouter)(FarmNav);
