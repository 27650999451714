import React from "react";
import { UIButton } from "~/views/presentation/ui/buttons";

import { compose, withHandlers, lifecycle, withState } from "recompose";
import { connect } from "react-redux";
import * as yup from "yup";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import {
  InputField,
  InputPriceField,
  SelectField,
} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import AvatarUpload from "~/views/presentation/ui/upload/AvatarUpload";
import { Tabs, Form } from "antd";
import { Select } from "antd";

import _ from "lodash";
import BanksForm from "./BanksForm";
import {
  emailNRValidate,
  stringNRFieldValidate,
  numberValidate,
  phoneNRValidate,
} from "~/views/utilities/validation/input";
import {
  getWards,
  getDistrict,
  getLiteracies,
  getProfessions,
  getType,
  getMassUnits,
  getProvinces,
} from "~/state/ducks/appApis/actions";
import { showError } from "~/configs/ServerErrors";
import { getArray, getNumber } from "~/views/utilities/helpers/utilObject";
const { TabPane } = Tabs;

const validationSchema = yup.object().shape({
  phone: phoneNRValidate, //
  email: emailNRValidate, //
  name: stringNRFieldValidate(), //
  surrogate: stringNRFieldValidate(), //
  costYear: numberValidate, //
  employee: numberValidate, //
  grossYield: numberValidate, //
  farmingSeasonNumberId: numberValidate, //
  unitId: numberValidate, //
  grossArea: numberValidate, //
  personalIncomeTax: numberValidate, //
  profitYear: numberValidate, //
  vat: numberValidate, //
  revenueYear: numberValidate, //
  // cultivation: numberValidate,
});

class PersonalInfoForms extends React.PureComponent {
  /**
   *
   * @param {*} prvDataSource - the dataSource will be updated
   */

  updateDataSource = (prvDataSource) => (dataSource) => {
    while (prvDataSource.length) {
      prvDataSource.shift();
    }
    dataSource.forEach((item) => {
      prvDataSource.push(item);
    });
  };

  handleChangeAvatar = (imageName) => {
    const { setFieldValue } = this.props;
    setFieldValue("avatar", imageName);
    this.props.info["avatar"] = imageName;
  };
  _handleChangenumber = (e, name) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, e);
    this.props.info[name] = {
      value: e,
      unit: "VND",
    };
    this.props.info[`${name}1`] = e;
  };
  handleChangeAdress = (e) => {
    const { handleChange } = this.props;
    handleChange(e);
    let name = e.target.name;
    this.props.info[name] = e.target.value;

    this.props.info.address[name] = e.target.value;
  };
  handleChangeInputValue = (e) => {
    const { handleChange } = this.props;
    handleChange(e);
    let name = e.target.name;
    this.props.info[name] = e.target.value;
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.error(
      "PersonalInfoForms -> UNSAFE_componentWillReceiveProps -> nextProps",
      nextProps.values
    );
    this.props.info["personalIncomeTax"] ={
      unit: "VND",
      value: this.props.values.personalIncomeTax,
    }
    const { fechWard, fechDistrict } = this.props;
    if (nextProps.info.provinceId !== this.props.info.provinceId)
      if (nextProps.info.provinceId) fechDistrict(nextProps.info.provinceId);
    if (nextProps.info.districtId !== this.props.info.districtId)
      if (nextProps.info.districtId) {
        fechWard(nextProps.info.districtId);
      }
  }
  handleChangeSelectValue = (name) => (value) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, value);
    this.props.info[name] = value;
  };

  handleChangeDate = (name) => (date) => {
    const { setFieldValue } = this.props;
    if (!_.isNil(date)) {
      setFieldValue(name, date.format(UtilDate.formatDateTimeServer));
      this.props.info[name] = date.format(UtilDate.formatDateTimeServer);
    } else {
      setFieldValue(name, undefined);
      this.props.info[name] = undefined;
    }
  };

  render() {
    const {
      acreAge,
      values,
      handleBlur,
      touched,
      errors,
      setFieldValue,
      setFieldTouched,
      // professions,
      editable,
      marketsIds,
    } = this.props;
    this.props.info.errors = errors;
    if (!values.productivityUnit && this.props.grossProductivityUnit.length)
      this.props.info.productivityUnit = getNumber(
        this.props.grossProductivityUnit[0],
        "value",
        null
      );

    return (
      <div>
        <Form>
          <div className="row d-flex flex-row-reverse">
            <div className="col-12 col-md-6 d-flex justify-content-center">
              <AvatarUpload
                disabled={!editable}
                avatarUrl={values.avatar}
                onChange={this.handleChangeAvatar}
              />
            </div>
            <div className="col-12 col-md-6">
              <InputField
                validatestatus={
                  touched.name && errors.name ? "error" : undefined
                }
                help={touched.name && errors.name ? errors.name : ""}
                name="name"
                autoFocus
                disabled={!editable}
                value={values.name}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={strings.name_enterpise}
                label={strings.name_enterpise}
              />
              <InputField
                validatestatus={
                  touched.surrogate && errors.surrogate ? "error" : undefined
                }
                help={
                  touched.surrogate && errors.surrogate ? errors.surrogate : ""
                }
                name="surrogate"
                disabled={!editable}
                value={values.surrogate}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={strings.name_representative}
                label={strings.name_representative}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <InputField
                validatestatus={
                  touched.phone && errors.phone ? "error" : undefined
                }
                help={touched.phone && errors.phone ? errors.phone : ""}
                name="phone"
                onInput={(e) => (e.target.value = e.target.value.slice(0, 12))}
                disabled={!editable}
                value={values.phone}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={strings.phone}
                label={strings.phone}
              />
              <InputField
                validatestatus={
                  touched.email && errors.email ? "error" : undefined
                }
                help={touched.email && errors.email ? errors.email : ""}
                name="email"
                disabled={!editable}
                value={values.email}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={strings.email}
                label={strings.email}
              />
              <InputPriceField
                min={0}
                validatestatus={errors.revenueYear ? "error" : undefined}
                help={errors.revenueYear ? errors.revenueYear : undefined}
                name="revenueYear"
                disabled={!editable}
                value={values.revenueYear}
                onChange={(e) => this._handleChangenumber(e, "revenueYear")}
                onBlur={handleBlur}
                inputStyle={{ with: "100% !important" }}
                label={strings.revenue_yeah}
              />
              <InputPriceField
                min={0}
                validatestatus={
                  touched.costYear && errors.costYear ? "error" : undefined
                }
                help={
                  touched.costYear && errors.costYear
                    ? errors.costYear
                    : undefined
                }
                name="costYear"
                disabled={!editable}
                value={values.costYear}
                onChange={(e) => this._handleChangenumber(e, "costYear")}
                onBlur={handleBlur}
                inputStyle={{ with: "100% !important" }}
                label={strings.cost_yeah}
              />
              <InputPriceField
                min={0}
                validatestatus={touched.vat && errors.vat ? "error" : undefined}
                help={touched.vat && errors.vat ? errors.vat : undefined}
                name="vat"
                disabled={!editable}
                value={values.vat}
                onChange={(e) => this._handleChangenumber(e, "vat")}
                onBlur={handleBlur}
                inputStyle={{ with: "100% !important" }}
                label={strings.tax_GTGT_yeah}
              />
              <InputPriceField
                min={0}
                validatestatus={
                  touched.profitYear && errors.profitYear ? "error" : undefined
                }
                help={
                  touched.profitYear && errors.profitYear
                    ? errors.profitYear
                    : undefined
                }
                name="profitYear"
                disabled={!editable}
                value={values.profitYear}
                onChange={(e) => this._handleChangenumber(e, "profitYear")}
                onBlur={handleBlur}
                inputStyle={{ with: "100% !important" }}
                label={strings.annual_profit}
              />
              <InputPriceField
                min={0}
                validatestatus={
                  touched.personalIncomeTax && errors.personalIncomeTax
                    ? "error"
                    : undefined
                }
                help={
                  touched.personalIncomeTax && errors.personalIncomeTax
                    ? errors.personalIncomeTax
                    : undefined
                }
                name="personalIncomeTax"
                disabled={!editable}
                value={values.personalIncomeTax}
                onChange={(e) =>
                  this._handleChangenumber(e, "personalIncomeTax")
                }
                onBlur={handleBlur}
                inputStyle={{ with: "100% !important" }}
                label={strings.tax_TNDN}
              />
            </div>
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-8 pr-2">
                  <InputField
                    disabled={!editable}
                    validatestatus={
                      touched.grossArea && errors.grossArea
                        ? "error"
                        : undefined
                    }
                    help={
                      touched.grossArea && errors.grossArea
                        ? errors.grossArea
                        : ""
                    }
                    name="grossArea"
                    min={0}
                    type="number"
                    value={values.grossArea}
                    onChange={this.handleChangeInputValue}
                    onBlur={handleBlur}
                    placeholder={strings.sum_acreage_mana}
                    label={strings.sum_acreage_mana}
                  />
                </div>
                <div className="col-4 pl-2">
                  <SelectField
                    validateStatus={
                      touched.unitId && errors.unitId ? "error" : undefined
                    }
                    disabled={!editable}
                    help={touched.unitId && errors.unitId ? errors.unitId : ""}
                    data={acreAge || []}
                    value={values.unitId}
                    onChange={(value) => {
                      this.props.info.unitId = value;
                      setFieldValue("unitId", value);
                      setFieldTouched("unitId");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.unit}
                    label={strings.unit}
                  />
                </div>
              </div>
              <SelectField
                mode="multiple"
                validateStatus={
                  touched.formOfUsesIds && errors.formOfUsesIds
                    ? "error"
                    : undefined
                }
                disabled={!editable}
                help={
                  touched.formOfUsesIds && errors.formOfUsesIds
                    ? errors.formOfUsesIds
                    : ""
                }
                data={this.props.formOfUsesIdsData || []}
                value={values.formOfUsesIds}
                onChange={(value) => {
                  this.props.info.formOfUseIds = value;
                  setFieldValue("formOfUsesIds", value);
                  setFieldTouched("formOfUsesIds");
                }}
                iconEnd="caret-down"
                placeholder={strings.status_land}
                label={strings.status_land}
              />
              <InputField
                validatestatus={
                  touched.employee && errors.employee ? "error" : undefined
                }
                help={
                  touched.employee && errors.employee ? errors.employee : ""
                }
                name="employee"
                type="number"
                min={0}
                disabled={!editable}
                value={values.employee}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={"100"}
                label={strings.sum_employee}
              />

              <InputField
                validatestatus={
                  touched.farmingSeasonNumberId && errors.farmingSeasonNumberId
                    ? "error"
                    : undefined
                }
                help={
                  touched.farmingSeasonNumberId && errors.farmingSeasonNumberId
                    ? errors.farmingSeasonNumberId
                    : ""
                }
                name="farmingSeasonNumberId"
                type="number"
                min={0}
                max={20}
                disabled={!editable}
                value={values.farmingSeasonNumberId}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={"1"}
                label={strings.number_seasons}
              />
              {/* <div className="row"> */}
              {/* <div className="col-8 pr-2"> */}
              <div className="row">
                <div className="col-8 pr-2">
                  <InputField
                    disabled={!editable}
                    validatestatus={
                      touched.grossYield && errors.grossYield
                        ? "error"
                        : undefined
                    }
                    help={
                      touched.grossYield && errors.grossYield
                        ? errors.grossYield
                        : ""
                    }
                    name="grossYield"
                    type="number"
                    min={0}
                    value={values.grossYield}
                    onChange={this.handleChangeInputValue}
                    onBlur={handleBlur}
                    placeholder={strings.sum_output}
                    label={strings.sum_output}
                  />
                </div>
                <div className="col-4 pl-2">
                  <SelectField
                    validateStatus={
                      touched.productivityUnit && errors.productivityUnit
                        ? "error"
                        : undefined
                    }
                    disabled={!editable}
                    help={
                      touched.productivityUnit && errors.productivityUnit
                        ? errors.productivityUnit
                        : ""
                    }
                    data={this.props.grossProductivityUnit || []}
                    value={
                      values.productivityUnit
                        ? values.productivityUnit
                        : this.props.grossProductivityUnit.length
                        ? this.props.grossProductivityUnit[0].value
                        : undefined
                    }
                    // value={values.productivityUnit}
                    onChange={(value) => {
                      this.props.info.productivityUnit = value;
                      setFieldValue("productivityUnit", value);
                      setFieldTouched("productivityUnit");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.unit}
                    label={strings.unit}
                  />
                </div>
              </div>
              {/* </div> */}
              {/* <div className="col-4 pl-2">
                  <SelectField
                    validateStatus={
                      touched.unitForMass && errors.unitForMass
                        ? "error"
                        : undefined
                    }
                    disabled={!editable}
                    help={
                      touched.unitForMass && errors.unitForMass
                        ? errors.unitForMass
                        : ""
                    }
                    data={unitMass || []}
                    value={values.unitForMass}
                    onChange={(value) => {
                      this.props.info.unitForMass = value;
                      setFieldValue("unitForMass", value);
                      setFieldTouched("unitForMass");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.unit}
                    label={strings.unit}
                  />
                </div> */}
              {/* </div> */}
              <SelectField
                validateStatus={
                  touched.marketsIds && errors.marketsIds ? "error" : undefined
                }
                mode="multiple"
                disabled={!editable}
                help={
                  touched.marketsIds && errors.marketsIds
                    ? errors.marketsIds
                    : ""
                }
                data={marketsIds || []}
                value={values.marketsIds}
                onChange={(value) => {
                  this.props.info.marketsIds = value;
                  setFieldValue("marketsIds", value);
                  setFieldTouched("marketsIds");
                }}
                iconEnd="caret-down"
                placeholder={strings.consumption_market}
                label={strings.consumption_market}
              />
            </div>
          </div>
          <div className="mt-4">
            <Tabs defaultActiveKey="1" type="card" size={"middle"}>
              <TabPane tab={strings.bank} key="1">
                <BanksForm
                  editable={editable}
                  onChange={this.updateDataSource(this.props.info.bankAccounts)}
                  bankAccounts={this.props.info.bankAccounts}
                />
                <div style={{ marginTop: "16px" }}>
                  <UIButton
                    style={{ float: "right" }}
                    type="primary"
                    className="ml-2"
                    htmlType="button"
                    disabled={!editable}
                    onClick={() => this.props.nextStept(1)}
                  >
                    <span>{strings.countinue}</span>
                  </UIButton>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Form>
      </div>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    getWards,
    getLiteracies,
    getProfessions,
    getMassUnits,
    getProvinces,
    getDistrict,
    getType,
  }),
  withState("grossProductivityUnit", "setgrossProductivityUnit", [
    { value: 2, label: "Tấn/Năm" },
  ]),
  // withState("professions", "setProfessions", []),
  withState("acreAge", "setAcreAge", []),
  withState("unit", "setUnit", []),
  withState("unitMass", "setUnitMass", []),
  withState("cultivation", "setcultivation", []),
  withState("profession", "setProfession", []),
  withState("marketsIds", "setMarket", []),
  withState("provin", "setProvin", []),
  withState("district", "setDistrict", []),
  withState("ward", "setWard", []),
  withState("formOfUsesIdsData", "setFormOfUsesIds", []),

  withHandlers({
    fetchformOfUsesIds: (props) => () => {
      const { getType, setFormOfUsesIds } = props;
      getType("FORM_OF_USES")
        .then(({ res }) => {
          setFormOfUsesIds(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fechWard: (props) => (id) => {
      const { getWards, setWard } = props;

      if (id)
        getWards(id)
          .then(({ res }) => {
            setWard(
              getArray(res, undefined, []).map((item) => {
                return {
                  value: item.id,
                  label: item.name || "",
                };
              })
            );
          })
          .catch((err) => showError(err));
    },
    fechDistrict: (props) => (id) => {
      const { getDistrict, setDistrict } = props;
      if (id)
        getDistrict(id)
          .then(({ res }) => {
            setDistrict(
              getArray(res, undefined, []).map((item) => {
                return {
                  value: item.id,
                  label: item.name || "",
                };
              })
            );
          })
          .catch((err) => showError(err));
    },
    fechGetProvince: (props) => () => {
      const { getProvinces, setProvin } = props;
      getProvinces(1)
        .then(({ res }) => {
          setProvin(
            getArray(res, undefined, []).map((item) => {
              return {
                value: item.id,
                label: item.name || "",
              };
            })
          );
        })
        .catch((err) => showError(err));
    },
    fetchTypeCulti: (props) => () => {
      const { getType, setcultivation } = props;
      getType("CULTIVATION")
        .then(({ res }) => {
          setcultivation(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fetchTypeProfess: (props) => () => {
      const { getType, setProfession } = props;
      getType("PRODUCT")
        .then(({ res }) => {
          setProfession(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fetchMarket: (props) => () => {
      const { getType, setMarket } = props;
      getType("MARKET")
        .then(({ res }) => {
          setMarket(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    // fetchLiteracies: (props) => () => {
    //   const { getLiteracies, setLiteracies } = props;
    //   getLiteracies()
    //     .then(({ res }) => {
    //       setLiteracies(
    //         getArray(res, undefined, []).map((item) => ({
    //           value: item.id,
    //           label: item.name || "",
    //         }))
    //       );
    //     })
    //     .catch((err) => showError(err));
    // },
    fecthAcreage: (props) => () => {
      const { setAcreAge, getMassUnits } = props;
      getMassUnits("ACREAGE")
        .then(({ res }) => {
          setAcreAge(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fecthUnitMass: (props) => () => {
      const { setUnitMass, getMassUnits } = props;
      getMassUnits()
        .then(({ res }) => {
          setUnitMass(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },

    // fetchProfessions: (props) => () => {
    //   const { getProfessions, setProfessions } = props;
    //   getProfessions()
    //     .then(({ res }) => {
    //       setProfessions(
    //         getArray(res, undefined, []).map((item) => ({
    //           value: item.id,
    //           label: item.name || "",
    //         }))
    //       );
    //     })
    //     .catch((err) => showError(err));
    // },
  }),
  withFormik({
    displayName: "inforFarm",
    enableReinitialize: true,
    validationSchema,
    mapPropsToValues: (props) => {
      return {
        phone: props.info.phone,
        email: props.info.email,
        name: props.info.name,
        surrogate: props.info.surrogate,
        costYear: props.info.costYear1,
        employee: props.info.employee,
        grossYield: props.info.grossYield,
        farmingSeasonNumberId: props.info.farmingSeasonNumberId,
        unitId: props.info.unitId,
        grossArea: props.info.grossArea,
        personalIncomeTax: props.info.personalIncomeTax1,
        profitYear: props.info.profitYear1,
        vat: props.info.vat1,
        revenueYear: props.info.revenueYear1,
        marketsIds: props.info.marketsIds,
        avatar: props.info.avatar,
        formOfUsesIds: props.info.formOfUseIds,
      };
    },
    // handleSubmit: async (values, { props, setSubmitting }) => {}
  }),
  withState("grossProductivityUnit", "setgrossProductivityUnit", [
    { value: 2, label: "Tấn/Năm" },
  ]),
  lifecycle({
    componentDidMount() {
      const {
        // fetchLiteracies,
        // fetchProfessions,
        fecthAcreage,
        fechGetProvince,
        fetchTypeCulti,
        fetchTypeProfess,
        fetchMarket,
        fecthUnitMass,
        fechDistrict,
        fechWard,
        fetchformOfUsesIds,
      } = this.props;
      const { districtId, provinceId } = this.props.info;

      if (provinceId) fechDistrict(provinceId);
      if (districtId) {
        fechWard(districtId);
      }
      // fetchProfessions();
      fecthAcreage();
      fetchTypeCulti();
      fetchTypeProfess();
      fetchMarket();
      fecthUnitMass();
      fechGetProvince();
      fetchformOfUsesIds();
    },
  })
)(PersonalInfoForms);
