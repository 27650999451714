import React from "react";
import AppContentWrapper from "~/views/presentation/ui/container/AppContentWrapper";
import strings from "~/localization";
import FarmNav from "../Navigation/FarmNav";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { showError } from "~/configs/ServerErrors";
import Formpost from "./FormPost";
import AllFarms from "./AllFarm";
import {
  getFarmProfile,
  getFarmSeasonCRUD,
} from "~/state/ducks/appApis/actions";
import {
  getArray,
  getNumber,
  getString,
} from "~/views/utilities/helpers/utilObject";
import _ from "lodash";
import {
  DashboardOutlined,
  FileTextOutlined,
  FolderOutlined,
} from "@ant-design/icons";
import { ContainerNavStyled } from "../Navigation/styles";
import * as PATH from "~/configs/routesConfig";
import AllFormPost from "./FormPost/AllFormPost/index.js";
import AllProductCost from "./Structure/AllStructure";
import FarmOverview from "./FarmOverview";
import FarmSeasonCRUD from "~/views/container/Farm/FarmSeasonCRUD";
import FarmSeasonDetailOverview from "./FarmSeasonDetailOverview";
import FarmSeasonExpectedHarvest from "~/views/container/Farm/FarmSeasonExpectedHarvest";
import FarmSeasonHarvest from "~/views/container/Farm/FarmSeasonHarvest";
import FarmSeasonHarvestOverview from "~/views/container/Farm/FarmSeasonHarvestOverview";
import FarmSeasonEnterHarvest from "~/views/container/Farm/FarmSeasonEnterHarvest";
import Procedure from "./Procedure";
import UpdateProcedure from "./Procedure/UpdateProcedure";
import UpdateProductCost from "./Structure/UpdateProductCost";
import UpdateSalePrice from "./Structure/UpdateSalePrice";
import UpdatePackagingCost from "./Structure/UpdatePackagingCost";
import FarmSeasonLedger from "./FarmSeasonLedger";
import { getType } from "~/state/ducks/appApis/actions";
import { createProcedure } from "~/state/ducks/typeProcedure/action";
import ProfileNav from "../Navigation/ProfileNav";

// menu Header
const menuHeader = [
  {
    title: strings.farm,
    path: PATH.FARM_HOME_PATH,
  },
  {
    title: strings.procedure,
    path: PATH.ALL_FARM_PROCEDURE_PATH.replace(":type", "ALL"),
  },
  {
    title: strings.seasons,
    path: PATH.FARM_DETAIL_PATH,
  },
  {
    title: strings.product,
    path: PATH.FARM_PRODUCT_PATH,
  },
  {
    title: strings.input,
    path: PATH.FARM_INPUT_PATH,
  },
  {
    title: strings.report,
  },
  {
    title: strings.configuration,
  },
];

const treeMenu = {
  overview: {
    name: strings.dashboard,
    path: PATH.FARM_DETAIL_PATH,
    defaultExpand: false,
  },

  season: {
    name: strings.seasons,
    path: PATH.FARM_SEASON_CRUD_PATH,
    defaultExpand: true,
    subMenus: {
      overview_season: {
        name: strings.overview_season,
        path: PATH.VIEW_SEASON_CRUD_FARM_PATH,
      },
      ledg: {
        name: strings.ledg,
        path: PATH.OVERVIEW_LEDGER_FARM_PATH,
      },
      structure: {
        name: strings.structure,
        path: PATH.FARM_STRUCTURE,
        subMenus: {
          cost_product: {
            name: strings.cost_product,
            path: PATH.ALL_FARM_PRODUCT_COST_PATH,
          },
          cost_pack: {
            name: strings.cost_pack,
            path: PATH.ALL_FARM_PACKAGING_COST_PATH,
          },
          price: {
            name: strings.price,
            path: PATH.ALL_FARM_SALE_PRICE_PATH,
          },
        },
      },
      harvest: {
        name: strings.harvest,
        path: "2",
        subMenus: {
          // harvest_overview: {
          //   name: strings.harvest_overview,
          //   path: PATH.OVERVIEW_SEASON_HARVEST_FARM_PATH,
          // },
          enter_the_harvest_inventory: {
            name: strings.enter_the_harvest_inventory,
            path: PATH.ENTER_HARVEST_FARM_PATH,
          },
          expected_inventory_entry: {
            name: strings.expected_inventory_entry,
            path: PATH.EDIT_SEASON_EXPECTED_HARVEST_FARM_PATH,
          },
        },
      },
      procedure: {
        name: strings.procedure,
        path: "3",
        subMenus: {
          procedure_all: {
            name: strings.all,
            path: PATH.ALL_FARM_SEASON_PROCEDURE_PATH.replace(":type", "ALL"),
          },
        },
      },
      post_sale: {
        name: strings.post_sale,
        path: PATH.ALL_FARM_FORM_PATH,
      },
    },
  },
};

const treeMenuOverview = {
  overview: {
    name: strings.dashboard,
    path: PATH.FARM_DETAIL_PATH,
  },
};

class Farm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameFarm: "",
      farmSeasonName: "",
      viewAsBlocks: true,
      id: undefined,
      farm: null,
      season: null,
      hidden: false,
      menu: treeMenu,
    };
    this.ref = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
    if (window.scrollY > 22) {
      this.setState({ hidden: true });
    } else {
      this.setState({ hidden: false });
    }
  }
  UNSAFE_componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  changeViewStyle = (viewAsBlocks) => {
    this.setState({ viewAsBlocks: viewAsBlocks });
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  fetchProfile = () => {
    const { match, getFarmProfile } = this.props;

    let profileId = +match.params.id;
    getFarmProfile(profileId)
      .then(async ({ res }) => {
        const name = getString(res, "name", "");
        this.setState({ nameFarm: name, farm: res });
      })
      .catch((err) => showError(err));
  };

  fetchSeasonProfile = () => {
    const { match, getFarmSeasonCRUD } = this.props;

    let profileId = +match.params.idSeason;
    getFarmSeasonCRUD(profileId)
      .then(async ({ res }) => {
        const name = getString(res, "name", "");
        this.setState({ farmSeasonName: name, season: res });
      })
      .catch((err) => showError(err));
  };

  fetchType = () => {
    const { getType, createProcedure } = this.props;
    let { menu } = this.state;
    let temp = menu.season.subMenus.procedure.subMenus;
    getType("CULTIVATION_PROCESS")
      .then(({ res }) => {
        getArray(res, undefined, []).map((val) => {
          temp = {
            ...temp,
            [getString(val, "name", "")]: {
              name: getString(val, "name", ""),
              path: PATH.ALL_FARM_SEASON_PROCEDURE_PATH.replace(
                ":type",
                val.id
              ),
            },
          };
        });
        createProcedure(menu);
        menu.season.subMenus.procedure.subMenus = temp;
        this.setState({ menu });
      })
      .catch((err) => {
        console.error("fetchType -> getType -> err", err);
        showError(err);
      });
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.fetchProfile();
      this.setState({ id: match.params.id });
    }
    if (match.params.idSeason) {
      this.fetchSeasonProfile();
      this.fetchType();
    }
  }
  objectBeadcrumb = () => {
    const { nameFarm, farmSeasonName } = this.state;
    return [
      { title: strings.farm, href: PATH.FARM_HOME_PATH },
      {
        title: nameFarm || strings.name_farm,
        href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
      },
      {
        title: farmSeasonName,
        href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
      },
    ];
  };
  replaceId(path) {
    const { match } = this.props;
    const { id } = this.state;
    const idSeason = match.params.idSeason;
    const idProcedure = match.params.idProcedure;
    const idPackagingCost = match.params.idPackagingCost;
    const idProductCost = match.params.idProductCost;
    return path
      .replace(":idSeason", idSeason)
      .replace(":idProcedure", idProcedure)
      .replace(":idPackagingCost", idPackagingCost)
      .replace(":idProductCost", idProductCost)
      .replace(":id", id)
      .replace(":type", "ALL");
  }

  getBreadcrumb = () => {
    const { match } = this.props;
    const { nameFarm, id, farmSeasonName } = this.state;
    const { params } = match;

    switch (match.path) {
      case PATH.ALL_FARM_SEASON_PROCEDURE_PATH:
        return [
          ...this.objectBeadcrumb(),
          {
            title: strings.procedure,
          },
        ];
      case PATH.EDIT_FARM_SEASON_PROCEDURE_PATH:
        return [
          ...this.objectBeadcrumb(),
          {
            title: strings.procedure,
            href: this.replaceId(PATH.ALL_FARM_SEASON_PROCEDURE_PATH),
          },
          { title: strings.edit_procedure },
        ];
      case PATH.VIEW_FARM_SEASON_PROCEDURE_PATH:
        return [
          ...this.objectBeadcrumb(),
          {
            title: strings.procedure,
            href: this.replaceId(PATH.ALL_FARM_SEASON_PROCEDURE_PATH),
          },
          { title: strings.view_procedure },
        ];
      case PATH.OVERVIEW_LEDGER_FARM_PATH: {
        return [
          { title: strings.farm, href: PATH.FARM_HOME_PATH },
          {
            title: nameFarm || strings.name_farm,
            href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
          },
          {
            title: farmSeasonName,
            href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
          },
          { title: strings.ledg },
        ];
      }
      case PATH.OVERVIEW_SEASON_HARVEST_FARM_PATH: {
        return [
          { title: strings.farm, href: PATH.FARM_HOME_PATH },
          {
            title: nameFarm || strings.name_farm,
            href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
          },
          {
            title: farmSeasonName,
            href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
          },
          { title: strings.harvest_overview_full },
        ];
      }
      case PATH.ENTER_HARVEST_FARM_PATH: {
        return [
          { title: strings.farm, href: PATH.FARM_HOME_PATH },
          {
            title: nameFarm || strings.name_farm,
            href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
          },
          {
            title: farmSeasonName,
            href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
          },
          { title: strings.enter_harvest_overview },
        ];
      }
      case PATH.EDIT_SEASON_HARVEST_FARM_PATH: {
        return [
          { title: strings.farm, href: PATH.FARM_HOME_PATH },
          {
            title: nameFarm || strings.name_farm,
            href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
          },
          {
            title: farmSeasonName,
            href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
          },
          { title: strings.enter_the_harvest_inventory },
        ];
      }

      case PATH.EDIT_SEASON_EXPECTED_HARVEST_FARM_PATH: {
        return [
          { title: strings.farm, href: PATH.FARM_HOME_PATH },
          {
            title: nameFarm || strings.name_farm,
            href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
          },
          {
            title: farmSeasonName,
            href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
          },
          { title: strings.expected_inventory_entry },
        ];
      }
      case PATH.ALL_FARM_PATH:
        return [{ title: strings.farm }];
      case PATH.FARM_PROCEDURE_PATH:
        return [{ title: strings.procedure, href: PATH.FARM_PROCEDURE_PATH }];
      case PATH.ALL_FARM_PROCEDURE_PATH:
        return [{ title: strings.procedure, href: PATH.FARM_PROCEDURE_PATH }];

      case PATH.EDIT_FARM_SALE_PRICE_PATH:
        return [
          ...this.objectBeadcrumb(),
          {
            href: this.replaceId(PATH.ALL_FARM_SALE_PRICE_PATH),
            title: strings.sale_price,
          },
          {
            title: strings.EDIT_SALE_PRICE,
          },
        ];
      case PATH.VIEW_FARM_SALE_PRICE_PATH:
        return [
          ...this.objectBeadcrumb(),
          {
            href: this.replaceId(PATH.ALL_FARM_SALE_PRICE_PATH),
            title: strings.sale_price,
          },
          {
            title: strings.detail,
          },
        ];
      case PATH.ALL_FARM_SEASON_PROCEDURE_PATH:
        return [{ title: strings.procedure, href: PATH.FARM_PROCEDURE_PATH }];
      case PATH.ALL_FARM_PRODUCT_COST_PATH:
        return [
          ...this.objectBeadcrumb(),
          { title: strings.structure_product_cost },
        ];
      case PATH.ALL_FARM_SALE_PRICE_PATH:
        return [
          ...this.objectBeadcrumb(),
          { title: strings.structure_sale_price },
        ];
      case PATH.ALL_FARM_SALE_PRICE_PATH:
        return [
          ...this.objectBeadcrumb(),
          { title: strings.structure_product_cost },
        ];
      case PATH.ALL_FARM_PACKAGING_COST_PATH:
        return [...this.objectBeadcrumb(), { title: strings.packaging_cost }];
      case PATH.VIEW_FARM_PACKAGING_COST_PATH:
        return [
          ...this.objectBeadcrumb(),
          {
            href: this.replaceId(PATH.ALL_FARM_PACKAGING_COST_PATH),
            title: strings.packaging_cost,
          },
          {
            title: strings.detail,
          },
        ];
      case PATH.EDIT_FARM_PACKAGING_COST_PATH:
        return [
          ...this.objectBeadcrumb(),
          {
            href: this.replaceId(PATH.ALL_FARM_PACKAGING_COST_PATH),
            title: strings.packaging_cost,
          },
          {
            title: strings.EDIT_PAKAGING_COST,
          },
        ];
      case PATH.VIEW_FARM_PRODUCT_COST_PATH:
        return [
          ...this.objectBeadcrumb(),
          {
            href: this.replaceId(PATH.ALL_FARM_PRODUCT_COST_PATH),
            title: strings.structure_product_cost,
          },
          {
            title: strings.detail,
          },
        ];
      case PATH.EDIT_FARM_PRODUCT_COST_PATH:
        return [
          ...this.objectBeadcrumb(),
          {
            href: this.replaceId(PATH.ALL_FARM_PRODUCT_COST_PATH),
            title: strings.structure_product_cost,
          },
          {
            title: strings.EDIT_PRODUCT_COST,
          },
        ];
      case PATH.ALL_FARM_FORM_PATH:
        return [...this.objectBeadcrumb(), { title: strings.post_sale }];
      case PATH.CREATE_FARM_PRODUCT_COST_PATH:
        return [
          ...this.objectBeadcrumb(),

          {
            title: strings.structure_product_cost,
            href: this.replaceId(PATH.ALL_FARM_PRODUCT_COST_PATH),
          },

          { title: strings.create },
        ];
      case PATH.CREATE_FARM_PACKAGING_COST_PATH:
        return [
          ...this.objectBeadcrumb(),

          {
            title: strings.packaging_cost,
            href: this.replaceId(PATH.ALL_FARM_PACKAGING_COST_PATH),
          },

          { title: strings.create },
        ];
      case PATH.CREATE_FARM_SALE_PRICE_PATH:
        return [
          ...this.objectBeadcrumb(),

          {
            title: strings.structure_product_cost,
            href: this.replaceId(PATH.ALL_FARM_PRODUCT_COST_PATH),
          },

          { title: strings.create },
        ];
      case PATH.CREATE_FARM_FORM_PATH:
        return [
          ...this.objectBeadcrumb(),

          {
            title: strings.post_sale,
            href: PATH.ALL_FARM_FORM_PATH.replace(
              ":id",
              getString(params, "id")
            ).replace(":idSeason", getString(params, "idSeason")),
          },
          { title: strings.create },
        ];
      case PATH.EDIT_FARM_FORM_PATH:
        return [
          { title: strings.farm, href: PATH.FARM_HOME_PATH },
          {
            title: nameFarm || strings.name_farm,
            href: `${PATH.FARM_HOME_PATH}/${id}/season`,
          },
          {
            title: farmSeasonName,
            href: `${PATH.FARM_HOME_PATH}/${id}/season`,
          },
          {
            title: strings.post_sale,
            href: PATH.ALL_FARM_FORM_PATH.replace(
              ":id",
              getString(params, "id")
            ).replace(":idSeason", getString(params, "idSeason")),
          },
          { title: strings.edit_form_sale },
        ];
      case PATH.VIEW_FARM_FORM_PATH:
        return [
          { title: strings.farm, href: PATH.FARM_HOME_PATH },
          {
            title: nameFarm || strings.name_farm,
            href: `${PATH.FARM_HOME_PATH}/${id}/season`,
          },
          {
            title: farmSeasonName,
            href: `${PATH.FARM_HOME_PATH}/${id}/season`,
          },
          {
            title: strings.post_sale,
            href: PATH.ALL_FARM_FORM_PATH.replace(
              ":id",
              getString(params, "id")
            ).replace(":idSeason", getString(params, "idSeason")),
          },
          { title: strings.detail },
        ];
      case PATH.CREATE_FARM_PROCEDURE_PATH: {
        return [
          {
            title: strings.procedure,
            href: PATH.ALL_FARM_PROCEDURE_PATH.replace(":type", "ALL"),
          },
          { title: strings.create },
        ];
      }
      case PATH.EDIT_FARM_PROCEDURE_PATH:
        return [
          {
            title: strings.procedure,
            href: PATH.ALL_FARM_PROCEDURE_PATH.replace(":type", "ALL"),
          },
          { title: strings.edit_procedure },
        ];
      case PATH.VIEW_FARM_PROCEDURE_PATH:
        return [
          {
            title: strings.procedure,
            href: PATH.ALL_FARM_PROCEDURE_PATH.replace(":type", "ALL"),
          },
          { title: strings.view_procedure },
        ];

      case PATH.FARM_INPUT_PATH:
        return [{ title: strings.input, href: PATH.FARM_INPUT_PATH }];
      case PATH.FARM_HOME_PATH:
        return [{ title: strings.farm, href: PATH.FARM_HOME_PATH }];
      case PATH.FARM_PRODUCT_PATH:
        return [{ title: strings.product, href: PATH.FARM_PRODUCT_PATH }];
      case PATH.FARM_DETAIL_PATH: {
        const { nameFarm } = this.state;
        return [
          { title: strings.farm, href: PATH.FARM_HOME_PATH },
          { title: nameFarm || strings.name_farm },
        ];
      }
      case PATH.FARM_SEASON_CRUD_PATH:
      case PATH.VIEW_SEASON_CRUD_FARM_PATH: {
        return [
          { title: strings.farm, href: PATH.FARM_HOME_PATH },
          {
            title: nameFarm || strings.name_farm,
            href: `${PATH.FARM_HOME_PATH}/${id}`,
          },
          { title: farmSeasonName },
        ];
      }
      case PATH.CREATE_SEASON_CRUD_FARM_PATH: {
        return [
          { title: strings.farm, href: PATH.FARM_HOME_PATH },
          {
            title: nameFarm || strings.name_farm,
            href: this.replaceId(PATH.FARM_DETAIL_PATH),
          },
          { title: strings.create_seasons },
        ];
      }
      case PATH.EDIT_SEASON_CRUD_FARM_PATH: {
        return [
          { title: strings.farm, href: PATH.FARM_HOME_PATH },
          {
            title: nameFarm || strings.name_farm,
            href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
          },
          {
            title: farmSeasonName,
            href: this.replaceId(PATH.VIEW_SEASON_CRUD_FARM_PATH),
          },
          { title: strings.update_seasons },
        ];
      }
    }
    return [];
  };

  onSave = () => {
    this.editView.onSave();
  };

  onCreate = () => {
    this.createView.onCreate();
  };

  onDelete = () => {
    this.editView.onDelete();
  };

  renderHeader = () => {};
  renderFooter = () => {};

  renderMenuItem(subMenu) {
    return subMenu.map((item) => {
      let arrSubMenu = getArray(item, "subMenu", []);
      if (arrSubMenu.length > 0) {
        return _.compact([item.path, ...this.renderMenuItem(arrSubMenu)]);
      }
    });
  }
  renderContent = () => {
    const { season, nameFarm, farmSeasonName, farm } = this.state;
    switch (this.props.match.path) {
      /**viet */

      case PATH.ALL_FARM_PRODUCT_COST_PATH:
        return (
          <AllProductCost
            className="tree-menu"
            treeMenu={treeMenu}
            viewAsBlock={this.state.viewAsBlocks}
          />
        );
      case PATH.ALL_FARM_SALE_PRICE_PATH:
        return (
          <AllProductCost
            className="tree-menu"
            treeMenu={treeMenu}
            viewAsBlock={this.state.viewAsBlocks}
          />
        );
      case PATH.ALL_FARM_PACKAGING_COST_PATH:
        return (
          <AllProductCost
            className="tree-menu"
            treeMenu={treeMenu}
            viewAsBlock={this.state.viewAsBlocks}
          />
        );
      case PATH.CREATE_FARM_PRODUCT_COST_PATH:
        return <UpdateProductCost ref={(r) => (this.createView = r)} />;
      case PATH.EDIT_FARM_PRODUCT_COST_PATH:
        return <UpdateProductCost ref={(r) => (this.editView = r)} />;
      case PATH.VIEW_FARM_PRODUCT_COST_PATH:
        return <UpdateProductCost ref={(r) => (this.editView = r)} />;
      //fame-sale
      case PATH.CREATE_FARM_SALE_PRICE_PATH:
        return <UpdateSalePrice ref={(r) => (this.createView = r)} />;
      case PATH.EDIT_FARM_SALE_PRICE_PATH:
        return <UpdateSalePrice ref={(r) => (this.editView = r)} />;
      case PATH.VIEW_FARM_SALE_PRICE_PATH:
        return <UpdateSalePrice ref={(r) => (this.editView = r)} />;
      //packaing
      case PATH.VIEW_FARM_PACKAGING_COST_PATH:
        return <UpdatePackagingCost ref={(r) => (this.editView = r)} />;
      case PATH.CREATE_FARM_PACKAGING_COST_PATH:
        return <UpdatePackagingCost ref={(r) => (this.createView = r)} />;
      case PATH.EDIT_FARM_PACKAGING_COST_PATH:
        return <UpdatePackagingCost ref={(r) => (this.editView = r)} />;

      /**viet!end--- */
      case PATH.FARM_HOME_PATH:
        return <AllFarms viewAsBlock={this.state.viewAsBlocks} />;
      case PATH.FARM_PROCEDURE_PATH:
        return (
          <Procedure
            topNav={{
              isMenu: true,
              pageName: strings.farm,
              mode: "inline",
            }}
          />
        );
      case PATH.CREATE_FARM_FORM_PATH:
        return (
          <Formpost
            farm={farm}
            season={season}
            ref={(r) => (this.createView = r)}
          />
        );
      case PATH.EDIT_FARM_FORM_PATH:
        return (
          <Formpost
            farm={farm}
            season={season}
            ref={(r) => (this.editView = r)}
          />
        );
      case PATH.VIEW_FARM_FORM_PATH:
        return (
          <Formpost
            farm={farm}
            season={season}
            ref={(r) => (this.editView = r)}
          />
        );
      case PATH.ALL_FARM_FORM_PATH:
        return (
          <AllFormPost
            ref={(r) => (this.editView = r)}
            className="tree-menu"
            treeMenu={treeMenu}
          />
        );
      case PATH.CREATE_FARM_PROCEDURE_PATH:
        return <UpdateProcedure ref={(r) => (this.createView = r)} />;
      case PATH.EDIT_FARM_PROCEDURE_PATH:
        return <UpdateProcedure ref={(r) => (this.editView = r)} />;
      case PATH.VIEW_FARM_PROCEDURE_PATH:
        return <UpdateProcedure ref={(r) => (this.editView = r)} />;
      case PATH.ALL_FARM_PROCEDURE_PATH:
        return (
          <Procedure
            viewAsBlock={this.state.viewAsBlocks}
            topNav={{
              isMenu: true,
              pageName: strings.farm,
              mode: "inline",
            }}
          />
        );
      case PATH.EDIT_FARM_SEASON_PROCEDURE_PATH:
        return <UpdateProcedure ref={(r) => (this.editView = r)} />;
      case PATH.VIEW_FARM_SEASON_PROCEDURE_PATH:
        return <UpdateProcedure ref={(r) => (this.editView = r)} />;
      case PATH.ALL_FARM_SEASON_PROCEDURE_PATH:
        return (
          <Procedure
            treeMenu={this.state.menu}
            viewAsBlock={this.state.viewAsBlocks}
            topNav={{
              isMenu: true,
              pageName: strings.farm,
              mode: "inline",
            }}
          />
        );
      case PATH.FARM_DETAIL_PATH:
        return <FarmOverview nameFarm={nameFarm} />;
      case PATH.CREATE_FARM_PROCEDURE_PATH:
        break;
      // Farm season CRUD and Farm Season Overview
      case PATH.FARM_SEASON_CRUD_PATH:
      case PATH.VIEW_SEASON_CRUD_FARM_PATH:
        return (
          <FarmSeasonDetailOverview
            nameFarm={nameFarm}
            className="tree-menu"
            treeMenu={treeMenu}
          />
        );
      case PATH.CREATE_SEASON_CRUD_FARM_PATH:
        return (
          <FarmSeasonCRUD
            ref={(r) => (this.createView = r)}
            nameFarm={nameFarm}
          />
        );
      case PATH.EDIT_SEASON_CRUD_FARM_PATH:
      case PATH.VIEW_SEASON_CRUD_FARM_PATH:
        return (
          <FarmSeasonCRUD
            ref={(r) => (this.editView = r)}
            nameFarm={nameFarm}
          />
        );
      case PATH.OVERVIEW_LEDGER_FARM_PATH:
        return (
          <FarmSeasonLedger
            nameFarm={nameFarm}
            farmSeasonName={farmSeasonName}
            className="tree-menu"
            treeMenu={treeMenu}
          />
        );
      // Farm season harvest (inventory)
      case PATH.ENTER_HARVEST_FARM_PATH:
        return (
          <FarmSeasonEnterHarvest
            nameFarm={nameFarm}
            farmSeasonName={farmSeasonName}
            className="tree-menu"
            treeMenu={treeMenu}
          />
        );
      case PATH.OVERVIEW_SEASON_HARVEST_FARM_PATH:
        return (
          <FarmSeasonHarvestOverview
            nameFarm={nameFarm}
            farmSeasonName={farmSeasonName}
            className="tree-menu"
            treeMenu={treeMenu}
          />
        );
      case PATH.EDIT_SEASON_HARVEST_FARM_PATH:
        return (
          <FarmSeasonHarvest
            ref={(r) => (this.editView = r)}
            nameFarm={nameFarm}
            farmSeasonName={farmSeasonName}
          />
        );
      case PATH.EDIT_SEASON_EXPECTED_HARVEST_FARM_PATH:
        return (
          <FarmSeasonExpectedHarvest
            ref={(r) => (this.editView = r)}
            nameFarm={nameFarm}
            farmSeasonName={farmSeasonName}
          />
        );
    }
  };

  render() {
    return (
      <AppContentWrapper
        showMainSideBar={false}
        topNav={{
          isMenu: true,
          pageName: strings.farm,
          slot: menuHeader,
        }}>
        <ContainerNavStyled>
          <FarmNav
            breadcrumb={this.getBreadcrumb()}
            changeViewStyle={this.changeViewStyle}
            className={`profile-nav ${this.state.hidden && "shadow"}`}
            onCreate={this.onCreate}
            onDelete={this.onDelete}
            onSave={this.onSave}
          />
          <div className="content none-menu"> {this.renderContent()}</div>
        </ContainerNavStyled>
      </AppContentWrapper>
    );
  }
}

export default compose(
  withRouter,
  connect(
    null,
    { getFarmProfile, getFarmSeasonCRUD, createProcedure, getType },
    null
  )
)(Farm);
