import React from "react";
import {} from "~/state/ducks/appApis/actions";
import { updateProfile, createProfile } from "~/state/ducks/authUser/actions";
import { connect } from "react-redux";
import {
  getProvinces,
  getDistrict,
  getBranches,
  deletePersonalProfile,
} from "~/state/ducks/appApis/actions";
import _ from "lodash";
import {} from "../styles";

import { Layout } from "antd";

import TreeMenu from "~/views/presentation/ui/navigation/Sidebar";

import FarmSeasonHarvest from "./FarmSeasonEnterHarvest";
import { withRouterInnerRef } from "~/views/container/commons";

import { ContentStructureStyle } from "../styles";

class FarmSeasonEnterHarvestOverview extends React.PureComponent {
  constructor(props) {
    super(props);
    const {} = this.props;
    this.state = { farmSeason: {} };
  }

  componentDidMount() {}

  renderTable() {
    const { editable } = this.props;
    return (
      <div className="farm-season__stack col-12" style={{ flex: "0 0 0" }}>
        <div className="farm-season__stack col-12">
          <FarmSeasonHarvest
            editable={editable}
            nameFarm={this.props.nameFarm}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <ContentStructureStyle
          style={{ backgroundColor: "#f5f5f5 !important" }}>
          <div className="right-content">{this.renderTable()}</div>
        </ContentStructureStyle>
        <TreeMenu
          className={this.props.className}
          menus={this.props.treeMenu}
        />
      </div>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      getDistrict,
      getBranches,
      updateProfile,
      createProfile,
      getProvinces,
      deletePersonalProfile,
    },
    null,
    { forwardRef: true }
  )(FarmSeasonEnterHarvestOverview)
);
