import React from "react";
import styled from "styled-components";
// import strings from "~/localization";
// import LogoIntro from "~/static/images/logo-intro.png";
import { Background } from "~/static/images";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url(${Background});
  padding-top: 100px;
  div img {
    width: 320px;
  }
  .intro {
    margin-top: 30px;
    margin-bottom: 100px;
    p {
      font-weight: 500;
    }
  }
  .copyright {
    display: block;
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 100%;
  }
`;
export default class IntroduceForm extends React.PureComponent {
  render() {
    return (
      <Container >
        {/* <div className='d-flex justify-content-center'><img src={LogoIntro}/></div>
       <div className='intro' dangerouslySetInnerHTML={{__html: strings.intro}}></div>
       <div className='text-center copyright'><span className='font-weight-bold'>{strings.copy_right}</span></div> */}
      </Container>
    );
  }
}
