import AppContentWrapper from "~/views/presentation/ui/container/AppContentWrapper";
import styled from "styled-components";
import _ from "lodash";
import strings from "~/localization";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import React from "react";

import { ActivityItem, EventItem, MenuAction, MessageItem, OrderItem } from "~/views/container/components";

import { Performance } from "~/views/container/components";

import { WrapDashboardContent } from "~/views/container/Farm/styles";
import WeatherChart from "~/views/presentation/ui/chart/weatherChart";
import { Alert, TitleCustom } from "~/views/presentation/ui/dashboard";
const ChartsStyled = styled.div``;

const WrapResponsive = styled.div`
  display: flex;
  flex-wrap: wrap;
  .item {
    padding: 0 16px;
    width: calc(100% / 4 - 10px);
    @media (max-width: 2948px) {
      width: calc(100% / 3 - 10px);
    }
    @media (max-width: 2348px) {
      width: calc(100% / 2 - 10px);
    }
    @media (max-width: 1664px) {
      width: calc(100% / 1 - 10px);
    }
    margin: 0px 5px 10px 5px;
  }
`;

const dataSouceNotice = [
  // {
  //   message: "Thông báo từ hệ thống AgriTech",
  //   type: "info",
  // },
  // {
  //   message: "Thông báo từ hệ thống AgriTech",
  //   type: "warning",
  // },
  // {
  //   message: "Thông báo từ hệ thống AgriTech",
  //   type: "success",
  // },
  // {
  //   message: "Thông báo từ hệ thống AgriTech",
  //   type: "error",
  // },
];

class Reports extends React.Component {
  renderFarmSeason() {
    return (
      <ChartsStyled className="col-12 d-flex flex-row pr-0 pl-0">
        <div className="farm-season__table col-8">
          <TitleCustom title={strings.seasons} slot={<MenuAction />} />
          <WrapResponsive className="row p-0">
            <div className="item">
              <WeatherChart />
            </div>
            <div className="item">
              <WeatherChart />
            </div>
            <div className="item">
              <WeatherChart />
            </div>
            <div className="item">
              <WeatherChart />
            </div>
          </WrapResponsive>
        </div>
        <div className="col-4 d-flex flex-column justify-content-start align-items-start">
          <div className="bg-fuchsia farming-calendar mt-1 shadow w-100">
            <h4 className="title">{strings.farming_history}</h4>
            <div className="list-item">
              <ActivityItem></ActivityItem>
              <ActivityItem></ActivityItem>
              <ActivityItem></ActivityItem>
            </div>
          </div>
          <div className="bg-fuchsia farming-calendar mt-5 shadow w-100">
            <h4 className="title">{strings.order}</h4>
            <div className="list-item">
              <OrderItem></OrderItem>
              <OrderItem></OrderItem>
              <OrderItem></OrderItem>
            </div>
          </div>
          <div className="bg-fuchsia farming-calendar mt-5 shadow w-100">
            <h4 className="title">{strings.messages_management}</h4>
            <div className="list-item">
              <MessageItem></MessageItem>
              <MessageItem></MessageItem>
              <MessageItem></MessageItem>
            </div>
          </div>
          <div className="bg-fuchsia farming-calendar mt-5 shadow w-100">
            <h4 className="title">{strings.event}</h4>
            <div className="list-item">
              <EventItem></EventItem>
              <EventItem></EventItem>
              <EventItem></EventItem>
            </div>
          </div>
        </div>
      </ChartsStyled>
    );
  }
  renderPerformance() {
    return (
      <div className="col-12">
        <Performance title={strings.farm_performance} info={this.props.info} />
      </div>
    );
  }

  renderNotification() {
    return (
      <div className="col-12">
        <Alert dataSouce={dataSouceNotice} />
      </div>
    );
  }

  render() {
    const {} = this.props;
    return (
      <AppContentWrapper
        showMainSideBar={false}
        topNav={{
          pageName: strings.applications,
        }}
      >
        <WrapDashboardContent>
          {/* {this.renderNotification()}
          {this.renderPerformance()}
          {this.renderFarmSeason()} */}
          <h3> {strings.feature_development}</h3>
        </WrapDashboardContent>
      </AppContentWrapper>
    );
  }
}

export default compose(withRouter)(Reports);
