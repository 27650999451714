import React from "react";

import { message } from "antd";
import _ from "lodash";
// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CREATE_SEASON_CRUD_FARM_PATH, FARM_DETAIL_PATH, VIEW_SEASON_CRUD_FARM_PATH } from "~/configs/routesConfig";
// config || common
import { showError } from "~/configs/ServerErrors";
import strings from "~/localization";
import {
    deleteFarmSeasonCRUD,
    getAllProfiles,
    getFarmSeasonCRUD,
    getSearchMaterialInventories,
    getStrockQuantity,
} from "~/state/ducks/appApis/actions";
import { createFarmSeasonCRUD, updateFarmSeasonCRUD } from "~/state/ducks/authUser/actions";
import ActivityForm from "~/views/container/ActivityForm";
// component
import FarmSeasonCRUDForms from "~/views/container/Farm/FarmSeasonCRUDForms";
import { getArray, getNumber, getObject, getString } from "~/views/utilities/helpers/utilObject";
import styled from "styled-components";
import moment from "moment";
import { StepsFormContainerStyle } from "~/views/container/styles";
import { cartSelectors, cartActions } from "~/state/ducks/cart";
import { POActions } from "~/state/ducks/po";

import { withRouterInnerRef } from "~/views/container/commons";
const selectNull = { key: -1, value: " ", label: strings.default_choice };

const emptyObject = () => ({
    name: undefined,
    sowingDate: undefined,
    harvestDate: undefined,
    note: undefined,
    solutions: undefined,
    status: undefined,
    grossArea: undefined,
    grossYield: undefined,
    seedDensity: undefined,
    seedDensityDetail: undefined,
    farmId: undefined,
    productId: undefined, //
    percentSizeId: undefined,
    certifycateOfLandIds: [], // thửa đất
    seasonProcessIds: [],
    seasonProcesses: [],
    materials: [], // Đầu vào step 3]
    cropsLIst: [],
    laborCosts: [],
    otherCosts: [],
    nameFarm: undefined,

    errors: {},
});

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
};

const scrollToBottom = () => {
    window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
    });
};

class FarmSeasonCRUD extends React.Component {
    constructor(props) {
        super(props);
        let editable = true;
        let isCreateProfile = false;
        const { match } = this.props;
        switch (match.path) {
            case VIEW_SEASON_CRUD_FARM_PATH:
                editable = false;
                break;
            case CREATE_SEASON_CRUD_FARM_PATH:
                isCreateProfile = true;
                break;
            default:
                break;
        }
        this.state = {
            editable,
            isCreateProfile,
            farmSeason: {},
            farmProfiles: [],
            farmProfile: {},
        };
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    // _handleFecthStockQuantity = (id) => {
    //   const { getStrockQuantity, setStockQuantity, stockQuantity } = this.props

    //   getStrockQuantity(id).then(({ res }) => {
    //
    //     row.quantityPlan = getNumber(res, "totalQuantityPlan", 0)
    //     row.totalQuantityOrder = getNumber(res, "totalQuantityOrder", 0)
    //
    //     // setStockQuantity
    //   })
    // }
    checkPrice = (item) => {
        let price = getNumber(item, "salePrice", 0) ? getNumber(item, "salePrice", 0) : getNumber(item, "price", 0);
        return price;
    };
    caculatorPrice = (pricingOptions, qty) => {
        console.log("InputForm -> caculatorPrice -> pricingOptions", pricingOptions);
        let result = { price: 0, id: null };
        const pricingRanges = getArray(pricingOptions[0], "pricingRanges", []);
        if (pricingRanges.length) {
            _.forEach(pricingRanges, (item) => {
                console.log("InputForm -> caculatorPrice -> item", item);
                const fromValue = getNumber(item, "fromValue", 0);
                const toValue = getNumber(item, "toValue", 0);
                if (!toValue) {
                    result.price = this.checkPrice(item);
                    result.id = item.id;
                    return false;
                }
                if (qty >= fromValue && qty <= toValue) {
                    result.price = this.checkPrice(item);
                    result.id = item.id;
                    return false;
                }
            });
        }
        console.log("InputForm -> caculatorPrice -> result", result);
        return result;
    };
    onDelete() {
        const { deleteFarmSeasonCRUD, match, history } = this.props;
        let id = match.params.idSeason;
        deleteFarmSeasonCRUD(id)
            .then(() => {
                message.success(strings.delete_profile_success);
                history.push(FARM_DETAIL_PATH.replace(":id", match.params.id));
            })
            .catch((err) => {
                return showError(err);
            });
    }
    fetchStock = async (id, unit) => {
        return await this.props
            .getStrockQuantity(id, unit)
            .then(({ res }) => {
                return {
                    quantityPlan: getNumber(res, "totalQuantityPlan", 0),
                    totalQuantityOrder: getNumber(res, "totalQuantityOrder", 0),
                };
            })
            .catch((err) => console.error("err-----", err));
    };
    fechProduct = async (cata, mau) => {
        let params = ``;
        if (cata) params = `manufacturerId=${cata}&type=MATERIAL`;
        if (mau) params = `categoryId=${mau}&type=MATERIAL`;
        if (cata && mau) params = `manufacturerId=${cata}&categoryId=${mau}&type=MATERIAL`;
        return await this.props
            .getSearchMaterialInventories(params)
            .then(({ res }) => {
                return [
                    selectNull,
                    ...getArray(res, undefined, []).map((item) => {
                        return {
                            ...item,
                            value: item.id ? item.id : item.id,
                            id: item.id ? item.id : item.id,
                            label: item.name,
                            pricingOptions: item.pricingOptions,
                        };
                    }),
                ];
            })
            .catch((err) => console.error("err-----", err));
    };

    componentDidMount() {
        const { clearCart } = this.props;
        clearCart();
        this.fetchFarmSeason();
        this.fetchFarmProfile();
    }

    componentWillUnmount() {
        this.setState({ farmSeason: {} });
    }

    getMapObjectToResponseFarmSeason = () => {
        const { farmSeason, isCreateProfile } = this.state;
        let cropListTemp = getArray(farmSeason, "cropsList", []);
        let processTemp = getArray(farmSeason, "seasonProcesses", []);
        let classificationsTemp = getArray(farmSeason, "classifications", []);
        let errorsTemp = getObject(farmSeason, "errors", {});
        let errorsValidate = getObject(farmSeason, "errorsValidate", {});
        if (cropListTemp.length === 0) {
            message.error(strings.need_at_least_one_crop_for_this_season, 3);
            scrollToBottom();
            return;
        }
        if (Object.keys(errorsValidate).length !== 0) {
            if (!_.isEmpty(errorsValidate)) {
                message.error(Object.values(errorsValidate)[0], 3);
                scrollToTop();
                return;
            }
        }
        if (Object.keys(errorsTemp).length !== 0) {
            if (!_.isEmpty(errorsTemp)) {
                message.error(Object.values(errorsTemp)[0], 3);
                scrollToTop();
                return false;
            }
        }
        if (processTemp.length !== 0) {
            for (const [i, v] of processTemp.entries()) {
                if (getNumber(v, "processId", 0) <= 0) {
                    message.error(strings.please_select_a_process_name, 3);
                    return;
                }
                if (getString(v, "startDate", "") === "") {
                    message.error(strings.please_select_a_start_date, 3);
                    return;
                }
            }
        }
        if (classificationsTemp.length !== 0)
            if (
                classificationsTemp[classificationsTemp.length - 1][
                    `validateOption${classificationsTemp.length - 1}`
                ] !== undefined ||
                classificationsTemp[classificationsTemp.length - 1][
                    `validateValue${classificationsTemp.length - 1}`
                ] !== undefined
            ) {
                message.error(strings.please_enter_a_valid_percent_allocation, 3);
                scrollToTop();
                return false;
            }
        if (classificationsTemp.length === 0) {
            message.error(strings.please_enter_a_valid_percent_allocation, 3);
            scrollToTop();
            return false;
        }

        if (!_.isEmpty(farmSeason.errors)) return farmSeason;

        const { id } = this.props.match.params;
        let cropsListOne = getArray(farmSeason, "cropsList", [])[0];

        let arrTempClassification = classificationsTemp.map((item, index) => {
            return {
                typeId: item[`typeId${index}`],
                value: item[`value${index}`],
            };
        });
        _.remove(arrTempClassification, (item) => item.typeId === undefined);

        let arrSeasonProcessesTemp = getArray(farmSeason, "seasonProcesses", []);
        _.remove(arrSeasonProcessesTemp, (item) => getNumber(item, "processId", 0) <= 0);

        let farmSeasonInfo = {
            //step 1
            farmId: parseInt(id),
            name: getString(farmSeason, "name", ""),
            sowingDate: farmSeason.sowingDate,
            harvestDate: farmSeason.harvestDate,

            status: getString(farmSeason, "status", "ACTIVATED"),
            seedDensity: {
                value: farmSeason.seedDensity,
                // unitName: strings.pillar_unit,
                unitName: farmSeason.unitForDensityLabel,
                unitId: getNumber(farmSeason, "unitForDensity"),
            },

            classifications: arrTempClassification,

            // only product - crops:
            // cropsList.
            seedDensityDetail: {
                value: getNumber(cropsListOne, "seedDensityDetail", undefined),
                unitName: strings.tree_unit,
            },
            productId: getNumber(cropsListOne, "productId", undefined),
            grossArea: {
                value: getNumber(cropsListOne, "grossArea", undefined),
                unitName: strings.hectare,
            },
            certifycateOfLandIds:
                getArray(cropsListOne, "certifycateOfLandIds", []).length === 0
                    ? undefined
                    : getArray(cropsListOne, "certifycateOfLandIds", []),
            grossYield: {
                value: getNumber(cropsListOne, "grossYield", undefined),
                unitName: strings.ton,
            },
            note: getString(cropsListOne, "note", ""),
            solutions: getString(cropsListOne, "solutions", ""),
            // step 2
            seasonProcessIds: arrSeasonProcessesTemp.map((item) => item.processId),
            seasonProcesses: arrSeasonProcessesTemp.map((item) => {
                if (isCreateProfile)
                    return {
                        status: item.status,
                        startDate: item.startDate,
                        processId: item.processId,
                        processName: item.processName,
                        note: item.note,
                    };
                return {
                    status: item.status,
                    ratings: undefined,
                    startDate: item.startDate,
                    processId: item.processId,
                    processName: item.processName,
                    note: item.note,
                };
            }),
            // step 3

            materials: getArray(farmSeason, "materials", []).map((item) => {
                return {
                    productId: getNumber(item, "materialId", null),
                    supplierId: getNumber(item, "supplierId", null),
                    quantity: getNumber(item, "quantity", null),
                    unitId: getNumber(item, "unitIdApi", null),
                    categoryId: parseInt(item.typeId) || null,
                    orderDate: item.orderDate,
                    receiveDate: item.receiveDate,
                    price: {
                        value: item.price,
                        unit: strings.unit_price,
                    },
                    amount: {
                        value: item.amount,
                        unit: strings.unit_price,
                    },
                    // unitId: item.unitId,
                };
            }),
            laborCosts: getArray(farmSeason, "laborCosts", []).map((item) => {
                return {
                    code: parseInt(item.code),
                    job: getString(item, "job", ""),
                    name: getString(item, "name", ""),
                    note: getString(item, "note", ""),
                    salaryPerMonth: getNumber(item, "salaryPerMonth", ""),
                    salaryPerSeason: getNumber(item, "salaryPerSeason", ""),
                    workingPerMonth: getNumber(item, "workingPerMonth", ""),
                    workingPerSeason: getNumber(item, "workingPerSeason", ""),
                };
            }),
            otherCosts: getArray(farmSeason, "otherCosts", []).map((item) => {
                return {
                    amount: {
                        unit: strings.unit_price,
                        value: item.amount,
                    },
                    description: item.description,
                    note: item.note,
                    price: {
                        unit: strings.unit_price,
                        value: item.price,
                    },
                    quantity: item.quantity,
                };
            }),
        };
        return farmSeasonInfo;
    };

    onSave() {
        const { match, history, updateFarmSeasonCRUD } = this.props;
        const farmSeason = this.getMapObjectToResponseFarmSeason();
        if (!farmSeason) return;
        const errorMess = getObject(farmSeason.errors, undefined, {});

        if (!_.isEmpty(errorMess)) {
            message.error(Object.values(farmSeason.errors)[0]);
            return;
        }
        const response = {
            id: +match.params.idSeason,
            ...this.getMapObjectToResponseFarmSeason(),
        };
        const { addPO, cartitems, clearCart } = this.props;
        if (getArray(cartitems, undefined, []).length)
            addPO(cartitems)
                .then((res) => {
                    message.success("Tạo đơn hàng thành công");
                    clearCart();
                })
                .catch((error) => {
                    showError(error);
                });
        updateFarmSeasonCRUD(response)
            .then(({}) => {
                message.success(strings.update_farm_successful);
                this.setState({ farmSeason: emptyObject() });
                history.push(
                    VIEW_SEASON_CRUD_FARM_PATH.replace(":idSeason", match.params.idSeason).replace(
                        ":id",
                        match.params.id
                    )
                );
            })
            .catch((err) => {
                console.error("FarmSeasonCRUD -> onEdit -> err", err);
                return showError(err);
            });
    }
    onCreate = () => {
        const { history, createFarmSeasonCRUD, match } = this.props;
        const farmSeason = this.getMapObjectToResponseFarmSeason();
        if (!farmSeason) return;

        const errorMess = getObject(farmSeason.errors, undefined, {});

        if (!_.isEmpty(errorMess)) {
            message.error(Object.values(farmSeason.errors)[0]);
            return;
        }
        const { addPO, cartitems, clearCart } = this.props;
        if (getArray(cartitems, undefined, []).length)
            addPO(cartitems)
                .then((res) => {
                    message.success("Tạo đơn hàng thành công");
                    clearCart();
                })
                .catch((error) => {
                    showError(error);
                });
        createFarmSeasonCRUD(farmSeason)
            .then(({}) => {
                message.success(strings.create_farm_season_successful);
                history.push(
                    FARM_DETAIL_PATH.replace(":idSeason", match.params.idSeason).replace(":id", match.params.id)
                );
                this.setState({ farmSeason: emptyObject() });
            })
            .catch((err) => {
                console.error("FarmSeasonCRUD -> onCreate -> err", err);
                return showError(err);
            });
    };

    fetchFarmProfile = () => {
        const { match, getAllProfiles } = this.props;
        getAllProfiles("FARM_PROFILE")
            .then(async ({ res }) => {
                this.setState({ farmProfiles: getArray(res, undefined, []) });
                const index = _.findIndex(getArray(res, undefined, []), (item) => item.id == match.params.id);
                if (index !== -1) {
                    this.setState({ farmProfile: getArray(res[index], undefined, []) });
                }
            })
            .catch((err) => {
                console.error("FarmSeasonCRUD -> getAllProfiles -> err", err);
                return showError(err);
            });
    };

    fetchFarmSeason = () => {
        const { match, getFarmSeasonCRUD } = this.props;
        let seasonId = +match.params.idSeason;
        // Create farm season
        if (_.isNaN(seasonId)) {
            this.setState({ farmSeason: emptyObject() });
            return;
        }

        // Update farm season
        getFarmSeasonCRUD(seasonId)
            .then(async ({ res }) => {
                let classificationsTemp = getArray(res, "classifications", []);
                let classifications = _.sortBy(classificationsTemp, ["typeId"]);

                let mappedObject = {
                    // ...res,
                    //step 1
                    farmId: getNumber(res, "farmId"),
                    name: getString(res, "name", ""),
                    sowingDate: res.sowingDate,
                    harvestDate: res.harvestDate,

                    status: getString(res, "status", "ACTIVATED"),
                    seedDensity: getNumber(res, "seedDensity.value"),
                    unitForDensity: getNumber(res, "seedDensity.unitId"),
                    farmGrossArea: getNumber(res, "grossArea.value", undefined),
                    farmGrossYield: getNumber(res, "grossYield.value", undefined),

                    classifications: classifications.map((item, index) => {
                        // Cần map data lại cho phần load lên
                        // từ dạng:
                        // [
                        //   {
                        //     "farmingSeasonId": 0,
                        //     "typeId": 1700,
                        //     "value": 100
                        //   }
                        // ]
                        // sang dạng:
                        // [
                        //   {
                        //     "typeId0": 1700,
                        //     "value0": 80,
                        //     "percentMax0": 100,
                        //     "dataOptions0": [{...}],
                        //   }
                        // ]
                        return {
                            id: index,
                            ["typeId" + index]: item.typeId,
                            ["value" + index]: item.value,
                            ["percentMax" + index]: 100,
                        };
                    }),

                    cropsList: [
                        {
                            productId: getNumber(res, "productId"),
                            grossArea: getNumber(res, "grossArea.value"),
                            certifycateOfLandIds: getArray(res, "certifycateOfLandIds", []),
                            seedDensityDetail: getNumber(res, "seedDensityDetail.value"),
                            grossYield: getNumber(res, "grossYield.value"),
                            note: getString(res, "note", ""),
                            solutions: getString(res, "solutions", ""),
                        },
                    ],

                    // step 2
                    seasonProcesses: getArray(res, "seasonProcesses", []).map((item) => {
                        let status = getString(item, "status", "");
                        let startDate = moment.utc(item.startDate);
                        let currentDate = moment.utc(moment().format());
                        let isNotRemove = false;
                        if (currentDate.isAfter(startDate)) {
                            isNotRemove = true;
                        }
                        let numberStepCurrent = _.findIndex(getArray(item, "steps", []), {
                            id: getNumber(item.currentStep, "id"),
                        });
                        let stepFinish = numberStepCurrent !== -1 ? numberStepCurrent : 0;
                        return {
                            ...item,
                            statusView: `${
                                status === "FINISH"
                                    ? strings.FINISHED
                                    : status === "RUNNING"
                                    ? strings.running
                                    : strings.not_run
                            } (${stepFinish}/${getNumber(item, "stepsNumber", 0)})`,
                            status: status || strings.status_process_default,
                            startDate: item.startDate,
                            processId: item.processId,
                            processName: item.processName,
                            ratings: item.ratings ? item.ratings : strings.rating_default,
                            procedureStepId: getString(item.currentStep, "name", ""),
                            note: item.note,
                            stepsNumber: getNumber(item, "stepsNumber", 0),
                            isNotRemove,
                        };
                    }),

                    // step 3
                    laborCosts: getArray(res, "laborCosts", []).map((item) => item),
                    otherCosts: getArray(res, "otherCosts", []).map((item) => {
                        return {
                            ...item,
                            price: getNumber(item, "price.value"),
                            amount: getNumber(item, "amount.value"),
                        };
                    }),
                };

                let materials = async (res) => {
                    return Promise.all(
                        getArray(res, "materials", []).map(async (item, index) => {
                            let amountPick = 0;
                            let status = "";
                            let quantityPlan = 0;
                            if (getNumber(item, "productId", 0)) {
                                const quanti = await this.fetchStock(
                                    getNumber(item, "productId", 0),
                                    getNumber(item, "unitId", null)
                                );
                                quantityPlan =
                                    getNumber(quanti, "totalQuantityPlan", 0) -
                                    getNumber(quanti, "totalQuantityOrder", 0);
                                if (quantityPlan < 0) quantityPlan = 0;
                                amountPick = getNumber(item, "quantity", 0) - quantityPlan;
                            }
                            if (amountPick === 0) status = strings.approve_product;
                            if (amountPick !== 0) status = strings.not_approve_product;
                            let pricingRangeId = null
                            const materialInventoryFilter = await this.fechProduct(
                                getNumber(item, "supplierId"),
                                getNumber(item, "categoryId")
                            );
                            const tempt = getArray(materialInventoryFilter, undefined, [])
                                .map((val) => {
                                    if (val.id === getNumber(item, "productId", null)) return val;
                                })
                                .filter((val) => val !== undefined);
                            if (getArray(tempt, undefined, []).length >= 0) {
                            pricingRangeId = this.caculatorPrice(getArray(tempt[0], "pricingOptions", []), item.quantity).id;

                            }

                            return {
                                ...item,
                                price: getNumber(item, "price.value"),
                                amount: getNumber(item, "amount.value"),
                                // quantityPlan: getNumber(quanti, 'quantityPlan', 0),
                                quantityPlan,
                                unitId: getString(item, "unitName", ""),
                                unitIdApi: getNumber(item, "unitId", null),
                                amountPick: amountPick > 0 ? amountPick : 0,
                                // status: item.status === 'NO_ORDER' ? strings.NO_ORDER : strings.ORDERED,
                                // action: item.status === 'NO_ORDER' ? strings.ORDER : '',
                                typeId: getNumber(item, "categoryId"),
                                status,
                                materialInventoryFilter,
                                materialId: getNumber(item, "productId", null),
                                info: _.find(getArray(materialInventoryFilter, undefined, []), (val) => {
                                    return val.id === getNumber(item, "productId", null);
                                }),
                                pricingRangeId: pricingRangeId,
                            };
                        })
                    );
                };
                const mapMate = await materials(res);

                this.setState({ farmSeason: { ...mappedObject, materials: mapMate } });
            })
            .catch((err) => {
                console.error("FarmSeasonCRUD -> getFarmSeasonCRUD -> err", err);
                return showError(err);
            });
    };

    render() {
        return (
            <StepsFormContainerStyle>
                <div className="profile-view">
                    <FarmSeasonCRUDForms
                        editable={this.state.editable}
                        farmProfile={this.state.farmProfile}
                        farmProfiles={this.state.farmProfiles}
                        info={this.state.farmSeason}
                        isCreateProfile={this.state.isCreateProfile}
                        onCreate={this.onCreate}
                        showFooter={false}
                        showHeader={false}
                    />
                </div>
                <div className="events-view">
                    <ActivityForm editable={this.state.editable} info={this.state.farmSeason} />
                </div>
            </StepsFormContainerStyle>
        );
    }
}

const mapstate = (state) => {
    const { authUser } = state;
    return {
        cartitems: cartSelectors.getCartItems(state),
        subtotal: cartSelectors.getTotalAmountCart(state),
    };
};
export default withRouterInnerRef(
    connect(
        mapstate,
        {
            createFarmSeasonCRUD,
            getFarmSeasonCRUD,
            updateFarmSeasonCRUD,
            getStrockQuantity,
            getSearchMaterialInventories,
            deleteFarmSeasonCRUD,
            getAllProfiles,
            addPO: POActions.addOrder,
            clearCart: cartActions.clearCart,
            //get more
        },
        null,
        {
            forwardRef: true,
        }
    )(FarmSeasonCRUD)
);
