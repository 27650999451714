import React from "react";
import { connect } from "react-redux";
import strings from "~/localization";
import { showError } from "~/configs/ServerErrors";
import { getType } from "~/state/ducks/appApis/actions";
import { getString, getArray } from "~/views/utilities/helpers/utilObject";
import { createProcedure } from "~/state/ducks/typeProcedure/action";
import _ from "lodash";
import * as PATH from "~/configs/routesConfig";
import AllProcedure from "./AllProcedure";
import { withRouterInnerRef } from "~/views/container/commons";
import MenuSideBar from "~/views/presentation/ui/navigation/MenuSideBar";
import TreeMenu from "~/views/presentation/ui/navigation/Sidebar";
import { ContainerNavStyled } from "../../Navigation/styles";

class Procedure extends React.Component {
  constructor(props) {
    super(props);
    const { idSeason } = this.props.match.params;
    this.state = {
      menu: this.props.treeMenu,
      loading: false,
      showLeftMenu: true,
      device: window.innerWidth,
      path: idSeason
        ? PATH.ALL_FARM_SEASON_PROCEDURE_PATH
        : PATH.ALL_FARM_PROCEDURE_PATH,
    };
  }

  handleHiddenMenu = (e) => {
    this.setState({ showLeftMenu: e });
  };

  componentDidMount() {
    this.fetchType();

    if (window.innerWidth < 1000) {
      this.setState({ showLeftMenu: false });
    }
  }

  renderContent = () => {
    switch (this.props.match.path) {
      case this.state.path:
        return <AllProcedure viewAsBlock={this.props.viewAsBlock} />;
      default:
        return null;
    }
  };

  renderMenu = () => {
    if (this.state.menu.length)
      return (
        <MenuSideBar
          menus={this.state.menu}
          handleHiddenMenu={this.handleHiddenMenu}
          showLeftMenu={this.state.showLeftMenu}
        />
      );
    else
      return (
        <TreeMenu
          menus={this.state.menu}
          handleHiddenMenu={this.handleHiddenMenu}
          showLeftMenu={this.state.showLeftMenu}
        />
      );
  };

  fetchType = () => {
    const { getType, match } = this.props;

    if (match.params.idSeason) {
      this.props.createProcedure(this.state.menu);
      return;
    }
    let menu = [];

    this.setState({ loading: true });

    menu.push({
      key: this.state.path,
      name: strings.all,
      path: this.state.path
        .replace(":type", "ALL")
        .replace(":idSeason", match.params.idSeason)
        .replace(":id", match.params.id),
    });
    getType("CULTIVATION_PROCESS")
      .then(({ res }) => {
        getArray(res, undefined, []).map((val) => {
          menu.push({
            key: this.state.path,
            name: getString(val, "name", ""),
            path: this.state.path
              .replace(":type", val.id)
              .replace(":idSeason", match.params.idSeason)
              .replace(":id", match.params.id),
          });
        });
        this.props.createProcedure(menu);
        this.setState({ menu, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        showError(err);
      });
  };

  render() {
    return (
      <ContainerNavStyled>
        <div>{this.state.menu && this.renderMenu()}</div>
        <div
          style={{ top: 0 }}
          className={`content ${
            (!this.state.showLeftMenu || this.state.device < 1024) &&
            "none-menu"
          }`}
          onKeyDown={(e) => {
            if (e.keyCode === 13) e.preventDefault();
          }}>
          {this.renderContent()}
        </div>
      </ContainerNavStyled>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      createProcedure,
      getType,
    },
    null,
    {
      forwardRef: true,
    }
  )(Procedure)
);
