import _ from 'lodash';
import queryString from 'query-string';

/**
 * parse object to query params
 * {id: 8, idSecondary:321} ===> ?id=123&idSecondary=321
 * @param {*} obj
 * @param {*} startWith
 */
function parseObjToQuery(obj = {}, startWith = '?') {
  if (_.isNil(obj)) return '';
  let keys = Object.keys(obj).filter((key) => !_.isNil(obj[key]) && !(_.isString(obj[key]) && obj[key].trim().length === 0));
  let params = {};

  (keys || []).map((key) => (params = {...params, [key]: obj[key]}));
  console.error(JSON.stringify(params));
  return startWith + queryString.stringify(params, {skipEmptyString: true});
}

export default parseObjToQuery;
