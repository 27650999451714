import React from "react";
import { compose, withHandlers, lifecycle, withState } from "recompose";
import { connect } from "react-redux";

import { showError } from "~/configs/ServerErrors";
import strings from "~/localization";
import { message } from "antd";
import UtilDate from "~/views/utilities/helpers/UtilDate";

import { getAllForm } from "~/state/ducks/authUser/actions";
import {
  getString,
  getNumber,
  mapArrayObjectToAPI,
  getArray,
} from "~/views/utilities/helpers/utilObject";

import { PROFILE_TYPES, PROCESS_RATING, PUBLISHSTATUS } from "~/configs/const";
import UITable from "~/views/presentation/ui/tables/Table";
import { withRouter } from "react-router-dom";
import { VIEW_FARM_FORM_PATH } from "~/configs/routesConfig";
import styled from "styled-components";

const Wrapred = styled.div`
  background-color: white !important;
  width: 100%;
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  td span{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
const CHANGE_NAME = [
  {
    currName: "code",
    changeName: "number_vote",
  },
  {
    currName: "salerName",
    changeName: "offering_sell",
  },

  {
    currName: "total",
    changeName: "appliedNumber",
  },

  {
    currName: "grossArea",
    changeName: "acreage_farming",
  },
  {
    currName: "publishDate",
    changeName: "date_buy_register",
  },

  {
    currName: "durationDate",
    changeName: "time_buy_register",
  },

  {
    currName: "status ",
    changeName: "status",
  },
];
class AllForm extends React.PureComponent {
  mapStatus(status) {
    switch (status) {
      case PUBLISHSTATUS.CREATING:
        return strings.creating;
      case PUBLISHSTATUS.WAITING_FOR_APPROVAL:
        return strings.waiting_for_approval;
      case PUBLISHSTATUS.DELETED:
        return strings.deleted;
      case PUBLISHSTATUS.DEACTIVATED:
        return strings.deactive;
      case PUBLISHSTATUS.ACTIVATED:
        return strings.actived;
      case PUBLISHSTATUS.APPROVED:
        return strings.approved;
      case PUBLISHSTATUS.REJECTED:
        return strings.rejected;
      case PUBLISHSTATUS.CONNECTED:
        return strings.conected;
      case PUBLISHSTATUS.EXPIRED:
        return strings.expired;
      default:
        return strings.creating;
    }
  }
  getProfileType = (type) => {
    switch (type) {
      case PROFILE_TYPES.PERSONAL_PROFILE:
        return strings.persional_profile;
      case PROFILE_TYPES.FARM_PROFILE:
        return strings.farm_profile;
      case PROFILE_TYPES.ENTERPRISE:
        return strings.enterprise_profile;
      case PROFILE_TYPES.COOPERATIVE:
        return strings.cooperative_profile;
      case PROFILE_TYPES.COOPERATIVE_VENTURE:
        return strings.cooperative_venture_profile;
      case PROFILE_TYPES.ORGANIZATION:
        return strings.organization_profile;
      default:
        return "";
    }
  };
  getTypeProcess = (type) => {
    switch (type) {
      case PROCESS_RATING.EXCELLENT:
        return strings.excellent;
      case PROCESS_RATING.VERY_GOOD:
        return strings.very_good;
      case PROCESS_RATING.GOOD:
        return strings.good;
      case PROCESS_RATING.FAIR:
        return strings.fair;
      case PROCESS_RATING.POOR:
        return strings.poor;

      default:
        return "None";
    }
  };
  handleViewProfile = (profile) => {
    const { history, match } = this.props;
    const { params } = match;
    history.push(
      VIEW_FARM_FORM_PATH.replace(":id", getString(params, "id"))
        .replace(":idSeason", getString(params, "idSeason"))
        .replace(":idForm", getNumber(profile, "id"))
    );
  };

  handleDeleteProfile = (profile) => () => {
    const { deletePersonalProfile, fetchAllProcedure } = this.props;
    let profileId = getNumber(profile, "id");
    const getAPIDelete = () => {
      switch (getString(profile, "type")) {
        case PROFILE_TYPES.PERSONAL_PROFILE:
          {
            return deletePersonalProfile(profileId);
          }
          break;
        case PROFILE_TYPES.COOPERATIVE_PROFILE:
          {
            return deletePersonalProfile(profileId);
          }
          break;
        default:
          break;
      }
    };
    getAPIDelete()
      .then(() => {
        message.success(strings.delete_profile_success);
        fetchAllProcedure();
      })
      .catch((err) => showError(err));
  };
  onSelectChange = (data) => {
    const { fetchAllProcedure } = this.props;

    fetchAllProcedure(data.current - 1, data.pageSize);
  };
  render() {
    /**
     * event cell click -> view profile detail
     */
    const renderCellItem = (data, records) => {
      return (
        <>
          <span>{data}</span>
          <p
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          ></p>
        </>
      );
    };

    const columns = [
      {
        title: strings.number_vote,
        dataIndex: "number_vote",
        key: "number_vote",
        width: "9%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.offering_sell,
        dataIndex: "offering_sell",
        key: "offering_sell",
        width: "11%",
        render: (record, records) => {
          return (
            <a href="#" onClick={() => this.handleViewProfile(records)}>
              {record}
            </a>
          );
        },
      },
      {
        title: strings.surrogate,
        dataIndex: "surrogate",
        key: "surrogate",
        width: "9%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.phone,
        dataIndex: "phone",
        key: "phone",
        width: "9%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.acreage_farming,
        dataIndex: "acreage_farming",
        key: "acreage_farming",
        align: "center",

        width: "9%",
        render: (record, records) => {
          return <span>{getNumber(record, "value", 0)}</span>;
        },
      },
      {
        title: strings.date_buy_register,
        dataIndex: "date_buy_register",
        key: "date_buy_register",
        width: "9%",
        render: (record, records) => {
          return <span>{UtilDate.toDateLocal(record)}</span>;
        },
      },
      {
        title: strings.time_buy_register,
        align: "center",

        dataIndex: "time_buy_register",
        key: "time_buy_register",
        width: "9%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.sum_total_buy,
        dataIndex: "sum_total_buy",
        key: "sum_total_buy",
        align: "center",

        width: "9%",
        render: (record, records) => {
          let total = 0;
          getArray(records, "productPublishes", []).map((val) => {
            total = getNumber(val, "quantity", 0) + total;
          });
          return <span>{total}</span>;
        },
      },

      {
        title: strings.sum_cost_buy,
        dataIndex: "sum_cost_buy",
        align: "center",
        key: "sum_cost_buy",
        width: "9%",
        render: (record, records) => {
          let total = 0;
          total =
            getNumber(records, "amout", 0) +
            getNumber(records, "serviceCosts", 0);
          return <span>{total}</span>;
        },
      },
      {
        title: strings.status,
        dataIndex: "status",
        align: "center",
        key: "status",
        width: "9%",
        render: (record, records) => {
          return <span>{this.mapStatus(record)}</span>;
        },
      },
      {
        title: strings.note,
        dataIndex: "note",
        align: "center",
        key: "note",
        width: "9%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
    ];

    const { procedure = false, total, pageSize } = this.props;
    return (
        <Wrapred>
          <UITable
            loading={this.props.loading}
            dataSource={getArray(procedure, undefined, [])}
            rowClassName="cursor-pointer"
            columns={columns}
            handleTableChange={this.onSelectChange}
            pagination={{ position: ["topRight"], total, pageSize }}
          />
        </Wrapred>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getAllForm,
  }),
  withState("procedure", "setProcedure", []),
  withState("total", "setTotal", 0),
  withState("curent", "setCurrent", 0),
  withState("pageSize", "setPageSize", 10),
  withState("loading", "setLoading", false),
  withHandlers({
    fetchAllProcedure: (props) => (curent, pageSize) => {
      const { getAllForm, setProcedure, match, setTotal, setLoading } = props;
      const { idSeason } = match.params;

      setLoading(true);
      getAllForm({ page: curent || 0, size: pageSize || 10 }, idSeason)
        .then(async (res) => {
          setLoading(false);
          setTotal(res.header["x-total-count"] || 0);
          const objectMap = mapArrayObjectToAPI(res.res, CHANGE_NAME);
          setProcedure(objectMap);
        })
        .catch((err) => {
          setLoading(false);
          showError(err);
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchAllProcedure } = this.props;
      fetchAllProcedure();
    },
  })
)(AllForm);
