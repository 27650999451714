import * as ACTION from "./type";
export const createProcedure = (data) => {
  return {
    type: ACTION.CREATE_TYPE_PROCEDURE,
    data,
  };
};
export const getProcedure = (data) => {
  return {
    type: ACTION.GET_TYPE_PROCEDURE,
    data,
  };
};
