import React from "react";

import {List} from "antd";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose, lifecycle, withHandlers, withState} from "recompose";
import styled from "styled-components";
import {IMAGE_URL} from "~/configs";
import {FARM_TYPES, PROFILE_TYPES} from "~/configs/const";
import * as PATH from "~/configs/routesConfig";
import {showError} from "~/configs/ServerErrors";
import strings from "~/localization";
import {getActivityLogs} from "~/state/ducks/appApis/actions";
import {Avatar} from "~/views/presentation/ui/fields";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import {getArray, getString} from "~/views/utilities/helpers/utilObject";

// calc (100% - top > bottom element height)
const ContainerStyled = styled.div`
  width: 100%;
  height: calc(100% - 110px - 180px - 40px);
  overflow: auto;
  ::-webkit-scrollbar {
    height: 6px !important;
    width: 6px !important;
    background: #f0f0f0 !important;
  }
  ::-webkit-scrollbar-thumb:vertical {
    background: #afafaf !important;
    border-radius: 8px !important;
  }
  scroll-behavior: smooth;
  border-top: 1px solid #afafaf;
`;
const GroupActivityStyled = styled.div`
  .time {
    padding: 10px;
    border-bottom: 1px solid #e9e9e9;
    color: gray;
    font-size: 14px;
    font-weight: bold;
  }
`;

class ActivityLogs extends React.PureComponent {
  render() {
    const {logs} = this.props;
    return (
      <ContainerStyled>
        {(logs || []).map((item, index) => (
          <GroupActivityStyled key={index}>
            <p className='time'>{UtilDate.toTimeLogLocal(getString(item, "date", ""))}</p>
            <List
              style={{paddingRight: 10, paddingLeft: 10}}
              itemLayout='horizontal'
              dataSource={getArray(item, "detail", [])}
              renderItem={(item, index) => (
                <List.Item key={index} className='py-2'>
                  <div className='d-flex'>
                    <div className='pt-2'>
                      <Avatar size={50} src={IMAGE_URL + getString(item, "executorAvatar", "")} />
                    </div>
                    <div className='ml-4'>
                      <div>
                        <span>{strings.notes_of}</span>
                        <span style={{fontWeight: "bold"}}>{getString(item, "executorName", "")}</span>
                        <span className='mx-2'>-</span>
                        <span>{UtilDate.toDateTimeLocal(getString(item, "eventDate"))}</span>
                      </div>
                      <h5>{strings[getString(item, "action", "")] || getString(item, "action", "")}</h5>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </GroupActivityStyled>
        ))}
      </ContainerStyled>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    getActivityLogs,
  }),
  withState("logs", "setLogs", []),
  withHandlers({
    handleFetchActivity: (props) => () => {
      const {getActivityLogs, setLogs, match} = props;
      let profileType = "";
      let id = match.params.id;
      switch (match.path) {
        /**
         * personal profile
         */
        case PATH.CREATE_PERSONAL_PROFILE_PATH:
        case PATH.EDIT_PERSONAL_PROFILE_PATH:
        case PATH.VIEW_PERSONAL_PROFILE_PATH:
          profileType = PROFILE_TYPES.PERSONAL_PROFILE;
          break;
        /**
         * farm profile
         */
        case PATH.CREATE_FARM_PROFILE_PATH:
        case PATH.EDIT_FARM_PROFILE_PATH:
        case PATH.VIEW_FARM_PROFILE_PATH:
          profileType = PROFILE_TYPES.FARM_PROFILE;
          break;
        /**
         * enterprise profile
         */
        case PATH.CREATE_ENTERPRISE_PROFILE_PATH:
        case PATH.EDIT_ENTERPRISE_PROFILE_PATH:
        case PATH.VIEW_ENTERPRISE_PROFILE_PATH:
          profileType = PROFILE_TYPES.ENTERPRISE;
          break;
        /**
         * cooperative profile
         */
        case PATH.CREATE_COOPERATIVE_PROFILE_PATH:
        case PATH.EDIT_COOPERATIVE_PROFILE_PATH:
        case PATH.VIEW_COOPERATIVE_PROFILE_PATH:
          profileType = PROFILE_TYPES.COOPERATIVE;
          break;
        /**
         * cooperative venture profile
         */
        case PATH.CREATE_COOPERATIVE_VENTURE_PROFILE_PATH:
        case PATH.EDIT_COOPERATIVE_VENTURE_PROFILE_PATH:
        case PATH.VIEW_COOPERATIVE_VENTURE_PROFILE_PATH:
          profileType = PROFILE_TYPES.COOPERATIVE_VENTURE;
          break;
        /**
         * organization profile
         */
        case PATH.CREATE_ORGANIZATION_PROFILE_PATH:
        case PATH.EDIT_ORGANIZATION_PROFILE_PATH:
        case PATH.VIEW_ORGANIZATION_PROFILE_PATH:
          profileType = PROFILE_TYPES.ORGANIZATION;
          break;
        /**
         * farm - procedure
         */
        case PATH.VIEW_FARM_PROCEDURE_PATH:
        case PATH.EDIT_FARM_PROCEDURE_PATH:
        case PATH.VIEW_FARM_SEASON_PROCEDURE_PATH:
        case PATH.EDIT_FARM_SEASON_PROCEDURE_PATH:
        case PATH.CREATE_FARM_PROCEDURE_PATH:
          profileType = FARM_TYPES.FARM_PROCESS;
          id = match.params.idProcedure;

          break;
        /**
         * farm season
         */
        case PATH.FARM_SEASON_CRUD_PATH:
        case PATH.EDIT_SEASON_CRUD_FARM_PATH:
        case PATH.CREATE_SEASON_CRUD_FARM_PATH:
        case PATH.VIEW_SEASON_CRUD_FARM_PATH:
          profileType = FARM_TYPES.FARM_SEASON;
          id = match.params.idSeason;
          break;
        case PATH.ALL_FARM_FORM_PATH:
        case PATH.EDIT_FARM_FORM_PATH:
        case PATH.CREATE_FARM_FORM_PATH:
        case PATH.VIEW_FARM_FORM_PATH:
          profileType = FARM_TYPES.PUBLISH_PRODUCT;
          id = match.params.idForm;
          break;
        case PATH.CREATE_FARM_PACKAGING_COST_PATH:
        case PATH.EDIT_FARM_PACKAGING_COST_PATH:
        case PATH.VIEW_FARM_PACKAGING_COST_PATH:
          profileType = FARM_TYPES.PACKAGING_COST;
          id = match.params.idPackagingCost;
          break;
        case PATH.CREATE_FARM_PRODUCT_COST_PATH:
        case PATH.EDIT_FARM_PRODUCT_COST_PATH:
        case PATH.VIEW_FARM_PRODUCT_COST_PATH:
          profileType = FARM_TYPES.PRICE_STRUCTURE;
          id = match.params.idProductCost;
          break;
        case PATH.CREATE_FARM_SALE_PRICE_PATH:
        case PATH.EDIT_FARM_SALE_PRICE_PATH:
        case PATH.VIEW_FARM_SALE_PRICE_PATH:
          profileType = FARM_TYPES.MANUFACTURING_COST;
          id = match.params.idsalePrice;
          break;

        // harvest
        case PATH.EDIT_SEASON_HARVEST_FARM_PATH:
          profileType = FARM_TYPES.ACTUAL_HARVEST;
          id = match.params.idSeason;
          break;
        case PATH.EDIT_SEASON_EXPECTED_HARVEST_FARM_PATH:
          profileType = FARM_TYPES.EXPECTED_HARVEST;
          id = match.params.idSeason;
          break;
        default:
          break;
      }
      if (id <= 0 || id === undefined) {
        setLogs([]);
        return;
      }
      getActivityLogs({
        type: profileType,
        profileId: id,
      })
        .then(({res}) => {
          setLogs(getArray(res, "logs", []));
        })
        .catch((err) => {
          console.error("getActivityLogs -> err", err);
          showError(err);
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      const {handleFetchActivity} = this.props;
      handleFetchActivity();
    },
  })
)(ActivityLogs);
