import React from "react";
import OneUploader from "./OneUploader";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { connect } from "react-redux";

import * as yup from "yup";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import {
  InputField,
  SelectField,
} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import { Tabs, Card, Form } from "antd";
import { UIButton } from "~/views/presentation/ui/buttons";
import {
  getMassUnits,
  getAgricultureTypes,
  getQuanlities,
  getProductTypes,
  getType,
  getProvinces,
  getDistrict,
  getPersionnalProfile,
  getWards,
} from "~/state/ducks/appApis/actions";
import { showError } from "~/configs/ServerErrors";
import {
  getArray,
  getNumber,
  getString,
} from "~/views/utilities/helpers/utilObject";
import { TableEditable } from "~/views/presentation/ui/tables";
import {
  stringNRFieldValidate,
  numberRequiredField,
  numberValidate,
} from "~/views/utilities/validation/input";
import _ from "lodash";
const { TabPane } = Tabs;

const validationSchema = yup.object().shape({
  // formOfUsesIds: numberValidate,
  address1: stringNRFieldValidate(), //
  hisLand: numberValidate, //
  unitId: numberValidate, //
  acreage: numberValidate, //
  idLand: numberRequiredField, //
  provinceId: numberValidate, //
  wardsId: numberValidate, //
  districtId: numberValidate, //
});

const scrollToBottom = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: "smooth",
  });
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
class FarmingExperienceForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { show: true };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
 
  }
  handleSaveValues = () => {
    const {
      values,
      setFieldValue,
      setValues,
      setFieldTouched,
    } = this.props;
    setFieldValue("images", "");
    if (values.key >= 0) {
      if ((this.props.info.certifycateOfLands || []).length > values.key) {
        let keys = Object.keys(values).filter(
          (key) => key=='formOfUsesIds' || !_.isArray(values[key]) && !_.isObject(values[key])
        );

        let modifyObject = keys.reduce((prev, curr) => {
          prev[curr] = values[curr];
          return prev;
        }, {});
        this.props.info.certifycateOfLands[values.key] = modifyObject;
      }
      Object.keys(values).map((key) => setFieldTouched(key, false));
      setValues({});
      scrollToBottom();
    } else {
      let keys = Object.keys(values).filter(
        (key) =>key=='formOfUsesIds' || !_.isArray(values[key]) && !_.isObject(values[key])
      );

      let modifyObject = keys.reduce((prev, curr) => {
        prev[curr] = values[curr];
        return prev;
      }, {});
      this.props.info.certifycateOfLands.push(modifyObject);

      this.props.info.certifycateOfLands.forEach((item, index) => {
        item.key = index;
      });
      Object.keys(values).map((key) => setFieldTouched(key, false));
      setValues({});
      scrollToBottom();
    }
  };

  handleChangeDataSource = (index, dataIndex, dataSource) => {
    const { setValues } = this.props;
    if (dataIndex === "key") {
      setValues({});
      while ((this.props.info.certifycateOfLands || []).length) {
        this.props.info.certifycateOfLands.shift();
      }
      dataSource.forEach((item) => {
        this.props.info.certifycateOfLands.push(item);
      });
    }
  };
  handleChangeCertificate = (e, index) => {
    const { setFieldValue, arrayImage, values } = this.props;
    let temptArray = arrayImage;
    let valuesImg = getString(values, "images", "");

    temptArray = valuesImg.split("|");
    temptArray[index - 1] = e;
    temptArray = temptArray.join("|");

    setFieldValue("images", temptArray);
  };
  getSplitImg(index) {
    let tempt = getString(this.props.info.certifycateOfLands, "images");
    tempt = tempt.split("|");
    return tempt[index];
  }
  handleSelectRow = (r) => {
    const { fechWard, fechDistrict } = this.props;

    const districtId = getNumber(r, "districtId", undefined);
    const provinceId = getNumber(r, "provinceId", undefined);
    if (provinceId) fechDistrict(provinceId);
    if (districtId) {
      fechWard(districtId);
    }
    const { setValues } = this.props;
    setValues(r);
    scrollToTop();
  };
  handleChange = (event) => {
    const { handleChange, setFieldTouched } = this.props;
    setFieldTouched(event.target.name, true);
    handleChange(event);
  };

  render() {
    const {
      values,
      // handleChange,
      handleBlur,
      touched,
      errors,
      isValid,
      editable = true,
      setFieldValue,
      setFieldTouched,
      provin,
      district,
      ward,
      persion,
      handleCancelChange,
      acreAge,
    } = this.props;
    const { show } = this.state;

    const columns = [
      {
        title: strings.id_land,
        dataIndex: "idLand",

        key: "idLand",

        width: "20%",
        editable: false,
        placeholder: strings.id_land,
        type: "text",
        // options: this.props.agricultureTypes || [],
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.address,
        placeholder: strings.address,
        dataIndex: "address1",
        key: "address1",
        width: "20%",
        type: "text",
        // options: this.props.productTypes || [],
      },
      {
        title: strings.acreage,
        dataIndex: "acreage",
        key: "acreage",
        placeholder: strings.acreage,
        type: "text",
        width: "20%",
      },
      {
        title: strings.status_land,
        dataIndex: "formOfUsesIds",
        key: "formOfUsesIds",
        placeholder: strings.status_land,
        type: "select",
        width: "20%",
        options: this.props.formOfUsesIdsData,
      },
      {
        title: strings.his_land,
        dataIndex: "hisLand",
        placeholder: strings.his_land,
        key: "hisLand",
        type: "select",
        width: "20%",
        options: this.props.persion,
      },
    ];

    return (
      <div>
        <Form>
          <div className="row">
            <div className="col-12 col-md-6">
              <InputField
                required

                disabled={!editable}
                min={0}
                help={touched.idLand && errors.idLand ? errors.idLand : ""}
                validatestatus={
                  touched.idLand && errors.idLand ? "error" : undefined
                }
                help={touched.idLand && errors.idLand ? errors.idLand : ""}
                name="idLand"
                type="number"
                value={values.idLand}
                onChange={this.handleChange}
                onBlur={handleBlur}
                placeholder={strings.id_land}
                label={strings.id_land}
              />

              <div className="row">
                <div className="col-6">
                  <InputField
                    validatestatus={
                      touched.address1 && errors.address1 ? "error" : undefined
                    }
                    help={
                      touched.address1 && errors.address1 ? errors.address1 : ""
                    }
                    name="address1"
                    disabled={!editable}
                    value={values.address1}
                    onChange={this.handleChange}
                    onBlur={handleBlur}
                    placeholder={strings.address}
                    label={strings.address}
                  />
                </div>
                <div className="col-6">
                  <SelectField
                    validateStatus={
                      touched.provinceId && errors.provinceId
                        ? "error"
                        : undefined
                    }
                    disabled={!editable}
                    help={
                      touched.provinceId && errors.provinceId
                        ? errors.provinceId
                        : ""
                    }
                    data={provin || []}
                    value={values.provinceId}
                    onChange={(value) => {
                      this.props.fechDistrict(value);
                      setFieldValue("provinceId", value);
                      setFieldValue("districtId", undefined);
                      setFieldValue("wardsId", undefined);

                      setFieldTouched("provinceId");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.city_or_province}
                    label={strings.city_or_province}
                  />
                </div>
                <div className="col-6">
                  <SelectField
                    validateStatus={
                      touched.districtId && errors.districtId
                        ? "error"
                        : undefined
                    }
                    disabled={!editable}
                    help={
                      touched.districtId && errors.unitId
                        ? errors.districtId
                        : ""
                    }
                    data={district || []}
                    value={values.districtId}
                    onChange={(value) => {
                      this.props.fechWard(value);
                      setFieldValue("districtId", value);
                      setFieldValue("wardsId", undefined);

                      setFieldTouched("districtId");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.district}
                    label={strings.district}
                  />
                </div>
                <div className="col-6">
                  <SelectField
                    validateStatus={
                      touched.wardsId && errors.wardsId ? "error" : undefined
                    }
                    disabled={!editable}
                    help={
                      touched.wardsId && errors.wardsId ? errors.wardsId : ""
                    }
                    data={ward || []}
                    value={values.wardsId}
                    onChange={(value) => {
                      setFieldValue("wardsId", value);
                      setFieldTouched("wardsId");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.ward}
                    label={strings.ward}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-8 pr-2">
                  <InputField
                    disabled={!editable}
                    validatestatus={
                      touched.acreage && errors.acreage ? "error" : undefined
                    }
                    help={
                      touched.acreage && errors.acreage ? errors.acreage : ""
                    }
                    name="acreage"
                    type="number"
                    min={0}
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 12))
                    }
                    value={values.acreage}
                    onChange={this.handleChange}
                    onBlur={handleBlur}
                    placeholder={strings.acreage}
                    label={strings.acreage}
                  />
                </div>
                <div className="col-4 pl-2">
                  <SelectField
                    validateStatus={
                      touched.unitId && errors.unitId ? "error" : undefined
                    }
                    disabled={!editable}
                    help={touched.unitId && errors.unitId ? errors.unitId : ""}
                    data={
                      acreAge || [
                        { label: "met", value: 1 },
                        { lable: "hecta", value: 2 },
                      ]
                    }
                    value={values.unitId}
                    onChange={(value) => {
                      setFieldValue("unitId", value);
                      setFieldTouched("unitId");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.unit}
                    label={strings.unit}
                  />
                </div>
              </div>
              <SelectField
                mode="multiple"
                validateStatus={
                  touched.formOfUsesIds && errors.formOfUsesIds
                    ? "error"
                    : undefined
                }
                disabled={!editable}
                help={
                  touched.formOfUsesIds && errors.formOfUsesIds
                    ? errors.formOfUsesIds
                    : ""
                }
                data={this.props.formOfUsesIdsData || []}
                value={values.formOfUsesIds}
                onChange={(value) => {
                  setFieldValue("formOfUsesIds", value);
                  setFieldTouched("formOfUsesIds");
                }}
                iconEnd="caret-down"
                placeholder={strings.status_land}
                label={strings.status_land}
              />
              <SelectField
                validateStatus={
                  touched.hisLand && errors.hisLand ? "error" : undefined
                }
                disabled={!editable}
                help={touched.hisLand && errors.hisLand ? errors.hisLand : ""}
                data={persion || []}
                value={values.hisLand}
                onChange={(value) => {
                  setFieldValue("hisLand", value);
                  setFieldTouched("hisLand");
                }}
                iconEnd="caret-down"
                placeholder={strings.his_land}
                label={strings.his_land}
              />
            </div>
            <div className="col-12 col-md-6 ">
              <Card className={!editable ? "avoid-click" : ""}>
                <div className="row">
                  <div className="col-6">
                    <OneUploader
                      show={show}
                      label={strings.before_upload_GCN}
                      images={{ img: values.images, index: 0 }}
                      onChange={(e) => this.handleChangeCertificate(e, 1)}
                    />
                  </div>
                  <div className="col-6" style={{ marginBottom: 25 }}>
                    <OneUploader
                      show={show}
                      label={strings.after_upload_GCN}
                      images={{ img: values.images, index: 1 }}
                      onChange={(e) => this.handleChangeCertificate(e, 2)}
                    />
                  </div>
                  <div className="col-6">
                    <OneUploader
                      show={show}
                      label={strings.human_and_GCN_upload}
                      images={{ img: values.images, index: 2 }}
                      onChange={(e) => this.handleChangeCertificate(e, 3)}
                    />
                  </div>
                  <div className="col-6">
                    <OneUploader
                      show={show}
                      label={strings.other}
                      images={{ img: values.images, index: 3 }}
                      onChange={(e) => this.handleChangeCertificate(e, 4)}
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <div>
            <UIButton
              type="primary"
              htmlType="submit"
              disabled={!isValid || !editable}
              onClick={this.handleSaveValues}
            >
              <span>{strings.save}</span>
            </UIButton>
            <UIButton
              type="secondary"
              className="ml-2"
              htmlType="button"
              disabled={!editable}
              onClick={handleCancelChange(this.props)}
            >
              <span>{strings.cancel}</span>
            </UIButton>
          </div>
        </Form>
        <div className="mt-3">
          <Tabs defaultActiveKey="1" type="card" size={"middle"}>
            <TabPane tab={strings.history} key="1">
              <TableEditable
                changedData={this.handleChangeDataSource}
                editable={editable}
                addRowAble={false}
                selectedKey={values.key}
                onSelect={this.handleSelectRow}
                selectable={true}
                dataSource={this.props.info.certifycateOfLands}
                columns={columns}
              />
              <div style={{ marginTop: "16px" }}>
                <UIButton
                  style={{ float: "right" }}
                  type="primary"
                  className="ml-2"
                  htmlType="button"
                  disabled={!editable}
                  onClick={() => {
                    this.props.nextStept(3);
                  }}
                >
                  <span>{strings.continue}</span>
                </UIButton>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    getMassUnits,
    getAgricultureTypes,
    getProductTypes,
    getQuanlities,
    getType,
    getProvinces,
    getDistrict,
    getWards,
    getPersionnalProfile,
  }),
  withState("units", "setUnits", []),
  withState("productTypes", "setProductTypes", []),
  withState("quanlities", "setQuanlities", []),
  withState("agricultureTypes", "setAgricultureTypes", []),
  withState("agricultureTypes", "setAgricultureTypes", []),
  withState("formOfUsesIdsData", "setFormOfUsesIds", []),
  withState("acreAge", "setAcreAge", []),
  withState("provin", "setProvin", []),
  withState("district", "setDistrict", []),
  withState("ward", "setWard", []),
  withState("persion", "setPerSion", []),
  withState("arrayImage", "setArrayImg", [null, null, null, null]),

  withHandlers({
    fechWard: (props) => (id) => {
      const { getWards, setWard } = props;
      if (id)
        getWards(id)
          .then(({ res }) => {
            setWard(
              getArray(res, undefined, []).map((item) => {
                return {
                  value: item.id,
                  label: item.name || "",
                };
              })
            );
          })
          .catch((err) => showError(err));
    },
    fechDistrict: (props) => (id) => {
      const { getDistrict, setDistrict } = props;
      if (id)
        getDistrict(id)
          .then(({ res }) => {
            setDistrict(
              getArray(res, undefined, []).map((item) => {
                return {
                  value: item.id,
                  label: item.name || "",
                };
              })
            );
          })
          .catch((err) => showError(err));
    },
    fechGetProvince: (props) => () => {
      const { getProvinces, setProvin } = props;
      getProvinces(1)
        .then(({ res }) => {
          setProvin(
            getArray(res, undefined, []).map((item) => {
              return {
                value: item.id,
                label: item.name || "",
              };
            })
          );
        })
        .catch((err) => showError(err));
    },
    handleCancelChange: () => (props) => () => {
      const { setValues, setTouched, setFieldValue } = props;
      setValues({});
      setTouched({});
      setFieldValue("images", "");
    },

    fetchformOfUsesIds: (props) => () => {
      const { getType, setFormOfUsesIds } = props;
      getType("FORM_OF_USES")
        .then(({ res }) => {
          setFormOfUsesIds(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },

    fetchProductTypes: (props) => () => {
      const { getProductTypes, setProductTypes } = props;
      getProductTypes()
        .then(({ res }) => {
          setProductTypes(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fetchPerSionalProfile: (props) => () => {
      const { getPersionnalProfile, setPerSion } = props;
      getPersionnalProfile()
        .then(({ res }) => {
          setPerSion(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },

    fetchQuanlities: (props) => () => {
      const { getQuanlities, setQuanlities } = props;
      getQuanlities()
        .then(({ res }) => {
          setQuanlities(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fecthAcreage: (props) => () => {
      const { setAcreAge, getMassUnits } = props;
      getMassUnits("ACREAGE")
        .then(({ res }) => {
          setAcreAge(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    /**
     * Get Agriculture Types
     */
    fetchAgricultureTypes: (props) => () => {
      const { setAgricultureTypes } = props;
      setAgricultureTypes([
        { value: 1, label: "sở hữu" },
        { value: 2, label: "thuê" },
        { value: 3, label: "t/g" },
      ]);
    },
  }),

  withFormik({
    displayName: "farmingExperienceForm",
    validationSchema: validationSchema,
  }),
  lifecycle({
    componentDidMount() {
      const {
        fetchAgricultureTypes,
        fetchProductTypes,
        fetchQuanlities,
        fetchformOfUsesIds,
        fecthAcreage,
        fechGetProvince,
        fetchPerSionalProfile,
        fechDistrict,
        fechWard,
      } = this.props;
      const districtId = getNumber(
        this.props.info.certifycateOfLands,
        "districtId",
        undefined
      );
      const provinceId = getNumber(
        this.props.info.certifycateOfLands,
        "provinceId",
        undefined
      );

      if (provinceId) fechDistrict(provinceId);

      if (districtId) {
        fechWard(districtId);
      }
      fetchAgricultureTypes();
      fetchPerSionalProfile();
      fetchProductTypes();
      fetchQuanlities();
      fetchformOfUsesIds();
      fecthAcreage();
      fechGetProvince();
    },
  })
)(FarmingExperienceForm);
