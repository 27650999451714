import React from "react";
import { Card, Steps, Divider, Button } from "antd";
import strings from "~/localization";
import InfomationGenal from "./InfomationGenal/index";
import PageDone from "./PageDone/index";
import DetailInfomation from "./DetailInfomation";
import GCNStandard from "./GCNStandard";
import { UIButton } from "~/views/presentation/ui/buttons";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  LOGIN_PATH,
} from "~/configs/routesConfig";
import _ from "lodash";
const { Step } = Steps;

const scrollToBottom = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};
class SetUpEnterpriseProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (current) => {
    this.setState({ current });
    scrollToBottom();
  };
  renderStepContent = () => {
    const { personalInfo, editable, savePageDone = true } = this.props;
    switch (this.state.current) {
      case 0:
        return (
          <InfomationGenal
            nextStept={(e) => this.onChange(e)}
            editable={editable}
            info={personalInfo}
          />
        );
      case 1:
        return (
          <DetailInfomation
            nextStept={(e) => this.onChange(e)}
            editable={editable}
            info={personalInfo}
          />
        );
      case 2:
        return (
          <GCNStandard
            nextStept={(e) => this.onChange(e)}
            editable={editable}
            info={personalInfo}
          />
        );
      case 3:
        return (
          <PageDone
            savePageDone={savePageDone}
            onCreate={() => this.props.onCreate()}
          />
        );
    }
  };

  componentDidMount() {
    if (!this.props.user) {
      this.props.history.push(LOGIN_PATH);
    }
  }

  componentWillUnmount() {
    // setPersonalInfo(emptyObject());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user) {
      this.props.history.push(LOGIN_PATH);
    }
    const { setPersonalInfo } = this.props;
    if (this.props.info !== nextProps.info) {
      setPersonalInfo(nextProps.info);
    }
  }

  handleNext = () => {
    if (this.state.current <= 3) {
      this.setState({ current: this.state.current + 1 },()=>{
      });
    }
  };

  render() {
    const { current } = this.state;
    const { handleSkip, showHeader = true, showFooter = true } = this.props;
    return (
      <div style={{ paddingBottom: "20px" }}>
        <Card
          title={showHeader ? strings.setup_profile : undefined}
          bordered={false}
        >
          <Steps current={current} onChange={this.onChange}>
            <Step title={strings.information_general} />
            <Step title={strings.information_detail} />
            <Step title={strings.gcn_standard} />
            <Step title={strings.completed} />
          </Steps>
          <Divider className="mb-5" />
          {this.renderStepContent()}
        </Card>
        {showFooter && (
          <div
            className="d-flex justify-content-end mb-5"
            style={{ paddingRight: "24px" }}
          >
            {this.state.current !== 3 && (
              <Button type="dashed" onClick={handleSkip}>
                {strings.skip_setup}
              </Button>
            )}
            <UIButton
              type="primary"
              className="ml-2"
              onClick={this.handleNext}
              htmlType="button"
            >
              <span>
                {this.state.current === 3
                  ? strings.completed
                  : strings.continue}
              </span>
            </UIButton>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      user: state["authUser"].user,
    }),
    {}
  ),
  withRouter,
  withState("personalInfo", "setPersonalInfo", {}),
  withHandlers({}),
  lifecycle({
    componentDidMount() {
      // setPersonalInfo(emptyObject);
    },
  })
)(SetUpEnterpriseProfile);
