import React from 'react';
import {Table} from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import {Color} from '~/views/utilities/layout';

const TableWrapStyled = styled.div`
  .even-row-styled {
    background: #f5f5f5;
  }
  .expanded-row-styled {
    background: #d9f7be;
    font-weight: 550;
  }
  .ant-table-thead > tr > th {
    height: 100%;
  }

  .ant-table-column-sorters,
  .ant-table-cell {
    padding: 8px 8px;

  }

  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 8px 8px !important;

  }
  .ant-table-cell {
    padding: 8px 8px !important;
  }
  thead {
    font-weight: bold;
    color: ${Color.gray};
    th {
      background-color: ${Color.tableBgHead};
      color: ${Color.tableTextHead};
      font-weight: bold;
    }
    th.ant-table-column-sort {
      background-color: ${Color.white};
    }
  }
  tbody {
    background-color: ${Color.white};
    td.ant-table-column-sort {
      background-color: ${Color.white};
    }
  }
  .ant-pagination-item {
    border: none;
    border-radius: 2px;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 10px;
    &:hover {
      a {
        color: ${Color.red};
      }
    }
  }
  .ant-pagination {
    border-top: none !important;
  }
  .ant-pagination-item-active {
    background-color: ${Color.green};
    font-weight: bold;
    line-height:24px;
    border:none;
    a,
    &:hover a {
      color: ${Color.white};
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    height: 24px;
    min-width: 24px;
    line-height: 24px;
    &:hover {
      .ant-pagination-item-link {
        border-color:${Color.green}
        color: ${Color.green};
      }
    }
  }
  .ant-pagination-next.ant-pagination-disabled,.ant-pagination-prev.ant-pagination-disabled{
    .ant-pagination-item-link{
      border-color: #a7a7a7;
    }
    &:hover{
      .ant-pagination-item-link{
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${Color.red};
  }
`;

class TableExpandSelectData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // dataSource: generateAutoKey(props.dataSource),
      sortedInfo: null,
      expandList: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if (this.state.dataSource !== nextProps.dataSource) {
    //    this.setState({dataSource: generateAutoKey(nextProps.dataSource)});
    // }
  }

  clearFilters = () => {
    this.setState({filteredInfo: null});
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  onExpand = (expanded, record) => {
    let {expandList} = this.state;
    if (expanded) {
      // true =?> add
      this.setState({expandList: [...expandList, record.key]});
    } else {
      // // false => delete
      _.remove(expandList, (item) => item === record.key);
      this.setState({expandList});
    }
  };

  rowClassName = (r) => {
    if (!r) return;
    const {selectable = false, selectedKey} = this.props;
    let classNameRow = `row-styled editable-row 
       ${selectedKey && selectedKey === r.key ? 'selected-row' : ''} 
       ${selectable ? 'selectable-row' : ''}`;
    if (r.key % 2 !== 0) {
      // odd
    } else {
      // even
      classNameRow += ` even-row-styled `;
    }
    if (_.findIndex(this.state.expandList, (key) => key === r.key) != -1) classNameRow += `expanded-row-styled`;
    return classNameRow;
  };

  render() {
    const {scroll, dataSource, columns} = this.props;

    let {sortedInfo, filteredInfo} = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    /**
     * ROW SELECTION
     */
    const rowSelection = {
      onChange: () => {},
      onSelect: () => {},
      onSelectAll: () => {},
    };

    /**
     * EXPAND
     */
    // const expandedRowRender = () => {
    //    return <TableExpand />;
    // };

    return (
      <TableWrapStyled>
        <div className='w-100'>
          <div className='overflow-auto'>
            <Table
              rowClassName={(r) => this.rowClassName(r)}
              // expandable={{expandedRowRender}}
              onExpand={this.onExpand}
              dataSource={dataSource}
              columns={columns}
              showSorterTooltip={false}
              rowSelection={{...rowSelection}}
              pagination={false}
              scroll={scroll}
            />
          </div>
        </div>
      </TableWrapStyled>
    );
  }
}

export default React.memo(TableExpandSelectData);
