import React from "react";
import { compose, withHandlers, lifecycle, withState } from "recompose";
import { connect } from "react-redux";
import * as yup from "yup";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { getType } from "~/state/ducks/appApis/actions";
import {
  InputField,
  SelectField,
  TextAreaField,
  RadioField,
} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import { Form } from "antd";

import _ from "lodash";
import {
  stringNRFieldValidate,
  numberValidate,
} from "~/views/utilities/validation/input";

import { showError } from "~/configs/ServerErrors";
import {
  getArray,
  getString,
  getNumber,
} from "~/views/utilities/helpers/utilObject";

const validationSchema = yup.object().shape({
  typeProcedure: numberValidate,
  description: stringNRFieldValidate(),
  name: stringNRFieldValidate(),
});
class FromProcedure extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.error(
      "FromProcedure -> UNSAFE_componentWillReceiveProps -> nextProps",
      nextProps
    );
  }
  _handleCheckRadio = (e) => {
    const { setFieldValue } = this.props;
    setFieldValue("type", e.target.value);
  };
  handleChangeInputValue = (e) => {
    const { handleChange } = this.props;
    handleChange(e);
    let name = e.target.name;
    this.props.process[name] = e.target.value;
  };
  render() {
    const {
      values,
      handleBlur,
      touched,
      errors,
      loadingTypeProcess,
      typeProcess,
      setFieldValue,
      setFieldTouched,
    } = this.props;
    const { editable } = this.props;

    return (
      <Form>
        <div className="row d-flex">
          <div className="col-12 col-lg-6">
            <SelectField
              loading={loadingTypeProcess}
              validateStatus={
                touched.typeProcedure && errors.typeProcedure
                  ? "error"
                  : undefined
              }
              disabled={!editable}
              help={
                touched.typeProcedure && errors.typeProcedure
                  ? errors.typeProcedure
                  : ""
              }
              data={typeProcess || []}
              value={values.typeProcedure}
              onChange={(value) => {
                this.props.process.typeProcedure = value;
                setFieldValue("typeProcedure", value);
                setFieldTouched("typeProcedure");
              }}
              iconEnd="caret-down"
              placeholder={strings.type_procedure}
              label={strings.type_procedure}
            />
          </div>
          <div className="col-12 col-lg-6">
            <InputField
              disabled={!editable}
              validatestatus={touched.name && errors.name ? "error" : undefined}
              help={touched.name && errors.name ? errors.name : ""}
              name="name"
              value={values.name}
              onChange={this.handleChangeInputValue}
              onBlur={handleBlur}
              placeholder={strings.name_procedure}
              label={strings.name_procedure}
            />
          </div>
          <div className="col-12 col-lg-6">
            <TextAreaField
              autoSize={{ minRows: 3, maxRows: 3 }}
              disabled={!editable}
              validateStatus={
                touched.description && errors.description ? "error" : undefined
              }
              help={
                errors.description
              }
              name="description"
              value={values.description}
              onChange={this.handleChangeInputValue}
              placeholder={strings.description}
              label={strings.description}
            />
          </div>
        </div>
        <RadioField
          defaultValue={1}
          disabled={!editable}
          value={values.type}
          onChange={this._handleCheckRadio}
          spaceBetween
          items={[
            { value: 1, label: strings.create },
            // { value: 2, label: strings.edit_from_procedure_form },
          ]}
        ></RadioField>
      </Form>
    );
  }
}
export default compose(
  withRouter,
  connect(null, { getType }),
  withState("typeProcess", "setProcess", []),
  withState("loadingTypeProcess", "setLoadingType", false),

  withHandlers({
    fetchTypeProcess: (props) => () => {
      const { getType, setProcess, setLoadingType } = props;
      setLoadingType(true);
      getType("CULTIVATION_PROCESS")
        .then(({ res }) => {
          setProcess(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
          setLoadingType(false);
        })
        .catch((err) => {
          setLoadingType(false);

          showError(err);
        });
    },
  }),
  withFormik({
    displayName: "process",
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      return {
        name: getString(props, "process.name"),
        typeProcedure: getNumber(props, "process.typeProcedure"),
        description: getString(props, "process.description"),
      };
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchTypeProcess } = this.props;
      fetchTypeProcess();
    },
  })
)(FromProcedure);
