import React from 'react';

import {
  Form,
  Tabs,
} from 'antd';
import { withFormik } from 'formik';
import _ from 'lodash';
// redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  compose,
  lifecycle,
  withHandlers,
} from 'recompose';
import * as yup from 'yup';
// config || common
import strings from '~/localization';
import { getType } from '~/state/ducks/appApis/actions';
// component
import { UIButton } from '~/views/presentation/ui/buttons';
import {
  InputField,
  SelectField,
} from '~/views/presentation/ui/fields';
import UtilDate from '~/views/utilities/helpers/UtilDate';
import { stringNRFieldValidate } from '~/views/utilities/validation/input';

import ProcedureForm from './ProcedureForm';

const {TabPane} = Tabs;

const validationSchema = yup.object().shape({
   nameFarm: stringNRFieldValidate(),
   name: stringNRFieldValidate(),
});

class ProcedureForms extends React.PureComponent {
   /**
    *
    * @param {*} prvDataSource - the dataSource will be updated
    */

   updateDataSource = (prvDataSource = []) => (dataSource) => {
      while (prvDataSource.length) {
         prvDataSource.shift();
      }
      dataSource.forEach((item) => {
         prvDataSource.push(item);
      });
      this.props.info.seasonProcesses = [...dataSource];
   };

   handleChangeNumber = (e, name) => {
      const {setFieldValue} = this.props;
      setFieldValue(name, e);
      this.props.info[name] = e;
   };

   handleChangeInputValue = (e) => {
      const {handleChange} = this.props;
      handleChange(e);
      let name = e.target.name;
      this.props.info[name] = e.target.value;
   };

   UNSAFE_componentWillReceiveProps(nextProps) {}

   handleChangeSelectValue = (name) => (value) => {
      const {setFieldValue} = this.props;
      setFieldValue(name, value);
      this.props.info[name] = value;
   };

   handleChangeDate = (name) => (date) => {
      const {setFieldValue} = this.props;
      if (!_.isNil(date)) {
         setFieldValue(name, date.format(UtilDate.formatDateTimeServer));
         this.props.info[name] = date.format(UtilDate.formatDateTimeServer);
      } else {
         setFieldValue(name, undefined);
         this.props.info[name] = undefined;
      }
   };

   render() {
      const {info, values, handleBlur, touched, errors, setFieldValue, setFieldTouched, editable} = this.props;

      return (
         <div>
            <Form>
               <div className="row d-flex flex-row">
                  <div className="col-12 col-md-6">
                     <SelectField
                        validatestatus={touched.nameFarm && errors.nameFarm ? "error" : undefined}
                        help={touched.nameFarm && errors.nameFarm ? errors.nameFarm : ""}
                        name="nameFarm"
                        data={info.farmProfiles}
                        autoFocus={true}
                        disabled={true}
                        value={values.nameFarm}
                        onChange={(value) => {
                           this.props.info.nameFarm = value;
                           setFieldValue("nameFarm", value);
                           setFieldTouched("nameFarm");
                        }}
                        onBlur={handleBlur}
                        placeholder={strings.farm_choose}
                        label={strings.farm_choose}
                     />
                  </div>
                  <div className="col-12 col-md-6">
                     <InputField
                        validatestatus={touched.name && errors.name ? "error" : undefined}
                        help={touched.name && errors.name ? errors.name : ""}
                        name="name"
                        disabled={true}
                        value={values.name}
                        onChange={this.handleChangeInputValue}
                        onBlur={handleBlur}
                        placeholder={strings.farm_season_name}
                        label={strings.farm_season_name}
                     />
                  </div>
               </div>
               <div className="mt-4">
                  {/* <Tabs defaultActiveKey="1" type="card" size={"middle"}>
                     <TabPane tab={strings.procedure} key="1"> */}
                  <ProcedureForm
                     editable={editable}
                     onChange={this.updateDataSource(this.props.info.seasonProcesses)}
                     seasonProcesses={this.props.info.seasonProcesses}
                  />
                  <div style={{marginTop: "16px"}}>
                     <UIButton
                        style={{float: "right"}}
                        type="secondary"
                        className="ml-2"
                        htmlType="button"
                        disabled={!editable}
                        onClick={() => this.props.nextStep(2)}
                     >
                        <span>{strings.countinue}</span>
                     </UIButton>
                  </div>
                  {/* </TabPane>
                  </Tabs> */}
               </div>
            </Form>
         </div>
      );
   }
}
export default compose(
   withRouter,
   connect(null, {
      getType,
   }),
   withHandlers({}),
   withFormik({
      displayName: "infoProcedure",
      enableReinitialize: true,
      validationSchema,
      mapPropsToValues: (props) => {
         return {
            farmProfile: props.info.farmProfile,
            nameFarm: props.info.nameFarm,
            name: props.info.name,
         };
      },
   }),
   lifecycle({
      componentDidMount() {},
   })
)(ProcedureForms);
