import React from "react";
import { compose, withState, withHandlers, pure, lifecycle } from "recompose";

import strings from "~/localization";
import _, { divide } from "lodash";

import styled from "styled-components";
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import {
  DataRang,
  LoadingRanger,
  TitleCustom,
} from "~/views/presentation/ui/dashboard";
import { showError } from "~/configs/ServerErrors";
import {
  getArray,
  getNumber,
  getObject,
  getString,
} from "~/views/utilities/helpers/utilObject";
import {
  numberFormatWithPoint,
  numberFormatWithPointFloat,
} from "~/views/utilities/helpers/currency";
import { Color } from "~/views/utilities/layout";
import { withRouter } from "react-router-dom";
import moment from "moment";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import { CheckWidth } from "~/views/utilities/CheckWidth";

const WrapPerformance = styled.div`
  margin-bottom: 32px;
  padding: 0 15px 0 15px;
  .up {
    color: ${Color.success} !important;
  }
  .down {
    color: ${Color.error} !important;
  }
  .none {
    color: ${Color.primaryText} !important;
  }
  .font-size {
    fontsize: 25px;
  }
`;

const statusArrow = {
  UP: "up",
  DOWN: "down",
  NONE: "none",
};

const startDateDefault = "1999-12-12";
const endDateDefault = moment
  .utc(moment(), UtilDate.formatDateTimeServer)
  .format(UtilDate.formatDateServer);

const Performance = (props) => {
  const _handleChange = (data) => {
    const query = {
      endDate: moment
        .utc(
          getString(data, "firstValue.first", startDateDefault),
          UtilDate.formatDateTimeServer
        )
        .format(UtilDate.formatDateServer),
      startDate: moment
        .utc(
          getString(data, "secondValue.first", endDateDefault),
          UtilDate.formatDateTimeServer
        )
        .format(UtilDate.formatDateServer),
    };
    const { setLoading, setQuery, fetchPerformance } = props;
    setQuery(query);
    setLoading(true);
    fetchPerformance(query);
  };

  const renderArrow = (status, percent) => {
    if (status === statusArrow.UP)
      return (
        <>
          <ArrowUpOutlined className={`${status}  `} style={{ fontSize: 24 }} />
          <span className={`fist-name square ${status}`}>{`${
            percent === parseInt(percent)
              ? numberFormatWithPoint(percent)
              : numberFormatWithPointFloat(percent)
          }%`}</span>
        </>
      );
    else if (status === statusArrow.DOWN)
      return (
        <>
          <ArrowDownOutlined className={`${status}`} style={{ fontSize: 24 }} />
          <span className={`fist-name square ${status}`}>{`${
            percent === parseInt(percent)
              ? numberFormatWithPoint(percent)
              : numberFormatWithPointFloat(percent)
          }%`}</span>
        </>
      );
    else
      return (
        <>
          <ArrowRightOutlined
            className={`${status}`}
            style={{ fontSize: 24 }}
          />
          <span className={`fist-name square ${status}`}>{`${
            percent === parseInt(percent)
              ? numberFormatWithPoint(percent)
              : numberFormatWithPointFloat(percent)
          }%`}</span>
        </>
      );
  };

  const renderPerformance = () => {
    const { performances } = props;
    return performances.map((v, i) => {
      return (
        <div key={i} className="item-ecom col-lg-3 col-md-4 col-12">
          <div className="ecom-fist mb-4">
            <div className="fist-name text-uppercase">{v.title}</div>
            <div>
              <span className=" color-b ">{`${
                v.current === parseInt(v.current)
                  ? numberFormatWithPoint(v.current)
                  : numberFormatWithPointFloat(v.current)
              } ${v.unit}`}</span>
              <div className="float-right">
                {renderArrow(v.status, v.percentValue)}
              </div>
            </div>
          </div>
          <div className="ecom-fist">
            <div className="fist-name text-capitalize">{v.subTitle}</div>
            <div>
              <span className="color-gray">{`${numberFormatWithPoint(
                v.earlier
              )} ${v.unit}`}</span>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderEmptyPerformance = () => {
    const { performances } = props;
    const arrT = _.fill(Array(4 - (performances.length % 4)), 0);
    return arrT.map((v, i) => {
      return (
        <div key={i} className="item-ecom last col-lg-3 col-md-4 col-0"></div>
      );
    });
  };

  const { title = "", loading = false } = props;
  const device = CheckWidth();

  return (
    <WrapPerformance>
      <DataRang
        handleChange={_handleChange}
        label={strings.dashboard_data_range}
      />
      <TitleCustom title={title} slot={<div></div>} />
      {loading && <LoadingRanger />}
      {!loading && (
        <div className="per-ecom">
          <div className="row">
            {renderPerformance()}

            {device.isDesktop && renderEmptyPerformance()}
          </div>
        </div>
      )}
    </WrapPerformance>
  );
};

const initState = {
  data: [],
  value: undefined,
};

export default compose(
  withRouter,
  pure,
  withState("state", "setState", (props) =>
    !!props.defaultValue ? props.defaultValue : initState
  ),
  withState("performances", "setPerformances", []),
  withState("loading", "setLoading", false),
  withState("query", "setQuery", {
    startDate: "2020-12-01",
    endDate: "2020-01-01",
  }),
  withHandlers({
    fetchPerformance: (props) => (query) => {
      const {
        fetchData,
        match,
        arrPerformance = [],
        setLoading,
        setPerformances,
      } = props;

      setLoading(true);

      setPerformances(arrPerformance);

      fetchData(match.params.id, query)
        .then(({ res }) => {
          const { setLoading } = props;
          setLoading(false);
          const data = getObject(res, undefined, {});

          if (Object.keys(data).length === 0) return;

          setPerformances(
            arrPerformance.map((v, i) => {
              const current = getNumber(
                data[Object.keys(data)[i]],
                "current",
                0
              );
              const earlier = getNumber(
                data[Object.keys(data)[i]],
                "earlier",
                0
              );
              const value = current - earlier;
              let status = statusArrow.NONE;
              if (value > 0) status = statusArrow.UP;
              else if (value < 0) status = statusArrow.DOWN;
              return {
                key: i,
                ...v,
                current,
                earlier,
                percentValue: getNumber(
                  data[Object.keys(data)[i]],
                  "percentValue",
                  0
                ),
                status,
              };
            })
          );
        })
        .catch((err) => {
          console.error("fetchPerformance -> err", err);
          setLoading(false);
          showError(err);
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchPerformance, query } = this.props;

      fetchPerformance(query);
    },
  })
)(Performance);
