import React from "react";
import {compose, withState, withHandlers, pure, lifecycle} from "recompose";
import {getString} from "~/views/utilities/helpers/utilObject";
import styled from "styled-components";
import {Color} from "~/views/utilities/layout";

const WrapEventItem = styled.div`
  -webkit-transition: all 0.4s 0s ease;
  -moz-transition: all 0.4s 0s ease;
  -o-transition: all 0.4s 0s ease;
  transition: all 0.4s 0s ease;

  padding: 8px 8px;

  .text-bold {
    font-weight: 600;
  }

  .before {
    width: 10px;
    height: auto;
    background-color: ${Color.black};
    margin: 0px 4px 0px 0px;
  }
  .info {
    background-color: ${Color.blue};
  }
  .warning {
    background-color: ${Color.yellow};
  }
  .error {
    background-color: ${Color.red};
  }
  .ht-title {
    font-size: 1.1em;
    color: ${Color.blueDeep};
    font-weight: 750;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    margin: 0;
  }
  .btn-activity {
    padding: 0 !important;
    margin-right: 4px !important;
    height: auto;
    width: auto;
    min-width: auto !important;
    width: auto !important;
  }
  .status-and-date {
    font-size: 12px;
    color: #595959;
    font-weight: 400;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: italic;
    font-variant: normal;
    text-transform: none;
  }
`;

const EventItem = (props) => {
  const {info} = props;
  const status = getString(info, "status", undefined);
  return (
    <WrapEventItem className="w-100">
      <div className="d-flex flex-column justify-content-start align-items-stretch align-content-center">
        {status !== undefined && <div className={`before ${status}`}></div>}
        <div className="d-flex flex-column justify-content-center align-items-start align-content-center mr-3">
          <p className="ht-title">{getString(info, "title", "")} some some some some some some some some</p>
        </div>
        <div className="d-flex flex-row justify-content-end align-items-end">
          <p className="status-and-date m-0 pr-2"> Đơn hàng mới</p>
          <span style={{marginRight: 4}}> - </span>
          <p className="status-and-date m-0 "> 12/04/2020 10:30</p>
        </div>
      </div>
    </WrapEventItem>
  );
};

const initState = {
  data: [],
  value: undefined,
  fetching: false,
};

export default compose(
  pure,
  withState("state", "setState", (props) => (!!props.defaultValue ? props.defaultValue : initState)),
  withHandlers({
    handleChange: () => () => {},
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps() {},
    componentDidUpdate() {},
    componentDidMount() {},
  })
)(EventItem);
