import { memo } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { cartActions } from "~/state/ducks/cart";
import { authSelectors } from "~/state/ducks/authUser";
import {withRouter} from 'react-router-dom';

export default compose(
    memo,
    connect(
        (state) => ({
            authUser: authSelectors.getAuthUser(state),
        }),
        {
            changeQuantity: cartActions.changeQuantity,
            removeItemCart: cartActions.removeItemCart,
            removeItemCartServer: cartActions.removeItemCartServer,
            updateItemCartServer: cartActions.updateItemCartServer,
            updateNewPrice: cartActions.updateNewPrice
        }
    ),
    withRouter
)