import React from "react";
import {Upload, Modal, Popconfirm} from "antd";
import {PlusOutlined, ArrowDownOutlined, DeleteOutlined} from "@ant-design/icons";
import styled from "styled-components";
import {getArray, getString, getNumber} from "~/views/utilities/helpers/utilObject";
import {
   API_UPLOAD_FILES_URL,
   IMAGE_URL,
   videoUploadConfig,
   docsUploadConfig,
   pdfUploadConfig,
   excelUploadConfig,
   txtUploadConfig,
   mediaExtension,
   imgUploadConfig,
   otherUploadConfig,
   rarUploadConfig,
} from "~/configs";
import {checkMedia} from "~/views/presentation/ui/upload/checkUploadFile";
import strings from "~/localization";
import _ from "lodash";
import templateExcel from "~/static/images/template-excel.png";
import templateFile from "~/static/images/template-file.jpg";
import templatePdf from "~/static/images/template-pdf.jpg";
import templateVideo from "~/static/images/template-video.png";
import templateTxt from "~/static/images/template-txt.png";
import templateWord from "~/static/images/template-word.png";
import templateOther from "~/static/images/template-file.jpg";
import templateRar from "~/static/images/template-rar.png";

const upload_width = "100px";
const upload_height = "60px";
const UploadStyled = styled(Upload)`
   .ant-upload-list.ant-upload-list-picture-card {
   }
   .ant-upload.ant-upload-select-picture-card {
      width: ${upload_width} !important;
      height: ${upload_height} !important;
   }
   .ant-upload {
      padding: 2px !important;
   }
   .ant-upload-select-picture-card {
      width: ${upload_width} !important;
      height: 40px !important;
   }
   .ant-upload-list-item-error {
      width: ${upload_width} !important;
      height: ${upload_height} !important;
   }
   .ant-upload-list-picture-card .ant-upload-list-picture-card-container {
      width: ${upload_width} !important;
      height: ${upload_height} !important;
      span .ant-upload-list-item {
         width: ${upload_width} !important;
         height: ${upload_height} !important;
         padding: 2px !important;
      }
   }
   .ant-upload-list-picture-card .ant-upload-list-item-actions {
      width: 80px !important;
      display: flex !important;
      justify-content: space-around !important;
   }
`;

const ModalStyled = styled(Modal)`
   .ant-modal-content span.ant-modal-close-x {
      position: relative;
      top: -13px;
      right: -13px;
   }
`;

function getBase64(file) {
   return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
   });
}

export default class ImagesUpload extends React.Component {
   constructor(props) {
      super(props);
      let fileList = getArray(this.props, "files", []).map((file, index) => ({
         ...file,
         url: this.getUrlImageTemplate(file.url),
         preview: IMAGE_URL + file.url,
      }));

      this.state = {
         pointerInDiv: false,
         isFocused: false,
         previewVisible: false,
         preview: "", //Image, pdf, docs,...
         previewTitle: "",
         fileList,
         file: {},
      };
   }

   getUrlImageTemplate = (fileName) => {
      if (videoUploadConfig.type.test(fileName)) return templateVideo;
      else if (docsUploadConfig.type.test(fileName)) return templateWord;
      else if (excelUploadConfig.type.test(fileName)) return templateExcel;
      else if (txtUploadConfig.type.test(fileName)) return templateTxt;
      else if (pdfUploadConfig.type.test(fileName)) return templatePdf;
      else if (otherUploadConfig.type.test(fileName)) return templateOther;
      else if (rarUploadConfig.type.test(fileName)) return templateRar;
      else if (imgUploadConfig.type.test(fileName)) return IMAGE_URL + fileName;
      else return templateFile;
   };

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.files !== this.state.fileList) {
         let fileList = getArray(nextProps, "files", []).map((file, index) => ({
            ...file,
            url: this.getUrlImageTemplate(file.url),
            preview: IMAGE_URL + file.url,
            path: file.path,
         }));
         this.setState({fileList});
      }
   }

   componentWillUnmount() {
      document.removeEventListener("click", this.onClickDocument);
   }

   onClickDocument = () => {
      if (this.state.isFocused === true && this.state.pointerInDiv === false) {
         this.props.onBlur && this.props.onBlur();
      }
   };

   handleCancel = (e) => this.setState({previewVisible: false, preview: ""});

   handlePreview = async (file) => {
      if (!file.url && !file.preview) {
         file.preview = await getBase64(file.originFileObj);
      }
      if (imgUploadConfig.type.test(file.preview))
         this.setState({
            preview: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
         });
      else
         this.setState({
            preview: file.preview || file.url,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
         });
   };

   handleChange = ({file, fileList}) => {
      const {onChange} = this.props;
      if (file.status === "uploading") {
         this.setState({fileList});
         return;
      }
      this.setState(
         {
            fileList: (fileList || []).map((item, index) => {
               if (item.response) {
                  return {
                     uid: getNumber(_.first(item.response), "id"),
                     name: getString(_.first(item.response), "originalName"),
                     status: "done",
                     url: getString(_.first(item.response), "path"),
                     preview: IMAGE_URL + getString(_.first(item.response), "path"),
                     path: getString(_.first(item.response), "path"),
                  };
               } else return item;
            }),
         },
         () => {
            let updateImages = this.state.fileList.map((file) => ({
               ...file,
               url: getString(file, "preview", "").replace(IMAGE_URL, ""),
            }));
            onChange && onChange(updateImages);
         }
      );
   };

   onConfirm = (e) => {
      const {onDeleteFile} = this.props;
      const {file, fileList} = this.state;
      _.remove(fileList, (item) => item.uid === file.uid);
      return onDeleteFile(file);
   };
   onCancel = (e) => {};

   handleRemove = (file) => {
      this.setState({file});
      return false;
   };

   renderContentPreview() {
      const {preview} = this.state;
      const {controlsVideo, autoPlayVideo} = this.props;
      if (videoUploadConfig.type.test(preview))
         return (
            <video
               style={{width: "100%", height: "auto"}}
               controls={controlsVideo}
               autoPlay={autoPlayVideo}
               preload="none"
            >
               <source src={preview} />
            </video>
         );
      else if (imgUploadConfig.type.test(preview))
         return <img alt={strings.image_not_display} style={{width: "100%"}} src={preview} />;
      else
         return (
            <a rel="noopener noreferrer" href={preview}>
               <ArrowDownOutlined /> {strings.please_download_in_to_view}
            </a>
         );
   }

   renderPreview() {
      const {previewVisible, previewTitle} = this.state;
      return (
         <ModalStyled
            visible={previewVisible}
            // title={previewTitle}
            footer={null}
            onCancel={this.handleCancel}
         >
            {this.renderContentPreview()}
         </ModalStyled>
      );
   }

   render() {
      const {fileList} = this.state;
      const {
         multiple,
         user,
         profileId,
         profileType,
         listType,
         showPreviewIcon,
         showRemoveIcon,
         showDownloadIcon,
         isUploadButton,
         nameUpload,
      } = this.props;

      // Có thể custom button upload ở đây
      const uploadButton = (
         <div className="ant-upload-text">
            <PlusOutlined />
            {strings.upload_media}
         </div>
      );
      return (
         <div className="clearfix" onClick={() => this.setState({isFocused: true})}>
            <UploadStyled
               action={API_UPLOAD_FILES_URL + `?profileId=${profileId}&profileType=${profileType}`}
               headers={{
                  Authorization: `Bearer ${user.id_token}`,
               }}
               name={nameUpload}
               listType={listType}
               accept={mediaExtension}
               multiple={multiple}
               fileList={fileList}
               showUploadList={{
                  showPreviewIcon: showPreviewIcon,
                  showRemoveIcon: showRemoveIcon,
                  showDownloadIcon: showDownloadIcon,
                  removeIcon: (
                     <Popconfirm
                        placement="topRight"
                        title={strings.upload_confirm_title}
                        okText={strings.upload_confirm_yes}
                        cancelText={strings.upload_confirm_no}
                        onConfirm={this.onConfirm}
                        onCancel={this.onCancel}
                     >
                        <DeleteOutlined />
                     </Popconfirm>
                  ),
               }}
               onPreview={this.handlePreview}
               onChange={this.handleChange}
               onRemove={this.handleRemove}
               beforeUpload={checkMedia}
            >
               {!isUploadButton ? null : uploadButton}
            </UploadStyled>
            {this.renderPreview()}
         </div>
      );
   }
}
