import React from "react";
import TableEdit from "../../TableEditProductCost";
import {
  InputField,
  SelectField,
  InputNumberPro,
} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import Wrap from "./style";

import { getArray, getNumber } from "~/views/utilities/helpers/utilObject";
import { showError } from "~/configs/ServerErrors";
import { MinusCircleOutlined } from "@ant-design/icons";

import { Form, Button } from "antd";

import _ from "lodash";

import enhance from "./script";

class FormProductCost extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { dataSource: this.props.pakaging.details || [] };
  }

  updateDataSource = (prvDataSource) => (index, dataIndex, dataSource) => {
    while (prvDataSource.length) {
      prvDataSource.shift();
    }
    dataSource.forEach((item) => {
      prvDataSource.push(item);
    });
  };
  _handleCheckRadio = (e) => {
    const { setFieldValue } = this.props;
    setFieldValue("type", e.target.value);
  };
  handleChangeInputValue = (e) => {
    const { handleChange } = this.props;
    handleChange(e);
    let name = e.target.name;
    this.props.pakaging[name] = e.target.value;
  };
  handleChangeInputValueNumber = (e) => {
    const { handleChange } = this.props;
    handleChange(e);
    let name = e.target.name;
    this.props.pakaging[name] = e.target.valueAsNumber;
  };
  calSumPercent() {
    let sumPercent = 0;
    for (let i = 0; i < this.props.percentSizeArr.length; i++) {
      sumPercent += this.props.percentSizeArr[i]["value" + i];
    }
    return sumPercent;
  }
  onRemoveAllocationItem = (index, item, event, percentSizeArr) => {
    if (!this.props.editable) {
      return;
    }
    const { setPercentSizeArr } = this.props;
    let tempArr = [...percentSizeArr];
    tempArr = _.remove(tempArr, (itemArr) => {
      return itemArr.id !== item.id;
    });
    tempArr[tempArr.length - 1][`validateValue${tempArr.length - 1}`] =
      strings.total_allocations_should_be_100_percent;
    setPercentSizeArr(tempArr);
    this.props.pakaging.classifications = tempArr;
  };
  addAllocation = (e, percentSizeArr) => {
    const { setPercentSizeArr } = this.props;
    // thêm phần tử vào percentSizeArr
    let tempArr = [...percentSizeArr];
    let idTemp = getNumber(tempArr[tempArr.length - 1], "id", 0);
    let value = percentSizeArr[idTemp][`typeId${idTemp}`];
    // filter dataOptions trước khi add
    let dataFilter = _.filter(
      this.props.percentSizeArr[idTemp][`dataOptions${idTemp}`],
      (item) => {
        return item.value !== value;
      }
    );
    // cal percent max
    let newPercentMax = 100 - this.calSumPercent();

    // clear validate
    tempArr[idTemp]["validateValue" + idTemp] = undefined;
    tempArr[idTemp]["validateOption" + idTemp] = undefined;

    let newId = parseInt(idTemp) + 1;
    tempArr.push({
      id: newId,
      ["typeId" + newId]: undefined,
      ["value" + newId]: 0,
      ["percentMax" + newId]: newPercentMax,
      ["dataOptions" + newId]: [...dataFilter],
      ["validateOption" + newId]: strings.please_choose_a_category,
      ["validateValue" + newId]:
        strings.total_allocations_should_be_100_percent,
    });
    setPercentSizeArr(tempArr);
    this.props.pakaging.classifications = tempArr;
  };
  componentWillUnmount() {
    const { percentSizeArr, setPercentSizeArr } = this.props;
    this.props.pakaging.classifications = [...percentSizeArr];
    setPercentSizeArr({});
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.pakaging.productId !== nextProps.pakaging.productId) {
      nextProps.fetchAllSizeClass(nextProps.pakaging.productId);
    }
    if (this.state.dataSource !== nextProps.pakaging.details) {
      this.setState({ dataSource: nextProps.pakaging.details || [] });
    }
    const { setPercentSizeArr } = this.props;

    // set default unit
    try {
      // load again classifications to percentSizeArr
      const percentArrThisProps = getArray(this.props, "percentSizeArr", []);
      const classificationsNextProps = getArray(
        nextProps,
        "pakaging.classifications",
        []
      );

      if (
        classificationsNextProps.length !== 0 &&
        percentArrThisProps.length === 0
      ) {
        setPercentSizeArr(classificationsNextProps);
      }
      if (
        classificationsNextProps.length !== 0 &&
        percentArrThisProps.length !== 0
      ) {
        if (
          classificationsNextProps[0][`typeId${0}`] &&
          !percentArrThisProps[0][`typeId${0}`]
        ) {
          setPercentSizeArr(() => {
            let dataTypeIds = percentArrThisProps[0][`dataOptions${0}`];
            let dataResult = [];
            for (let i = 0; i < classificationsNextProps.length; i++) {
              if (i !== 0)
                dataTypeIds = _.filter(dataTypeIds, (item) => {
                  return (
                    item.value !==
                    classificationsNextProps[i - 1][`typeId${i - 1}`]
                  );
                });
              dataResult.push({
                ...classificationsNextProps[i],
                ["dataOptions" + i]: dataTypeIds,
              });
            }
            return dataResult;
          });
        }
      }
    } catch (err) {
      console.error(
        "SeasonpakagingForms -> UNSAFE_componentWillReceiveProps -> err",
        err
      );
      showError(err);
    }
  }
  render() {
    const {
      values,
      handleBlur,
      touched,
      errors,
      sizeClass,
      loadingTypeProcess,
      percentSizeArr,
      product,
      setFieldValue,
      setFieldTouched,
      unit,
      expalin,
    } = this.props;
    const { editable } = this.props;
    this.props.pakaging.errors = errors;

    let columns = [
      {
        title: strings.info_impact_to_price_want,
        dataIndex: "influence",
        width: "200px",
        editable: true,
        placeholder: strings.info_impact_to_price_want,
        type: "select",
        options: expalin,
      },
      {
        title: strings.unit,
        dataIndex: "unitId",
        width: "150px",
        editable: true,
        placeholder: strings.unit,
        type: "select",
        options: unit,
      },
      {
        title: strings.price_for_golbalgrap,
        dataIndex: "globalGap",
        width: "150px",
        editable: true,
        placeholder: strings.price_for_golbalgrap,
        type: "number",
      },
      {
        title: strings.type_shove_garen,
        dataIndex: "type1",
        width: "150px",
        editable: true,
        placeholder: strings.type_shove_garen,
        type: "number",
      },
      {
        title: strings.type_one_go_port,
        dataIndex: "type2",
        width: "150px",
        editable: true,
        placeholder: strings.type_one_go_port,
        type: "number",
      },
      {
        title: strings.type_two_go_pochai,
        dataIndex: "type3",
        width: "150px",
        editable: true,
        placeholder: strings.type_two_go_pochai,
        type: "number",
      },
      {
        title: strings.type_three__dat_than,
        dataIndex: "type4",
        width: "150px",
        editable: true,

        placeholder: strings.type_three__dat_than,
        type: "number",
      },
      {
        title: strings.type_four_remove,
        dataIndex: "type5",
        width: "150px",
        editable: true,

        placeholder: strings.type_four_remove,
        type: "number",
      },
      {
        title: strings.resuilt,
        dataIndex: "result",
        width: "150px",
        editable: false,
        placeholder: strings.resuilt,
        type: "number",
      },
    ];
    return (
      <Form onFinish={() => {}}>
        <div className="row d-flex">
          <div className="col-12 col-lg-6">
            <SelectField
              loading={loadingTypeProcess}
              validateStatus={
                touched.productId && errors.productId ? "error" : undefined
              }
              disabled={!editable}
              help={
                touched.productId && errors.productId ? errors.productId : ""
              }
              data={product || []}
              value={values.productId}
              onChange={(value) => {
                this.props.pakaging.productId = value;
                this.props.pakaging.sizeClassificationsIds = undefined;
                setFieldValue("productId", value);
                setFieldValue("sizeClassificationsIds", undefined);
                setFieldTouched("productId");
                this.props.fetchAllSizeClass(value);
              }}
              iconEnd="caret-down"
              placeholder={strings.chose_type_tree}
              label={strings.type_tree}
            />
            <div className="row">
              <div className="col-lg-8 col-12">
                <InputField
                  disabled={!editable}
                  validatestatus={
                    touched.avrQuantity && errors.avrQuantity
                      ? "error"
                      : undefined
                  }
                  help={
                    touched.avrQuantity && errors.avrQuantity
                      ? errors.avrQuantity
                      : ""
                  }
                  name="avrQuantity"
                  type="number"
                  value={values.avrQuantity}
                  onChange={this.handleChangeInputValueNumber}
                  onBlur={handleBlur}
                  placeholder={strings.average_yield}
                  label={strings.average_yield}
                />
              </div>
              <div className="col-lg-4 col-12">
                <SelectField
                  loading={loadingTypeProcess}
                  validateStatus={
                    touched.productId && errors.productId ? "error" : undefined
                  }
                  disabled={!editable}
                  help={
                    touched.productId && errors.productId
                      ? errors.productId
                      : ""
                  }
                  data={[{ value: "kg/trụ/vụ", label: "kg/trụ/vụ" }]}
                  value={"kg/trụ/vụ"}
                  onChange={() => {}}
                  iconEnd="caret-down"
                  placeholder={strings.unit}
                  label={strings.unit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-12">
                <InputField
                  disabled={!editable}
                  validatestatus={
                    touched.avrSeason && errors.avrSeason ? "error" : undefined
                  }
                  help={
                    touched.avrSeason && errors.avrSeason
                      ? errors.avrSeason
                      : ""
                  }
                  name="avrSeason"
                  value={values.avrSeason}
                  onChange={this.handleChangeInputValueNumber}
                  type="number"
                  onBlur={handleBlur}
                  placeholder={strings.number_season_on_one_year}
                  label={strings.number_season_on_one_year}
                />
              </div>
              <div className="col-lg-4 col-12">
                <SelectField
                  loading={loadingTypeProcess}
                  validateStatus={
                    touched.productId && errors.productId ? "error" : undefined
                  }
                  disabled={!editable}
                  help={
                    touched.productId && errors.productId
                      ? errors.productId
                      : ""
                  }
                  data={[{ value: "vụ", label: "vụ" }]}
                  value={"vụ"}
                  onChange={() => {}}
                  iconEnd="caret-down"
                  placeholder={strings.unit}
                  label={strings.unit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-12">
                <InputField
                  disabled={!editable}
                  validatestatus={
                    touched.avrYield && errors.avrYield ? "error" : undefined
                  }
                  help={
                    touched.avrYield && errors.avrYield ? errors.avrYield : ""
                  }
                  name="avrYield"
                  type="number"
                  value={values.avrYield}
                  onChange={this.handleChangeInputValueNumber}
                  onBlur={handleBlur}
                  placeholder={strings.productivity_one_year}
                  label={strings.productivity_one_year}
                />
              </div>
              <div className="col-lg-4 col-12">
                <SelectField
                  loading={loadingTypeProcess}
                  validateStatus={
                    touched.productId && errors.productId ? "error" : undefined
                  }
                  disabled={!editable}
                  help={
                    touched.productId && errors.productId
                      ? errors.productId
                      : ""
                  }
                  data={[{ value: "tấn/ha/năm", label: "tấn/ha/năm" }]}
                  value={"tấn/ha/năm"}
                  onChange={() => {}}
                  iconEnd="caret-down"
                  placeholder={strings.unit}
                  label={strings.unit}
                />
              </div>
            </div>
            {percentSizeArr.map((item, index) => {
              return (
                <>
                  <div className="row" key={index}>
                    <div className="col-12 col-lg-8">
                      <SelectField
                        validateStatus={
                          item[`validateOption${index}`] !== undefined
                            ? "error"
                            : undefined
                        }
                        disabled={
                          !editable || index !== percentSizeArr.length - 1 // disabled các phần tử trước
                        }
                        help={
                          item[`validateOption${index}`] !== undefined
                            ? item[`validateOption${index}`]
                            : undefined
                        }
                        data={percentSizeArr[index][`dataOptions${index}`]}
                        value={percentSizeArr[index][`typeId${index}`]}
                        onChange={(value) => {
                          percentSizeArr[index][`typeId${index}`] = value;
                          setFieldValue(`typeId${index}`, value);
                          setFieldTouched(`typeId${index}`);
                          percentSizeArr[index][`validateOption${index}`] =
                            undefined;
                          percentSizeArr[index][`validateValue${index}`] =
                            strings.total_allocations_should_be_100_percent;
                          if (this.calSumPercent() === 100) {
                            percentSizeArr[index][`validateValue${index}`] =
                              undefined;
                            if (percentSizeArr[index][`typeId${index}`])
                              percentSizeArr[index][`validateOption${index}`] =
                                undefined;
                          } else {
                            percentSizeArr[index][`validateValue${index}`] =
                              strings.total_allocations_should_be_100_percent;
                          }
                        }}
                        iconEnd="caret-down"
                        placeholder={strings.allocation_per_tree}
                        label={
                          index === 0
                            ? `${strings.allocation_per_tree} (*)`
                            : undefined
                        }
                      />
                    </div>
                    <div className="col-12 col-lg-4">
                      <InputNumberPro
                        min={0}
                        max={
                          index === 0
                            ? 100
                            : percentSizeArr[index][`percentMax${index}`]
                        }
                        name={`value${index}`}
                        validatestatus={
                          item[`validateValue${index}`] !== undefined
                            ? "error"
                            : undefined
                        }
                        help={
                          item[`validateValue${index}`] !== undefined
                            ? item[`validateValue${index}`]
                            : undefined
                        }
                        disabled={
                          !editable || index !== percentSizeArr.length - 1 // disabled các phần tử trước
                        }
                        suffix={` ${strings.percent}`}
                        value={percentSizeArr[index][`value${index}`]}
                        onChange={(value) => {
                          percentSizeArr[index][`value${index}`] = value;
                          setFieldValue(`value${index}`, value);
                          setFieldTouched(`value${index}`);
                          if (
                            (value < 100 && index === 0) ||
                            this.calSumPercent() < 100
                          ) {
                            percentSizeArr[index][`validateValue${index}`] =
                              strings.total_allocations_should_be_100_percent;
                          } else {
                            percentSizeArr[index][`validateValue${index}`] =
                              undefined;
                          }
                          if (
                            percentSizeArr[index][`validateOption${index}`] ===
                              undefined &&
                            percentSizeArr[index][`typeId${index}`] ===
                              undefined
                          )
                            percentSizeArr[index][`validateOption${index}`] =
                              strings.please_choose_a_category;
                        }}
                        onBlur={() => {
                          this.props.pakaging.classifications = [
                            ...percentSizeArr,
                          ];
                          if (this.calSumPercent() === 100) {
                            percentSizeArr[index][`validateValue${index}`] =
                              undefined;
                            if (percentSizeArr[index][`typeId${index}`])
                              percentSizeArr[index][`validateOption${index}`] =
                                undefined;
                          } else {
                            percentSizeArr[index][`validateValue${index}`] =
                              strings.total_allocations_should_be_100_percent;
                          }
                        }}
                        label={
                          index === 0
                            ? `${strings.percent_name} (*)`
                            : undefined
                        }
                      />
                    </div>
                    {index === percentSizeArr.length - 1 && index !== 0 ? (
                      <div
                        className="col-1 ml-0 pl-0 d-flex flex-column cursor-pointer "
                        onClick={(e) =>
                          this.onRemoveAllocationItem(
                            index,
                            item,
                            e,
                            percentSizeArr
                          )
                        }>
                        <MinusCircleOutlined />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {index === percentSizeArr.length - 1 &&
                  percentSizeArr.length < this.props[`typeId`].length &&
                  this.calSumPercent() < 100 &&
                  percentSizeArr[index][`typeId${index}`] &&
                  this.calSumPercent() > 0 ? (
                    <>
                      <div className="row d-flex flex-row justify-content-center align-items-center">
                        <Button
                          type="dashed"
                          onClick={(e) =>
                            this.addAllocation(e, percentSizeArr)
                          }>
                          {strings.allocation_add}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
            <div className="row">
              <div className="col-lg-8 col-12">
                <InputField
                  disabled={!editable}
                  validatestatus={
                    touched.surveyPrice && errors.surveyPrice
                      ? "error"
                      : undefined
                  }
                  help={
                    touched.surveyPrice && errors.surveyPrice
                      ? errors.surveyPrice
                      : ""
                  }
                  name="surveyPrice"
                  type="number"
                  value={values.surveyPrice}
                  onChange={this.handleChangeInputValueNumber}
                  onBlur={handleBlur}
                  placeholder={strings.price_product_for_sur}
                  label={strings.price_product_for_sur}
                />
              </div>
              <div className="col-lg-4 col-12">
                <SelectField
                  loading={loadingTypeProcess}
                  validateStatus={
                    touched.productId && errors.productId ? "error" : undefined
                  }
                  disabled={!editable}
                  help={
                    touched.productId && errors.productId
                      ? errors.productId
                      : ""
                  }
                  data={[{ value: "đ/1kg", label: "đ/1kg" }]}
                  value={"đ/1kg"}
                  onChange={() => {}}
                  iconEnd="caret-down"
                  placeholder={strings.unit}
                  label={strings.unit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-12">
                <InputField
                  disabled={!editable}
                  validatestatus={
                    touched.modelGroup && errors.modelGroup
                      ? "error"
                      : undefined
                  }
                  help={
                    touched.modelGroup && errors.modelGroup
                      ? errors.modelGroup
                      : ""
                  }
                  name="modelGroup"
                  value={values.modelGroup}
                  onChange={this.handleChangeInputValue}
                  onBlur={handleBlur}
                  placeholder={strings.MH_VH_for_group_small}
                  label={strings.MH_VH_for_group_small}
                />
              </div>
              <div className="col-lg-4 col-12">
                <SelectField
                  loading={loadingTypeProcess}
                  validateStatus={
                    touched.productId && errors.productId ? "error" : undefined
                  }
                  disabled={!editable}
                  help={
                    touched.productId && errors.productId
                      ? errors.productId
                      : ""
                  }
                  data={[{ value: "hộ/nhóm", label: "hộ/nhóm" }]}
                  value={"hộ/nhóm"}
                  onChange={() => {}}
                  iconEnd="caret-down"
                  placeholder={strings.unit}
                  label={strings.unit}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <InputField
              disabled={!editable}
              validatestatus={touched.name && errors.name ? "error" : undefined}
              help={touched.name && errors.name ? errors.name : ""}
              name="name"
              value={values.name}
              onChange={this.handleChangeInputValue}
              onBlur={handleBlur}
              placeholder={strings.name_sale_price}
              label={strings.name_sale_price}
            />
            <SelectField
              loading={loadingTypeProcess}
              validateStatus={
                touched.sizeClassificationsIds && errors.sizeClassificationsIds
                  ? "error"
                  : undefined
              }
              disabled={!editable}
              help={
                touched.sizeClassificationsIds && errors.sizeClassificationsIds
                  ? errors.sizeClassificationsIds
                  : ""
              }
              data={sizeClass}
              mode="multiple"
              value={values.sizeClassificationsIds}
              onChange={(value) => {
                this.props.setFieldValue("sizeClassificationsIds", value);
                this.props.pakaging.sizeClassificationsIds = value;
              }}
              iconEnd="caret-down"
              placeholder={strings.classify_size_seed}
              label={strings.classify_size_seed}
            />
            <div className="row">
              <div className="col-lg-8 col-12">
                <InputField
                  disabled={!editable}
                  validatestatus={
                    touched.seedDensity && errors.seedDensity
                      ? "error"
                      : undefined
                  }
                  help={
                    touched.seedDensity && errors.seedDensity
                      ? errors.seedDensity
                      : ""
                  }
                  name="seedDensity"
                  type="number"
                  value={values.seedDensity}
                  onChange={this.handleChangeInputValueNumber}
                  onBlur={handleBlur}
                  placeholder={strings.number_post_one_ha_land}
                  label={strings.number_post_one_ha_land}
                />
              </div>
              <div className="col-lg-4 col-12">
                <SelectField
                  loading={loadingTypeProcess}
                  validateStatus={
                    touched.productId && errors.productId ? "error" : undefined
                  }
                  disabled={!editable}
                  help={
                    touched.productId && errors.productId
                      ? errors.productId
                      : ""
                  }
                  data={[{ value: "trụ", label: "trụ" }]}
                  value={"trụ"}
                  onChange={() => {}}
                  iconEnd="caret-down"
                  placeholder={strings.unit}
                  label={strings.unit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-12">
                <InputField
                  disabled={!editable}
                  validatestatus={
                    touched.expectedPrice && errors.expectedPrice
                      ? "error"
                      : undefined
                  }
                  help={
                    touched.expectedPrice && errors.expectedPrice
                      ? errors.expectedPrice
                      : ""
                  }
                  name="expectedPrice"
                  type="number"
                  value={values.expectedPrice}
                  onChange={this.handleChangeInputValueNumber}
                  onBlur={handleBlur}
                  placeholder={strings.price_want}
                  label={strings.price_want}
                />
              </div>
              <div className="col-lg-4 col-12">
                <SelectField
                  loading={loadingTypeProcess}
                  validateStatus={
                    touched.productId && errors.productId ? "error" : undefined
                  }
                  disabled={!editable}
                  help={
                    touched.productId && errors.productId
                      ? errors.productId
                      : ""
                  }
                  data={[{ value: "đ/kg", label: "đ/kg" }]}
                  value={"đ/kg"}
                  onChange={() => {}}
                  iconEnd="caret-down"
                  placeholder={strings.unit}
                  label={strings.unit}
                />
              </div>
            </div>
            <InputNumberPro
              min={0}
              max={100}
              validatestatus={
                touched.rateOfProfit && errors.rateOfProfit
                  ? "error"
                  : undefined
              }
              help={
                touched.rateOfProfit && errors.rateOfProfit
                  ? errors.rateOfProfit
                  : undefined
              }
              name="rateOfProfit"
              disabled={!editable}
              suffix={`%`}
              value={values.rateOfProfit}
              onChange={(value) => {
                this.props.pakaging.rateOfProfit = value;
                setFieldValue("rateOfProfit", value);
                setFieldTouched("rateOfProfit");
              }}
              onBlur={handleBlur}
              placeholder={strings.per_profit_want}
              label={strings.per_profit_want}
            />
            <div className="row">
              <div className="col-lg-8 col-12">
                <InputField
                  disabled={!editable}
                  validatestatus={
                    touched.minLinkArea && errors.minLinkArea
                      ? "error"
                      : undefined
                  }
                  help={
                    touched.minLinkArea && errors.minLinkArea
                      ? errors.minLinkArea
                      : ""
                  }
                  name="minLinkArea"
                  type="number"
                  value={values.minLinkArea}
                  onChange={this.handleChangeInputValueNumber}
                  onBlur={handleBlur}
                  placeholder={strings.Agree_link_sm}
                  label={strings.Agree_link_sm}
                />
              </div>
              <div className="col-lg-4 col-12">
                <SelectField
                  loading={loadingTypeProcess}
                  validateStatus={
                    touched.productId && errors.productId ? "error" : undefined
                  }
                  disabled={!editable}
                  help={
                    touched.productId && errors.productId
                      ? errors.productId
                      : ""
                  }
                  data={[{ value: "ha", label: "ha" }]}
                  value={"ha"}
                  onChange={() => {}}
                  iconEnd="caret-down"
                  placeholder={strings.unit}
                  label={strings.unit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-12">
                <InputField
                  disabled={!editable}
                  validatestatus={
                    touched.maxLinkGroup && errors.maxLinkGroup
                      ? "error"
                      : undefined
                  }
                  help={
                    touched.maxLinkGroup && errors.maxLinkGroup
                      ? errors.maxLinkGroup
                      : ""
                  }
                  name="maxLinkGroup"
                  type="number"
                  value={values.maxLinkGroup}
                  onChange={this.handleChangeInputValueNumber}
                  onBlur={handleBlur}
                  placeholder={strings.number_group_link_than}
                  label={strings.number_group_link_than}
                />
              </div>
              <div className="col-lg-4 col-12">
                <SelectField
                  loading={loadingTypeProcess}
                  validateStatus={
                    touched.productId && errors.productId ? "error" : undefined
                  }
                  disabled={!editable}
                  help={
                    touched.productId && errors.productId
                      ? errors.productId
                      : ""
                  }
                  data={[{ value: "Nhóm", label: "Nhóm" }]}
                  value={"Nhóm"}
                  onChange={() => {}}
                  iconEnd="caret-down"
                  placeholder={strings.unit}
                  label={strings.unit}
                />
              </div>
            </div>
          </div>

          <Wrap>
            <TableEdit
              editable={editable}
              changedData={this.updateDataSource(this.props.pakaging.details)}
              dataSource={this.state.dataSource || []}
              columns={columns}
            />
          </Wrap>
        </div>
      </Form>
    );
  }
}
export default enhance(FormProductCost);
