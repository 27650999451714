import React from "react";
import {} from "~/state/ducks/appApis/actions";
import { connect } from "react-redux";
import { getFarmSeasonCRUD } from "~/state/ducks/appApis/actions";
import strings from "~/localization";
import _ from "lodash";

import FarmSeasonLedgerHistory from "./FarmSeasonLedger";
import { getObject } from "~/views/utilities/helpers/utilObject";
import TreeMenu from "~/views/presentation/ui/navigation/Sidebar";

import { Layout } from "antd";
import { MenuAction } from "~/views/container/components";

import { Performance } from "~/views/container/components";
import WeatherChart from "~/views/presentation/ui/chart/weatherChart";
import { WrapDashboardContent } from "~/views/container/Farm/styles";
import InfoSeason from "./InfoSeason";
import { TitleCustom } from "~/views/presentation/ui/dashboard";
import { showError } from "~/configs/ServerErrors";
import { EDIT_SEASON_CRUD_FARM_PATH } from "~/configs/routesConfig";
import { withRouterInnerRef } from "~/views/container/commons";

const { Content } = Layout;

class FarmSeasonLedger extends React.PureComponent {
  constructor(props) {
    super(props);
    let editable = true;
    this.state = {
      editable: editable,
      seasonInfo: {},
    };
  }
  handelButtonClick = () => {
    const { history, match } = this.props;
    history.push(
      EDIT_SEASON_CRUD_FARM_PATH.replace(
        ":idSeason",
        match.params.idSeason
      ).replace(":id", match.params.id)
    );
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { getFarmSeasonCRUD } = this.props;
    getFarmSeasonCRUD(this.props.match.params.idSeason)
      .then(async ({ res }) => {
        this.setState({ seasonInfo: getObject(res, undefined, []) });
      })
      .catch((err) => {
        console.error("FarmSeasonLedger -> fetchData -> err", err);
        showError(err);
      });
  };

  renderFarmSeasonHistory() {
    const { nameFarm, farmSeasonName } = this.props;

    return (
      <div className="w-100 row pl-15">
        <div className="farm-season__table col-12 col-lg-8">
          <TitleCustom title={strings.farming_history} slot={<MenuAction />} />
          <div className="shadow">
            <FarmSeasonLedgerHistory nameFarm={nameFarm} />
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <TitleCustom
            title={strings.seasonal_information}
            slot={
              <MenuAction
                icon={
                  <i
                    className="fa fa-pencil-square-o"
                    aria-hidden="true"
                    style={{ fontSize: "1.3em", cursor: "pointer" }}></i>
                }
                onClick={this.handelButtonClick}
              />
            }
          />
          <div className="bg-fuchsia farming-calendar mt-1 shadow">
            <h4 className="title">{farmSeasonName}</h4>
            <div className="list-item">
              <InfoSeason
                farmSeasonName={farmSeasonName}
                nameFarm={nameFarm}
                seasonInfo={this.state.seasonInfo}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderIoTDevice() {
    return (
      <div className="w-100 row pl-15">
        <div className="col-12 ">
          <TitleCustom
            title={strings.environmental_parameters_from_the_iodine_device}
            slot={<MenuAction />}
          />
        </div>

        <div className="col-12 col-md-6 col-lg-4">
          <WeatherChart title={strings.climate} height={250} />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <WeatherChart title={strings.humidity} height={250} />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <WeatherChart title={strings.temperature} height={250} />
        </div>
      </div>
    );
  }

  renderPerformance() {
    return (
      <Performance title={strings.farm_performance} info={this.props.info} />
    );
  }

  state = {
    collapsed: false,
  };

  render() {
    return (
      <div>
        <WrapDashboardContent className="d-flex justify-content-end">
          <div className="right-content">
            {this.renderIoTDevice()}
            {this.renderFarmSeasonHistory()}
          </div>
        </WrapDashboardContent>

        <TreeMenu
          className={this.props.className}
          menus={this.props.treeMenu}
        />
      </div>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      getFarmSeasonCRUD,
    },
    null,
    { forwardRef: true }
  )(React.memo(FarmSeasonLedger))
);
