const vi = {
  header_logo_name: "AgriSys",
  menu_header_dashboard: "Tổng Quan",
  menu_header_information_system: "Hệ thống Thông tin",
  menu_header_farm_management: "Quản Lý Trang Trại",
  menu_header_cultivation_process: "Quy Trình Canh Tác",
  menu_header_commercial_management: "Quản Lý Thương Mại",
  menu_header_report: "Báo Cáo",
  menu_header_config: "Cấu Hình",

  /**
   * Server localization
   */
  TOKEN_EXPIRED: "Hết phiên đăng nhập",
  server_resetkey_invalid: "Mã OTP không chính xác",
  server_TOKEN_EXPIRED: "Hết phiên đăng nhập",
  server_email_already_used:
    "Email hoặc Số điện thoại đã được sử dụng để đăng ký tài khoản",
  server_error_validation: "Dữ liệu nhập vào không đúng định dạng",
  server_user_notfound: "Tài khoản không tồn tại",
  server_account_notfound: "Tài khoản không tồn tại",
  server_can_not_connect: "Không thể kết nối đến máy chủ!!!",
  server_maintenance: "Máy chủ đang bảo trì",
  server_user_login_invalid:
    "Tài khoản đăng nhập hoặc mật khẩu không chính xác",
  server_user_not_activated: "Tài khoản chưa được kích hoạt",
  server_activationkey_incorrect: "Mã xác thực không chính xác",
  server_email_invalid: "Email không hợp lệ",
  server_phone_invalid: "Số điện thoại không hợp lệ",
  /**
   * global validation
   */
  phone_or_email_required: "Vui lòng nhập email hoặc số điện thoại",
  invalid_email: "Email không hợp lệ",
  email_required: "Vui lòng nhập Email",
  password_not_matched: "Mật khẩu không trùng khớp",
  invalid_email_or_phone: "Email/Số điện thoại không hợp lệ",
  required: "Vui lòng nhập thông tin",
  password_required: "Vui lòng nhập mật khẩu",
  more_than_zero: "Giá trị phải lớn hơn 0",
  invalid_password: "Mật khẩu từ 6 ký tự trở lên",
  max_length: "Tối đa {max} ký tự",
  image_max_size: "Dung lượng tối đa hình ảnh là {size} MB",
  file_max_size: "Dung lượng tối đa của tệp tin tải lên là {size} MB",
  must_be_number: "Vui lòng nhập số",

  /**
   * enum
   */
  CREATE_PRICE_STRUCTURE: "Tạo cơ cấu giá bán",
  UPDATE_PRICE_STRUCTURE: "Sửa cơ cấu giá bán",
  DELETE_PRICE_STRUCTURE: "Xóa cơ cấu giá bán",
  UPDATE_MANUFACTURING_COST: "Cập nhật cấu trúc chi phí sản xuất",
  CREATE_MANUFACTURING_COST: "Tạo cấu trúc chi phí sản xuất",
  DELETE_MANUFACTURING_COST: "Xóa cấu trúc chi phí sản xuất",
  CREATE_PRODUCT_COST: "Tạo CT CP SX",
  EDIT_PRODUCT_COST: "Sửa CT CP SX",
  CREATE_SALE_PRICE: "Tạo cơ cấu giá bán",
  CREATE_PAKAGING_COST: "Tạo CT CP ĐG",
  EDIT_PAKAGING_COST: "Sửa CT CP ĐG",
  EDIT_SALE_PRICE: "Sửa cơ cấu giá bán",
  UPDATE_PACKAGING_COST: "Sửa chi phí đóng gói",
  CREATE_SEASON_PROCESS: "Tạo quy trình",
  UPDATE_PUBLISH_PRODUCT: "Cập nhật phiếu đăng bán",
  DELETE_PUBLISH_PRODUCT: "Xoá phiếu đăng bán",
  CREATE_PUBLISH_PRODUCT: "Tạo phiếu đăng bán",
  UPDATE_SEASON_PROCESS: "Cập nhật quy trình",
  DELETE_SEASON_PROCESS: "Xoá quy trình",
  CREATE_PERSIONAL_PROFILE: "Tạo hồ sơ cá nhân",
  UPDATE_PERSIONAL_PROFILE: "Cập nhật hồ sơ cá nhân",
  DELETE_PERSIONAL_PROFILE: "Xoá hồ sơ cá nhân",
  UPDATE_FARM_PROFILE: "Cập nhật hồ sơ trang trại",
  CREATE_FARM_PROFILE: "Tạo hồ sơ trang trại",
  DELETE_FARM_PROFILE: "Xoá hồ sơ trang trại",
  UPDATE_ENTERPRISE_PROFILE: "Cập nhật hồ sơ doanh nghiệp",
  CREATE_ENTERPRISE_PROFILE: "Tạo hồ sơ doanh nghiệp",
  DELETE_ENTERPRISE_PROFILE: "Xoá hồ sơ doanh nghiệp",
  UPDATE_COOPERATIVE_PROFILE: "Cập nhật hồ sơ hợp tác xã",
  CREATE_COOPERATIVE_PROFILE: "Tạo hồ sơ hợp tác xã",
  DELETE_COOPERATIVE_PROFILE: "Xoá hồ sơ hợp tác xã",
  UPDATE_COOPERATIVE_VENTURE_PROFILE: "Cập nhật hồ sơ liên hợp tác xã",
  CREATE_COOPERATIVE_VENTURE_PROFILE: "Tạo hồ sơ liên hợp tác xã",
  DELETE_COOPERATIVE_VENTURE_PROFILE: "Xoá hồ sơ liên hợp tác xã",
  UPDATE_ORGANIZATION_PROFILE: "Cập nhật hồ sơ tổ chức",
  CREATE_ORGANIZATION_PROFILE: "Tạo hồ sơ tổ chức",
  DELETE_ORGANIZATION_PROFILE: "Xoá hồ sơ tổ chức",
  UPDATE_FARM_SEASON: "Cập nhật mùa vụ",
  CREATE_FARM_SEASON: "Tạo mùa vụ",
  DELETE_FARM_SEASON: "Xoá mùa vụ",
  UPDATE_ACTUAL_HARVEST: "Chỉnh sửa tồn kho",
  CREATE_ACTUAL_HARVEST: "Tạo tồn kho",
  DELETE_ACTUAL_HARVEST: "Xóa tồn kho",
  UPDATE_EXPECTED_HARVEST: "Chỉnh sửa tồn kho dự kiến",
  CREATE_EXPECTED_HARVEST: "Tạo tồn kho dự kiến",
  DELETE_EXPECTED_HARVEST: "Xoá tồn kho dự kiến",
  UPDATE_PACKAGING_COS: "Chỉnh chi phí đóng gói",
  CREATE_PACKAGING_COST: "Tạo chi phí đóng gói",
  DELETE_PACKAGING_COS: "Xoá chi phí đóng gói",
  /**
   * Client localization
   */
  //conflig

  coppyright: "Copyright © 2023 EdenHub,",
  powered: "Powered by ",
  edenhub_jsc: "Edenhub JSC",
  rules: "Chính sách bảo mật | Điều khoản dịch vụ",
  welcom_to: "Welcom to",
  Farm_management_backoffice: "Farm management backoffice",
  app_name: "Online Farm Portal",
  app_des:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  app_title: "Ứng dụng quản lý trang trại edenhub",
  home: "Trang chủ",
  login: "Đăng nhập",
  login_anotation: "Đăng nhập để sử dụng các ứng dụng trong nền tảng",
  login_placeholder: "Email hoặc số điện thoại",
  password: "Mật khẩu",
  reenter_password: "Nhập lại mật khẩu",
  forgot_password: "Quên mật khẩu?",
  register_an_account: "Đăng ký ngay!",
  dont_have_acount: "Chưa có tài khoản?",
  intro:
    "<p>AgriSys là nền tảng quản lý trang trại dành cho nông dân hoặc nhà sản xuất được phát triển bởi công ty công nghệ nông nghiệp, bao gồm hệ thống các ứng dụng hỗ trợ nông dân từ trang trại đến thương mại hoá sản phẩm. Người dùng có thể cài đặt từng ứng dụng để sử dụng hoặc gỡ cài đặt nếu không sử dụng.</p><p>Cảm ơn đã đồng hành cùng AgriTech. Mọi thông tin hoặc thắc mắc vui lòng gửi về địa chỉ email hotro@global-agritech.</p>",
  copy_right: "Copyright @2020 by AgriTech Inc.",
  recovery: "Lấy lại mật khẩu",
  recovery_anotation: "Theo chỉ dẫn dưới đây để lấy lại mật khẩu tài khoản",
  continue: "Tiếp tục",
  back: "Quay lại",
  wanna_login: "Đăng nhập?",
  email_otp_sent:
    "Chúng tôi đã gửi 6 ký tự để xác thực tài khoản chủ thể tới email {email}",
  phone_otp_sent:
    "Chúng tôi đã gửi 6 ký tự để xác thực tài khoản chủ thể tới số điện thoại {phone}",
  your_account: "Tài khoản của bạn: {account}",
  completed: "Hoàn thành",
  new_password: "Mật khẩu mới",
  confirm_password: "Nhập lại mật khẩu",
  register: "Đăng ký",
  register_anotation:
    "Vui lòng nhập vào thông tin theo mẫu dưới đây để đăng ký tài khoản",
  login_with_your_account: "Đã có tài khoản? Đăng nhập",
  full_name: "Họ và tên",
  phone: "Số điện thoại",
  email: "Email",
  choose_business_model: "Chọn loại hình hoạt động",
  farming_business_placeholder:
    "Vui lòng cung cấp thêm thông tin về trang trại hoặc doanh nghiệp",
  agree_agriSys_term: "Đồng ý các điều khoản của AgriSys",
  completed_registry:
    "<p>Cảm ơn đã đăng ký tài khoản của AgriSys, chúng tôi sẽ liên hệ với quý khách sớm nhất để hoàn tất các thủ tục pháp lý để có tài khoản đăng nhập.</p><p>Trong thời gian đợi, vui lòng lưới qua các sàn giao dịch bán sỉ và bán lẻ cung cấp với AgriTech hoặc tải ứng dụng về máy để trải nghiệm.</p>",
  download: "Tải ứng dụng",
  setup_profile: "Cài đặt thông tin hồ sơ",
  personal_info: "Thông tin cá nhân",
  farming_experience: "Kinh nghiệm canh tác",
  certificate: "Giấy chứng nhận",
  birthday_placeholder: "Ngày tháng năm sinh",
  level: "Trình độ",
  career: "Ngành nghề",
  identification_documents: "Giấy tờ tuỳ thân",
  address: "Địa chỉ",
  degree: "Bằng cấp",
  income: "Thu nhập",
  family: "Gia đình",
  bank: "Ngân hàng",
  document_type: "Loại giấy tờ",
  identity_number: "Số",
  get_expected: "Hàng tồn kho dự kiến",
  get_actual: "Hàng tồn kho thực",
  date_of_issue: "Ngày cấp",
  place_of_issue: "Nơi cấp",
  identification: "Định danh",
  type_chose_product: "Kiểu chọn hàng hóa",
  kyc: "KYC",
  close: "Đóng",
  add_row: "Thêm dòng mới",
  address_type: "Loại địa chỉ",
  country: "Quốc gia",
  city_or_province: "Tỉnh/Thành phố",
  district: "Quận/Huyện",
  postcode: "Mã vùng",
  degree_of_the_school: "Trường cấp",
  classification: "Xếp loại",
  year_stared: "Năm bắt đầu",
  year_ended: "Năm kết thúc",
  field: "Lĩnh vực",
  income_type: "Loại",
  description: "Mô tả",
  amount_of_money: "Số tiền",
  bank_name: "Ngân hàng",
  account_name: "Tên tài khoản",
  account_number: "Số tài khoản",
  bank_branch: "Chi nhánh",
  relation_ship: "Quan hệ",
  year_of_birth: "Năm sinh",
  identity_papers: "Giấy tờ tuỳ thân",
  ID_number: "Số",
  start_date: "Ngày bắt đầu",
  end_date: "Ngày kết thúc",
  product_type: "Loại sản phẩm",
  yield: "Sản lượng",
  quanlity: "Chất lượng",
  farming: "Canh tác",
  drag_and_drop: "Kéo và thả",
  unit: "Đơn vị",
  more_description: "Mô tả thêm chi tiết",
  history: "Lịch sử",
  type_of: "Loại hình",
  product: "Sản phẩm",
  save: "Lưu",
  cancel: "Huỷ bỏ",
  skip_setup: "Cài đặt sau",
  first_name: "Tên",
  last_name: "Họ",
  invalid_phone: "Số điện thoại không hợp lệ",
  email_or_phone: "Email/Số điện thoại",
  sent_OTP: "Chúng tôi đã gởi mã kích hoạt đến tài khoản của bạn",
  activation_successful: "Kích hoạt tài khoản thành công",
  active_account: "Kích hoạt tài khoản",
  ACTIVATED: "Đang hoạt động",
  DEACTIVATED: "Chưa áp dụng",
  active_account_anotation: "Theo chỉ dẫn dưới đây để kích hoạt tài khoản",
  reset_password_successful: "Cập nhật tài khoản thành công",
  login_failed: "Đăng nhập không thành công",
  expiration_date: "Ngày hết hạn",
  certificate_type: "Loại chứng nhận",
  issued_by: "Cấp bởi",
  endorsed: "Đã chứng thực",
  not_authenticated: "Chưa chứng thực",
  require_firstName: "Vui lòng nhập Họ",
  require_lastName: "Vui lòng nhập Tên",
  vietnam: "Việt Nam",
  completed_setup: "Hoàn thành cài đặt",
  completed_setup_description:
    "Cảm ơn đã hoàn thành thông tin cần thiết để có thể sử dụng hệ thống quản lý nông trại.",
  start_date_greater_than_end_date: "Ngày kết thúc phải lớn hơn ngày bắt đầu",
  issue_date_greater_than_expired_date: "Ngày hết hạn phải lớn hơn ngày cấp",
  activity_date_greater_than_expired_date:
    "Ngày hoạt động phải lớn hơn ngày cấp",
  reevaluate_date_greater_than_evaluate_date:
    "Ngày tái đánh giá phải lớn hơn ngày đánh giá",
  update_profile_successful: "Cập nhật hồ sơ thành công",
  create_profile_successful: "Tạo hồ sơ thành công",
  invalid_format: "Nhập sai định dạng",
  profiles_management: "Quản lý hồ sơ",
  farmings_management: "Quản lý trang trại",
  purchasing_management: "Quản lý thu mua",
  goods_management: "Quản lý hàng hoá",
  comercial_management: "Quản lý thương mại",
  orders_management: "Quản lý đơn hàng",
  partners_management: "Quản lý đối tác",
  logistics_management: "Quản lý kho vận",
  human_resources_management: "Quản lý nhân sự",
  financial_management: "Quản lý tài chính",
  messages_management: "Tin nhắn",
  reports: "Báo cáo thống kê",
  dashboard: "Tổng quan",
  profile: "Hồ sơ",
  configuration: "Cấu hình",
  all_profiles: "Tất cả hồ sơ",
  persional_profile: "Cá nhân",
  farm: "Trang trại",
  business: "Doanh nghiệp",
  cooperative: "Hợp tác xã",
  union_of_cooperative: "Liên hiệp tác xã",
  association_of_occupation: "Hiệp hội nghành nghề",
  department: "Ban sở ngành",
  officials: "Cán bộ",
  foreign: "Nước ngoài",
  upload_certificate: "Tải chứng nhận",
  applications: "Ứng dụng",
  farm_area: "DT Trang trại",
  farm_area_size: "{size} ha",
  farm_profile: "Trang trại",
  enterprise_profile: "Doanh nghiệp",
  cooperative_profile: "Hợp tác xã",
  cooperative_venture_profile: "Liên hợp tác xã",
  organization_profile: "Tổ chức",
  create_profile: "Tạo hồ sơ",
  import: "Import",
  search_placeholder: "Tìm kiếm",
  filter: "Bộ lọc",
  name: "Tên",
  profile_type: "Loại hồ sơ",
  avatar: "Ảnh đại diện",
  manager: "Người quản lý",
  sign_out: "Đăng xuất",
  myinfor: "Thông tin của tôi",
  myinfor_des: "Cài đặt thông tin tài khoản",
  changepw_des: "Thay đổi mật khẩu tài khoản của bạn",
  changepw: "Đổi mật khẩu",
  personal: "Cá nhân",
  edit_profile: "Sửa hồ sơ",
  create: "Tạo mới",
  remove_profile_confirmation: "Xoá hồ sơ này?",
  yes: "Đồng ý",
  no: "Không",
  confirmation: "Xác nhận",
  delete_profile_success: "Đã xoá hồ sơ",
  action: "Hoạt động",
  storage: "Lưu trữ",
  delete: "Xoá bỏ",
  copy: "Bản sao",
  //conflig
  farm_name: "Tên trang trại",
  sent_mess: "Gửi tin nhắn",
  history_note: "Nhật ký ghi chú",
  schedule_note: "Nhật ký ghi chú",
  type_certification: "Loại chứng nhận",
  date_range: "Ngày cấp",
  place_suplly: "Nơi cấp",
  number_cn: "Số CN",
  date_evaluate: "Ngày đánh giá",
  date_reevaluate: "Ngày tái đánh giá",
  id_land: "Mã số thửa đất",
  status_land: "Tình trạng đất sử dụng",
  his_land: "Chủ sở hữu đất",
  acreage: "Diện tích",
  before_upload_GCN: "Tải mặt trước GCN",
  after_upload_GCN: "Tải mặt sau GCN",
  human_and_GCN_upload: "GCN và người sử dụng",
  other: "Khác",
  cost_yeah: "Chi phí năm",
  tax_TNCN: "Thuế TNCN",
  tax_GTGT_yeah: "Thuế GTGT năm",
  annual_profit: "Lợi nhuận năm",
  sum_acreage: "Tổng diện tích",
  status_land_use: "Tình trạng đất SD",
  sum_human: "Số lượng nhân viên",
  number_seasons: "Số mùa vụ",
  sum_output: "Tổng sản lượng",
  consumption_market: "Thị trường tiêu thụ",
  revenue_yeah: "Doanh thu năm",
  model: "Loại hình",
  total_productivity: "Tổng năng suất",
  ward: "Xã/Phường",
  farm_info: "Thông tin trang trại",
  gcn_standard: "GCN tiêu chuẩn",
  gcn_qsdd: "GCN QSDĐ",
  activity_logs: "Lịch sử hoạt động",
  notes_of: "Ghi chú của ",
  invalid_image_upload:
    "Hình ảnh tải lên chưa đúng định dạng PNG, JPG, JPEG, BMP",
  limited_image_upload: "Bạn chỉ được upload tối đa {limit} hình",
  countinue: "Tiếp tục",
  branch_product: "Ngành",
  information_general: "Thông tin chung",
  information_detail: "Thông tin chi tiết",
  name_enterpise: "Tên doanh nghiệp",
  name_representative: "Tên người đại diện",
  sum_acreage_mana: "Tổng diện tích Q Lý",
  sum_employee: "Tổng số nhân viên",
  detail: "Chi tiết",
  not: "Không",
  have: "Có",
  structure_product_cost: "Cấu trúc chi phí sản xuất",
  structure_sale_price: "Cơ cấu giá bán",
  /**
   * HOANG TAN Upload files
   */
  type_profile: "Loại hồ sơ",
  upload_media_placeholder: "Tải lên",
  please_download_in_to_view: "Vui lòng tải xuống để xem nội dung tệp tin này!",
  delete_file_success: "Tệp tin {name} đã được xóa thành công!",
  image_not_display: "Không thể hiển thị",
  warning_create_upload:
    "Vui lòng hoàn thành khởi tạo trước khi quản lý các tệp tin!",
  warning_no_files: "Không có tệp tin nào!",
  invalid_media_upload: "Tệp tin tải lên chưa đúng định dạng MP4, IMAGE",
  invalid_docs_upload:
    "Tệp tin tải lên chưa đúng định dạng WORD, EXCEL, PDF, TXT, RAR",
  download_file: "Tải tệp tin",
  upload_docs: " Tài liệu",
  upload_docs_title: "Tài liệu",
  upload_media: " Hình/Video",
  upload_confirm_title: "Bạn chắc chắn xóa",
  upload_confirm_yes: "Có",
  upload_confirm_no: "Không",
  management_grouping: "Phân nhóm QL",
  number_to_large: "Số quá lớn",
  /**
   * Viet - avoid conflict
   */
  total_than_zero: "Nhập số lượng lớn hơn không",
  enter_product: "Nhập sản phẩm",
  id_tax: "MS Thuế",
  date_activity: "Ngày hoạt động",
  procedure: "Quy trình",
  culture: "Canh tác",
  handling: "Xử lý",
  land_water: "Đất và nước",
  enviroment: "Môi trường",
  prevention: "Phòng bệnh",
  seasons: "Mùa vụ",
  create_seasons: "Tạo Mùa vụ",
  assign_procedure: "Assign quy trình",
  leĐGer: "Lập sổ cái",
  plant_culture: "Kế hoạch canh tác",
  structure: "Cấu trúc",
  cost_product: "Chi phí SX",
  cost_pack: "Chi phí đóng gói",
  report: "Báo cáo",
  fertilizer: "Phân bón",
  medicine_BVTV: "Thuốc bảo vệ thực vật",
  create_farm: "Tạo trang trại",
  create_on: "Tạo",
  import_exit: "Nhập tồn",
  import_input: "Nhập đầu vào",
  name_farm: "Tên trang trại",
  overview_season: "Tổng quan mùa vụ",
  overview_procedure: "Tổng quan quy trình",
  leĐG: "Sổ cái",
  price: "Giá bán",
  all: "Tất cả",
  resolve_land_watter: "Xử lý đất và nước",
  resolve_envi: "Xử lý môi trường",
  resolve_prevention: "Xủ lý phòng bệnh",
  date_upadte: "Cập nhật ngày",
  rate: "Đánh giá",
  decription_procedure: "Mô tả về quy trình",
  stage: "Công đoạn",
  total_procedure: "Tổng số vụ áp dụng",
  type_procedure: "Loại quy trình",
  name_procedure: "Tên quy trình",
  edit_from_procedure_form: "Chỉnh sửa từ quy trình mẫu",
  add_procedure: "Thêm quy trình",
  edit_procedure: "Sửa quy trình",
  edit_form_sale: "Sửa đăng bán",
  view_procedure: "Chi tiết",
  create_procedure_successful: "Tạo quy trình thành công",
  update_sale_price_sucess: "Sửa cơ cấu giá bán thành công",
  delete_procedure_successful: "Xóa quy trình thành công",
  specifications: "Quy cách",
  commerce: "Thương mại",
  //form
  vietnam_agricultural_food_products_connection_exchange:
    "SÀN GIAO DỊCH KẾT NỐI NÔNG SẢN THỰC PHẨM VIỆT NAM",
  offering_number: "Phiếu chào hàng số",
  seller_registration_ship: "PHIẾU ĐĂNG KÝ CHÀO BÁN HÀNG",
  seller_code_number: "Mã số người chào bán hàng",
  name_of_the_offering_organization: "Tên tổ chức chào bán",
  date_sale: "Ngày chào bán hàng",
  week_number: "Tuần thứ",
  limit_total_buy: "Số lượng mua tối thiểu",
  transaction_classification: "Phân loại giao dịch",
  name_of_personal_representative: "Tên cá nhân đại diện",
  DTDD_number: "ĐTDĐ số",
  website: "Website",
  transaction_role: "Vai trò giao dịch",
  sales_address: "Địa chỉ bán hàng",
  town_village: "KP/thôn",
  city_city: "TP/tỉnh",
  BK: "BK(Km)",
  scope_transaction_registration: "Phạm vi đăng ký giao dịch",
  register_to_sell_nationwide: "Đăng ký bán cả nước",
  register_to_sell_by_region: "Đăng ký bán theo vùng miền",
  register_to_sell_in_city_province: "Đăng ký bán trong TP/tỉnh",
  register_to_sell_in_district: "Đăng ký bán trong Quận/huyện",
  register_to_sell_in_ward_commune: "Đăng ký bán trong Phường/xã",
  connection_results: "Kết quả việc kết nối",
  time_to_sell: "Thời gian đăng bán(Ngày)",
  products_offered_for_sale: "Mặt hàng chào bán",
  technical_specifications_of_products: "Thông số kỹ thuật sản phẩm chào bán",
  number_of_offers: "Số lượng chào bán",
  offering_price_vnd: "Giá chào bán (đồng) ",
  service_fee_vnd: "Phí dịch vụ (đồng)",
  delivery_time: "Thời gian giao hàng",
  payments: "Hình thức thanh toán",
  STT: "SST",
  qr_code: "Mã số QR",
  characteristic: "Đặc tính",
  formality: "Hình thức",
  size_product: "Cỡ trái",
  number_of_seeds: "Số hạt",
  plate: "% tấm",

  color_sharp: "Màu sắc",
  humidity: "Độ ẩm",
  humidity_is_required: "Phải nhập độ ẩm",
  must_post_lest_one: "Phải đăng ít nhất 1 sản phẩm",
  closed_package: "Đóng gói",
  measure: "Đo lường",
  measure_must_than_zero: "Đo lường phải lớn hơn 0",
  ĐVT: "ĐVT",
  total: "Tổng cộng",
  delivery_date: "Ngày giao",
  deposit: "Đặt cọc",
  Pay: "Thanh toán",
  percentage_connection_service_cost_over_transaction:
    "Tỉ lệ% chi phí dịch vụ kết nối trên giao dịch tổng giá trị",
  sign_up_for_sale: "Đăng ký chào bán",
  successful_connection: "Kết nối thành công",
  payment_through_the_exchange: "Thanh toán qua Sàn",
  register_for_delivery_at: "Đăng ký giao hàng tại",
  delivered_in_the_field: "Giao tại ruộng",
  delivered_at_the_facility: "Giao tại cơ sở",
  delivered_at_the_warehouse: "Giao tại kho",
  delivered_at_factory: "Giao tại nhà máy",
  delivered_at_the_store: "Giao tại cửa hàng",
  delivered_at_the_company: "Giao tại công ty",
  delivered_at_the_airport: "Giao tại sân bay",
  delivered_at_the_port: "Giao tại cảng",
  delivered_at_the_checkpoint_of_export: "Giao tại cửa khẩu xuất",
  delivered_at_the_import_border_gate: "Giao tại cửa khẩu nhập",
  delivery_to_the_receiver: "Giao tận cửa người nhận",
  other_mixes: "Giao hỗn hợp khác ",
  AgriSys: "AgriSys",
  AgriDepot: "AgriDepot",
  AgriService: "AgriService",
  AgriHub: "AgriHub",
  AgriPay: "AgriPay",
  using_value_added_services: "Sử dụng dịch vụ giá trị gia tăng",
  supplying_agricultural_materials: "Cung cấp vật tư nông nghiệp ",
  plant_doctor_services: "Dịch vụ bác sĩ cây trồng",
  warehouse_rental_service: "Dịch vụ cho thuê kho hàng",
  data_analysis_service: "Dịch vụ phân tích số liệu",
  trade_promotion_services_fairs: "Dịch vụ XTTM, hội chợ",
  payment_assistance_service: "Dịch vụ hỗ trợ thanh toán",
  irrigation_system_care_service: "Dịch vụ chăm sóc hệ thống tưới",
  service_of_product_harvest: "D.vụ thu hoạch sản phẩm",
  freight_forwarding_service: "Dịch vụ giao nhận vận chuyển",
  services_of_iot_technology_solutions: "D.vụ giải pháp công nghệ IoT",
  service_to_find_customers: "Dịch vụ tìm khách hàng",
  loan_support_services: "Dịch vụ hỗ trợ vốn vay",
  drug_administration_services: "Dịch vụ quản lý phân thuốc",
  standard_consulting_service: "D.vụ tư vấn tiêu chuẩn",
  outsourcing_service_packaging: "Dịch vụ gia công đóng gói",
  cargo_location_services: "Dịch vụ định vị hàng hóa",
  export_support_services: "Dịch vụ hỗ trợ xuất khẩu",
  insurance_consulting_services: "Dịch vụ tư vấn bảo hiểm",
  lighting_management_service: "Dịch vụ quản lý hệ thống chiếu sáng",
  service_standard_assessment: "D.vụ đánh giá tiêu chuẩn",
  outsourcing_service: "Dịch vụ gia công chế biến",
  packaging_label_design_services: "Dịch vụ thiết kế tem nhãn bao bì",
  legal_consulting_service: "Dịch vụ tư vấn pháp lý",
  financial_advisory_service: "Dịch vụ tư vấn tài chính",
  farm_management_services: "Dịch vụ quản lý trang trại",
  dv_traceability: "DV Truy xuất nguồn gốc",
  factory_management_services: "Dịch vụ quản lý nhà máy",
  packaging_services: "Dịch vụ cung cấp bao bì",
  distribution_support_service: "Dịch vụ hỗ trợ phân phối",
  investment_consulting_services: "Dịch vụ tư vấn đầu tư",
  post_sale: "Đăng bán",
  create_post_sale: "Tạo đăng bán",
  create_post_sale_sucsessfull: "Tạo đăng bán thành công",
  update_post_sale_sucsessfull: "Sửa đăng bán thành công",
  number_vote: "Số phiếu",
  offering_sell: "Tổ chức chào bán",
  surrogate: "Người đại diện",
  date_buy_register: "Ngày ĐK bán",
  time_buy_register: "Thời gian đăng ký bán",
  sum_total_buy: "Tổng số lượng bán",
  sum_cost_buy: "Tổng giá trị bán",
  feature_development: "Tính năng đang phát triển",
  creating: "Đang tạo",
  waiting_for_approval: "Đợi xác nhận",
  deleted: "đã xóa",
  deactive: "Không hoạt động",
  actived: "Đã hoạt động",
  approved: "Đã xác nhận",
  rejected: "Đã từ chối",
  conected: "Đã kết nối",
  expired: "Đã hết hạn",
  cash: "Tiền mặt",
  debit: "Ghi nợ",
  transfer: "Chuyển khoản",
  percent_check: "Nhập từ 0% đến 100%",
  affter_process: "Sau công đoạn trước",
  time_excute: "Thời điểm thực hiện",
  period_time_excute: "Khoảng thời gian thực hiện",
  human_excute: "Người thực hiện",
  shining: "Sáng",
  type_requried: "Phải chọn loại sản phẩm",
  noon: "Chiều",
  //cau truc chi phi san xuat
  classify_size_seed: "Phân loại kích cỡ trái",
  number_post_one_ha_land: "Số trụ trồng trên 01 ha dất",
  distribution_percent_size_one_tree: "Phân bổ % size /01 cây",
  explain: "Diễn giải",
  unit_number_post_per_ha: "Số trụ/ha",
  unit_number_post_kg_ha: "Số kg/trụ/vụ",
  unit_number_post_one_year: "Số trụ TH trong 1 năm",
  solution_re: "Giải pháp cải tiến",
  price_th: "Giá thành",
  //cau truc gia ban
  price_product_for_sur: "Giá sản xuất theo khảo sát",
  MH_VH_for_group_small: "MH VH theo nhóm nhỏ",
  price_want: "Giá bán mong muốn",
  per_profit_want: "Tỉ suất lợi nhuận mong muốn",
  Agree_link_sm: "Diện tích liên kết tối thiểu",
  number_group_link_than: "Số nhóm liên kết tối đa",
  info_impact_to_price_want: "Thông số ảnh hưởng đến giá bán mong muốn",
  price_for_golbalgrap: "Giá bán theo GlobalGrap",
  type_shove_garen: "Loại xô vườn",
  type_one_go_port: "Loại 1 đi cảng",
  type_two_go_pochai: "Loại 2 đi Pòchai",
  type_three__dat_than: "Loại 3 dạt lớn",
  type_four_remove: "Loại 4 bỏ đi",
  resuilt: "Kết quả",
  name_sale_price: "Tên cơ cấu giá bán",
  sale_price: "Cơ cấu giá bán",
  create_pakaing_cost_sucess: "Tạo Cấu trúc giá bán thành công",
  type_tree: "Loại cây trồng",
  chose_type_tree: "Chọn loại cây trồng",
  name_product_cost: "Tên cấu trúc chi phí sản xuất",
  average_yield: "Sản lượng trung bình",
  number_season_on_one_year: "Số vụ TB TH trong 01 năm",
  productivity_one_year: "Năng suất TH hàng năm",
  classify_size_seed: "Phân loại cỡ trái",
  create_pakaging_cost_sucess: "Tạo cấu trúc giá bán thành công",
  //cau truc dong goi
  best_product_cost: "Chi phí sản xuất tối ưu",
  sum_product_cost: "Tổng giá thành sản xuất",
  name_pagkaging_cost: "Tên cấu trúc chi phí đóng gói",
  best_famr_sale: "Giá bán trong nước",
  sum_farm_sale: "Giá xuất khẩu",
  best_pagkaging_cost: "Chi phí đóng gói tối ưu",
  sum_pakaging_cost: "Tổng chi phí đóng gói",
  edit_pakaing_cost_sucess: "Sửa chi phí đóng gói thành công",
  delete_pakaing_cost: "Xóa chi phí đóng gói này",
  delete_pakaing_cost_sucess: "Xóa chi phí đóng gói thành công",
  delete_product_cost: "Xóa cấu trúc chi phí sản xuất này",
  delete_product_cost_sucess: "Xóa cấu trúc chi phí sản xuất thành công",
  delete_sale_price: "Xóa cơ cấu giá bán này",
  delete_sale_price_sucess: "Xóa cơ cấu giá bán thành công",
  edit_sale_price: "Sửa cơ cấu giá bán",

  /**
   * HIEU - avoid conflict
   */
  install: "Cài đặt",
  installed: "Đã cài",
  contact: "Liên lạc",
  logistics: "Kho vận",
  human_resources: "Nhân sự",
  financial: "Tài chính",
  profile_management_description: "Hồ sơ cá nhân, pháp nhân",
  farm_management_description: "Trang trại, nông sản, canh tác",
  purchase_management_description: "Trang trại, nông sản, canh tác",
  goods_management_description: "Nông sản, hàng hoá",
  comercial_management_description: "Mua, bán, giá, quy cách",
  orders_management_description: "Đơn bán hàng, mua, đặt trước",
  contact_management_description: "Đối tác, khách hàng",
  logictics_management_description: "Nhập/xuất kho, tồn kho, kiểm kê",
  human_resources_management_description: "Phòng ban, nhân sự",
  finance_management_description: "Kế toán, tài chính",
  messenger_management_description: "",
  report_management_description: "Báo cáo, thống kê, dự đoán",
  expected: "Dự kiến",
  input: "Đầu vào",
  candidate: "Lịch",
  excellent: "Xuất sắc",
  very_good: "Rất tốt",
  good: "Tốt",
  fair: "Khá",
  poor: "Kém",
  promotion: "Khuyến mãi",

  add_stage: "Thêm công đoạn",
  delete_procedure: "Xóa quy trình này",
  buy_human: "Người mua",
  sell_human: "Người bán",
  edit_form_post: "Sửa đăng bán",
  delete_form_post: "Xóa bản đăng bán này",
  delete_form_sucessful: "Xóa bản đăng bán thành công",
  price_product_cost_optimas: "Giá thành sản xuất tối ưu",
  total_product_cost: "Tổng giá thành sản xuất",
  paker: "Đối tác",
  noti_price: "Báo giá",
  percent_per: "Tỉ lệ",

  /**
   * HOANG TAN Cooperative, Cooperation ventrure, Organization profile
   */
  cooperative_info: "Thông tin chung",
  cooperative_detail: "Thông tin chi tiết",
  cooperative_name: "Tên hợp tác xã",
  placeholder_cooperative_name: "HTX Ba khía",
  cooperative_sum_member: "Tổng số thành viên",
  certificate_number: "Số chứng nhận",
  tax_number: "Mã số thuế",
  tax_TNDN: "Thuế TNDN",
  operation_date: "Ngày hoạt động",
  issue_date_greater_than_operation_date: "Ngày hoạt động phải sau ngày cấp",
  where_by_issue: "Nơi cấp",
  cooperative_name_required: "Vui lòng nhập tên Hợp tác xã",
  cooperative_surrogate_required: "Vui lòng nhập tên Người đại diện",
  cooperative_employ_required: "Vui lòng nhập Tổng số thành viên",
  unit_price: "VND",
  unit_price_d: "đ",
  more_than_number: "Giá trị phải lớn hơn {number}",
  number_to_larger: "Giá trị phải nhỏ hơn {number}",
  unit_farming_season: "Vụ/năm",
  unit_gross_yield: "Tấn/năm",
  cooperative_farm_season_required: "Vui lòng nhập Tổng số mùa vụ",
  cooperative_gross_yield_required: "Vui lòng nhập Tổng sản lượng",
  placeholder_sum_acreage: "1",
  placeholder_name_representative: "Nguyễn Văn A",
  placeholder_phone: "0987654321",
  placeholder_email: "abc@gmail.com",
  placeholder_status_land: "Sỡ hữu",
  placeholder_consumption_market: "Thương lái",
  placeholder_certificate_type: "Giấy phép kinh doanh",
  placeholder_certificate_type_gap: "Global GAP",
  placeholder_certificate_number: "777888999",
  placeholder_date_of_issue: "01/01/2020",
  placeholder_where_by_issue: "Sở kế hoạch - đầu tư HCM",
  placeholder_tax_number: "0123456789",
  placeholder_operation_date: "01/02/2020",
  placeholder_address: "15 Trần Hưng Đạo",
  placeholder_type_of: "Sản xuất",
  placeholder_branch_product: "Hồ tiêu",
  placeholder_management_grouping: "Đầu vào, Xử lý chế biến",
  placeholder_expiration_date: "31/12/2025",
  placeholder_date_evaluate: "01/01/2026",
  placeholder_date_reevaluate: "01/06/2026",
  placeholder_issued_by: "Sở kế hoạch - đầu tư HCM",
  // cooperative venture
  cooperative_venture_name: "Tên liên hợp tác xã",
  // Organization
  organization: "Tổ chức",
  START: "Bắt đầu",
  STOP: "Dừng",
  PAUSE: "Đang ngưng",
  ACCOMPLISHED: "Xong",
  RESCHEDULE: "Dời lịch",
  CANCEL: "Đã hủy",
  CANCELED: "Hủy",
  WAITING: "Đang chờ",
  NOT_RUN: "Chưa chạy",
  RUNNING: "Đang chạy",
  DONE: "Đã kết thúc",
  DONE_ACTIVITY: "Đã xong",
  LATE: "Đã trễ",
  FINISHED: "Hoàn thành",
  rating_default: "Chưa đánh giá",
  update_season: "Cập nhật mùa vụ",
  organization_name: "Tên tổ chức",
  packaging_cost: "Cấu trúc chi phí đóng gói",

  /**
   * HOANG TAN Farm season
   */
  farm_season_input: "Đầu vào",
  farm_setup: "Cài đặt thông tin cho trang trại",
  farm_choose: "Chọn trang trại",
  down_seed_date: "Ngày xuống giống",
  allocation_per_tree: "Phân bổ % size / 01 cây",
  total_cultivated_area: "Tổng diện tích canh tác",
  farm_season_name: "Tên mùa vụ",
  expected_harvest_date: "Ngày dự kiến thu hoạch",
  number_tree_per_ha: "Mật độ trồng trên 01 ha đất",
  expected_sum_output: "Tổng sản lượng dự kiến",
  crops: "Cây trồng",
  cultivated_area: "DT canh tác",
  parcel_of_land: "Thửa đất",
  tree_per_area: "Số cây/trụ",
  expected_output: "Sản lượng dự kiến",
  note: "Ghi chú",
  solution_improvement: "Giải pháp cải tiến",
  pillar_unit: "Trụ",
  total_yield_harvested: "Tổng sản lượng thu hoạch",
  total_revenue: "Tổng doanh thu",
  total_cost: "Tổng chi phí",
  expected_total_cost: "Tổng chi phí dự kiến",

  gross_profit: "Lợi nhuận gộp",

  down_seed_date_greater_than_expected_harvest_date:
    "Ngày thu hoạch phải lớn hơn ngày xuống giống",
  standard_of_cultivation: "Tiêu chuẩn canh tác",
  acreage_farming: "Diện tích canh tác",
  crops_type: "Loại cây trồng",
  harvest_date: "Ngày thu hoạch",
  classify: "Phân loại",
  yield_by_type: "Sản lượng theo loại",
  recent_activity: "Hoạt động gần đây",
  recent_farm_season: "Mùa vụ gần đây",
  input_type: "Loại đầu vào",
  order_date_greater_than_received_date: "Ngày nhận phải lớn hơn ngày đặt",
  create_farm_season_successful: "Tạo mùa vụ thành công",
  running: "Đang chạy",
  not_run: "Chưa chạy",
  update_farm_successful: "Cập nhật mùa vụ thành công",
  ledg: "Sổ cái",

  // procedure
  procedure_name: "Tên quy trình",
  status: "Trạng thái",
  procedure_step: "Giai đoạn",
  supplier: "Nhà cung cấp",
  count: "Số lượng",
  unit_cal: "ĐVT",
  order_date: "Ngày đặt",
  procedure_unit_price: "Đơn giá",
  procedure_unit_price_more_than_zero: "Đơn giá phải lớn hơn không",

  into_money: "Thành tiền",
  received_date: "Ngày nhận",
  estimated_unit_price: "Đơn giá ước tính",
  estimated_money: "Thành tiền ước tính",
  hectare: "Ha",
  ton: "Tấn",
  forecast_production_season_results: "Dự báo kết quả mùa vụ sản xuất",
  amount_must_large_t_gross:
    "Số lượng sản phẩm phải nhỏ hơn số lượng thực trong kho",
  lesBuy_must_lagre: "Số lượng mua tối thiểu phải nhỏ hơn số lượng đã nhập",

  /**
   * FARM SEASON
   */
  farm_performance: "Hiệu suất sản xuất của trang trại",

  // farm season input - supplies:
  supplies: "Vật tư",
  labor: "Nhân công",
  employee_id: "Mã nhân viên",
  employee_full_name: "Họ Tên nhân viên",
  work: "Công việc",
  status_process_default: "Chưa chạy",

  // farm season input - employee:
  employee_work_day_month: "Số ngày làm việc trên tháng",
  employee_salary: "Lương tháng",
  employee_work_day_season: "Số ngày làm việc trong mùa vụ",
  employee_cost_season: "Chi phí Lương Mùa vụ",
  //note: Ghi chú

  // farm season input - others:
  other_cost_detail: "Mô tả chi phí",
  // unit_price
  // quantity
  // into_money
  // note
  allocation_add: "Thêm phân bổ",
  allocation_done: "Tạo xong phân bổ",
  allocation_type_placeholder: "Loại",
  allocation_percent_placeholder: "% phân bổ",
  required_allocation_percent: "Vui lòng nhập % phân bổ",
  required_allocation_type: "Vui lòng chọn loại",
  percent: "%",
  year: "Năm",
  edit_farm_season: "Sửa mùa vụ",
  create_farm_season: "Tạo mùa vụ",
  environmental_parameters_from_the_iodine_device:
    "Thông số môi trường từ thiết bị IoT",
  recent_farming_activities: "Hoạt động canh tác gần đây",
  upcoming_farming_schedule: "Lịch canh tác sắp tới",
  percent_name: "Phần trăm phân bổ",
  tree_unit: "cây",
  not_yet_started: "Chưa bắt đầu",
  prepare: "Chuẩn bị",
  ORDERED: "Đã đặt",
  ORDER: "Đặt",
  NO_ORDER: "Chưa đặt",
  default_choice: "Không chọn",
  please_enter_the_same_date: "Vui lòng nhập ngày xuống giống",
  percent_of_remaining_allocation_will_be_of_type_other: `🛈 % phân bổ còn lại sẽ được xem là loại "Khác"`,
  total_allocations_should_be_100_percent: "Tổng các phân bổ phải là 100%",
  harvest_date_must_be_larger_than_sowing_date:
    "Ngày thu hoạch phải lơn hơn ngày xuống giống",
  please_choose_a_category: "Vui lòng chọn loại",
  need_at_least_one_crop_for_this_season:
    "Cần ít nhất một cây trồng cho mùa vụ này",
  please_enter_a_valid_percent_allocation: "Vui lòng nhập % phân bổ hợp lệ",
  expected_output_by_type: "Sản lượng dự kiến theo loại",
  delete_this_season: "Xóa mùa vụ này",
  update_seasons: "Sửa mùa vụ",
  current_inventory: "Tồn kho hiện tại",
  please_select_a_start_date: "Vui lòng chọn ngày bắt đầu cho quy trình",
  please_select_a_process_name: "Vui lòng chọn tên quy trình",

  /**
   * farm season HARVESTS
   *
   */
  // menu
  harvest: "Thu hoạch",
  harvest_overview: "Tổng quan TH",
  harvest_overview_full: "Tổng quan thu hoạch",
  enter_harvest_overview: "Tổng quan nhập tồn",
  enter_the_harvest_inventory: "Nhập tồn TH",
  expected_inventory_entry: "Nhập tồn dự kiến",
  expected_inventory_entry_success: "Nhập tồn dự kiến thành công",

  // form harvest
  import_products_in_stock_during_the_season: "Nhập tồn sản phẩm trong mùa vụ",
  original_code: "Mã gốc",
  stock_at_the_beginning_of_the_period: "Tồn ĐK",
  import_inventory: "Nhập TK",
  export_inventory: "Xuất TK",
  toal_inventory: "Tổng TK",
  damaged_goods: "Hàng hư",
  picture: "Hình",
  product_name: "Tên sản phẩm",
  product_code: "MSP",
  expected_product_inventory_during_the_season:
    "Dự kiến tồn sản phẩm trong mùa vụ",
  unit_acronym: "ĐVT",
  import_successfully_harvested: "Nhập tồn thu hoạch thành công",

  functions_are_under_development: "Tính năng đang được phát triển",
  inventory_account_entry_must_be_greater_than_out_of_account_damaged_goods:
    "[Tồn ĐK + Nhập TK] phải lớn hơn [Xuất TK + Hàng hư]",

  default_unnamed: "Chưa được đặt tên",
  default_no_phone_number: "Chưa có số điện thoại",
  default_no_address_yet: "Chưa có địa chỉ",

  inventory_overview: "Tổng quan tồn kho",
  display_error: "Lỗi hiển thị",

  inventory: "Tồn kho",

  /**
   * DASHBOARD Farm management
   *
   */
  no_name: "Không tên",
  no_activities: "Không có lịch",
  title_default: "",
  farming_calendar: "Lịch canh tác",
  notification_content_default: "Thông báo",
  report_title_default: "",
  report_title_second_default: "",
  season_ago: "Mùa/đợt trước",
  default_chart_title: "Biểu đồ không có tên",
  climate: "Khí hậu",
  temperature: "Nhiệt độ",
  order: "Đơn hàng",
  chart: "Biểu đồ",
  production_efficiency: "Hiệu suất sản xuất",
  farming_history: "Lịch sử canh tác",
  farm_overview: "Tổng quan trai trại",
  start_the_day: "Bắt đầu ngày",
  will_start_the_day: "Sẽ bắt đầu ngày",

  invalid_lines_will_be_automatically_deleted:
    "Các dòng không hợp lệ sẽ tự động xóa",
  event: "Sự kiện",
  seasonal_information: "Thông tin mùa vụ",
  start_date_action: "Ngày T/H",
  type_work: "Loại CV",
  capacity: "Dung lượng",
  finish_date: "Ngày H/T",
  complete_level: "Mức độ H/T",
  date_created: "Ngày tạo",
  time: "Thời gian",
  successfully_rescheduled: "Dời lịch thành công",
  successfully_canceled: "Hủy lịch thành công",
  successfully_updated: "Cập nhật thành công",
  you_have_completed_this_step_in_the_process: "Bạn đã hoàn thành bước này",
  are_you_sure_you_want_to_cancel_this_step_in_the_process:
    "Bạn có chắc muốn hủy bước này",

  /**viet change */
  date_ranger_title: "Chọn Khoảng Thời Gian",
  today: "Hôm nay",
  yesterday: "Hôm qua",
  last_week: "Tuần trước",
  week_to_date: "Tuần này",
  month_to_date: "Tháng này",
  last_moth: "Tháng trước",
  quarter_to_date: "Quý này",
  last_quarter: "Quý trước",
  year_to_date: "Năm này",
  last_year: "Năm trước",
  presets: "Cài đặt trước",
  custom: "Tùy trỉnh",
  compare_to: "So Sánh VS",
  previos_period: "Kỳ Trước",
  previos_year: "Năm trước",
  update: "Cập Nhật",
  peformance_ecommerce: "Hiệu suất thương mại",
  sales: "Doanh số",
  pill_product_early: "Đơn hàng mới nhất cần xử lý",
  customer_early: "Khách hàng mới nhất",
  code_product: "Mã đơn hàng",
  date_deal: "Ngày đặt hàng",
  customer: "Khách hàng",
  price_bill: "Giá trị đơn hàng",
  performent_product: "Hiệu suất sản xuất",
  list_bill_to_deal: "Danh sách đơn hàng bán cần xử lý",
  create_bill: "Tạo đơn hàng",
  create_promotion: "Tạo trương trình khuyến mãi",
  list_product_best_sale: "Danh sách sản phẩm bán chạy nhất",
  promotion_on_runing: "Chương trình khuyến mãi đang chạy",
  create_breadcrum_users: "Tạo người dùng",
  manager_stock_product: "Quản lý kho ở cấp sản phẩm",
  status_stock: "Trạng thái kho hàng",
  in_stock: "Có hàng",
  out_of_stock: "Hết hàng",
  stock_quantity: "Số lượng hàng hóa",
  apply: "Áp dụng",
  approve_product: "Đủ hàng",
  not_approve_product: "Chưa đủ hàng",
  pickup_product: "Đặt hàng",
  must_chosse_quantity: "Phải chọn số lượng",
  must_chosse_product: "Phải chọn sản phẩm",
  amount_expect: "Số lượng dự kiến",
  current_inventory_expect: "Tồn kho dự kiến hiện tại",
  amount_must_pick: "Số lượng đặt tham khảo",
  // sidebar_ecom_ari: "Nông sản",
  // sidebar_ecom_goods_ari: "Đầu vào nông nghiệp",
  /** end!---viet */
  load_more: "Xem thêm",
  empty_text: "Không có dữ liệu",
  empty_text_yesterday: "Không có lịch nào bị bỏ lỡ",
  report_exported_successfully: "Xuất báo cáo thành công!",
  previous_period: "Kỳ trước",
  total_production: "TỔNG SẢN LƯỢNG",
  production_by_season: "SẢN LƯỢNG THEO MÙA/ĐỢT",
  medium_quantity: "CHẤT LƯỢNG TRUNG BÌNH",
  cost: "CHI PHÍ",
  revenue: "DOANH THU",
  profit: "LỢI NHUẬN",
  report_content_is_empty: "Nội dung báo cáo rỗng",

  /**============================================
   *               FARM SEASON DASHBOARD
   *=============================================**/
  dashboard_data_range: "Khung dữ liệu (Data Range)",
  default: "Mặc định",

  /**============================================
   *               CREATE PRODUCT (Agriculture Sell)
   *=============================================**/
  btn_add_unit: "Thêm đơn vị",

  /**================================================================================================
   *                                         ACTION
   *================================================================================================**/
  add: "Thêm",
  create_product_btn_create_product: "Tạo sản phẩm",
};

export default vi;
