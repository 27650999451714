import React from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  withHandlers,
  withState,
} from 'recompose';
import strings from '~/localization';
import { TableEditable } from '~/views/presentation/ui/tables';
import { getNumber } from '~/views/utilities/helpers/utilObject';

class LaborsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {dataSource: this.props.laborCosts || []};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.laborCosts != this.state.dataSource) {
      this.setState({dataSource: nextProps.laborCosts || []});
    }
  }

  handleChangedData = (index, dataIndex, dataSource) => {
    const {onChange} = this.props;
    onChange && onChange(dataSource);

    // Calculator money
    if (
      dataIndex === "workingPerMonth" ||
      dataIndex === "salaryPerMonth" ||
      dataIndex === "workingPerSeason"
    ) {
      let workingPerMonth = getNumber(dataSource[index], "workingPerMonth", 0);
      let salaryPerMonth = getNumber(dataSource[index], "salaryPerMonth", 0);
      let workingPerSeason = getNumber(dataSource[index], "workingPerSeason", 0);
      let row = dataSource[index];
      row.salaryPerSeason = (salaryPerMonth / workingPerMonth) * workingPerSeason;
      this.setState({dataSource});
    }
  };

  render() {
    const {editable} = this.props;

    let columns = [
      {
        title: strings.employee_id,
        dataIndex: "code",
        editable: false,
        required: false,
        width: "10%",
        placeholder: strings.employee_id,
        type: "text",
        nameData: "value",
        filterOption: true,
        optionFilterProp: "label",
        renderOpts: {
          value: "value",
          labelSelect: "label",
          disabled: "disabled",
        },
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.employee_full_name,
        dataIndex: "name",
        editable: editable,
        required: true,
        width: "15%",
        placeholder: strings.employee_full_name,
        type: "text",
        isSearch: false,
        nameData: "value",
        filterOption: true,
        optionFilterProp: "label",
        renderOpts: {
          value: "value",
          labelSelect: "label",
          disabled: "disabled",
        },
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.work,
        dataIndex: "job",
        editable: true,
        width: "15%",
        required: true,
        placeholder: strings.work,
        type: "text",
        isSearch: false,
        nameData: "value",
        filterOption: true,
        optionFilterProp: "label",
        renderOpts: {
          value: "value",
          labelSelect: "label",
          disabled: "disabled",
        },
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.employee_work_day_month,
        dataIndex: "workingPerMonth",
        editable: true,
        width: "15%",
        required: true,
        placeholder: strings.employee_work_day_month,
        min: 1,
        max: 31,
        type: "number",
      },
      {
        title: strings.employee_salary,
        dataIndex: "salaryPerMonth",
        editable: editable,
        width: "10%",
        required: true,
        suffix: " tr",
        placeholder: strings.employee_salary,
        type: "decimalInput",
      },
      {
        // auto calculator
        title: strings.employee_work_day_season,
        dataIndex: "workingPerSeason",
        editable: editable,
        width: "15%",
        required: true,
        placeholder: strings.employee_work_day_season,
        min: 1,
        max: 366,
        type: "number",
      },
      {
        // auto calculator
        title: strings.employee_cost_season,
        dataIndex: "salaryPerSeason",
        editable: false,
        width: "10%",
        required: true,
        min: 0,
        suffix: " tr",
        placeholder: strings.employee_cost_season,
        type: "decimalInput",
      },
      {
        title: strings.note,
        dataIndex: "note",
        editable: editable,
        width: "10%",
        required: false,
        placeholder: strings.note,
        type: "text",
      },
    ];

    return (
      <div>
        <TableEditable
          scroll={{x: 1100}}
          editable={editable}
          changedData={this.handleChangedData}
          dataSource={this.state.dataSource || []}
          columns={columns}
        />
      </div>
    );
  }
}

export default compose(
  connect(null, {}),
  withState("dataSource", "setDataSource", []),

  withHandlers({}),
  lifecycle({
    componentDidMount() {
      const {} = this.props;
    },
  })
)(LaborsForm);
