import React from "react";
import AppContentWrapper from "~/views/presentation/ui/container/AppContentWrapper";
import strings from "~/localization";
import CommerceNav from "./CommerceNav";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { showError } from "~/configs/ServerErrors";

import { getFarmProfile } from "~/state/ducks/appApis/actions";

import { getString } from "~/views/utilities/helpers/utilObject";
import { ContainerNavStyled } from "../Navigation/styles";

// menu Header
const menuHeader = [
  {
    title: strings.dashboard,
    path: "#1",
  },
  {
    title: strings.price,
    path: "#2",
  },
  {
    title: strings.specifications,
    path: "#3",
  },
  {
    title: strings.commerce,
    path: "#4",
  },
  {
    title: strings.promotion,
    path: "#4",
  },
  {
    title: strings.report,
  },
  {
    title: strings.configuration,
  },
];

// const treeMenu = [
//   {
//     name: strings.dashboard,
//     path: PATH.FARM_DETAIL_PATH,
//   },
//   {
//     default: "1",
//     name: strings.seasons,
//     path: PATH.FARM_SEASON_PATH,
//     subTitle: [
//       {
//         name: strings.overview_season,
//         path: PATH.FARM_SEASON_CRUD_PATH,
//       },
//       {
//         name: strings.ledg,
//       },
//       {
//         default: "2",
//         name: strings.structure,
//         option: [
//           { name: strings.cost_product, path: "7" },
//           { name: strings.cost_pack, path: "8" },
//           { name: strings.price, path: "9" },
//         ],
//       },
//       {
//         default: "3",
//         name: strings.procedure,
//         option: [
//           { name: strings.overview_procedure, path: "10" },
//           { name: strings.farming, path: "11" },
//           { name: strings.resolve_land_watter, path: "12" },
//           { name: strings.resolve_envi, path: "13" },
//           { name: strings.resolve_prevention, path: "14" },
//         ],
//       },
//     ],
//   },
// ];

class Commerce extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameFarm: "",
      viewAsBlocks: true,
      id: undefined,
    };
    this.ref = React.createRef();
  }
  changeViewStyle = (viewAsBlocks) => {
    this.setState({ viewAsBlocks: viewAsBlocks });
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  renderContent = () => {};
  setBearCum = (name) => {
    this.setState({ bearCum: name });
  };
  fetchProfile = () => {
    const { match, getFarmProfile } = this.props;

    let profileId = +match.params.id;
    getFarmProfile(profileId)
      .then(async ({ res }) => {
        const name = getString(res, "name", "");
        this.setState({ nameFarm: name });
      })
      .catch((err) => showError(err));
  };
  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      // this.fetchProfile();
      this.setState({ id: match.params.id });
    }
  }
  getBreadcrumb = () => {
    return [];
  };

  onSave = () => {
    this.editView.onSave();
  };

  onCreate = () => {
    this.createView.onCreate();
  };

  onDelete = () => {
    this.editView.onDelete();
  };

  render() {
    return (
      <AppContentWrapper
        showMainSideBar={false}
        topNav={{
          isMenu: true,
          pageName: strings.commerce,
          slot: menuHeader,
        }}>
        <ContainerNavStyled>
          <CommerceNav
            onSave={this.onSave}
            onDelete={this.onDelete}
            onCreate={this.onCreate}
            changeViewStyle={this.changeViewStyle}
            className="profile-nav shadow"
            breadcrumb={this.getBreadcrumb()}
          />
          <div className="content">{this.renderContent()}</div>
        </ContainerNavStyled>
      </AppContentWrapper>
    );
  }
}

export default compose(
  withRouter,
  connect(null, { getFarmProfile }, null)
)(Commerce);
