import React from "react";
import { updateProfile, createProfile } from "~/state/ducks/authUser/actions";
import { connect } from "react-redux";
import {
   getProvinces,
   getDistrict,
   getBranches,
   deletePersonalProfile,
   getAllPerformanceSeason,
   getAllPerformanceFarm,
} from "~/state/ducks/appApis/actions";
import strings from "~/localization";
import _ from "lodash";

import TreeMenu from "~/views/presentation/ui/navigation/Sidebar";
// import {TreeMenu} from "~/views/presentation/ui/navigation";
import { Layout, Menu, message } from "antd";
import { MenuAction, OrderItem } from "~/views/container/components";
import UIRAlert from "~/views/presentation/ui/feedback/alert";
import { UserOutlined } from "@ant-design/icons";
import { Performance } from "~/views/container/components";
import WeatherChart from "~/views/presentation/ui/chart/weatherChart";

import { WrapDashboardContent } from "~/views/container/Farm/styles";
import { TitleCustom } from "~/views/presentation/ui/dashboard";
import { withRouterInnerRef } from "~/views/container/commons";

const { Content } = Layout;

class FarmHarvestOverview extends React.PureComponent {
   handleMenuClick() {
      message.info("Click on menu item.");
   }

   renderFarmSeason() {
      return (
         <div className="col-12 d-flex flex-row ">
            <div className="farm-season__table col-8 pl-0 pr-4">
               <TitleCustom title={strings.expected_output} slot={<MenuAction />} />
               <WeatherChart />
            </div>
            <div className="col-4 bg-fuchsia farming-calendar mt-1">
               <h4 className="title">{strings.order}</h4>
               <div className="list-item">
                  <OrderItem></OrderItem>
                  <OrderItem></OrderItem>
                  <OrderItem></OrderItem>
               </div>
            </div>
         </div>
      );
   }

   renderPerformance() {
      const arrPerformance = [
         {
            title: strings.total_production,
            unit: strings.unit_price_d,
            subTitle: strings.previous_period,
         },
         {
            title: strings.production_by_season,
            unit: strings.unit_price_d,
            subTitle: strings.season_ago,
         },
         {
            title: strings.medium_quantity,
            unit: "",
            subTitle: strings.season_ago,
         },
         {
            title: strings.cost,
            unit: strings.unit_price_d,
            subTitle: strings.season_ago,
         },
         {
            title: strings.revenue,
            unit: strings.unit_price_d,
            subTitle: strings.season_ago,
         },
         {
            title: strings.profit,
            unit: strings.unit_price_d,
            subTitle: strings.season_ago,
         },
      ];

      const { getAllPerformanceSeason } = this.props;
      return (
         <div className="col-12">
            <Performance title={strings.production_efficiency} arrPerformance={arrPerformance} fetchData={getAllPerformanceSeason} />
         </div>
      );
   }

   renderNotification() {
      return (
         <div className="col-12 mt-4" style={{ flex: "auto" }}>
            <div className="w-100 col-12">
               <UIRAlert message="Thông báo mẫu là đây" />
               <UIRAlert type="error" />
               <UIRAlert type="warning" />
               <UIRAlert type="success" />
            </div>
         </div>
      );
   }

   state = {
      collapsed: false,
   };

   render() {
      return (
         <Layout>
            <TreeMenu className={this.props.className} menus={this.props.treeMenu} />
            <Content className={`wrap-content d-flex flex-column justify-content-start mt-4 clear-fix pl-0 pr-0 pt-0`}>
               <WrapDashboardContent>
                  {this.renderPerformance()}
                  {this.renderFarmSeason()}
               </WrapDashboardContent>
            </Content>
         </Layout>
      );
   }
}

export default withRouterInnerRef(
   connect(
      null,
      {
         getDistrict,
         getBranches,
         updateProfile,
         createProfile,
         getProvinces,
         deletePersonalProfile,
         getAllPerformanceFarm,
         getAllPerformanceSeason,
      },
      null,
      { forwardRef: true }
   )(React.memo(FarmHarvestOverview))
);
