import React from "react";
import {} from "~/state/ducks/appApis/actions";
import { updateProfile, createProfile } from "~/state/ducks/authUser/actions";
import { connect } from "react-redux";
import {
  getProvinces,
  getDistrict,
  getBranches,
  deletePersonalProfile,
} from "~/state/ducks/appApis/actions";
import strings from "~/localization";
import _ from "lodash";
import { WrapDashboardContent } from "../styles";

import { Button } from "antd";

import TreeMenu from "~/views/presentation/ui/navigation/Sidebar";

import FarmSeasonsDetail from "./FarmSeasonDetails";
import WeatherChart from "~/views/presentation/ui/chart/weatherChart";
import { MenuAction } from "../../components";
import { TitleCustom } from "~/views/presentation/ui/dashboard";
import FarmSeasonActivities from "./FarmSeasonActivities";
import { withRouterInnerRef } from "~/views/container/commons";
import { ContainerNavStyled } from "../../Navigation/styles";

class FarmSeasonDetailOverview extends React.PureComponent {
  constructor(props) {
    super(props);
    const {} = this.props;
    this.state = { farmSeason: {} };
  }

  componentDidMount() {}

  renderTable() {
    const { editable, nameFarm } = this.props;
    return (
      <div className="farm-season__stack col-12 w-100 p-0">
        <div className=" col-12 ">
          <TitleCustom title={strings.expected_output} slot={<MenuAction />} />
        </div>
        <div className="farm-season__stack col-12">
          <FarmSeasonsDetail editable={editable} nameFarm={nameFarm} />
        </div>
      </div>
    );
  }

  renderActivities() {
    return <FarmSeasonActivities />;
  }

  renderIoTDevice() {
    return (
      <div className="w-100 row pl-15">
        <div className="col-12 ">
          <TitleCustom
            title={strings.environmental_parameters_from_the_iodine_device}
            slot={<MenuAction />}
          />
        </div>

        <div className="col-12 col-md-6 col-lg-4">
          <WeatherChart title={strings.climate} height={250} />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <WeatherChart title={strings.humidity} height={250} />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <WeatherChart title={strings.temperature} height={250} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <ContainerNavStyled>
        <TreeMenu
          className={this.props.className}
          menus={this.props.treeMenu}
        />
        <WrapDashboardContent className="d-flex justify-content-end">
          <div className="right-content">
            <h3>{strings.overview_season}</h3>
            {this.renderIoTDevice()}
            {this.renderActivities()}
            {this.renderTable()}
          </div>
        </WrapDashboardContent>
      </ContainerNavStyled>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      getDistrict,
      getBranches,
      updateProfile,
      createProfile,
      getProvinces,
      deletePersonalProfile,
    },
    null,
    { forwardRef: true }
  )(FarmSeasonDetailOverview)
);
