import * as PATH from "~/configs/routesConfig";
import { COMMERCIAL } from "~/configs";
import strings from "~/localization";

export const menuHeader = [
  {
    title: strings.menu_header_dashboard,
    path: PATH.APP_DEFAULT_PATH,
  },
  {
    title: strings.menu_header_information_system,
    path: PATH.ALL_PROFILES_PATH,
  },
  {
    title: strings.menu_header_farm_management,
    path: PATH.FARM_HOME_PATH,
  },
  {
    title: strings.menu_header_cultivation_process,
    path: PATH.ALL_FARM_PROCEDURE_PATH.replace(":type", "ALL"),
  },
  {
    title: strings.menu_header_commercial_management,
    path: COMMERCIAL,
    redirect: true,
  },
  {
    title: strings.menu_header_report,
    path: PATH.REPORTS_PATH,
  },
  {
    title: strings.menu_header_config,
    path: PATH.CONFIGS_PATH,
  },
];
