import React from "react";

import { message, Spin } from "antd";
import { withFormik } from "formik";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  docsUploadConfig,
  excelUploadConfig,
  imgUploadConfig,
  otherUploadConfig,
  pdfUploadConfig,
  rarUploadConfig,
  txtUploadConfig,
  videoUploadConfig,
} from "~/configs";
import { FARM_TYPES, PROFILE_TYPES } from "~/configs/const";
import * as PATH from "~/configs/routesConfig";
import { showError } from "~/configs/ServerErrors";
import strings from "~/localization";
import {
  deleteFileProfile,
  getFilesProfile,
} from "~/state/ducks/appApis/actions";
import { getString } from "~/views/utilities/helpers/utilObject";

import ActivityAttach from "./ActivityAttach";
import ActivityLogs from "./ActivityLogs";
import COLOR from "~/views/utilities/layout/color";

const ActivityLogStyled = styled.div`
  position: relative;
  z-index: 10;
  .content-action {
    @media (max-width: 1200px) {
      width: 100%;
      transform: translateX(${(props) => (props.collapsed ? "0" : "100%")});
    }
    @media (max-width: 600px) {
      top: 130px;
    }
    transition: 1s;
    background-color: #ffff;
    position: fixed;
    right: 0;
    height: 100vh;
  }
  .button-hidden {
    width: 200px;
    @media (min-width: 1200px) {
      display: none;
    }
    transform: translateX(${(props) => (props.collapsed ? "0" : "-12%")});
  }
  .button-hidden button {
    border: none;
    color: ${COLOR.green};
    background-color: #ffff !important;
  }
  .top-actions {
    border-bottom: 1px solid #c3c3c3;
    display: flex;
    padding: 16px 8px 0px 8px;
    ul {
      height: 100%;
      display: flex;
      list-style: none;
      flex: 1;
      li {
        display: flex;
        align-items: center;
        span {
          margin: 0px 5px;
          color: #337ab7;
          font-weight: 500;
        }
        cursor: pointer;
      }
      li:after {
        content: "|";
      }
      li:last-child:after {
        content: "";
      }
    }
    .attack-counter {
      width: 70px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-right: 10px;
      color: #337ab7;
    }
  }
  .file-and-activity {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .warning_upload {
      padding: 8px;
    }
  }
`;

class ActivityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }
  handleChangeMedia = (media) => {
    const { setMediaProfile, setFileCount, docs } = this.props;
    try {
      setMediaProfile(media);
      setFileCount(media.length + docs.length);
    } catch (err) {
      console.error("ActivityForm -> handleChangeMedia -> err", err);
      showError(err);
    }
  };
  handleChangeDocs = (docs) => {
    const { setDocsProfile, setFileCount, media } = this.props;
    try {
      setDocsProfile(docs);
      setFileCount(docs.length + media.length);
    } catch (err) {
      console.error("ActivityForm -> handleChangeDocs -> err", err);
      showError(err);
    }
  };

  handleHiddenAction = (e) => {
    this.setState({ collapsed: e });
  };
  componentDidMount() {
    this.setState({ collapsed: false });
  }
  onDeleteFile = (file) => {
    const {
      deleteFileProfile,
      fileCount,
      setFileCount,
      setMediaProfile,
      setDocsProfile,
      media,
      docs,
    } = this.props;
    deleteFileProfile(getString(file, "path"))
      .then(({}) => {
        message.success(
          strings.formatString(strings.delete_file_success, {
            name: getString(file, "name"),
          })
        );
        let newMedia = [...media];
        _.remove(newMedia, (item) => item.uid === file.uid);
        let newDocs = [...docs];
        _.remove(newDocs, (item) => item.uid === file.uid);
        setMediaProfile(newMedia);
        setDocsProfile(newDocs);
        setFileCount(fileCount - 1);
      })
      .catch((err) => {
        console.error("ActivityForm -> err", err);
        return showError(err);
      });
  };

  isPathCreate() {
    const path = getString(this.props, "match.path", "");
    switch (path) {
      case PATH.CREATE_PERSONAL_PROFILE_PATH:
      case PATH.CREATE_FARM_PROFILE_PATH:
      case PATH.CREATE_ENTERPRISE_PROFILE_PATH:
      case PATH.CREATE_COOPERATIVE_PROFILE_PATH:
      case PATH.CREATE_COOPERATIVE_VENTURE_PROFILE_PATH:
      case PATH.CREATE_ORGANIZATION_PROFILE_PATH:
      case PATH.CREATE_FARM_PROCEDURE_PATH:
      case PATH.CREATE_SEASON_CRUD_FARM_PATH:
      case PATH.CREATE_FARM_FORM_PATH:
      case PATH.CREATE_FARM_PACKAGING_COST_PATH:
      case PATH.CREATE_FARM_PRODUCT_COST_PATH:
      case PATH.CREATE_FARM_SALE_PRICE_PATH:
        return true;
        break;
      default:
        return false;
    }
  }
  render() {
    const { editable, media, docs, fileCount, profileType, spinning } =
      this.props;

    return (
      <ActivityLogStyled collapsed={this.state.collapsed}>
        <div className="content-action">
          <div className="button-hidden">
            <button
              onClick={() => this.handleHiddenAction(!this.state.collapsed)}>
              {this.state.collapsed ? (
                <DoubleRightOutlined />
              ) : (
                <DoubleLeftOutlined />
              )}
            </button>
          </div>
          <div className="top-actions">
            <ul className="px-0">
              <li>
                <span className="not-disable">{strings.sent_mess}</span>
              </li>
              <li>
                <span className="not-disable">{strings.history_note}</span>
              </li>
              <li>
                <span className="not-disable">{strings.activity_logs}</span>
              </li>
            </ul>
            <p className="attack-counter">
              <span>
                <i className="fa fa-paperclip mr-1" /> {fileCount}
              </span>
            </p>
          </div>
          <div className="file-and-activity">
            {!this.isPathCreate() ? (
              <Spin
                size="middle"
                spinning={spinning}
                style={{ minHeight: "70px" }}>
                <ActivityAttach
                  editable={editable}
                  media={media}
                  docs={docs}
                  profileType={profileType}
                  fileCount={fileCount}
                  onChangeMedia={this.handleChangeMedia}
                  onChangeDocs={this.handleChangeDocs}
                  onDeleteFile={this.onDeleteFile}
                />
              </Spin>
            ) : (
              <p className="warning_upload">
                {" "}
                🛈 {strings.warning_create_upload}
              </p>
            )}
            <ActivityLogs profileType={profileType} editable={editable} />
          </div>
        </div>
      </ActivityLogStyled>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    getFilesProfile,
    deleteFileProfile,
  }),
  withState("media", "setMediaProfile", []),
  withState("docs", "setDocsProfile", []),
  withState("spinning", "setSpinning", true),
  withState("fileCount", "setFileCount", 0),
  withState("profileType", "setProfileType", PROFILE_TYPES.PERSONAL_PROFILE),
  withHandlers({
    fetchFilesProfile: (props) => () => {
      const {
        getFilesProfile,
        setMediaProfile,
        setDocsProfile,
        match,
        setProfileType,
        setSpinning,
      } = props;
      let profileType = "";
      let id = props.match.params.id;
      switch (match.path) {
        case PATH.CREATE_ORGANIZATION_PROFILE_PATH:
        case PATH.CREATE_COOPERATIVE_VENTURE_PROFILE_PATH:
        case PATH.CREATE_COOPERATIVE_PROFILE_PATH:
        case PATH.CREATE_ENTERPRISE_PROFILE_PATH:
        case PATH.CREATE_FARM_PROFILE_PATH:
        case PATH.CREATE_PERSONAL_PROFILE_PATH:
        case PATH.CREATE_FARM_PROCEDURE_PATH:
        case PATH.CREATE_SEASON_CRUD_FARM_PATH:
        case PATH.CREATE_FARM_FORM_PATH:
          setSpinning(false);
          return;
        /**
         * personal profile
         */
        /**viet */
        case PATH.EDIT_PERSONAL_PROFILE_PATH:
        case PATH.VIEW_PERSONAL_PROFILE_PATH:
          profileType = PROFILE_TYPES.PERSONAL_PROFILE;
          break;
        case PATH.EDIT_FARM_PROCEDURE_PATH:
        case PATH.VIEW_FARM_PROCEDURE_PATH:
        case PATH.VIEW_FARM_SEASON_PROCEDURE_PATH:
          profileType = FARM_TYPES.FARM_PROCESS;
          id = props.match.params.idProcedure;
          break;
        case PATH.EDIT_FARM_FORM_PATH:
        case PATH.VIEW_FARM_FORM_PATH:
          profileType = FARM_TYPES.PUBLISH_PRODUCT;
          id = match.params.idForm;
          break;
        case PATH.EDIT_FARM_PACKAGING_COST_PATH:
        case PATH.VIEW_FARM_PACKAGING_COST_PATH:
          profileType = FARM_TYPES.PACKAGING_COST;
          id = match.params.idPackagingCost;
          break;

        case PATH.EDIT_FARM_PRODUCT_COST_PATH:
        case PATH.VIEW_FARM_PRODUCT_COST_PATH:
          profileType = FARM_TYPES.PRICE_STRUCTURE;
          id = match.params.idProductCost;
          break;
        case PATH.EDIT_FARM_SALE_PRICE_PATH:
        case PATH.VIEW_FARM_SALE_PRICE_PATH:
          profileType = FARM_TYPES.MANUFACTURING_COST;
          id = match.params.idsalePrice;
          break;
        /**viet!end ---  */
        /**
         * farm profile
         */
        case PATH.EDIT_FARM_PROFILE_PATH:
        case PATH.VIEW_FARM_PROFILE_PATH:
          profileType = PROFILE_TYPES.FARM_PROFILE;
          break;
        /**
         * enterprise profile
         */
        case PATH.EDIT_ENTERPRISE_PROFILE_PATH:
        case PATH.VIEW_ENTERPRISE_PROFILE_PATH:
          profileType = PROFILE_TYPES.ENTERPRISE;
          break;
        /**
         * cooperative profile
         */
        case PATH.EDIT_COOPERATIVE_PROFILE_PATH:
        case PATH.VIEW_COOPERATIVE_PROFILE_PATH:
          profileType = PROFILE_TYPES.COOPERATIVE;
          break;
        /**
         * cooperative venture profile
         */
        case PATH.EDIT_COOPERATIVE_VENTURE_PROFILE_PATH:
        case PATH.VIEW_COOPERATIVE_VENTURE_PROFILE_PATH:
          profileType = PROFILE_TYPES.COOPERATIVE_VENTURE;
          break;
        /**
         * organization profile
         */
        case PATH.EDIT_ORGANIZATION_PROFILE_PATH:
        case PATH.VIEW_ORGANIZATION_PROFILE_PATH:
          profileType = PROFILE_TYPES.ORGANIZATION;
          break;
        /**
         * FARM SEASON
         */
        case PATH.FARM_SEASON_CRUD_PATH:
        case PATH.EDIT_SEASON_CRUD_FARM_PATH:
        case PATH.VIEW_SEASON_CRUD_FARM_PATH:
          profileType = FARM_TYPES.FARM_SEASON;
          id = props.match.params.idSeason;
          break;
        case PATH.EDIT_SEASON_HARVEST_FARM_PATH:
          profileType = FARM_TYPES.ACTUAL_HARVEST;
          id = props.match.params.idSeason;
          break;
        case PATH.EDIT_SEASON_EXPECTED_HARVEST_FARM_PATH:
          profileType = FARM_TYPES.EXPECTED_HARVEST;
          id = props.match.params.idSeason;
          break;
        default:
          break;
      }
      setProfileType(profileType);
      if (profileType === FARM_TYPES.FARM_SEASON)
        id = props.match.params.idSeason;
      getFilesProfile(id, profileType)
        .then(({ res }) => {
          setSpinning(false);
          setMediaProfile(
            res
              .filter(
                (file) =>
                  videoUploadConfig.type.test(file.path) ||
                  imgUploadConfig.type.test(file.path)
              )
              .map((file) => {
                return {
                  uid: file.id,
                  name: file.originalName,
                  url: file.path,
                  path: file.path,
                };
              })
          );
          setDocsProfile(
            res
              .filter(
                (file) =>
                  docsUploadConfig.type.test(file.path) ||
                  pdfUploadConfig.type.test(file.path) ||
                  excelUploadConfig.type.test(file.path) ||
                  txtUploadConfig.type.test(file.path) ||
                  otherUploadConfig.type.test(file.path) ||
                  rarUploadConfig.type.test(file.path)
              )
              .map((file) => {
                return {
                  uid: file.id,
                  name: file.originalName,
                  url: file.path,
                  path: file.path,
                };
              })
          );
        })
        .catch((err) => {
          setSpinning(false);
          console.error("ActivityForm -> getFilesProfile -> err", err);
          return showError(err);
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchFilesProfile } = this.props;
      fetchFilesProfile();
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        nextProps.media.length + nextProps.docs.length !==
        this.props.fileCount
      ) {
        const { setFileCount } = this.props;
        setFileCount(nextProps.media.length + nextProps.docs.length);
      }
    },
  }),
  withFormik({
    displayName: "activityForm",
    mapPropsToValues: () => ({}),
    handleSubmit: async () => {},
  })
)(ActivityForm);
