import React from "react";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tabs, Card, message } from "antd";
import moment from "moment";
import _ from "lodash";
// formik and yup
import { withFormik } from "formik";
import * as yup from "yup";
import {
  stringNRFieldValidate,
  numberRequiredField,
} from "~/views/utilities/validation/input";
// redux
import { getType } from "~/state/ducks/appApis/actions";
// config || common
import strings from "~/localization";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import { showError } from "~/configs/ServerErrors";
import { getArray, getString } from "~/views/utilities/helpers/utilObject";
// component
import { TableEditable } from "~/views/presentation/ui/tables";
import LicenceUploader from "../../PersonalProfileForms/LicenceForm/LicenceUploader";
import { UIButton } from "~/views/presentation/ui/buttons";
import {
  InputField,
  DatePickerField,
  SelectField,
} from "~/views/presentation/ui/fields";

const { TabPane } = Tabs;

const validationSchema = yup.object().shape({
  typeId: numberRequiredField,
  issuedDate: stringNRFieldValidate(),
  expirationDate: stringNRFieldValidate(),
  evaluationDate: stringNRFieldValidate(),
  reassessmentDate: stringNRFieldValidate(),
  issuedBy: stringNRFieldValidate(),
});
const scrollToBottom = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: "smooth",
  });
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
class GCNStandard extends React.PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {}
  handleChangeDataSource = (index, dataIndex, dataSource) => {
    const { setValues } = this.props;
    if (dataIndex === "key") {
      setValues({});
      while ((this.props.info.certifications || []).length) {
        this.props.info.certifications.shift();
      }
      dataSource.forEach((item) => {
        this.props.info.certifications.push(item);
      });
    }
  };
  handleSaveValues = () => {
    const { values, setValues, setFieldTouched } = this.props;

    let issuedDate = moment.utc(values.issuedDate);
    let expirationDate = moment.utc(values.expirationDate);
    let evaluationDate = moment.utc(values.evaluationDate);
    let reassessmentDate = moment.utc(values.reassessmentDate);
    if (issuedDate.isAfter(expirationDate)) {
      message.error(strings.issue_date_greater_than_expired_date);
      return;
    }
    if (evaluationDate.isAfter(reassessmentDate)) {
      message.error(strings.reevaluate_date_greater_than_evaluate_date);
      return;
    }

    if (values.key >= 0) {
      if ((this.props.info.certifications || []).length > values.key) {
        let keys = Object.keys(values).filter(
          (key) => !_.isArray(values[key]) && !_.isObject(values[key])
        );
        let modifyObject = keys.reduce((prev, curr) => {
          prev[curr] = values[curr];
          return prev;
        }, {});

        this.props.info.certifications[values.key] = modifyObject;
      }
      Object.keys(values).map((key) => setFieldTouched(key, false));
      setValues({});
      scrollToBottom();
    } else {
      let keys = Object.keys(values).filter(
        (key) => !_.isArray(values[key]) && !_.isObject(values[key])
      );
      let modifyObject = keys.reduce((prev, curr) => {
        prev[curr] = values[curr];
        return prev;
      }, {});
      modifyObject["kyc"] = false;
      this.props.info.certifications.push(modifyObject);
      this.props.info.certifications.forEach((item, index) => {
        item.key = index;
      });
      Object.keys(values).map((key) => setFieldTouched(key, false));
      setValues({});
      scrollToBottom();
    }
  };
  handleSelectRow = (r) => {
    const { setValues } = this.props;
    setValues(r);
    scrollToTop();
  };
  handleChange = (event) => {
    const { handleChange, setFieldTouched } = this.props;
    setFieldTouched(event.target.name, true);
    handleChange(event);
  };
  handleChangeCertificate = (images) => {
    const { setFieldValue } = this.props;

    setFieldValue("images", images);
  };

  render() {
    const {
      values,
      handleChange,
      handleBlur,
      touched,
      errors,
      handleChangeDate,
      isValid,
      setFieldValue,
      setFieldTouched,
      handleCancelChange,
      editable,
    } = this.props;

    let issuedDate = moment
      .utc(values.issuedDate, UtilDate.formatDateTimeServer)
      .local();
    let expirationDate = moment
      .utc(values.expirationDate, UtilDate.formatDateTimeServer)
      .local();
    let evaluationDate = moment
      .utc(values.evaluationDate, UtilDate.formatDateTimeServer)
      .local();
    let reassessmentDate = moment
      .utc(values.reassessmentDate, UtilDate.formatDateTimeServer)
      .local();

    const columns = [
      {
        title: strings.certificate_type,
        dataIndex: "typeId",
        key: "typeId",
        width: "15%",
        editable: false,
        placeholder: strings.certificate_type,
        type: "select",
        options: this.props.certificationTypes || [],
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.issued_by,
        dataIndex: "issuedBy",
        key: "issuedBy",
        width: "15%",
        editable: false,
        placeholder: strings.certificate_type,
        type: "text",
      },
      {
        title: strings.date_of_issue,
        dataIndex: "issuedDate",
        key: "issuedDate",
        width: "15%",
        editable: false,
        placeholder: strings.date_of_issue,
        type: "date",
      },
      {
        title: strings.expiration_date,
        dataIndex: "expirationDate",
        key: "expirationDate",
        width: "15%",
        editable: false,
        placeholder: strings.certificate_type,
        type: "date",
      },
      {
        title: strings.date_evaluate,
        dataIndex: "evaluationDate",
        key: "evaluationDate",
        width: "15%",
        editable: false,
        placeholder: strings.date_evaluate,
        type: "date",
      },
      {
        title: strings.date_reevaluate,
        dataIndex: "reassessmentDate",
        key: "reassessmentDate",
        width: "15%",
        editable: false,
        placeholder: strings.date_reevaluate,
        type: "date",
      },
      {
        title: strings.kyc,
        dataIndex: "kyc",
        key: "kyc",
        width: "15%",
        editable: false,
        placeholder: strings.certificate_type,
        type: "select",
        options: [
          { value: false, label: strings.not_authenticated },
          { value: true, label: strings.endorsed },
        ],
      },
    ];
    return (
      <div>
        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            <Card>
              <SelectField
                validateStatus={
                  touched.typeId && errors.typeId ? "error" : undefined
                }
                disabled={!editable}
                help={touched.typeId && errors.typeId ? errors.typeId : ""}
                data={this.props.certificationTypes || []}
                value={values.typeId}
                onChange={(value) => {
                  setFieldValue("typeId", value);
                  setFieldTouched("typeId");
                }}
                iconEnd="caret-down"
                placeholder={strings.placeholder_certificate_type_gap}
                label={strings.certificate_type}
              />
              <DatePickerField
                disabled={!editable}
                validateStatus={
                  touched.issuedDate && errors.issuedDate ? "error" : undefined
                }
                help={
                  touched.issuedDate && errors.issuedDate
                    ? errors.issuedDate
                    : ""
                }
                name="issuedDate"
                value={issuedDate.isValid() ? issuedDate : undefined}
                onChange={handleChangeDate(this.props)("issuedDate")}
                onBlur={handleBlur}
                label={strings.date_of_issue}
                placeholder={strings.placeholder_date_of_issue}
              />
              <DatePickerField
                disabled={!editable}
                validateStatus={
                  touched.expirationDate && errors.expirationDate
                    ? "error"
                    : undefined
                }
                help={
                  touched.expirationDate && errors.expirationDate
                    ? errors.expirationDate
                    : ""
                }
                name="expirationDate"
                value={expirationDate.isValid() ? expirationDate : undefined}
                onChange={handleChangeDate(this.props)("expirationDate")}
                onBlur={handleBlur}
                label={strings.expiration_date}
                placeholder={strings.placeholder_expiration_date}
              />
              <DatePickerField
                disabled={!editable}
                validateStatus={
                  touched.evaluationDate && errors.evaluationDate
                    ? "error"
                    : undefined
                }
                help={
                  touched.evaluationDate && errors.evaluationDate
                    ? errors.evaluationDate
                    : ""
                }
                name="evaluationDate"
                value={evaluationDate.isValid() ? evaluationDate : undefined}
                onChange={handleChangeDate(this.props)("evaluationDate")}
                onBlur={handleBlur}
                label={strings.date_evaluate}
                placeholder={strings.placeholder_date_evaluate}
              />
              <DatePickerField
                disabled={!editable}
                validateStatus={
                  touched.reassessmentDate && errors.reassessmentDate
                    ? "error"
                    : undefined
                }
                help={
                  touched.reassessmentDate && errors.reassessmentDate
                    ? errors.reassessmentDate
                    : ""
                }
                name="reassessmentDate"
                value={
                  reassessmentDate.isValid() ? reassessmentDate : undefined
                }
                // onChange={handleChangeExpirationDate(this.props)}
                onChange={handleChangeDate(this.props)("reassessmentDate")}
                onBlur={handleBlur}
                label={strings.date_reevaluate}
                placeholder={strings.placeholder_date_reevaluate}
              />
              <InputField
                validatestatus={
                  touched.issuedBy && errors.issuedBy ? "error" : undefined
                }
                help={
                  touched.issuedBy && errors.issuedBy ? errors.issuedBy : ""
                }
                name="issuedBy"
                type="text"
                disabled={!editable}
                value={values.issuedBy}
                onChange={handleChange}
                onBlur={handleBlur}
                label={strings.issued_by}
                placeholder={strings.placeholder_issued_by}
              />
            </Card>
          </div>
          <div className="col-12 col-md-6 mb-3">
            <Card className={!editable ? "avoid-click" : ""}>
              <LicenceUploader
                images={values.images}
                onChange={this.handleChangeCertificate}
              />
            </Card>
          </div>
        </div>
        <div>
          <UIButton
            type="primary"
            htmlType="submit"
            disabled={!isValid || !editable}
            onClick={this.handleSaveValues}
          >
            <span>{strings.save}</span>
          </UIButton>
          <UIButton
            type="secondary"
            className="ml-2"
            htmlType="button"
            disabled={!editable}
            onClick={handleCancelChange(this.props)}
          >
            <span>{strings.cancel}</span>
          </UIButton>
        </div>
        <div className="mt-3">
          <Tabs defaultActiveKey="1" type="card" size={"middle"}>
            <TabPane tab={strings.history} key="1">
              <TableEditable
                changedData={this.handleChangeDataSource}
                editable={editable}
                addRowAble={false}
                selectedKey={values.key}
                onSelect={this.handleSelectRow}
                selectable={true}
                dataSource={this.props.info.certifications}
                columns={columns}
              />
              <div style={{ marginTop: "16px" }}>
                <UIButton
                  style={{ float: "right" }}
                  type="primary"
                  className="ml-2"
                  htmlType="button"
                  disabled={!editable}
                  onClick={() => this.props.nextStep(3)}
                >
                  <span>{strings.countinue}</span>
                </UIButton>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    getType,
  }),
  withState("certificationTypes", "setCertificationTypes", []),
  withHandlers({
    handleCancelChange: () => (props) => () => {
      const { setValues, setTouched } = props;
      setValues({});
      setTouched({});
    },
    handleChangeIssuedDate: () => (props) => (value) => {
      const { setFieldValue, setFieldTouched } = props;
      setFieldTouched("issuedDate");
      if (value) {
        setFieldValue(
          "issuedDate",
          value.startOf("day").utc().format(UtilDate.formatDateTimeServer)
        );
      } else {
        setFieldValue("issuedDate", undefined);
      }
    },
    handleChangeDate: (_) => (props) => (name) => (value) => {
      const { setFieldValue, setFieldTouched } = props;
      setFieldTouched(name);
      if (value) {
        setFieldValue(
          name,
          value.endOf("day").utc().format(UtilDate.formatDateTimeServer)
        );
      } else {
        setFieldValue(name, undefined);
      }
    },
    handleChangeExpirationDate: () => (props) => (value) => {
      const { setFieldValue, setFieldTouched } = props;
      setFieldTouched("expirationDate");
      if (value) {
        setFieldValue(
          "expirationDate",
          value.endOf("day").utc().format(UtilDate.formatDateTimeServer)
        );
      } else {
        setFieldValue("expirationDate", undefined);
      }
    },
    fetchCertificationTypes: (props) => () => {
      const { getType, setCertificationTypes } = props;
      getType("CERTIFICATION")
        .then(({ res }) => {
          setCertificationTypes(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
  }),
  withFormik({
    displayName: "GCNStandard",
    enableReinitialize: true,
    validationSchema: validationSchema,

    mapPropsToValues: (props) => {
      return {
        images: getString(props, "info.certifications.images", ""),
      };
    },
    handleSubmit: async (values) => {},
  }),
  lifecycle({
    componentDidMount() {
      const { fetchCertificationTypes } = this.props;
      fetchCertificationTypes();
    },
  })
)(GCNStandard);
