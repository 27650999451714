import React, { useContext, useEffect, useRef, useState } from "react";
import * as PATH from "~/configs/routesConfig";
import { withRouter } from "react-router-dom";

import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
  Button,
} from "antd";

import _ from "lodash";
import moment from "moment";
import strings from "~/localization";
import { SuggestionField } from "~/views/presentation/ui/fields";
import {
  currencyFormat,
  numberFormatDecimal,
  numberFormatInt,
  numberFormatWithPoint,
} from "~/views/utilities/helpers/currency";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import {
  getArray,
  getString,
  isNullOrEmpty,
} from "~/views/utilities/helpers/utilObject";

import ModelImages from "~/views/presentation/ui/tables/ModelImages";

const EditableContext = React.createContext();

const { Option } = Select;

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  placeholder,
  suffix,
  type,
  options,
  optionIndex,
  rules,
  maxSlice,
  tableEditable = true,
  isAddMoreRow,
  colIndex,
  renderOpts,
  nameData,
  handleFetch,
  filterOption,
  disabled,
  isSearch,
  disabledDate,
  optionFilterProp,
  min,
  max,
  formatter,
  parser,
  ...restProps
}) => {
  let dataOptions = optionIndex ? record[optionIndex] || [] : options;
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      // type: number, date, year, select, selectMulti, images, selectSuggestion, link
      // type do not focus: images, link
      if (type === "images" || type === "link") return;
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (!tableEditable && type !== "images") return;
    setEditing(!editing);
    if (
      type === "text" ||
      type === "select" ||
      type === "currency" ||
      type === "selectSuggestion" ||
      type === "selectMulti"
    ) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    } else if (type === "date" || type === "year") {
      let date = undefined;
      if (!editing) {
        date = moment.utc(record[dataIndex], UtilDate.formatDateTimeServer);
        form.setFieldsValue({
          [dataIndex]: date.isValid() ? date : undefined,
        });
      }
    }
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if (
        type === "number" ||
        type === "number5" ||
        type === "currency" ||
        type === "numberReadOnly" ||
        type === "numberInput" ||
        type === "decimalInput"
      ) {
        let intValue = +values[dataIndex];
        if (!_.isNaN(intValue) && record[dataIndex] !== intValue) {
          handleSave({ ...record, [dataIndex]: intValue }, dataIndex);
        }
      } else if (
        type === "text" ||
        type === "select" ||
        type === "selectSuggestion" ||
        type === "selectMulti"
      ) {
        if (record[dataIndex] !== values[dataIndex]) {
          handleSave({ ...record, ...values }, dataIndex);
        }
      } else if (type === "date" || type === "year") {
        let date = values[dataIndex];
        handleSave(
          {
            ...record,
            ...{
              [dataIndex]:
                date && date.isValid()
                  ? date.format(UtilDate.formatDateTimeServer)
                  : undefined,
            },
          },
          dataIndex
        );
      }
    } catch (errInfo) {
      toggleEdit();
      if (
        type === "text" ||
        type === "select" ||
        type === "selectSuggestion" ||
        type === "selectMulti"
      ) {
        handleSave({ ...record }, dataIndex);
      } else if (type === "date" || type === "year") {
        handleSave({ ...record }, dataIndex);
      }
    }
  };

  const saveImages = (updateImages) => {
    try {
      toggleEdit();
      handleSave({ ...record, ...{ [dataIndex]: updateImages } }, dataIndex);
    } catch (error) {
      handleSave({ ...record }, dataIndex);
    }
  };

  let childNode = children;
  const renderEditChildren = () => {
    if (type === "text") {
      return (
        <Input
          ref={inputRef}
          maxLength={255}
          placeholder={placeholder}
          onPressEnter={save}
          onBlur={save}
        />
      );
    } else if (
      type === "number" ||
      type === "currency" ||
      type === "numberReadOnly"
    ) {
      return (
        <Input
          type="number"
          min={min || 0}
          max={max}
          onInput={(e) =>
            (e.target.value = e.target.value.slice(0,  12).replace(".", ""))
          }
          ref={inputRef}
          formatter={(value) => {
            const va = `${value}${suffix}`;
            return va;
          }}
          placeholder={placeholder}
          // suffix={suffix}
          onPressEnter={save}
          onBlur={save}
        />
      );
    } else if (
      type === "number5"
    ) {
      return (
        <Input
          type="number"
          min={min || 0}
          max={max}
          onInput={(e) =>
            (e.target.value = e.target.value.slice(0, 5).replace(".", ""))
          }
          ref={inputRef}
          formatter={(value) => {
            const va = `${value}${suffix}`;
            return va;
          }}
          placeholder={placeholder}
          // suffix={suffix}
          onPressEnter={save}
          onBlur={save}
        />
      );
    } else if (type === "decimalInput") {
      return (
        <Input
          type="number"
          min={min || 0}
          max={max}
          step={0.1}
          onInput={(e) => (e.target.value = e.target.value.slice(0,  12))}
          ref={inputRef}
          placeholder={placeholder}
          suffix={suffix}
          onPressEnter={save}
          onBlur={save}
        />
      );
    } else if (type === "numberInput") {
      return (
        <InputNumber
          min={min || 0}
          max={max}
          onInput={(e) => (e.target.value = e.target.value.slice(0, 12))}
          ref={inputRef}
          placeholder={placeholder}
          suffix={suffix}
          formatter={formatter}
          parser={parser}
          onPressEnter={save}
          onBlur={save}
        />
      );
    } else if (type === "date") {
      return (
        <DatePicker
          defaultOpen
          showAction="focus"
          allowClear
          picker="date"
          ref={inputRef}
          onChange={save}
          onBlur={save}
          placeholder={placeholder}
          format={UtilDate.formatDateLocal}
          disabledDate={disabledDate}
        />
      );
    } else if (type === "year") {
      return (
        <DatePicker
          defaultOpen
          showAction="focus"
          allowClear
          picker="year"
          ref={inputRef}
          onChange={save}
          onBlur={save}
          placeholder={placeholder}
          format={"YYYY"}
        />
      );
    } else if (type === "select") {
      return (
        <Select
          defaultValue={record[dataIndex]}
          onBlur={save}
          onChange={save}
          openOnFocus
          placeholder={placeholder}
          ref={inputRef}
          showSearch
          filterOption={(inputValue, option) =>
            option.children.toUpperCase().indexOf(inputValue.toUpperCase()) !==
            -1
          }
          showAction="focus"
          size="middle"
          style={{ width: "100%" }}
        >
          {getArray(dataOptions, undefined, []).map((item) => (
            <Option
              key={item.value}
              value={item.value}
              disabled={item.disabled}
            >
              {item.label}
            </Option>
          ))}
        </Select>
      );
    } else if (type === "selectMulti") {
      return (
        <Select
          defaultValue={record[dataIndex]}
          disabled={disabled}
          filterOption={filterOption}
          mode="multiple"
          onBlur={save}
          onChange={save}
          openOnFocus
          optionFilterProp={optionFilterProp}
          placeholder={placeholder}
          ref={inputRef}
          showAction="focus"
          size="middle"
          style={{ width: "100%" }}
          suffix={suffix}
        >
          {getArray(dataOptions, undefined, []).map((item) => (
            <Option key={item.value} value={item.value} label={item.label}>
              {item.label}
            </Option>
          ))}
        </Select>
      );
    } else if (type === "images") {
      return (
        <>
          <ModelImages
            title={title}
            editable={tableEditable}
            images={record[dataIndex]}
            onChange={saveImages}
          />
          <i className="fa fa-spinner" style={{ fontSize: "20px" }} />
        </>
      );
    } else if (type === "selectSuggestion") {
      return (
        <SuggestionField
          data={dataOptions}
          defaultValue={record[dataIndex]}
          disabled={disabled}
          filterOption={filterOption}
          forwardRef={inputRef}
          handleFetch={handleFetch}
          isSearch={isSearch}
          nameData={nameData}
          onBlur={save}
          onChange={save}
          openOnFocus
          optionFilterProp={optionFilterProp}
          placeholder={placeholder}
          renderOpts={renderOpts}
          showAction="focus"
          size="middle"
          style={{ height: "auto", outline: "none", border: "none" }}
          suffix={suffix}
        />
      );
    }
  };

  const renderChildren = () => {
    if (type === "images") {
      if (getString(record[dataIndex], undefined, "").length > 0) {
        return <i className="fa fa-edit" style={{ fontSize: "20px" }} />;
      } else {
        return <span className="placeholder">{placeholder}</span>;
      }
    } else if (type === "number") {
      if (typeof record[dataIndex] !== "number") {
        return <span className="placeholder">{placeholder}</span>;
      } else {
        return suffix ? (
          <span className="input-option">{`${record[dataIndex]}${suffix}`}</span>
        ) : (
          <span className="input-option">{`${record[dataIndex]}`}</span>
        );
      }

    }else if (type === "number5") {
      if (typeof record[dataIndex] !== "number") {
        return <span className="placeholder">{placeholder}</span>;
      } else {
        return suffix ? (
          <span className="input-option">{`${record[dataIndex]}${suffix}`}</span>
        ) : (
          <span className="input-option">{`${record[dataIndex]}`}</span>
        );
      }
    }  
    else if (type === "numberInput") {
      return typeof record[dataIndex] !== "number" ? (
        <span className="placeholder">{placeholder}</span>
      ) : (
        <span>
          {numberFormatWithPoint(record[dataIndex])} {suffix}
        </span>
      );
    } else if (type === "decimalInput") {
      return typeof record[dataIndex] !== "number" ? (
        <span className="placeholder">{placeholder}</span>
      ) : (
        <span>{numberFormatDecimal(record[dataIndex], suffix)}</span>
      );
    } else if (type === "currency") {
      return typeof record[dataIndex] !== "number" ? (
        <span className="placeholder">{placeholder}</span>
      ) : (
        <span>{currencyFormat(record[dataIndex])}</span>
      );
    } else if (type === "numberReadOnly") {
      return typeof record[dataIndex] !== "number" ? (
        <span className="placeholder">{placeholder}</span>
      ) : (
        <span>{currencyFormat(record[dataIndex])}</span>
      );
    } else if (isNullOrEmpty(children[1])) {
      return <span className="placeholder">{placeholder}</span>;
    } else {
      if (type === "text") {
        return children;
      } else if (type === "link") {
        return <a href="# "> {record[dataIndex] || `  `} </a>;
      } else if (type === "select" || type === "selectSuggestion") {
        let selectedObject = getArray(dataOptions, undefined, []).find(
          (item) => item.value + "" === record[dataIndex] + ""
        );
        return selectedObject
          ? [null, getString(selectedObject, "label")]
          : [null, record[dataIndex]];
      } else if (type === "selectMulti") {
        if (getArray(record[dataIndex], undefined, []).length <= 1) {
          let selectedObject = getArray(dataOptions, undefined, []).find(
            (item) => {
              return item.value + "" === record[dataIndex] + "";
            }
          );
          return selectedObject ? (
            [null, getString(selectedObject, "label")]
          ) : (
            <span className="placeholder">{placeholder}</span>
          );
        } else {
          let selectedObject = _.intersection(
            getArray(dataOptions, undefined, []).map((item) => item.value),
            record[dataIndex]
          );
          // Sau khi so sánh thì compare với giá trị để hiện thị và join lại thành chuỗi
          let resultMap = selectedObject.map((item) => {
            return {
              label: getArray(dataOptions, undefined, []).find((i) => {
                return i.value + "" === item + "";
              }).label,
              value: item,
            };
          });
          let result = resultMap.map((item) => item.label);
          return result ? [null, result.join(`; `)] : [null, record[dataIndex]];
        }
      } else if (type === "date") {
        let date = moment.utc(record[dataIndex], UtilDate.formatDateTimeServer);
        return date && date.isValid()
          ? [null, date.format(UtilDate.formatDateLocal)]
          : [null];
      } else if (type === "year") {
        let date = moment.utc(record[dataIndex], UtilDate.formatDateTimeServer);
        return date && date.isValid() ? [null, date.format("YYYY")] : [null];
      }
    }
    return null;
  };

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={type !== "images" ? rules : []}
      >
        {renderEditChildren()}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {renderChildren()}
      </div>
    );
  }
  if (tableEditable && isAddMoreRow) {
    return null;
  }
  return <td {...restProps}>{childNode}</td>;
};

const generateAutoKey = (dataSource) => {
  return getArray(dataSource, undefined, []).map((item, index) => ({
    ...item,
    key: index,
    STT: index + 1,
  }));
};

class TableEditable extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    let view = false;
    switch (match.path) {
      case PATH.VIEW_FARM_FORM_PATH:
        {
          view = true;
        }
        break;
    }
    this.state = {
      dataSource: generateAutoKey(this.props.dataSource),
      none: !view,
      disabledBu: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.dataReal.length !== nextProps.dataReal.length) {
      if (nextProps.dataSource.length === nextProps.dataReal.length)
        this.setState({ disabledBu: true });
      else {
        this.setState({ disabledBu: false });
      }
    }
    if (this.state.dataSource !== nextProps.dataSource) {
      this.setState(
        { dataSource: generateAutoKey(nextProps.dataSource) },
        () => {}
      );
    }
    // if (this.props.dataReal !== nextProps.dataReal) {
    //   this.setState({
    //     dataSource: [
    //       {
    //         productName: nextProps.dataReal,
    //         key: 0,
    //         STT: 1,
    //         total: 0,
    //         toPriceShell: 0,
    //         const_serviceToMoney: 0,
    //       },
    //     ],
    //   });
    // }
  }

  handleDelete = (key) => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key),
    });
  };

  handleAdd = () => {
    const { dataReal } = this.props;
    if (this.state.dataSource.length === dataReal.length) {
      this.setState({ disabledBu: true });
      return;
    } else {
      const { count } = this.state;

      const newData = this.props.handleAddRow
        ? this.props.handleAddRow()
        : { total: 0, toPriceShell: 0, const_serviceToMoney: 0, week: 0 };
      this.state.dataSource.push(newData);
      if (this.state.dataSource.length === dataReal.length) {
        this.setState({ disabledBu: true });
      }
      this.setState({
        dataSource: generateAutoKey(this.state.dataSource).map((val) => ({
          ...val,
        })),
        count: count + 1,
      });
    }
  };

  handleSave = (row, dataIndex) => {
    const newData = [...this.state.dataSource];
    const index = row.key;
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({ dataSource: newData }, () => {
      const { changedData } = this.props;
      /**
       * index : Số thứ tự của dòng có data thay đổi
       * dataIndex: Tên field dữ liệu thay đổi
       */
      changedData && changedData(index, dataIndex, this.state.dataSource);
    });
  };

  render() {
    const { dataSource } = this.state;
    const {
      selectable = false,
      onSelect,
      selectedKey,
      addRowAble = true,
      editable = true,
      onlyOneRow,

      scroll,
    } = this.props;

    const checkFilledRow = (val) => {
      var filled = true;
      for (var i = 0; i < (this.props.columns || []).length; i++) {
        if (
          (this.props.columns[i].required === true) &
          isNullOrEmpty(val[this.props.columns[i].dataIndex])
        ) {
          filled = false;
        }
        const child = getArray(this.props.columns[i], "children", []);
        if (child.length) {
          child.map((valChil) => {
            if (
              (valChil.required === true) &
              isNullOrEmpty(val[valChil.dataIndex])
            ) {
              filled = false;
            }
          });
        }
      }
      return filled;
    };

    /**
     * kiểm tra những field required đã được đổ data chưa
     */
    let notFilledRow = (dataSource || []).filter((item) => {
      return !checkFilledRow(item);
    });

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    const renderContainer = (value, rowIndex) => {
      return (
        <>
          <span>{value}</span>
          <p
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
            onClick={() => {
              onSelect && onSelect(dataSource[rowIndex]);
            }}
          ></p>
        </>
      );
    };

    const renderZeroColSpan = () => {
      return {
        props: {
          colSpan: 0,
        },
      };
    };
    const mapColumns = (col, colIndex) => {
      if (!col.editable) {
        return {
          ...col,
          render: (record, rowData, index) => {
            if ((this.state.dataSource || []).length === index)
              return renderZeroColSpan();
            if (col.type === "numberReadOnly") {
              return renderContainer(
                numberFormatInt(record, col.suffix),
                rowData.key
              );
            }
            if (col.type === "numberInput") {
              return renderContainer(currencyFormat(record), rowData.key);
            }
            if (col.type === "decimalInput") {
              return renderContainer(
                numberFormatDecimal(record, col.suffix),
                rowData.key
              );
            } else if (col.type === "date") {
              let date = moment.utc(record, UtilDate.formatDateTimeServer);
              return renderContainer(
                date.isValid() ? date.format(UtilDate.formatDateLocal) : "",
                rowData.key
              );
            } else if (col.type === "year") {
              let date = moment.utc(record, UtilDate.formatDateTimeServer);
              return renderContainer(
                date.isValid() ? date.format("YYYY") : "",
                rowData.key
              );
            } else if (
              col.type === "select" ||
              col.type === "selectSuggestion" ||
              col.type === "selectMulti"
            ) {
              let selectOption = [];
              if (_.isArray(record)) {
                record.map((val) => {
                  const itemFind = _.find(col.options, (item) => {
                    return item.value === val;
                  });
                  selectOption.push(getString(itemFind, "label", ""));
                });

                return renderContainer(selectOption.join(", "), rowData.key);
              } else {
                selectOption = _.find(col.options, (item) => {
                  return item.value === record;
                });
                return renderContainer(
                  getString(selectOption, "label", ""),
                  rowData.key
                );
              }
            }
            return renderContainer(record, rowData.key);
          },
        };
      }

      let newCol = {
        ...col,
        onCell: (record, rowIndex) => ({
          record,
          editable: col.editable,
          isAddMoreRow: rowIndex === (this.state.dataSource || []).length,
          colIndex: colIndex,
          tableEditable: editable,
          dataIndex: col.dataIndex,
          title: col.title,
          placeholder: col.placeholder,
          type: col.type,
          options: col.options,
          rules: col.rules,
          optionIndex: col.optionIndex,
          handleSave: this.handleSave,
          handleFetch: col.handleFetch,
          nameData: col.nameData,
          renderOpts: col.renderOpts,
          filterOption: col.filterOption,
          suffix: col.suffix,
          disabled: col.disabled,
          disabledDate: col.disabledDate,
          isSearch: col.isSearch,
          optionFilterProp: col.optionFilterProp,
          min: col.min,
          max: col.max,
          formatter: col.formatter,
          parser: col.parser,
        }),
      };
      if (col.children) {
        newCol.children = col.children.map(mapColumns);
      }
      return newCol;
    };
    let columns = this.props.columns.map(mapColumns);

    const removeRow = (record) => {
      const { dataReal } = this.props;

      const { changedData } = this.props;
      if (dataSource.length < 2) return;
      this.setState(
        {
          dataSource: (this.state.dataSource || [])
            .filter((item) => item.key !== record.key)
            .map((val, index) => ({ ...val, STT: index + 1 })),
        },
        () => {
          if (dataReal.length > this.state.dataSource.length) {
            this.setState({ disabledBu: false });
          }
          changedData && changedData(record.key, "key", this.state.dataSource);
        }
      );
    };
    const { headerAdd } = this.props;
    if (editable && headerAdd && this.state.dataSource.length) {
      columns.unshift({
        title: undefined,
        width: "40px",
        className: "px-1",
        render: (record) => {
          return (
            <button
              className="btn"
              style={{
                height: "30px",
                width: "30px",
                display: "flex",
                justifyContent: "center",
                color: "rgba(0, 0, 0, 0.65)",
              }}
              onClick={() => removeRow(record)}
            >
              <i className="fa fa-trash-o" style={{ fontSize: "15px" }}></i>
            </button>
          );
        },
      });
    }

    if (editable && !headerAdd) {
      let lengthOfDataSource = (this.state.dataSource || []).length;
      let lengthOfColumns = columns.length;
      columns.unshift({
        title: undefined,
        width: "40px",
        className: "px-1",
        render: (data, records, index) => {
          if (lengthOfDataSource === index) {
            return {
              children: (
                <button
                  onClick={this.handleAdd}
                  className="btn py-0 d-flex align-items-center"
                  style={{ paddingLeft: 8, color: "rgba(0,0,0,0.65)" }}
                  disabled={
                    (notFilledRow || []).length !== 0 ||
                    (lengthOfDataSource === 1 && onlyOneRow)
                  }
                >
                  <i className="fa fa-plus-circle" />
                  <span className="ml-3">{strings.add_row}</span>
                </button>
              ),
              // children: <span>{lengthOfColumns}</span>,
              props: {
                colSpan: lengthOfColumns + 1,
              },
            };
          }
          return (
            <button
              className="btn"
              style={{
                height: "30px",
                width: "30px",
                display: "flex",
                justifyContent: "center",
                color: "rgba(0, 0, 0, 0.65)",
              }}
              onClick={() => removeRow(records)}
            >
              <i className="fa fa-trash-o" style={{ fontSize: "15px" }}></i>
            </button>
          );
        },
      });
    }

    return (
      <div className="w-100">
        {/**
         * input-option
         */}
        {addRowAble && editable && this.props.headerAdd && (
          <div className="d-flex ">
            <Button
              onClick={this.handleAdd}
              type="primary"
              disabled={
                (notFilledRow || []).length !== 0 ||
                (this.state.dataSource.length === 1 && onlyOneRow) ||
                this.state.disabledBu
              }
              style={{
                marginBottom: 16,
              }}
            >
              <i className="fa fa-plus-circle" />
              <span className="ml-2">{strings.add_row}</span>
            </Button>
          </div>
        )}

        {/**
         * Het !!!1
         */}

        <div className="overflow-auto">
          <Table
            className={this.state.none && "none-table"}
            components={components}
            scroll={{x:0}}
            rowClassName={(r) =>
              ` editable-row ${
                selectedKey && selectedKey === r.key ? "selected-row" : ""
              } ${selectable ? "selectable-row" : ""}`
            }
            dataSource={
              !editable || onSelect ? dataSource : [...dataSource, {}]
            }
            columns={columns}
            pagination={false}
            /** input-option*/
            footer={this.props.footer || undefined}
            bordered={this.props.bordered}
            summary={this.props.summary || undefined}
            /** end! */
          />
        </div>
      </div>
    );
  }
}

export default withRouter(TableEditable);
