import { getArray, getNumber, getString } from "./utilObject";
import _, { mergeWith } from "lodash";
import { getPriceOfProductCartItem } from "./mapData";

function checkSameProduct(item, product) {
    return (
        getNumber(item, "productId") == getNumber(product, "productId") &&
        getNumber(item, "manufacturerId") == getNumber(product, "manufacturerId")
    );
}
function checkPrice(item) {
    let price = getNumber(item, "salePrice", 0) ? getNumber(item, "salePrice", 0) : getNumber(item, "price", 0);
    return price;
}
function caculatorPrice(pricingOptions, qty) {
    let result = { price: 0, id: null };
    const pricingRanges = getArray(pricingOptions, undefined, []);
    if (pricingRanges.length) {
        _.forEach(pricingRanges, (item) => {
            const fromValue = getNumber(item, "fromValue", 0);
            const toValue = getNumber(item, "toValue", 0);
            if (!toValue) {
				result.price = checkPrice(item);
				result.id = item.id
                return false;
            }
            if (qty >= fromValue && qty <= toValue) {
				result.price = checkPrice(item);
				result.id = item.id
                return false;
            }
        });
    }
    return result;
}
export function changeProductQuantity(itemsStored, product, quantity) {
    let cartItems = getArray(itemsStored, "cartItems") || [];
    console.log("changeProductQuantity -> itemsStored", product, quantity);

    let existObject = cartItems.find(
        (item) => getNumber(item, "manufacturerId") === getNumber(product, "manufacturerId")
    );
    if (existObject) {
        let products = getArray(existObject, "orderDetails") || [];
        let existProduct = products.find((item) => checkSameProduct(item, product));
        if (existProduct) {
            /**
             * tan.hoang@vslsoft.com : need change pricingRangeId when quantity changed
             */
            let pricingRanges = getArray(existProduct, "pricingRanges", []);
            const pricingRangesSortReverse = _.reverse(_.sortBy(pricingRanges, ["fromValue"]));
            const priceItem = _.find(pricingRangesSortReverse, (o) => parseInt(quantity) >= parseInt(o.fromValue));
            let pricingRangeId = caculatorPrice(getArray(product, "pricingRangeOptions", []), quantity).id;
            existProduct.quantity = quantity;
            let priceNew = getPriceOfProductCartItem(product, existProduct.quantity);
            existProduct.unitPrice = caculatorPrice(getArray(product, "pricingRangeOptions", []), quantity).price;
            console.log("priceeeeeeeee", caculatorPrice(getArray(product, "pricingRangeOptions", []), quantity));
            existProduct.subTotal = existProduct.quantity * priceNew;
            existProduct.pricingRangeId = pricingRangeId;
        }
        existObject.amount = _.sum(
            getArray(existObject, "orderDetails", []).map((item) => getNumber(item, "subTotal", 1))
        );
        itemsStored.totalPrice = sumTotalPrice(cartItems);
        itemsStored.totalItem = sumTotalItem(cartItems);
    }
    return itemsStored;
}

export function removeProduct(itemsStored, product) {
    let cartItems = getArray(itemsStored, "cartItems") || [];

    let newCartItem = getArray(cartItems, undefined, []).map((item) => ({
        ...item,
        orderDetails:
            getNumber(item, "manufacturerId") === getNumber(product, "manufacturerId") ||
            getArray(cartItems, "orderDetails", []).lenght != 0
                ? getArray(item, "orderDetails", []).filter(
                      (itemProduct) => getNumber(itemProduct, "productId") != getNumber(product, "productId")
                  )
                : getArray(item, "orderDetails"),
    }));

    let existObject = newCartItem.find(
        (item) => getNumber(item, "manufacturerId") === getNumber(product, "manufacturerId")
    );
    existObject.amount = _.sum(getArray(existObject, "orderDetails", []).map((item) => getNumber(item, "subTotal", 1)));

    // newCartItem = newCartItem.filter((item) => item.manufacturerId != product.manufacturerId && getArray(item, "orderDetails", []).lenght != 0);

    itemsStored.cartItems = newCartItem;
    itemsStored.totalItem = sumTotalItem(newCartItem);
    itemsStored.totalPrice = sumTotalPrice(newCartItem);

    return itemsStored;
}

export function addProduct(itemsStored, product) {
    let cartItems = getArray(itemsStored, "cartItems") || [];

    let existObject = cartItems.find(
        (item) => getNumber(item, "manufacturerId") === getNumber(product, "manufacturerId")
    );

    if (existObject) {
        let products = getArray(existObject, "orderDetails", []);
        let exitsProduct = products.find((item) => checkSameProduct(item, product));
        if (exitsProduct) {
            exitsProduct.quantity = exitsProduct.quantity + product.quantity;
            exitsProduct.unitPrice = exitsProduct.unitPrice + product.unitPrice;
            exitsProduct.subTotal = exitsProduct.quantity * exitsProduct.unitPrice;
            existObject.amount = _.sum(getArray(existObject, "orderDetails", []).map((item) => item.subTotal || 0));
        } else {
            products.push({
                ...product,
                subTotal: product.quantity * getNumber(product, "unitPrice") || 0,
            });
            existObject.amount = _.sum(getArray(existObject, "orderDetails", []).map((item) => item.subTotal || 0));
        }
    } else {
        cartItems.push({
            manufacturerId: getNumber(product, "manufacturerId", 0),
            manufacturerName: getString(product, "manufacturerName", ""),
            amount: product.quantity * getNumber(product, "unitPrice") || 0,
            orderDetails: [
                {
                    ...product,
                    subTotal: product.quantity * getNumber(product, "unitPrice") || 0,
                },
            ],
        });
    }

    return {
        cartItems: cartItems,
        totalPrice: sumTotalPrice(cartItems),
        totalItem: sumTotalItem(cartItems),
    };
}

function sumTotalItem(itemsStored) {
    let cartItems = getArray(itemsStored || []);
    let items = cartItems.map((it) => _.sum((getArray(it, "orderDetails") || []).map((item) => item.quantity || 0)));
    let quantity = _.sum(items);
    return quantity;
}

function sumTotalPrice(itemsStored) {
    let cartItems = getArray(itemsStored || []);
    let items = cartItems.map((it) => _.sum((getArray(it, "orderDetails") || []).map((item) => item.subTotal || 0)));
    let subTotal = _.sum(items);
    return subTotal;
}
