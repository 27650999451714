import React from "react";
import { compose, withHandlers, lifecycle, withState } from "recompose";
import { connect } from "react-redux";
import {
  getAllProces,
  deletePersonalProfile,
  getAllProcessSeasonApplied,
} from "~/state/ducks/appApis/actions";
import { showError } from "~/configs/ServerErrors";
import strings from "~/localization";
import { ContentWrapper } from "~/views/presentation/ui/container";
import styled from "styled-components";
import UtilDate from "~/views/utilities/helpers/UtilDate";

import { message } from "antd";
import {
  getString,
  getNumber,
  mapArrayObjectToAPI,
  getArray,
} from "~/views/utilities/helpers/utilObject";
import { PROFILE_TYPES, PROCESS_RATING } from "~/configs/const";
import UITable from "~/views/presentation/ui/tables/Table";
import { withRouter } from "react-router-dom";
import {
  VIEW_FARM_PROCEDURE_PATH,
  VIEW_FARM_SEASON_PROCEDURE_PATH,
} from "~/configs/routesConfig";

const GridStyled = styled.div`
  .total {
    font-size: 16px;
    font-weight: 500;
    margin-left: 16px;
  }
  .right h4 {
    min-height: 20px;
    max-height: 50px;
    font-weight: 550;

    white-space: nowrap;
    width: 364px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .right .des {
    display: -webkit-box;
    max-height: 50px;
    -webkit-line-clamp: 2;
    width: 364px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
  display: flex;
  flex-wrap: wrap;
  padding: 0px 9px !important;
  .item {
    width: calc(100% / 4 - 10px);
    @media (max-width: 1300px) {
      width: calc(100% / 3 - 10px);
    }
    @media (max-width: 870px) {
      width: calc(100% / 2 - 10px);
    }
    @media (max-width: 650px) {
      width: calc(100% - 10px);
    }
    margin: 0px 5px 10px 5px;
  }

  div .item-container {
    cursor: pointer;
    min-height: 210px;
    background-color: #fff;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    .left {
      .ant-avatar {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        border: 1px solid #c3c3c3;
        margin: 10px 15px 5px 15px;
        overflow: hidden;
      }
      p {
        text-align: center;
      }
    }
    .right {
      flex: 1;
      padding: 0px 10px 0px 10px;
      div .title::after {
        content: ": ";
      }
      div .title {
        min-width: 100px;
      }
      div .value {
        font-weight: 500;
      }
      div p {
        margin-bottom: 0px;
      }
      div {
        margin-bottom: 10px;
        display: flex;
      }
    }
    button {
      position: absolute;
      right: 15px;
      top: 0px;
      height: 40px;
      width: 40px;
      i {
        font-size: 20px;
      }
    }
    button:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;

class AllProcedure extends React.PureComponent {
  getProfileType = (type) => {
    switch (type) {
      case PROFILE_TYPES.PERSONAL_PROFILE:
        return strings.persional_profile;
      case PROFILE_TYPES.FARM_PROFILE:
        return strings.farm_profile;
      case PROFILE_TYPES.ENTERPRISE:
        return strings.enterprise_profile;
      case PROFILE_TYPES.COOPERATIVE:
        return strings.cooperative_profile;
      case PROFILE_TYPES.COOPERATIVE_VENTURE:
        return strings.cooperative_venture_profile;
      case PROFILE_TYPES.ORGANIZATION:
        return strings.organization_profile;
      default:
        return "";
    }
  };
  getTypeProcess = (type) => {
    switch (type) {
      case PROCESS_RATING.EXCELLENT:
        return strings.excellent;
      case PROCESS_RATING.VERY_GOOD:
        return strings.very_good;
      case PROCESS_RATING.GOOD:
        return strings.good;
      case PROCESS_RATING.FAIR:
        return strings.fair;
      case PROCESS_RATING.POOR:
        return strings.poor;

      default:
        return "None";
    }
  };
  handleViewProfile = (profile) => {
    const { history, match } = this.props;
    if (match.params.idSeason)
      history.push(
        VIEW_FARM_SEASON_PROCEDURE_PATH.replace(
          ":idProcedure",
          getNumber(profile, "id")
        )
          .replace(":idSeason", match.params.idSeason)
          .replace(":id", match.params.id)
      );
    else
      history.push(
        VIEW_FARM_PROCEDURE_PATH.replace(
          ":idProcedure",
          getNumber(profile, "id")
        )
      );
  };

  handleDeleteProfile = (profile) => () => {
    const { deletePersonalProfile, fetchAllProcedure } = this.props;
    let profileId = getNumber(profile, "id");
    const getAPIDelete = () => {
      switch (getString(profile, "type")) {
        case PROFILE_TYPES.PERSONAL_PROFILE:
          {
            return deletePersonalProfile(profileId);
          }
          break;
        case PROFILE_TYPES.COOPERATIVE_PROFILE:
          {
            return deletePersonalProfile(profileId);
          }
          break;
        default:
          break;
      }
    };
    getAPIDelete()
      .then(() => {
        message.success(strings.delete_profile_success);
        fetchAllProcedure();
      })
      .catch((err) => showError(err));
  };

  render() {
    /**
     * event cell click -> view profile detail
     */
    const renderCellItem = (data, records) => {
      return (
        <>
          <span>{data}</span>
          <p
            onClick={() => this.handleViewProfile(records)}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}></p>
        </>
      );
    };

    const columns = [
      {
        title: strings.name,
        dataIndex: "name",
        key: "name",
        width: "15%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.date_upadte,
        dataIndex: "date",
        key: "date",
        width: "15%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.rate,
        dataIndex: "rate",
        key: "rate",
        width: "10%",
        render: (record, records) => {
          return renderCellItem(
            this.getTypeProcess(getString(record, undefined, "")),
            records
          );
        },
      },
      {
        title: strings.decription_procedure,
        dataIndex: "decription",
        key: "decription",
        width: "25%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.stage,
        dataIndex: "step",
        key: "step",
        align: "center",

        width: "10%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.total_procedure,
        dataIndex: "total",
        align: "center",
        key: "total",
        width: "15%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
    ];

    const { procedure, viewAsBlock = false } = this.props;
    return (
      <ContentWrapper className="p-0">
        {viewAsBlock ? (
          <GridStyled className="row p-0">
            {getArray(procedure, undefined, []).map((item, index) => (
              <div className="item" key={index}>
                <div className="item-container">
                  <div
                    className="left"
                    onClick={() => this.handleViewProfile(item)}></div>
                  <div
                    className="right  w-100"
                    onClick={() => this.handleViewProfile(item)}>
                    <h4 style={{ fontWeight: "500", marginTop: "10px" }}>
                      {getString(item, "name", "")}
                    </h4>
                    <div
                      style={{
                        justifyContent: "space-between",
                        color: "gray",
                        fontStyle: "italic",
                      }}>
                      <div className="d-block mb-0">
                        <p className="title mb-0">{strings.date_upadte}</p>
                        <div className="value mb-0">
                          {" "}
                          {UtilDate.toDateTimeLocal(
                            getString(item, "date", " None")
                          )}
                        </div>
                      </div>
                      <div>
                        <p className="title">{strings.rate}</p>
                        <p className="value">
                          {this.getTypeProcess(getString(item, "rate", ""))}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "block" }}>
                      <span>{strings.decription_procedure}: </span>
                      <span
                        className="des"
                        style={
                          getString(item, "description", "None") === "None"
                            ? { color: "gray" }
                            : null
                        }>
                        {getString(item, "description", "None")}
                      </span>
                    </div>
                    <div>
                      <p>{strings.stage}:</p>
                      <p className="total"> {getNumber(item, "step", 0)}</p>
                      <p style={{ marginLeft: "auto" }}>
                        {strings.total_procedure}:
                      </p>
                      <p className="total">{getNumber(item, "total", 0)}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </GridStyled>
        ) : (
          <UITable
            dataSource={getArray(procedure, undefined, [])}
            rowClassName="cursor-pointer"
            columns={columns}
          />
        )}
      </ContentWrapper>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    deletePersonalProfile,
    getAllProces,
    getAllProcessSeasonApplied,
  }),
  withState("procedure", "setProcedure", []),
  withHandlers({
    fetchAllProcedure: (props) => () => {
      const { getAllProces, setProcedure, match, getAllProcessSeasonApplied } =
        props;
      const { type } = match.params;

      const CHANGE_NAME = [
        {
          currName: "stepsNumber",
          changeName: "step",
        },
        {
          currName: "appliedNumber",
          changeName: "total",
        },
        {
          currName: "ratings",
          changeName: "rate",
        },
        {
          currName: "lastModifiedDate",
          changeName: "date",
        },
      ];
      /**
       * TAN CHANGED
       * Tùy vào có idSeaon không mà sẽ gọi API tương ứng cho mùa vụ của season và mùa vụ chung của trang trại
       */
      if (match.params.idSeason)
        getAllProcessSeasonApplied(match.params.idSeason)
          .then(async ({ res }) => {
            const objectMap = mapArrayObjectToAPI(
              res.filter(
                (i) =>
                  type.toString() === "ALL"
                    ? true
                    : i.typeId === parseInt(type),
                CHANGE_NAME
              )
            );
            setProcedure(objectMap);
          })
          .catch((err) => {
            console.error("getAllProcessSeasonApplied -> err", err);
            return showError(err);
          });
      else
        getAllProces(type === "ALL" ? "" : type)
          .then(async ({ res }) => {
            const objectMap = mapArrayObjectToAPI(res, CHANGE_NAME);
            setProcedure(objectMap);
          })
          .catch((err) => {
            console.error("getAllProces -> err", err);
            return showError(err);
          });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchAllProcedure } = this.props;
      fetchAllProcedure();
    },
  })
)(AllProcedure);
