import styled from "styled-components";
import backgroundUser from "~/static/images/background-user-infor.jpg";
import COLOR from "~/views/utilities/layout/color";
import { Button } from "antd";

export const DivStyled = styled.div`
  position: relative;
  height: 100%;
  background-color: #fff;
  border-right: 1px solid #dcdcdc;

  transform: ${(props) =>
    props.showLeftMenu ? "translateX(0%)" : "translateX(-100%)"};
  transition: 1s;

  .ant-menu-item {
    height: 100%;
    color: #607d8b;
    text-transform: capitalize;
  }
  .ant-menu-item-selected {
    font-weight: 600;
    color: #66963a;
    background-color: #edf5e3 !important;
    box-shadow: inset 4px 0 0 #66963a;
  }
  .ant-menu-item a,
  .ant-menu-submenu-title {
    height: 100%;
    color: #607d8b;
    text-transform: capitalize;
  }
  .ant-menu-item a:hover,
  .ant-menu-submenu-title:hover {
    color: #66963a;
    text-decoration: none;
  }
  ant-menu-submenu-title i {
    color: #black;
  }
`;

export const ButtonStyle = styled(Button)`
  @media (min-width: 1200px) {
    display: none;
  }
  text-align: right;
  right: -40px;
  position: absolute;
  top: 30%;
  color: #00000;
  background-color: #ffff !important;
  :hover,
  focus {
    color: #5d9c14;
  }
`;

export const MenuStyled = styled.ul`
  display: flex;
  padding: 0 0 0 24px;
  height: 45px;
  flex: 1;
  list-style: none;
  overflow: hidden;
  .ant-menu-item,
  .ant-menu-submenu-title {
    padding: 0;
  }
  li {
    display: flex;
    align-items: center;
  }
  li a {
    color: #ffffffe8;
  }
  lia: hover {

  }

  .word {
    padding: 0 16px;
  }
  .active {
    background: #237804 !important;
  }

  .active a {
    color: #fff;
    font-weight: 500;
  }
  ul.ant-menu-horizontal {
    border-bottom: none;
  }
  .ant-menu {
    background: none !important;
  }
  li.ant-menu-submenu.ant-menu-submenu-horizontal {
    color: #ffffffe8;
  }
  li.ant-menu-submenu: hover {
    .ant-menu-submenu-title {
      color: #fff !important;
      font-weight: 500;
      background: #237804 !important;
    }
  }

  .ant-menu-submenu-selected {
    color: #fff !important;
    font-weight: 500;
  }
`;

export const MenuDropDownStyle = styled.div`
  background-color: ${COLOR.green};
  height: 100vh;
  width: 100%;
  transform: ${(props) =>
    props.collapsed ? "translateY(0)" : "translateY(-100%)"};
  transition: 0.6s;

  .vertical-menu {
    width: 100%;
  }

  .vertical-menu a {
    background-color: ${COLOR.green};
    color: #f9fbfa;
    display: block;
    padding: 12px;
    text-decoration: none;
    height: 50px;
    border-bottom: 0.5px solid;
  }

  .vertical-menu a:hover {
    background-color: #237804;
  }

  .vertical-menu a.active {
    background-color: #237804;
  }
`;
export const DropDownStyled = styled.a`
  position: relative;
  display: inline-block;
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 45px;
  }
  :hover .dropdown-content {
    display: block;
  }
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .dropdown-content a span {
    font-size: 14px;
  }
`;

export const NarvarStyle = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  background-color: ${COLOR.green};
`;

export const UserActionStyle = styled.div`
  height: 45px;
  padding: 8px;
  display: flex;
  justify-content: flex-end;
  background-color: #ffff;
  box-shadow: 2px 10px 5px #aaaaaa;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  transform: ${(props) =>
    props.collapsed ? "translateY(110%)" : "translateY(0)"};
  transition: 0.5s;
  .ant-avatar {
    background-color: ${COLOR.green};
    border-radius: 10%;
    margin-left: 10px;
  }
  .ant-avatar.alert-icon {
    background-color: #ffff;
    color: ${COLOR.green};
  }
`;
export const ModalUserInfor = styled.div`
  background-color: #ffff;
  position: absolute;
  top: 95px;
  right: 17px;
  height: 280px;
  width: 300px;
  border-radius: 5px;
  box-shadow: 2px 10px 10px #aaaaaa;
  .ant-avatar {
    background-color: ${COLOR.green};
    border-radius: 10%;
    margin-right: 5px;
  }

  .card-title {
    padding: 20px;
    height: 30%;
    border-bottom: 0.2px solid;
    background-image: url("${backgroundUser}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffff;
    font-size: 25px;
  }

  .card-infor {
    height: 20%;
    padding: 2px 0 0 20px;
  }

  .title-text {
    display: block;
    font-size: 10px;
    font-weight: bold;
  }
  .des-text {
    display: block;
  }

  .card-changepw {
    padding: 2px 0 0 20px;
    height: 25%;
    width: 100%;
    border-bottom: 0.2px solid;
    margin-bottom: 10px;
  }
  .card-button {
    padding: 5px 0 10px 20px;
    height: 20%;
  }
  .ant-btn {
    background-color: ${COLOR.green};
  }
`;
