import React from "react";
import _ from "lodash";
import { TableView } from "~/views/presentation/ui/tables";
import { compose, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { getAllFarmSeasonByYear } from "~/state/ducks/appApis/actions";
import { withRouter } from "react-router-dom";

class FarmSeasonTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dataSource: this.props.farmingSeason || [] };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.farmingSeason != this.state.dataSource) {
      this.setState({ dataSource: nextProps.farmingSeason || [] });
    }
  }
  render() {
    const { columns } = this.props;
    return (
      <TableView
        style={{ width: "100%" }}
        dataSource={this.state.dataSource || []}
        columns={columns}
        scroll={{ x: 1000 }}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getAllFarmSeasonByYear,
  }),
  withHandlers({}),
  lifecycle({
    componentDidMount() {
      const {} = this.props;
    },
  })
)(React.memo(FarmSeasonTables));
