import React from "react";

import _ from "lodash";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose, lifecycle, withHandlers, withState} from "recompose";
import {showError} from "~/configs/ServerErrors";
import strings from "~/localization";
import {getAllCertificateOfLands, getAllFarmCrops} from "~/state/ducks/appApis/actions";
import {TableEditable} from "~/views/presentation/ui/tables";
import {getArray, getNumber} from "~/views/utilities/helpers/utilObject";
import styled, {css} from "styled-components";
import {Form, message} from "antd";
import {getFarmProfile, getMassUnits, getType, getWards} from "~/state/ducks/appApis/actions";
import {PRODUCT_TYPE} from '~/configs/const';
export const TableStyled = styled(Form.Item)`
  width: 100%;
  display: block;

  margin-bottom: ${(props) => (props.mBottom ? props.mBottom : "10px")};
  }
`;

class CropsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {dataSource: this.props.actualHarvest || []};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.actualHarvest != this.state.dataSource && nextProps.actualHarvest !== undefined) {
      const {allocationPerTreeUniqueCurrent, setAllocationPerTreeUniqueCurrent} = this.props;
      if (allocationPerTreeUniqueCurrent.length === 0) {
        let arrCurrentTemp = [];
        getArray(nextProps, "actualHarvest", []).forEach((item) => {
          arrCurrentTemp.push(getNumber(item, "classifyId"));
        });
        setAllocationPerTreeUniqueCurrent(arrCurrentTemp);
      }
      this.setState({dataSource: nextProps.actualHarvest || []});
    }
    // update default ĐVT
    let arrUnitMass = getArray(nextProps, "unitMass", []);
    let arrActualHarvest = getArray(nextProps, "actualHarvest", []);
    if (arrUnitMass.length > 0 && arrActualHarvest.length > 0 && getNumber(_.head(arrActualHarvest), "unitId", 0) === 0) {
      let newData = arrActualHarvest.map((item) => {
        return {
          ...item,
          unitId: getNumber(_.head(arrUnitMass), "value"),
        };
      });
      this.setState({dataSource: newData});
    }
  }
  handleChangedData = (index, dataIndex, dataSource) => {
    const {onChange, setAllocationPerTreeUniqueCurrent} = this.props;
    onChange && onChange(dataSource);
    let row = dataSource[index];

    if (dataIndex === "key" || dataIndex === "classifyId") {
      let arrTmp = [];
      getArray(dataSource, undefined, []).forEach((item) => {
        arrTmp.push(getNumber(item, "classifyId"));
      });
      setAllocationPerTreeUniqueCurrent(arrTmp);
    }

    // Calculator Tổng TK
    if (dataIndex === "openingStock" || dataIndex === "input" || dataIndex === "output" || dataIndex === "defect" || dataIndex === "unitId") {
      let openingStock = getNumber(row, "openingStock", 0);
      let input = getNumber(row, "input", 0);
      let output = getNumber(row, "output", 0);
      let defect = getNumber(row, "defect", 0);
      if (dataIndex === "unitId") {
        // TODO - Need conversation factor unit MASS
      }
      if (openingStock + input <= output + defect) {
        message.warning(strings.inventory_account_entry_must_be_greater_than_out_of_account_damaged_goods, 7);
        switch (dataIndex) {
          case "input":
          case "output":
            row.output = input;
            row.defect = 0;
          case "defect":
            row.defect = row.input - row.output;
        }
      }
      row.gross = getNumber(row, "openingStock", 0) + getNumber(row, "input", 0) - getNumber(row, "output", 0) - getNumber(row, "defect", 0);
      this.setState({dataSource});
    }
  };

  render() {
    const {cropsFarm, editable, getAllFarmCrops, validatestatus, className, help, handleAddRow, allocationPerTree} = this.props;
    let columns = [
      {
        title: strings.original_code,
        dataIndex: "code",
        editable: false,
        required: false,
        width: "8%",
        placeholder: strings.original_code,
        type: "text",
      },
      {
        title: strings.picture,
        dataIndex: "images",
        width: "8%",
        editable: true,
        required: false,
        placeholder: strings.picture,
        type: "images",
        rules: [
          {
            len: 1,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.product_name,
        dataIndex: "productId",
        width: "10%",
        editable: false,
        required: true,
        placeholder: strings.product_name,
        type: "selectSuggestion",
        options: cropsFarm || [],
        isSearch: false,
        handleFetch: getAllFarmCrops,
        nameData: "labelSelect",
        filterOption: true,
        optionFilterProp: "label",
        renderOpts: {
          value: "value",
          labelSelect: "label",
          disabled: "disabled",
        },
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.unit_acronym,
        dataIndex: "unitId",
        width: "8%",
        editable: editable,
        required: true,
        placeholder: strings.unit_acronym,
        filterOption: true,
        optionFilterProp: "label",
        type: "select",
        options: this.props.unitMass || [],
      },
      {
        title: strings.classify,
        dataIndex: "classifyId",
        width: "8%",
        editable: editable,
        required: true,
        placeholder: strings.classify,
        filterOption: true,
        optionFilterProp: "label",
        type: "select",
        options: this.props.allocationPerTree || [],
        unique: true,
        optionsUnique: this.props.allocationPerTreeUniqueCurrent || [],
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.product_code,
        dataIndex: "productCode",
        editable: false,
        required: false,
        width: "8%",
        placeholder: strings.product_code,
        type: "text",
      },
      {
        title: strings.stock_at_the_beginning_of_the_period,
        dataIndex: "openingStock",
        editable: false,
        required: false,
        placeholder: strings.stock_at_the_beginning_of_the_period,
        min: 0,
        width: "8%",
        type: "decimalInput",
        suffix: "",
      },
      {
        title: strings.import_inventory,
        dataIndex: "input",
        width: "8%",
        editable: editable,
        required: true,
        placeholder: strings.import_inventory,
        min: 0,
        type: "decimalInput",
      },
      {
        title: strings.export_inventory,
        dataIndex: "output",
        editable: editable,
        required: false,
        width: "8%",
        min: 0,
        placeholder: strings.export_inventory,
        type: "decimalInput",
      },
      {
        title: strings.toal_inventory,
        dataIndex: "gross",
        editable: false,
        required: false,
        width: "8%",
        min: 0,
        placeholder: strings.toal_inventory,
        type: "decimalInput",
        suffix: "",
      },
      {
        title: strings.damaged_goods,
        dataIndex: "defect",
        editable: editable,
        required: false,
        width: "8%",
        min: 0,
        placeholder: strings.damaged_goods,
        type: "decimalInput",
      },
      {
        title: strings.note,
        dataIndex: "note",
        editable: editable,
        width: "10%",
        required: false,
        placeholder: strings.note,
        type: "text",
      },
    ];
    return (
      <TableStyled validatestatus={validatestatus} help={help} className={className}>
        {/* <p className="font-weight-bold"> {help} </p> */}
        <TableEditable
          changedData={this.handleChangedData}
          columns={columns}
          dataSource={_.sortBy(this.state.dataSource || [], [
            (o) => {
              return o.classifyId;
            },
          ])}
          editable={editable}
          handleAddRow={handleAddRow}
          maxRowCount={allocationPerTree.length || 0}
          minRowCount={1}
          scroll={{x: 1700}}
        />
      </TableStyled>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getAllFarmCrops,
    getAllCertificateOfLands,
    getFarmProfile,
    getMassUnits,
    getType,
    getWards,
  }),
  withState("dataSource", "setDataSource", []),
  withState("unitMass", "setUnitMass", []),
  withState("allocationPerTree", "setAllocationPerTree", []),
  withState("allocationPerTreeUniqueCurrent", "setAllocationPerTreeUniqueCurrent", []),
  withState("cropsFarm", "setCropsFarm", []),
  withHandlers({
    fetchUnitMass: (props) => () => {
      const {setUnitMass, getMassUnits} = props;
      getMassUnits()
        .then(({res}) => {
          setUnitMass(
            getArray(res, undefined, [])
              .map((item) => ({
                value: item.id,
                label: item.name || "",
              }))
              .filter((item) => item.value === 2 && item.label === strings.ton)
          );
        })
        .catch((err) => {
          console.error("fetchUnitMass -> err", err);
          showError(err);
        });
    },
    fetchPercentSize: (props) => () => {
      const {getType, setAllocationPerTree} = props;
      getType("PERCENT_SIZE")
        .then(({res}) => {
          const r = getArray(res, undefined, []).map((item) => ({
            value: item.id,
            label: item.name || "",
          }));
          setAllocationPerTree(r);
        })
        .catch((err) => {
          console.error("fetchPercentSize -> err", err);
          showError(err);
        });
    },
    fetchGetAllFarmCrops: (props) => () => {
      const {setCropsFarm, getAllFarmCrops} = props;
      getAllFarmCrops(PRODUCT_TYPE.PRODUCT)
        .then(({res}) => {
          setCropsFarm(
            getArray(res, undefined, []).map((item) => {
              return {
                value: item.id,
                label: item.name || "",
              };
            })
          );
        })
        .catch((err) => showError(err));
    },
  }),
  lifecycle({
    componentDidMount() {
      const {fetchUnitMass, fetchPercentSize, fetchGetAllFarmCrops} = this.props;
      fetchGetAllFarmCrops();
      fetchPercentSize();
      fetchUnitMass();
    },
  })
)(React.memo(CropsForm));
