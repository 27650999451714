import React from "react";

import {Button, Form, Tabs} from "antd";
import {withFormik} from "formik";
import _ from "lodash";
import moment from "moment";
// redux
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose, lifecycle, withHandlers, withState} from "recompose";
import * as yup from "yup";
import {showError} from "~/configs/ServerErrors";
// config || common
import strings from "~/localization";
import {getFarmProfile, getMassUnits, getType, getWards} from "~/state/ducks/appApis/actions";
// component
import {UIButton} from "~/views/presentation/ui/buttons";
import {DatePickerField, InputField, InputNumberPro, SelectField} from "~/views/presentation/ui/fields";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import {getArray, getNumber, getObject} from "~/views/utilities/helpers/utilObject";
import {numberValidate, stringNRFieldValidate, stringRequiredField} from "~/views/utilities/validation/input";

import {MinusCircleOutlined} from "@ant-design/icons";

import CropsForm from "./CropsForm";

const validationSchema = yup.object().shape({
  nameFarm: stringNRFieldValidate(),
  name: stringNRFieldValidate(),
  farmGrossYield: numberValidate,
  seedDensity: numberValidate,
  seedDensityDetail: numberValidate,
  unitId: numberValidate,
  typeId: numberValidate,
  sowingDate: stringRequiredField(strings.please_enter_the_same_date),
  farmGrossArea: numberValidate,
  unitForMass: numberValidate,
  unitForDensity: numberValidate,
  harvestDate: stringNRFieldValidate(),
});

class SeasonInfoForms extends React.PureComponent {
  /**
   * @param {*} prvDataSource - the dataSource will be updated
   */
  updateDataSource = (prvDataSource = []) => (dataSource) => {
    const {setFieldValue, setFieldTouched} = this.props;
    while (prvDataSource.length) {
      prvDataSource.shift();
    }
    let grossArea = 0;
    let grossYield = 0;
    dataSource.forEach((item) => {
      grossArea += getNumber(item, "grossArea");
      grossYield += getNumber(item, "grossYield");
      prvDataSource.push(item);
    });
    // update farmGrossArea (sum)
    if (grossArea >= 0) {
      this.props.info.farmGrossArea = grossArea;
      setFieldValue("farmGrossArea", grossArea);
      setFieldTouched("farmGrossArea");
    }
    // update farmGrossYield (sum)
    if (grossYield >= 0) {
      this.props.info.farmGrossYield = grossYield;
      setFieldValue("farmGrossYield", grossYield);
      setFieldTouched("farmGrossYield");
    }
    this.props.info.cropsList = [...dataSource];
  };

  UNSAFE_componentWillReceiveProps(nextProps) { 
    const {setFieldValue, setFieldTouched, setPercentSizeArr} = this.props;

    // set default unit
    try {
      // load again classifications to percentSizeArr
      const percentArrThisProps = getArray(this.props, "percentSizeArr", []);
      const classificationsNextProps = getArray(nextProps, "info.classifications", []);
      if (classificationsNextProps.length !== 0 && percentArrThisProps.length === 0) {
        setPercentSizeArr(classificationsNextProps);
      }
      if (classificationsNextProps.length !== 0 && percentArrThisProps.length !== 0) {
        if (classificationsNextProps[0][`typeId${0}`] && !percentArrThisProps[0][`typeId${0}`]) {
          setPercentSizeArr(() => {
            let dataTypeIds = percentArrThisProps[0][`dataOptions${0}`];
            let dataResult = [];
            for (let i = 0; i < classificationsNextProps.length; i++) {
              if (i !== 0)
                dataTypeIds = _.filter(dataTypeIds, (item) => {
                  return item.value !== classificationsNextProps[i - 1][`typeId${i - 1}`];
                });
              dataResult.push({
                ...classificationsNextProps[i],
                ["dataOptions" + i]: dataTypeIds,
              });
            }
            return dataResult;
          });
        }
      }
      if (getArray(nextProps, "profiles", []).length !== 0 && getArray(this.props, "farmProfiles", []).length === 0) {
        this.findFarmProfiles();

        const index = _.findIndex(getArray(nextProps, "profiles", []), (item) => item.id == this.props.match.params.id);
        if (index !== -1) {
          this.props.info.farmProfile = getArray(nextProps, "profiles", [])[index];
          this.props.info.nameFarm = getArray(nextProps, "profiles", [])[index].name;
          setFieldValue("nameFarm", getArray(nextProps, "profiles", [])[index].name);
          setFieldTouched("nameFarm");
        }
      }
      if (this.props.acreAge.length === 0 && nextProps.acreAge.length !== 0) {
        this.getDefaultUnit("acreAge", "unitId", nextProps);
      }
      if (this.props.unitMass.length === 0 && nextProps.unitMass.length !== 0) {
        this.getDefaultUnit("unitMass", "unitForMass", nextProps);
      }
      if (this.props.unitDensities.length === 0 && nextProps.unitDensities.length !== 0) {
        this.getDefaultUnit("unitDensities", "unitForDensity", nextProps);
      }
    } catch (err) {
      console.error("SeasonInfoForms -> UNSAFE_componentWillReceiveProps -> err", err);
      showError(err);
    }
  }

  componentWillUnmount() {
    const {percentSizeArr, setPercentSizeArr} = this.props;
    this.props.info.classifications = [...percentSizeArr];
    setPercentSizeArr({});
  }

  handleChangeNumber = (e, name) => {
    const {setFieldValue} = this.props;
    setFieldValue(name, e);
    this.props.info[name] = e;
  };

  handleChangeSelectValue = (name) => (value, label) => {
    const {setFieldValue, setFieldTouched} = this.props;
    setFieldValue(name, value);
    setFieldTouched(name);
    this.props.info[name] = value;
    this.props.info[name + "Label"] = label.children;
    // {
    //   "value": 501,
    //   "children": "Trụ"
    // }
  };

  handleChangeInputValue = (e) => {
    const {handleChange} = this.props;
    handleChange(e);
    let name = e.target.name;
    this.props.info[name] = e.target.value;
  };

  getDefaultUnit(name, nameUnit, nextProps) {
    const {setFieldValue, setFieldTouched} = this.props;
    if (getArray(nextProps, name, []).length !== 0 && this.props.info[nameUnit] === undefined) {
      this.props.info[nameUnit] = nextProps[name][0].value;
      this.props.info[nameUnit + "Label"] = nextProps[name][0].label;
      setFieldValue(nameUnit, nextProps[name][0].value);
      setFieldTouched(nameUnit);
    }
  }

  /**
   * event
   * Input: void
   * Output: void
   * Description: add % size / 01 tree, update percentSizeArr with new field
   * Author: Hoang Tan (tan.hoang@vslsoft.com)
   * Date: 9:37 Sep 10, 2020
   */
  addAllocation = (e, percentSizeArr) => {
    const {setPercentSizeArr} = this.props;
    // thêm phần tử vào percentSizeArr
    let tempArr = [...percentSizeArr];
    let idTemp = getNumber(tempArr[tempArr.length - 1], "id", 0);
    let value = percentSizeArr[idTemp][`typeId${idTemp}`];
    // filter dataOptions trước khi add
    let dataFilter = _.filter(this.props.percentSizeArr[idTemp][`dataOptions${idTemp}`], (item) => {
      return item.value !== value;
    });
    // cal percent max
    let newPercentMax = 100 - this.calSumPercent();

    // clear validate
    tempArr[idTemp]["validateValue" + idTemp] = undefined;
    tempArr[idTemp]["validateOption" + idTemp] = undefined;

    let newId = parseInt(idTemp) + 1;
    tempArr.push({
      id: newId,
      ["typeId" + newId]: undefined,
      ["value" + newId]: 0,
      ["percentMax" + newId]: newPercentMax,
      ["dataOptions" + newId]: [...dataFilter],
      ["validateOption" + newId]: strings.please_choose_a_category,
      ["validateValue" + newId]: strings.total_allocations_should_be_100_percent,
    });
    setPercentSizeArr(tempArr);
    this.props.info.classifications = tempArr;
  };

  /**
   * Output: void
   * Description: remove % size / 01 tree field
   * Author: Hoang Tan (tan.hoang@vslsoft.com)
   * Date: 10:17 Sep 10, 2020
   * @param {*} index  index of map percentSizeArr render
   * @param {*} item item of percentSizeArr
   * @param {*} event
   */
  onRemoveAllocationItem = (index, item, event, percentSizeArr) => {
    const {setPercentSizeArr} = this.props;
    let tempArr = [...percentSizeArr];
    tempArr = _.remove(tempArr, (itemArr) => {
      return itemArr.id !== item.id;
    });
    tempArr[tempArr.length - 1][`validateValue${tempArr.length - 1}`] = strings.total_allocations_should_be_100_percent;
    setPercentSizeArr(tempArr);
    this.props.info.classifications = tempArr;
  };

  /**
   * Calculator percent of Phân bổ % size / cây
   * Author: tan.hoang@vslsoft.com
   * Date: Sep 11, 2020
   */
  calSumPercent() {
    let sumPercent = 0;
    for (let i = 0; i < this.props.percentSizeArr.length; i++) {
      sumPercent += this.props.percentSizeArr[i]["value" + i];
    }
    return sumPercent;
  }

  handleChangeDatePro = (name) => (date) => {
    const {setFieldValue, setFieldTouched, setErrorsValidate} = this.props;

    if (!_.isNil(date)) {
      let sowingDate = undefined;
      let harvestDate = undefined;
      if (name === "sowingDate" && this.props.info.harvestDate !== undefined) {
        sowingDate = moment.utc(date);
        harvestDate = moment.utc(this.props.info.harvestDate);
      }
      if (name === "harvestDate") {
        sowingDate = moment.utc(this.props.info.sowingDate);
        harvestDate = moment.utc(date);
      }
      if (sowingDate && harvestDate && sowingDate.isAfter(harvestDate)) {
        setErrorsValidate({
          ...setErrorsValidate,
          harvestDate: strings.harvest_date_must_be_larger_than_sowing_date,
        });
        this.props.info.errors = {
          ...setErrorsValidate,
          harvestDate: strings.harvest_date_must_be_larger_than_sowing_date,
        };
        this.props.info.errorsValidate = {
          ...setErrorsValidate,
          harvestDate: strings.harvest_date_must_be_larger_than_sowing_date,
        };
        if (name === "sowingDate") {
          setFieldValue(name, date);
          setFieldTouched(name);
          this.props.info[name] = date;
        }
        // Lỗi thì return luôn không cho set dữ liệu ở sau
        setFieldValue("harvestDate", undefined);
        setFieldTouched("harvestDate");
        this.props.info["harvestDate"] = undefined;
        return;
      } else {
        setErrorsValidate({});
        this.props.info.errors = {};
        this.props.info.errorsValidate = {};
      }
      setFieldValue(name, date);
      setFieldTouched(name);
      this.props.info[name] = date;
    } else {
      setFieldValue(name, undefined);
      setFieldTouched(name);
      this.props.info[name] = undefined;
      setErrorsValidate({});
      this.props.info.errors = {};
      this.props.info.errorsValidate = {};
    }
  };

  // find farm into load default value in list farm profiles
  findFarmProfiles = () => {
    try {
      const {profiles, setFarmProfiles} = this.props;
      setFarmProfiles(
        getArray(profiles, undefined, []).map((item) => ({
          value: item.id,
          label: item.name || "",
        }))
      );
      this.props.info.farmProfiles = getArray(profiles, undefined, []).map((item) => ({
        value: item.id,
        label: item.name || "",
      }));
    } catch (err) {
      console.error("findFarmProfiles -> err", err);
      showError(err);
    }
  };

  render() {
    const {
      farmProfiles,
      values,
      handleBlur,
      touched,
      errors,
      setFieldValue,
      setFieldTouched,
      unitDensities,
      editable,
      percentSizeArr,
      errorsValidate,
    } = this.props;

    this.props.info.errors = errors;
    if (getObject(this.props.info, "errors", {}).sowingDate !== undefined) setFieldTouched("sowingDate");

    // alway required ngày dự tính xuống giống:
    // if (errors.sowingDate) setFieldTouched("sowingDate");

    return (
      <Form>
        <div className="row d-flex flex-row">
          <div className="col-12 col-md-6">
            <SelectField
              validatestatus={touched.nameFarm && errors.nameFarm ? "error" : undefined}
              help={touched.nameFarm && errors.nameFarm ? errors.nameFarm : ""}
              name="nameFarm"
              data={farmProfiles}
              autoFocus={true}
              disabled={true}
              value={values.nameFarm}
              onChange={this.handleChangeSelectValue("nameFarm")}
              onBlur={handleBlur}
              placeholder={strings.farm_choose}
              label={strings.farm_choose}
            />
            <DatePickerField
              disabled={!editable}
              validateStatus={touched.sowingDate && errors.sowingDate ? "error" : undefined}
              help={errorsValidate.sowingDate ? errorsValidate.sowingDate : touched.sowingDate && errors.sowingDate ? errors.sowingDate : ""}
              name="sowingDate"
              value={values.sowingDate ? moment.utc(values.sowingDate, UtilDate.formatDateTimeServer).local() : undefined}
              onChange={this.handleChangeDatePro("sowingDate")}
              onBlur={handleBlur}
              label={`${strings.down_seed_date} (*)`}
              placeholder={strings.down_seed_date}
              disabledDate={(current) => current && current < moment().endOf("day")}
            />

            {/* Chỗ này render ra thêm input
                // điều kiện cái đầu tiên có label
                // cái tiếp theo không có
                // render theo state percentSizeArr
              */}
            {percentSizeArr.map((item, index) => {
              return (
                <>
                  <div className="row" key={index}>
                    <div className="col-6 pr-2">
                      <SelectField
                        validateStatus={item[`validateOption${index}`] !== undefined ? "error" : undefined}
                        disabled={
                          !editable || index !== percentSizeArr.length - 1 // disabled các phần tử trước
                        }
                        help={item[`validateOption${index}`] !== undefined ? item[`validateOption${index}`] : undefined}
                        data={percentSizeArr[index][`dataOptions${index}`]}
                        value={percentSizeArr[index][`typeId${index}`]}
                        onChange={(value) => {
                          percentSizeArr[index][`typeId${index}`] = value;
                          setFieldValue(`typeId${index}`, value);
                          setFieldTouched(`typeId${index}`);
                          percentSizeArr[index][`validateOption${index}`] = undefined;
                          percentSizeArr[index][`validateValue${index}`] = strings.total_allocations_should_be_100_percent;
                          if (this.calSumPercent() === 100) {
                            percentSizeArr[index][`validateValue${index}`] = undefined;
                            if (percentSizeArr[index][`typeId${index}`]) percentSizeArr[index][`validateOption${index}`] = undefined;
                          } else {
                            percentSizeArr[index][`validateValue${index}`] = strings.total_allocations_should_be_100_percent;
                          }
                        }}
                        iconEnd="caret-down"
                        placeholder={strings.allocation_per_tree}
                        label={index === 0 ? `${strings.allocation_per_tree} (*)` : undefined}
                      />
                    </div>
                    <div className="col-5 pl-2">
                      <InputNumberPro
                        min={0}
                        max={index === 0 ? 100 : percentSizeArr[index][`percentMax${index}`]}
                        name={`value${index}`}
                        validatestatus={item[`validateValue${index}`] !== undefined ? "error" : undefined}
                        help={item[`validateValue${index}`] !== undefined ? item[`validateValue${index}`] : undefined}
                        disabled={
                          !editable || index !== percentSizeArr.length - 1 // disabled các phần tử trước
                        }
                        suffix={` ${strings.percent}`}
                        value={percentSizeArr[index][`value${index}`]}
                        onChange={(value) => {
                          percentSizeArr[index][`value${index}`] = value;
                          setFieldValue(`value${index}`, value);
                          setFieldTouched(`value${index}`);
                          if ((value < 100 && index === 0) || this.calSumPercent() < 100) {
                            percentSizeArr[index][`validateValue${index}`] = strings.total_allocations_should_be_100_percent;
                          } else {
                            percentSizeArr[index][`validateValue${index}`] = undefined;
                          }
                          if (percentSizeArr[index][`validateOption${index}`] === undefined && percentSizeArr[index][`typeId${index}`] === undefined)
                            percentSizeArr[index][`validateOption${index}`] = strings.please_choose_a_category;
                        }}
                        onBlur={() => {
                          this.props.info.classifications = [...percentSizeArr];
                          if (this.calSumPercent() === 100) {
                            percentSizeArr[index][`validateValue${index}`] = undefined;
                            if (percentSizeArr[index][`typeId${index}`]) percentSizeArr[index][`validateOption${index}`] = undefined;
                          } else {
                            percentSizeArr[index][`validateValue${index}`] = strings.total_allocations_should_be_100_percent;
                          }
                        }}
                        label={index === 0 ? `${strings.percent_name} (*)` : undefined}
                      />
                    </div>
                    {index === percentSizeArr.length - 1 && index !== 0 ? (
                      <div
                        className="col-1 ml-0 pl-0 d-flex flex-column cursor-pointer "
                        onClick={(e) => this.onRemoveAllocationItem(index, item, e, percentSizeArr)}
                      >
                        <MinusCircleOutlined />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {index === percentSizeArr.length - 1 &&
                  percentSizeArr.length < this.props[`typeId`].length &&
                  this.calSumPercent() < 100 &&
                  percentSizeArr[index][`typeId${index}`] &&
                  this.calSumPercent() > 0 ? (
                    <>
                      <div className="row d-flex flex-row justify-content-center align-items-center">
                        <Button type="dashed" onClick={(e) => this.addAllocation(e, percentSizeArr)}>
                          {strings.allocation_add}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </div>
          <div className="col-12 col-md-6">
            <InputField
              validatestatus={touched.name && errors.name ? "error" : undefined}
              help={touched.name && errors.name ? errors.name : ""}
              name="name"
              disabled={!editable}
              value={values.name}
              onChange={this.handleChangeInputValue}
              onBlur={handleBlur}
              placeholder={strings.farm_season_name}
              label={strings.farm_season_name}
            />
            <DatePickerField
              disabled={!editable}
              validateStatus={touched.harvestDate && errors.harvestDate ? "error" : undefined}
              help={errorsValidate.harvestDate ? errorsValidate.harvestDate : touched.harvestDate && errors.harvestDate ? errors.harvestDate : ""}
              name="harvestDate"
              value={values.harvestDate ? moment.utc(values.harvestDate, UtilDate.formatDateTimeServer).local() : undefined}
              onChange={this.handleChangeDatePro("harvestDate")}
              onBlur={handleBlur}
              label={strings.expected_harvest_date}
              placeholder={strings.expected_harvest_date}
              disabledDate={(current) => current && current < moment().endOf("day")}
            />
            <div className="row">
              <div className="col-8 pr-2">
                <InputNumberPro
                  min={0}
                  validatestatus={touched.seedDensity && errors.seedDensity ? "error" : undefined}
                  help={touched.seedDensity && errors.seedDensity ? errors.seedDensity : undefined}
                  name="seedDensity"
                  disabled={!editable}
                  // suffix={` ${strings.pillar_unit}`}
                  value={values.seedDensity}
                  onChange={(value) => {
                    this.props.info.seedDensity = value;
                    setFieldValue("seedDensity", value);
                    setFieldTouched("seedDensity");
                  }}
                  onBlur={handleBlur}
                  placeholder={"1"}
                  label={strings.number_tree_per_ha}
                />
              </div>
              <div className="col-4 pl-2">
                <SelectField
                  validateStatus={touched.unitForDensity && errors.unitForDensity ? "error" : undefined}
                  disabled={!editable}
                  help={touched.unitForDensity && errors.unitForDensity ? errors.unitForDensity : ""}
                  data={unitDensities || []}
                  value={values.unitForDensity}
                  onChange={this.handleChangeSelectValue("unitForDensity")}
                  iconEnd="caret-down"
                  placeholder={strings.unit}
                  label={strings.unit}
                />
              </div>
            </div>
            <InputNumberPro
              name="farmGrossYield"
              min={0}
              type="number"
              suffix={` ` + strings.ton}
              disabled={true}
              value={values.farmGrossYield}
              onChange={this.handleChangeInputValue}
              onBlur={handleBlur}
              placeholder={strings.expected_sum_output}
              label={strings.expected_sum_output}
            />

            <InputNumberPro
              name="farmGrossArea"
              min={0}
              disabled={true}
              type="number"
              suffix={` ` + strings.hectare}
              value={values.farmGrossArea}
              onChange={this.handleChangeInputValue}
              onBlur={handleBlur}
              placeholder={strings.total_cultivated_area}
              label={strings.total_cultivated_area}
            />
          </div>
        </div>
        <div className="mt-4">
          {/* <Tabs defaultActiveKey="1" type="card" size={"middle"}> */}
          {/* <TabPane tab={strings.crops} key="1"> */}

          <CropsForm
            help={
              getArray(this.props.info, "cropsList", []).length === 0 ? (
                <p className=""> (*) {strings.need_at_least_one_crop_for_this_season}</p>
              ) : undefined
            }
            validatestatus={getArray(this.props.info, "cropsList", []).length === 0 ? "error" : undefined}
            className={""}
            editable={editable}
            onChange={this.updateDataSource(this.props.info.cropsList)}
            cropsList={this.props.info.cropsList}
          />
          <div style={{marginTop: "16px"}}>
            <UIButton
              style={{float: "right"}}
              type="secondary"
              className="ml-2"
              htmlType="button"
              disabled={!editable}
              onClick={() => this.props.nextStep(1)}
            >
              <span>{strings.countinue}</span>
            </UIButton>
          </div>
          {/* </TabPane> */}
          {/* </Tabs> */}
        </div>
      </Form>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    getFarmProfile,
    getMassUnits,
    getType,
    getWards,
  }),
  withState("acreAge", "setAcreAge", []),
  withState("unitDensities", "setDensitiesUnit", []),
  withState("cropsFarm", "setCropsFarm", []),
  withState("farmProfiles", "setFarmProfiles", []),
  withState("percentSizeArr", "setPercentSizeArr", []),
  withState("typeId", "setAllocationPerTree", []),
  withState("unitMass", "setUnitMass", []),
  withState("errorsValidate", "setErrorsValidate", {}),
  withHandlers({
    fetchAcreage: (props) => () => {
      const {setAcreAge, getMassUnits} = props;
      getMassUnits("ACREAGE")
        .then(({res}) => {
          setAcreAge(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => {
          console.error("fetchAcreage -> err", err);
          showError(err);
        });
    },
    fetchDensityUnit: (props) => () => {
      const {setDensitiesUnit, getMassUnits} = props;
      getMassUnits("DENSITY")
        .then(({res}) => {
          setDensitiesUnit(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => {
          console.error("fetchDensityUnit -> err", err);
          showError(err);
        });
    },
    fetchUnitMass: (props) => () => {
      const {setUnitMass, getMassUnits} = props;
      getMassUnits()
        .then(({res}) => {
          setUnitMass(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => {
          console.error("fetchUnitMass -> err", err);
          showError(err);
        });
    },
    fetchPercentSize: (props) => () => {
      const {getType, setAllocationPerTree, setPercentSizeArr} = props;
      getType("PERCENT_SIZE")
        .then(({res}) => {
          props.info.percentSize = getArray(res, undefined, []).map((item) => ({
            value: item.id,
            label: item.name || "",
          }));
          setAllocationPerTree(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
          if (getArray(props.info, "classifications", []).length === 0)
            setPercentSizeArr(() => {
              let idTemp = 0;
              let tempArr = [];
              tempArr.push({
                id: idTemp,
                ["typeId" + idTemp]: undefined,
                ["value" + idTemp]: 0,
                ["percentMax" + idTemp]: 100,
                ["dataOptions" + idTemp]: getArray(res, undefined, []).map((item) => ({
                  value: item.id,
                  label: item.name || "",
                })),
                ["validateOption" + idTemp]: strings.please_choose_a_category,
                ["validateValue" + idTemp]: strings.total_allocations_should_be_100_percent,
              });

              props.info.classifications = [...tempArr];
              return tempArr;
            });
        })
        .catch((err) => {
          console.error("fetchPercentSize -> err", err);
          showError(err);
        });
    },
  }),
  withFormik({
    displayName: "InfoFarmSeason",
    enableReinitialize: true,
    validationSchema,
    mapPropsToValues: (props) => {
      return {
        avatar: props.info.avatar,
        farmGrossArea: props.info.farmGrossArea,
        farmGrossYield: props.info.farmGrossYield,
        harvestDate: props.info.harvestDate,
        name: props.info.name,
        nameFarm: props.info.nameFarm,
        seedDensity: props.info.seedDensity,
        seedDensityDetail: props.info.seedDensityDetail,
        sowingDate: props.info.sowingDate,
        unitForMass: props.info.unitForMass,
        unitForDensity: props.info.unitForDensity,
        unitId: props.info.unitId,
      };
    },
  }),
  lifecycle({
    componentDidMount() {
      const {fetchAcreage, fetchUnitMass, fetchPercentSize, fetchDensityUnit} = this.props;

      //fetch
      fetchDensityUnit();
      fetchAcreage();
      fetchUnitMass();
      fetchPercentSize();
    },
  })
)(SeasonInfoForms);
