import React from "react";
import PropTypes from "prop-types";
import {Alert} from "antd";

import styled from "styled-components";
import Color from "~/views/utilities/layout/color";
import strings from "~/localization";

const AlertStyle = styled.div`
  text-align: left;
  margin-bottom: 8px;
  .ant-alert {
    background: ${Color.white};
  }
  .ant-alert-item {
    &:hover {
      a {
        color: ${Color.red};
      }
    }
  }
  .ant-alert-item-active {
    background-color: ${Color.red};
    font-weight: bold;
    a,
    &:hover a {
      color: ${Color.white};
    }
  }
  .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${Color.red};
  }
  .info {
    // ${Color.blue}
  }
  .success {
    // ${Color.green}
  }
  .warning {
    // ${Color.yellow}
  }
  .error {
    // ${Color.red}
  }
`;
function UIRAlert(props) {
  const {message, type, closable, showIcon, description} = props;
  return (
    <AlertStyle>
      <Alert message={message} description={description} type={type} showIcon={showIcon} closable={closable} />
    </AlertStyle>
  );
}

UIRAlert.propType = {
  message: PropTypes.string,
  type: ["success", "info", "warning", "error"],
  showIcon: PropTypes.bool,
  closable: PropTypes.bool,
};

UIRAlert.defaultProps = {
  message: strings.notification_content_default,
  type: "info",
  showIcon: true,
  closable: true,
};

export default UIRAlert;
