import React from "react";
import { UIButton } from "~/views/presentation/ui/buttons";
import { compose, withHandlers } from "recompose";
import { connect } from "react-redux";
import * as yup from "yup";
import { Button } from "antd";
// import Draggable from "react-draggable";
import { ArcherContainer, ArcherElement } from "react-archer";
import { Modal } from "antd";

import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import {
  InputField,
  SelectField,
  TextAreaField,
} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import ReactResizeDetector from "react-resize-detector";

import {
  PlusCircleOutlined,
  FormOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import {
  stringNRFieldValidate,
  numberValidateMinMax,
  stringRequiredField,
} from "~/views/utilities/validation/input";
import { createCultivationStep } from "~/state/ducks/appApis/actions";
import { Form } from "antd";
import { showError } from "~/configs/ServerErrors";

import {
  getArray,
  getString,
  getBool,
  mapArrayObjectToAPI,
  getNumber,
} from "~/views/utilities/helpers/utilObject";
import Loading from "~/views/presentation/ui/loading";

import styled from "styled-components";

const WrapDragDropStyle = styled.div`
  .textBeffore {
    position: relative;
  }
  .textBeffore::before {
    left: 22px;
    padding: 5px;
    position: absolute;
    content: "${strings.drag_and_drop}";
    background-color: white;
    top: -14px;
    font-size: 16px;
    width: 96px;
    font-weight: bold;
  }
  #style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  #style-3::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  #style-3::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
`;
const DivStyled = styled.div`
  display: flex;
  padding: 24px;
  border: solid 3px #41403e;
  height: 100vh;
  overflow: auto;

}`;
const BoxStyled = styled.div`
  .ant-input {
    height: auto !important;
  }
  width: 100%;
  padding: 10px 15px;
  height: fit-content;
  margin: 10px;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  .wrap-arrow {
    width: 200px;
    height: 300px;
    box-sizing: border-box;
    margin: 60px 41px;
  }
  .FfMWu {
    align-items: center;
    display: flex;
    background-color: white;
    color: #189eff;
    min-width: unset !important;

    justify-content: center;
  }

  .active {
    transition: all 0.2s ease-in-out;
    transform: scale(1.1);
    background: #c6d0d8;
    color: #de3700;
  }
  .arrow {
    display: flex;
    overflow-wrap: break-word;
    overflow-y: hidden;
    min-height: 130px;
    height: 285px;
    text-align: center;
    width: 199px;
    border: solid 3px #5d9c14;
    position: relative;
    padding-left: 10px;
    h4 {
      text-align: center;

      display: -webkit-box;
      max-height: 50px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
      font-weight: 700;
      margin-top: 11px;
    }
    .text-over {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis
    }

    div {
      text-align: left;
    }
    div .title::after {
      content: ": ";
    }
    div .title {
      font-weight: 600;
      min-width: 55px;
    }
    div .value {
      font-weight: 500;
    }
  }
  .back {
    background: rgb(255, 138, 19);
  }
  .add-button {
    width: 23px;
    color: white;
    border: unset;
    height: 50%;
    border-radius: unset;
  }
  .add-button: hover {
    background: rgb(143 177 161);
  }
  .arrow: visited {
    cursor: move;
  }
  .arrow: hover {
    .hidden-button {
      display: block;
    }
  }
  .hidden-button {
    display: none;
    margin-left: auto;
    max-width: 23px;
  }
  .wrap-content-pro {
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
  }
`;

const validationSchema = yup.object().shape({
  label: stringRequiredField(),
  candidate: stringRequiredField(),
  decriptions: stringNRFieldValidate(),
  humanEx: stringNRFieldValidate(),
  afterProcessDate:numberValidateMinMax("",-1,100000000),
  periodTimeEx: numberValidateMinMax("",-1,100000000),
});
const CHANGE_NAME_CREATE = [
  { currName: "parent", changeName: "nextStepId" },
  { currName: "decriptions", changeName: "description" },
  // { currName: "date", changeName: "startDate" },
  { currName: "notdrag", changeName: "" },
  { currName: "start", changeName: "" },
  { currName: "new", changeName: "" },
  { currName: "label", changeName: "name" },
  //
  { currName: "date", changeName: "startHour" },
  { currName: "afterProcessDate", changeName: "afterDays" },
  { currName: "periodTimeEx", changeName: "interval" },
  { currName: "humanEx", changeName: "assignMe" },
];
const CHANGE_NAME_VIEW = [
  { currName: "nextStepId", changeName: "parent" },
  { currName: "description", changeName: "decriptions" },
  { currName: "startHour", changeName: "date" },
  { currName: "name", changeName: "label" },
  //
  // { currName: "startHour", changeName: "startDate" },
  { currName: "afterDays", changeName: "afterProcessDate" },
  { currName: "interval", changeName: "periodTimeEx" },
  { currName: "assignMe", changeName: "humanEx" },
];
const dataHour = () => {
  let hours = [];

  for (let i = 0; i < 24; i++) {
    // let labelEx="AM"
    // if(i >= 13 && i<=23)
    // labelEx="PM"
    hours.push({ value: i, label: `${i < 10 ? "0" : ""}${i}:00` });
  }
  return hours;
};

class DragAndDropProcedure extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isdAdd: false,
      startItem: null,
      dragItem: null,
      ovetItem: null,
      visible: false,
      isEdit: false,
      itemEdit: {},
      idCreate: null,
      loading: false,
      edit: false,
      dataHour: dataHour(),
    };
  }
  _handleFectchCreate = (id = null) => {
    const { createCultivationStep } = this.props;
    this.setState({ loading: true });
    return createCultivationStep({ id })
      .then(({ res }) => {
        this.setState({ loading: false });

        return res.id || null;
      })
      .catch((err) => {
        this.setState({ loading: false });

        showError(err);
      });
  };
  getNameHour = (value) => {
    return `${value < 10 ? "0" : ""}${value}:00`;
  };
  async componentDidMount() {
    const { match } = this.props;
    let processId = +match.params.idProcedure;
    if (processId) {
      this.setState({ edit: true });
      return;
    }
    const idCreate = await this._handleFectchCreate();
    let arrayTemp;
    if (idCreate)
      arrayTemp = [
        {
          id: idCreate,
          label: "Bắt Đầu",
          date: null,
          parent: -1,
          start: true,
          notdrag: true,
          descriptions: "",
        },

        {
          id: -1,
          new: true,
          parent: "",
          notdrag: true,
          descriptions: "",
        },
      ];
    else
      arrayTemp = [
        {
          id: 1,
          label: "Bắt Đầu",
          date: null,
          parent: -1,
          start: true,
          notdrag: true,
          descriptions: "",
        },

        {
          id: -1,
          new: true,
          parent: "",
          notdrag: true,
          descriptions: "",
        },
      ];
    this.setState({
      data: arrayTemp,
    });
  }
  checkArrayRevert = (data) => {
    if (!data.length) return [];
    let temptArray = [];
    let tempFind = data.find((val) => !val.parent);
    temptArray.push(tempFind);

    for (let index = 0; index < data.length - 1; index++) {
      tempFind = data.find(
        (val) => val.parent === getNumber(tempFind, "id", undefined)
      );
      temptArray.push(tempFind);
    }
    return temptArray.reverse();
  };
  async UNSAFE_componentWillReceiveProps(nextProps) {
    console.error(nextProps);
    const { edit } = this.state;
    const { values } = nextProps;

    if (edit && values.process) {
      const { process } = values;
      if (!process.length) {
        const idCreate = await this._handleFectchCreate();
        let arrayTemp;
        if (idCreate)
          arrayTemp = [
            {
              id: idCreate,
              label: "Bắt Đầu",
              date: null,
              parent: -1,
              start: true,
              notdrag: true,
              descriptions: "",
            },

            {
              id: -1,
              new: true,
              parent: "",
              notdrag: true,
              descriptions: "",
            },
          ];
        else
          arrayTemp = [
            {
              id: 1,
              label: "Bắt Đầu",
              date: null,
              parent: -1,
              start: true,
              notdrag: true,
              descriptions: "",
            },

            {
              id: -1,
              new: true,
              parent: "",
              notdrag: true,
              descriptions: "",
            },
          ];
        this.setState({
          data: arrayTemp,
          edit: false,
        });
        return;
      }
      let objectMapReponse = this.checkArrayRevert(
        mapArrayObjectToAPI(process, CHANGE_NAME_VIEW)
      );
      let tempArray = objectMapReponse;

      if (objectMapReponse.length) {
        objectMapReponse.forEach((val, index) => {
          if (index === 0) {
          
          }
          if (!getNumber(val, "parent", null)) {
            tempArray[index] = {
              ...objectMapReponse[index],
              parent: -1,
            };
          }
        });
        tempArray.unshift ({
          id: 1,
          label: "Bắt Đầu",
          start: true,
          notdrag: true,
          parent:objectMapReponse[0].id,

        });
        tempArray.push({
          id: -1,
          new: true,
          notdrag: true,
          descriptions: "",
        });
      }
      this.setState({
        data: tempArray,
        edit: false,
      });
    }
  }
  handleOk = async () => {
    const { values, setValues } = this.props;

    let { data, isEdit, itemEdit } = this.state;
    if (isEdit) {
      let arrayTemp = data;
      data.map((itemData, index) => {
        if (itemData.id === itemEdit.id) {
          arrayTemp[index] = {
            ...itemEdit,
            label: values.label,
            date: values.candidate,
            decriptions: values.decriptions,
            humanEx: values.humanEx,
            afterProcessDate: values.afterProcessDate,
            periodTimeEx: values.periodTimeEx,
          };
        }
      });
      setValues({});

      this.setState({
        data: arrayTemp,
        isEdit: false,
        itemEdit: {},
        visible: false,
      });

      let process = mapArrayObjectToAPI(arrayTemp, CHANGE_NAME_CREATE);
      if (process.length) process[process.length - 2].nextStepId = null;

      this.props.process["process"] = process.slice(0, -1);
      return;
    }
    const id = await this._handleFectchCreate();
    if (!id) {
      this.setState({ visible: false });
      return;
    }
    let dataArray = data;
    const itemLast = data[dataArray.length - 1];
    dataArray = data.filter((val) => val.id !== itemLast.id);
    data.map((item, index) => {
      if (item.parent === itemLast.id) {
        dataArray[index].parent = id;
      }
    });
    dataArray.push(
      {
        id,
        label: values.label,
        decriptions: values.decriptions,
        date: values.candidate,
        humanEx: values.humanEx,
        afterProcessDate: values.afterProcessDate,
        periodTimeEx: values.periodTimeEx,
        parent: -1,
      },
      itemLast
    );
    setValues({});
    this.setState({ data: dataArray, visible: false });

    let process = mapArrayObjectToAPI(dataArray, CHANGE_NAME_CREATE);
    if (process.length) process[process.length - 2].nextStepId = null;

    this.props.process["process"] = process.slice(0, -1);
  };

  handleCancel = (e) => {
    console.error(e);
    this.setState({
      visible: false,
    });
  };
  checkArray = (data) => {
    let temptData = data;
    // temptData = data.filter((item) => item !== undefined);
    getArray(data, undefined, []).forEach((item, index1) => {
      getArray(data, undefined, []).forEach((dataItem, index2) => {
        if (
          getNumber(item, "parent", null) === getNumber(dataItem, "id", null)
        ) {
          if (!getBool(data, "new", false)) {
            const temp = temptData[index1 + 1];
            temptData[index1 + 1] = dataItem;
            temptData[index2] = temp;
          }
        }
      });
    });

    let process = mapArrayObjectToAPI(temptData, CHANGE_NAME_CREATE);
    if (process.length) {
      process[process.length - 2].nextStepId = null;

      this.props.process["process"] = process.slice(0, -1);
    }
    return temptData;
  };
  _handleSave = () => {};
  handleChange = (event) => {
    const { handleChange, setFieldTouched } = this.props;
    setFieldTouched(event.target.name, true);
    handleChange(event);
  };
  renderForm = () => {
    const {
      values,
      touched,
      errors,
      editable,
      handleBlur,
    } = this.props;
    const { dataHour } = this.state;
    console.error("renderForm -> dataHour", dataHour);
    console.error(
      "DragAndDropProcedure -> renderForm -> values-tou",
      touched,
      errors
    );
    return (
      <div style={{ flexDirection: "column" }}>
        <Form>
          <InputField
            disabled={!editable}
            validateStatus={touched.label && errors.label ? "error" : undefined}
            help={touched.label && errors.label ? errors.label : ""}
            name="label"
            required
            onBlur={handleBlur}
            value={values.label}
            onChange={this.handleChange}
            placeholder={strings.name}
            label={strings.name}
          />

          <InputField
            disabled={!editable}
            required
            validateStatus={
              touched.afterProcessDate && errors.afterProcessDate
                ? "error"
                : undefined
            }
            help={
              touched.afterProcessDate && errors.afterProcessDate
                ? errors.afterProcessDate
                : ""
            }
            name="afterProcessDate"
            onBlur={handleBlur}
            type="number"
            pattern="[0-9]*"
            suffix="ngày"
            value={values.afterProcessDate}
            onChange={this.handleChange}
            placeholder={strings.affter_process}
            label={strings.affter_process}
          />
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <SelectField
                required
                disabled={!editable}
                validateStatus={
                  touched.candidate && errors.candidate ? "error" : undefined
                }
                help={
                  touched.candidate && errors.candidate ? errors.candidate : ""
                }
                data={dataHour || []}
                value={values.candidate}
                onChange={(value) => {
                  // this.props.process["humanEx"]=value;
                  this.props.setFieldValue("candidate", value);

                  this.props.setFieldTouched("candidate");
                }}
                iconEnd="caret-down"
                placeholder={strings.time_excute}
                label={strings.time_excute}
              />
              {/* <DatePickerField
                required
                disabled={!editable}
                validateStatus={
                  touched.candidate && errors.candidate ? "error" : undefined
                }
                help={
                  touched.candidate && errors.candidate ? errors.candidate : ""
                }
                showTime
                type="time"
                name="candidate"
                value={candidate ? candidate : undefined}
                onChange={handleChangeDate(this.props)("candidate")}
                placeholder={strings.time_excute}
                label={strings.time_excute}
              /> */}
            </div>
            <div className="col-lg-7 col-md-12">
              <InputField
                disabled={!editable}
                validateStatus={
                  touched.periodTimeEx && errors.periodTimeEx
                    ? "error"
                    : undefined
                }
                help={
                  touched.periodTimeEx && errors.periodTimeEx
                    ? errors.periodTimeEx
                    : ""
                }
                name="periodTimeEx"
                required
                onBlur={handleBlur}
                type="number"
                suffix="ngày"
                value={values.periodTimeEx}
                onChange={this.handleChange}
                placeholder={strings.period_time_excute}
                label={strings.period_time_excute}
              />
            </div>
          </div>
          <SelectField
            disabled={!editable}
            validateStatus={
              touched.humanEx && errors.humanEx ? "error" : undefined
            }
            help={touched.humanEx && errors.humanEx ? errors.humanEx : ""}
            data={[]}
            value={values.humanEx}
            onChange={(value) => {
              // this.props.process["humanEx"]=value;
              this.props.setFieldValue("humanEx", value);

              this.props.setFieldTouched("humanEx");
            }}
            iconEnd="caret-down"
            placeholder={strings.human_excute}
            label={strings.human_excute}
          />
          <TextAreaField
            disabled={!editable}
            rows={4}
            validateStatus={
              touched.decriptions && errors.decriptions ? "error" : undefined
            }
            help={
              touched.decriptions && errors.decriptions
                ? errors.decriptions
                : ""
            }
            name="decriptions"
            value={values.decriptions}
            onChange={this.handleChange}
            placeholder={strings.description}
            label={strings.description}
          />
        </Form>
      </div>
    );
  };
  onDragOver = (ovetItem) => {
    this.setState({ ovetItem });
  };

  onDragStart = (index) => {
    this.setState({ startItem: index });
  };
  onDragEnd = () => {
    let { data, startItem, ovetItem } = this.state;
    let tempItem = data;
    console.error(startItem, ovetItem);
    if (!startItem || !ovetItem) return;
    if (
      getBool(startItem, "start", false) ||
      getBool(ovetItem, "start", false)
    ) {
      this.setState({ startItem: null, ovetItem: null });
      return;
    }
    if (getNumber(ovetItem, "id", null) === getNumber(startItem, "id", null)) {
      this.setState({ startItem: null, ovetItem: null });
      return;
    }
    if (
      getNumber(startItem, "parent", null) === getNumber(ovetItem, "id", null)
    ) {
      let indexStart, indexOver;
      for (let index = 0; index < data.length; index++) {
        if (data[index].id === startItem.id) indexStart = index;
        if (data[index].id === ovetItem.id) indexOver = index;
      }
      tempItem[indexStart] = {
        ...ovetItem,
        parent: startItem.parent,
        id: startItem.id,
      };
      tempItem[indexOver] = {
        ...startItem,
        parent: ovetItem.parent,
        id: ovetItem.id,
      };
    } else {
      for (let index = 0; index < data.length; index++) {
        if (data[index].parent === startItem.id) {
          tempItem[index] = {
            ...data[index],
            parent: startItem.parent,
          };
        }
        if (data[index].parent === ovetItem.id) {
          tempItem[index] = {
            ...data[index],
            parent: startItem.id,
          };
        }
        if (data[index].id === startItem.id) {
          tempItem[index] = {
            ...data[index],
            parent: ovetItem.id,
          };
        }
      }
    }
    this.setState({ data: tempItem, startItem: null, ovetItem: null });
  };
  _handleEdit = (item) => {
    console.error("_handleEdit -> item", item);
    let { visible } = this.state;
    const { setFieldValue } = this.props;
    // humanEx: stringNRFieldValidate(),
    // afterProcessDate: numberValidate,
    // periodTimeEx: numberValidate,
    setFieldValue("label", getString(item, "label", ""));
    // setFieldValue("candidate", getString(item, "date", ""));
    setFieldValue("decriptions", getString(item, "decriptions", ""));
    setFieldValue("humanEx", getString(item, "humanEx", ""));
    setFieldValue("afterProcessDate", getNumber(item, "afterProcessDate", ""));
    setFieldValue("periodTimeEx", getNumber(item, "periodTimeEx", ""));
    setFieldValue("candidate", getNumber(item, "date", ""));

    this.setState({ visible: !visible, isEdit: true, itemEdit: item });
  };
  _handleDelete = (item) => {
    let { data } = this.state;
    let arrayTemp = data;
    if (getBool(item, "notdrag", false)) return;
    data.map((val, index) => {
      if (val.parent === item.id) {
        arrayTemp[index].parent = item.parent;
      }
    });
    arrayTemp = data.filter((val) => val.id !== item.id);
    this.setState({ data: arrayTemp });
  };
  checkWidth = (index, width) => {
    if (index > width) return false;
    if (width >= 1410 && width <= 1689) {
      if ((index + 1) % 5 === 0) return true;
      else return false;
    }
    if (width <= 1127.8125 && width > 847) {
      if ((index + 1) % 3 === 0) return true;
      else return false;
    }
    if (width > 2258) {
      if ((index + 1) % 9 === 0) return true;
      else return false;
    }
    if (width > 2252 && width <= 2258) {
      if ((index + 1) % 8 === 0) return true;
      else return false;
    }
    if (width > 1981 && width <= 2252) {
      if ((index + 1) % 7 === 0) return true;
      else return false;
    }
    if (width > 1689 && width <= 1981) {
      if ((index + 1) % 6 === 0) return true;
      else return false;
    }
    if (width <= 1410 && width >= 1127.8125) {
      if ((index + 1) % 4 === 0) return true;
      else return false;
    }
    if (width <= 565) {
      return true;
    }
    if (width <= 847 && width > 565) {
      if ((index + 1) % 2 === 0) return true;
      else return false;
    }
  };
  allowDrop = (ev) => {
    ev.preventDefault();
  };

  render() {
    const { data, ovetItem } = this.state;
    console.error("render -> data", data);
    const { editable, isValid } = this.props;
    return (
      <WrapDragDropStyle>
        <div className="textBeffore"></div>
        <DivStyled id="style-3">
          <ArcherContainer
            style={{
              width: "100%",
              position: "relative",
              height: "max-content",
            }}
            strokeColor="red"
            noCurves
          >
            <ReactResizeDetector handleWidth handleHeight>
              {({ width, height }) => (
                <BoxStyled onDragOver={(event) => this.allowDrop(event)}>
                  {console.error(width, height)}
                  {getArray(this.checkArray(data), undefined, []).map(
                    (item, index) => {
                      if (!item) return;
                      if (!item.new)
                        return (
                          <ArcherElement
                            key={item.id}
                            id={item.id}
                            relations={[
                              {
                                targetId: `${item.parent}`,
                                targetAnchor: this.checkWidth(index, width)
                                  ? "top"
                                  : "left",
                                sourceAnchor: this.checkWidth(index, width)
                                  ? "bottom"
                                  : "right",
                                style: {
                                  strokeColor: "#5d9c14",
                                  strokeWidth: 2,
                                },
                              },
                            ]}
                          >
                            <div className="wrap-arrow d-flex justify-content-center align-items-center">
                              {getBool(item.start) && (
                                <div
                                  style={{
                                    width: "200px",
                                    height: "200px",
                                    border: "2px dotted #5d9c14",
                                  }}
                                  className="d-flex justify-content-center align-items-center rounded-circle"
                                >
                                  <div style={{ fontSize: "30px" }}>
                                    {strings.START}
                                  </div>
                                </div>
                              )}
                              {!getBool(item.start) && (
                                <div
                                  key={item.id}
                                  className={`arrow ${
                                    item.id === getNumber(ovetItem, "id", "")
                                      ? "active"
                                      : null
                                  }`}
                                  draggable={
                                    editable && !getBool(item, "notdrag", false)
                                  }
                                  onDragEnd={() => this.onDragEnd(item.id)}
                                  onDragOver={(e) => this.onDragOver(item, e)}
                                  onDragStart={(e) => this.onDragStart(item, e)}
                                >
                                  <div className="wrap-content-pro">
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <h4
                                        className="value"
                                        style={{ marginTop: "20px" }}
                                      >
                                        {getString(item, "label", "")}
                                      </h4>
                                    </div>

                                        <div>
                                          <div className="title">
                                            {strings.affter_process}
                                          </div>
                                          <div className="value">
                                            {`${getNumber(
                                              item,
                                              "afterProcessDate",
                                              0
                                            )} ngày`}
                                          </div>
                                        </div>
                                        <div>
                                          <div className="title">
                                            {strings.time_excute}
                                          </div>
                                          <div className="value">
                                            {this.getNameHour(
                                              getNumber(item, "date",0)
                                            )}
                                          </div>
                                        </div>
                                      <div>
                                        <div className="title">
                                          {strings.period_time_excute}
                                        </div>
                                        <div className="value">
                                          {getNumber(item, "periodTimeEx", 0)} ngày
                                        </div>
                                      </div>
                                    {item.humanEx && (
                                      <div>
                                        <div className="title">
                                          {strings.human_excute}
                                        </div>
                                        <div className="value">
                                          {getString(item, "humanEx", "")}{" "}
                                        </div>
                                      </div>
                                    )}

                                    {item.decriptions && (
                                      <div>
                                        <div className="title">
                                          {strings.detail}
                                        </div>
                                        <div className="value text-over">
                                          {getString(item, "decriptions", "")}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {!getBool(item, "new", false) && editable && (
                                    <div className="hidden-button">
                                      <Button
                                        className="add-button back"
                                        size="small"
                                        disabled={
                                          !editable || item.start ? true : null
                                        }
                                        type="default"
                                        onClick={() => this._handleDelete(item)}
                                        icon={
                                          <DeleteOutlined
                                            style={{ fontSize: "20" }}
                                          />
                                        }
                                      />
                                      <Button
                                        className="add-button"
                                        size="small"
                                        type="primary"
                                        disabled={!editable}
                                        onClick={() => {
                                          this._handleEdit(item);
                                        }}
                                        icon={
                                          <FormOutlined
                                            style={{ fontSize: "20" }}
                                          />
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </ArcherElement>
                        );
                      else
                        return (
                          <ArcherElement key={item.id} id={item.id}>
                            <div className="wrap-arrow">
                              <div
                                key={item.id}
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                                className="arrow"
                              >
                                <UIButton
                                  disabled={!editable}
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                  size="large"
                                  type="primary"
                                  shape="circle"
                                  onClick={() => {
                                    const { setValues } = this.props;
                                    setValues({});

                                    this.setState({ visible: true });
                                  }}
                                  icon={
                                    <PlusCircleOutlined
                                      style={{ fontSize: "28px" }}
                                    />
                                  }
                                />
                              </div>
                            </div>
                          </ArcherElement>
                        );
                    }
                  )}
                </BoxStyled>
              )}
            </ReactResizeDetector>
          </ArcherContainer>
          <Modal
            title={strings.add_stage}
            visible={this.state.visible}
            okButtonProps={{ disabled: !isValid || !editable }}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            {this.renderForm()}
          </Modal>
        </DivStyled>
        {this.state.loading && <Loading />}
      </WrapDragDropStyle>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    createCultivationStep,
  }),
  withHandlers({
    handleChangeDate: (_) => (props) => (name) => (value) => {
      const { setFieldValue, setFieldTouched } = props;
      setFieldTouched(name);
      if (value) {
        console.error("value-value", value.utc().format("HH:mm"));
        setFieldValue(name, value.utc().format("HH:mm"));
      } else {
        setFieldValue(name, undefined);
      }
    },
  }),
  withFormik({
    displayName: "process",
    validationSchema: validationSchema,

    enableReinitialize: true,
    mapPropsToValues: (props) => {
      return {
        process: getArray(props, "process.process"),
      };
    },
  })
)(DragAndDropProcedure);
