import React from 'react';
import {Card, message} from 'antd';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
// import DragAndDropProcedure from "./DragAndDropProcedure.js";
import * as PATH from '~/configs/routesConfig';
import FormProductCost from './Form';
import {createPakagingCode, updatePakagingCode, getDetailPakaingCost, deletePakaingCost} from '~/state/ducks/appApis/actions';
import {deleteProcess} from '~/state/ducks/authUser/actions';
import strings from '~/localization';
import ActivityForm from '~/views/container/ActivityForm';
import Loading from '~/views/presentation/ui/loading';

import _ from 'lodash';

import {showError} from '~/configs/ServerErrors';
import {mapArrayObjectToAPI} from '~/views/utilities/helpers/utilObject';
import {StepsFormContainerStyle} from '~/views/container/styles';
import {withRouterInnerRef} from '~/views/container/commons';

const emptyObject = () => ({
  name: undefined,
  productId: undefined,
  details: undefined,
  farmingSeasonId: undefined,
  errors: undefined,
  details: [],
});
const CHANGE_NAME_CREATE = [
  {currName: 'pakaging', changeName: 'steps'},
  {currName: 'typeProcedure', changeName: 'typeId'},
];
const CHANGE_NAME_GET_DETAIL = [
  {currName: 'steps', changeName: 'pakaging'},
  {currName: 'typeId', changeName: 'typeProcedure'},
];

class UpdateProce extends React.PureComponent {
  constructor(props) {
    super(props);
    let editable = true;

    const {match} = this.props;
    switch (match.path) {
      case PATH.VIEW_FARM_PACKAGING_COST_PATH:
        {
          editable = false;
        }
        break;

      case PATH.EDIT_FARM_PACKAGING_COST_PATH:
        {
          editable = true;
        }
        break;
    }
    this.state = {
      viewAndEdit: false,
      pakaging: {},
      editable,
      procedures: {},
      loading: false,
    };
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);

    // this.onDelete = this.onDelete.bind(this);
  }
  replaceId(path) {
    const {match} = this.props;
    const id = match.params.id;
    const idSeason = match.params.idSeason;
    const idProcedure = match.params.idProcedure;
    const idPackagingCost = match.params.idPackagingCost;
    const idProductCost = match.params.idProductCost;
    return path
      .replace(':idSeason', idSeason)
      .replace(':idProcedure', idProcedure)
      .replace(':idPackagingCost', idPackagingCost)
      .replace(':id', id)
      .replace(':idProductCost', idProductCost);
  }
  onDelete() {
    const {match, deletePakaingCost, history} = this.props;
    let processId = +match.params.idPackagingCost;
    deletePakaingCost(processId)
      .then(({}) => {
        message.success(strings.delete_pakaing_cost_sucess);
        history.push(this.replaceId(PATH.ALL_FARM_PACKAGING_COST_PATH));
      })
      .catch((err) => showError(err));
  }
  onCreate() {
    const {match, createPakagingCode, history} = this.props;

    let objectApi = this.state.pakaging;
    const errorMess = this.state.pakaging.errors;
       

    if (!_.isEmpty(errorMess)) {
      message.error(Object.values(errorMess)[0]);
      return;
    }
    createPakagingCode({
      ...objectApi,
      farmingSeasonId: parseInt(match.params.idSeason),
    })
      .then(({}) => {
        message.success(strings.create_pakaging_cost_sucess);
        history.push(this.replaceId(PATH.ALL_FARM_PACKAGING_COST_PATH));
      })
      .catch((err) => showError(err));
  }
  _handleFecthProcess() {
    const {match, getDetailPakaingCost} = this.props;
    let processId = +match.params.idPackagingCost;

    if (!processId) {
      this.setState({pakaging: emptyObject(), viewAndEdit: false});
    } else {
      this.setState({loading: true});

      getDetailPakaingCost(processId)
        .then(({res}) => {
          const objecReponse = mapArrayObjectToAPI(res, CHANGE_NAME_GET_DETAIL);
          this.setState({
            pakaging: objecReponse,
            viewAndEdit: true,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({loading: false});

          showError(err);
        });
    }
  }
  componentDidMount() {
    this._handleFecthProcess();
  }
  onSave() {
    const {updatePakagingCode, history, match} = this.props;
    let objectApi = this.state.pakaging;

    updatePakagingCode({
      ...objectApi,
      farmingSeasonId: parseInt(match.params.idSeason),
      id: parseInt(match.params.idPackagingCost),
    })
      .then(({}) => {
        message.success(strings.edit_pakaing_cost_sucess);
        history.push(this.replaceId(PATH.ALL_FARM_PACKAGING_COST_PATH));
      })
      .catch((err) => showError(err));
  }
  _handleCheckRadio = (e) => {
    const {setFieldValue} = this.props;
    setFieldValue('type', e.target.value);
  };
  render() {
    const {editable} = this.state;
    return (
      <StepsFormContainerStyle>
        <div className='row' style={{width: '100%'}}>
          <div className='col-lg-9 col-md-12 profile-view'>
            <Card bordered={false}>
              <FormProductCost editable={editable} pakaging={this.state.pakaging} />
              {/* <DragAndDropProcedure
                viewAndEdit={this.state.viewAndEdit}
                editable={editable}
                pakaging={this.state.pakaging}
              /> */}
              {this.state.loading && <Loading />}
            </Card>
          </div>
          <div style={{background: 'white'}} className='col-lg-3 col-md-12 events-view'>
            <ActivityForm />
          </div>
        </div>
      </StepsFormContainerStyle>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      createPakagingCode,
      getDetailPakaingCost,
      updatePakagingCode,
      deletePakaingCost,
    },
    null,
    {
      forwardRef: true,
    }
  )(UpdateProce)
);
