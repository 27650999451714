import React from "react";

import {Form, Tabs} from "antd";
import {withFormik} from "formik";
import _ from "lodash";
import moment from "moment";
// redux
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose, lifecycle, withHandlers, withState} from "recompose";
import * as yup from "yup";
import {showError} from "~/configs/ServerErrors";
// config || common
import strings from "~/localization";
import {getFarmProfile} from "~/state/ducks/appApis/actions";
// component
import {UIButton} from "~/views/presentation/ui/buttons";
import {DatePickerField, InputField, InputNumberPro, SelectField} from "~/views/presentation/ui/fields";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import {getArray, getNumber, getObject, getString} from "~/views/utilities/helpers/utilObject";
import {numberValidate, stringNRFieldValidate, stringRequiredField} from "~/views/utilities/validation/input";

import ExpectedHarvestsForm from "./ExpectedHarvestsForm";

const emptyObjetExpectedHarvest = {
  classifyId: undefined,
  classifyName: "",
  closingStock: undefined,
  code: undefined,
  defect: undefined,
  gross: undefined,
  images: "",
  input: undefined,
  name: "",
  note: "",
  openingStock: undefined,
  output: undefined,
  productCode: "",
  productId: undefined,
  typeId: undefined,
  unitId: undefined,
  unitName: undefined,
};

const validationSchema = yup.object().shape({
  nameFarm: stringNRFieldValidate(),
  name: stringNRFieldValidate(),
  sowingDate: stringRequiredField(strings.please_enter_the_same_date),
  farmGrossArea: numberValidate,
  harvestDate: stringNRFieldValidate(),
});

class HarvestInfoForms extends React.PureComponent {
  /**
   * @param {*} prvDataSource - the dataSource will be updated
   */
  updateDataSource = (prvDataSource = []) => (dataSource) => {
    while (prvDataSource.length) {
      prvDataSource.shift();
    }
    dataSource.forEach((item) => {
      prvDataSource.push(item);
    });
  };

  handleAddRow = () => {
    try {
      return {
        productId: getNumber(this.props.info.expectedHarvest[0], "productId"),
        unitId: getNumber(this.props.info.expectedHarvest[0], "unitId"),
      };
    } catch (err) {
      console.error("HarvestInfoForms -> handleAddRow -> err", err);
      showError(err);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {setFieldValue, setFieldTouched} = this.props;
    try {
      if (getArray(nextProps, "profiles", []).length !== 0 && getArray(this.props, "farmProfiles", []).length === 0) {
        this.findFarmProfiles();

        const index = _.findIndex(getArray(nextProps, "profiles", []), (item) => item.id == this.props.match.params.id);
        if (index !== -1) {
          this.props.info.farmProfile = getArray(nextProps, "profiles", [])[index];
          this.props.info.nameFarm = getArray(nextProps, "profiles", [])[index].name;
          setFieldValue("nameFarm", getArray(nextProps, "profiles", [])[index].name);
          setFieldTouched("nameFarm");
        }
      }

      // farm season info new new
      const expectedHarvest = getArray(nextProps.info, "expectedHarvest", []);
      const classifications = getArray(nextProps.info, "classifications", []);
      if (
        expectedHarvest.length === 0 &&
        this.props.info.name === undefined &&
        classifications.length !== 0 &&
        this.props.info.sowingDate === undefined
      ) {
        let dataSourceNew = [];
        if (classifications.length > 0) {
          dataSourceNew = classifications.map((item) => {
            return {
              ...emptyObjetExpectedHarvest,
              name: getObject(nextProps.info, "product", {name: ""}).name,
              classifyId: getNumber(item, "typeId"),
              classifyName: getString(item, "typeName"),
              farmingSeasonId: getNumber(item, "farmingSeasonId", undefined),
              note: getString(item, "note", ""),
              productId: getNumber(nextProps.info, "productId"),
            };
          });
        }

        nextProps.info.expectedHarvest = [...dataSourceNew];
        this.props.info.sowingDate = nextProps.sowingDate;
        setFieldValue("sowingDate", nextProps.sowingDate);
        setFieldTouched("sowingDate");
        this.props.info.farmGrossArea = getNumber(nextProps.info.farmGrossArea, "value", 0);
        setFieldValue("farmGrossArea", getNumber(nextProps.info.farmGrossArea, "value", 0));
        setFieldTouched("farmGrossArea");
        this.props.info.harvestDate = nextProps.info.harvestDate;
        setFieldValue("harvestDate", nextProps.info.harvestDate);
        setFieldTouched("harvestDate");
        this.props.info.name = nextProps.info.name;
        setFieldValue("name", nextProps.info.name);
        setFieldTouched("name");
        // load data table
      }
    } catch (err) {
      console.error("HarvestInfoForms -> UNSAFE_componentWillReceiveProps -> err", err);
      showError(err);
    }
  }

  handleChangeNumber = (e, name) => {
    const {setFieldValue} = this.props;
    setFieldValue(name, e);
    this.props.info[name] = e;
  };

  handleChangeSelectValue = (name) => (value, label) => {
    const {setFieldValue, setFieldTouched} = this.props;
    setFieldValue(name, value);
    setFieldTouched(name);
    this.props.info[name] = value;
    this.props.info[name + "Label"] = label.children;
  };

  handleChangeInputValue = (e) => {
    const {handleChange} = this.props;
    handleChange(e);
    let name = e.target.name;
    this.props.info[name] = e.target.value;
  };

  getDefaultUnit(name, nameUnit, nextProps) {
    const {setFieldValue, setFieldTouched} = this.props;
    if (getArray(nextProps, name, []).length !== 0 && this.props.info[nameUnit] === undefined) {
      this.props.info[nameUnit] = nextProps[name][0].value;
      this.props.info[nameUnit + "Label"] = nextProps[name][0].label;
      setFieldValue(nameUnit, nextProps[name][0].value);
      setFieldTouched(nameUnit);
    }
  }

  handleChangeDatePro = (name) => (date) => {
    const {setFieldValue, setFieldTouched, setErrorsValidate} = this.props;

    if (!_.isNil(date)) {
      let sowingDate = undefined;
      let harvestDate = undefined;
      if (name === "sowingDate" && this.props.info.harvestDate !== undefined) {
        sowingDate = moment.utc(date);
        harvestDate = moment.utc(this.props.info.harvestDate);
      }
      if (name === "harvestDate") {
        sowingDate = moment.utc(this.props.info.sowingDate);
        harvestDate = moment.utc(date);
      }
      if (sowingDate && harvestDate && sowingDate.isAfter(harvestDate)) {
        setErrorsValidate({
          ...setErrorsValidate,
          harvestDate: strings.harvest_date_must_be_larger_than_sowing_date,
        });
        this.props.info.errors = {
          ...setErrorsValidate,
          harvestDate: strings.harvest_date_must_be_larger_than_sowing_date,
        };
        this.props.info.errorsValidate = {
          ...setErrorsValidate,
          harvestDate: strings.harvest_date_must_be_larger_than_sowing_date,
        };
        if (name === "sowingDate") {
          setFieldValue(name, date);
          setFieldTouched(name);
          this.props.info[name] = date;
        }
        // Lỗi thì return luôn không cho set dữ liệu ở sau
        setFieldValue("harvestDate", undefined);
        setFieldTouched("harvestDate");
        this.props.info["harvestDate"] = undefined;
        return;
      } else {
        setErrorsValidate({});
        this.props.info.errors = {};
        this.props.info.errorsValidate = {};
      }
      setFieldValue(name, date);
      setFieldTouched(name);
      this.props.info[name] = date;
    } else {
      setFieldValue(name, undefined);
      setFieldTouched(name);
      this.props.info[name] = undefined;
      setErrorsValidate({});
      this.props.info.errors = {};
      this.props.info.errorsValidate = {};
    }
  };

  // find farm into load default value in list farm profiles
  findFarmProfiles = () => {
    try {
      const {profiles, setFarmProfiles} = this.props;
      setFarmProfiles(
        getArray(profiles, undefined, []).map((item) => ({
          value: item.id,
          label: item.name || "",
        }))
      );
      this.props.info.farmProfiles = getArray(profiles, undefined, []).map((item) => ({
        value: item.id,
        label: item.name || "",
      }));
    } catch (err) {
      console.error("findFarmProfiles -> err", err);
      showError(err);
    }
  };

  render() {
    const {farmProfiles, values, handleBlur, touched, errors, setFieldTouched, editable, errorsValidate} = this.props;

    this.props.info.errors = errors;
    if (getObject(this.props.info, "errors", {}).sowingDate !== undefined) setFieldTouched("sowingDate");
    return (
      <Form>
        <div className="row col-12">
          <h3> {strings.import_products_in_stock_during_the_season} </h3>
        </div>
        <div className="row d-flex flex-row">
          <div className="col-12 col-md-6">
            <SelectField
              autoFocus={true}
              data={farmProfiles}
              disabled={true}
              help={touched.nameFarm && errors.nameFarm ? errors.nameFarm : ""}
              label={strings.farm_choose}
              name="nameFarm"
              onBlur={handleBlur}
              onChange={this.handleChangeSelectValue("nameFarm")}
              placeholder={strings.farm_choose}
              validatestatus={touched.nameFarm && errors.nameFarm ? "error" : undefined}
              value={values.nameFarm}
            />
            <DatePickerField
              disabled={true}
              disabledDate={(current) => current && current < moment().endOf("day")}
              help={errorsValidate.sowingDate ? errorsValidate.sowingDate : touched.sowingDate && errors.sowingDate ? errors.sowingDate : ""}
              label={strings.down_seed_date}
              name="sowingDate"
              onBlur={handleBlur}
              onChange={this.handleChangeDatePro("sowingDate")}
              placeholder={strings.down_seed_date}
              validateStatus={touched.sowingDate && errors.sowingDate ? "error" : undefined}
              value={values.sowingDate ? moment.utc(values.sowingDate, UtilDate.formatDateTimeServer).local() : undefined}
            />
            <InputNumberPro
              disabled={true}
              label={strings.acreage_farming}
              min={0}
              name="farmGrossArea"
              onBlur={handleBlur}
              onChange={this.handleChangeInputValue}
              placeholder={strings.acreage_farming}
              suffix={` ` + strings.hectare}
              type="number"
              value={values.farmGrossArea}
            />
          </div>
          <div className="col-12 col-md-6">
            <InputField
              disabled={true}
              help={touched.name && errors.name ? errors.name : ""}
              label={strings.farm_season_name}
              name="name"
              onBlur={handleBlur}
              onChange={this.handleChangeInputValue}
              placeholder={strings.farm_season_name}
              validatestatus={touched.name && errors.name ? "error" : undefined}
              value={values.name}
            />
            <DatePickerField
              disabled={true}
              disabledDate={(current) => current && current < moment().endOf("day")}
              help={errorsValidate.harvestDate ? errorsValidate.harvestDate : touched.harvestDate && errors.harvestDate ? errors.harvestDate : ""}
              label={strings.harvest_date}
              name="harvestDate"
              onBlur={handleBlur}
              onChange={this.handleChangeDatePro("harvestDate")}
              placeholder={strings.harvest_date}
              validateStatus={touched.harvestDate && errors.harvestDate ? "error" : undefined}
              value={values.harvestDate ? moment.utc(values.harvestDate, UtilDate.formatDateTimeServer).local() : undefined}
            />
          </div>
        </div>
        <div className="mt-4">
          <ExpectedHarvestsForm
            className={""}
            expectedHarvest={this.props.info.expectedHarvest}
            handleAddRow={this.handleAddRow}
            editable={editable}
            // help={<p className="text-primary"> 🛈 {strings.invalid_lines_will_be_automatically_deleted}</p>}
            onChange={this.updateDataSource(this.props.info.expectedHarvest)}
            validatestatus={getArray(this.props.info, "expectedHarvest", []).length === 0 ? "error" : undefined}
          />
          <div style={{marginTop: "16px"}}>
            <UIButton
              className="ml-2"
              disabled={!editable}
              htmlType="button"
              onClick={() => {
                this.props.onSave && this.props.onSave();
                this.props.onCreate && this.props.onCreate();
              }}
              style={{float: "right"}}
              type="secondary"
            >
              <span>{strings.save}</span>
            </UIButton>
          </div>
        </div>
      </Form>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    getFarmProfile,
  }),
  withState("farmProfiles", "setFarmProfiles", []),
  withState("errorsValidate", "setErrorsValidate", {}),
  withHandlers({}),
  withFormik({
    displayName: "InfoFarmSeason",
    enableReinitialize: true,
    validationSchema,
    mapPropsToValues: (props) => {
      return {
        farmGrossArea: props.info.farmGrossArea,
        harvestDate: props.info.harvestDate,
        name: props.info.name || undefined,
        nameFarm: props.info.nameFarm,
        sowingDate: props.info.sowingDate,
      };
    },
  }),
  lifecycle({
    componentDidMount() {},
  })
)(HarvestInfoForms);
