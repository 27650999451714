import React from "react";
import { Menu } from "antd";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { DivStyled, ButtonStyle } from "./styles";

const MenuSideBar = (props) => {
  const { history } = props;
  return (
    <DivStyled showLeftMenu={props.showLeftMenu} className="menu">
      <div style={{ float: "right" }}>
        <ButtonStyle
          type="text"
          onClick={(e) => {
            props.handleHiddenMenu &&
              props.handleHiddenMenu(!props.showLeftMenu);
          }}>
          {props.showLeftMenu ? (
            <DoubleLeftOutlined />
          ) : (
            <DoubleRightOutlined />
          )}
        </ButtonStyle>
      </div>
      <Menu
        defaultSelectedKeys={[props.match.url]}
        mode={"inline"}
        theme={"light"}>
        {props.menus.length &&
          props.menus.map((item, index) => (
            <Menu.Item key={item.path} onClick={() => history.push(item.path)}>
              {item.name}
            </Menu.Item>
          ))}
      </Menu>
    </DivStyled>
  );
};
export default compose(withRouter)(MenuSideBar);
