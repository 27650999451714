import React, { useState, useEffect } from "react";
import { map, compact, last, uniq } from "lodash";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import enhance from "~/views/presentation/ui/navigation/withMenuEnhancer";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";
import { getString } from "~/views/utilities/helpers/utilObject";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import COLOR from "~/views/utilities/layout/color";
import { CheckWidth } from "~/views/utilities/CheckWidth";
import { DivStyled, ButtonStyle } from "./styles";

const WrapTreeMenu = styled.div`
  position: relative;
  .sidebar-main {
    @media (max-width: 600px) {
      top: 90px;
    }
    transform: ${(props) =>
      props.showLeftMenu ? "translateX(0)" : "translateX(-90%)"};
    transition: 1s;
  }
  button {
    @media (min-width: 750px) {
      display: none;
    }
    background-color: #ffff;
    border: none;
    position: absolute;
    right: 0;
    width: 40px;
    height: 34px;
    top: 30%;
    :hover {
      color: ${COLOR.green};
    }
  }

  .ant-menu {
    width: 90%;
  }
`;

function renderMenuItem(props) {
  const { keyOption, icon, name, path, subMenus, disbled } = props;

  let { match } = props;
  const id = match.params.id;
  const idSeason = match.params.idSeason;
  const idProcedure = match.params.idProcedure;
  if (subMenus) {
    return (
      <Menu.SubMenu key={keyOption} title={name}>
        {map(subMenus, (item, keyOption) =>
          renderMenuItem({ ...item, keyOption, match })
        )}
      </Menu.SubMenu>
    );
  }

  return (
    <Menu.Item key={keyOption} disabled={disbled}>
      {!disbled ? (
        <a
          href={path
            .replace(":idSeason", idSeason)
            .replace(":idProcedure", idProcedure)
            .replace(":id", id)}>
          {icon} {name}
        </a>
      ) : (
        <div className="not-disable">
          {icon} {name}
        </div>
      )}
    </Menu.Item>
  );
}
var checkLobal;
var checkOpenString;
var checkOpen = [];
function Sidebar({
  menus,
  openKeys,
  isCollapsed,
  handleClick,
  location,
  theme,
  setOpenKeys,
  match,
}) {
  const [showLeftMenu, setShowLeftMenu] = useState(true);
  const divice = CheckWidth();
  useEffect(() => {
    setShowLeftMenu(divice.innerWidth >= 750);
  }, [divice.innerWidth]);
  const mapDefaul = (menus) => {
    return map(menus, (val, index) => {
      if (val.subMenus) {
        mapDefaul(val.subMenus);
        return index;
      }
    });
  };
  const recurFindKey = (menus, match, indexParent) => {
    if (checkLobal) return;
    map(menus, (menu, index) => {
      if (checkLobal) return false;
      if (menu.subMenus) {
        recurFindKey(menu.subMenus, match, index);
      }

      if (
        menu.path ===
        match.path.replace(":type", getString(match.params, "type", ""))
      ) {
        checkOpenString = indexParent;
        checkLobal = index;
      }
    });
  };
  const checkSelectedKeys = (menus, location, match) => {
    checkLobal = "";
    checkOpenString = "";
    recurFindKey(menus, match);

    // setOpenKeys([...openKeys,checkOpen])

    return [checkLobal];
  };

  const _handleOpenChange = (openKeys, menus, location, match, setOpenKeys) => {
    let check = [];
    checkOpenString = "";
    map(menus, (menu, index) => {
      if (menu.defaultExpand) check.push(index);
    });
    checkOpen = uniq([...check, last(openKeys), checkOpenString]);
    setOpenKeys(checkOpen);
  };
  return (
    <DivStyled showLeftMenu={showLeftMenu} className="menu">
      <PerfectScrollbar>
        <div style={{ float: "right" }}>
          <ButtonStyle
            type="text"
            onClick={(e) => {
              setShowLeftMenu(!showLeftMenu);
            }}>
            {showLeftMenu ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
          </ButtonStyle>
        </div>
        <Menu
          mode="inline"
          theme={"light"}
          openKeys={uniq(compact([...openKeys, checkOpenString]))}
          onOpenChange={(data) =>
            _handleOpenChange(data, menus, location, match, setOpenKeys)
          }
          defaultSelectedKeys={checkSelectedKeys(
            menus,
            location,
            match,
            setOpenKeys,
            openKeys
          )}
          inlineCollapsed={isCollapsed}
          onClick={handleClick}>
          {map(menus, (item, keyOption) =>
            renderMenuItem({ ...item, keyOption, match })
          )}
        </Menu>
      </PerfectScrollbar>
    </DivStyled>
  );
}

export default enhance(Sidebar);
