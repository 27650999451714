import * as types from "./types";
import produce from "immer";
import * as _ from "lodash";

const initialState = {
  loaded: 0,
  cartitems: [
    { id: 1, salePrice: 15999, manufacturer: { id: 1 }, quantity: 1 },
    { id: 3, salePrice: 4000, manufacturer: { id: 1 }, quantity: 1 }
  ]
};

const success = "_SUCCESS";
const failed = "_FAILED";

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    default:
      return draft;
  }
}, initialState);

export default reducer;
