import React from 'react';

import {Button} from 'antd';
import _ from 'lodash';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose, lifecycle, withHandlers, withState} from 'recompose';
import {FARM_DETAIL_PATH, VIEW_SEASON_CRUD_FARM_PATH} from '~/configs/routesConfig';
import {showError} from '~/configs/ServerErrors';
import strings from '~/localization';
import {getAllFarmSeasonByYear, getFarmSeasonExpectedHarvest} from '~/state/ducks/appApis/actions';
import {getArray, getNumber, getString} from '~/views/utilities/helpers/utilObject';


import FarmSeasonDetailsTable from './FarmSeasonDetailsTable';
import {PicturesWall} from '~/views/container/components';
import Modal from 'antd/lib/modal/Modal';

class FarmSeasons extends React.Component {
  /**
   * Description: Link to farm season detail
   * @param {*} e event
   * @param {*} record contain id
   */
  onClickFarmSeason = (e, record) => {
    // {id: 76001, ...record}
    const {history, match} = this.props;
    history.push(VIEW_SEASON_CRUD_FARM_PATH.replace(':idSeason', getNumber(record, 'farmingSeasonId')).replace(':id', match.params.id));
  };
  onClickFarm = () => {
    // {id: 76001, ...record}
    const {history, match} = this.props;
    history.push(FARM_DETAIL_PATH.replace(':id', match.params.id));
  };

  render() {
    const columns = [
      {
        dataIndex: 'nameFarm',
        key: 'nameFarm',
        align: "left",
        title: strings.farm,
        render: (text, record) => {
          return (
            <Button className='p-0 m-0' type='link' onClick={(e) => this.onClickFarm(e, record)}>
              {nameFarm}
            </Button>
          );
        },
      },
      {
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.name.length - b.name.length,
        title: strings.farm_season_name,
      },
      {
        title: strings.original_code,
        dataIndex: 'code',
        align: 'center',
      },
      {
        title: strings.picture,
        dataIndex: 'images',
        align: 'center',
        render: (images) => {
          return (
            <i
              className='fa fa-edit'
              style={{fontSize: '20px', cursor: 'pointer'}}
              onClick={() => {
                const {setVisible, setCurrentImages} = this.props;
                setVisible(true);
                setCurrentImages(images);
              }}
            />
          );
        },
      },
      {
        title: strings.product_name,
        dataIndex: 'productName',
        align: 'center',
      },
      {
        title: strings.unit_acronym,
        dataIndex: 'unitName',
        align: 'center',
        type: 'select',
      },
      {
        title: strings.classify,
        dataIndex: 'classifyName',
        align: 'center',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.classifyName.length - b.classifyName.length,
      },
      {
        title: strings.product_code,
        dataIndex: 'productCode',
        align: 'right',
      },
      {
        title: strings.stock_at_the_beginning_of_the_period,
        dataIndex: 'openingStock',
        align: 'right',
      },
      {
        title: strings.import_inventory,
        dataIndex: 'input',
        align: 'right',
      },
      {
        title: strings.export_inventory,
        dataIndex: 'output',
        align: 'right',
      },
      {
        title: strings.toal_inventory,
        dataIndex: 'gross',
        align: 'right',
      },
      {
        title: strings.damaged_goods,
        dataIndex: 'defect',
        align: 'right',
      },
      {
        title: strings.note,
        dataIndex: 'note',
        align: 'left',
      },
    ];

    const {harvestSeason, editable, nameFarm, setVisible, currentImages, visible} = this.props;
    return (
      <>
        <div className='shadow'>
          <FarmSeasonDetailsTable editable={editable} nameFarm={nameFarm} harvestSeason={harvestSeason} columns={columns} />
        </div>
        <Modal
          title={strings.picture}
          visible={visible}
          onOk={() => {
            setVisible(false);
          }}
          onCancel={() => {
            setVisible(false);
          }}
          footer={[
            <Button
              key='submit'
              onClick={() => {
                setVisible(false);
              }}>
              {strings.close}
            </Button>,
          ]}>
          <div className='clear-fix'>
            <PicturesWall images={currentImages} />
          </div>
        </Modal>
      </>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getAllFarmSeasonByYear,
    getFarmSeasonExpectedHarvest,
  }),
  withState('farmingSeason', 'setFarmingSeason', []),
  withState('harvestSeason', 'setHarvestSeason', []),
  withState('visible', 'setVisible', false),
  withState('currentImages', 'setCurrentImages', ''),
  withHandlers({
    fetchGetAllFarmingSeasons: (props) => () => {
      const {setFarmingSeason, getAllFarmSeasonByYear, match} = props;
      getAllFarmSeasonByYear(match.params.id)
        .then(({res}) => {
          setFarmingSeason(
            getArray(res, undefined, []).map((row) => {
              return {
                year: row.year,
                details: getArray(row, 'details', []).map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    sowingDate: item.sowingDate,
                    grossArea: getNumber(item, 'grossArea'),
                    productName: item.productName,
                    harvestDate: item.harvestDate,
                    grossYield: getNumber(item, 'grossYield'),
                  };
                }),
              };
            })
          );
        })
        .catch((err) => {
          console.error('fetchGetAllFarmingSeasons -> err', err);
          showError(err);
        });
    },
    fetchFarmSeasonHarvest: (props) => () => {
      const {setHarvestSeason, getFarmSeasonExpectedHarvest, match} = props;
      const idSeason = match.params.idSeason;
      getFarmSeasonExpectedHarvest(idSeason)
        .then(async ({res}) => {
          setHarvestSeason(
            getArray(res, 'expectedHarvest', []).map((item) => {
              return {
                ...item,
                name: getString(res, 'name', ''),
                productName: getString(res, 'product.name', ''),
              };
            })
          );
        })
        .catch((err) => {
          console.error('fetchFarmSeasonHarvest -> err', err);
          return showError(err);
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      // const {fetchGetAllFarmingSeasons} = this.props;
      // fetchGetAllFarmingSeasons();
      const {fetchFarmSeasonHarvest} = this.props;
      fetchFarmSeasonHarvest();
    },
  })
)(FarmSeasons);
