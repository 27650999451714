import styled from "styled-components";

export const GridStyled = styled.div`
.ant-empty{
  margin: auto;
  margin-top: 100px;
}
.color-text-status{
  font-weight: 500;
  color: #8080807d;
  font-style: italic;
}
div{
  min-height: 20px;

}
  width: 100%;
  .right h4 {
    min-height: 20px;
    max-height: 50px;
    font-weight: 550;


    white-space: nowrap;
    width: 364px;
    overflow: hidden;
    text-overflow: ellipsis; 

  }
  .right .des {
    display: -webkit-box;
    max-height: 50px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
  display: flex;
  flex-wrap: wrap;
  padding: 0px 9px !important;
  .item {
    width: calc(100% / 4 - 10px);
    @media (max-width: 1300px) {
      width: calc(100% / 3 - 10px);
    }
    @media (max-width: 800px) {
      width: calc(100% - 10px);
    }
    margin: 0px 5px 10px 5px;
  }

  div .item-container {
    padding: 15px;
    cursor: pointer;
    min-height: 193px;
    background-color: #fff;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    .left {
      .ant-avatar {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        border: 1px solid #c3c3c3;
        margin: 10px 15px 5px 15px;
        overflow: hidden;
      }
      p {
        text-align: center;
      }
    }
    .right {
      flex: 1;
      div .title::after {
        content: ": ";
      }
      div .title {
        color: #000000a8;
        margin-right: 5px;
        min-width: 100px;
      }
      div .value {
        font-weight: 500;
        color: black;
        font-size: 14px;
      }
      div p {
        margin-bottom: 0px;
      }
      div {
        margin-bottom: 10px;
        display: flex;
      }
    }
    button {
      position: absolute;
      right: 15px;
      top: 0px;
      height: 40px;
      width: 40px;
      i {
        font-size: 20px;
      }
    }
    button:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;
