import React from "react";
import { Navbar, Sidebar } from "../navigation";
import _ from "lodash";
import { MenuSize } from "../navigation/Navbar";
import "jquery-slimscroll/jquery.slimscroll.min";
import styled from "styled-components";
import strings from "~/localization";
import { menuHeader } from "~/configs/menus";

const WrapperStyled = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  h2 {
    font-size: 24px;
    color: #595959;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    margin-top: 16px;
  }
  h3 {
    font-size: 20px;
    color: #595959;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    margin-top: 10px;
  }
  h4 {
    font-size: 16px;
    color: #595959;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    margin-top: 10px;
  }
  .line-pro {
    border: 0;
    height: 1px;
    width: 100%;
    background-image: -webkit-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -moz-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -ms-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -o-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  }
`;

export default class AppContentWrapper extends React.PureComponent {
  state = {
    isCollapsed: true,
    isToggled: false,
    menuSize: MenuSize.small,
    pageWidth: 0,

    collapsed: false,
  };

  componentDidMount() {
    this.setState({
      pageWidth: window.innerHeight,
      menuSize:
        window.innerHeight < 600
          ? MenuSize.small
          : window.innerHeight < 800
          ? MenuSize.medium
          : MenuSize.large,
    });
    window.addEventListener(
      "resize",
      () => {
        if (window.innerWidth < 600) {
          this.setState({ menuSize: MenuSize.small });
        } else if (window.innerWidth < 800) {
          this.setState({ menuSize: MenuSize.medium });
        } else {
          this.setState({ menuSize: MenuSize.large });
        }
        this.setState({ pageWidth: window.innerHeight });
      },
      false
    );
  }

  getMenuSize = (size) => {
    switch (size) {
      case MenuSize.small:
        {
          return "appWrapper scheme-default default-scheme-color header-fixed aside-fixed rightbar-hidden device-lg sidebar-xs";
        }
        break;
      case MenuSize.medium:
        {
          return "appWrapper scheme-default default-scheme-color header-fixed aside-fixed rightbar-hidden device-sm sidebar-sm";
        }
        break;
      default:
        return "appWrapper";
    }
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    const { children, showMainSideBar } = this.props;

    return (
      <WrapperStyled
        id="minovate"
        className={this.getMenuSize(this.state.menuSize)}>
        <div
          id="wrap"
          className="animsition"
          style={{ animationDuration: "1.5s", opacity: 1 }}>
          <section
            id="header"
            style={{ position: "fixed", width: "100%" }}
            className="scheme-default">
            <Navbar
              topNav={{
                isMenu: true,
                pageName: strings.header_logo_name,
                slot: menuHeader,
              }}
              collapsed={this.state.collapsed}
              onCollapse={this.onCollapse}
              size={this.state.menuSize}
              onResize={(size) => this.setState({ menuSize: size })}
            />
          </section>
          {/* ================================================
                ================= SIDEBAR Content ===================
                ================================================= */}
          {/* {showMainSideBar === true && (
            <div id="controls">
              <aside
                id="sidebar"
                className="scheme-default aside-fixed dropdown-open">
                <div id="sidebar-wrap">
                  <div className="slimScrollDiv">
                    <div className="panel-group slim-scroll" role="tablist">
                      <div className="panel panel-default">
                        <div className="panel-heading" role="tab">
                          <h4 className="panel-title">
                            <a data-toggle="collapse" href="#sidebarNav">
                              Navigation <i className="fa fa-angle-up" />
                            </a>
                          </h4>
                        </div>
                        <div
                          id="sidebarNav"
                          className="panel-collapse collapse in hide-scrollbar"
                          role="tabpanel"
                          style={{
                            height: this.state.pageWidth - 45,
                            overflowY: "scroll",
                          }}>
                          <div className="panel-body">
                            <Sidebar showMainSideBar={showMainSideBar} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          )} */}
          {/* ====================================================
            ================= CONTENT ===============================
            ===================================================== */}
          <section
            style={{ width: "100%" }}
            id={showMainSideBar === true ? "content" : "non-left-content"}>
            {/* map to layout component ant design */}
            {children}
          </section>
        </div>
      </WrapperStyled>
    );
  }
}
