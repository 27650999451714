import { compose, withHandlers, lifecycle, withState } from "recompose";
import { connect } from "react-redux";
import * as yup from "yup";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import {
  getUnitMaterial,
  getAllFarmCrops,
  getType,
} from "~/state/ducks/appApis/actions";
import _ from "lodash";
import {
  stringNRFieldValidate,
  numberValidate,
} from "~/views/utilities/validation/input";

import { showError } from "~/configs/ServerErrors";
import {
  getArray,
  getString,
  getNumber,
} from "~/views/utilities/helpers/utilObject";
import {PRODUCT_TYPE} from '~/configs/const';

const validationSchema = yup.object().shape({
  name: stringNRFieldValidate(),
});
export default compose(
  withRouter,
  connect(null, { getUnitMaterial, getAllFarmCrops,getType }),
  withState("typeProcess", "setProcess", []),
  withState("loadingTypeProcess", "setLoadingType", false),
  withState("dataSource", "setDataSource", []),
  withState("product", "setProduct", []),
  withState("expalin", "setExpalin", []),
  withState("unitData", "setUnit", []),
  withState("packer", "setPacker", []),

  withHandlers({

    fetchAllProduct: (props) => () => {
      const { getAllFarmCrops, setProduct,setExpalin } = props;
      getAllFarmCrops(PRODUCT_TYPE.PRODUCT)
        .then(({ res }) => {
          setProduct(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
   
        })
        .catch((err) => {
          showError(err);
        });
    },

    fetchType: (props) => () => {
      const { getType,setExpalin,setPacker } = props;
      getType("PACKAGING_DETAIL")
        .then(({ res }) => {
    
          setExpalin(
            getArray(res, undefined, []).map((item) => ({
              value: item.name,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => {
          showError(err);
        });
      getType("PACKER")
        .then(({ res }) => {
    
          setPacker(
            getArray(res, undefined, []).map((item) => ({
              value: item.name,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => {
          showError(err);
        });
    },
    fecthAllUnit: (props) => () => {
      const { getUnitMaterial, setUnit } = props;
      getUnitMaterial("MASS")
        .then(({ res }) => {
          setUnit(
            getArray(res, undefined, []).map((item) => ({
              value:item.name,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => {
          showError(err);
        });
    },
  }),
  withFormik({
    displayName: "process",
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      return {
        name: getString(props, "pakaging.name"),
        productId: getNumber(props, "pakaging.productId"),
      };
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchAllProduct,fetchType, fecthAllUnit } = this.props;
      fetchAllProduct();
      fecthAllUnit();
      fetchType();
    },
  })
);
