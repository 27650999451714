import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {UIButton} from "~/views/presentation/ui/buttons";
import strings from "~/localization";
import DoneForm from "./DoneForm";
import {CREATE_SEASON_CRUD_FARM_PATH} from "~/configs/routesConfig";
import {getArray, getString, getNumber} from "~/views/utilities/helpers/utilObject";
import styled from "styled-components";
import {Avatar} from "~/views/presentation/ui/fields";
import {numberFormatWithPoint, numberFormatInt} from "~/views/utilities/helpers/currency";
import {withFormik} from "formik";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import _ from "lodash";
import moment from "moment";
import {IMAGE_URL} from "~/configs";
import {compose, lifecycle, withHandlers, withState} from "recompose";
import {showError} from "~/configs/ServerErrors";
import {getAllFarmCrops, getType} from "~/state/ducks/appApis/actions";
import {PRODUCT_TYPE} from '~/configs/const';

// redux

const ContainerStyled = styled.div`
   display: flex;
   flex-direction: row;
   .none-menu {
      left: 0px !important;
   }
   .info-content{
      background-color: #fafafa
      border-radius: 10px 10px 10px 10px;
      -moz-border-radius: 10px 10px 10px 10px;
      -webkit-border-radius: 10px 10px 10px 10px;
      border: 3px dashed #BBBBBB;
   }
   .info__info-content{
      font-size: 15px;
      letter-spacing: 0px;
      color: #7D7D7D;
      font-weight: 700;
      text-decoration: none;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
   }
`;

class PageDone extends React.PureComponent {
  constructor(props) {
    super(props);
    const {match} = this.props;
    let savePageDone = true;

    switch (match.path) {
      case CREATE_SEASON_CRUD_FARM_PATH:
        savePageDone = false;
        break;
    }
    this.state = {
      savePageDone,
    };
  }
  UNSAFE_componentWillMount() {
    window.scroll({top: 0, left: 0, behavior: "smooth"});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.cropsFarm.length > 0 &&
      nextProps.percentSizeArr.length > 0 &&
      this.props.dataViewSource.length === 0
    ) {
      this.fetchGetAllDone();
    }
  }

  fetchGetAllDone = () => {
    const {info, cropsFarm, setDataSourceViewSource, percentSizeArr, setExpand} = this.props;
    let result = [];
    let sumGrossYield = parseFloat(getNumber(info, "farmGrossYield", 0));
    let sumGrossArea = parseFloat(getNumber(info, "farmGrossArea", 0));

    try {
      result = getArray(info, "cropsList", []).map((item1, index1) => {
        let product = _.find(cropsFarm, (o) => {
          return o.value === item1.productId;
        });
        return {
          key: index1,
          productId: getString(product, "label", ""),
          grossArea: sumGrossArea + ` ` + strings.hectare,
          type: "",
          grossYield: sumGrossYield + ` ` + strings.ton,
          unit: strings.ton,
          note: item1.note,
          children: getArray(info, "classifications", []).map((item2, index) => {
            let percentSize = _.find(percentSizeArr, (o) => {
              return o.value === item2[`typeId${index}`];
            });
            return {
              key: index1 + "" + index + "",
              productId: "",
              grossArea: "",
              type: getString(percentSize, "label", ""),
              grossYield: (sumGrossYield * item2[`value${index}`]) / 100,
              unit: strings.ton,
              note: item2.note || "-",
            };
          }),
        };
      });
      this.props.info.dataViewSource = [...result];
      setDataSourceViewSource(result);
      setExpand(true);
    } catch (err) {
      console.error("DoneForm -> fetchGetAllDone -> err", err);
      showError(err);
    }
  };

  /**
   * Cal sum cost expected
   * Author: tan.hoang@vslsoft.com
   * Date: 18:13 Sep 14, 2020
   */
  calSumCost() {
    const {info} = this.props;

    let sumMaterial = 0;
    let sumLaborCost = 0;
    let sumOtherCost = 0;
    sumMaterial = _.sumBy(getArray(info.materials), (o) => {
      return o.amount;
    });
    sumLaborCost = _.sumBy(getArray(info.laborCosts), (o) => {
      return o.salaryPerSeason;
    });
    sumOtherCost = _.sumBy(getArray(info.otherCosts), (o) => {
      return o.amount;
    });
    // * 1000 000 because unit sumLabor is "triệu đồng", sumOtherCost and sumMaterial is "đ"
    return parseFloat(sumMaterial) + parseFloat(sumLaborCost) * 1000000 + parseFloat(sumOtherCost);
  }

  render() {
    const {info, dataViewSource, expand} = this.props;
    const harvestDate = moment.utc(info.harvestDate, UtilDate.formatDateTimeServer);

    return (
      <ContainerStyled>
        <div style={{width: "100%"}}>
          <div className="d-flex justify-content-between align-items-baseline flex-row mr-10">
            <h2> {strings.forecast_production_season_results}</h2>
            {/* <PrinterOutlined size="large" style={{fontSize: "35px", cursor: "pointer"}} /> */}
          </div>
          <div className="info-content col-12 d-flex flex-column justify-content-center align-items-center mb-20 ml-0 mr-0 pl-0 pr-0">
            <div className="col-12 d-flex flex-row justify-content-between align-items-center mt-10">
              <div className="d-flex flex-column col-10">
                <h3>{info.nameFarm}</h3>
                <h4>{info.name}</h4>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6 title__info-content">
                        {strings.total_cultivated_area}:
                      </div>
                      <div className="col-6 info__info-content">
                        {numberFormatWithPoint(parseFloat(info.farmGrossArea))} {info.unitIdLabel}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 title__info-content">
                        {strings.expected_harvest_date}:
                      </div>
                      <div className="col-6 info__info-content">
                        {harvestDate && harvestDate.isValid()
                          ? harvestDate.format(UtilDate.formatDateLocal)
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6 title__info-content">
                        {strings.total_yield_harvested}:
                      </div>
                      <div className="col-6 info__info-content">
                        {numberFormatWithPoint(parseFloat(info.farmGrossYield))}{" "}
                        {info.unitForMassLabel}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 title__info-content">
                        {strings.expected_total_cost}:
                      </div>
                      <div className="col-6 info__info-content">
                        {numberFormatInt(this.calSumCost(), strings.unit_price_d)} {}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2 d-flex flex-row justify-content-end align-items-center">
                <Avatar
                  size={100}
                  src={IMAGE_URL + getString(info, "farmProfile.avatar", undefined)}
                />
              </div>
            </div>
            <div className="done-content row col-12 mt-4">
              <h4 className="col-12"> {strings.expected_output_by_type} </h4>

              <DoneForm
                className="col-12"
                dataViewSource={dataViewSource ? dataViewSource : info.dataViewSource}
                expand={expand}
              />
            </div>
          </div>

          <UIButton
            disabled={this.state.savePageDone}
            style={{float: "right"}}
            type="primary"
            className="ml-2"
            htmlType="button"
            onClick={() => {
              this.props.onCreate();
            }}
          >
            <span>{strings.completed}</span>
          </UIButton>
        </div>
      </ContainerStyled>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    getAllFarmCrops,
    getType,
  }),
  withState("cropsFarm", "setCropsFarm", []),
  withState("expand", "setExpand", []),
  withState("percentSizeArr", "setPercentSizeArr", []),
  withState("dataViewSource", "setDataSourceViewSource", []),
  withHandlers({
    fetchGetAllFarmCrops: (props) => () => {
      const {setCropsFarm, getAllFarmCrops} = props;
      getAllFarmCrops(PRODUCT_TYPE.PRODUCT)
        .then(({res}) => {
          setCropsFarm(
            getArray(res, undefined, []).map((item) => {
              return {
                label: item.name || "",
                value: item.id,
              };
            })
          );
        })
        .catch((err) => showError(err));
    },
    /**
     * Map data to table view
     * Author: tan.hoang@vslsoft.com
     * Date: 18:13 Sep 14, 2020
     */

    fetchPercentSize: (props) => () => {
      const {getType, setPercentSizeArr} = props;
      getType("PERCENT_SIZE")
        .then(({res}) => {
          setPercentSizeArr(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => {
          console.error("DoneForm -> fetchPercentSize -> err", err);
          showError(err);
        });
    },
  }),
  withFormik({
    displayName: "infoPageDone",
    enableReinitialize: true,
    mapPropsToValues: () => {
      return {
        // nameFarm: props.info.nameFarm,
        // farmSeasonName: props.info.farmSeasonName,
      };
    },
  }),
  lifecycle({
    componentDidMount() {
      const {fetchGetAllFarmCrops, fetchPercentSize} = this.props;
      fetchGetAllFarmCrops();
      fetchPercentSize();
    },
  })
)(PageDone);
