import { compose, withState, withHandlers, lifecycle } from "recompose";
import { withRouter } from "react-router-dom";
// import {
//   findKey,
//   indexOf,
//   dropRight,
//   last,
//   _,
//   startsWith,
//   filter,
//   endsWith,
// } from "lodash";
import * as _ from "lodash";
import { mediaMin } from "~/views/utilities/layout";
import { getAuthUser } from "~/state/ducks/authUser/selectors";
import { authActions } from "~/state/ducks/authUser";
import { connect } from "react-redux";

const setSelectedKeys = (menus, path, keys) => {
  const keyOption = _.findKey(menus, (menu) => {
    const str = path.split(/[//]/);
    let url = menu.path;
    if (_.startsWith(path || "", url)) {
      return true;
    }
    return false;
  });

  if (keyOption) {
    keys.push(keyOption);
    if (menus[keyOption] && menus[keyOption].subMenus) {
      setSelectedKeys(menus[keyOption].subMenus, path, keys);
    }
  }
};

const enhance = compose(
  connect(
    (state) => ({
      user: getAuthUser(state),
    }),
    {
      logout: authActions.logout,
    }
  ),
  withRouter,
  withState("defaultSelectedKeys", "setDefaultSelectedKeys", ({ location, menus }) => {
    const keys = [];
    setSelectedKeys(menus, location.pathname, keys);

    return keys;
  }),
  withState("openKeys", "setOpenKeys", ({ location, menus }) => {
    let check = [];
    _.map(menus, (menu, index) => {
      if (menu.defaultExpand) check.push(index);
    });

    return check;
  }),
  withState("menus", "setMenus", (props) => props.menus),
  withHandlers({
    onOpenChange: ({ setOpenKeys }) => (openKeys, menus, location) => {
      let check = [];
      _.map(menus, (menu, index) => {
        if (menu.defaultExpand) check.push(index);
      });
      setOpenKeys([...check, _.last(openKeys)]);
    },
  }),
  lifecycle({
    shouldComponentUpdate(nextProps) {
      if (nextProps.isCollapsed && nextProps.isCollapsed !== this.props.isCollapsed) {
        nextProps.setOpenKeys([]);
        return false;
      }
      return true;
    },
  })
);

export default enhance;
