import { message } from "antd";
message.config({
  maxCount: 1,
  duration: 2,
});

/**
 * CONFIGS
 */
export const PRODUCT_EVENTS = {
  NONE: "NONE",
  PRICE_IN: "PRICE_IN",
  PRICE_DE: "PRICE_DE",
  OUT_OF_STOCK: "OUT_OF_STOCK",
  PRODUCT_DELETED: "PRODUCT_DELETED", // ngừng kinh doanh
  PRODUCT_INACTIVE: "PRODUCT_INACTIVE", // ngừng kinh doanh
  VARIATION_DELETED: "VARIATION_DELETED", // ngừng kinh doanh
  INVENTORY_DISABLE: "INVENTORY_DISABLE", // ngường kinh doanh
  INVENTORY_OUT_OF: "INVENTORY_OUT_OF", // ngừng kinh doanh
  INVENTORY_NOT_SUFFICIENT: "INVENTORY_NOT_SUFFICIENT", // ngừng kinh doanh
  INVENTORY_NOT_FOUND: "INVENTORY_NOT_FOUND", // ngừng kinh doanh
  AVAILABLE: "AVAILABLE",
  NOT_ENOUGH_PRODUCT: "NOT_ENOUGH_PRODUCT",
  PRODUCT_INACTIVE: "PRODUCT_INACTIVE", // ngừng kinh doanh
  VENDOR_INACTIVE: "VENDOR_INACTIVE", // ngừng kinh doanh
};
export const COMMERCIAL = "https://supplier.edenhub.vn/isdashboard/";

export const API_URL = process.env.DOMAIN;
export const API_UPLOAD_URL = API_URL + "/services/vslfiles/api/images";
export const IMAGE_URL = API_URL + "/services/vslfiles/api/images/";

export const API_UPLOAD_FILES_URL = API_URL + "/services/sys/api/v1/files";
export const API_EXPORT_HARVEST =
  API_URL + "/services/sys/api/v1/export/excel?farmingSeasonId=";

export const sizes = {
  xl: "1200px",
  lg: "992px",
  md: "768px",
  sm: "576px",
};

export const imgUploadConfig = {
  minWidth: 750,
  minHeight: 500,
  fileSize: 5,
  type: /\.(jpg|jpeg|bmp|png|gif|tiff)$/i,
};

/**
 * media
 */
export const mediaUploadConfig = {
  type: /\.(png|jpg|jpeg|bmp|mp4|gif|tiff)$/i,
};
export const docsFilesUploadConfig = {
  type: /\.(xlsb|xls|xlsm|xlsx|doc|docx|pdf|txt|odt|rtf|tex|wks|wpd|ods|odp|pps|ppt|pptx|gz|zip|rar|iso)$/i,
};
export const videoUploadConfig = {
  fileSize: 10,
  type: /\.(mp4)$/i,
};
export const docsUploadConfig = {
  type: /\.(doc|docx)$/i,
};
export const pdfUploadConfig = {
  type: /\.(pdf)$/i,
};
export const excelUploadConfig = {
  type: /\.(xlsb|xls|xlsm|xlsx|xlr)$/i,
};
export const txtUploadConfig = {
  type: /\.(txt)$/i,
};
export const rarUploadConfig = {
  type: /\.(zip|rar|iso|gz)$/i,
};
export const otherUploadConfig = {
  type: /\.(odt|rtf|tex|wks|wpd|ods|odp|pps|ppt|pptx|)$/i,
};

export const imageExtension = ".png, .jpg, .jpeg, .bmp";
export const mediaExtension = ".png,.jpg,.jpeg,.bmp,.mp4,.gif,.tiff";
export const filesExtension =
  ".doc,.docx,,.pdf,.xls,.xlsb,.xlsm,.xlsx,.txt,.odt,.rtf,.tex,.wks,.wpd,.ods,.odp,.pps,.ppt,.pptx,.gz,.zip,.rar,.iso";

export const API_CODE = {
  AUTHENTICATION_INVALID: "AUTHENTICATION_INVALID",
  SUCCESS: "SUCCESS",
  UNAUTHORIZED: "UNAUTHORIZED",
};

export const JWT = "jwt_agrisys" + API_URL;
export const JWTFORSUPPLY = "jwt_supplier_" + API_URL;

export const LICENCE_MAX_LENGTH = 50;

export const defaultExport = {
  harvestInventory: "Báo cáo tồn kho.xlsx",
};
