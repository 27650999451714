import React from "react";
import PersonalProfileForms from "~/views/container/Profiles/PersonalProfileForms";
import { getPersonalProfile } from "~/state/ducks/appApis/actions";
import { updateProfile, createProfile } from "~/state/ducks/authUser/actions";
import { showError } from "~/configs/ServerErrors";
import { connect } from "react-redux";
import {
  getNumber,
  getArray,
  removeEmptyArrayItem,
} from "~/views/utilities/helpers/utilObject";
import {
  getProvinces,
  getDistrict,
  getBranches,
  deletePersonalProfile,
} from "~/state/ducks/appApis/actions";
import {
  VIEW_PERSONAL_PROFILE_PATH,
  ALL_PROFILES_PATH,
} from "~/configs/routesConfig";
import { message } from "antd";
import strings from "~/localization";
import ActivityForm from "../../ActivityForm";
import _ from "lodash";
import { StepsFormContainerStyle } from "~/views/container/styles";

import { withRouterInnerRef } from "~/views/container/commons";

/**
 * create or update profile format
 */
const emptyObject = () => ({
  name: undefined,
  shortName: undefined,
  birthday: undefined,
  phone: undefined,
  email: undefined,
  profession: undefined,
  gender: undefined,
  avatar: undefined,
  logo: undefined,
  website: undefined,
  description: undefined,
  professionId: undefined,
  literacyId: undefined,
  addresses: [],
  familyRegisters: [],
  identityPapers: [],
  degrees: [],
  farmingExperiences: [],
  incomes: [],
  certifications: [],
  bankAccounts: [],
});
class PersonalProfile extends React.PureComponent {
  constructor(props) {
    super(props);
    const { match } = this.props;
    let editable = true;
    switch (match.path) {
      case VIEW_PERSONAL_PROFILE_PATH:
        {
          editable = false;
        }
        break;
    }
    this.state = {
      editable: editable,
      loading: false,
      profile: {},
    };
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.fetchProfile();
  }

  /**
   * delete profile
   */
  onDelete() {
    const { deletePersonalProfile, match, history } = this.props;
    let profileId = match.params.id;
    deletePersonalProfile(profileId)
      .then(({}) => {
        message.success(strings.delete_profile_success);
        history.push(ALL_PROFILES_PATH);
      })
      .catch((err) => showError(err));
  }
  /**
   * save exist profile
   */
  onSave() {
    const { updateProfile, history } = this.props;
    updateProfile(removeEmptyArrayItem(this.state.profile))
      .then(({}) => {
        message.success(strings.update_profile_successful);
        history.push(ALL_PROFILES_PATH);
      })
      .catch((err) => showError(err));
  }

  /**
   * create profile
   */
  onCreate() {
    const { createProfile, history } = this.props;

    createProfile(this.state.profile)
      .then(({}) => {
        message.success(strings.create_profile_successful);
        history.push(ALL_PROFILES_PATH);
      })
      .catch((err) => showError(err));
  }

  fetchProfile = () => {
    const { match, getPersonalProfile } = this.props;
    this.setState({ loading: true });
    let profileId = +match.params.id;
    /**
     * trường hợp tạo profile
     */
    if (_.isNaN(profileId)) {
      this.setState({ profile: emptyObject() });
      return;
    }

    /**
     * trường hợp cập nhật profile
     */
    getPersonalProfile(profileId)
      .then(async ({ res }) => {
        // map data từ response về format chuẩn để hiển thị trên views
        let mappedObject = {
          ...res,
          professionId: getNumber(res, "profession.id"),
          literacyId: getNumber(res, "literacy.id"),
          identityPapers: getArray(res, "identityPapers", []).map((item) => ({
            ...item,
            id: undefined,
            typeId: getNumber(item, "type.id"),
            issuedPlaceId: getNumber(item, "issuedPlace.id"),
          })),
          addresses: getArray(res, "addresses", []).map((item) => ({
            ...item,
            id: undefined,
            typeId: getNumber(item, "type.id"),
            countryId: getNumber(item, "country.id"),
            provinceId: getNumber(item, "province.id"),
            districtId: getNumber(item, "district.id"),
          })),
          certifications: getArray(res, "certifications", []).map((item) => ({
            ...item,
            id: undefined,
            typeId: getNumber(item, "type.id"),
          })),
          degrees: getArray(res, "degrees", []).map((item) => ({
            ...item,
            id: undefined,
            typeId: getNumber(item, "type.id"),
            rankingId: getNumber(item, "ranking.id"),
            specializeId: getNumber(item, "specialize.id"),
          })),
          incomes: getArray(res, "incomes", []).map((item) => ({
            ...item,
            id: undefined,
            typeId: getNumber(item, "type.id"),
          })),
          familyRegisters: getArray(res, "familyRegisters", []).map((item) => ({
            ...item,
            id: undefined,
            relationId: getNumber(item, "relation.id"),
            issuedPlaceId: getNumber(item, "issuedPlace.id"),
            typeId: getNumber(item, "type.id"),
          })),
          bankAccounts: getArray(res, "bankAccounts", []).map((item) => ({
            ...item,
            id: undefined,
            bankId: getNumber(item, "bank.id"),
            branchId: getNumber(item, "branch.id"),
          })),
          farmingExperiences: getArray(res, "farmingExperiences", []).map(
            (item) => ({
              ...item,
              id: undefined,
              unitId: getNumber(item, "unit.id"),
              cultivationTypeId: getNumber(item, "cultivationType.id"),
              qualityTypeId: getNumber(item, "qualityType.id"),
              productTypeId: getNumber(item, "productType.id"),
            })
          ),
        };

        /**
         * load districts liên kết tới provinceId
         */
        const { getDistrict, getProvinces } = this.props;
        const mapMasterDataToAddress = (addresses) => {
          return new Promise((resolve) => {
            let mappedAddress = [];
            try {
              if ((addresses || []).length === 0) {
                resolve([]);
                return;
              }
              (addresses || []).forEach(async (item) => {
                /**
                 * load provinces
                 */
                let countryId = getNumber(item, "countryId");
                let provinces = [];
                if (countryId > 0) {
                  try {
                    let response = await getProvinces(countryId);
                    provinces = getArray(response, "res", []).map(
                      (province) => ({
                        value: province.id,
                        label: province.name,
                      })
                    );
                  } catch (error) {}
                }
                /**
                 * load districts
                 */
                let provinceId = getNumber(item, "provinceId");
                let districts = [];
                if (provinceId > 0) {
                  try {
                    let response = await getDistrict(provinceId);
                    districts = getArray(response, "res", []).map(
                      (district) => ({
                        value: district.id,
                        label: district.name,
                      })
                    );
                  } catch (error) {}
                }

                mappedAddress.push({
                  ...item,
                  districts: districts,
                  provinces: provinces,
                });

                if ((mappedAddress || []).length === (addresses || []).length) {
                  resolve(mappedAddress);
                }
              });
            } catch (error) {
              console.error(JSON.stringify(error));
            }
          });
        };

        let addresses = getArray(mappedObject, "addresses", []);
        let mappedAddress = await mapMasterDataToAddress(addresses);
        mappedObject.addresses = mappedAddress;

        /**
         * load chi nhánh ngân hàng liên kết tới bankId
         */

        const { getBranches } = this.props;
        const mapMasterDataToBankAccounts = (bankAccounts) => {
          return new Promise((resolve) => {
            let mappedBankAccount = [];
            if ((bankAccounts || []).length === 0) {
              resolve([]);
              return;
            }
            try {
              (bankAccounts || []).forEach(async (item) => {
                let bankId = getNumber(item, "bankId");
                if (bankId > 0) {
                  try {
                    let response = await getBranches(bankId);
                    let branches = getArray(response, "res", []).map(
                      (branch) => ({
                        value: branch.id,
                        label: branch.name,
                      })
                    );
                    mappedBankAccount.push({
                      ...item,
                      branches: branches,
                    });
                  } catch (error) {
                    mappedBankAccount.push(item);
                  }
                } else {
                  mappedBankAccount.push(item);
                }
                if (
                  (mappedBankAccount || []).length ===
                  (bankAccounts || []).length
                ) {
                  resolve(mappedBankAccount);
                }
              });
            } catch (error) {
              console.error(JSON.stringify(error));
            }
          });
        };

        let bankAccounts = getArray(mappedObject, "bankAccounts", []);
        let mappedBankAccount = await mapMasterDataToBankAccounts(bankAccounts);
        mappedObject.bankAccounts = mappedBankAccount;
        this.setState({ profile: mappedObject, loading: false });
      })
      .catch((err) => {
        showError(err);
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <StepsFormContainerStyle>
        <div>
          <div className="profile-view">
            <PersonalProfileForms
              info={this.state.profile}
              editable={this.state.editable}
              showHeader={false}
              showFooter={false}
            />
          </div>
          <div>
            <ActivityForm
              editable={this.state.editable}
              info={this.state.profile}
            />
          </div>
        </div>
      </StepsFormContainerStyle>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      getPersonalProfile,
      getDistrict,
      getBranches,
      updateProfile,
      createProfile,
      getProvinces,
      deletePersonalProfile,
    },
    null,
    { forwardRef: true }
  )(PersonalProfile)
);
