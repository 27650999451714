import React from 'react';

import {
  Form,
  Tabs,
} from 'antd';
import { withFormik } from 'formik';
import _ from 'lodash';
// redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  compose,
  lifecycle,
  withHandlers,
  withState,
} from 'recompose';
import * as yup from 'yup';
import { showError } from '~/configs/ServerErrors';
// config || common
import strings from '~/localization';
import { getType } from '~/state/ducks/appApis/actions';
// component
import { UIButton } from '~/views/presentation/ui/buttons';
import {
  InputField,
  SelectField,
} from '~/views/presentation/ui/fields';
import UtilDate from '~/views/utilities/helpers/UtilDate';
import { getArray } from '~/views/utilities/helpers/utilObject';
import { stringNRFieldValidate } from '~/views/utilities/validation/input';

import FarmSeasonInputForm from './FarmSeasonInputForm';
import LaborsForm from './LaborsForm';
import OthersForm from './OthersForm';

const {TabPane} = Tabs;

const validationSchema = yup.object().shape({
  nameFarm: stringNRFieldValidate(),
  name: stringNRFieldValidate(),
});

class InputForms extends React.PureComponent {
  /**
   *
   * @param {*} prvDataSource - the dataSource will be updated
   */
  updateDataSource = (prvDataSource = [], type) => (dataSource) => {
    while (prvDataSource.length) {
      prvDataSource.shift();
    }
    dataSource.forEach((item) => {
      prvDataSource.push(item);
    });
    // this.props.info[type] = [...dataSource];
  };

  handleChangeNumber = (e, name) => {
    const {setFieldValue} = this.props;
    setFieldValue(name, e);
    this.props.info[name] = e;
  };

  handleChangeInputValue = (e) => {
    const {handleChange} = this.props;
    handleChange(e);
    let name = e.target.name;
    this.props.info[name] = e.target.value;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {}

  handleChangeSelectValue = (name) => (value) => {
    const {setFieldValue} = this.props;
    setFieldValue(name, value);
    this.props.info[name] = value;
  };

  handleChangeDate = (name) => (date) => {
    const {setFieldValue} = this.props;
    if (!_.isNil(date)) {
      setFieldValue(name, date.format(UtilDate.formatDateTimeServer));
      this.props.info[name] = date.format(UtilDate.formatDateTimeServer);
    } else {
      setFieldValue(name, undefined);
      this.props.info[name] = undefined;
    }
  };

  render() {
    const {
      info,
      values,
      handleBlur,
      touched,
      errors,
      setFieldValue,
      setFieldTouched,
      editable,
    } = this.props;

    return (
      <div>
        <Form>
          <div className="row d-flex flex-row">
            <div className="col-12 col-md-6">
              <SelectField
                validatestatus={
                  touched.nameFarm && errors.nameFarm ? "error" : undefined
                }
                help={
                  touched.nameFarm && errors.nameFarm ? errors.nameFarm : ""
                }
                name="nameFarm"
                data={info.farmProfiles}
                autoFocus={true}
                disabled={true}
                value={values.nameFarm}
                onChange={(value) => {
                  this.props.info.nameFarm = value;
                  setFieldValue("nameFarm", value);
                  setFieldTouched("nameFarm");
                }}
                onBlur={handleBlur}
                placeholder={strings.farm_choose}
                label={strings.farm_choose}
              />
            </div>
            <div className="col-12 col-md-6">
              <InputField
                validatestatus={
                  touched.name && errors.name ? "error" : undefined
                }
                help={touched.name && errors.name ? errors.name : ""}
                name="name"
                disabled={true}
                value={values.name}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={strings.farm_season_name}
                label={strings.farm_season_name}
              />
            </div>
          </div>
          <div className="mt-4">
            <Tabs defaultActiveKey="1" type="card" size={"middle"}>
              <TabPane tab={strings.supplies} key="1">
                <FarmSeasonInputForm
                  editable={editable}
                  onChange={this.updateDataSource(
                    this.props.info.materials,
                    "materials"
                  )}
                  materials={this.props.info.materials}
                />
              </TabPane>
              <TabPane tab={strings.labor} key="2">
                <LaborsForm
                  editable={editable}
                  onChange={this.updateDataSource(
                    this.props.info.laborCosts,
                    "laborCosts"
                  )}
                  laborCosts={this.props.info.laborCosts}
                />
              </TabPane>
              <TabPane tab={strings.other} key="3">
                <OthersForm
                  editable={editable}
                  onChange={this.updateDataSource(
                    this.props.info.otherCosts,
                    "otherCosts"
                  )}
                  otherCosts={this.props.info.otherCosts}
                />
              </TabPane>
            </Tabs>
            <div style={{marginTop: "16px"}}>
              <UIButton
                style={{float: "right"}}
                type="secondary"
                className="ml-2"
                htmlType="button"
                disabled={!editable}
                onClick={() => this.props.nextStep(3)}
              >
                <span>{strings.countinue}</span>
              </UIButton>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    getType,
  }),
  withState("farmProfiles", "setFarmProfiles", []),
  withState("allocationPerTree", "setAllocationPerTree", []),
  withHandlers({
    fetchAllocationPerTree: (props) => () => {
      const {getType, setAllocationPerTree} = props;
      getType("PERCENT_SIZE")
        .then(({res}) => {
          setAllocationPerTree(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => {
          console.error("fetchAllocationPerTree -> err", err);
          showError(err);
        });
    },
    handleChangeDate: (_) => (props) => (name) => (value) => {
      const {setFieldValue, setFieldTouched} = props;
      setFieldTouched(name);
      if (value) {
        setFieldValue(
          name,
          value.endOf("day").utc().format(UtilDate.formatDateTimeServer)
        );
      } else {
        setFieldValue(name, undefined);
      }
    },
  }),
  withFormik({
    displayName: "infoProcedure",
    enableReinitialize: true,
    validationSchema,
    mapPropsToValues: (props) => {
      return {
        nameFarm: props.info.nameFarm,
        name: props.info.name,
      };
    },
  }),
  lifecycle({
    componentDidMount() {
      const {fetchAllocationPerTree, fetchFarmProfiles} = this.props;
      fetchAllocationPerTree();
    },
  })
)(InputForms);
