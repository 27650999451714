import styled from "styled-components";
import { Input, Layout, Menu } from "antd";

const ContainerNavStyled = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: 1px 1px 1px 0px #dcdcdc;
  .profile-nav {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 45px;
    z-index: 999;
  }
  .menu {
    margin-top: 4px;
    width: 250px;
    height: 100vh;
    top: 110px;
    position: fixed;
    z-index: 999;
  }
  .content {
    @media (max-width: 1000px) {
      width: 100%;
    }
    @media (min-width: 1900px) {
      width: 85%;
    }
    flex: 1;
    right: 0px;
    width: 80%;
    position: absolute;
    min-height: 100%;
    top: 70px;
    transition: 1s;
  }
  .none-menu {
    width: 100%;
  }
`;

const SearchStyled = styled(Input)`
  margin: 4px 0 0 0;
  input {
    height: 30px !important;
  }
  input:hover,
  input:active,
  input:focus,
  input:visited {
    border-color: transparent !important;
    border-right-width: 1px !important;
    outline: none !important;
  }
  border-left: 1px !important;
  border-top: 1px !important;
  border-right: 1px !important;
  :hover,
  :active,
  :focus,
  :visited {
    border-color: transparent !important;
    outline: none !important;
  }
`;
const ContainerStyled = styled.div`
  transform: ${(props) =>
    !props.hiddenAction
      ? "translateY(0)"
      : props.showAction
      ? "translateY(10%)"
      : "translateY(-70%)"};
  @media (min-width: 1000px) {
    transform: translateY(0);
  }
  transition: 1s;
  span.ant-dropdown-trigger {
    margin-right: auto;
  }
  background-color: #fff;
  padding: 2px 0px;
  border-bottom: 1px solid #dcdcdc;
  .row {
    align-items: center;
    div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .show-action {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .show-action .ant-btn {
    background-color: #ffff !important;
  }
`;
const GroupButtonStyled = styled.div`
  button {
    width: auto;
    height: 24px !important;
    padding: 0px 15px !important;
    border-radius: 5px;
  }
  button span {
    text-transform: capitalize;
    cursor: pointer;
  }
  // button span:first-letter {
  //   text-transform: uppercase;
  // }
`;
const FilterGroup = styled.div`
  .filter {
    color: gray;
  }
  display: flex;
  justify-content: space-between;
  divi: hover {
    cursor: pointer;
  }
`;
const ActionStyled = styled.div`
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  span {
    cursor: pointer;
  }
`;
const SegmentedControlContainer = styled.div`
  .r-segmented-control > ul > li.base {
    border-color: transparent !important;
    color: lightgray !important;
    background: transparent !important;
    padding: 0px 5px;
  }
  .r-segmented-control > ul > li.base.selected {
    background: transparent !important;
    color: gray !important;
  }
  .r-segmented-control {
    background-color: transparent !important;
    border: none !important;
  }
`;

const ContainerTreeMenuStyle = styled(Layout)``;

const WrapMenu = styled(Menu)`
  span.ant-dropdown-trigger {
    margin-right: auto;
  }
`;

export {
  SearchStyled,
  ContainerStyled,
  ContainerNavStyled,
  ContainerTreeMenuStyle,
  GroupButtonStyled,
  WrapMenu,
  FilterGroup,
  ActionStyled,
  SegmentedControlContainer,
};
