import React from "react";
import strings from "~/localization";
import { getString, getNumber } from "~/views/utilities/helpers/utilObject";
import { IMAGE_URL } from "~/configs";
import { Avatar } from "~/views/presentation/ui/fields";
import { Tooltip } from "antd";

function CardItem(props) {
  const name = getString(props.item, "name", strings.default_unnamed);
  const phone = getString(props.item, "phone", strings.default_no_phone_number);
  const fullAddress =
    getString(props.item, "fulladdress", strings.default_no_address_yet) === ""
      ? strings.default_no_address_yet
      : getString(props.item, "fulladdress", strings.default_no_address_yet);
  const grossArea = getNumber(props.item, "grossarea", 0);
  return (
    <div className={`${props.isFarmManage ? "none-menu-item" : "item"} `}>
      <div className={`item-container ${getString(props.item, "type", "")}`}>
        <div
          className="left d-flex flex-column justify-content-center align-items-center m-2 ml-4"
          onClick={() => props.handleViewProfile(props.item)}>
          <Avatar
            className="border-white shadow ht-avatar"
            size={80}
            src={IMAGE_URL + getString(props.item, "avatar", "")}
          />
          <p
            style={{ maxWidth: 100 }}
            className="m-2 text-break text-decoration-none text-center">
            {props.getProfileType(getString(props.item, "type", ""))}
          </p>
        </div>
        <div
          className="right"
          onClick={() => props.handleViewProfile(props.item)}>
          <Tooltip title={name}>
            <h4>{name}</h4>
          </Tooltip>

          <div>
            {/* <p className="title">{strings.phone}</p>{" "} */}
            <i className="fa fa-phone ht-icon" aria-hidden="true"></i>
            <p
              className={`value ml-2 ${
                phone === strings.default_no_phone_number
                  ? "text-secondary"
                  : ""
              }`}>
              {phone}
            </p>
          </div>
          <div>
            {/* <p className="title">{strings.address}</p>{" "} */}
            <i className="fa fa-map-marker ht-icon" aria-hidden="true"></i>
            <p
              className={`value ml-2 ${
                fullAddress === strings.default_no_address_yet
                  ? "text-secondary"
                  : ""
              }`}>
              {fullAddress}
            </p>
          </div>
          <div>
            {/* <p className="title">{strings.farm_area}</p>{" "} */}
            <i className="fa fa-area-chart ht-icon" aria-hidden="true"></i>
            <p
              className={`value ml-2 ${
                grossArea === 0 ? "text-secondary" : ""
              }`}>
              {strings.formatString(strings.farm_area_size, {
                size: grossArea,
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardItem;
