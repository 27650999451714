import React from "react";

import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose, lifecycle, withHandlers, withState} from "recompose";
import {showError} from "~/configs/ServerErrors";
import strings from "~/localization";
import {getAllCertificateOfLands, getAllFarmCrops} from "~/state/ducks/appApis/actions";
import {TableEditable} from "~/views/presentation/ui/tables";
import {getArray} from "~/views/utilities/helpers/utilObject";
import styled from "styled-components";
import {Form} from "antd";
import {PRODUCT_TYPE} from '~/configs/const';

export const TableStyled = styled(Form.Item)`
  width: 100%;
  display: block;

  margin-bottom: ${(props) => (props.mBottom ? props.mBottom : "10px")};
  }
`;

class CropsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {dataSource: this.props.cropsList || []};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cropsList != this.state.dataSource) {
      this.setState({dataSource: nextProps.cropsList || []});
    }
  }
  handleChangedData = (index, dataIndex, dataSource) => {
    const {onChange} = this.props;
    onChange && onChange(dataSource);
  };

  render() {
    const {cropsFarm, editable, getAllFarmCrops, validatestatus, className, help} = this.props;
    let columns = [
      {
        title: strings.crops,
        dataIndex: "productId",
        width: "20%",
        editable: editable,
        required: true,
        placeholder: strings.crops,
        type: "selectSuggestion",
        options: cropsFarm || [],
        isSearch: false,
        handleFetch: getAllFarmCrops,
        nameData: "labelSelect",
        filterOption: true,
        optionFilterProp: "label",
        renderOpts: {
          value: "value",
          labelSelect: "label",
          disabled: "disabled",
        },
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.cultivated_area,
        dataIndex: "grossArea",
        editable: editable,
        required: true,
        placeholder: strings.cultivated_area,
        min: 0,
        width: "15%",
        type: "decimalInput",
        suffix: strings.hectare,
      },
      {
        title: strings.parcel_of_land,
        dataIndex: "certifycateOfLandIds",
        width: "15%",
        editable: editable,
        required: true,
        placeholder: strings.parcel_of_land,
        filterOption: true,
        optionFilterProp: "label",
        type: "selectMulti",
        options: this.props.certificateOfLands || [],
      },
      {
        title: strings.tree_per_area,
        dataIndex: "seedDensityDetail",
        width: "10%",
        editable: editable,
        required: true,
        placeholder: strings.tree_per_area,
        min: 0,
        type: "number",
      },
      {
        title: strings.expected_output,
        dataIndex: "grossYield",
        editable: editable,
        required: true,
        width: "15%",
        min: 0,
        placeholder: strings.expected_output,
        suffix: strings.ton,
        type: "decimalInput",
      },
      {
        title: strings.note,
        dataIndex: "note",
        editable: editable,
        required: false,
        width: "10%",
        placeholder: strings.note,
        type: "text",
      },
      {
        title: strings.solution_improvement,
        dataIndex: "solutions",
        editable: editable,
        width: "15%",
        required: false,
        placeholder: strings.solution_improvement,
        type: "text",
      },
    ];

    return (
      <TableStyled validatestatus={validatestatus} help={help} className={className}>
        {/* <p className="font-weight-bold"> {help} </p> */}
        <TableEditable
          onlyOneRow={true}
          scroll={{x: 1200}}
          editable={editable}
          changedData={this.handleChangedData}
          dataSource={this.state.dataSource || []}
          columns={columns}
        />
      </TableStyled>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getAllFarmCrops,
    getAllCertificateOfLands,
  }),
  withState("dataSource", "setDataSource", []),
  withState("certificateOfLands", "setCertificateOfLands", []),
  withState("cropsFarm", "setCropsFarm", []),
  withHandlers({
    fetchCertificateOfLands: (props) => () => {
      const {getAllCertificateOfLands, setCertificateOfLands} = props;
      getAllCertificateOfLands(props.match.params.id)
        .then(({res}) => {
          setCertificateOfLands(
            getArray(res, undefined, []).map((item) => {
              return {
                value: item.id,
                label: item.landLotNo,
              };
            })
          );
        })
        .catch((err) => showError(err));
    },

    fetchGetAllFarmCrops: (props) => () => {
      const {setCropsFarm, getAllFarmCrops} = props;
      getAllFarmCrops(PRODUCT_TYPE.PRODUCT)
        .then(({res}) => {
          setCropsFarm(
            getArray(res, undefined, []).map((item) => {
              return {
                value: item.id,
                label: item.name || "",
              };
            })
          );
        })
        .catch((err) => showError(err));
    },
  }),
  lifecycle({
    componentDidMount() {
      const {fetchCertificateOfLands, fetchGetAllFarmCrops} = this.props;
      fetchCertificateOfLands();
      fetchGetAllFarmCrops();
    },
  })
)(CropsForm);
