import React from "react";
import styled from "styled-components";

const ContentWrapperStyle = styled.div`
  padding: 20px;

  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  h2 {
    font-size: 24px;
    color: #595959;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    margin-top: 16px;
  }
  h3 {
    font-size: 20px;
    color: #595959;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    margin-top: 10px;
  }
  h4 {
    font-size: 16px;
    color: #595959;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    margin-top: 10px;
  }
  .line-pro {
    border: 0;
    height: 1px;
    width: 100%;
    background-image: -webkit-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -moz-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -ms-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -o-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  }
`;
/**
 *
 * @param {*} topNav {pageName : 'Test', links : [{ name : 'link', link : '#' }]}
 */
export const ContentWrapper = ({ children }) => {
  return <ContentWrapperStyle>{children}</ContentWrapperStyle>;
};

export default ContentWrapper;
