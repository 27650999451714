import * as types from "./types";
import apiAction, { defaultAction } from "../utils/createAction";
import { parseObjToQuery } from "~/views/utilities/helpers";

const servicegateway = {
  store: "/services/godi-services/",
  wallet: "services/wallet/"
};

export const getOrderDetail = (id) =>
  apiAction("get")(
    types.GETORDERDETAIL,
    `${servicegateway.store}api/v1/manufacturer/myorder/${id}`,
    {},
    true
  );
export const getOrderList = (data) =>
  apiAction("get")(
    types.COMMON,
    `${servicegateway.store}api/v1/manufacturer/myorder/list` +
    parseObjToQuery(data),
    {},
    true
  );

export const addOrder = (data) => apiAction()(types.COMMON, `${servicegateway.store}api/v1/manufacturer/myorder`, data, true);
