import { compose, withHandlers, lifecycle, withState } from "recompose";
import { connect } from "react-redux";
import {
  getAllPakaingCode,
  getAllProductCost,
  getAllPriceStructures,
} from "~/state/ducks/appApis/actions";
import { showError } from "~/configs/ServerErrors";
import { getArray } from "~/views/utilities/helpers/utilObject";
import { mapArrayObjectToAPI } from "~/views/utilities/helpers/utilObject";
import * as PATH from "~/configs/routesConfig";

import { withRouter } from "react-router-dom";

export default compose(
  withRouter,
  connect(null, {
    getAllPakaingCode,
    getAllProductCost,
    getAllPriceStructures,
  }),
  withState("procedure", "setProcedure", []),
  withState("typeAll", "setTypeAll", ""),
  withHandlers({
    fetchAllProcedure: (props) => () => {
      const { getAllPakaingCode, setProcedure, match } = props;

      const CHANGE_NAME = [
        {
          currName: "name",
          changeName: "title",
        },
        {
          currName: "appliedNumber",
          changeName: "status",
        },
        {
          currName: "productName",
          changeName: "type",
        },
        {
          currName: "lastModifiedDate",
          changeName: "price",
        },
        {
          currName: "status",
          changeName: "total",
        },
      ];
      getAllPakaingCode(match.params.idSeason)
        .then(async ({ res }) => {
          const objectMap = mapArrayObjectToAPI(res, CHANGE_NAME);
          setProcedure(
            getArray(objectMap, undefined, []).map((val) => ({
              ...val,
              // status: "Chưa áp dụng",
            }))
          );
        })
        .catch((err) => {});
    },

    fetchAllProductCost: (props) => () => {
      const { getAllProductCost, setProcedure, match } = props;

      const CHANGE_NAME = [
        {
          currName: "name",
          changeName: "title",
        },
        {
          currName: "appliedNumber",
          changeName: "status",
        },
        {
          currName: "productName",
          changeName: "type",
        },
        {
          currName: "lastModifiedDate",
          changeName: "price",
        },
        {
          currName: "a",
          changeName: "total",
        },
      ];
      getAllProductCost(match.params.idSeason)
        .then(async ({ res }) => {
          const objectMap = mapArrayObjectToAPI(res, CHANGE_NAME);
          setProcedure(
            getArray(objectMap, undefined, []).map((val) => ({
              ...val,
              // status: "Chưa áp dụng",
            }))
          );
        })
        .catch((err) => {});
    },
    fetchAllSalePrice: (props) => () => {
      const { getAllPriceStructures, setProcedure, match } = props;

      const CHANGE_NAME = [
        {
          currName: "name",
          changeName: "title",
        },
        {
          currName: "appliedNumber",
          changeName: "status",
        },
        {
          currName: "productName",
          changeName: "type",
        },
        {
          currName: "lastModifiedDate",
          changeName: "price",
        },
        {
          currName: "status",
          changeName: "total",
        },
      ];
      getAllPriceStructures(match.params.idSeason)
        .then(async ({ res }) => {
          const objectMap = mapArrayObjectToAPI(res, CHANGE_NAME);
          setProcedure(
            getArray(objectMap, undefined, []).map((val) => ({
              ...val,
              // status: "Chưa áp dụng",
            }))
          );
        })
        .catch((err) => {});
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        fetchAllSalePrice,
        fetchAllProcedure,
        fetchAllProductCost,
        match,
        setTypeAll,
      } = this.props;
      switch (match.path) {
        case PATH.ALL_FARM_PRODUCT_COST_PATH:
          fetchAllProductCost();
          setTypeAll("product_cost");
          break;
        case PATH.ALL_FARM_SALE_PRICE_PATH:
          fetchAllSalePrice();
          setTypeAll("sale_price");
          break;
        case PATH.ALL_FARM_PACKAGING_COST_PATH:
          fetchAllProcedure();
          setTypeAll("sale_price");
          break;

        default:
          break;
      }
    },
  })
);
