import React from "react";

import {Card, message, Steps, Divider, Button} from "antd";
import _ from "lodash";
import {UIButton} from "~/views/presentation/ui/buttons";

// redux
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose, lifecycle, withHandlers} from "recompose";
import {LOGIN_PATH} from "~/configs/routesConfig";
import strings from "~/localization";

import FarmSeasonDone from "./FarmSeasonDone";
import FarmSeasonInputForms from "./FarmSeasonInputForms";
import ProcedureForms from "./ProcedureForms";
// component
import SeasonInfoForms from "./SeasonInfoForms";
import {getArray, getNumber, getObject, getString} from "~/views/utilities/helpers/utilObject";

const {Step} = Steps;

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const scrollToBottom = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: "smooth",
  });
};

class FarmSeasonCRUDForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  handleNext = () => {
    if (this.state.current === 3) {
      this.props.onCreate && this.props.onCreate();
    } else if (this.state.current <= 2) {
      this.setState({current: this.state.current + 1});
    }
  };

  onChange = (current) => {
    const {info} = this.props;
    /**
     * Điều kiện cho next là:
     * cropsList.length !== 0
     * seasonProcesses: processId and startDate is valid(require);
     * classifications validateOption and validateValue lasted is undefined
     * Object.keys(errors).length !== 0
     *  if do not: message error when can
     */
    let cropListTemp = getArray(info, "cropsList", []);
    let processTemp = getArray(info, "seasonProcesses", []);
    let classificationsTemp = getArray(info, "classifications", []);
    let errorsTemp = getObject(info, "errors", {});
    let errorsValidate = getObject(info, "errorsValidate", {});
    if (cropListTemp.length === 0) {
      message.error(strings.need_at_least_one_crop_for_this_season, 3);
      scrollToBottom();
      return;
    }
    if (Object.keys(errorsValidate).length !== 0) {
      if (!_.isEmpty(errorsValidate)) {
        message.error(Object.values(errorsValidate)[0], 3);
        scrollToTop();
        return;
      }
    }
    if (Object.keys(errorsTemp).length !== 0) {
      if (!_.isEmpty(errorsTemp)) {
        message.error(Object.values(errorsTemp)[0], 3);
        scrollToTop();
        return;
      }
    }

    if (processTemp.length !== 0) {
      for (const v of processTemp) {
        if (getNumber(v, "processId", 0) <= 0) {
          message.error(strings.please_select_a_process_name, 3);
          return;
        }
        if (getString(v, "startDate", "") === "") {
          message.error(strings.please_select_a_start_date, 3);
          return;
        }
      }
    }
    if (classificationsTemp.length !== 0)
      if (
        classificationsTemp[classificationsTemp.length - 1][`validateOption${classificationsTemp.length - 1}`] !== undefined ||
        classificationsTemp[classificationsTemp.length - 1][`validateValue${classificationsTemp.length - 1}`] !== undefined
      ) {
        message.error(strings.please_enter_a_valid_percent_allocation, 3);
        scrollToTop();
        return;
      }
    if (classificationsTemp.length === 0) {
      message.error(strings.please_enter_a_valid_percent_allocation, 3);
      scrollToTop();
      return false;
    }
    this.setState({current});
  };

  renderStepContent = () => {
    const {info, editable, saveFarmSeasonDone = true, farmProfiles, farmProfile} = this.props;
    switch (this.state.current) {
      case 0:
        return <SeasonInfoForms editable={editable} farmProfile={farmProfile} info={info} nextStep={this.onChange} profiles={farmProfiles} />;
      case 1:
        return <ProcedureForms editable={editable} farmProfile={farmProfile} info={info} nextStep={this.onChange} profiles={farmProfiles} />;
      case 2:
        return <FarmSeasonInputForms editable={editable} farmProfile={farmProfile} info={info} nextStep={this.onChange} profiles={farmProfiles} />;

      case 3:
        return (
          <FarmSeasonDone
            farmProfile={farmProfile}
            info={info}
            onCreate={() => this.props.onCreate()}
            profiles={farmProfiles}
            saveFarmSeasonDone={saveFarmSeasonDone}
          />
        );
      default:
        return;
    }
  };

  componentDidMount() {
    if (!this.props.user) {
      this.props.history.push(LOGIN_PATH);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user) {
      this.props.history.push(LOGIN_PATH);
    }
  }

  render() {
    const {current} = this.state;
    const {showHeader = true, showFooter = true} = this.props;

    return (
      <div style={{paddingBottom: "20px"}}>
        <Card title={showHeader ? strings.farm_setup : undefined} bordered={false}>
          <Steps current={current} onChange={this.onChange}>
            <Step title={strings.information_general} />
            <Step title={strings.procedure} />
            <Step title={strings.farm_season_input} />
            <Step title={strings.completed} />
          </Steps>
          <Divider className='mb-5' />
          {this.renderStepContent()}
        </Card>
        {showFooter && (
          <div className='d-flex justify-content-end mb-5' style={{paddingRight: "24px"}}>
            {this.state.current !== 3 && (
              <Button type='dashed' onClick={this.handleNext}>
                {strings.skip_setup}
              </Button>
            )}
            <UIButton type='primary' className='ml-2' onClick={this.handleNext} htmlType='button'>
              <span>{this.state.current === 3 ? strings.completed : strings.continue}</span>
            </UIButton>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      user: state["authUser"].user,
    }),
    {}
  ),
  withRouter,
  withHandlers({}),
  lifecycle({})
)(FarmSeasonCRUDForm);
