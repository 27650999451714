import styled from "styled-components";
import COLOR from "../utilities/layout/color";

const StepsFormContainerStyle = styled.div`
  display: flex;
  .profile-view {
    padding: 24px 16px 8px 16px;
    overflow-x: scroll;
    position: absolute;
    width: calc(100% - 450px);
    ::-webkit-scrollbar {
      width: 0px;
    }
    @media (max-width: 1200px) {
      width: 100%;
    }
    left: 0px;
  }
`;

const GridStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 9px !important;

  // .ENTERPRISE {
  //   color: ${COLOR.white};
  //   background-color: ${COLOR.yellowGreen};
  // }

  .none-menu-item {
    -webkit-transition: all 0.5s 0s ease;
    -moz-transition: all 0.5s 0s ease;
    -o-transition: all 0.5s 0s ease;
    transition: all 0.5s 0s ease;
    width: calc(100% / 6 - 10px);
    @media (max-width: 2048px) {
      width: calc(100% / 5 - 10px);
    }
    @media (max-width: 1664px) {
      width: calc(100% / 4 - 10px);
    }
    @media (max-width: 1280px) {
      width: calc(100% / 3 - 10px);
    }
    @media (max-width: 896px) {
      width: calc(100% / 2 - 10px);
    }
    @media (max-width: 687px) {
      width: calc(100% - 10px);
    }
    margin: 0px 5px 10px 5px;
  }
  .item {
    -webkit-transition: all 0.5s 0s ease;
    -moz-transition: all 0.5s 0s ease;
    -o-transition: all 0.5s 0s ease;
    transition: all 0.5s 0s ease;
    width: calc(100% / 5 - 10px);
    @media (max-width: 2048px) {
      width: calc(100% / 4 - 10px);
    }
    @media (max-width: 1664px) {
      width: calc(100% / 3 - 10px);
    }
    @media (max-width: 1280px) {
      width: calc(100% / 2 - 10px);
    }
    @media (max-width: 896px) {
      width: calc(100% - 10px);
    }
    margin: 0px 5px 10px 5px;
  }

  div .item-container {
    transition: box-shadow 0.3s;
    -webkit-transition: all 0.5s 0s ease;
    -moz-transition: all 0.5s 0s ease;
    -o-transition: all 0.5s 0s ease;
    transition: all 0.5s 0s ease;
    cursor: pointer;
    min-height: 180px;
    background-color: #fff;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    .ht-avatar {
      -webkit-transition: all 0.5s 0s ease;
      -moz-transition: all 0.5s 0s ease;
      -o-transition: all 0.5s 0s ease;
      transition: all 0.5s 0s ease;
    }
    .left {
      .ant-avatar {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        border: 1px solid #c3c3c3;
        overflow: hidden;
      }
      p {
        text-align: center;
      }
    }
    .right {
      flex: 1;
      width: 75%;
      padding: 0px 8px 0px 8px;
      div .title::after {
        content: ": ";
      }
      div .title {
        min-width: 95px;
      }
      div .value {
        font-weight: 500;
      }
      div p {
        margin-bottom: 0px;
      }
      div {
        margin-bottom: 10px;
        display: flex;
      }
      h4 {
        height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .ht-icon {
      font-size: 1.3em;
      letter-spacing: 0;
      word-spacing: 0;
      color: #6d6d6d;
      font-weight: normal;
      text-decoration: none solid rgb(68, 68, 68);
      font-style: normal;
      font-variant: normal;
      text-transform: none;
    }
    button {
      position: absolute;
      right: 15px;
      top: 0px;
      height: 40px;
      width: 40px;
      i {
        font-size: 20px;
      }
    }
    button:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;

const ProfileType = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    padding: 0px 5px 0px 5px;
    border-radius: 5px;
    span {
      color: #fff;
    }
  }
`;

export { StepsFormContainerStyle, ProfileType, GridStyled };
