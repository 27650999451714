import { Form } from "antd";
import { withFormik } from "formik";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import styled from "styled-components";
import * as yup from "yup";
import { PUBLISHSTATUS } from "~/configs/const";
import { showError } from "~/configs/ServerErrors";
import strings from "~/localization";
import {
  getActual,
  getAllDistricsOfProvin,
  getAllProvinOfRegion,
  getAllPublishCostconfig,
  getAllPublishServiceLists,
  getAllRegions,
  getAllWardOf,
  getDistrict,
  getExpected,
  getMassUnits,
  getProvinces,
  getType,
  getWards,
} from "~/state/ducks/appApis/actions";
import {
  AutoComplete,
  CheckboxField,
  DatePickerField,
  InputField,
  SelectField,
} from "~/views/presentation/ui/fields";
import Loading from "~/views/presentation/ui/loading";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import {
  getArray,
  getBool,
  getNumber,
  getObject,
  getString,
} from "~/views/utilities/helpers/utilObject";
import Color from "~/views/utilities/layout/color";
import {
  emailNRValidate,
  numberValidate,
  phoneNRValidate,
  stringNRFieldValidate,
} from "~/views/utilities/validation/input";
import TableEditable from "./TableForm";

const Wrapred = styled.div`
.ant-select-selection-item-content{
  margin-right: 3px !important;
}
  h2 {
    margin: unset;
  }
  .flex-center: {
    display: flex;
    align-items: center;
  }

  .ant-col.ant-form-item-label {
    label {
      width: fit-content;
    }
  }
  .row{
  }
  table {

    td{
      min-width: 150px !important;
    }
    td:nth-child(1){
      min-width: 33px !important;

    }
    td:nth-child(2){
      min-width: 60px !important;

    }
    th{
      white-space: unset !important;

    }
    width: 2500px;

      display: block;
    white-space: unset !important;
    .ant-table-cell{
      span{
        white-space: nowrap !important;
      }
    }
  }
  .overflow-auto::-webkit-scrollbar-track {
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    	background-color: #F5F5F5;
  }

  .overflow-auto::-webkit-scrollbar {
    width: 3px;
    height:8px;
    background-color: #F5F5F5;
  }
  }
  th{
    // outline: 1px solid black;

  }
  .overflow-auto::-webkit-scrollbar-thumb:horizontal {
    background-color: #000000;

  }
  table thead tr:nth-child(1) th{
    // border-top: 1px solid black;

  }
  .none-table{
    table tbody tr:last-child{
      display:none;
    }
  }

  .ant-table-footer {
    width: 2500px;
  }
  .ant-col.ant-form-item-control{
  }
  .ant-col.ant-form-item-label{
    display: flex;
    align-items: center;
}
.check{
  .ant-form-item{
    margin-bottom:0px !important;
  }
  .ant-form-item-control-input{
    justify-content: flex-end !important;
  }
  .ant-form-item-control-input-content {
    -ms-flex: auto;
    flex: unset !important;
  }
    
}


.col-lg-2dot4{
  padding: 0px 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;

  @media(max-width: 1162px){
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-2dot3{
  padding: 0px 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 19%;
  flex: 0 0 19%;
  max-width: 19%;
  @media(max-width: 1162px){
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-2dot5{
  padding: 0px 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 21%;
  flex: 0 0 21%;
  max-width: 21%;
  @media(max-width: 1162px){
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.color-row{
  background-color:#fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    h3{
      font-weight: 700;
      text-align: center;
      margin:0 !important;
      color:red;
    }
}

`;
const validationSchema = yup.object().shape({
  // name_organization: stringRequiredField,
  name_organization: stringNRFieldValidate(),
  cultivation: stringNRFieldValidate(),
  transaction: stringNRFieldValidate(),
  surrogate: stringNRFieldValidate(),
  phone: phoneNRValidate,
  email: emailNRValidate,
  website: stringNRFieldValidate(),
  role: stringNRFieldValidate(),
  address: stringNRFieldValidate(),
  town_village: stringNRFieldValidate(),
  Km: numberValidate,
  timeShell: numberValidate,
  name: stringNRFieldValidate(),
  name: stringNRFieldValidate(),
  name: stringNRFieldValidate(),
});
class FromProcedure extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      typeExpect: "ACTUAL",
      data: {},
      productName: "",
      dataReal: [],
      option: [],
    };
  }
  mapStatus(status) {
    switch (status) {
      case PUBLISHSTATUS.CREATING:
        return strings.creating;
      case PUBLISHSTATUS.WAITING_FOR_APPROVAL:
        return strings.waiting_for_approval;
      case PUBLISHSTATUS.DELETED:
        return strings.deleted;
      case PUBLISHSTATUS.DEACTIVATED:
        return strings.deactive;
      case PUBLISHSTATUS.ACTIVATED:
        return strings.actived;
      case PUBLISHSTATUS.APPROVED:
        return strings.approved;
      case PUBLISHSTATUS.REJECTED:
        return strings.rejected;
      case PUBLISHSTATUS.CONNECTED:
        return strings.conected;
      case PUBLISHSTATUS.EXPIRED:
        return strings.expired;
      default:
        return strings.creating;
    }
  }

  componentDidMount() {
    this._handleGetActual();
  }
  updateDataSource = (prvDataSource) => (dataSource) => {
    while (prvDataSource.length) {
      prvDataSource.shift();
    }
    dataSource.forEach((item) => {
      prvDataSource.push(item);
    });
  };
  componentDidUpdate(props) {
    const typeExpectnext = getString(props.info, "typeExpect", "");
    const typeExpect = getString(this.props.info, "typeExpect", "");
    if (typeExpectnext !== typeExpect) {
      if (typeExpect === "ACTUAL") {
        this._handleGetActual(true);
      }
      if (typeExpect === "EXPECTED") {
        this._handleGetExpected(true);
      }
    }
  }
  _handleGetExpected(check) {
    const { match } = this.props;
    this.setState({ loading: true });
    const idSeason = getString(match, "params.idSeason");
    const { getExpected } = this.props;
    if (idSeason)
      getExpected(idSeason)
        .then(({ res }) => {
          const productName = getString(res, "product.name");
          const dataReal = getArray(res, "expectedHarvest", []);
          const option = getArray(res, "expectedHarvest", []);
          if (!check)
            this.props.info.product = [
              {
                productName: productName,
                key: 0,
                STT: 1,
                total: 0,
                toPriceShell: 0,
                const_serviceToMoney: 0,
              },
            ];

          this.setState({ loading: false, dataReal, productName, option });
        })
        .catch((err) => {
          this.setState({ loading: false });

          console.error(err);
        });
  }
  _handleGetActual(check) {
    const { match } = this.props;
    this.setState({ loading: true });

    const idSeason = getString(match, "params.idSeason");
    const { getActual } = this.props;
    if (idSeason)
      getActual(idSeason)
        .then(({ res }) => {
          const productName = getString(res, "product.name");
          const dataReal = getArray(res, "actualHarvest", []);
          const option = getArray(res, "actualHarvest", []);
          if (!check)
            this.props.info.product = [
              {
                productName: productName,
                key: 0,
                STT: 1,
                total: 0,
                toPriceShell: 0,
                const_serviceToMoney: 0,
              },
            ];

          this.setState({ loading: false, dataReal, productName, option });
        })
        .catch((err) => {
          this.setState({ loading: false });

          console.error(err);
        });
  }
  _handleChangeCheckBox = (e) => {
    const { setFieldValue } = this.props;
    const { values } = this.props;
    setFieldValue(e.target.name, !values[e.target.name]);
    let serviceIds = getArray(values, "serviceIds", []);
    let item = (serviceIds || []).find(
      (val) => val === parseInt(e.target.name)
    );
    if (item) {
      setFieldValue(
        "serviceIds",
        serviceIds.filter((val) => val !== parseInt(e.target.name))
      );
      this.props.info["serviceIds"] = serviceIds.filter(
        (val) => val !== parseInt(e.target.name)
      );
    } else {
      serviceIds.push(parseInt(e.target.name));
      this.props.info["serviceIds"] = serviceIds;

      setFieldValue("serviceIds", serviceIds);
    }
  };
  handleChangeInputValue = (e) => {
    const { handleChange } = this.props;
    handleChange(e);
    let name = e.target.name;
    this.props.info[name] = e.target.value;
  };
  UNSAFE_componentWillReceiveProps(nextProps) {}
  _handleGetDefaultValue(array) {
    if (array.length) {
      return getString(array[0], "value", "");
    }
    return "";
  }
  handleChangeAutoCompelete = (name, value) => {
    const { setFieldValue, setFieldTouched } = this.props;
    setFieldValue(name, value);

    setFieldTouched(name, true);
    this.props.info[name] = value;
  };
  render() {
    const {
      values,
      handleBlur,
      acreAge,
      touched,
      ward,
      provin,
      config,
      deliver,
      district,
      ward_register,
      service,
      provin_register,
      district_register,
      errors,
      cultivationOption,
      trading_rangeOption,
      setFieldValue,
      setFieldTouched,
      handleChangeDate,
    } = this.props;
    const { editable } = this.props;
    this.props.info.errors = errors;

    let dateShell = moment
      .utc(values.dateShell, UtilDate.formatDateTimeServer)
      .local();

    return (
      <Wrapred>
        <Form>
          <div className="row">
            <div className="col-lg-3 col-md-12 w-100">
              <SelectField
                disabled={!editable}
                validateStatus={
                  touched.typeExpect && errors.typeExpect ? "error" : undefined
                }
                help={
                  touched.typeExpect && errors.typeExpect
                    ? errors.typeExpect
                    : ""
                }
                data={[
                  { value: "EXPECTED", label: strings.get_expected },
                  { value: "ACTUAL", label: strings.get_actual },
                ]}
                value={values.typeExpect}
                onChange={(value) => {
                  setFieldValue("typeExpect", value);

                  if (value === "ACTUAL") {
                    this._handleGetActual();
                  }
                  if (value === "EXPECTED") {
                    this._handleGetExpected();
                  }
                }}
                iconEnd="caret-down"
                placeholder={strings.type_chose_product}
                label={strings.type_chose_product}
              />
            </div>
          </div>
          <div className="row color-row">
            <div className="col-lg-3 col-md-12">
              <InputField
                disabled={!editable}
                validatestatus={
                  touched.name && errors.name ? "error" : undefined
                }
                help={touched.name && errors.name ? errors.name : ""}
                name="name"
                bordered={false}
                suffix="/CM   /NS"
                value={values.code}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={"01"}
                label={strings.offering_number}
              />
            </div>
            <div className="col-lg-6 col-md-12 ">
              <h3>{strings.seller_registration_ship}</h3>
            </div>
            <div className="col-lg-3 col-md-12">
              <DatePickerField
                disabled={!editable}
                // defaultValue={values.dateShell || moment()}
                // disabled={!editable}
                validateStatus={
                  touched.dateShell && errors.dateShell ? "error" : undefined
                }
                help={
                  touched.dateShell && errors.dateShell ? errors.dateShell : ""
                }
                name="dateShell"
                dateShell
                value={dateShell.isValid() ? dateShell : undefined}
                onChange={handleChangeDate(this.props)("dateShell")}
                onBlur={handleBlur}
                label={strings.date_sale}
                placeholder={strings.date_sale}
                disabledDate={(current) =>
                  current && current < moment().endOf("day")
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3"></div>
            <div className="col-2"></div>
            <div className="col-2"></div>
            <div className="col-2"></div>
            <div className="col-lg-3 col-md-12">
              <InputField
                disabled={!editable}
                validatestatus={
                  touched.numberWeek && errors.numberWeek ? "error" : undefined
                }
                help={
                  touched.numberWeek && errors.numberWeek
                    ? errors.numberWeek
                    : ""
                }
                name="numberWeek"
                bordered={false}
                // defaultValue={values.numberWeek || moment(moment()).isoWeek()}

                value={values.numberWeek}
                // onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={strings.week_number}
                label={strings.week_number}
              />
            </div>
          </div>
          <div className="row color-row">
            <div className="col-lg-3 col-md-12">
              <InputField
                disabled={!editable}
                validatestatus={
                  touched.name && errors.name ? "error" : undefined
                }
                help={touched.name && errors.name ? errors.name : ""}
                name="name"
                bordered={false}
                value={values.salerCode}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={strings.seller_code_number}
                label={strings.seller_code_number}
              />
            </div>
            <div className="col-lg-2 col-md-12">
              <AutoComplete
                disabled={!editable}
                options={values.option_name_organization || []}
                validatestatus={
                  touched.name_organization && errors.name_organization
                    ? "error"
                    : undefined
                }
                help={
                  touched.name_organization && errors.name_organization
                    ? errors.name_organization
                    : ""
                }
                name="name_organization"
                value={values.name_organization}
                onChange={(e) =>
                  this.handleChangeAutoCompelete("name_organization", e)
                }
                onBlur={handleBlur}
                placeholder={strings.name_of_the_offering_organization}
                label={strings.name_of_the_offering_organization}
              />
            </div>
            <div className="col-lg-2 col-md-12"></div>
            <div className="col-lg-2 col-md-12">
              <AutoComplete
                disabled={!editable}
                options={cultivationOption || []}
                validatestatus={
                  touched.cultivation && errors.cultivation
                    ? "error"
                    : undefined
                }
                help={
                  touched.cultivation && errors.cultivation
                    ? errors.cultivation
                    : ""
                }
                name="cultivation"
                value={values.cultivation}
                onChange={(e) =>
                  this.handleChangeAutoCompelete("cultivation", e)
                }
                onBlur={handleBlur}
                placeholder={strings.type_of}
                label={strings.type_of}
              />
            </div>
            <div className="col-lg-3 col-md-12">
              <SelectField
                disabled={!editable}
                validateStatus={
                  touched.transaction && errors.transaction
                    ? "error"
                    : undefined
                }
                help={
                  touched.transaction && errors.transaction
                    ? errors.transaction
                    : ""
                }
                data={[
                  { label: strings.sell_human, value: "SELL" },
                  { label: strings.buy_human, value: "BUY" },
                ]}
                value={values.transaction}
                onChange={(value) => {
                  setFieldValue("transaction", value);
                  setFieldTouched("transaction");
                  this.props.info["transaction"] = value;
                  // this.props.info.address["wardsId"] = value;
                  // this.props.info.wardsId = value;
                }}
                iconEnd="caret-down"
                placeholder={strings.transaction_classification}
                label={strings.transaction_classification}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-12">
              <AutoComplete
                disabled={!editable}
                options={values.option_surrogate || []}
                validatestatus={
                  touched.surrogate && errors.surrogate ? "error" : undefined
                }
                help={
                  touched.surrogate && errors.surrogate ? errors.surrogate : ""
                }
                name="surrogate"
                value={values.surrogate}
                onChange={(e) => this.handleChangeAutoCompelete("surrogate", e)}
                onBlur={handleBlur}
                placeholder={strings.name_of_personal_representative}
                label={strings.name_of_personal_representative}
              />
            </div>
            <div className="col-lg-2 col-md-12">
              <AutoComplete
                disabled={!editable}
                options={values.option_phone || []}
                validatestatus={
                  touched.phone && errors.phone ? "error" : undefined
                }
                help={touched.phone && errors.phone ? errors.phone : ""}
                name="phone"
                value={values.phone}
                onChange={(e) => this.handleChangeAutoCompelete("phone", e)}
                onBlur={handleBlur}
                placeholder={strings.DTDD_number}
                label={strings.DTDD_number}
              />
            </div>
            <div className="col-lg-2 col-md-12">
              <AutoComplete
                disabled={!editable}
                options={values.option_email || []}
                validatestatus={
                  touched.email && errors.email ? "error" : undefined
                }
                help={touched.email && errors.email ? errors.email : ""}
                name="email"
                value={values.email}
                onChange={(e) => this.handleChangeAutoCompelete("email", e)}
                onBlur={handleBlur}
                placeholder={strings.email}
                label={strings.email}
              />
            </div>
            <div className="col-lg-2 col-md-12">
              <InputField
                disabled={!editable}
                validatestatus={
                  touched.website && errors.website ? "error" : undefined
                }
                help={touched.website && errors.website ? errors.website : ""}
                name="website"
                bordered={false}
                value={values.website}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={strings.website}
                label={strings.website}
              />
            </div>
            <div className="col-lg-3 col-md-12">
              <InputField
                disabled={!editable}
                validatestatus={
                  touched.role && errors.role ? "error" : undefined
                }
                help={touched.role && errors.role ? errors.role : ""}
                name="role"
                bordered={false}
                value={values.role}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={strings.transaction_role}
                label={strings.transaction_role}
              />
            </div>
          </div>
          <div className="row color-row">
            <div className="col-lg-3 col-md-12">
              <AutoComplete
                disabled={!editable}
                options={values.option_adress || []}
                validatestatus={
                  touched.address && errors.address ? "error" : undefined
                }
                help={touched.address && errors.address ? errors.address : ""}
                name="address"
                value={values.address}
                onChange={(e) => this.handleChangeAutoCompelete("address", e)}
                onBlur={handleBlur}
                placeholder={strings.sales_address}
                label={strings.sales_address}
              />
            </div>
            <div className="col-lg-2 col-md-12">
              <InputField
                disabled={!editable}
                validatestatus={
                  touched.town_village && errors.town_village
                    ? "error"
                    : undefined
                }
                help={
                  touched.town_village && errors.town_village
                    ? errors.town_village
                    : ""
                }
                name="town_village"
                bordered={false}
                value={values.town_village}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={strings.town_village}
                label={strings.town_village}
              />
            </div>
            <div className="col-lg-2 col-md-12">
              <SelectField
                disabled={!editable}
                validateStatus={
                  touched.wardsId && errors.wardsId ? "error" : undefined
                }
                help={touched.wardsId && errors.wardsId ? errors.wardsId : ""}
                data={this.props.ward || []}
                value={values.wardsId}
                onChange={(value) => {
                  setFieldValue("wardsId", value);
                  setFieldTouched("wardsId");
                  // this.props.info.address["wardsId"] = value;
                  // this.props.info.wardsId = value;
                }}
                iconEnd="caret-down"
                placeholder={strings.ward}
                label={strings.ward}
              />
            </div>
            <div className="col-lg-2 col-md-12">
              <SelectField
                disabled={!editable}
                validateStatus={
                  touched.districtId && errors.districtId ? "error" : undefined
                }
                help={
                  touched.districtId && errors.districtId
                    ? errors.districtId
                    : ""
                }
                data={district || []}
                value={values.districtId}
                onChange={(value) => {
                  this.props.fechWard(value);
                  setFieldValue("districtId", value);
                  setFieldValue("wardsId", undefined);
                  // this.props.info.address["districtId"] = value;
                  // this.props.info.districtId = value;
                  setFieldTouched("districtId");
                }}
                iconEnd="caret-down"
                placeholder={strings.district}
                label={strings.district}
              />
            </div>
            <div className="col-lg-3 col-md-12">
              <SelectField
                disabled={!editable}
                validateStatus={
                  touched.provinceId && errors.provinceId ? "error" : undefined
                }
                help={
                  touched.provinceId && errors.provinceId
                    ? errors.provinceId
                    : ""
                }
                data={provin || []}
                value={values.provinceId}
                onChange={(value) => {
                  setFieldTouched("provinceId");
                  setFieldValue("provinceId", value);
                  setFieldValue("districtId", undefined);
                  setFieldValue("wardsId", undefined);

                  // this.props.info.address["provinceId"] = value;
                  // this.props.info.provinceId = value;
                  this.props.fechDistrict(value);
                }}
                iconEnd="caret-down"
                placeholder={strings.city_or_province}
                label={strings.city_or_province}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-12">
              <SelectField
                disabled={!editable}
                validateStatus={
                  touched.certifications && errors.certifications
                    ? "error"
                    : undefined
                }
                help={
                  touched.certifications && errors.certifications
                    ? errors.provcertificationsinceId
                    : ""
                }
                stringMode
                mode="multiple"
                showSearch={false}
                onChange={(value) => {
                  setFieldTouched("certifications");
                  setFieldValue("certifications", value);
                }}
                data={""}
                value={values.certifications}
                // disabled
                iconEnd="caret-down"
                placeholder={strings.standard_of_cultivation}
                label={strings.standard_of_cultivation}
              />
              {/* <AutoComplete disabled={!editable}
                disabled={true}
                options={values.option_certifications || []}
                validatestatus={
                  touched.certifications && errors.certifications
                    ? "error"
                    : undefined
                }
                help={
                  touched.certifications && errors.certifications
                    ? errors.certifications
                    : ""
                }
                name="certifications"
                value={values.certifications}
                onChange={(e) =>
                  this.handleChangeAutoCompelete("certifications", e)
                }
                onBlur={handleBlur}
                placeholder={strings.standard_of_cultivation}
                label={strings.standard_of_cultivation}
              /> */}
            </div>
            <div className="col-lg-2 col-md-12">
              <div className="row">
                <div className="col-7 pr-2">
                  <InputField
                    disabled={true}
                    validatestatus={
                      touched.grossArea && errors.grossArea
                        ? "error"
                        : undefined
                    }
                    help={
                      touched.grossArea && errors.grossArea
                        ? errors.grossArea
                        : ""
                    }
                    name="grossArea"
                    // min={0}
                    // max={9223372036854775807}
                    type="number"
                    value={values.grossArea}
                    onChange={this.handleChangeInputValue}
                    onBlur={handleBlur}
                    placeholder={strings.acreage_farming}
                    label={strings.acreage_farming}
                  />
                </div>
                <div className="col-5 pl-2">
                  <SelectField
                    disabled={!editable}
                    validateStatus={
                      touched.unitId && errors.unitId ? "error" : undefined
                    }
                    help={touched.unitId && errors.unitId ? errors.unitId : ""}
                    data={acreAge || []}
                    value={
                      values.unitId
                        ? values.unitId
                        : acreAge.length
                        ? acreAge[0].value
                        : undefined
                    }
                    onChange={(value) => {
                      this.props.info.unitId = value;
                      setFieldValue("unitId", value);
                      setFieldTouched("unitId");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.unit}
                    label={strings.unit}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-12">
              <div className="row">
                <div className="col-lg-8 col-md-12 pr-2">
                  <AutoComplete
                    disabled={!editable}
                    options={trading_rangeOption || []}
                    validatestatus={
                      touched.trading && errors.trading ? "error" : undefined
                    }
                    help={
                      touched.trading && errors.trading ? errors.trading : ""
                    }
                    name="trading"
                    value={values.trading}
                    onChange={(e) =>
                      this.handleChangeAutoCompelete("trading", e)
                    }
                    onBlur={handleBlur}
                    placeholder={strings.scope_transaction_registration}
                    label={strings.scope_transaction_registration}
                  />
                </div>
                <div className="col-lg-4 col-md-12 pl-2">
                  <InputField
                    disabled={!editable}
                    validatestatus={
                      touched.Km && errors.Km ? "error" : undefined
                    }
                    help={touched.Km && errors.Km ? errors.Km : ""}
                    name="Km"
                    type="number"
                    bordered={false}
                    value={values.Km}
                    onChange={this.handleChangeInputValue}
                    onBlur={handleBlur}
                    placeholder={"Km"}
                    label={strings.BK}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-12">
              <InputField
                disabled={!editable}
                validatestatus={
                  touched.timeShell && errors.timeShell ? "error" : undefined
                }
                help={
                  touched.timeShell && errors.timeShell ? errors.timeShell : ""
                }
                name="timeShell"
                type="number"
                bordered={false}
                value={values.timeShell}
                onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={strings.time_to_sell}
                label={strings.time_to_sell}
              />
            </div>
            <div className="col-lg-3 col-md-12">
              <SelectField
                disabled={!editable}
                validateStatus={
                  touched.registerNation && errors.registerNation
                    ? "error"
                    : undefined
                }
                help={
                  touched.registerNation && errors.registerNation
                    ? errors.registerNation
                    : ""
                }
                data={[
                  { label: strings.not, value: false },
                  { label: strings.have, value: true },
                ]}
                value={values.registerNation}
                onChange={(value) => {
                  setFieldTouched("registerNation");
                  setFieldValue("registerNation", value);
                  this.props.info["isNationwide"] = value;
                  if (value) {
                    this.props.info["regionsIds"] = null;
                    this.props.info["provinceIds"] = null;
                    this.props.info["districtIds"] = null;
                    this.props.info["wardsIds"] = null;
                    setFieldValue("region_reginter", undefined);
                    setFieldValue("provinceId_register", undefined);
                    setFieldValue("districtId_register", undefined);
                    setFieldValue("wardsId_register", undefined);
                  }
                }}
                iconEnd="caret-down"
                placeholder={strings.register_to_sell_nationwide}
                label={strings.register_to_sell_nationwide}
              />
            </div>
          </div>

          <div className="row color-row">
            <div className="col-lg-3 col-md-12">
              <SelectField
                validateStatus={
                  touched.region_reginter && errors.region_reginter
                    ? "error"
                    : undefined
                }
                help={
                  touched.region_reginter && errors.region_reginter
                    ? errors.region_reginter
                    : ""
                }
                mode="multiple"
                data={this.props.regions || []}
                value={values.region_reginter}
                disabled={!editable || values.registerNation}
                onChange={(value) => {
                  this.props.info["regionsIds"] = value;
                  setFieldTouched("region_reginter");
                  setFieldValue("region_reginter", value);
                  this.props.fetchAllProvinOfRegion(value.join(","));
                  this.props.info["provinceIds"] = null;
                  this.props.info["districtIds"] = null;
                  this.props.info["wardsIds"] = null;
                  setFieldValue("provinceId_register", undefined);
                  setFieldValue("districtId_register", undefined);
                  setFieldValue("wardsId_register", undefined);

                  // setFieldValue("districtId_register", undefined);
                  // setFieldValue("wardsId_register", undefined);
                  // this.props.fechDistrict(value, true);
                }}
                iconEnd="caret-down"
                placeholder={strings.register_to_sell_by_region}
                label={strings.register_to_sell_by_region}
              />
            </div>
            <div className="col-lg-2 col-md-12">
              <SelectField
                validateStatus={
                  touched.provinceId_register && errors.provinceId_register
                    ? "error"
                    : undefined
                }
                help={
                  touched.provinceId_register && errors.provinceId_register
                    ? errors.provinceId_register
                    : ""
                }
                mode="multiple"
                maxTagCount={1}
                data={provin_register || []}
                value={values.provinceId_register}
                disabled={!editable || values.registerNation}
                onChange={(value) => {
                  setFieldTouched("provinceId_register");
                  setFieldValue("provinceId_register", value);
                  setFieldValue("districtId_register", undefined);
                  setFieldValue("wardsId_register", undefined);
                  this.props.info["districtIds"] = null;
                  this.props.info["wardsIds"] = null;
                  this.props.info["provinceIds"] = value;
                  this.props.fetchAllDistricsOfProvin(value.join(","));

                  // this.props.info.address["provinceId"] = value;
                  // this.props.info.provinceId = value;
                  // this.props.fechDistrict(value, true);
                }}
                iconEnd="caret-down"
                placeholder={strings.register_to_sell_in_city_province}
                label={strings.register_to_sell_in_city_province}
              />
            </div>
            <div className="col-lg-2 col-md-12">
              <SelectField
                disabled={!editable || values.registerNation}
                maxTagCount={1}
                validateStatus={
                  touched.districtId_register && errors.districtId_register
                    ? "error"
                    : undefined
                }
                help={
                  touched.districtId_register && errors.districtId_register
                    ? errors.districtId_register
                    : ""
                }
                data={district_register || []}
                mode="multiple"
                value={values.districtId_register}
                onChange={(value) => {
                  this.props.info["districtIds"] = value;
                  this.props.info["wardsIds"] = null;
                  this.props.fetchAllWardOfProvine(value.join(","));
                  setFieldValue("districtId_register", value);
                  setFieldValue("wardsId_register", undefined);
                  // this.props.info.address["districtId"] = value;
                  // this.props.info.districtId = value;
                  setFieldTouched("districtId_register");
                }}
                iconEnd="caret-down"
                placeholder={strings.register_to_sell_in_district}
                label={strings.register_to_sell_in_district}
              />
            </div>
            <div className="col-lg-2 col-md-12">
              <SelectField
                disabled={!editable || values.registerNation}
                maxTagCount={1}
                validateStatus={
                  touched.wardsId_register && errors.wardsId_register
                    ? "error"
                    : undefined
                }
                help={
                  touched.wardsId_register && errors.wardsId_register
                    ? errors.wardsId_register
                    : ""
                }
                mode="multiple"
                data={ward_register || []}
                value={values.wardsId_register}
                onChange={(value) => {
                  this.props.info["wardsIds"] = value;
                  setFieldValue("wardsId_register", value);
                  setFieldTouched("wardsId_register");
                  // this.props.info.address["wardsId"] = value;
                  // this.props.info.wardsId = value;
                }}
                iconEnd="caret-down"
                placeholder={strings.register_to_sell_in_ward_commune}
                label={strings.register_to_sell_in_ward_commune}
              />
            </div>
            <div className="col-lg-3 col-md-12">
              <InputField
                disabled={!editable}
                validatestatus={
                  touched.puplistStatus && errors.puplistStatus
                    ? "error"
                    : undefined
                }
                help={
                  touched.puplistStatus && errors.puplistStatus
                    ? errors.puplistStatus
                    : ""
                }
                name="puplistStatus"
                bordered={false}
                value={this.mapStatus(values.puplistStatus)}
                // onChange={this.handleChangeInputValue}
                onBlur={handleBlur}
                placeholder={strings.connection_results}
                label={strings.connection_results}
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-3 col-md-12"></div>
            <div className="col-lg-2 col-md-12"></div>
            <div className="col-lg-2 col-md-12"></div>
            <div className="col-lg-2 col-md-12"></div>
            <div className="col-lg-3 col-md-12"></div>
          </div> */}

          <div className="row ">
            <TableEditable
              onChange={this.updateDataSource(this.props.info.product)}
              dataReal={
                this.props.view ? this.props.info.product : this.state.dataReal
              }
              option={this.state.option || []}
              info={this.props.info}
              dataSrc={this.props.info.product || []}
              editable={editable}
            />
          </div>
          <div class="row">
            <div className="col-lg-3 col-12">
              <label style={{ color: Color.green, marginTop: "16px" }}>
                {strings.percentage_connection_service_cost_over_transaction}:
              </label>
            </div>
            <div className="col-lg-3 col-12">
              <label style={{ color: Color.green, marginTop: "16px" }}>{`${
                strings.sign_up_for_sale
              }: ${getNumber(config, "offering", 0)}%`}</label>
            </div>
            <div className="col-lg-3 col-12">
              <label style={{ color: Color.green, marginTop: "16px" }}>
                {`${strings.successful_connection}: ${getNumber(
                  config,
                  "connection",
                  0
                )}%`}
                :
              </label>
            </div>
            <div className="col-lg-3 col-12">
              <label style={{ color: Color.green, marginTop: "16px" }}>
                {`${strings.payment_through_the_exchange}: ${getNumber(
                  config,
                  "payment",
                  0
                )}%`}
              </label>
            </div>
          </div>

          <label style={{ color: Color.green, marginTop: "16px" }}>
            {strings.register_for_delivery_at}:
          </label>
          <div className="row check">
            {getArray(deliver, undefined, []).map((item) => (
              <div className="col-lg-2 col-md-12">
                <CheckboxField
                  disabled={!editable}
                  validatestatus={
                    touched.delivered_in_the_field &&
                    errors.delivered_in_the_field
                      ? "error"
                      : undefined
                  }
                  help={
                    touched.delivered_in_the_field &&
                    errors.delivered_in_the_field
                      ? errors.delivered_in_the_field
                      : ""
                  }
                  name={`${item.id.toString()}`}
                  checked={getArray(values, "serviceIds", []).includes(item.id)}
                  onChange={this._handleChangeCheckBox}
                  onBlur={handleBlur}
                  label={item.name}
                />
              </div>
            ))}

            <div className="col-12" style={{ marginTop: "16px" }}>
              <label style={{ color: Color.green }}>
                {strings.using_value_added_services}:
              </label>
            </div>

            <div className="col-lg-2dot5  col-md-2dot4 ">
              <label style={{ color: "red" }}>{strings.AgriSys}:</label>

              <div className="row">
                <div className="col-lg-12 col-md-12">
                  {getArray(this.props.agriSys, undefined, []).map((item) => (
                    <CheckboxField
                      disabled={!editable}
                      validatestatus={
                        touched.agricultural_materials &&
                        errors.agricultural_materials
                          ? "error"
                          : undefined
                      }
                      help={
                        touched.agricultural_materials &&
                        errors.agricultural_materials
                          ? errors.agricultural_materials
                          : ""
                      }
                      name={`${item.id.toString()}`}
                      checked={getArray(values, "serviceIds", []).includes(
                        item.id
                      )}
                      onChange={this._handleChangeCheckBox}
                      onBlur={handleBlur}
                      label={item.name}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-2dot4 col-md-2dot4">
              <label style={{ color: "red" }}>{strings.AgriDepot}:</label>
              <div className="row">
                <div className="col-12">
                  {getArray(this.props.agriDepot, undefined, []).map((item) => (
                    <CheckboxField
                      disabled={!editable}
                      validatestatus={
                        touched.agricultural_materials &&
                        errors.agricultural_materials
                          ? "error"
                          : undefined
                      }
                      help={
                        touched.agricultural_materials &&
                        errors.agricultural_materials
                          ? errors.agricultural_materials
                          : ""
                      }
                      name={`${item.id.toString()}`}
                      checked={getArray(values, "serviceIds", []).includes(
                        item.id
                      )}
                      onChange={this._handleChangeCheckBox}
                      onBlur={handleBlur}
                      label={item.name}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-2dot4 col-md-2dot4 ">
              <label style={{ color: "red" }}>{strings.AgriService}:</label>
              <div className="row">
                <div className="col-12">
                  {getArray(this.props.agriService, undefined, []).map(
                    (item) => (
                      <CheckboxField
                        disabled={!editable}
                        validatestatus={
                          touched.agricultural_materials &&
                          errors.agricultural_materials
                            ? "error"
                            : undefined
                        }
                        help={
                          touched.agricultural_materials &&
                          errors.agricultural_materials
                            ? errors.agricultural_materials
                            : ""
                        }
                        name={`${item.id.toString()}`}
                        checked={getArray(values, "serviceIds", []).includes(
                          item.id
                        )}
                        onChange={this._handleChangeCheckBox}
                        onBlur={handleBlur}
                        label={item.name}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-2dot3 col-md-2dot4 ">
              <label style={{ color: "red" }}>{strings.AgriHub}:</label>
              <div className="row">
                <div className="col-12">
                  {getArray(this.props.agriHub, undefined, []).map((item) => (
                    <CheckboxField
                      disabled={!editable}
                      validatestatus={
                        touched.agricultural_materials &&
                        errors.agricultural_materials
                          ? "error"
                          : undefined
                      }
                      help={
                        touched.agricultural_materials &&
                        errors.agricultural_materials
                          ? errors.agricultural_materials
                          : ""
                      }
                      name={`${item.id.toString()}`}
                      checked={getArray(values, "serviceIds", []).includes(
                        item.id
                      )}
                      onChange={this._handleChangeCheckBox}
                      onBlur={handleBlur}
                      label={item.name}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-2dot4 col-md-2dot4 ">
              <label style={{ color: "red" }}>{strings.AgriPay}:</label>
              <div className="row">
                <div className="col-12">
                  {getArray(this.props.agriPay, undefined, []).map((item) => (
                    <CheckboxField
                      disabled={!editable}
                      validatestatus={
                        touched.agricultural_materials &&
                        errors.agricultural_materials
                          ? "error"
                          : undefined
                      }
                      help={
                        touched.agricultural_materials &&
                        errors.agricultural_materials
                          ? errors.agricultural_materials
                          : ""
                      }
                      name={`${item.id.toString()}`}
                      checked={getArray(values, "serviceIds", []).includes(
                        item.id
                      )}
                      onChange={this._handleChangeCheckBox}
                      onBlur={handleBlur}
                      label={item.name}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row"></div>
        </Form>
        {(this.state.loading || this.props.loadingTypeProcess) && <Loading />}
      </Wrapred>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    getType,
    getWards,
    getDistrict,
    getProvinces,
    getMassUnits,
    getExpected,
    getAllRegions,
    getActual,
    getAllProvinOfRegion,
    getAllPublishServiceLists,
    getAllDistricsOfProvin,
    getAllWardOf,
    getAllPublishCostconfig,
  }),
  withState("tradingRangeOptions", "setTradingRangeOptions", []),
  withState("cultivationOption", "setcultivation", []),
  withState("trading_rangeOption", "setTrading_range", []),
  withState("paymentOptions", "setPayment", []),
  withState("acreAge", "setAcreAge", []),
  withState("loadingTypeProcess", "setLoadingType", false),
  withState("transactionType", "setTransactionType", [
    { value: strings.buy_human },
    { value: strings.sell_human },
  ]),
  withState("provin", "setProvin", []),
  withState("district", "setDistrict", []),
  withState("ward", "setWard", []),
  withState("provin_register", "setProvin_register", []),
  withState("district_register", "setDistrict_register", []),
  withState("ward_register", "setWard_register", []),
  withState("regions", "setRegions", []),
  withState("service", "setService", []),
  withState("deliver", "setDeliver", []),
  withState("agriSys", "SetAgriSys", []),
  withState("agriPay", "SetAgriPay", []),
  withState("agriHub", "SetAgriHub", []),
  withState("agriDepot", "SetAgriDepot", []),
  withState("agriService", "SetAgriService", []),
  withState("config", "setConfig", {}),
  withHandlers({
    fetchAllPublishServiceLists: (props) => () => {
      const {
        getAllPublishServiceLists,
        setService,
        setLoadingType,
        setDeliver,
        SetAgriSys,
        //
        SetAgriService,
        SetAgriDepot,
        SetAgriHub,
        SetAgriPay,
      } = props;
      setLoadingType(true);
      getAllPublishServiceLists()
        .then(({ res }) => {
          getArray(res, "DELIVER", []).map((dele) =>
            setDeliver(getArray(dele, "items", []))
          );
          getArray(res, "VAT", []).map((vat) => {
            if (getString(vat, "group", "") === "SYS") {
              SetAgriSys(getArray(vat, "items", []));
            }
            if (getString(vat, "group", "") === "SERVICE") {
              SetAgriService(getArray(vat, "items", []));
            }
            if (getString(vat, "group", "") === "DEPOT") {
              SetAgriDepot(getArray(vat, "items", []));
            }
            if (getString(vat, "group", "") === "PAY") {
              SetAgriPay(getArray(vat, "items", []));
            }
            if (getString(vat, "group", "") === "HUB") {
              SetAgriHub(getArray(vat, "items", []));
            }
          });

          setLoadingType(false);
        })
        .catch((err) => {
          setLoadingType(false);

          showError(err);
        });
    },
    fetchAllConfig: (props) => () => {
      const { getAllPublishCostconfig, setLoadingType, setConfig } = props;
      setLoadingType(true);

      getAllPublishCostconfig()
        .then(({ res }) => {
          if (res.length) setConfig(res[0]);
          setLoadingType(false);
        })
        .catch((err) => {
          setLoadingType(false);

          showError(err);
        });
    },
    fetchAllRegions: (props) => () => {
      const { getAllRegions, setRegions } = props;
      getAllRegions()
        .then(({ res }) => {
          setRegions(
            getArray(res, undefined, []).map((item) => ({
              value: getNumber(item, "id", null),
              label: getString(item, "name", ""),
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fectTypeTradingRange: (props) => () => {
      const { getType, setTrading_range, setFieldValue } = props;
      getType("TRADING_RANGE")
        .then(({ res }) => {
          setTrading_range(
            getArray(res, undefined, []).map((item) => ({
              value: item.name,
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fecthAcreage: (props) => () => {
      const { setAcreAge, getMassUnits } = props;
      getMassUnits("ACREAGE")
        .then(({ res }) => {
          setAcreAge(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fetchTypeCulti: (props) => () => {
      const { getType, setcultivation } = props;
      getType("CULTIVATION")
        .then(({ res }) => {
          res.unshift({ name: strings.personal });
          setcultivation(
            getArray(res, undefined, []).map((item) => ({
              value: item.name,
            }))
          );
        })
        .catch((err) => showError(err));
    },

    fetchAllProvinOfRegion: (props) => (value) => {
      const { getAllProvinOfRegion, setProvin_register } = props;
      getAllProvinOfRegion(value)
        .then(({ res }) => {
          setProvin_register(
            getArray(res, undefined, []).map((item) => {
              return {
                value: item.id,
                label: item.name || "",
              };
            })
          );
        })
        .catch((err) => showError(err));
    },
    fetchAllDistricsOfProvin: (props) => (value) => {
      const { getAllDistricsOfProvin, setDistrict_register } = props;
      getAllDistricsOfProvin(value)
        .then(({ res }) => {
          setDistrict_register(
            getArray(res, undefined, []).map((item) => {
              return {
                value: item.id,
                label: item.name || "",
              };
            })
          );
        })
        .catch((err) => showError(err));
    },
    fetchAllWardOfProvine: (props) => (value) => {
      const { getAllWardOf, setWard_register } = props;
      getAllWardOf(value)
        .then(({ res }) => {
          setWard_register(
            getArray(res, undefined, []).map((item) => {
              return {
                value: item.id,
                label: item.name || "",
              };
            })
          );
        })
        .catch((err) => showError(err));
    },
    fechGetProvince: (props) => () => {
      const { getProvinces, setProvin, setProvin_register } = props;
      getProvinces(1)
        .then(({ res }) => {
          setProvin(
            getArray(res, undefined, []).map((item) => {
              return {
                value: item.id,
                label: item.name || "",
              };
            })
          );
        })
        .catch((err) => showError(err));
    },
    fechDistrict: (props) => (id, check) => {
      const { getDistrict, setDistrict, setDistrict_register } = props;
      if (id)
        getDistrict(id)
          .then(({ res }) => {
            setDistrict(
              getArray(res, undefined, []).map((item) => {
                return {
                  value: item.id,
                  label: item.name || "",
                };
              })
            );
          })
          .catch((err) => showError(err));
    },
    fechWard: (props) => (id, check) => {
      const { getWards, setWard, setWard_register } = props;

      if (id)
        getWards(id)
          .then(({ res }) => {
            setWard(
              getArray(res, undefined, []).map((item) => {
                return {
                  value: item.id,
                  label: item.name || "",
                };
              })
            );
          })
          .catch((err) => showError(err));
    },
    handleChangeDate: (_) => (props) => (name) => (value) => {
      const { setFieldValue, setFieldTouched } = props;
      setFieldTouched(name);
      if (name === "dateShell") {
        var weeknumber = moment(value).isoWeek();
        setFieldValue("numberWeek", weeknumber);
        props.info["numberWeek"] = weeknumber;
      }
      if (value) {
        setFieldValue(
          name,
          value.endOf("day").utc().format(UtilDate.formatDateTimeServer)
        );
        props.info[name] = value
          .endOf("day")
          .utc()
          .format(UtilDate.formatDateTimeServer);
      } else {
        setFieldValue(name, undefined);
        props.info[name] = undefined;
      }
    },
    handleMapInfo: (name, value) => {},
  }),
  withFormik({
    displayName: "inforForm",
    validationSchema,
    initialValues: {},
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      if (!props.view) {
        var salerCode = getString(props, "formPost.farm.code", 0);

        var farm = getObject(props, "formPost.farm", {});
        var season = getObject(props, "formPost.season", {});
        var address = getObject(farm, "address", {});
        //
        var provinceId = getNumber(address, "province.id", null);
        var districtId = getNumber(address, "district.id", null);
        var wardsId = getNumber(address, "wards.id", null);
        var grossArea = getNumber(season, "grossArea.value");
        var unitId = getNumber(season, "grossArea.unitId");
        var adress = getString(address, "address1", "");
        var name_organization = getString(props, "formPost.farm.name", "");
        var surrogate = getString(props, "formPost.farm.surrogate", "");
        var phone = getString(props, "formPost.farm.phone", "");
        var email = getString(props, "formPost.farm.email", "");
        var certifications = getArray(props, "formPost.farm.certifications", [])
          .map((val, index) => {
            return getString(val, "type.name", "");
            // if( val.kyc) check Kyc !!!!!
          })
          .join(",");
        var typeExpect = "ACTUAL";
      } else if (props.view) {
        var info = getObject(props, "info", {});
        var address = getObject(info, "placeOfPlace", {});
        //
        var provinceId = getNumber(address, "provinceId", null);
        var districtId = getNumber(address, "districtId", null);
        var wardsId = getNumber(address, "wardsId", null);
        var grossArea = getNumber(info, "grossArea.value");
        var unitId = getNumber(info, "grossArea.unitId");
        var adress = getString(address, "address1", "");
        var town_village = getString(address, "address2", "");
        var name_organization = getString(props, "info.name_organization", "");
        var surrogate = getString(props, "info.surrogate", "");
        var phone = getString(props, "info.phone", "");
        var website = getString(props, "info.website", "");
        var email = getString(props, "info.email", "");
        var certifications = getString(props, "info.certifications", "");

        var numberWeek = getNumber(props, "info.numberWeek");
        var dateShell = getString(props, "info.dateShell");
        var role = getString(props, "info.role");
        var cultivation = getString(props, "info.cultivation");
        var timeShell = getNumber(props, "info.timeShell");
        var Km = getNumber(props, "info.Km");
        var transaction = getString(props, "info.transaction");
        var trading = getString(props, "info.trading");
        var status = getString(props, "info.status");
        var registerNation = getBool(props, "info.isNationwide");
        var region_reginter = getArray(props.info, "regionsIds", []);
        var provinceId_register = getArray(props.info, "provinceIds", []);
        var districtId_register = getArray(props.info, "districtIds", []);
        var wardsId_register = getArray(props.info, "wardsIds", []);
        var serviceIds = getArray(props, "info.serviceIds", []);
        var typeExpect = getString(props, "info.typeExpect", "");
        var code = getString(props, "info.code", "");
        var salerCode = getString(props, "formPost.farm.code", 0);
      }
      return {
        code: code || 0,
        salerCode: salerCode || 0,
        typeExpect: typeExpect || "ACTUAL",
        serviceIds: serviceIds || [],
        region_reginter: region_reginter || [],
        provinceId_register: provinceId_register || [],
        districtId_register: districtId_register || [],
        wardsId_register: wardsId_register || [],
        registerNation: registerNation || false,
        status: status || "",
        trading: trading || null,
        Km: Km || null,
        timeShell: timeShell || null,
        town_village: town_village || "",
        transaction: transaction || "SELL",
        role: role || "",
        website: website || "",
        numberWeek: numberWeek || moment(moment()).isoWeek(),
        dateShell:
          dateShell ||
          moment().endOf("day").utc().format(UtilDate.formatDateTimeServer),
        unitId,
        grossArea,
        wardsId,
        provinceId,
        districtId,
        option_adress: [{ value: getString(address, "address1", "") }],
        address: adress,
        option_name_organization: [
          { value: getString(props, "formPost.farm.name", "") },
        ],
        name_organization,
        surrogate,
        option_surrogate: [
          { value: getString(props, "formPost.farm.surrogate", "") },
        ],
        phone,
        option_phone: [{ value: getString(props, "formPost.farm.phone", "") }],
        email,
        option_email: [{ value: getString(props, "formPost.farm.email", "") }],
        option_certifications: getArray(
          props,
          "formPost.farm.certifications",
          []
        ).map((val) => {
          // if( val.kyc) check Kyc !!!!!
          return {
            value: getString(val, "type.name", ""),
          };
        }),

        certifications,

        cultivation: cultivation,
        // typeExpect:
      };
    },
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps(props) {
      const { values } = props;
      this.props.info["provinceId"] = values.provinceId;
      this.props.info["districtId"] = values.districtId;
      this.props.info["wardsId"] = values.wardsId;
      this.props.info["grossArea"] = values.grossArea;
      this.props.info["typeExpect"] = values.typeExpect;

      this.props.info["address"] = values.address;
      this.props.info["town_village"] = values.town_village;
      this.props.info["name_organization"] = values.name_organization;
      this.props.info["surrogate"] = values.surrogate;
      this.props.info["phone"] = values.phone;
      this.props.info["email"] = values.email;
      this.props.info["dateShell"] = values.dateShell;
      this.props.info["numberWeek"] = values.numberWeek;
      this.props.info["timeShell"] = values.timeShell;
      this.props.info["Km"] = values.Km;
      this.props.info["certifications"] = values.certifications;
      this.props.info["cultivation"] = values.cultivation;
      this.props.info["trading"] = values.trading;
      this.props.info["transaction"] = values.transaction;
      this.props.info["status"] = "CREATING";
      this.props.info["isNationwide"] = values.registerNation;
      if (!this.props.view) {
        const farm = getObject(props, "formPost.farm", {});
        const address = getObject(farm, "address", {});
        const provinceId = getNumber(address, "province.id", null);
        const districtId = getNumber(address, "district.id", null);
        //this.props
        const farmCurrent = getObject(this.props, "formPost.farm", {});
        const addressCurrent = getObject(farmCurrent, "address", {});
        const provinceIdCurrent = getNumber(
          addressCurrent,
          "province.id",
          null
        );
        const districtIdCurrent = getNumber(
          addressCurrent,
          "district.id",
          null
        );
        if (provinceIdCurrent !== provinceId)
          if (provinceId) props.fechDistrict(provinceId);
        if (districtIdCurrent !== districtId)
          if (districtId) {
            props.fechWard(districtId);
          }
      } else if (props.view) {
        const address = getObject(props.info, "placeOfPlace", {});
        const provinceId = getNumber(address, "provinceId", null);
        const districtId = getNumber(address, "districtId", null);
        //this.props
        const addressCurrent = getObject(this.props.info, "placeOfPlace", {});
        const provinceIdCurrent = getNumber(addressCurrent, "provinceId", null);
        const districtIdCurrent = getNumber(addressCurrent, "districtId", null);
        if (provinceIdCurrent !== provinceId)
          if (provinceId) props.fechDistrict(provinceId);
        if (districtIdCurrent !== districtId)
          if (districtId) {
            props.fechWard(districtId);
          }
        const regionsIds = getArray(this.props.info, "regionsIds", []);
        const provinceIds = getArray(this.props.info, "provinceIds", []);
        const districtIds = getArray(this.props.info, "districtIds", []);
        //this.props
        const regionsIdsNext = getArray(props.info, "regionsIds", []);
        const provinceIdsNext = getArray(props.info, "provinceIds", []);
        const districtIdsNext = getArray(props.info, "districtIds", []);
        if (regionsIds.length !== regionsIdsNext.length)
          if (regionsIdsNext.length)
            props.fetchAllProvinOfRegion(regionsIds.join(","));
        if (provinceIds.length !== provinceIdsNext.length)
          if (provinceIdsNext.length)
            props.fetchAllDistricsOfProvin(provinceIds.join(","));
        if (districtIdsNext.length !== districtIds.length)
          if (districtIdsNext.length) {
            props.fetchAllWardOfProvine(districtIdsNext.join(","));
          }
      }
    },
    componentDidMount() {
      const {
        fetchTypeCulti,
        fecthAcreage,
        fectTypeTradingRange,
        fechGetProvince,
        fetchAllConfig,
        fetchAllRegions,
        fetchAllPublishServiceLists,
        // fechDistrict,
        // fechWard,
      } = this.props;
      fetchAllRegions();
      fetchTypeCulti();
      fechGetProvince();
      fecthAcreage();
      fetchAllPublishServiceLists();
      fectTypeTradingRange();
      fetchAllConfig();
    },
  })
)(FromProcedure);
