import React from "react";
import {compose, withHandlers, lifecycle, withState} from "recompose";
import {connect} from "react-redux";
import {getAllProfiles, deleteFarmProfile, deleteFarmSeasonCRUD} from "~/state/ducks/appApis/actions";
import Loading from "~/views/presentation/ui/loading";

import {showError} from "~/configs/ServerErrors";
import strings from "~/localization";
import {ContentWrapper} from "~/views/presentation/ui/container";
import {message} from "antd";
import {getArray, getString, getNumber} from "~/views/utilities/helpers/utilObject";
import {PROFILE_TYPES, FARM_TYPES} from "~/configs/const";
import {IMAGE_URL} from "~/configs";
import UITable from "~/views/presentation/ui/tables/Table";
import {withRouter} from "react-router-dom";

import {FARM_DETAIL_PATH} from "~/configs/routesConfig";
import {Avatar} from "~/views/presentation/ui/fields";
import {ProfileType} from "~/views/container/styles";
import {ProfileItem} from "~/views/container/components";

class AllFarms extends React.PureComponent {
  getProfileType = (type) => {
    switch (type) {
      case FARM_TYPES.FARM_SEASON:
        return strings.season;
      default:
        return "";
    }
  };

  handleViewProfile = (profile) => {
    const {history} = this.props;
    switch (getString(profile, "type")) {
      case PROFILE_TYPES.FARM_PROFILE: {
        history.push({
          pathname: FARM_DETAIL_PATH.replace(":id", getNumber(profile, "id")),
        });
      }
      default:
        break;
    }
  };

  handleDeleteProfile = (profile) => () => {
    const {deleteFarmProfile, fetchAllProfiles} = this.props;
    let profileId = getNumber(profile, "id");
    const getAPIDelete = () => {
      switch (getString(profile, "type")) {
        case PROFILE_TYPES.FARM_PROFILE:
          {
            return deleteFarmProfile(profileId);
          }
          break;
        case FARM_TYPES.FARM_SEASON_CRUD:
          {
            return deleteFarmSeasonCRUD(profileId);
          }
          break;
        default:
          break;
      }
    };
    getAPIDelete()
      .then(() => {
        message.success(strings.delete_profile_success);
        fetchAllProfiles();
      })
      .catch((err) => showError(err));
  };

  render() {
    /**
     * event cell click -> view profile detail
     */
    const renderCellItem = (data, records) => {
      return (
        <>
          <span>{data}</span>
          <p
            onClick={() => this.handleViewProfile(records)}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}></p>
        </>
      );
    };

    const columns = [
      {
        title: strings.avatar,
        dataIndex: "avatar",
        key: "avatar",
        width: "15%",
        align: "center",
        render: (cellData, records) => {
          return renderCellItem(<Avatar size={48} src={IMAGE_URL + cellData} />, records);
        },
      },
      {
        title: strings.name,
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => {
          return getString(a, "name", "").length - getString(b, "name", "").length;
        },
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.address,
        dataIndex: "fulladdress",
        key: "fulladdress",
        width: "25%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.phone,
        dataIndex: "phone",
        key: "phone",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.farm_area,
        dataIndex: "grossarea",
        key: "grossarea",
        sorter: (a, b) => getNumber(a, "grossarea", 0) - getNumber(b, "grossarea", 0),
        align: "right",
        render: (record, records) => {
          return renderCellItem(`${getNumber(record, undefined, 0)} ${getString(records, "grossAreaUnit", strings.hectare)}`, records);
        },
      },
    ];

    const {profiles, viewAsBlock = false} = this.props;
    return (
      <ContentWrapper>
        {viewAsBlock ? (
          <ProfileItem profiles={profiles} getProfileType={this.getProfileType} handleViewProfile={this.handleViewProfile} isFarmManage={true} />
        ) : (
          <UITable dataSource={profiles || []} rowClassName='cursor-pointer' columns={columns} loading={this.props.loading} />
        )}
        {this.props.loadng && <Loading />}
      </ContentWrapper>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getAllProfiles,
    deleteFarmProfile,
    deleteFarmSeasonCRUD,
  }),
  withState("profiles", "setProfiles", []),
  withState("loading", "setLoading", false),
  withHandlers({
    fetchAllProfiles: (props) => () => {
      //   let type = props.match.params.id
      const {getAllProfiles, setProfiles, setLoading} = props;
      setLoading(true);

      getAllProfiles("FARM_PROFILE")
        .then(({res}) => {
          setProfiles(getArray(res, undefined, []));
          setLoading(false);
        })
        .catch((err) => {
          showError(err);
          setLoading(false);
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      const {fetchAllProfiles} = this.props;
      fetchAllProfiles();
    },
  })
)(AllFarms);
