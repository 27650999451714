import React from "react";
import AppContentWrapper from "~/views/presentation/ui/container/AppContentWrapper";
import styled from "styled-components";
import _ from "lodash";
import { Button, Card } from "antd";
import strings from "~/localization";
import { getString } from "~/views/utilities/helpers/utilObject";
import {
  ALL_PROFILES_PATH,
  FARM_HOME_PATH,
  COMMERCE_HOME_PATH,
} from "~/configs/routesConfig";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

const ContainerStyled = styled.div`
  display: flex;
  padding: 10px;
  .item-container:hover {
    .disable-p {
      color: red !important;
    }
    .avatar {
      -webkit-transition: all 0.5s 0s ease;
      -moz-transition: all 0.5s 0s ease;
      -o-transition: all 0.5s 0s ease;
      transition: all 0.5s 0s ease;
      color: #5d9c14 !important;
      border: 1px solid #5d9c14 !important;
    }
  }
  .menu-list {
    width: 1092px;
    @media (max-width: 600px) {
      width: 100%;
      margin-top: 32px;
    }
    .ant-card-bordered {
      -webkit-transition: all 0.5s 0s ease;
      -moz-transition: all 0.5s 0s ease;
      -o-transition: all 0.5s 0s ease;
      transition: all 0.5s 0s ease;
      border: 1px solid #e9e9e9;
      border-radius: 8px;
    }
    display: flex;
    flex-wrap: wrap;
    padding: 16px;

    .ant-card {
      margin: 5px;
      width: 330px !important;
      @media (max-width: 600px) {
        width: 100% !important;
      }
      .ant-card-body {
        height: 148px;
        cursor: pointer;
        padding: 0 8px;
        padding: 10px;
        display: flex;
        -webkit-transition: background 0.5s 0s ease;
        -moz-transition: background 0.5s 0s ease;
        -o-transition: background 0.5s 0s ease;
        transition: background 0.5s 0s ease;
        align-items: center;
        div:first-child {
          height: 60px;
          width: 60px;
          margin: 10px;
          border-radius: 50%;
          border: 1px solid #f0f0f0;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            font-size: 25px;
          }
        }
        div:last-child {
          flex: 1;
        }
      }
    }
  }
  // .disable {
  //   // background-color: #e9e9e9 !important;
  //   cursor: auto !important;
  //   // .ant-card-body {
  //   //   cursor: auto !important;
  //   //   border: 1px solid #e9e9e9;
  //   //   border-radius: 8px;
  //   //   // :hover {
  //   //   //   background-color: #fff;
  //   //   // }
  //   // }
  // }
`;

const ButtonStyled = styled(Button)`
  height: 30px !important;

  // padding: 0px 16px !important;
  // border-radius: 3px !important;
}
`;

const ApplicationsMenu = [
  {
    name: strings.profiles_management,
    classIcon: "fa fa-user",
    path: ALL_PROFILES_PATH,
    description: strings.profile_management_description,
  },
  {
    name: strings.farmings_management,
    classIcon: "fa fa-inbox",
    path: FARM_HOME_PATH,
    description: strings.farm_management_description,
  },
  {
    name: strings.purchasing_management,
    classIcon: "fa fa-shopping-basket",
    path: "path_3",
    description: strings.purchase_management_description,
    disabled: true,
  },
  {
    name: strings.goods_management,
    classIcon: "fa fa-envira",
    path: "path_4",
    description: strings.goods_management_description,
    disabled: true,
  },
  {
    name: strings.comercial_management,
    classIcon: "fa fa-shopping-cart",
    path: COMMERCE_HOME_PATH,
    description: strings.comercial_management_description,
    disabled: true,
  },
  {
    name: strings.orders_management,
    classIcon: "fa fa-file-text",
    path: "path_6",
    description: strings.orders_management_description,
    disabled: true,
  },
  {
    name: strings.contact,
    classIcon: "fa fa-address-card",
    path: "path_7",
    description: strings.contact_management_description,
    disabled: true,
  },
  {
    name: strings.logistics,
    classIcon: "fa fa-truck",
    path: "path_8",
    description: strings.logictics_management_description,
    disabled: true,
  },
  {
    name: strings.human_resources,
    classIcon: "fa fa-user-plus",
    path: "path_9",
    description: strings.human_resources_management,
    disabled: true,
  },
  {
    name: strings.financial,
    classIcon: "fa fa-line-chart",
    path: "path_10",
    description: strings.finance_management_description,
    disabled: true,
  },
  {
    name: strings.messages_management,
    classIcon: "fa fa-commenting",
    path: "path_11",
    description: strings.messenger_management_description,
    disabled: true,
  },
  {
    name: strings.reports,
    classIcon: "fa fa-bar-chart",
    path: "path_12",
    description: strings.report_management_description,
    disabled: true,
  },
];

class Applications extends React.Component {
  ApplicationItem = (item, index) => {
    const { history } = this.props;
    return (
      <Card
        className={item.disabled ? "disable item-container" : "item-container"}
        key={index}
        bordered
        onClick={() => history.push(getString(item, "path", ""))}>
        <div className="avatar">
          <i className={getString(item, "classIcon", "")} />
        </div>
        <div>
          <h2>{getString(item, "name", "")}</h2>
          <p>{getString(item, "description", "")}</p>
          <div className="d-flex justify-content-end">
            {item.disabled && (
              <p className="disable-p" style={{ color: "#8080809e" }}>
                {strings.feature_development}
              </p>
            )}
            <ButtonStyled
              type={!item.disabled ? "primary" : "default"}
              shape="round">
              {!item.disabled ? strings.installed : strings.install}
            </ButtonStyled>
          </div>
        </div>
      </Card>
    );
  };
  render() {
    const {} = this.props;
    return (
      <AppContentWrapper
        showMainSideBar={false}
        topNav={{
          pageName: strings.applications,
        }}>
        <ContainerStyled>
          <div className="menu-list container">
            {ApplicationsMenu.map(this.ApplicationItem)}
          </div>
        </ContainerStyled>
      </AppContentWrapper>
    );
  }
}

export default compose(withRouter)(Applications);
