import React from "react";
import {withRouter} from "react-router-dom";
import {compose, withState, withHandlers, lifecycle} from "recompose";
import {Card, Steps, Divider, Button, message} from "antd";
// redux
import {connect} from "react-redux";
import {createProfile, skipFirstSetUpProfile} from "~/state/ducks/authUser/actions";
// config or common
import {showError} from "~/configs/ServerErrors";
import strings from "~/localization";
import {LOGIN_PATH, APP_DEFAULT_PATH} from "~/configs/routesConfig";
// component
import CooperativeInfoForms from "./OrganizationInfoForms/index";
import QNCForm from "./OrganizationDetailForms";
import PageDone from "./PageDone";
import {UIButton} from "~/views/presentation/ui/buttons";

const {Step} = Steps;

const scrollToBottom = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};
class SetUpProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (current) => {
    const {isCreateProfile} = this.props;
    if (current === 2 && !isCreateProfile) return;
    this.setState({current});
    scrollToBottom();
  };
  renderStepContent = () => {
    const {cooperativeInfo, editable, savePageDone = true} = this.props;
    switch (this.state.current) {
      case 0:
        return <CooperativeInfoForms nextStep={(e) => this.onChange(e)} editable={editable} info={cooperativeInfo} />;
      case 1:
        return <QNCForm nextStep={(e) => this.onChange(e)} editable={editable} info={cooperativeInfo} />;
      case 2:
        return <PageDone savePageDone={savePageDone} onCreate={() => this.props.onCreate()} />;
    }
  };

  componentDidMount() {
    if (!this.props.user) {
      this.props.history.push(LOGIN_PATH);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user) {
      this.props.history.push(LOGIN_PATH);
    }
    const {setCooperativeInfo} = this.props;
    if (this.props.info !== nextProps.info) {
      setCooperativeInfo(nextProps.info);
    }
  }

  handleNext = () => {
    if (this.state.current === 3) {
      this.props.handleCreateProfile(this.props);
    } else if (this.state.current <= 2) {
      this.setState({current: this.state.current + 1});
    }
  };

  render() {
    const {current} = this.state;
    const {handleSkip, showHeader = true, showFooter = true, isCreateProfile} = this.props;
    return (
      <div style={{paddingBottom: "20px"}}>
        <Card title={showHeader ? strings.setup_profile : undefined} bordered={false}>
          <Steps current={current} onChange={this.onChange}>
            <Step title={strings.cooperative_info} />
            <Step title={strings.cooperative_detail} />
            {isCreateProfile && <Step title={strings.completed} />}
          </Steps>
          <Divider className="mb-5" />
          {this.renderStepContent()}
        </Card>
        {showFooter && (
          <div className="d-flex justify-content-end mb-5" style={{paddingRight: "24px"}}>
            {this.state.current !== 3 && (
              <Button type="dashed" onClick={handleSkip}>
                {strings.skip_setup}
              </Button>
            )}
            <UIButton type="primary" className="ml-2" onClick={this.handleNext} htmlType="button">
              <span>{this.state.current === 3 ? strings.completed : strings.continue}</span>
            </UIButton>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      user: state["authUser"].user,
    }),
    {
      createProfile,
      skipFirstSetUpProfile,
    }
  ),
  withRouter,
  withState("cooperativeInfo", "setCooperativeInfo", {}),
  withHandlers({
    handleCreateProfile: () => (props) => {
      const {createProfile, cooperativeInfo, history} = props;
      createProfile(cooperativeInfo)
        .then(() => {
          message.success(strings.update_profile_successful);
          history.push(APP_DEFAULT_PATH);
        })
        .catch((err) => {
          showError(err);
        });
    },
    handleSkip: (props) => () => {
      const {skipFirstSetUpProfile, history} = props;
      skipFirstSetUpProfile()
        .then(() => {
          history.push(APP_DEFAULT_PATH);
        })
        .catch((err) => showError(err));
    },
  }),
  lifecycle({
    componentDidMount() {},
  })
)(SetUpProfile);
