import { compose, lifecycle, withState, withHandlers } from "recompose";
import { connect } from "react-redux";
import { cartSelectors, cartActions } from "~/state/ducks/cart";
import { authSelectors } from "~/state/ducks/authUser";
import { getArray } from "~/views/utilities/helpers/utilObject";
import { withRouter } from "react-router-dom";
// import { showToastError } from '~/configs/serverErrors';
// import ModalLoading from "../../presentation/Modals/ModalLoading";

export default compose(
    connect(
        (state) => ({
            cartItems: getArray(cartSelectors.getCart(state), "cartItems", []) ,
            totalItemCart: cartSelectors.getTotalItemsCart(state),
            totalAmountCart: cartSelectors.getTotalAmountCart(state),
            // couponDiscount: cartSelectors.getCouponCartDiscount(state),
            // cartInfoItems: cartSelectors.getCart(state),
            user: authSelectors.getAuthUser(state),
        }),
        {
            // getDefaultCheckoutComplete: cartActions.getDefaultCheckoutComplete,
            // addBuyNowItem: cartActions.addBuyNowItem,
            // storePreviousProduct: cartActions.storePreviousProduct,
            // clearCouponClientCart: cartActions.clearCouponClientCart,
            // applyCoupon: cartActions.applyCoupon,
            // applyCouponNA: cartActions.applyCouponNA,
        }
    ),
    withRouter,
    withState("coupon", "setCoupon", ""),
    withState("errors", "setErros", {}),
    withHandlers({
        handleRemoveCoupon: (props) => {
            
        },
        // applyCoupon: (props) => () => {
        //     const { applyCoupon, coupon, setErros, setCoupon } = props;
        //     if (coupon !== "") {
        //         ModalLoading.show()
        //         applyCoupon(coupon)
        //             .then((res) => {
        //                 ModalLoading.hide()
        //                 setCoupon("")
        //             })
        //             .catch((err) => {
        //                 showToastError(err)
        //                 ModalLoading.hide()
        //             });
        //     }
        // },
    })
);
