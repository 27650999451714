import React from "react";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import * as yup from "yup";
import OneUploader from "../../FarmProfileForms/QNCForm/OneUploader";

import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import {
  InputField,
  DatePickerField,
  SelectField,
  AutoComplete,
} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import { Tabs, Card, message } from "antd";
import { UIButton } from "~/views/presentation/ui/buttons";
import { TableEditable } from "~/views/presentation/ui/tables";
import {
  getType,
  getProvinces,
  getDistrict,
  getPersionnalProfile,
  getWards,
} from "~/state/ducks/appApis/actions";
import { showError } from "~/configs/ServerErrors";
import {
  getArray,
  getString,
  getNumber,
} from "~/views/utilities/helpers/utilObject";
import moment from "moment";
import _ from "lodash";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import {
  stringNRFieldValidate,
  numberValidate,
  stringRequiredField,
} from "~/views/utilities/validation/input";

const { TabPane } = Tabs;

const validationSchema = yup.object().shape({
  typeId: stringRequiredField(),
  issuedDate: stringNRFieldValidate(),
  expirationDate: stringNRFieldValidate(),
  dateEvaluate: stringNRFieldValidate(),
  dateReEvaluate: stringNRFieldValidate(),
  issuedPlace: stringNRFieldValidate(),
  numberCN: stringNRFieldValidate(),
  idTax: stringNRFieldValidate(),
  address1: stringNRFieldValidate(),
});
const scrollToBottom = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: "smooth",
  });
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
class LicenceForm extends React.PureComponent {
  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.error(
      "LicenceForm -> UNSAFE_componentWillReceiveProps -> nextProps",
      nextProps
    );
  }
  handleChangeDataSource = (index, dataIndex, dataSource) => {
    const { setValues } = this.props;
    if (dataIndex === "key") {
      setValues({});
      while ((this.props.info.businessLicenses || []).length) {
        this.props.info.businessLicenses.shift();
      }
      dataSource.forEach((item) => {
        this.props.info.businessLicenses.push(item);
      });
    }
  };
  handleChangeCertificate = (e, index) => {
    const { setFieldValue, arrayImage, values } = this.props;
    let temptArray = arrayImage;
    let valuesImg = getString(values, "images", "");

    temptArray = valuesImg.split("|");
    temptArray[index - 1] = e;
    temptArray = temptArray.join("|");

    setFieldValue("images", temptArray);
  };
  getSplitImg(index) {
    let tempt = getString(this.props.info.businessLicenses, "images");
    tempt = tempt.split("|");
    return tempt[index];
  }
  handleSaveValues = () => {
    const { values, setValues, setFieldValue, setFieldTouched } = this.props;
    setFieldValue("images", "");
    setFieldValue("typeId", "");

    let issuedDate = moment.utc(values.issuedDate);
    let expirationDate = moment.utc(values.expirationDate);
    let dateEvaluate = moment.utc(values.dateEvaluate);
    let dateReEvaluate = moment.utc(values.dateReEvaluate);
    if (issuedDate.isAfter(expirationDate)) {
      message.error(strings.activity_date_greater_than_expired_date);
      return;
    }
    if (dateEvaluate.isAfter(dateReEvaluate)) {
      message.error(strings.activity_date_greater_than_expired_date);
      return;
    }

    if (values.key >= 0) {
      if ((this.props.info.businessLicenses || []).length > values.key) {
        let keys = Object.keys(values).filter(
          (key) =>
            key === "cultivation" ||
            key === "productionTypesIds" ||
            key === "groupManagementIds" ||
            (!_.isArray(values[key]) && !_.isObject(values[key]))
        );
        let modifyObject = keys.reduce((prev, curr) => {
          prev[curr] = values[curr];
          return prev;
        }, {});

        this.props.info.businessLicenses[values.key] = modifyObject;
      }
      Object.keys(values).map((key) => setFieldTouched(key, false));
      setValues({});
      scrollToBottom();
    } else {
      let keys = Object.keys(values).filter(() => (key) =>
        key === "cultivation" ||
        key === "productionTypesIds" ||
        key === "groupManagementIds" ||
        (!_.isArray(values[key]) && !_.isObject(values[key]))
      );
      let modifyObject = keys.reduce((prev, curr) => {
        prev[curr] = values[curr];
        return prev;
      }, {});
      modifyObject["kyc"] = false;
      this.props.info.businessLicenses.push(modifyObject);
      this.props.info.businessLicenses.forEach((item, index) => {
        item.key = index;
      });
      Object.keys(values).map((key) => setFieldTouched(key, false));
      setValues({});
      scrollToBottom();
    }
  };
  handleSelectRow = (r) => {
    const { fechWard, fechDistrict } = this.props;

    const districtId = getNumber(r, "districtId", undefined);
    const provinceId = getNumber(r, "provinceId", undefined);
    if (provinceId) fechDistrict(provinceId);
    if (districtId) {
      fechWard(districtId);
    }
    const { setValues } = this.props;
    setValues(r);
    scrollToTop();
  };
  handleChange = (event) => {
    const { handleChange, setFieldTouched } = this.props;

    setFieldTouched(event.target.name, true);
    handleChange(event);
  };
  handleChangeAutoCompelete = (name, value) => {
    const { setFieldValue, setFieldTouched } = this.props;
    setFieldValue(name, value);

    setFieldTouched(name, true);
  };

  render() {
    const {
      values,
      handleChange,
      handleBlur,
      touched,
      errors,
      handleChangeDate,
      isValid,
      setFieldValue,
      setFieldTouched,
      handleCancelChange,
      editable,
      provin,
      cultivation,
      profession,
      groupManagerment,
      district,
      certificationTypes,
      ward,
    } = this.props;

    let issuedDate = moment
      .utc(values.issuedDate, UtilDate.formatDateTimeServer)
      .local();
    let expirationDate = moment
      .utc(values.expirationDate, UtilDate.formatDateTimeServer)
      .local();

    const columns = [
      {
        title: strings.certificate_type,
        dataIndex: "typeId",
        key: "typeId",
        width: "15%",
        editable: false,
        placeholder: strings.certificate_type,
        type: "text",
        // options: certificationTypes,
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.number_cn,
        dataIndex: "numberCN",
        key: "numberCN",
        width: "9%",
        editable: false,
        placeholder: strings.number_cn,
        type: "text",
      },
      {
        title: strings.id_tax,
        dataIndex: "idTax",
        key: "idTax",
        width: "9%",
        editable: false,
        placeholder: strings.id_tax,
        type: "text",
      },
      {
        title: strings.place_of_issue,
        dataIndex: "issuedPlace",
        key: "issuedPlace",
        width: "9%",
        editable: false,
        placeholder: strings.place_of_issue,
        type: "text",
      },
      {
        title: strings.date_of_issue,
        dataIndex: "issuedDate",
        key: "issuedDate",
        width: "9%",
        editable: false,
        placeholder: strings.date_of_issue,
        type: "date",
      },

      {
        title: strings.date_activity,
        dataIndex: "expirationDate",
        key: "expirationDate",
        width: "9%",
        editable: false,
        placeholder: strings.date_activity,
        type: "date",
      },
      {
        title: strings.address,
        placeholder: strings.address,
        dataIndex: "address1",
        key: "address1",
        width: "9%",
        type: "text",
      },
      {
        title: strings.type_of,
        dataIndex: "cultivation",
        key: "cultivation",
        placeholder: strings.type_of,
        type: "select",
        width: "9%",
        options: this.props.cultivation,
      },
      {
        title: strings.branch_product,
        dataIndex: "productionTypesIds",
        key: "productionTypesIds",
        placeholder: strings.branch_product,
        type: "select",
        width: "9%",
        options: this.props.profession,
      },
      {
        title: strings.management_grouping,
        dataIndex: "groupManagementIds",
        key: "groupManagementIds",
        placeholder: strings.management_grouping,
        type: "select",
        width: "9%",
        options: this.props.groupManagerment,
      },
      {
        title: strings.kyc,
        dataIndex: "kyc",
        key: "kyc",
        width: "9%",
        editable: false,
        placeholder: strings.certificate_type,
        type: "select",
        options: [
          { value: false, label: strings.not_authenticated },
          { value: true, label: strings.endorsed },
        ],
      },
    ];
    return (
      <div>
        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            <Card>
              <div className="row">
                <div className="col-6">
                  <AutoComplete
                    options={this.props.certificationTypes || []}
                    validatestatus={
                      touched.typeId && errors.typeId ? "error" : undefined
                    }
                    help={touched.typeId && errors.typeId ? errors.typeId : ""}
                    name="typeId"
                    type="text"
                    required
                    disabled={!editable}
                    value={values.typeId}
                    onChange={(e) =>
                      this.handleChangeAutoCompelete("typeId", e)
                    }
                    onBlur={handleBlur}
                    placeholder={strings.type_certification}
                    label={strings.type_certification}
                  />
                  {/* <InputField
                    validatestatus={
                      touched.type && errors.type ? "error" : undefined
                    }
                    help={touched.type && errors.type ? errors.type : ""}
                    name="type"
                    type="text"
                    disabled={!editable}
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={strings.certificate_type}
                    label={strings.certificate_type}
                  /> */}
                  <DatePickerField
                    disabled={!editable}
                    validateStatus={
                      touched.issuedDate && errors.issuedDate
                        ? "error"
                        : undefined
                    }
                    help={
                      touched.issuedDate && errors.issuedDate
                        ? errors.issuedDate
                        : ""
                    }
                    name="issuedDate"
                    value={issuedDate.isValid() ? issuedDate : undefined}
                    onChange={handleChangeDate(this.props)("issuedDate")}
                    onBlur={handleBlur}
                    label={strings.date_of_issue}
                    placeholder={strings.date_of_issue}
                  />
                  <InputField
                    validatestatus={
                      touched.issuedPlace && errors.issuedPlace
                        ? "error"
                        : undefined
                    }
                    help={
                      touched.issuedPlace && errors.issuedPlace
                        ? errors.issuedPlace
                        : ""
                    }
                    name="issuedPlace"
                    type="text"
                    disabled={!editable}
                    value={values.issuedPlace}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={strings.place_of_issue}
                    label={strings.place_of_issue}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    validatestatus={
                      touched.numberCN && errors.numberCN ? "error" : undefined
                    }
                    help={
                      touched.numberCN && errors.numberCN ? errors.numberCN : ""
                    }
                    name="numberCN"
                    type="text"
                    disabled={!editable}
                    value={values.numberCN}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={strings.number_cn}
                    label={strings.number_cn}
                  />
                  <InputField
                    validatestatus={
                      touched.idTax && errors.idTax ? "error" : undefined
                    }
                    help={touched.idTax && errors.idTax ? errors.idTax : ""}
                    name="idTax"
                    type="text"
                    disabled={!editable}
                    value={values.idTax}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={strings.id_tax}
                    label={strings.id_tax}
                  />
                  <DatePickerField
                    disabled={!editable}
                    validateStatus={
                      touched.expirationDate && errors.expirationDate
                        ? "error"
                        : undefined
                    }
                    help={
                      touched.expirationDate && errors.expirationDate
                        ? errors.expirationDate
                        : ""
                    }
                    name="expirationDate"
                    value={
                      expirationDate.isValid() ? expirationDate : undefined
                    }
                    onChange={handleChangeDate(this.props)("expirationDate")}
                    onBlur={handleBlur}
                    label={strings.date_activity}
                    placeholder={strings.date_activity}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputField
                    validatestatus={
                      touched.address1 && errors.address1 ? "error" : undefined
                    }
                    help={
                      touched.address1 && errors.address1 ? errors.address1 : ""
                    }
                    name="address1"
                    disabled={!editable}
                    value={values.address1}
                    onChange={this.handleChange}
                    onBlur={handleBlur}
                    placeholder={strings.address}
                    label={strings.address}
                  />
                </div>
                <div className="col-6">
                  <SelectField
                    validateStatus={
                      touched.provinceId && errors.provinceId
                        ? "error"
                        : undefined
                    }
                    disabled={!editable}
                    help={
                      touched.provinceId && errors.provinceId
                        ? errors.provinceId
                        : ""
                    }
                    data={provin || []}
                    value={values.provinceId}
                    onChange={(value) => {
                      this.props.fechDistrict(value);
                      setFieldValue("provinceId", value);
                      setFieldValue("districtId", undefined);
                      setFieldValue("wardsId", undefined);

                      setFieldTouched("provinceId");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.city_or_province}
                    label={strings.city_or_province}
                  />
                </div>
                <div className="col-6">
                  <SelectField
                    validateStatus={
                      touched.districtId && errors.districtId
                        ? "error"
                        : undefined
                    }
                    disabled={!editable}
                    help={
                      touched.districtId && errors.unitId
                        ? errors.districtId
                        : ""
                    }
                    data={district || []}
                    value={values.districtId}
                    onChange={(value) => {
                      this.props.fechWard(value);
                      setFieldValue("districtId", value);
                      setFieldValue("wardsId", undefined);

                      setFieldTouched("districtId");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.district}
                    label={strings.district}
                  />
                </div>
                <div className="col-6">
                  <SelectField
                    validateStatus={
                      touched.wardsId && errors.wardsId ? "error" : undefined
                    }
                    disabled={!editable}
                    help={
                      touched.wardsId && errors.wardsId ? errors.wardsId : ""
                    }
                    data={ward || []}
                    value={values.wardsId}
                    onChange={(value) => {
                      setFieldValue("wardsId", value);
                      setFieldTouched("wardsId");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.ward}
                    label={strings.ward}
                  />
                </div>
              </div>
              <SelectField
                mode="multiple"
                validateStatus={
                  touched.cultivation && errors.cultivation
                    ? "error"
                    : undefined
                }
                disabled={!editable}
                help={
                  touched.cultivation && errors.cultivation
                    ? errors.cultivation
                    : ""
                }
                data={cultivation || []}
                value={values.cultivation}
                onChange={(value) => {
                  setFieldValue("cultivation", value);
                  setFieldTouched("cultivation");
                }}
                iconEnd="caret-down"
                placeholder={strings.type_of}
                label={strings.type_of}
              />
              <SelectField
                validateStatus={
                  touched.productionTypesIds && errors.productionTypesIds
                    ? "error"
                    : undefined
                }
                disabled={!editable}
                help={
                  touched.productionTypesIds && errors.productionTypesIds
                    ? errors.productionTypesIds
                    : ""
                }
                mode="multiple"
                data={profession || []}
                value={values.productionTypesIds}
                onChange={(value) => {
                  setFieldValue("productionTypesIds", value);
                  setFieldTouched("productionTypesIds");
                }}
                iconEnd="caret-down"
                placeholder={strings.branch_product}
                label={strings.branch_product}
              />
              <SelectField
                validateStatus={
                  touched.groupManagementIds && errors.groupManagementIds
                    ? "error"
                    : undefined
                }
                disabled={!editable}
                help={
                  touched.groupManagementIds && errors.groupManagementIds
                    ? errors.groupManagementIds
                    : ""
                }
                mode="multiple"
                data={groupManagerment || []}
                value={values.groupManagementIds}
                onChange={(value) => {
                  setFieldValue("groupManagementIds", value);
                  setFieldTouched("groupManagementIds");
                }}
                iconEnd="caret-down"
                placeholder={strings.management_grouping}
                label={strings.management_grouping}
              />
            </Card>
          </div>
          <div className="col-12 col-md-6 ">
            <Card className={!editable ? "avoid-click" : ""}>
              <div className="row">
                <div className="col-6">
                  <OneUploader
                    label={strings.before_upload_GCN}
                    images={{ img: values.images, index: 0 }}
                    onChange={(e) => this.handleChangeCertificate(e, 1)}
                  />
                </div>
                <div className="col-6" style={{ marginBottom: 25 }}>
                  <OneUploader
                    label={strings.after_upload_GCN}
                    images={{ img: values.images, index: 1 }}
                    onChange={(e) => this.handleChangeCertificate(e, 2)}
                  />
                </div>
                <div className="col-6">
                  <OneUploader
                    label={strings.human_and_GCN_upload}
                    images={{ img: values.images, index: 2 }}
                    onChange={(e) => this.handleChangeCertificate(e, 3)}
                  />
                </div>
                <div className="col-6">
                  <OneUploader
                    label={strings.other}
                    images={{ img: values.images, index: 3 }}
                    onChange={(e) => this.handleChangeCertificate(e, 4)}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div>
          <UIButton
            type="primary"
            htmlType="submit"
            disabled={!isValid || !editable}
            onClick={this.handleSaveValues}
          >
            <span>{strings.save}</span>
          </UIButton>
          <UIButton
            type="secondary"
            className="ml-2"
            htmlType="button"
            disabled={!editable}
            onClick={handleCancelChange(this.props)}
          >
            <span>{strings.cancel}</span>
          </UIButton>
        </div>
        <div className="mt-3">
          <Tabs defaultActiveKey="1" type="card" size={"middle"}>
            <TabPane tab={strings.history} key="1">
              <TableEditable
                changedData={this.handleChangeDataSource}
                editable={editable}
                addRowAble={false}
                selectedKey={values.key}
                onSelect={this.handleSelectRow}
                selectable={true}
                dataSource={this.props.info.businessLicenses}
                columns={columns}
              />
              <div style={{ marginTop: "16px" }}>
                <UIButton
                  style={{ float: "right" }}
                  type="primary"
                  className="ml-2"
                  htmlType="button"
                  disabled={!editable}
                  onClick={() => this.props.nextStept(2)}
                >
                  <span>{strings.countinue}</span>
                </UIButton>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    getType,
    getProvinces,
    getDistrict,
    getPersionnalProfile,
    getWards,
  }),
  withState("certificationTypes", "setCertificationTypes", []),
  withState("provin", "setProvin", []),
  withState("district", "setDistrict", []),
  withState("cultivation", "setcultivation", []),
  withState("profession", "setProfession", []),
  withState("groupManagerment", "setGroupManagerment", []),
  withState("arrayImage", "setArrayImg", [null, null, null, null]),

  withState("ward", "setWard", []),
  withHandlers({
    handleCancelChange: () => (props) => () => {
      const { setValues, setTouched, setFieldValue } = props;
      setValues({});
      setTouched({});
      setFieldValue("images", "");
    },
    handleChangeIssuedDate: () => (props) => (value) => {
      const { setFieldValue, setFieldTouched } = props;
      setFieldTouched("issuedDate");
      if (value) {
        setFieldValue(
          "issuedDate",
          value.startOf("day").utc().format(UtilDate.formatDateTimeServer)
        );
      } else {
        setFieldValue("issuedDate", undefined);
      }
    },
    handleChangeDate: (_) => (props) => (name) => (value) => {
      const { setFieldValue, setFieldTouched } = props;
      setFieldTouched(name);
      if (value) {
        setFieldValue(
          name,
          value.endOf("day").utc().format(UtilDate.formatDateTimeServer)
        );
      } else {
        setFieldValue(name, undefined);
      }
    },
    handleChangeExpirationDate: () => (props) => (value) => {
      const { setFieldValue, setFieldTouched } = props;
      setFieldTouched("expirationDate");
      if (value) {
        setFieldValue(
          "expirationDate",
          value.endOf("day").utc().format(UtilDate.formatDateTimeServer)
        );
      } else {
        setFieldValue("expirationDate", undefined);
      }
    },
    fetchGroupmanagermentTypes: (props) => () => {
      const { getType, setGroupManagerment } = props;
      getType("GROUP_MANAGEMENT")
        .then(({ res }) => {
          setGroupManagerment(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },

    fechWard: (props) => (id) => {
      const { getWards, setWard } = props;
      if (id)
        getWards(id)
          .then(({ res }) => {
            setWard(
              getArray(res, undefined, []).map((item) => {
                return {
                  value: item.id,
                  label: item.name || "",
                };
              })
            );
          })
          .catch((err) => showError(err));
    },
    fechDistrict: (props) => (id) => {
      const { getDistrict, setDistrict } = props;
      if (id)
        getDistrict(id)
          .then(({ res }) => {
            setDistrict(
              getArray(res, undefined, []).map((item) => {
                return {
                  value: item.id,
                  label: item.name || "",
                };
              })
            );
          })
          .catch((err) => showError(err));
    },
    fechGetProvince: (props) => () => {
      const { getProvinces, setProvin } = props;
      getProvinces(1)
        .then(({ res }) => {
          setProvin(
            getArray(res, undefined, []).map((item) => {
              return {
                value: item.id,
                label: item.name || "",
              };
            })
          );
        })
        .catch((err) => showError(err));
    },
    fetchTypeCulti: (props) => () => {
      const { getType, setcultivation } = props;
      getType("CULTIVATION")
        .then(({ res }) => {
          setcultivation(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fetchTypeProfess: (props) => () => {
      const { getType, setProfession } = props;
      getType("PRODUCT")
        .then(({ res }) => {
          setProfession(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fetchCertificationTypes: (props) => () => {
      const { getType, setCertificationTypes } = props;
      getType("BUSINESS_LICENSE")
        .then(({ res }) => {
          setCertificationTypes(
            getArray(res, undefined, []).map((item) => ({
              value: item.name || "",
              // label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
  }),
  withFormik({
    displayName: "licenceForm",
    enableReinitialize: true,
    validationSchema: validationSchema,

    mapPropsToValues: (props) => {
      return {
        images: getString(props, "info.businessLicenses.images", ""),
      };
    },
    handleSubmit: async (values) => {},
  }),
  lifecycle({
    componentDidMount() {
      const {
        fetchCertificationTypes,
        fetchTypeCulti,
        fechGetProvince,
        fetchTypeProfess,
        fechDistrict,
        fechWard,
        fetchGroupmanagermentTypes,
      } = this.props;
      const districtId = getNumber(
        this.props.info.businessLicenses,
        "districtId",
        undefined
      );
      const provinceId = getNumber(
        this.props.info.businessLicenses,
        "provinceId",
        undefined
      );

      if (provinceId) fechDistrict(provinceId);

      if (districtId) {
        fechWard(districtId);
      }
      fetchCertificationTypes();
      fechGetProvince();
      fetchTypeProfess();
      fetchTypeCulti();
      fetchGroupmanagermentTypes();
    },
  })
)(LicenceForm);
