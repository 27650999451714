import React from "react";
import {Card, message} from "antd";
import {connect} from "react-redux";
import DragAndDropProcedure from "./DragAndDropProcedure.js";
import * as PATH from "~/configs/routesConfig";
import FormProcedure from "./FormProcedure";
import {createCultivationProcess, editCultivationProcess} from "~/state/ducks/appApis/actions";
import {getDetailProcess, deleteProcess} from "~/state/ducks/authUser/actions";
import strings from "~/localization";
import ActivityForm from "~/views/container/ActivityForm";
import Loading from "~/views/presentation/ui/loading";

import _ from "lodash";

import {showError} from "~/configs/ServerErrors";
import {mapArrayObjectToAPI} from "~/views/utilities/helpers/utilObject";

import {StepsFormContainerStyle} from "~/views/container/styles";
import {withRouterInnerRef} from "~/views/container/commons";

const emptyObject = () => ({
  typeProcedure: undefined,
  nameProcedure: undefined,
  description: undefined,
  process: [],
});
const CHANGE_NAME_CREATE = [
  {currName: "process", changeName: "steps"},
  {currName: "typeProcedure", changeName: "typeId"},
];
const CHANGE_NAME_GET_DETAIL = [
  {currName: "steps", changeName: "process"},
  {currName: "typeId", changeName: "typeProcedure"},
];

class UpdateProce extends React.PureComponent {
  constructor(props) {
    super(props);
    let editable = true;

    const {match} = this.props;
    switch (match.path) {
      case PATH.VIEW_FARM_PROCEDURE_PATH:
      case PATH.VIEW_FARM_SEASON_PROCEDURE_PATH:
        {
          editable = false;
        }
        break;

      case PATH.EDIT_FARM_PROCEDURE_PATH:
      case PATH.EDIT_FARM_SEASON_PROCEDURE_PATH:
        {
          editable = true;
        }
        break;
    }
    this.state = {
      viewAndEdit: false,
      process: {},
      editable,
      procedures: {},
      loading: false,
    };
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);

    // this.onDelete = this.onDelete.bind(this);
  }
  onDelete() {
    const {match, deleteProcess, history} = this.props;
    let processId = +match.params.idProcedure;
    deleteProcess(processId)
      .then(({}) => {
        message.success(strings.delete_procedure_successful);
        history.push(PATH.ALL_FARM_PROCEDURE_PATH.replace(":type", "ALL"));
      })
      .catch((err) => showError(err));
  }
  onCreate() {
    const {createCultivationProcess, history} = this.props;
    let objectApi = mapArrayObjectToAPI(this.state.process, CHANGE_NAME_CREATE);
    console.error(mapArrayObjectToAPI(this.state.process, CHANGE_NAME_CREATE));

    objectApi = {
      ...objectApi,
      steps: objectApi.steps.filter((val) => val.name !== "Bắt Đầu"),
    };
    createCultivationProcess(objectApi)
      .then(({}) => {
        message.success(strings.create_procedure_successful);
        history.push(PATH.ALL_FARM_PROCEDURE_PATH.replace(":type", "ALL"));
      })
      .catch((err) => showError(err));
  }
  _handleFecthProcess() {
    const {match, getDetailProcess} = this.props;
    let processId = +match.params.idProcedure;

    if (!processId) {
      this.setState({process: emptyObject(), viewAndEdit: false});
    } else {
      this.setState({loading: true});

      getDetailProcess(processId)
        .then(({res}) => {
          const objecReponse = mapArrayObjectToAPI(res, CHANGE_NAME_GET_DETAIL);
          this.setState({
            process: objecReponse,
            viewAndEdit: true,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({loading: false});

          showError(err);
        });
    }
  }
  componentDidMount() {
    this._handleFecthProcess();
  }
  onSave() {
    const {editCultivationProcess, history} = this.props;
    let objectApi = mapArrayObjectToAPI(this.state.process, CHANGE_NAME_CREATE);
    console.error("hello", mapArrayObjectToAPI(this.state.process, CHANGE_NAME_CREATE));
    objectApi = {
      ...objectApi,
      steps: objectApi.steps.filter((val) => val.name !== "Bắt Đầu"),
    };
    editCultivationProcess(objectApi)
      .then(({}) => {
        message.success(strings.create_procedure_successful);
        history.push(PATH.ALL_FARM_PROCEDURE_PATH.replace(":type", "ALL"));
      })
      .catch((err) => showError(err));
  }
  _handleCheckRadio = (e) => {
    const {setFieldValue} = this.props;
    setFieldValue("type", e.target.value);
  };
  render() {
    const {editable} = this.state;
    return (
      <StepsFormContainerStyle>
        <div className='row' style={{width: "100%"}}>
          <div className='profile-view'>
            <Card bordered={false}>
              <FormProcedure editable={editable} process={this.state.process} />
              <DragAndDropProcedure viewAndEdit={this.state.viewAndEdit} editable={editable} process={this.state.process} />
              {this.state.loading && <Loading />}
            </Card>
          </div>
          {/* <div style={{background: "white"}} className='col-lg-3 col-md-12 events-view'> */}
          <div className="events-view">
            <ActivityForm />
            </div>
          {/* </div> */}
        </div>
      </StepsFormContainerStyle>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      createCultivationProcess,
      editCultivationProcess,
      getDetailProcess,
      deleteProcess,
    },
    null,
    {
      forwardRef: true,
    }
  )(UpdateProce)
);
