import React from "react";
import { Card, message } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import DragAndDropProcedure from "./DragAndDropProcedure.js";
import * as PATH from "~/configs/routesConfig";
import FormProductCost from "./FormProductCost";
import {
  deleteProductCost,
  createPakagingCode,
  updateProductCost,
  getDetailProductCost,
  createManufacturingCost,
} from "~/state/ducks/appApis/actions";
import {
  getArray,
  getNumber,
  getObject,
  getString,
} from "~/views/utilities/helpers/utilObject";

import strings from "~/localization";
import ActivityForm from "~/views/container/ActivityForm";
import Loading from "~/views/presentation/ui/loading";

import _ from "lodash";

import { showError } from "~/configs/ServerErrors";
import { mapArrayObjectToAPI } from "~/views/utilities/helpers/utilObject";
import { StepsFormContainerStyle } from "~/views/container/styles";
import { withRouterInnerRef } from "~/views/container/commons";

const emptyObject = () => ({
  name: undefined,
  productId: undefined,
  details: undefined,
  farmingSeasonId: undefined,
  errors: undefined,
  classifications: [],
  details: [],
});

class UpdateProductCost extends React.PureComponent {
  constructor(props) {
    super(props);
    let editable = true;

    const { match } = this.props;
    switch (match.path) {
      case PATH.VIEW_FARM_PRODUCT_COST_PATH:
        {
          editable = false;
        }
        break;

      case PATH.EDIT_FARM_PRODUCT_COST_PATH:
        {
          editable = true;
        }
        break;
    }
    this.state = {
      viewAndEdit: false,
      pakaging: {},
      editable,
      procedures: {},
      loading: false,
    };
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);

    // this.onDelete = this.onDelete.bind(this);
  }
  replaceId(path) {
    const { match } = this.props;

    const id = match.params.id;
    const idSeason = match.params.idSeason;
    const idProcedure = match.params.idProcedure;
    const idPackagingCost = match.params.idPackagingCost;
    const idProductCost = match.params.idProductCost;
    return path
      .replace(":idSeason", idSeason)
      .replace(":idProcedure", idProcedure)
      .replace(":idPackagingCost", idPackagingCost)
      .replace(":idProductCost", idProductCost)
      .replace(":id", id);
  }
  onDelete() {
    const { match, deleteProductCost, history } = this.props;
    let processId = +match.params.idProductCost;
    deleteProductCost(processId)
      .then(({}) => {
        message.success(strings.delete_product_cost_sucess);
        history.push(this.replaceId(PATH.ALL_FARM_PRODUCT_COST_PATH));
      })
      .catch((err) => {});
  }
  getMapObject = () => {
    const { pakaging } = this.state;
    let classificationsTemp = getArray(pakaging, "classifications", []);
    if (classificationsTemp.length !== 0)
      if (
        classificationsTemp[classificationsTemp.length - 1][
          `validateOption${classificationsTemp.length - 1}`
        ] !== undefined ||
        classificationsTemp[classificationsTemp.length - 1][
          `validateValue${classificationsTemp.length - 1}`
        ] !== undefined
      ) {
        message.error(strings.please_enter_a_valid_percent_allocation, 3);
        return false;
      }
    if (classificationsTemp.length === 0) {
      message.error(strings.please_enter_a_valid_percent_allocation, 3);
      return false;
    }
    let arrTempClassification = classificationsTemp.map((item, index) => {
      return {
        typeId: item[`typeId${index}`],
        value: item[`value${index}`],
      };
    });
    _.remove(arrTempClassification, (item) => item.typeId === undefined);
    let info = {
      ...this.state.pakaging,
      classifications: arrTempClassification,
      avrQuantity: {
        value: getNumber(pakaging, "avrQuantity"),
        unitName: "kg/trụ/vụ",
      },
      avrSeason: {
        value: getNumber(pakaging, "avrSeason"),
        unitName: "vụ",
      },
      seedDensity: {
        value: getNumber(pakaging, "seedDensity"),
        unitName: "trụ",
      },
      yield: {
        value: getNumber(pakaging, "yield"),
        unitName: "tấn/ha/năm",
      },
    };
    return info;
  };

  onCreate() {
    const { match, createManufacturingCost, history } = this.props;
    const farmSeason = this.getMapObject();

    if (!farmSeason) return;
    const errorMess = getObject(this.state.pakaging.errors, undefined, {});

    if (!_.isEmpty(errorMess)) {
      message.error(Object.values(farmSeason.errors)[0]);
      return;
    }
    let getMapObject = this.state.pakaging;

    if (!this.getMapObject()) return;

    createManufacturingCost({
      ...this.getMapObject(),
      farmingSeasonId: parseInt(match.params.idSeason),
    })
      .then(({}) => {
        message.success(strings.create_pakaging_cost_sucess);
        history.push(this.replaceId(PATH.ALL_FARM_PRODUCT_COST_PATH));
      })
      .catch((err) => showError(err));
  }
  _handleFecthProcess() {
    const { match, getDetailProductCost } = this.props;
    let processId = +match.params.idProductCost;

    if (!processId) {
      this.setState({ pakaging: emptyObject(), viewAndEdit: false });
    } else {
      this.setState({ loading: true });

      getDetailProductCost(processId)
        .then(({ res }) => {
          let objectReopns = res;
          let classificationsTemp = getArray(res, "classifications", []);
          let classifications = _.sortBy(classificationsTemp, ["typeId"]);
          objectReopns = {
            ...objectReopns,
            name: getString(res, "name"),
            productId: getNumber(res, "productId"),
            avrSeason: getNumber(res, "avrSeason.value"),
            avrQuantity: getNumber(res, "avrQuantity.value"),
            yield: getNumber(res, "yield.value"),
            seedDensity: getNumber(res, "seedDensity.value"),
            sizePercentsIds: getArray(res, "sizePercentsIds"),
            classifications: classifications.map((item, index) => {
              return {
                id: index,
                ["typeId" + index]: item.typeId,
                ["value" + index]: item.value,
                ["percentMax" + index]: 100,
              };
            }),
          };

          this.setState({
            pakaging: objectReopns,
            viewAndEdit: true,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          showError(err);
        });
    }
  }
  componentDidMount() {
    this._handleFecthProcess();
  }
  onSave() {
    const { updateProductCost, history, match } = this.props;
    const farmSeason = this.getMapObject();

    if (!farmSeason) return;
    const errorMess = getObject(this.state.pakaging.errors, undefined, {});

    if (!_.isEmpty(errorMess)) {
      message.error(Object.values(farmSeason.errors)[0]);
      return;
    }

    updateProductCost({
      ...this.getMapObject(),
      id: match.params.idProductCost,
    })
      .then(({}) => {
        message.success(strings.create_procedure_successful);
        history.push(this.replaceId(PATH.ALL_FARM_PRODUCT_COST_PATH));
      })
      .catch((err) => showError(err));
  }
  _handleCheckRadio = (e) => {
    const { setFieldValue } = this.props;
    setFieldValue("type", e.target.value);
  };
  render() {
    const { editable } = this.state;
    return (
      <StepsFormContainerStyle>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-lg-9 col-md-12 profile-view">
            <Card bordered={false}>
              <FormProductCost
                editable={editable}
                pakaging={this.state.pakaging}
              />
              {/* <DragAndDropProcedure
                viewAndEdit={this.state.viewAndEdit}
                editable={editable}
                pakaging={this.state.pakaging}
              /> */}
              {this.state.loading && <Loading />}
            </Card>
          </div>
          <div
            style={{ background: "white" }}
            className="col-lg-3 col-md-12 events-view">
            <ActivityForm />
          </div>
        </div>
      </StepsFormContainerStyle>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      createPakagingCode,
      updateProductCost,
      getDetailProductCost,
      deleteProductCost,
      createManufacturingCost,
    },
    null,
    {
      forwardRef: true,
    }
  )(UpdateProductCost)
);
