import React from "react";
import {
  Breadcrumb,
  Button,
  Modal,
  Radio,
  Dropdown,
  Menu,
  message,
} from "antd";
import * as PATH from "~/configs/routesConfig";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import strings from "~/localization";
import { SegmentedControl } from "segmented-control-react";
import ConfirmModal from "./ConfirmModal";
import {
  SearchStyled,
  ActionStyled,
  SegmentedControlContainer,
  ContainerStyled,
  GroupButtonStyled,
  FilterGroup,
} from "./styles";
import {
  CloseOutlined,
  EditOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
  fontWeight: 500,
};

const TYPES_OF_PROFILE = [
  { links: PATH.CREATE_PERSONAL_PROFILE_PATH, name: strings.persional_profile },
  { links: PATH.CREATE_FARM_PROFILE_PATH, name: strings.farm },
  { links: PATH.CREATE_ENTERPRISE_PROFILE_PATH, name: strings.business },
  { links: PATH.CREATE_COOPERATIVE_PROFILE_PATH, name: strings.cooperative },
  {
    links: PATH.CREATE_COOPERATIVE_VENTURE_PROFILE_PATH,
    name: strings.union_of_cooperative,
  },
  { links: PATH.CREATE_ORGANIZATION_PROFILE_PATH, name: strings.organization },
];

class ProfileNav extends React.PureComponent {
  constructor(props) {
    super(props);
    if (global.viewAsBlocks === undefined) {
      global.viewAsBlocks = true;
    }
    this.state = {
      viewAsBlocks: global.viewAsBlocks,
      isChooseProfileType: false,
    };
  }

  componentDidMount() {
    this.handleUpdateViewStyle(this.state.viewAsBlocks);
  }

  handleChangeView = () => {
    let viewAsBlocks = this.state.viewAsBlocks;
    this.setState({ viewAsBlocks: !viewAsBlocks });
    global.viewAsBlocks = !viewAsBlocks;
    this.handleUpdateViewStyle(!viewAsBlocks);
  };

  handleUpdateViewStyle = (viewAsBlocks) => {
    const { changeViewStyle } = this.props;
    changeViewStyle && changeViewStyle(viewAsBlocks);
  };

  /**
   * Des: render button name and action of it
   * Input:
   * @param {*} namePrimary name button
   * @param {*} actionPrimary => history.push(action) always replace ":id" to {match.params.id} if exist
   * @param {*} name nameSecond button
   * @param {*} action => history.push(actionSecondary)
   * Output: 2 ReactNode Button
   * Author: ithoangtan (tan.hoang@vslsoft.com)
   * Date: 9:10 Sep 18, 2020
   */
  mRenderButton(
    name = strings.create,
    action = "/",
    nameSecond = strings.cancel,
    actionSecond = "/",
    buttonIcon = ""
  ) {
    const { history, match, onSave, onCreate } = this.props;
    const { innerWidth } = window;
    let id = match.params.id || undefined;
    let idSeason = match.params.idSeason || undefined;
    let idProcedure = match.params.idProcedure || undefined;
    let actionReplaceId = action
      .replace(":id", id ? id : "")
      .replace(":idSeason", idSeason ? idSeason : "")
      .replace(":idProcedure", idProcedure ? idProcedure : "");
    let actionSecondReplaceId = actionSecond
      .replace(":id", id ? id : "")
      .replace(":idSeason", idSeason ? idSeason : "")
      .replace(":idProcedure", idProcedure ? idProcedure : "");

    let iconPrimary = <EditOutlined />;
    if (name === strings.save) iconPrimary = <SaveOutlined />;
    if (name === strings.create || buttonIcon === "create")
      iconPrimary = <PlusCircleOutlined />;
    if (action === "") iconPrimary = "";
    let iconSecondary = <PlusCircleOutlined />;
    if (nameSecond === strings.cancel) iconSecondary = <CloseOutlined />;
    if (nameSecond === strings.import) iconSecondary = <UploadOutlined />;

    return (
      <>
        <Button
          className="ml-3"
          type="primary"
          block
          icon={iconPrimary}
          onClick={
            action === "onSave"
              ? onSave
              : action === "onCreate"
              ? onCreate
              : () => history.push(actionReplaceId)
          }>
          {innerWidth >= 600 && name}
        </Button>
        <Button
          icon={iconSecondary}
          className="ml-2"
          block
          onClick={() => {
            if (actionSecondReplaceId === "/" || nameSecond === strings.cancel)
              return history.goBack();
            else return history.push(actionSecondReplaceId);
          }}>
          {innerWidth >= 600 && nameSecond}
        </Button>
      </>
    );
  }

  renderModalChooseTypeCreate() {
    const { history } = this.props;

    return (
      <Modal
        title={strings.create_profile}
        visible={this.state.isChooseProfileType}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ disabled: this.state.profile_type === undefined }}
        okText={strings.continue}
        onOk={() => {
          history.push(this.state.profile_type);
        }}
        onCancel={() => {
          this.setState({ isChooseProfileType: false });
        }}>
        <Radio.Group
          onChange={(e) => {
            this.setState({ profile_type: e.target.value });
          }}
          value={this.state.profile_type}>
          {TYPES_OF_PROFILE.map((item) => (
            <Radio style={radioStyle} value={item.links}>
              {item.name}
            </Radio>
          ))}
        </Radio.Group>
      </Modal>
    );
  }

  renderMenuAction(stringDelete, onDelete) {
    const menu = (
      <Menu style={{ width: "100px" }}>
        <Menu.Item>
          <span>
            <i className="fa fa-floppy-o mr-2" />
            {strings.storage}
          </span>
        </Menu.Item>
        <Menu.Item>
          <ConfirmModal
            title={strings.confirmation}
            message={stringDelete}
            okText={strings.yes}
            cancelText={strings.no}
            onOk={() => {
              onDelete && onDelete();
            }}>
            <span>
              <i className="fa fa-trash-o mr-2" />
              {strings.delete}
            </span>
          </ConfirmModal>
        </Menu.Item>
        <Menu.Item>
          <span>
            <i className="fa fa-files-o mr-2" />
            {strings.copy}
          </span>
        </Menu.Item>
      </Menu>
    );
    return (
      <ActionStyled className="col-6">
        <Dropdown overlay={menu} placement="bottomRight" className="m-0">
          <span>
            {strings.action} <i className="fa fa-caret-down" />
          </span>
        </Dropdown>
      </ActionStyled>
    );
  }

  renderFilterGroup() {
    // TODO Chỗ này cần phải làm "Bộ lọc" cho trang
    // Sắp xếp theo tên A-Z Z-A theo icon của ant-design
    return (
      <FilterGroup className="col-12">
        <span className="filter" style={{ cursor: "no-drop" }}>
          <i className="fa fa-filter" /> {strings.filter}
        </span>
        <div className="mr-3">
          <SegmentedControlContainer>
            <SegmentedControl
              segments={[
                { name: <i className="fa fa-th-large" /> },
                { name: <i className="fa fa-list" /> },
              ]}
              selected={this.state.viewAsBlocks ? 0 : 1}
              variant="base"
              onChangeSegment={this.handleChangeView}
            />
          </SegmentedControlContainer>
        </div>
      </FilterGroup>
    );
  }

  renderBreadcrumbAndSearch() {
    const { history, breadcrumb = [{ title: "", href: "" }] } = this.props;
    // TODO Chỗ này cần search bên trong danh sách profiles, truyền key xuống profile khi onChange, bounce 300ms"
    return (
      <>
        <div className="col-6 ">
          <Breadcrumb className="ml-3" separator=">">
            {(breadcrumb || []).map((item) => (
              <Breadcrumb.Item
                style={{ cursor: item.href ? "pointer" : "default" }}
                onClick={() => {
                  item.href && history.push(item.href);
                }}>
                {item.title || ""}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
        <div className="col-6">
          <SearchStyled
            className="mr-3"
            suffix={<i className="fa fa-search" />}
            placeholder={strings.search_placeholder}
            enterButton
            onClick={() => message.info(strings.feature_development)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) message.info(strings.feature_development);
            }}
          />
        </div>
      </>
    );
  }

  renderButtons = () => {
    const { match } = this.props;
    const { innerWidth } = window;

    switch (match.path) {
      case PATH.ALL_PROFILES_PATH:
      case PATH.LIST_PROFILES_PATH: {
        return (
          <>
            <Button
              className="ml-3"
              type="primary"
              block
              onClick={() => this.setState({ isChooseProfileType: true })}
              icon={<PlusCircleOutlined />}>
              {innerWidth >= 600 && strings.create_profile}
            </Button>
            <Button className="ml-2" block icon={<UploadOutlined />}>
              {innerWidth >= 600 && strings.import}
            </Button>
          </>
        );
      }

      case PATH.VIEW_PERSONAL_PROFILE_PATH:
        return this.mRenderButton(
          strings.edit_profile,
          PATH.EDIT_PERSONAL_PROFILE_PATH,
          strings.create_profile,
          PATH.CREATE_PERSONAL_PROFILE_PATH
        );
      case PATH.EDIT_PERSONAL_PROFILE_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_PERSONAL_PROFILE_PATH
        );
      case PATH.CREATE_PERSONAL_PROFILE_PATH:
      case PATH.CREATE_FARM_PROFILE_PATH:
      case PATH.CREATE_ENTERPRISE_PROFILE_PATH:
        return this.mRenderButton(
          strings.save,
          "onCreate",
          strings.cancel,
          PATH.ALL_PROFILES_PATH
        );
      case PATH.VIEW_ENTERPRISE_PROFILE_PATH:
        return this.mRenderButton(
          strings.edit_profile,
          PATH.EDIT_ENTERPRISE_PROFILE_PATH,
          strings.create_profile,
          PATH.CREATE_ENTERPRISE_PROFILE_PATH
        );
      case PATH.EDIT_ENTERPRISE_PROFILE_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_ENTERPRISE_PROFILE_PATH
        );
      case PATH.VIEW_FARM_PROFILE_PATH:
        return this.mRenderButton(
          strings.edit_profile,
          PATH.EDIT_FARM_PROFILE_PATH,
          strings.create_profile,
          PATH.CREATE_FARM_PROFILE_PATH
        );
      case PATH.EDIT_FARM_PROFILE_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_FARM_PROFILE_PATH
        );
      case PATH.VIEW_COOPERATIVE_PROFILE_PATH:
        return this.mRenderButton(
          strings.edit_profile,
          PATH.EDIT_COOPERATIVE_PROFILE_PATH,
          strings.create_profile,
          PATH.CREATE_COOPERATIVE_PROFILE_PATH
        );
      case PATH.EDIT_COOPERATIVE_PROFILE_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_COOPERATIVE_PROFILE_PATH
        );
      case PATH.CREATE_COOPERATIVE_PROFILE_PATH:
        return this.mRenderButton(
          strings.save,
          "onCreate",
          strings.cancel,
          PATH.ALL_PROFILES_PATH
        );
      case PATH.VIEW_COOPERATIVE_VENTURE_PROFILE_PATH:
        return this.mRenderButton(
          strings.edit_profile,
          PATH.EDIT_COOPERATIVE_VENTURE_PROFILE_PATH,
          strings.create_profile,
          PATH.CREATE_COOPERATIVE_VENTURE_PROFILE_PATH
        );
      case PATH.EDIT_COOPERATIVE_VENTURE_PROFILE_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_COOPERATIVE_VENTURE_PROFILE_PATH
        );
      case PATH.CREATE_COOPERATIVE_VENTURE_PROFILE_PATH:
        return this.mRenderButton(
          strings.save,
          "onCreate",
          strings.cancel,
          PATH.ALL_PROFILES_PATH
        );
      case PATH.VIEW_ORGANIZATION_PROFILE_PATH:
        return this.mRenderButton(
          strings.edit_profile,
          PATH.EDIT_ORGANIZATION_PROFILE_PATH,
          strings.create_profile,
          PATH.CREATE_ORGANIZATION_PROFILE_PATH
        );
      case PATH.EDIT_ORGANIZATION_PROFILE_PATH:
        return this.mRenderButton(
          strings.save,
          "onSave",
          strings.cancel,
          PATH.VIEW_ORGANIZATION_PROFILE_PATH
        );
      case PATH.CREATE_ORGANIZATION_PROFILE_PATH:
        return this.mRenderButton(
          strings.save,
          "onCreate",
          strings.cancel,
          PATH.LIST_ORGANIZATION_PROFILES_PATH
        );
      default:
        return this.mRenderButton();
    }
  };

  renderRightContent = () => {
    const { match, onDelete } = this.props;
    let stringDelete = strings.remove_profile_confirmation;

    switch (match.path) {
      case PATH.ALL_PROFILES_PATH:
      case PATH.LIST_PROFILES_PATH:
        return this.renderFilterGroup();

      case PATH.VIEW_PERSONAL_PROFILE_PATH:
      case PATH.VIEW_FARM_PROFILE_PATH:
      case PATH.VIEW_COOPERATIVE_PROFILE_PATH:
      case PATH.VIEW_ENTERPRISE_PROFILE_PATH:
      case PATH.VIEW_COOPERATIVE_VENTURE_PROFILE_PATH:
      case PATH.VIEW_ORGANIZATION_PROFILE_PATH:
      case PATH.EDIT_PERSONAL_PROFILE_PATH:
      case PATH.EDIT_ENTERPRISE_PROFILE_PATH:
      case PATH.EDIT_COOPERATIVE_PROFILE_PATH:
      case PATH.EDIT_COOPERATIVE_VENTURE_PROFILE_PATH:
      case PATH.EDIT_ORGANIZATION_PROFILE_PATH:
      case PATH.EDIT_FARM_PROFILE_PATH:
        return this.renderMenuAction(stringDelete, onDelete);
      default:
        break;
    }
  };

  render() {
    return (
      <ContainerStyled className={this.props.className}>
        <div className="row">{this.renderBreadcrumbAndSearch()}</div>
        <div className="row">
          <GroupButtonStyled className="col-6 ">
            {this.renderButtons()}
          </GroupButtonStyled>
          <div className="col-6 d-flex flex-row justify-content-end align-items-baseline">
            {" "}
            {this.renderRightContent()}
          </div>
        </div>
        {this.renderModalChooseTypeCreate()}
      </ContainerStyled>
    );
  }
}
export default compose(withRouter)(ProfileNav);
