import React from "react";
import _ from "lodash";
import {TableView} from "~/views/presentation/ui/tables";
import {compose, lifecycle, withHandlers} from "recompose";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import strings from "~/localization";
import {Rate, Tag} from "antd";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import moment from "moment";
import {PROCESS_STATUS, PROCESS_RATING} from "~/configs/const";
import {CheckCircleOutlined, ClockCircleOutlined, MinusCircleOutlined, PauseCircleOutlined, SyncOutlined} from "@ant-design/icons";

class FarmSeasonTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {dataSource: this.props.farmingHistory || []};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.farmingHistory != this.state.dataSource) {
      this.setState({dataSource: nextProps.farmingHistory || []});
    }
  }
  render() {
    const columns = [
      {
        dataIndex: "date",
        key: "date",
        align: "center",
        title: strings.start_date_action,
        render: (date) => {
          return moment(date, UtilDate.formatDateTimeServer).isValid()
            ? moment.utc(date, UtilDate.formatDateTimeServer).format(UtilDate.formatDateLocal)
            : "";
        },
      },
      {
        dataIndex: "action",
        key: "action",

        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.action.length - b.action.length,
        title: strings.work,
      },
      // TODO Chỗ này đáng lẽ là type của công việc, cần làm trong phiên bản tiếp theo
      {
        dataIndex: "proccessName",
        key: "proccessName",
        title: strings.type_work,
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.type.length - b.type.length,
      },
      {
        dataIndex: "proccessName",
        key: "proccessName",
        title: strings.procedure,
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.proccessName.length - b.proccessName.length,
      },
      {
        dataIndex: "capacityId",
        key: "capacityId",
        align: "center",
        title: strings.capacity,
      },
      {
        dataIndex: "finishDate",
        key: "finishDate",
        align: "center",
        title: strings.finish_date,
        render: (date) => {
          return moment(date, UtilDate.formatDateTimeServer).isValid()
            ? moment.utc(date, UtilDate.formatDateTimeServer).format(UtilDate.formatDateLocal)
            : "";
        },
      },
      {
        dataIndex: "completionLevel",
        key: "completionLevel",
        align: "center",
        title: strings.complete_level,
      },
      {
        dataIndex: "processStatus",
        key: "processStatus",
        title: strings.status,
        render: (text) => {
          switch (text) {
            case PROCESS_STATUS.CANCELED:
              return (
                <Tag icon={<MinusCircleOutlined />} color='default'>
                  {strings.CANCELED}
                </Tag>
              );
            case PROCESS_STATUS.FINISH:
              return (
                <Tag icon={<CheckCircleOutlined />} color='success'>
                  {strings.FINISHED}
                </Tag>
              );
              return;
            case PROCESS_STATUS.PAUSE:
              return (
                <Tag icon={<PauseCircleOutlined />} color='error'>
                  {strings.PAUSE}
                </Tag>
              );
            case PROCESS_STATUS.RUNNING:
              return (
                <Tag icon={<SyncOutlined spin />} color='processing'>
                  {strings.RUNNING}
                </Tag>
              );
            case PROCESS_STATUS.WAITING:
              return (
                <Tag icon={<ClockCircleOutlined />} color='warning'>
                  {strings.WAITING}
                </Tag>
              );
            default:
              break;
          }
        },
      },
      {
        dataIndex: "ratings",
        key: "ratings",
        title: strings.rate,
        render: (text) => {
          const desc = [strings.poor, strings.fair, strings.good, strings.very_good, strings.excellent];
          switch (text) {
            case PROCESS_RATING.EXCELLENT:
              return <Rate disabled defaultValue={5} tooltips={desc} style={{fontSize: 13}} />;
            case PROCESS_RATING.FAIR:
              return <Rate disabled defaultValue={1} tooltips={desc} style={{fontSize: 13}} />;
            case PROCESS_RATING.GOOD:
              return <Rate disabled defaultValue={3} tooltips={desc} style={{fontSize: 13}} />;
            case PROCESS_RATING.POOR:
              return <Rate disabled defaultValue={2} tooltips={desc} style={{fontSize: 13}} />;
            case PROCESS_RATING.VERY_GOOD:
              return <Rate disabled defaultValue={4} tooltips={desc} style={{fontSize: 13}} />;
            default:
              break;
          }
        },
      },
    ];
    return <TableView style={{width: "100%"}} dataSource={this.state.dataSource || []} columns={columns} scroll={{x: 1300}} />;
  }
}

export default compose(
  withRouter,
  connect(null, {}),
  withHandlers({}),
  lifecycle({
    componentDidMount() {
      const {} = this.props;
    },
  })
)(React.memo(FarmSeasonTables));
