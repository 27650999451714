import React, { memo, useState, useEffect } from "react";
import { Input } from "antd";
import { showToastError } from "~/configs/ServerErrors";

const QtyChanger = (props) => {

    const { value, getValue , maximumValue } = props;
    const [ qty, setQty ] = useState(value);
  
    useEffect(() => {
        if(value !== qty){
            setQty(value);
        }
    },[value])

    const updateQty =(value)=>{
        if(maximumValue != undefined && value > maximumValue){
            if(maximumValue == 0){
                showToastError(`Sản phẩm đã hết hàng`);
            }
            else{
                showToastError(`Số lượng sản phẩm chỉ còn: ${maximumValue}`);
            }
            
        }
        else{
            setQty(value)
            getValue(value)
        }
    }

    return (
        <div className="qty-cart-product-box">
            <button
                className="qty-cart-product-decrease qty-cart-product-amount border-0"
                disabled={qty < 2}
                onClick={() =>  {
                    getValue(qty - 1)
                    setQty(qty - 1)
                }}
            >
                -
            </button>
            <Input  min={0} value={qty} onChange={e=>{
                try {
                    let strValue = e.target.value ;
                    let value = parseInt(strValue) || 0;
                    updateQty(value > 0 ? value: 1);
                      
                } catch (error) {
                    updateQty(1);
                      
                }
            }} />
            <button
                className="qty-cart-product-increase qty-cart-product-amount border-0"
                onClick={() =>  {
                    getValue(qty + 1)
                    setQty(qty + 1)
                }}
                disabled={ maximumValue != undefined  ? qty > maximumValue : false }
            >
                +
            </button>
        </div>
    )
}

export default memo(QtyChanger);