import styled from "styled-components";
const Wrap = styled.div`
width:1500px;
overflow: auto;


td{
    min-width: 150px !important;
  }
td.ant-table-cell:nth-child(11){
    div{
        max-width:200px;
        max-height:110px;
        overflow: auto;
    }
}
td.ant-table-cell{
    span{
        white-space: nowrap;
    }
}
td.ant-table-cell:nth-child(12){
    div{
        max-width:200px;
        max-height:110px;
        overflow: auto;
    }
}
td.ant-table-cell.px-1{
    min-width: 33px !important;

}
th.ant-table-cell.px-1{
    min-width: 33px !important;

}
`;
export default Wrap