import { DownloadOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, message, Modal, Upload } from "antd";
import { saveAs } from "file-saver";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { API_EXPORT_HARVEST, defaultExport, IMAGE_URL } from "~/configs";
import { FARM_DETAIL_PATH, VIEW_SEASON_CRUD_FARM_PATH } from "~/configs/routesConfig";
import { showError } from "~/configs/ServerErrors";
import strings from "~/localization";
import { getExportInventoryOfHarvest, getFarmSeasonHarvest } from "~/state/ducks/appApis/actions";
import { TitleCustom } from "~/views/presentation/ui/dashboard";
import { getArray, getNumber, getString } from "~/views/utilities/helpers/utilObject";
import FarmSeasonHarvestTable from "./FarmSeasonEnterHarvestTable";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class PicturesWall extends React.Component {
  constructor(props) {
    super(props);
    let stringImage = this.props.images || "";
    this.state = {
      visible: true,
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList:
        stringImage.trim().length > 0
          ? (this.props.images || "").split("|").map((item, index) => ({
              uid: index,
              name: this.props.title,
              status: "done",
              url: IMAGE_URL + item,
            }))
          : [],
    };
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  render() {
    const { previewVisible, fileList, previewTitle } = this.state;
    return (
      <>
        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          showUploadList={{
            showPreviewIcon: true,
            showDownloadIcon: false,
            showRemoveIcon: false,
          }}
        ></Upload>
        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={this.handleCancel}></Modal>
      </>
    );
  }
}

class FarmSeasonHarvest extends React.Component {
  handleButtonClick() {
    message.info("Click on left button.");
  }
  handleMenuClick() {
    message.info("Click on menu item.");
  }
  /**
   * Description: Link to farm season detail
   * @param {*} e event
   * @param {*} record contain id
   */
  onClickFarmSeason = (e, record) => {
    // {id: 76001, ...record}
    const { history, match } = this.props;
    history.push(VIEW_SEASON_CRUD_FARM_PATH.replace(":idSeason", getNumber(record, "farmingSeasonId")).replace(":id", match.params.id));
  };
  onClickFarm = () => {
    // {id: 76001, ...record}
    const { history, match } = this.props;
    history.push(FARM_DETAIL_PATH.replace(":id", match.params.id));
  };

  /**
   * Function: onClick export
   * Input: idSeason : number
   * Output: file excel export
   * @Desc: export inventory of harvest season
   * @Author: tan.hoang@vslsoft.com
   * @Date: 2020-10-19 08:48:50
   */
  onExport = () => {
    // FIXME Dùng tạm thời fetch gọi trực tiếp, dev nào có cách hay hơn dùng được cách gọi chung thì config nhé
    const { match, user, setLoadingExport } = this.props;
    setLoadingExport(true);

    fetch(API_EXPORT_HARVEST + match.params.idSeason, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.id_token}`,
      },
    })
      .then(function (response) {
        if (response.status === 200) return response.blob();
        else {
          message.warn(strings.report_content_is_empty, 4);
          setLoadingExport(false);
        }
      })
      .then(function (blob) {
        if (blob) {
          saveAs(blob, defaultExport.harvestInventory);
          setLoadingExport(false);
        }
      })
      .catch((err) => {
        console.error("getExportInventoryOfHarvest -> err", err);
        return showError(err);
      });
  };

  render() {
    const { nameFarm } = this.props;
    const columns = [
      {
        dataIndex: "nameFarm",
        key: "nameFarm",
        title: strings.farm,
        render: (text, record) => {
          return (
            <Button type="link" onClick={(e) => this.onClickFarm(e, record)}>
              {nameFarm}
            </Button>
          );
        },
      },
      {
        dataIndex: "name",
        key: "name",
        render: (text, record) => {
          return (
            <Button type="link" onClick={(e) => this.onClickFarmSeason(e, record)}>
              {text == "" ? strings.no_name : text}
            </Button>
          );
        },
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.name.length - b.name.length,
        title: strings.farm_season_name,
      },
      {
        title: strings.original_code,
        dataIndex: "code",
      },
      {
        title: strings.picture,
        dataIndex: "images",
        render: (images) => {
          return (
            <i
              className="fa fa-edit"
              style={{ fontSize: "20px", cursor: "pointer" }}
              onClick={() => {
                const { setVisible, setCurrentImages } = this.props;
                setVisible(true);
                setCurrentImages(images);
              }}
            />
          );
        },
      },
      {
        title: strings.product_name,
        dataIndex: "productName",
      },
      {
        title: strings.unit_acronym,
        dataIndex: "unitName",
        type: "select",
      },
      {
        title: strings.classify,
        dataIndex: "classifyName",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.classifyName.length - b.classifyName.length,
      },
      {
        title: strings.product_code,
        dataIndex: "productCode",
      },
      {
        title: strings.stock_at_the_beginning_of_the_period,
        dataIndex: "openingStock",
      },
      {
        title: strings.import_inventory,
        dataIndex: "input",
      },
      {
        title: strings.export_inventory,
        dataIndex: "output",
      },
      {
        title: strings.toal_inventory,
        dataIndex: "gross",
      },
      {
        title: strings.damaged_goods,
        dataIndex: "defect",
      },
      // {
      //   title: strings.procedure_unit_price,
      //   dataIndex: "price",
      // },
      // {
      //   // auto calculator
      //   title: strings.into_money,
      //   dataIndex: "amount",
      // },
      {
        title: strings.note,
        dataIndex: "note",
      },
    ];

    const { harvestSeason, editable, setVisible, currentImages, visible, loadingExport } = this.props;

    return (
      <>
        <div className="farm-season__title d-flex flex-row justify-content-between align-items-baseline">
          <div className=" col-12 pr-0">
            <TitleCustom
              title={strings.inventory_overview}
              slot={
                loadingExport ? (
                  <SyncOutlined spin style={{ fontSize: "2em", cursor: "progress" }} className="mr-1" />
                ) : (
                  <DownloadOutlined style={{ fontSize: "2em" }} className="mr-1" onClick={this.onExport} />
                )
              }
            />
          </div>
        </div>
        <div className="farm-season__table__item">
          <FarmSeasonHarvestTable editable={editable} nameFarm={nameFarm} harvestSeason={harvestSeason} columns={columns} />
        </div>
        <Modal
          title={strings.picture}
          visible={visible}
          onOk={() => {
            setVisible(false);
          }}
          onCancel={() => {
            setVisible(false);
          }}
          footer={[
            <Button
              key="submit"
              onClick={() => {
                setVisible(false);
              }}
            >
              {strings.close}
            </Button>,
          ]}
        >
          <div className="clear-fix">
            <PicturesWall images={currentImages} />
          </div>
        </Modal>
      </>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state) => ({
      user: state["authUser"].user,
    }),
    {
      getFarmSeasonHarvest,
      getExportInventoryOfHarvest,
    }
  ),
  withState("harvestSeason", "setHarvestSeason", []),
  withState("visible", "setVisible", false),
  withState("loadingExport", "setLoadingExport", false),
  withState("currentImages", "setCurrentImages", ""),
  withHandlers({
    fetchFarmSeasonHarvest: (props) => () => {
      const { setHarvestSeason, getFarmSeasonHarvest, match } = props;
      getFarmSeasonHarvest(match.params.idSeason)
        .then(async ({ res }) => {
          setHarvestSeason(
            getArray(res, "actualHarvest", []).map((item) => {
              return {
                ...item,
                name: getString(res, "name", ""),
                productName: getString(res, "product.name", ""),
              };
            })
          );
        })
        .catch((err) => {
          console.error("fetchFarmSeasonHarvest -> err", err);
          return showError(err);
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchFarmSeasonHarvest } = this.props;
      fetchFarmSeasonHarvest();
    },
  })
)(FarmSeasonHarvest);
