import React, {useState} from "react";
import {compose, withState, withHandlers, lifecycle} from "recompose";
import {getArray, getString, getNumber, getObject} from "~/views/utilities/helpers/utilObject";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import styled from "styled-components";
import {Color} from "~/views/utilities/layout";

import moment from "moment";

import strings from "~/localization";
import {UIButton} from "~/views/presentation/ui/buttons";
import {message} from "antd";
import Reschedule from "./Reschedule";
import Modal from "antd/lib/modal/Modal";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import {deleteSeasonProcessStep, updateFinishSeasonProcessStep, rescheduleSeasonProcessStep} from "~/state/ducks/appApis/actions";
import {FARM_DETAIL_PATH, OVERVIEW_LEDGER_FARM_PATH} from "~/configs/routesConfig";
import {showError} from "~/configs/ServerErrors";
import _ from "lodash";

const WrapInfoItem = styled.div`
  -webkit-transition: all 0.4s 0s ease;
  -moz-transition: all 0.4s 0s ease;
  -o-transition: all 0.4s 0s ease;
  transition: all 0.4s 0s ease;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;

  width: 100%;

  .row-item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding: 8px 0px;
    width: 100%;
  }
  .t-primary {
    color: ${Color.textPrimary};
    font-size: 14px;
    font-weight: 500;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .t-secondary {
    color: ${Color.secondaryText};
  }
  .end-line {
    width: 100%;
    border-bottom: 1px solid ${Color.divider};
    padding-bottom: 16px;
  }
`;

/**
 * Trả về các phần tử với label, content, detail tương ứng
 * @param {*} props :// info
 * Author: tan.hoang@vslsoft.com
 * Date: 10:45 9/10/2020 (vi)
 */
const InfoItem = (props) => {
  const [visible, setVisible] = useState(false);
  const [step, setStep] = useState({});

  const handleFinish = (values) => {
    const {rescheduleSeasonProcessStep, match, history} = props;
    const sdR = moment(values.startDate);
    const data = {
      id: step.id,
      name: step.name,
      startDate: sdR,
      startHour: parseInt(sdR.get("hours")),
      endDate: step.endDate,
      description: step.description,
      note: step.note,
      type: step.type,
      nextStepId: step.nextStepId,
      cultivationProcessId: step.cultivationProcessId,
      seasonProcessId: step.seasonProcessId,
    };
    rescheduleSeasonProcessStep(data)
      .then(({}) => {
        message.success(strings.successfully_rescheduled);
        if (match.path === OVERVIEW_LEDGER_FARM_PATH)
          history.push(OVERVIEW_LEDGER_FARM_PATH.replace(":idSeason", match.params.idSeason).replace(":id", match.params.id));
        else history.push(FARM_DETAIL_PATH.replace(":id", match.params.id));
      })
      .catch((err) => {
        console.error("rescheduleSeasonProcessStep -> err", err);
        showError(err);
      });
  };

  const {info, line = true, col = {l: 4, r: 8}} = props;

  return (
    <WrapInfoItem>
      {getArray(info, undefined, []).map((v, i) => {
        return (
          <div className={`row-item ${i === info.length - 1 && line ? "end-line" : ""}`}>
            <div className={`t-secondary col-${col.l} pl-0`}>
              {v.label}
              {v.label ? ":" : ""}
            </div>
            <div className={`t-primary col-${col.r} pl-0 pr-0`}>
              {v.content}
              {v.detail ? (
                <span className='info-more'>
                  {v.detail}{" "}
                  {v.action ? (
                    <UIButton
                      type='link'
                      className='btn-activity btn-reschedule'
                      onClick={() => {
                        setVisible(true);
                        setStep(_.head(getArray(getObject(v, "action"), "steps", [])));
                      }}>
                      {strings.START}
                    </UIButton>
                  ) : (
                    <></>
                  )}{" "}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      })}
      <Modal title={strings.RESCHEDULE} centered visible={visible} footer={null} onCancel={() => setVisible(false)}>
        <InfoItem
          line={false}
          info={[
            {label: strings.procedure_name, content: getString(step, "name", "")},
            {
              label: strings.time,
              content:
                moment.utc(getNumber(step, "startHour") * 3600 * 1000).format("HH:mm") !== "Invalid date"
                  ? moment.utc(getNumber(step, "startHour") * 3600 * 1000).format("HH:mm")
                  : "",
            },
            {label: strings.description, content: getString(step, "description", "")},
            {
              label: strings.start_date,
              content: moment(getString(step, "startDate"), UtilDate.formatDateTimeServer, true).isValid()
                ? moment.utc(getString(step, "startDate"), UtilDate.formatDateTimeServer).format(UtilDate.formatDateLocal)
                : "",
            },
            {
              label: strings.end_date,
              content: moment(getString(step, "endDate"), UtilDate.formatDateTimeServer, true).isValid()
                ? moment.utc(getString(step, "endDate"), UtilDate.formatDateTimeServer).format(UtilDate.formatDateLocal)
                : "",
            },
          ]}
        />
        <div className='row mt-1 mb-1'>
          <div className='col-4 font-weight-bold'>{strings.RESCHEDULE}:</div>
          <div className='col-8 pl-0'>
            <Reschedule
              handleFinish={handleFinish}
              setVisible={setVisible}
              defaultValue={moment.utc(getString(step, "startDate"), UtilDate.formatDateTimeServer).format(UtilDate.formatDateTimeServer)}
              startHour={getNumber(step, "startHour")}
            />
          </div>
        </div>
      </Modal>
    </WrapInfoItem>
  );
};

const initState = {
  data: [],
  value: undefined,
  fetching: false,
};

export default compose(
  withRouter,
  connect(null, {
    deleteSeasonProcessStep,
    updateFinishSeasonProcessStep,
    rescheduleSeasonProcessStep,
  }),
  withState("state", "setState", (props) => (!!props.defaultValue ? props.defaultValue : initState)),
  withHandlers({
    handleChange: () => () => {},
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps() {},
    componentDidUpdate() {},
    componentDidMount() {},
  })
)(InfoItem);
