import React from "react";
import strings from "~/localization";
import _ from "lodash";
import {compose, lifecycle, withHandlers} from "recompose";
import {connect} from "react-redux";
import {withFormik} from "formik";
import {Table} from "antd";
import styled from "styled-components";

// redux
const TableWrapStyled = styled.div`
   .even-row-styled {
      background: #f5f5f5;
   }
   width: 100%
   padding: 16px 0px 8px 0px
`;
class DoneForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state  = {
      sortedInfo: null,
      filteredInfo: null,
    };
  }

  clearFilters = () => {
    this.setState({filteredInfo: null});
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo  : null,
      expand: true
    });
  };

  rowClassName(r) {
    if (!r) return;
    const {selectable = false, selectedKey} = this.props;
    if (r.key % 2 !== 0) {
      // odd
      return `row-styled editable-row ${
        selectedKey && selectedKey === r.key ? "selected-row" : ""
      } ${selectable ? "selectable-row" : ""}`;
    } else {
      // even
      return `even-row-styled row-styled editable-row ${
        selectedKey && selectedKey === r.key ? "selected-row" : ""
      } ${selectable ? "selectable-row" : ""}`;
    }
  }

  render() {
    // const {dataViewSource} = this.state;
    const {dataViewSource, expand} = this.props;
    console.error("DoneForm -> render -> dataViewSource", dataViewSource)

    let {sortedInfo, filteredInfo} = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    /**
     * EXPAND
     */

    const columns = [
      {
        title: strings.crops_type,
        dataIndex: "productId",
        width: "",
        key: "productId",
      },
      {
        title: strings.cultivated_area,
        dataIndex: "grossArea",
        key: "grossArea",
        width: "",
      },
      {
        title: strings.classify,
        dataIndex: "type",
        key: "type",
        width: "",
      },

      {
        title: strings.yield_by_type,
        dataIndex: "grossYield",
        width: "",
        key: "grossYield",
      },
      {
        title: strings.unit_cal,
        dataIndex: "unit",
        width: "",
        key: "unit",
      },
      {
        title: strings.note,
        dataIndex: "note",
        width: "",
        key: "note",
      },
    ];

    console.error("DoneForm -> render -> expand", expand)
    return (
      <TableWrapStyled>
        <Table
          defaultExpandAllRows={expand}
          defaultExpandedRowKeys={["0"]}
          bordered={true}
          rowClassName={(r) => this.rowClassName(r)}
          dataSource={dataViewSource}
          columns={columns}
          showSorterTooltip={false}
          pagination={false}
        />
      </TableWrapStyled>
    );
  }
}
export default compose(
  connect(null, {
  }),
  withHandlers({
  }),
  withFormik({
    displayName: "infoDoneForm",
    enableReinitialize: true,
    mapPropsToValues: () => {
      return {
        // nameFarm: props.info.nameFarm,
        // farmSeasonName: props.info.farmSeasonName,
      };
    },
  }),
  lifecycle({
    componentDidMount() {
    },
  })
)(DoneForm);
