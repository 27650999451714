import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { UIButton } from "~/views/presentation/ui/buttons";
import strings from "~/localization";

import {
  CREATE_ENTERPRISE_PROFILE_PATH,
} from "~/configs/routesConfig";
class PageDone extends React.PureComponent {
  constructor(props) {
    super(props);
    const { match } = this.props;
    let savePageDone = true;

    switch (match.path) {
      case CREATE_ENTERPRISE_PROFILE_PATH:
        {

          savePageDone = false;
        }
        break;
    }
    this.state = {
      savePageDone,
    };
  }
  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  UNSAFE_componentWillMount() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
  render() {
    return (
      <div>
        <div style={{ height: "calc(100vh - 300px)" }} className="row ">
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              alignItems: "center",
              lexDirection: "column",
              marginRight: "auto",
              flexDirection: "column",
            }}
          >
            <h1>{strings.completed_setup}</h1>
            <p className="w-100 mb-4" >
              {strings.completed_setup_description}
            </p>
            <UIButton
              disabled={this.state.savePageDone}
              style={{ float: "right" }}
              type="primary"
              className="ml-2"
              htmlType="button"
              onClick={() => {
                this.props.onCreate();
              }}
            >
              <span>{strings.completed}</span>
            </UIButton>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(withRouter, connect(null, {}))(PageDone);
