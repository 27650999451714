import styled from "styled-components";
import Color from "~/views/utilities/layout/color";
import { Form } from "antd";

const FormStyled = styled(Form)`
  width: 100%;
  .login   {
    font-family: "Inter";
    font-style: normal;
    /* identical to box height */
    text-align: center;
    color: #043a34;
  }
  .email {
    color: ${Color.brow};
  }
  .otp_send {
    color: ${Color.brow};
    font-style: italic;
  }
  .login_anotation {
    font-family: "Inter";
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #9ca3af;
  }
  div div button:hover {
    color: ${Color.browLight};
    text-decoration: none;
    border: none;
  }
  div div button {
    color: ${Color.brow};
    text-decoration: none;
    font-style: italic;
    border: none;
  }

  .forgot-text {
    margin: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #202020;
  }
  .register-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
  }
  .register-text span {
    color: #9ca3af;
  }

  .register-text a {
    color: #428079;
  }
`;

export { FormStyled };
