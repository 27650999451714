import * as types from "./types";
import apiAction, { defaultAction } from "~/state/ducks/utils/createAction";
import _ from "lodash";

export const addToCart = (itemCart) => ({
  type: types.ADD_TO_CART,
  payload: itemCart,
});

export const removeItemCart = (product) => ({
  type: types.REMOVE_ITEM_CART,
  payload: { product },
});

export const clearCart = () => ({
  type: types.CLEAR_CART,
});
export const clearServerCart = () => ({
  type: types.CLEAR_SERVER_CARD,
  payload: {},
});

export const addBuyNowItem = (body) => ({
  type: types.ADD_BUY_NOW_ITEM,
  payload: body,
});

export const storePreviousProduct = (body) => ({
  type: types.STORE_PREVIOUS_PRODUCT,
  payload: body,
});

export const changeQuantity = (product, qty) => ({
  type: types.CHANGE_QUANTITY,
  payload: { product, qty },
});

export const updateNewItemAdd = (body) =>
  defaultAction(types.UPDATE_NEW_ITEM_ADD, body);

export const closeNotifyCart = () => defaultAction(types.CLOSE_NOTIFY, {});

export const getCartServer = (ctx) =>
    apiAction("get")(types.LOAD_CART_SERVER, '/cart/details', {}, true, true, ctx)

export const getCartServerSuccess = (body) =>
    defaultAction(types.ASYNC_CART_SERVER_SUCCESS, body)

export const addCartServer = (body, ctx) =>
    apiAction()(types.ADD_CART_SERVER, '/cart/add', body, true, true, ctx)

export const getShippingCost = (vendorId, productId, shippingAddressId, productVariationId = undefined , ctx) => 
{
  let path = `/shipping/fee/${vendorId}/${productId}/${shippingAddressId}`;
  if (productVariationId) {
        path = `${path}/${productVariationId}`
    }
    return apiAction('get')(types.GET_SHIPPING_COST, path , {}, true, true, ctx)
  }

export const getShippingCostByZipCode = (
  vendorId,
  productId,
  zipCode,
  productVariationId = undefined,
  ctx
) => {
  let path = `/shipping/cost/${vendorId}/${productId}/${zipCode}`;
  if (productVariationId) {
    path = `${path}/${productVariationId}`;
  }
  return apiAction("get")(
    types.GET_SHIPPING_COST_BY_ZIP_CODE,
    path,
    {},
    true,
    true,
    ctx
  );
};

export const getZipCodeInfo = (zipcode, ctx) =>
  apiAction("get")(
    types.GET_ZIP_CODE_INFO,
    "/zipcode/" + zipcode,
    {},
    true,
    true,
    ctx
  );

export const askToMergeCart = (value) => ({
  type: types.ASK_TO_MERGE_CART,
  payload: value,
});

export const mergeCarts = (body, ctx) =>
  apiAction("PUT")(types.CART_MERGE, "/cart/merge", body, true, true, ctx);

export const mergeCartsSuccess = (body) =>
  defaultAction(types.ASYNC_CART_SERVER_SUCCESS, body);

export const getAddresses = (ctx) =>
  apiAction("get")(
    types.GET_ADDRESSES,
    "/consumer/shipping_address",
    {},
    true,
    true,
    ctx
  );

export const postAddDeliveryAddress = (body, ctx) =>
  apiAction("post")(
    types.POST_ADD_DELIVERY_ADDRESS,
    "/consumer/add_address",
    body,
    true,
    true,
    ctx
  );

export const getShippingOptions = (shippingAddressId, ctx) =>
  apiAction("get")(
    types.GET_SHIPPING_OPTIONS,
    "/shipping/option/" + shippingAddressId,
    {},
    true,
    true,
    ctx
  );

export const postDeleteDeliveryAddress = (address_id, ctx) =>
  apiAction("get")(
    types.POST_DELETE_DELIVERY_ADDRESS,
    "/consumer/delete_address/" + address_id,
    {},
    true,
    true,
    ctx
  );

export const postUpdateDeliveryAddress = (body, ctx) =>
  apiAction("post")(
    types.POST_UPDATE_DELIVERY_ADDRESS,
    "/consumer/update_address",
    body,
    true,
    true,
    ctx
  );

export const postCheckoutComplete = (idempotencyKey, body, ctx) =>
  apiAction("post")(
    types.POST_CHECKOUT_COMPLETED,
    "/checkout/completed",
    body,
    true,
    true,
    ctx,
    { idempotencyKey: idempotencyKey }
  );

export const postOrderPayment = (body, ctx) =>
  apiAction("post")(
    types.POST_ORDER_PAYMENT,
    "/order/payment",
    body,
    true,
    true,
    ctx
  );

export const getCheckoutComplete = (idempotencyKey, addressId, ctx) =>
  apiAction("get")(
    types.GET_CHECKOUT_COMPLETED,
    `/checkout/completed/${addressId}`,
    {},
    true,
    true,
    ctx,
    { idempotencyKey: idempotencyKey }
  );

export const getDefaultCheckoutComplete = (idempotencyKey, ctx) =>
  apiAction("get")(
    types.GET_CHECKOUT_COMPLETED,
    `/checkout/completed`,
    {},
    true,
    true,
    ctx,
    { idempotencyKey: idempotencyKey }
  );

export const getCheckoutPublicKey = (ctx) =>
  apiAction("get")(
    types.GET_CHECKOUT_PUBLIC_KEY,
    "/checkout/public_key",
    {},
    true,
    true,
    ctx
  );

export const postUpdateDefaultAddress = (addressId, ctx) =>
  apiAction("get")(
    types.POST_UPDATE_DEFAULT_ADDRESS,
    "/consumer/default_address/" + addressId,
    {},
    true,
    true,
    ctx
  );

export const postBuyNowOrder = (body, ctx) =>
  apiAction("post")(
    types.POST_BUY_NOW_ORDER,
    "/buynow/order",
    body,
    true,
    true,
    ctx
  );

export const postBuyNowConfirm = (body, ctx) =>
  apiAction("post")(
    types.POST_BUY_NOW_CONFIRM,
    "/buynow/confirm/false",
    body,
    true,
    true,
    ctx
  );

export const updateResponseBuyNow = (body) => ({
  type: types.UPDATE_RESPONSE_BUY_NOW,
  payload: body,
});

export const postBuyNowOrderComplete = (body, ctx) =>
  apiAction("post")(
    types.POST_BUY_NOW_ORDER_COMPLETED,
    "/buynow/complete",
    body,
    true,
    true,
    ctx
  );

export const getCart = (body) => ({
  type: types.GET_ORDER_DETAIL,
  payload: body,
});
export const updateSearchInCategory = (body) => ({
  type: types.UPDATE_SEARCH_IN_CATEGORY,
  payload: body,
});

export const updateZipCode = (body) => ({
  type: types.UPDATE_ZIP_CODE,
  payload: body,
});
export const updatePayingOrder = (body) => ({
  type: types.UPDATE_PAYING_ORDER_DETAIL,
  payload: body,
});

export const loadHistoryOrders = (status, size, page, ctx) =>
  apiAction("get")(
    types.LOAD_HISTORY_ORDER,
    `/order/detail/status/${status}?size=${size}&page=${page}`,
    {},
    true,
    true,
    ctx
  );

export const loadWaitingOrders = (size, page, ctx) =>
  apiAction("get")(
    types.LOAD_ORDER_WAITING,
    `/order/waiting`,
    {},
    true,
    true,
    ctx
  );

export const loadOrderDetail = (orderId, ctx) =>
  apiAction("get")(types.LOAD_ORDER, `/order/${orderId}`, {}, true, true, ctx);

export const cancelOrder = (orderDetailId, ctx) =>
  apiAction("put")(
    types.CANCEL_ORDER,
    "/order/detail/cancel/" + orderDetailId,
    {},
    true,
    true,
    ctx
  );

export const getOrderDetail = (orderDetailId, ctx) =>
  apiAction("get")(
    types.GET_ORDER_DETAIL,
    "/order/detail/" + orderDetailId,
    {},
    true,
    true,
    ctx
  );

export const postSearchSuggestion = (body, ctx) =>
  apiAction("post")(
    types.POST_SEARCH_SUGGESTION,
    "/advance/search/suggestion",
    body,
    true,
    true,
    ctx
  );

export const loadAdvanceSearchResult = (body, ctx) =>
  apiAction("post")(
    types.LOAD_ADVANCE_SEARCH_RESULT,
    `/advance/search/result_v2`,
    body,
    true,
    true,
    ctx
  );

export const deleteOrder = (orderId, ctx) =>
  apiAction("delete")(
    types.DELETE_ORDER,
    `/order/${orderId}`,
    {},
    true,
    true,
    ctx
  );

export const updateCriterionSearch = (body) => ({
  type: types.UPDATE_CRITERION_SEARCH,
  payload: body,
});

export const updateAdvanceSearchResult = (body) => ({
  type: types.UPDATE_ADVANCE_SEARCH_RESULT,
  payload: body,
});

// updateAdvanceSearchResult
