import React from "react";
import TableEdit from "../../TableEditProductCost";
import {
  InputField,
  SelectField,
} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import { Form } from "antd";

import _ from "lodash";
import Wrap from "./style"
import enhance from "./script";

class FormProductCost extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { dataSource: this.props.pakaging.details || [] }

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
       
    if (this.state.dataSource !== nextProps.pakaging.details) {
      this.setState({ dataSource: nextProps.pakaging.details || [] })
    }
  }
  updateDataSource = (prvDataSource) => (index, dataIndex, dataSource) => {
    this.props.onChange && this.props.onChange(dataSource)

       
    while (prvDataSource.length) {
      prvDataSource.shift()
    }
    dataSource.forEach(item => {
      prvDataSource.push(item)
    })
  }

  _handleCheckRadio = (e) => {
    const { setFieldValue } = this.props;
    setFieldValue("type", e.target.value);
  };
  handleChangeInputValue = (e) => {
    const { handleChange } = this.props;
    handleChange(e);
    let name = e.target.name;
    this.props.pakaging[name] = e.target.value;
  };
  render() {
    const {
      values,
      unitData,
      handleBlur,
      expalin,
      touched,
      errors,
      loadingTypeProcess,
      packer,
      product,
      setFieldValue,
      setFieldTouched,
    } = this.props;
    this.props.pakaging.errors = errors

    const { editable } = this.props;
    let columns = [
      {
        title: strings.explain,
        dataIndex: "description",
        width: "25%",
        editable: true,

        placeholder: strings.explain,
        type: "select",
        options: expalin,
      },
      {
        title: strings.percent_per,
        dataIndex: "ratio",
        width: "10%",
        editable: true,
        suffix: "%",

        placeholder: strings.percent_per,
        type: "number",
        rules: [
          {
            pattern: /^0*(?:[1-9][0-9]?|100)$/,
            message: strings.percent_check,
          },
        ],
      },
      {
        title: strings.unit,
        dataIndex: "unit",
        width: "10%",
        editable: true,
        placeholder: strings.unit,
        type: "select",
        options: unitData || [],
      },
      {
        title: strings.paker,
        dataIndex: "packerName",
        width: "15%",
        editable: true,
        placeholder: strings.paker,
        type: "select",
        options: packer,
      },
      {
        title: strings.noti_price,
        placeholder: strings.noti_price,
        dataIndex: "price",
        width: "15%",
        editable: true,
        suffix: "đ",

        type: "decimalInput",
      },
      {
        title: strings.note,
        dataIndex: "note",
        width: "200px",
        editable: true,

        placeholder: strings.note,
        type: "text",
        options: [],
      },
    ];
    return (
      <Form>
        <div className="row d-flex">
          <div className="col-12 col-lg-6">
            <SelectField
              loading={loadingTypeProcess}
              validateStatus={
                touched.productId && errors.productId
                  ? "error"
                  : undefined
              }
              disabled={!editable}
              help={
                touched.productId && errors.productId
                  ? errors.productId
                  : ""
              }
              data={product || []}
              value={values.productId}
              onChange={(value) => {
                this.props.pakaging.productId = value;
                setFieldValue("productId", value);
                setFieldTouched("productId");
              }}
              iconEnd="caret-down"
              placeholder={strings.chose_type_tree}
              label={strings.type_tree}
            />
          </div>
          <div className="col-12 col-lg-6">
            <InputField
              disabled={!editable}
              validatestatus={touched.name && errors.name ? "error" : undefined}
              help={touched.name && errors.name ? errors.name : ""}
              name="name"
              value={values.name}
              onChange={this.handleChangeInputValue}
              onBlur={handleBlur}
              placeholder={strings.name_pagkaging_cost}
              label={strings.name_pagkaging_cost}
            />
          </div>
          <Wrap>
            <TableEdit
              editable={editable}
              changedData={this.updateDataSource(this.props.pakaging.details)}
              dataSource={this.state.dataSource || []}
              columns={columns}
            /></Wrap>

        </div>
      </Form>
    );
  }
}
export default enhance(FormProductCost);
