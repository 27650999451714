import React from "react";
import {compose, withHandlers, withState, lifecycle} from "recompose";
import {connect} from "react-redux";
import * as yup from "yup";
import OneUploader from "../../FarmProfileForms/QNCForm/OneUploader";

import {withFormik} from "formik";
import {withRouter} from "react-router-dom";
import {InputField, DatePickerField, SelectField} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import {Tabs, Card} from "antd";
import {UIButton} from "~/views/presentation/ui/buttons";
import {TableEditable} from "~/views/presentation/ui/tables";
import {getType, getProvinces, getDistrict, getWards} from "~/state/ducks/appApis/actions";
import {showError} from "~/configs/ServerErrors";
import {getArray, getString, getNumber} from "~/views/utilities/helpers/utilObject";
import moment from "moment";
import _ from "lodash";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import {stringNRFieldValidate, stringRequiredField, numberValidate} from "~/views/utilities/validation/input";
import {LICENCE_MAX_LENGTH} from "~/configs";
const {TabPane} = Tabs;

const validationSchema = yup.object().shape({
  type: stringRequiredField(),
  issuedDate: stringNRFieldValidate(),
  activateDate: stringNRFieldValidate(),
  expirationDate: stringNRFieldValidate(),
  dateEvaluate: stringNRFieldValidate(),
  dateReEvaluate: stringNRFieldValidate(),
  issuedPlace: stringNRFieldValidate(),
  licenseCode: stringNRFieldValidate(),
  taxCode: numberValidate,
  idTax: stringNRFieldValidate(),
  issuedPlace: stringNRFieldValidate(),
  address1Licenses: stringNRFieldValidate(),
});

const scrollToBottom = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: "smooth",
  });
};

const scrollToTop = () => {
  window.scrollTo({top: 0, left: 0, behavior: "smooth"});
};
class LicenceForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {show: true};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  handleChangeDataSource = (index, dataIndex, dataSource) => {
    const {setValues} = this.props;
    if (dataIndex === "key") {
      setValues({});
      while ((this.props.info.businessLicenses || []).length) {
        this.props.info.businessLicenses.shift();
      }
      dataSource.forEach((item) => {
        this.props.info.businessLicenses.push(item);
      });
    }
  };

  handleChangeCertificate = (e, index) => {
    const {setFieldValue, arrayImage, values} = this.props;
    let temptArray = arrayImage;
    let valuesImg = getString(values, "images", "");

    temptArray = valuesImg.split("|");
    temptArray[index - 1] = e;
    temptArray = temptArray.join("|");

    setFieldValue("images", temptArray);
  };

  getSplitImg(index) {
    let tempt = getString(this.props.info.businessLicenses, "images");
    tempt = tempt.split("|");
    return tempt[index];
  }

  handleSaveValues = () => {
    const {values, setValues, setFieldValue, setFieldTouched} = this.props;
    setFieldValue("images", "");

    if (values.key >= 0) {
      if ((this.props.info.businessLicenses || []).length > values.key) {
        let keys = Object.keys(values).filter(
          (key) =>
            key === "businessTypeIds" ||
            key === "productionTypeIds" ||
            key === "groupManagementIds" ||
            (!_.isArray(values[key]) && !_.isObject(values[key]))
        );
        let modifyObject = keys.reduce((prev, curr) => {
          prev[curr] = values[curr];
          return prev;
        }, {});
        this.props.info.businessLicenses[values.key] = modifyObject;
      }
      Object.keys(values).map((key) => setFieldTouched(key, false));
      setValues({});
      scrollToBottom();
    } else {
      let keys = Object.keys(values).filter(() => (key) =>
        key === "businessTypeIds" ||
        key === "productionTypeIds" ||
        key === "groupManagementIds" ||
        (!_.isArray(values[key]) && !_.isObject(values[key]))
      );
      let modifyObject = keys.reduce((prev, curr) => {
        prev[curr] = values[curr];
        return prev;
      }, {});
      modifyObject["kyc"] = false;
      this.props.info.businessLicenses.push(modifyObject);
      this.props.info.businessLicenses.forEach((item, index) => {
        item.key = index;
      });
      Object.keys(values).map((key) => setFieldTouched(key, false));
      setValues({});
      scrollToBottom();
    }
  };

  handleSelectRow = (r) => {
    const {fetchWard, fetchDistrict, setValues, setFieldValue} = this.props;
    const districtIdLicenses = getNumber(r, "districtIdLicenses", undefined);
    const provinceIdLicenses = getNumber(r, "provinceIdLicenses", undefined);
    const wardsIdLicenses = getNumber(r, "wardsIdLicenses", undefined);
    const address1Licenses = getString(r, "address1Licenses", "");
    if (provinceIdLicenses) fetchDistrict(provinceIdLicenses);
    if (districtIdLicenses) {
      fetchWard(districtIdLicenses);
    }
    setValues(r);
    setFieldValue("address1Licenses", address1Licenses);
    setFieldValue("provinceIdLicenses", provinceIdLicenses);
    setFieldValue("districtIdLicenses", districtIdLicenses);
    setFieldValue("wardsIdLicenses", wardsIdLicenses);
    scrollToTop();
  };

  handleChange = (event) => {
    const {handleChange, setFieldTouched} = this.props;
    setFieldTouched(event.target.name, true);
    handleChange(event);
  };

  render() {
    const {
      values,
      handleChange,
      handleBlur,
      touched,
      errors,
      handleChangeDate,
      isValid,
      setFieldValue,
      setFieldTouched,
      handleCancelChange,
      editable,
      provinceData,
      businessTypeIdsData,
      productionTypeIdsData,
      groupManagementIdsData,
      districtData,
      wardData,
      setDistrict,
      setWard,
    } = this.props;
    const {show} = this.state;

    let issuedDate = moment.utc(values.issuedDate, UtilDate.formatDateTimeServer).local();
    let activateDate = moment.utc(values.activateDate, UtilDate.formatDateTimeServer).local();

    const columns = [
      {
        title: strings.certificate_type,
        dataIndex: "type",
        key: "type",
        width: "15%",
        editable: false,
        placeholder: strings.certificate_type,
        type: "text",
        // options: this.props.businessLicenseData || [],
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.certificate_number,
        dataIndex: "licenseCode",
        key: "licenseCode",
        width: "15%",
        editable: false,
        placeholder: strings.certificate_number,
        type: "text",
      },
      {
        title: strings.tax_number,
        dataIndex: "taxCode",
        key: "taxCode",
        width: "15%",
        editable: false,
        placeholder: strings.tax_number,
        type: "number",
      },
      {
        title: strings.date_of_issue,
        dataIndex: "issuedDate",
        key: "issuedDate",
        width: "15%",
        editable: false,
        placeholder: strings.date_of_issue,
        type: "date",
      },
      {
        title: strings.operation_date,
        dataIndex: "activateDate",
        key: "activateDate",
        width: "15%",
        editable: false,
        placeholder: strings.certificate_type,
        type: "date",
      },
      {
        title: strings.place_of_issue,
        dataIndex: "issuedPlace",
        key: "issuedPlace",
        width: "15%",
        editable: false,
        placeholder: strings.place_of_issue,
        type: "text",
      },
      {
        title: strings.kyc,
        dataIndex: "kyc",
        key: "kyc",
        width: "15%",
        editable: false,
        placeholder: strings.certificate_type,
        type: "select",
        options: [
          {value: false, label: strings.not_authenticated},
          {value: true, label: strings.endorsed},
        ],
      },
    ];
    return (
      <div>
        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            <Card>
              <SelectField
                validateStatus={touched.type && errors.type ? "error" : undefined}
                disabled={!editable}
                help={touched.type && errors.type ? errors.type : ""}
                data={this.props.businessLicenseData || []}
                value={values.type}
                onChange={(value, data) => {
                  setFieldValue("type", data.children);
                  setFieldTouched("type");
                }}
                iconEnd="caret-down"
                placeholder={strings.placeholder_certificate_type}
                label={strings.certificate_type}
              />
              <InputField
                validatestatus={touched.licenseCode && errors.licenseCode ? "error" : undefined}
                help={touched.licenseCode && errors.licenseCode ? errors.licenseCode : ""}
                name="licenseCode"
                maxLength={LICENCE_MAX_LENGTH}
                type="text"
                disabled={!editable}
                value={values.licenseCode}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={strings.placeholder_certificate_number}
                label={strings.certificate_number}
              />
              <div className="row">
                <div className="col-6 pr-2">
                  <DatePickerField
                    disabled={!editable}
                    validateStatus={touched.issuedDate && errors.issuedDate ? "error" : undefined}
                    help={touched.issuedDate && errors.issuedDate ? errors.issuedDate : ""}
                    name="issuedDate"
                    value={issuedDate.isValid() ? issuedDate : undefined}
                    onChange={handleChangeDate(this.props)("issuedDate")}
                    onBlur={handleBlur}
                    label={strings.date_of_issue}
                    placeholder={strings.placeholder_date_of_issue}
                  />
                  <InputField
                    validatestatus={touched.issuedPlace && errors.issuedPlace ? "error" : undefined}
                    help={touched.issuedPlace && errors.issuedPlace ? errors.issuedPlace : ""}
                    name="issuedPlace"
                    type="text"
                    disabled={!editable}
                    value={values.issuedPlace}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={strings.where_by_issue}
                    placeholder={strings.placeholder_where_by_issue}
                  />
                </div>
                <div className="col-6 pl-2">
                  <InputField
                    validatestatus={touched.taxCode && errors.taxCode ? "error" : undefined}
                    help={touched.taxCode && errors.taxCode ? errors.taxCode : ""}
                    name="taxCode"
                    type="text"
                    disabled={!editable}
                    value={values.taxCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={strings.tax_number}
                    placeholder={strings.placeholder_tax_number}
                  />
                  <DatePickerField
                    disabled={!editable}
                    validateStatus={touched.activateDate && errors.activateDate ? "error" : undefined}
                    help={touched.activateDate && errors.activateDate ? errors.activateDate : ""}
                    name="activateDate"
                    value={activateDate.isValid() ? activateDate : undefined}
                    onChange={handleChangeDate(this.props)("activateDate")}
                    onBlur={handleBlur}
                    label={strings.operation_date}
                    placeholder={strings.placeholder_operation_date}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 pr-2">
                  <InputField
                    validatestatus={touched.address1Licenses && errors.address1Licenses ? "error" : undefined}
                    help={touched.address1Licenses && errors.address1Licenses ? errors.address1Licenses : ""}
                    name="address1Licenses"
                    disabled={!editable}
                    value={values.address1Licenses}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={strings.address}
                    placeholder={strings.placeholder_address}
                  />
                </div>
                <div className="col-6 pl-2">
                  <SelectField
                    validateStatus={touched.provinceIdLicenses && errors.provinceIdLicenses ? "error" : undefined}
                    disabled={!editable}
                    help={touched.provinceIdLicenses && errors.provinceIdLicenses ? errors.provinceIdLicenses : ""}
                    data={provinceData || []}
                    value={values.provinceIdLicenses}
                    onChange={(value) => {
                      this.props.fetchDistrict(value);
                      setFieldValue("provinceIdLicenses", value);
                      setFieldValue("districtIdLicenses", undefined);
                      setFieldValue("wardsIdLicenses", undefined);
                      setDistrict([]);
                      setWard([]);

                      setFieldTouched("provinceIdLicenses");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.city_or_province}
                    label={strings.city_or_province}
                  />
                </div>
                <div className="col-6 pr-2">
                  <SelectField
                    validateStatus={touched.districtIdLicenses && errors.districtIdLicenses ? "error" : undefined}
                    disabled={!editable}
                    help={touched.districtIdLicenses && errors.districtIdLicenses ? errors.districtIdLicenses : ""}
                    data={districtData || []}
                    value={values.districtIdLicenses}
                    onChange={(value) => {
                      this.props.fetchWard(value);
                      setFieldValue("districtIdLicenses", value);
                      setFieldValue("wardsIdLicenses", undefined);
                      setWard([]);

                      setFieldTouched("districtIdLicenses");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.district}
                    label={strings.district}
                  />
                </div>
                <div className="col-6 pl-2">
                  <SelectField
                    validateStatus={touched.wardsIdLicenses && errors.wardsIdLicenses ? "error" : undefined}
                    disabled={!editable}
                    help={touched.wardsIdLicenses && errors.wardsIdLicenses ? errors.wardsIdLicenses : ""}
                    data={wardData || []}
                    value={values.wardsIdLicenses}
                    onChange={(value) => {
                      setFieldValue("wardsIdLicenses", value);
                      setFieldTouched("wardsIdLicenses");
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.ward}
                    label={strings.ward}
                  />
                </div>
              </div>
              <SelectField
                mode="multiple"
                validateStatus={touched.businessTypeIds && errors.businessTypeIds ? "error" : undefined}
                disabled={!editable}
                help={touched.businessTypeIds && errors.businessTypeIds ? errors.businessTypeIds : ""}
                data={businessTypeIdsData || []}
                value={values.businessTypeIds}
                onChange={(value) => {
                  this.props.info.businessTypeIds = value;
                  setFieldValue("businessTypeIds", value);
                  setFieldTouched("businessTypeIds");
                }}
                iconEnd="caret-down"
                label={strings.type_of}
                placeholder={strings.placeholder_type_of}
              />
              <SelectField
                validateStatus={touched.productionTypeIds && errors.productionTypeIds ? "error" : undefined}
                disabled={!editable}
                help={touched.productionTypeIds && errors.productionTypeIds ? errors.productionTypeIds : ""}
                mode="multiple"
                data={productionTypeIdsData || []}
                value={values.productionTypeIds}
                onChange={(value) => {
                  setFieldValue("productionTypeIds", value);
                  setFieldTouched("productionTypeIds");
                }}
                iconEnd="caret-down"
                label={strings.branch_product}
                placeholder={strings.placeholder_branch_product}
              />
              <SelectField
                validateStatus={touched.groupManagementIds && errors.groupManagementIds ? "error" : undefined}
                disabled={!editable}
                help={touched.groupManagementIds && errors.groupManagementIds ? errors.groupManagementIds : ""}
                mode="multiple"
                data={groupManagementIdsData || []}
                value={values.groupManagementIds}
                onChange={(value) => {
                  setFieldValue("groupManagementIds", value);
                  setFieldTouched("groupManagementIds");
                }}
                iconEnd="caret-down"
                label={strings.management_grouping}
                placeholder={strings.placeholder_management_grouping}
              />
            </Card>
          </div>
          <div className="col-12 col-md-6 ">
            <Card className={!editable ? "avoid-click" : ""}>
              <div className="row">
                <div className="col-6">
                  <OneUploader
                    show={show}
                    label={strings.before_upload_GCN}
                    images={{img: values.images, index: 0}}
                    onChange={(e) => this.handleChangeCertificate(e, 1)}
                  />
                </div>
                <div className="col-6" style={{marginBottom: 25}}>
                  <OneUploader
                    show={show}
                    label={strings.after_upload_GCN}
                    images={{img: values.images, index: 1}}
                    onChange={(e) => this.handleChangeCertificate(e, 2)}
                  />
                </div>
                <div className="col-6">
                  <OneUploader
                    show={show}
                    label={strings.human_and_GCN_upload}
                    images={{img: values.images, index: 2}}
                    onChange={(e) => this.handleChangeCertificate(e, 3)}
                  />
                </div>
                <div className="col-6">
                  <OneUploader
                    show={show}
                    label={strings.other}
                    images={{img: values.images, index: 3}}
                    onChange={(e) => this.handleChangeCertificate(e, 4)}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div>
          <UIButton type="primary" htmlType="submit" disabled={!isValid || !editable} onClick={this.handleSaveValues}>
            <span>{strings.save}</span>
          </UIButton>
          <UIButton type="secondary" className="ml-2" htmlType="button" disabled={!editable} onClick={handleCancelChange(this.props)}>
            <span>{strings.cancel}</span>
          </UIButton>
        </div>
        <div className="mt-3">
          <Tabs defaultActiveKey="1" type="card" size={"middle"}>
            <TabPane tab={strings.history} key="1">
              <TableEditable
                changedData={this.handleChangeDataSource}
                editable={editable}
                addRowAble={false}
                selectedKey={values.key}
                onSelect={this.handleSelectRow}
                selectable={true}
                dataSource={this.props.info.businessLicenses}
                columns={columns}
              />
              <div style={{marginTop: "16px"}}>
                <UIButton
                  style={{float: "right"}}
                  type="primary"
                  className="ml-2"
                  htmlType="button"
                  disabled={!editable}
                  onClick={() => this.props.nextStep(2)}
                >
                  <span>{strings.countinue}</span>
                </UIButton>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}
export default compose(
  withRouter,
  connect(null, {
    getType,
    getProvinces,
    getDistrict,
    getWards,
  }),
  withState("businessLicenseData", "setBusinessLicense", []),
  withState("provinceData", "setProvince", []),
  withState("wardData", "setWard", []),
  withState("districtData", "setDistrict", []),
  withState("businessTypeIdsData", "setBusinessType", []),
  withState("productionTypeIdsData", "setProductType", []),
  withState("groupManagementIdsData", "setManagementGroup", []),

  withHandlers({
    handleCancelChange: () => (props) => () => {
      const {setValues, setTouched, setFieldValue} = props;
      setValues({});
      setTouched({});
      setFieldValue("images", "");
    },
    handleChangeIssuedDate: () => (props) => (value) => {
      const {setFieldValue, setFieldTouched} = props;
      setFieldTouched("issuedDate");
      if (value) {
        setFieldValue("issuedDate", value.startOf("day").utc().format(UtilDate.formatDateTimeServer));
      } else {
        setFieldValue("issuedDate", undefined);
      }
    },
    handleChangeDate: (_) => (props) => (name) => (value) => {
      const {setFieldValue, setFieldTouched} = props;
      setFieldTouched(name);
      if (value) {
        setFieldValue(name, value.endOf("day").utc().format(UtilDate.formatDateTimeServer));
      } else {
        setFieldValue(name, undefined);
      }
    },
    handleChangeExpirationDate: () => (props) => (value) => {
      const {setFieldValue, setFieldTouched} = props;
      setFieldTouched("activateDate");
      if (value) {
        setFieldValue("activateDate", value.endOf("day").utc().format(UtilDate.formatDateTimeServer));
      } else {
        setFieldValue("activateDate", undefined);
      }
    },
    fetchCertificationTypes: (props) => () => {
      const {getType, setBusinessLicense} = props;
      getType("CERTIFICATION")
        .then(({res}) => {
          setBusinessLicense(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fetchWard: (props) => (id) => {
      const {getWards, setWard} = props;
      if (id)
        getWards(id)
          .then(({res}) => {
            setWard(
              getArray(res, undefined, []).map((item) => {
                return {
                  value: item.id,
                  label: item.name || "",
                };
              })
            );
          })
          .catch((err) => showError(err));
    },
    fetchDistrict: (props) => (id) => {
      const {getDistrict, setDistrict} = props;
      if (id)
        getDistrict(id)
          .then(({res}) => {
            setDistrict(
              getArray(res, undefined, []).map((item) => {
                return {
                  value: item.id,
                  label: item.name || "",
                };
              })
            );
          })
          .catch((err) => showError(err));
    },
    fetchGetProvince: (props) => () => {
      const {getProvinces, setProvince} = props;
      getProvinces(1)
        .then(({res}) => {
          setProvince(
            getArray(res, undefined, []).map((item) => {
              return {
                value: item.id,
                label: item.name || "",
              };
            })
          );
        })
        .catch((err) => showError(err));
    },
    fetchTypeBusinessType: (props) => () => {
      const {getType, setBusinessType} = props;
      getType("BUSSINESS")
        .then(({res}) => {
          setBusinessType(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fetchTypeProfess: (props) => () => {
      const {getType, setProductType} = props;
      getType("PRODUCT")
        .then(({res}) => {
          setProductType(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fetchTypeManageGroup: (props) => () => {
      const {getType, setManagementGroup} = props;
      getType("GROUP_MANAGEMENT")
        .then(({res}) => {
          setManagementGroup(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
  }),
  withFormik({
    displayName: "licenceForm",
    enableReinitialize: true,
    validationSchema: validationSchema,

    mapPropsToValues: (props) => {
      return {
        images: getString(props, "info.businessLicenses.images", ""),
      };
    },
    handleSubmit: async (values) => {},
  }),
  lifecycle({
    componentDidMount() {
      const {
        fetchCertificationTypes,
        fetchTypeBusinessType,
        fetchGetProvince,
        fetchTypeProfess,
        fetchTypeManageGroup,
        fetchDistrict,
        fetchWard,
      } = this.props;
      const districtIdLicenses = getNumber(this.props.info.businessLicenses, "districtIdLicenses", undefined);
      const provinceIdLicenses = getNumber(this.props.info.businessLicenses, "provinceIdLicenses", undefined);

      if (provinceIdLicenses) fetchDistrict(provinceIdLicenses);

      if (districtIdLicenses) {
        fetchWard(districtIdLicenses);
      }
      fetchCertificationTypes();
      fetchGetProvince();
      fetchTypeProfess();
      fetchTypeManageGroup();
      fetchTypeBusinessType();
    },
  })
)(LicenceForm);
