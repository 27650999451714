/**
 *
 *  MỚI LÀ PHẢI ĐƯA LÊN ĐẦU TIÊN
 *f
 */
//cau truc
// export const FARM_PRODUCT_COST_PATH="/farm/:id/season/:idSeason/product_cost-all"
export const FARM_STRUCTURE = "/farm/:id/season/:idSeason/structure";

export const ALL_FARM_PRODUCT_COST_PATH = "/farm/:id/season/:idSeason/structure/product-cost-all";
export const CREATE_FARM_PRODUCT_COST_PATH = "/farm/:id/season/:idSeason/structure/product-cost/create";
export const EDIT_FARM_PRODUCT_COST_PATH = "/farm/:id/season/:idSeason/structure/product-cost/:idProductCost/edit";
export const VIEW_FARM_PRODUCT_COST_PATH = "/farm/:id/season/:idSeason/structure/product-cost/:idProductCost";

export const ALL_FARM_SALE_PRICE_PATH = "/farm/:id/season/:idSeason/structure/sale-price-all";
export const CREATE_FARM_SALE_PRICE_PATH = "/farm/:id/season/:idSeason/structure/sale-price/create";
export const EDIT_FARM_SALE_PRICE_PATH = "/farm/:id/season/:idSeason/structure/sale-price/:idsalePrice/edit";
export const VIEW_FARM_SALE_PRICE_PATH = "/farm/:id/season/:idSeason/structure/sale-price/:idsalePrice";

export const ALL_FARM_PACKAGING_COST_PATH = "/farm/:id/season/:idSeason/structure/packaging-cost-all";
export const CREATE_FARM_PACKAGING_COST_PATH = "/farm/:id/season/:idSeason/structure/packaging-cost/create";
export const EDIT_FARM_PACKAGING_COST_PATH = "/farm/:id/season/:idSeason/structure/packaging-cost/:idPackagingCost/edit";
export const VIEW_FARM_PACKAGING_COST_PATH = "/farm/:id/season/:idSeason/structure/packaging-cost/:idPackagingCost/view";

/**
 * COMMERCE
 */
export const COMMERCE_HOME_PATH = "/commerce";
// product
export const FARM_PRODUCT_PATH = "/farm/product";
// input
export const FARM_INPUT_PATH = "/farm/input";
// all farm
export const ALL_FARM_PATH = "/all-farm";

/**
 * FARM MANAGEMENT
 */
export const FARM_HOME_PATH = "/farm";
export const FARM_DETAIL_PATH = "/farm/:id";

export const OVERVIEW_LEDGER_FARM_PATH = "/farm/:id/season/:idSeason/ledger";
// Farm season Harvest
export const ENTER_HARVEST_FARM_PATH = "/farm/:id/season/:idSeason/enter-harvest";
export const OVERVIEW_SEASON_HARVEST_FARM_PATH = "/farm/:id/season/:idSeason/harvest-overview";
export const EDIT_SEASON_HARVEST_FARM_PATH = "/farm/:id/season/:idSeason/harvest";
export const EDIT_SEASON_EXPECTED_HARVEST_FARM_PATH = "/farm/:id/season/:idSeason/expected-harvest";

// Farm Season CRUD
export const FARM_SEASON_CRUD_PATH = "/farm/:id/season/"; // all season
export const EDIT_SEASON_CRUD_FARM_PATH = "/farm/:id/season/:idSeason/edit";
export const CREATE_SEASON_CRUD_FARM_PATH = "/farm/:id/season/create";
export const VIEW_SEASON_CRUD_FARM_PATH = "/farm/:id/season/:idSeason";
//form
export const VIEW_FARM_FORM_PATH = "/farm/:id/season/:idSeason/form/:idForm";
export const CREATE_FARM_FORM_PATH = "/farm/:id/season/:idSeason/form";
export const EDIT_FARM_FORM_PATH = "/farm/:id/season/:idSeason/form/:idForm/edit";
export const ALL_FARM_FORM_PATH = "/farm/:id/season/:idSeason/form-all";
// procedure
export const FARM_PROCEDURE_PATH = "/procedure";
export const ALL_FARM_PROCEDURE_PATH = "/procedure/all/:type";
export const CREATE_FARM_PROCEDURE_PATH = "/procedure/create";
export const EDIT_FARM_PROCEDURE_PATH = "/procedure/:idProcedure/edit";
export const VIEW_FARM_PROCEDURE_PATH = "/procedure/:idProcedure";
//procedure of farm seaon
export const ALL_FARM_SEASON_PROCEDURE_PATH = "/farm/:id/season/:idSeason/procedures/:type";
export const EDIT_FARM_SEASON_PROCEDURE_PATH = "/farm/:id/season/:idSeason/procedure/:idProcedure/edit";
export const VIEW_FARM_SEASON_PROCEDURE_PATH = "/farm/:id/season/:idSeason/procedure/:idProcedure";

// Authentication path
export const LOGIN_PATH = "/login";
export const ACCOUNT_RECOVERY_PATH = "/account-recovery";
export const RECOVERY_OTP_PATH = "/account-recovery/otp/:account";
export const REGISTER_OTP_PATH = "/register/otp/:account";
export const RECOVERY_CHANGE_PASSWORD_PATH = "/account-recovery/change-password";
export const REGISTER_PATH = "/register";
export const REGISTER_COMPLETED_PATH = "/register/completed";
export const SETUP_PROFILE_PATH = "/setup-profile";
/**
 *     APPLICATIONs
 */
export const APPLICATIONS_PATH = "/applications";
/**
 *     DASHBOARD
 */
export const DASHBOARD_PATH = "/dashboard";
export const REPORTS_PATH = "/reports";
export const CONFIGS_PATH = "/configs";

/**
 * PROFILES MANAGEMENT
 */
export const ALL_PROFILES_PATH = "/profile";
export const LIST_PROFILES_PATH = "/profile/:id";
// personal
export const CREATE_PERSONAL_PROFILE_PATH = "/profile/personal/create";
export const VIEW_PERSONAL_PROFILE_PATH = "/profile/personal/:id";
export const EDIT_PERSONAL_PROFILE_PATH = "/profile/personal/:id/edit";
// farm profile
export const EDIT_FARM_PROFILE_PATH = "/profile/farm/:id/edit";
export const VIEW_FARM_PROFILE_PATH = "/profile/farm/:id";
export const CREATE_FARM_PROFILE_PATH = "/profile/farm/create";
// enterprise
export const EDIT_ENTERPRISE_PROFILE_PATH = "/profile/enterprise/:id/edit";
export const VIEW_ENTERPRISE_PROFILE_PATH = "/profile/enterprise/:id";
export const CREATE_ENTERPRISE_PROFILE_PATH = "/profile/enterprise/create";
// cooperative
export const COOPERATIVE_PROFILE_PATH = "/profile/cooperative/";
export const LIST_COOPERATIVE_PROFILES_PATH = "/profile/COOPERATIVE";
export const EDIT_COOPERATIVE_PROFILE_PATH = "/profile/cooperative/:id/edit";
export const CREATE_COOPERATIVE_PROFILE_PATH = "/profile/cooperative/create";
export const VIEW_COOPERATIVE_PROFILE_PATH = "/profile/cooperative/:id";
// cooperative venture (union of cooperative)
export const LIST_COOPERATIVE_VENTURE_PROFILES_PATH = "/profile/COOPERATIVE_VENTURE";
export const EDIT_COOPERATIVE_VENTURE_PROFILE_PATH = "/profile/cooperative-venture/:id/edit";
export const CREATE_COOPERATIVE_VENTURE_PROFILE_PATH = "/profile/cooperative-venture/create";
export const VIEW_COOPERATIVE_VENTURE_PROFILE_PATH = "/profile/cooperative-venture/:id";
// organization
export const LIST_ORGANIZATION_PROFILES_PATH = "/profile/ORGANIZATION";
export const EDIT_ORGANIZATION_PROFILE_PATH = "/profile/organization/:id/edit";
export const CREATE_ORGANIZATION_PROFILE_PATH = "/profile/organization/create";
export const VIEW_ORGANIZATION_PROFILE_PATH = "/profile/organization/:id";

/**
 * DEFAULT PATH
 */
export const APP_DEFAULT_PATH = DASHBOARD_PATH;
