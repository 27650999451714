import React from 'react';
import EnterpriseProfile from '../EnterpriseProfileForms';
import {connect} from 'react-redux';

import {ALL_PROFILES_PATH, VIEW_ENTERPRISE_PROFILE_PATH} from '~/configs/routesConfig';
import strings from '~/localization';
import {message} from 'antd';
import {showError} from '~/configs/ServerErrors';
import {getEnterpriseProfile, deleteEnterpriseProfile, getBranches} from '~/state/ducks/appApis/actions';
import _ from 'lodash';

import {updateFarmProfile, createEnterpriseProfile, updateEnterpriseProfile} from '~/state/ducks/authUser/actions';

import {getArray, getString, getNumber, getObject} from '~/views/utilities/helpers/utilObject';
import {StepsFormContainerStyle} from '~/views/container/styles';
import ActivityForm from '../../ActivityForm';
import {withRouterInnerRef} from '~/views/container/commons';

const emptyObject = () => ({
  errors: {},
  surrogate: undefined,
  email: undefined,
  employee: undefined, // tong so thanh vien
  farmingSeasonNumberId: undefined, // so mua vu
  avatar: undefined,
  images: undefined,
  name: undefined, // ten doanh nghiep
  phone: undefined,
  costYear: undefined, // chi phi hang nam
  grossArea: undefined, // tong dien tich
  grossYield: undefined, // tong san luong
  personalIncomeTax: undefined, // thue thu nhap ca nhan
  profitYear: undefined, // loi nhuan
  revenueYear: undefined, // doanh thu
  vat: undefined, // thue GTGT
  marketsIds: [], // thi truong tieu thu
  bankAccounts: [],
  certifications: [],
  businessLicenses: [], // cac loai giay chung nhan

  //custom
  provinceId: undefined,
  wardsId: undefined,
  districtId: undefined,
});
class EnterPrieForm extends React.Component {
  constructor(props) {
    super(props);
    let editable = true;

    const {match} = this.props;
    switch (match.path) {
      case VIEW_ENTERPRISE_PROFILE_PATH:
        {
          editable = false;
        }
        break;
    }
    // switch(match.path){
    //   case VIEW_PERSONAL_PROFILE_PATH: {editable = false} break
    // }
    this.state = {
      editable,
      enterprise: {},
    };
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }
  onDelete() {
    const {deleteEnterpriseProfile, match, history} = this.props;
    let farmProfile = match.params.id;
    deleteEnterpriseProfile(farmProfile)
      .then(() => {
        message.success(strings.delete_profile_success);
        history.push(ALL_PROFILES_PATH);
      })
      .catch((err) => showError(err));
  }
  componentDidMount() {
    this.fetchProfile();
  }
  getMapObjectToReponseFarm = () => {
    let {enterprise} = this.state;
    console.error('EnterPrieForm -> getMapObjectToReponseFarm -> enterprise', enterprise);
    if (Object.keys(enterprise.errors).length) return enterprise;
    let enterProfile = {
      avatar: getString(enterprise, 'avatar'), //
      bankAccounts: getArray(enterprise, 'bankAccounts', []).map((item) => {
        return {
          accountName: item.accountName,
          accountNumber: item.accountNumber,
          bankId: item.bankId,
          branchId: item.branchId,
        };
      }), //

      costYear: enterprise.costYear, // chi phi nam
      email: getString(enterprise, 'email'), //email
      employee: enterprise.employee, // tong so thanh vien
      farmingSeason: {
        // so mua vu
        unitName: 'vụ/năm',
        value: enterprise.farmingSeasonNumberId,
      },
      grossArea: {
        //tong dien tich quan ly
        unitId: enterprise.unitId,
        value: enterprise.grossArea,
      },
      formOfUseIds: getArray(enterprise, 'formOfUseIds', []).map((item) => {
        return item;
      }), // tinh trang su dung dat
      grossYield: {
        unitName: 'tấn/năm',
        // unitId: enterprise.unitForMass,
        value: enterprise.grossYield,
      },
      marketIds: getArray(enterprise, 'marketsIds', []).map((item) => {
        return item;
      }), // thi truiong
      name: getString(enterprise, 'name'), //ten
      incomeTax: enterprise.personalIncomeTax, // thue thu nhap ca nhan
      phone: enterprise.phone || undefined, //so dien thoai

      profitYear: enterprise.profitYear, // loi nhuan nam
      revenueYear: enterprise.revenueYear, // doanh thu nam
      surrogate: enterprise.surrogate || undefined, //ten nguoi danh dien
      vat: enterprise.vat, // thua gt gt nam
      businessLicenses: getArray(enterprise, 'businessLicenses', []).map((item) => {
        return {
          //
          images: getString(item, 'images'),
          activateDate: item.expirationDate,
          address: {
            address1: item.address1,
            provinceId: item.provinceId,
            districtId: item.districtId,
            wardsId: item.wardsId,
          },
          businessTypeIds: getArray(item, 'cultivation', []).map((item) => {
            return item;
          }),
          groupManagementIds: getArray(item, 'groupManagementIds', []).map((item) => {
            return item;
          }),
          issuedDate: item.issuedDate,
          issuedPlace: item.issuedPlace,
          licenseCode: item.numberCN,
          productionTypeIds: getArray(item, 'productionTypesIds', []).map((item) => {
            return item;
          }),
          taxCode: item.idTax,
          type: item.typeId,
        };
      }),
      certifications: getArray(enterprise, 'certifications', []).map((item) => {
        return {
          issuedDate: item.issuedDate,
          expirationDate: item.expirationDate,
          images: item.images,
          reassessmentDate: item.dateReEvaluate,
          issuedBy: item.issuedPlace,
          typeId: item.typeId,
          evaluationDate: item.dateEvaluate,
        };
      }),
    };
    return enterProfile;
  };
  onSave() {
    const {updateEnterpriseProfile, match, history} = this.props;
    const enterprise = this.getMapObjectToReponseFarm();
    const errorMess = getObject(enterprise.errors, undefined, {});

    if (Object.keys(errorMess).length) {
      message.error(Object.values(enterprise.errors)[0]);
      return;
    }
    const reponse = {
      ...this.getMapObjectToReponseFarm(),
      id: +match.params.id,
    };
    console.error('EnterPrieForm -> onSave -> reponse', reponse);
    console.error('EnterPrieForm -> onSave -> reponse', reponse);
    updateEnterpriseProfile(reponse)
      .then(() => {
        message.success(strings.update_profile_successful);
        history.push(ALL_PROFILES_PATH);
      })
      .catch((err) => showError(err));
  }
  onCreate = () => {
    const {createEnterpriseProfile, history} = this.props;
    const enterprise = this.getMapObjectToReponseFarm();
    const errorMess = getObject(enterprise.errors, undefined, {});

    if (Object.keys(errorMess).length) {
      message.error(Object.values(enterprise.errors)[0]);
      return;
    }

    createEnterpriseProfile(enterprise)
      .then(() => {
        message.success(strings.create_profile_successful);
        history.push(ALL_PROFILES_PATH);
      })
      .catch((err) => showError(err));
  };
  fetchProfile = () => {
    const {match, getEnterpriseProfile} = this.props;

    let profileId = +match.params.id;
    /**
     * trường hợp tạo profile
     */
    if (_.isNaN(profileId)) {
      this.setState({enterprise: emptyObject()});
      return;
    }

    /**
     * trường hợp cập nhật profile
     */
    getEnterpriseProfile(profileId)
      .then(async ({res}) => {
        // map data từ response về format chuẩn để hiển thị trên views
        let mappedObject = {
          ...res,
          avatar: getString(res, 'avatar'), //

          email: getString(res, 'email'),

          formOfUseIds: getArray(res, 'formOfUseIds', []).map((item) => {
            return item;
          }), // tinh trang su dung dat

          marketIds: getArray(res, 'marketIds', []).map((item) => {
            return item;
          }), // thi truiong
          name: getString(res, 'name'), //ten
          phone: res.phone || undefined, //so dien thoai

          surrogate: res.surrogate || undefined, //ten nguoi danh dien
          ////
          costYear1: getNumber(res, 'costYear.value'),
          revenueYear1: getNumber(res, 'revenueYear.value'),
          vat1: getNumber(res, 'vat.value'),
          personalIncomeTax1: getNumber(res, 'incomeTax.value'),
          profitYear1: getNumber(res, 'profitYear.value'),
          //
          grossArea: getNumber(res, 'grossArea.value'),
          unitId: getNumber(res, 'grossArea.unitId'),

          farmingSeasonNumberId: getNumber(res, 'farmingSeason.value'),

          grossYield: getNumber(res, 'grossYield.value'),
          employee: getNumber(res, 'employee'), // tong so thanh vien

          marketsIds: getArray(res, 'marketIds', []).map((item) => {
            return item;
          }),

          certifications: getArray(res, 'certifications', []).map((item) => {
            return {
              issuedDate: item.issuedDate,
              expirationDate: item.expirationDate,
              images: item.images,
              dateReEvaluate: item.reassessmentDate,
              issuedPlace: item.issuedBy,
              typeId: item.typeId,
              dateEvaluate: item.evaluationDate,
              kyc: item.kyc === null ? false : true,
            };
          }),
          bankAccounts: getArray(res, 'bankAccounts', []).map((item) => ({
            ...item,
            id: undefined,
            bankId: getNumber(item, 'bankId'),
            branchId: getNumber(item, 'branchId'),
          })),
          businessLicenses: getArray(res, 'businessLicenses', []).map((item) => {
            return {
              //
              images: getString(item, 'images'),
              expirationDate: item.activateDate,

              provinceId: getNumber(item, 'address.provinceId'),
              districtId: getNumber(item, 'address.districtId'),
              wardsId: getNumber(item, 'address.wardsId'),
              address1: getString(item, 'address.address1'),

              cultivation: getArray(item, 'businessTypeIds', []).map((item) => {
                return item;
              }),

              groupManagementIds: getArray(item, 'groupManagementIds', []).map((item) => {
                return item;
              }),
              issuedDate: item.issuedDate,
              issuedPlace: item.issuedPlace,
              numberCN: item.licenseCode,
              productionTypesIds: getArray(item, 'productionTypeIds', []).map((item) => {
                return item;
              }),
              idTax: item.taxCode,

              typeId: item.type,
              kyc: item.kyc,
            };
          }),
        };
        const {getBranches} = this.props;
        const mapMasterDataToBankAccounts = (bankAccounts) => {
          return new Promise((resolve) => {
            let mappedBankAccount = [];
            if ((bankAccounts || []).length === 0) {
              resolve([]);
              return;
            }
            try {
              (bankAccounts || []).forEach(async (item) => {
                let bankId = getNumber(item, 'bankId');
                if (bankId > 0) {
                  try {
                    let response = await getBranches(bankId);
                    let branches = getArray(response, 'res', []).map((branch) => ({
                      value: branch.id,
                      label: branch.name,
                    }));
                    mappedBankAccount.push({
                      ...item,
                      branches: branches,
                    });
                  } catch (error) {
                    mappedBankAccount.push(item);
                  }
                } else {
                  mappedBankAccount.push(item);
                }
                if ((mappedBankAccount || []).length === (bankAccounts || []).length) {
                  resolve(mappedBankAccount);
                }
              });
            } catch (error) {
              console.error(JSON.stringify(error));
            }
          });
        };

        let bankAccounts = getArray(mappedObject, 'bankAccounts', []);
        let mappedBankAccount = await mapMasterDataToBankAccounts(bankAccounts);
        mappedObject.bankAccounts = mappedBankAccount;

        this.setState({enterprise: mappedObject});
      })
      .catch((err) => console.error(err));
  };

  render() {
    return (
      <StepsFormContainerStyle>
        <div className='row' style={{width: '100%'}}>
          <div className='profile-view'>
            <EnterpriseProfile
              info={this.state.enterprise}
              editable={this.state.editable}
              showHeader={false}
              showFooter={false}
              onCreate={this.onCreate}
            />
          </div>
          <div className='events-view'>
            <ActivityForm editable={this.state.editable} info={this.state.enterprise} />
          </div>
        </div>
      </StepsFormContainerStyle>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      createEnterpriseProfile,
      getEnterpriseProfile,
      updateFarmProfile,
      deleteEnterpriseProfile,
      getBranches,
      updateEnterpriseProfile,
    },
    null,
    {
      forwardRef: true,
    }
  )(EnterPrieForm)
);
