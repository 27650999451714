export const ADD_TO_CART = "cart/ADD_TO_CART";
export const CHANGE_QUANTITY = "cart/CHANGE_QUANTITY";
export const REMOVE_ITEM_CART = "cart/REMOVE_ITEM_CART";
export const CLEAR_SERVER_CARD = "cart/CLEAR_SERVER_CARD";
export const CLEAR_CART = "clearCart";
export const ADD_CART_SERVER = "cart/ADD_CART_SERVER";
export const LOAD_CART_SERVER = "cart/LOAD_CART_SERVER";
export const LOAD_CART_SERVER_SUCCESS = "cart/LOAD_CART_SERVER_SUCCESS";
export const ASYNC_CART_SERVER_SUCCESS = "cart/ASYNC_CART_SERVER_SUCCESS";
export const REMOVE_ITEM_CART_SERVER = "cart/REMOVE_ITEM_CART_SERVER";
export const UPDATE_ITEM_CART_SERVER = "cart/UPDATE_ITEM_CART_SERVER";
export const UPDATE_ITEM_CART_SERVER_SUCCESS = "cart/UPDATE_ITEM_CART_SERVER_SUCCESS";

export const UPDATE_NEW_ITEM_ADD = "cart/UPDATE_NEW_ITEM_ADD";
export const CLOSE_NOTIFY = "cart/CLOSE_NOTIFY";

export const ASK_TO_MERGE_CART = "cart/ASK_TO_MERGE_CART";
export const CART_MERGE = "cart/CART_MERGE";
export const CART_MERGE_SUCCESS = "cart/CART_MERGE_SUCCESS";

export const GET_ADDRESSES = "cart/GET_ADDRESSES";
export const GET_ADDRESSES_SUCCESS = "cart/GET_ADDRESSES_SUCCESS";

export const POST_ADD_DELIVERY_ADDRESS = "cart/POST_ADD_DELIVERY_ADDRESS";
export const POST_DELETE_DELIVERY_ADDRESS = "cart/POST_DELETE_DELIVERY_ADDRESS";
export const POST_UPDATE_DELIVERY_ADDRESS = "cart/POST_UPDATE_DELIVERY_ADDRESS";
export const GET_SHIPPING_OPTIONS = "cart/GET_SHIPPING_OPTIONS";
export const POST_CHECKOUT_COMPLETED = "cart/POST_CHECKOUT_COMPLETED";
export const POST_ORDER_PAYMENT = "cart/POST_ORDER_PAYMENT";
export const GET_CHECKOUT_COMPLETED = "cart/GET_CHECKOUT_COMPLETED";
export const GET_CHECKOUT_PUBLIC_KEY = "cart/GET_CHECKOUT_PUBLIC_KEY";
export const POST_UPDATE_DEFAULT_ADDRESS = "cart/POST_UPDATE_DEFAULT_ADDRESS";

export const ADD_BUY_NOW_ITEM = "cart/ADD_BUY_NOW_ITEM";
export const ADD_BUY_NOW_ADDRESS = "cart/ADD_BUY_NOW_ADDRESS";

export const POST_BUY_NOW_ORDER = "cart/POST_BUY_NOW_ORDER";
export const POST_BUY_NOW_ORDER_SUCCESS = "cart/POST_BUY_NOW_ORDER_SUCCESS";

export const POST_BUY_NOW_CONFIRM = "cart/POST_BUY_NOW_CONFIRM";
export const POST_BUY_NOW_CONFIRM_SUCCESS = "cart/POST_BUY_NOW_CONFIRM_SUCCESS";

export const UPDATE_RESPONSE_BUY_NOW = "cart/UPDATE_RESPONSE_BUY_NOW";
export const POST_BUY_NOW_ORDER_COMPLETED = "cart/POST_BUY_NOW_ORDER_COMPLETED";

export const UPDATE_SEARCH_IN_CATEGORY = "cart/UPDATE_SEARCH_IN_CATEGORY";
export const GET_SHIPPING_COST = "cart/GET_SHIPPING_COST";
export const GET_SHIPPING_COST_BY_ZIP_CODE = "cart/GET_SHIPPING_COST_BY_ZIP_CODE";
export const UPDATE_ZIP_CODE = "cart/UPDATE_ZIP_CODE";

export const GET_ZIP_CODE_INFO = "cart/GET_ZIP_CODE_INFO";

export const STORE_PREVIOUS_PRODUCT = "cart/STORE_PREVIOUS_PRODUCT";
export const LOAD_HISTORY_ORDER = "cart/LOAD_HISTORY_ORDER";
export const LOAD_ORDER_WAITING = "cart/LOAD_ORDER_WAITING";
export const LOAD_ORDER = "cart/LOAD_ORDER";
export const LOAD_ORDER_SUCCESS = "cart/LOAD_ORDER_SUCCESS";
export const UPDATE_PAYING_ORDER_DETAIL = "cart/UPDATE_PAYING_ORDER_DETAIL";

export const CANCEL_ORDER = "cart/CANCEL_ORDER";
export const GET_ORDER_DETAIL = "cart/GET_ORDER_DETAIL";
export const POST_SEARCH_SUGGESTION = "cart/POST_SEARCH_SUGGESTION";
export const DELETE_ORDER = "cart/DELETE_ORDER";

export const LOAD_ADVANCE_SEARCH_RESULT = "cart/LOAD_ADVANCE_SEARCH_RESULT";
export const LOAD_ADVANCE_SEARCH_RESULT_SUCCESS = "cart/LOAD_ADVANCE_SEARCH_RESULT_SUCCESS";

export const UPDATE_CRITERION_SEARCH = "cart/UPDATE_CRITERION_SEARCH";
export const UPDATE_ADVANCE_SEARCH_RESULT = "cart/UPDATE_ADVANCE_SEARCH_RESULT";
