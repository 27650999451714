import React from "react";

import { Button, message, Modal } from "antd";
import { cartActions } from "~/state/ducks/cart";

import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { showError } from "~/configs/ServerErrors";
import strings from "~/localization";
import {
    getAllMaterialInventories,
    getAllSupplier,
    getSearchMaterialInventories,
    getTypeMaterials,
    getUnitMaterial,
    getAllCategoryPurChase,
    getStrockQuantity,
} from "~/state/ducks/appApis/actions";
import { TableEditable } from "~/views/presentation/ui/tables";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import { getArray, getObject, getNumber, getString } from "~/views/utilities/helpers/utilObject";
import Cart from "./Cart";
import { CATEGORY_TYPE } from "~/configs/const";
const selectNull = { key: -1, value: " ", label: strings.default_choice };
let MATERIALALL = [];
class InputForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { dataSource: this.props.materials || [], visible: false, cart: null };
    }
    checkPrice = (item) => {
        let price = getNumber(item, "salePrice", 0) ? getNumber(item, "salePrice", 0) : getNumber(item, "price", 0);
        return price;
    };
    caculatorPrice = (pricingOptions, qty) => {
        console.log("InputForm -> caculatorPrice -> pricingOptions", pricingOptions)
        let result = { price: 0, id: null };
        const pricingRanges = getArray(pricingOptions[0], "pricingRanges", []);
        if (pricingRanges.length) {
            _.forEach(pricingRanges, (item) => {
            console.log("InputForm -> caculatorPrice -> item", item)
                const fromValue = getNumber(item, "fromValue", 0);
                const toValue = getNumber(item, "toValue", 0);
                if (!toValue) {
                    result.price = this.checkPrice(item);
                    result.id = item.id;
                    return false;
                }
                if (qty >= fromValue && qty <= toValue) {
                    result.price = this.checkPrice(item);
                    result.id = item.id;
                    return false;
                }
            });
        }
        console.log("InputForm -> caculatorPrice -> result", result)
        return result;
    };
    checkSameProduct(item, product) {
        return (
            getNumber(item, "productId") == getNumber(product, "productId") &&
            getNumber(item, "manufacturerId") == getNumber(product, "manufacturerId")
        );
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { typeIdGroup, supplierIdGroup, materialInventory, unitIdGroup } = nextProps;
        if (
            typeIdGroup.length !== 0 &&
            supplierIdGroup.length !== 0 &&
            materialInventory.length !== 0 &&
            unitIdGroup.length !== 0 &&
            this.state.dataSource.length !== 0
        ) {
            let resultData = this.state.dataSource.map((item) => {
                return {
                    ...item,
                    typeIdGroupFilter: typeIdGroup,
                    supplierIdGroupFilter: supplierIdGroup,
                    materialInventoryFilter: materialInventory,
                    unitIdGroupFilter: unitIdGroup,
                };
            });
            this.setState({ dataSource: resultData || [] });
        }
        if (nextProps.materials != this.state.dataSource) {
            this.setState({ dataSource: nextProps.materials || [] });
        }
    }

    /**
     *
     * @param {*} index // index row
     * @param {*} dataIndex // dataIndex: name data hiện tại
     * @param {*} dataSource
     * @param {*} nameIndex // name index cần check để lấy value
     * @param {*} isFiler
     */
    setParamQuery(index, dataIndex, dataSource, nameIndex) {
        if (dataIndex === nameIndex) {
            let rowDataIndex = dataSource[index][dataIndex];
            if (rowDataIndex > 0) {
                return rowDataIndex;
            }
        }
    }

    /**
     *
     * @param {*} arrSelect // [id, id, id]
     * @param {*} arrSelectOld // [{id, value, name,...}, {id, value, name,...} ]
     */
    mapDataToSelect(arrSelect, arrSelectOld) {
        if (arrSelect.length === arrSelectOld.length) return arrSelectOld;
        let arrTemp = _.filter(
            arrSelectOld,
            (item) => -1 !== _.findIndex(arrSelect, (element) => element === item.value)
        );
        return arrTemp;
    }

    /**
     *
     * @param {*} materialId // number: 1
     * @param {*} name // string
     * @param {*} supplierId // number: 1
     * @param {*} typeId // number: 1
     * @param {*} unitId // number: 1
     */
    getParamSearch(materialId, name, supplierId, typeId, unitId) {
        let params = ``;
        // if (name) {
        //   if (params !== "") params += `&name=${name}`;
        //   else params += `name=${name}`;
        // }
        if (supplierId) {
            if (params !== "") params += `&manufacturerId=${supplierId}`;
            else params += `manufacturerId=${supplierId}`;
        }
        if (typeId) {
            if (params !== "") params += `&categoryId=${typeId}`;
            else params += `categoryId=${typeId}`;
        }
        // if (unitId) {
        //   if (params !== "") params += `&unitId=${unitId}`;
        //   else params += `unitId=${unitId}`;
        // }
        // if (materialId) {
        //   if (params !== "") params += `&materialId=${materialId}`;
        //   else params += `materialId=${materialId}`;
        // }
        return params;
    }

    /**
     * OnChange any field of table
     * @param {*} index // 0
     * @param {*} dataIndex // "typeId"
     * @param {*} dataSource // row data
     */
    handleChangedData = (index, dataIndex, dataSource) => {
        const {
            onChange,
            getSearchMaterialInventories,
            typeIdGroup,
            supplierIdGroup,
            unitIdGroup,
            getStrockQuantity,
        } = this.props;

        onChange && onChange(dataSource);

        let paramTypeId = undefined;
        let paramSupplierId = undefined;
        let paramMaterialId = undefined;
        let paramUnitId = undefined;
        let paramName = undefined;

        let isProduct = false;
        let row = dataSource[index];
        // Calculator money
        let typeId = getNumber(row, "typeId", 0);
        let materialId = getNumber(row, "materialId", 0);
        let supplierId = getNumber(row, "supplierId", 0);
        let unitId = getNumber(row, "unitId", 0);
        let quantity = getNumber(row, "quantity", 0);
        if (dataIndex === "typeId") {
            row["materialId"] = null;
        }

        // Khi chọn dữ liệu 1 trong các ô input product
        if (
            dataIndex === "materialId" ||
            dataIndex === "supplierId" ||
            dataIndex === "typeId" ||
            dataIndex === "unitId"
        ) {
            paramTypeId = this.setParamQuery(index, "typeId", dataSource, "typeId");
            paramSupplierId = this.setParamQuery(index, "supplierId", dataSource, "supplierId");
            paramMaterialId = this.setParamQuery(index, "materialId", dataSource, "materialInventory");
            // Khi một trong 3 trường dữ liệu thay đổi thì fetch API và cập nhật 3  trường còn lại

            // Để code ở đây hơi cồng kềnh - nhưng mà số field truyền vào khi gọi sẽ rõ ràng lúc gọi
            let params = this.getParamSearch(paramMaterialId, paramName, paramSupplierId, paramTypeId, paramUnitId);
            if (dataIndex === "typeId" || dataIndex === "supplierId")
                getSearchMaterialInventories(params)
                    .then((res) => {
                        // set 3 options của 3 trường còn lại:
                        let newData = getArray(res, "res", []);

                        getArray(res, "res", []).map((data) => {});
                        //

                        row.materialInventoryFilter = [
                            selectNull,
                            ...getArray(res, "res", []).map((item) => {
                                return {
                                    ...item,
                                    value: item.id ? item.id : item.id,
                                    id: item.id ? item.id : item.id,
                                    label: item.name,
                                    pricingOptions: item.pricingOptions,
                                };
                            }),
                        ];
                        // one material => set status and action
                        // === 2 because 1 default select and one data only
                        if (getArray(newData["materialInventory"], undefined, []).length === 1) {
                            // row.status = newData["materialInventory"][0].status;
                            row.status =
                                newData["materialInventory"][0].status === "NO_ORDER"
                                    ? strings.NO_ORDER
                                    : strings.ORDERED;
                            // row.action = newData["materialInventory"][0].status === "NO_ORDER" ? strings.ORDER : " ";
                        }
                        row.typeIdGroupFilter = [selectNull, ...typeIdGroup];
                        row.supplierIdGroupFilter = [
                            selectNull,
                            ...this.mapDataToSelect(newData["supplierIdGroup"], supplierIdGroup),
                        ];
                        row.unitIdGroupFilter = [
                            selectNull,
                            ...this.mapDataToSelect(newData["unitIdGroup"], unitIdGroup),
                        ];

                        if (dataIndex === "typeId") {
                            row.supplierId = undefined;
                            row.materialId = undefined;
                            row.unitId = undefined;
                            row.price = undefined;
                            row.amount = undefined;
                            row.status = undefined;
                            // row.action = undefined;
                            row.quantity = undefined;
                            row.quantityPlan = undefined;
                        }

                        this.setState({ dataSource });
                        return;
                    })
                    .catch((err) => {
                        console.error("handleChangedData -> err", err);
                        // showError(err);
                    });

            // end if  Khi chọn dữ liệu 1 trong các ô input
        }
        // if (typeId > 0 && materialId > 0 && supplierId > 0 && unitId > 0) {
        //   isProduct = true;
        // } else isProduct = false;

        // if (isProduct) {
        //   row.price = row.priceCurrent;
        // } else {
        //   row.price = "";
        //   row.amount = "";
        // }

        // Check orderDate larger receiveDate
        if (dataIndex === "orderDate" || dataIndex === "receiveDate") {
            let orderDate = moment.utc(dataSource[index].orderDate, UtilDate.formatDateTimeServer);
            let receiveDate = moment.utc(dataSource[index].receiveDate, UtilDate.formatDateTimeServer);
            if (orderDate.isValid() && receiveDate.isValid() && orderDate.isAfter(receiveDate)) {
                let row = dataSource[index];
                row.receiveDate = undefined;
                message.error(strings.order_date_greater_than_received_date);
            }
        }
        if (dataIndex === "quantity") {
            const tempt = getArray(row, "materialInventoryFilter", [])
                .map((val) => {
                    if (val.id === getNumber(row, "materialId", 0)) return val;
                })
                .filter((val) => val !== undefined);
            if (getArray(tempt, undefined, []).length >= 0) {
                const price = this.caculatorPrice(getArray(tempt[0], "pricingOptions", []), row.quantity).price;
                row.pricingRangeId = this.caculatorPrice(getArray(tempt[0], "pricingOptions", []), row.quantity).id;
                row.price = price;
                if (row.quantity > 0 && price > 0)
                    row.amount = dataSource[index]["quantity"] * dataSource[index]["price"];
                const temptQuantity = +row.quantity - row.quantityPlan;

                if (temptQuantity < 0) row.amountPick = 0;
                else row.amountPick = temptQuantity;
                if (row.amountPick === 0) row.status = strings.approve_product;
                if (row.amountPick !== 0) row.status = strings.not_approve_product;
            } else {
                row.amount = 0;
            }
        }

        if (dataIndex === "materialId") {
            row.orderDate = moment().endOf("day");
            // const tempt = _.find(getArray(row, "materialInventoryFilter", []), (val) => { return val.id === getNumber(row, "materialId", 0) })

            let tempt = _.find(getArray(row, "materialInventoryFilter", []), (val) => {
                return val.id === getNumber(row, "materialId", 0);
            });

            row["info"] = _.find(getArray(row, "materialInventoryFilter", []), (val) => {
                return val.id === getNumber(row, "materialId", 0);
            });
            const temptFist = getArray(tempt, "pricingOptions", []);

            if (temptFist.length) row.unitId = getString(temptFist[0], "unitName", "");
            row.unitIdApi = getNumber(temptFist[0], "unitId", null);

            row.quantity = 0;

            //

            getStrockQuantity(materialId, row.unitIdApi)
                .then(({ res }) => {
                    row.quantityPlan =
                        getNumber(res, "totalQuantityOrder", 0) - getNumber(res, "totalQuantityOrder", 0);
                    row.totalQuantityOrder = getNumber(res, "totalQuantityOrder", 0);

                    this.setState({ dataSource });
                })
                .catch((err) => console.error("err-----", err));
        }

        this.setState({ dataSource });
    };

    handleAddRow = () => {
        // return new Row data
        const { typeIdGroup, materialInventory, supplierIdGroup, unitIdGroup } = this.props;
        return {
            materialInventoryFilter: [selectNull, ...materialInventory],
            typeIdGroupFilter: [selectNull, ...typeIdGroup],
            supplierIdGroupFilter: [selectNull, ...supplierIdGroup],
            unitIdGroupFilter: [selectNull, ...unitIdGroup],
        };
    };

    render() {
        const { editable, getAllSupplier, getTypeMaterials, getSearchMaterialInventories, maxRowCount } = this.props;

        let columns = [
            {
                title: strings.input_type,
                dataIndex: "typeId",
                editable: editable,
                width: "14%",
                placeholder: strings.input_type,
                type: "treeSelect",
                options: this.props.catagory,

                // options: typeIdGroupFilter.length === 0 ? [selectNull, ...typeIdGroup] : undefined,
                // optionIndex: "typeIdGroupFilter",
                // isSearch: false,
                // handleFetch: this.props._handleFecthStockQuantity,
                // nameData: "value",
                // filterOption: true,
                // optionFilterProp: "label",
                // renderOpts: {
                //   value: "value",
                //   labelSelect: "label",
                //   disabled: "disabled",
                // },
                rules: [
                    {
                        required: true,
                        message: strings.required,
                    },
                ],
            },
            {
                title: strings.supplier,
                dataIndex: "supplierId",
                editable: true,
                width: "10%",
                placeholder: strings.supplier,
                type: "select",
                options: this.props.supplier,

                // type: "selectSuggestion",
                // // options: supplierIdGroupFilter.length === 0 ? [selectNull, ...supplierIdGroup] : undefined,
                // optionIndex: "supplierIdGroupFilter",
                // isSearch: false,
                // handleFetch: getAllSupplier,
                // nameData: "value",
                // filterOption: true,
                // optionFilterProp: "label",
                // renderOpts: {
                //   value: "value",
                //   labelSelect: "label",
                //   disabled: "disabled",
                // },
                // rules: [
                //   {
                //     required: true,
                //     message: strings.required,
                //   },
                // ],
            },
            {
                title: strings.product,
                dataIndex: "materialId",
                editable: true,
                width: "12%",
                placeholder: strings.product,
                type: "selectSuggestion",
                // options:
                // materialInventoryFilter.length === 0 ? [selectNull, ...materialInventory] : undefined,
                optionIndex: "materialInventoryFilter",
                isSearch: false,
                // handleFetch: getSearchMaterialInventories,
                nameData: "value",
                filterOption: true,
                optionFilterProp: "label",
                renderOpts: {
                    value: "value",
                    labelSelect: "label",
                    disabled: "disabled",
                },
                rules: [
                    {
                        required: true,
                        message: strings.required,
                    },
                ],
            },
            {
                title: strings.amount_expect,
                dataIndex: "quantity",
                editable: true,
                width: "7%",
                required: true,
                placeholder: strings.amount_expect,
                min: 0,
                type: "number",
                rules: [
                    {
                        pattern: /^[0-9]{1,10}$/g,
                        message: strings.invalid_format,
                    },
                ],
            },
            {
                title: strings.unit_cal,
                dataIndex: "unitId",
                // editable: true,
                width: "7%",
                required: true,
                placeholder: strings.unit_cal,
                type: "text",
                // options: unitIdGroupFilter.length === 0 ? [selectNull, ...unitIdGroup] : undefined,
                optionIndex: "unitIdGroupFilter",
                rules: [
                    {
                        required: true,
                        message: strings.required,
                    },
                ],
            },
            {
                // auto calculator
                title: strings.procedure_unit_price,
                dataIndex: "price",
                editable: false,
                disabled: true,
                width: "7%",
                required: true,
                placeholder: strings.procedure_unit_price,
                min: 0,
                type: "numberReadOnly",
                suffix: "đ",
                rules: [
                    {
                        // pattern: /^[0-9]{1,15}$/g,
                        message: strings.invalid_format,
                    },
                ],
            },
            {
                // auto calculator
                title: strings.into_money,
                dataIndex: "amount",
                editable: false,
                disabled: true,
                width: "7%",
                required: true,
                type: "numberReadOnly",
                min: 0,
                suffix: "đ",
                placeholder: strings.into_money,
                rules: [
                    {
                        // pattern: /^[0-9]{1,15}$/g,
                        message: strings.invalid_format,
                    },
                ],
            },
            {
                title: strings.order_date,
                dataIndex: "orderDate",
                // TODO: Dựa vào số lượng trong kho mà làm load ngày đặt và ngày nhận ở đây
                // editable: true,
                width: "7%",
                required: false,
                placeholder: strings.order_date,
                min: 0,
                type: "date",
                disabledDate: (current) => {
                    // Can not select days before today and today
                    return current && current < moment().endOf("day");
                },
            },
            {
                title: strings.received_date,
                dataIndex: "receiveDate",
                // TODO: Dựa vào số lượng trong kho mà làm load ngày đặt và ngày nhận ở đây
                editable: true,
                width: "7%",
                required: false,
                placeholder: strings.received_date,
                min: 0,
                type: "date",
                disabledDate: (current) => {
                    // Can not select days before today and today
                    return current && current < moment().endOf("day");
                },
            },
            {
                title: strings.current_inventory_expect,
                dataIndex: "quantityPlan",
                editable: false,
                disabled: true,
                width: "7%",
                required: true,
                type: "numberReadOnly",
                min: 0,
                suffix: "",
                placeholder: strings.current_inventory_expect,
            },
            {
                title: strings.amount_must_pick,
                dataIndex: "amountPick",
                editable: false,
                disabled: true,
                width: "7%",
                required: true,
                type: "numberReadOnly",
                min: 0,
                suffix: "",
                placeholder: strings.amount_must_pick,
            },
            {
                title: strings.status,
                dataIndex: "status",
                editable: false,
                width: "7%",
                required: false,
                placeholder: strings.status,
                type: "text",
            },
            {
                title: strings.action,
                dataIndex: "action",
                width: "7%",
                editable: true,
                required: false,
                placeholder: strings.pickup_product,
                type: "link",
                action: (rows) => {
                    const data = rows.info;
                    console.log("rows.pricingRangeId", rows.pricingRangeId);
                    let mapPrice = getArray(_.first(getArray(data, "pricingOptions")), "pricingRanges");

                    let unitName = getString(_.first(getArray(data, "pricingOptions")), "unitName");
                    let product = {
                        manufacturerId: getNumber(data, "manufacturer.id"),
                        productId: getNumber(data, "id"),
                        quantity: rows.amountPick || 0,
                        unitPrice: rows.price ? rows.price : getNumber(data, "salePrice", 0),
                        pricingRangeOptions: mapPrice,
                        pricingRangeId: rows.pricingRangeId,
                        originalPrice: getNumber(data, "originalPrice", 0),
                        name: getString(data, "name"),
                        unitName: unitName,
                        image: getString(data, "images"),
                        manufacturerName: getString(data, "manufacturer.profile.name"),
                    };

                    if (!rows.quantity) {
                        message.warning(strings.must_chosse_quantity);
                        return;
                    }
                    if (!rows.materialId) {
                        message.warning(strings.must_chosse_product);
                        return;
                    }

                    let flag = false;
                    getArray(this.props, "cartLocal.cart.clientCart.cartItems", []).map((item) => {
                        if (getNumber(item, "manufacturerId") == getNumber(product, "manufacturerId")) {
                            getArray(item, "orderDetails", []).map((val) => {
                                if (getNumber(val, "productId") == getNumber(product, "productId")) {
                                    product["quantity"] = 0;
                                    flag = true;
                                }
                            });
                        }
                    });
                    if (!flag) this.props.updateNewItemAdd(product);

                    this.setState({ visible: !this.state.visible, cart: data });
                },
            },
        ];

        return (
            <div>
                <TableEditable
                    scroll={{ x: 1800 }}
                    editable={editable}
                    maxRowCount={maxRowCount || 0}
                    handleAddRow={this.handleAddRow}
                    changedData={this.handleChangedData}
                    dataSource={this.state.dataSource || []}
                    columns={columns}
                />
                <Modal
                    title="Mua hàng hóa"
                    visible={this.state.visible}
                    width={1500}
                    onOk={this.handleOk}
                    footer={() => <Button>OK</Button>}
                    onCancel={() => {
                        this.setState({ visible: !this.state.visible });
                    }}
                >
                    <Cart cart={this.state.cart} />
                </Modal>
            </div>
        );
    }
}

export default compose(
    connect(
        (state) => ({
            cartLocal: state,
        }),
        {
            getAllMaterialInventories,
            getAllSupplier,
            getTypeMaterials,
            getUnitMaterial,
            getSearchMaterialInventories,
            getAllCategoryPurChase,
            getStrockQuantity,
            updateNewItemAdd: cartActions.updateNewItemAdd,
            getCart: cartActions.getCart,
        }
    ),
    withState("typeIdGroup", "setTypeIdGroup", []),
    withState("supplierIdGroup", "setSupplierIdGroup", []),
    withState("materialInventory", "setMaterialInventory", []),
    withState("unitIdGroup", "setUnitIdGroup", []),

    withState("isMappedData", "setIsMappedData", false),

    withState("dataSource", "setDataSource", []),
    withState("maxRowCount", "setMaxRowCount", []),
    // save param when changed
    withState("paramTypeId", "setParamTypeId", []),
    withState("paramSupplierId", "setParamSupplierId", []),
    withState("paramMaterialId", "setParamMaterialId", []),
    withState("paramUnitId", "setParamUnitId", []),
    //viet
    withState("catagory", "setCatagory", []),
    withState("supplier", "setSupplier", []),

    withState("stockQuantity", "setStockQuantity", []),

    //end viet
    withHandlers({
        _handleFecthStockQuantity: (props) => (id) => {
            const { getStrockQuantity, setStockQuantity, stockQuantity } = props;
            getStrockQuantity(id).then(({ res }) => {
                // setStockQuantity
            });
        },
        _handleFecthCategory: (props) => () => {
            const selectNull = { key: -1, value: " ", label: strings.default_choice };

            const { setCatagory, getAllCategoryPurChase } = props;
            getAllCategoryPurChase()
                .then(({ res }) => {
                    //   let tempt =getArray(res, undefined, []).map((item) => ({
                    //     value: item.id,
                    //     label: item.name || "",
                    //   }))
                    //
                    //   setCatagory([selectNull,...tempt])

                    const getItems = (list) => {
                        let results = (getArray(list) || []).map((item) => ({
                            value: getNumber(item, "id") + "",
                            label: getString(item, "name"),
                            children: getItems(getArray(item, "subCategories")),
                        }));
                        return results;
                    };

                    setCatagory(getItems(res));
                })
                .catch((err) => console.error(err));
        },
        fetchTypeId: (props) => () => {
            const { setTypeIdGroup, getTypeMaterials, getSearchMaterialInventories } = props;
            getTypeMaterials()
                .then(({ res }) => {
                    let response = [...res];
                    getSearchMaterialInventories().then(({ res }) => {
                        let arrTemp1 = getArray(response, undefined, []);
                        let arrTemp2 = getArray(res, "typeIdGroup", []);
                        // Nếu không có trong arrTemp2 thì disabled
                        let arrResult = [];
                        for (let i = 0; i < arrTemp1.length; i++) {
                            if (
                                _.findIndex(arrTemp2, (item) => {
                                    return item === arrTemp1[i].id;
                                }) !== -1
                            ) {
                                arrResult.push({ ...arrTemp1[i], disabled: false });
                            } else arrResult.push({ ...arrTemp1[i], disabled: true });
                        }
                        setTypeIdGroup(
                            arrResult.map((item) => {
                                return {
                                    value: item.id,
                                    label: item.name,
                                    disabled: item.disabled,
                                };
                            })
                        );
                    });
                })
                .catch((err) => {
                    console.error("fetchTypeId -> err", err);
                    showError(err);
                });
        },
        fetchSupplier: (props) => () => {
            const selectNull = { key: -1, value: " ", label: strings.default_choice };

            const { setSupplier, getAllSupplier } = props;
            getAllSupplier()
                .then(({ res }) => {
                    let tempt = getArray(res, undefined, []).map((item) => ({
                        value: item.id,
                        label: getString(item, "profile.name", "") || "",
                    }));

                    setSupplier([selectNull, ...tempt]);
                })
                .catch((err) => {
                    console.error("getAllSupplier -> err", err);
                    showError(err);
                });
        },
        fetchInventories: (props) => () => {
            const { getAllMaterialInventories, setMaterialInventory } = props;
            getAllMaterialInventories()
                .then(({ res }) => {
                    setMaterialInventory(
                        getArray(res, "materialInventory", []).map((item) => {
                            return {
                                value: item.materialId ? item.materialId : item.id,
                                id: item.materialId ? item.materialId : item.id,
                                label: item.name,
                            };
                        })
                    );
                })
                .catch((err) => {
                    console.error("fetchInventories -> err", err);
                    showError(err);
                });
        },
        fetchUnitMaterial: (props) => () => {
            const { setUnitIdGroup, getUnitMaterial } = props;
            getUnitMaterial()
                .then(({ res }) => {
                    setUnitIdGroup(
                        getArray(res, undefined, []).map((item) => ({
                            value: item.id,
                            label: item.name || "",
                        }))
                    );
                })
                .catch((err) => {
                    console.error("fetchTypeId -> err", err);
                    showError(err);
                });
        },
    }),
    lifecycle({
        componentDidMount() {
            const {
                fetchInventories,
                fetchSupplier,
                fetchUnitMaterial,
                fetchTypeId,
                _handleFecthCategory,
            } = this.props;
            // fetchTypeId();
            fetchSupplier();
            // fetchInventories();
            // fetchUnitMaterial();
            _handleFecthCategory();
        },
    })
)(InputForm);
