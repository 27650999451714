import React from "react";

import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withHandlers } from "recompose";
import {
  getFarmProcessActivitiesUpcoming,
  getFarmProcessActivitiesRecent,
} from "~/state/ducks/appApis/actions";
import { ListLoadMore } from "../../components";
import strings from "~/localization";

class FarmActivities extends React.PureComponent {
  render() {
    const {
      getFarmProcessActivitiesRecent,
      getFarmProcessActivitiesUpcoming,
      match,
    } = this.props;

    return (
      <div className="bg-fuchsia farming-calendar mt-4 shadow ml-4 ">
        <h4 className="title">{strings.farming_calendar}</h4>
        <div className="list-item" onScroll={this.fetchMoreData}>
          <p className="item-title">{strings.recent_farming_activities}</p>
          <ListLoadMore
            fetch={getFarmProcessActivitiesRecent}
            isToday={true}
            id={match.params.id}
            isActivityFarm={true}
          />
        </div>
        <div className="list-item divider-top" onScroll={this.fetchMoreData}>
          <p className="item-title">{strings.upcoming_farming_schedule} </p>
          <ListLoadMore
            fetch={getFarmProcessActivitiesUpcoming}
            isTomorrow={true}
            id={match.params.id}
            isActivityFarm={true}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getFarmProcessActivitiesRecent,
    getFarmProcessActivitiesUpcoming,
  }),
  withHandlers({}),
  lifecycle({
    componentDidMount() {},
  })
)(FarmActivities);
