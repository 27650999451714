import React from "react";

import { Breadcrumb, Input, Modal } from "antd";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import styled from "styled-components";
import strings from "~/localization";

const SearchStyled = styled(Input)`
  input {
    height: 30px !important;
  }
  border-left: 1px !important;
  border-top: 1px !important;
  border-right: 1px !important;
  :hover {
    border-color: transparent !important;
    border-right-width: 1px !important;
    outline: 0 !important;
  }
`;
const ContainerStyled = styled.div`
  height: 70px;
  span.ant-dropdown-trigger {
    margin-right: auto;
  }
  background-color: #fff;
  -moz-box-shadow: 3px 3px 5px 0px #ccc;
  -webkit-box-shadow: 3px 3px 5px 0px #ccc;
  box-shadow: 1px 1px 1px 0px #ccc;
  padding: 2px 0px;
  .row {
    height: 30px;
    align-items: center;
    div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
`;
const GroupButtonStyled = styled.div`
  button {
    width: auto;
    height: 24px !important;
    padding: 0px 15px !important;
    border-radius: 5px;
  }
  button span {
    text-transform: lowercase;

    cursor: pointer;
  }
  button span:first-letter {
    text-transform: uppercase;
  }
`;
// const FilterGroup = styled.div`
//   .filter {
//     color: gray;
//   }
//   display: flex;
//   justify-content: space-between;
//   divi: hover {
//     cursor: pointer;
//   }
// `;
// const ActionStyled = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   padding-right: 26px;

//   span {
//     cursor: pointer;
//   }
// `;
// const SegmentedControlContainer = styled.div`
//   .r-segmented-control > ul > li.base {
//     border-color: transparent !important;
//     color: lightgray !important;
//     background: transparent !important;
//     padding: 0px 5px;
//   }
//   .r-segmented-control > ul > li.base.selected {
//     background: transparent !important;
//     color: gray !important;
//   }
//   .r-segmented-control {
//     background-color: transparent !important;
//     border: none !important;
//   }
// `;

class FarmNav extends React.PureComponent {
  constructor(props) {
    super(props);
    if (global.viewAsBlocks === undefined) {
      global.viewAsBlocks = true;
    }
    this.state = {
      viewAsBlocks: global.viewAsBlocks,
      isChooseProfileType: false,
    };
  }

  handleChangeView = () => {
    let viewAsBlocks = this.state.viewAsBlocks;
    this.setState({ viewAsBlocks: !viewAsBlocks });
    global.viewAsBlocks = !viewAsBlocks;
    this.handleUpdateViewStyle(!viewAsBlocks);
  };
  componentDidMount() {
    this.handleUpdateViewStyle(this.state.viewAsBlocks);
  }

  handleUpdateViewStyle = (viewAsBlocks) => {
    const { changeViewStyle } = this.props;
    changeViewStyle && changeViewStyle(viewAsBlocks);
  };
  checkTitleButton = (title) => {};
  checkButtonAction = (title) => {};
  renderButtons = () => {};

  renderRightContent = () => {};

  render() {
    const { history, breadcrumb = [{ title: "", href: "" }] } = this.props;
    return (
      <ContainerStyled className={this.props.className}>
        <div className="row">
          <div className="col-6 ">
            <Breadcrumb className="ml-3" separator=">">
              {(breadcrumb || []).map((item, index) => (
                <Breadcrumb.Item
                  key={index}
                  style={{ cursor: item.href ? "pointer" : "default" }}
                  onClick={() => {
                    item.href && history.push(item.href);
                  }}>
                  {item.title || ""}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
          <div className="col-6">
            <SearchStyled
              className="mr-3"
              suffix={<i className="fa fa-search" />}
              placeholder={strings.search_placeholder}
              enterButton
            />
          </div>
        </div>
        <div className="row">
          <GroupButtonStyled className="col-6">
            {this.renderButtons()}
          </GroupButtonStyled>
          {this.renderRightContent()}
        </div>
      </ContainerStyled>
    );
  }
}
export default compose(withRouter)(FarmNav);
