import React from "react";

import {message} from "antd";
import _ from "lodash";
import moment from "moment";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose, lifecycle, withHandlers, withState} from "recompose";
import {VIEW_SEASON_CRUD_FARM_PATH} from "~/configs/routesConfig";
import {showError} from "~/configs/ServerErrors";
import strings from "~/localization";
import {getFarmSeasonCRUD, rescheduleSeasonProcessStep} from "~/state/ducks/appApis/actions";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import {getArray, getNumber, getString, getObject} from "~/views/utilities/helpers/utilObject";

import styled from "styled-components";
import {Color} from "~/views/utilities/layout";
import InfoItem from "../../components/InfoItem";

const WrapInfoSeason = styled.div`
  background: ${Color.white};
  padding: 8px;
  .content-right {
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .info-more {
    text-align: left;
    font-size: 12px;
    color: ${Color.secondaryText};
    font-weight: normal;
    text-decoration: none;
    font-style: italic;
    font-variant: normal;
    text-transform: none;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
`;

class InfoSeason extends React.Component {
  handleButtonClick() {
    message.info("Click on left button.");
  }
  handleMenuClick() {
    message.info("Click on menu item.");
  }
  /**
   * Description: Link to farm season detail
   * @param {*} e event
   * @param {*} record contain id
   */
  onClickFarmSeason = (e, record) => {
    // {id: 76001, ...record}
    const {history, match} = this.props;
    history.push(VIEW_SEASON_CRUD_FARM_PATH.replace(":idSeason", getNumber(record, "id")).replace(":id", match.params.id));
  };

  mViewClassification(cls) {
    let result = "";
    for (let i = 0; i < cls.length; i++) {
      if (i === cls.length - 1) result += `${cls[i].typeName} (${cls[i].value}%) `;
      else result += `${cls[i].typeName} (${cls[i].value}%), `;
    }
    return result;
  }
  mGetStatusProcess(process) {
    let result = "";
    let cr = moment();
    const step = _.head(getArray(process, "steps", []));
    const day = moment(getString(step, "startDate"));
    const sd = moment().set({year: day.get("year"), month: day.get("month"), date: day.get("date"), hour: 0, minute: 0});

    const da = moment(getString(step, "startDate"));
    const ed = moment().set({year: da.get("year"), month: da.get("month"), date: da.get("date"), hour: 23, minute: 59});

    // TH Chưa chạy cr < sd
    if (cr.isBefore(sd))
      result = `${strings.NOT_RUN} - ${strings.will_start_the_day} ${moment.utc(step.startDate, UtilDate.formatDateTimeServer).format(UtilDate.formatDateLocal)}`;
    // TH Đang chạy sd <= cr <= ed
    if (cr.isBetween(sd, ed, undefined, "[]"))
      result = `${strings.RUNNING} - ${strings.start_the_day} ${moment.utc(step.startDate, UtilDate.formatDateTimeServer).format(UtilDate.formatDateLocal)}`;
    // TH Đã xong cr > ed
    if (cr.isAfter(ed)) result = strings.DONE;
    return result;
  }

  render() {
    const {nameFarm, seasonInfo, farmSeasonName} = this.props;
    const createDate = moment.utc(getString(seasonInfo, "sowingDate", ""), UtilDate.formatDateTimeServer).format(UtilDate.formatDateLocal);

    const info = [
      {
        label: strings.farm,
        content: nameFarm,
      },
      {
        label: strings.farm_season_name,
        content: farmSeasonName,
      },
    ];

    const sowingD = moment.utc(getString(seasonInfo, "sowingDate", ""), UtilDate.formatDateTimeServer).format(UtilDate.formatDateLocal);
    const harvestD = moment.utc(getString(seasonInfo, "harvestDate", ""), UtilDate.formatDateTimeServer).format(UtilDate.formatDateLocal);
    const grArea = getNumber(seasonInfo, "grossArea.value", 0);
    const uArea = getString(seasonInfo, "grossArea.unitName", "");
    const grYield = getNumber(seasonInfo, "grossYield.value", 0);
    const uYield = getString(seasonInfo, "grossYield.unitName", "");
    const sdv = getNumber(seasonInfo, "seedDensity.value", 0);
    const sdu = getString(seasonInfo, "seedDensity.unitName", "");
    const detail = [
      {
        label: strings.down_seed_date,
        content: sowingD === "Invalid date" ? "" : sowingD,
      },
      {
        label: strings.expected_harvest_date,
        content: harvestD === "Invalid date" ? "" : harvestD,
      },
      {
        label: strings.allocation_per_tree,
        content: this.mViewClassification(getArray(seasonInfo, "classifications", [])),
      },
      {
        label: strings.total_cultivated_area,
        content: `${grArea} ${uArea}`,
      },
      {
        label: strings.number_tree_per_ha,
        content: `${sdv} ${sdu}`,
      },
      {
        label: strings.expected_sum_output,
        content: `${grYield} ${uYield}`,
      },
    ];

    const cropName = getString(seasonInfo, "product.name", "");
    const crops = [
      {
        label: strings.crops,
        content: cropName,
        detail: `${grArea} ${uArea}`,
      },
    ];

    const materials = _.sortBy(getArray(seasonInfo, "materials", []), ["startDate"], ["asc"]).map((v) => {
      return {
        label: getString(v, "typeName"),
        content: getString(v, "materialName", "") || getString(v, "inventoryName", ""),
        detail: `${getNumber(v, "quantity", 0)} ${getString(v, "unitName", "")} -
         ${getString(v, "supplierName", "")}`,
      };
    });

    const arrProcedures = getArray(seasonInfo, "seasonProcesses", []).map((v, i) => {
      const status = this.mGetStatusProcess(getObject(v, undefined));
      return {
        label: i === 0 ? strings.procedure : undefined,
        content: getString(v, "name", ""),
        detail: status,
        action: status.includes(strings.NOT_RUN) ? {...v} : undefined,
        seasonProcess: {...v},
      };
    });

    return (
      <WrapInfoSeason>
        <div className='content-right'>
          <div className='info-more'>{`${strings.date_created} ${createDate}`}</div>
        </div>

        <InfoItem info={info} />
        <InfoItem info={detail} col={{l: 6, r: 6}} />
        <InfoItem info={crops} />
        <InfoItem info={materials} />
        <InfoItem info={arrProcedures} line={false} />
      </WrapInfoSeason>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getFarmSeasonCRUD,
    rescheduleSeasonProcessStep,
  }),
  withState("farmingSeason", "setFarmingSeason", []),
  withHandlers({
    fetchGeFarmingSeasons: (props) => () => {
      const {setFarmingSeason, getFarmSeasonCRUD, match} = props;
      getFarmSeasonCRUD(match.params.idSeason)
        .then(({res}) => {
          setFarmingSeason(getArray(res, undefined, []));
        })
        .catch((err) => {
          console.error("fetchGeFarmingSeasons -> err", err);
          showError(err);
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      const {fetchGeFarmingSeasons} = this.props;
      fetchGeFarmingSeasons();
    },
  })
)(React.memo(InfoSeason));
