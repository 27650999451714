import React from "react";
import { compose, withHandlers, lifecycle, withState } from "recompose";
import { connect } from "react-redux";
import {
  getAllProfiles,
  deletePersonalProfile,
} from "~/state/ducks/appApis/actions";
import { showError } from "~/configs/ServerErrors";
import strings from "~/localization";
import { ContentWrapper } from "~/views/presentation/ui/container";
import { message } from "antd";
import {
  getArray,
  getString,
  getNumber,
} from "~/views/utilities/helpers/utilObject";
import { PROFILE_TYPES } from "~/configs/const";
import { IMAGE_URL } from "~/configs";
import UITable from "~/views/presentation/ui/tables/Table";
import { withRouter } from "react-router-dom";
import {
  VIEW_PERSONAL_PROFILE_PATH,
  VIEW_FARM_PROFILE_PATH,
  VIEW_ENTERPRISE_PROFILE_PATH,
  VIEW_COOPERATIVE_PROFILE_PATH,
  VIEW_COOPERATIVE_VENTURE_PROFILE_PATH,
  VIEW_ORGANIZATION_PROFILE_PATH,
} from "~/configs/routesConfig";
import { Avatar } from "~/views/presentation/ui/fields";
import { ProfileType } from "~/views/container/styles";

import { ProfileItem } from "~/views/container/components";

class AllProfiles extends React.PureComponent {
  getProfileType = (type) => {
    switch (type) {
      case PROFILE_TYPES.PERSONAL_PROFILE:
        return strings.persional_profile;
      case PROFILE_TYPES.FARM_PROFILE:
        return strings.farm_profile;
      case PROFILE_TYPES.ENTERPRISE:
        return strings.enterprise_profile;
      case PROFILE_TYPES.COOPERATIVE:
        return strings.cooperative_profile;
      case PROFILE_TYPES.COOPERATIVE_VENTURE:
        return strings.cooperative_venture_profile;
      case PROFILE_TYPES.ORGANIZATION:
        return strings.organization_profile;
      default:
        return "";
    }
  };
  handleViewProfile = (profile) => {
    const { history } = this.props;
    switch (getString(profile, "type")) {
      case PROFILE_TYPES.PERSONAL_PROFILE:
        {
          history.push(
            VIEW_PERSONAL_PROFILE_PATH.replace(":id", getNumber(profile, "id"))
          );
        }
        break;
      case PROFILE_TYPES.FARM_PROFILE:
        {
          history.push(
            VIEW_FARM_PROFILE_PATH.replace(":id", getNumber(profile, "id"))
          );
        }
        break;
      case PROFILE_TYPES.ENTERPRISE:
        {
          history.push(
            VIEW_ENTERPRISE_PROFILE_PATH.replace(
              ":id",
              getNumber(profile, "id")
            )
          );
        }
        break;
      case PROFILE_TYPES.COOPERATIVE:
        {
          history.push(
            VIEW_COOPERATIVE_PROFILE_PATH.replace(
              ":id",
              getNumber(profile, "id")
            )
          );
        }
        break;
      case PROFILE_TYPES.COOPERATIVE_VENTURE:
        {
          history.push(
            VIEW_COOPERATIVE_VENTURE_PROFILE_PATH.replace(
              ":id",
              getNumber(profile, "id")
            )
          );
        }
        break;
      case PROFILE_TYPES.ORGANIZATION:
        {
          history.push(
            VIEW_ORGANIZATION_PROFILE_PATH.replace(
              ":id",
              getNumber(profile, "id")
            )
          );
        }
        break;
      default:
        break;
    }
  };

  handleDeleteProfile = (profile) => () => {
    const { deletePersonalProfile, fetchAllProfiles } = this.props;
    let profileId = getNumber(profile, "id");
    const getAPIDelete = () => {
      switch (getString(profile, "type")) {
        case PROFILE_TYPES.PERSONAL_PROFILE:
          {
            return deletePersonalProfile(profileId);
          }
          break;
        case PROFILE_TYPES.COOPERATIVE:
          {
            return deletePersonalProfile(profileId);
          }
          break;
        case PROFILE_TYPES.COOPERATIVE_VENTURE:
          {
            return deletePersonalProfile(profileId);
          }
          break;
        case PROFILE_TYPES.ORGANIZATION:
          {
            return deletePersonalProfile(profileId);
          }
          break;
        default:
          break;
      }
    };
    getAPIDelete()
      .then(({}) => {
        message.success(strings.delete_profile_success);
        fetchAllProfiles();
      })
      .catch((err) => {
        console.error("handleDeleteProfile -> err", err);
        showError(err);
      });
  };

  render() {
    /**
     * event cell click -> view profile detail
     */
    const renderCellItem = (data, records) => {
      return (
        <>
          <span>{data}</span>
          <p
            onClick={() => this.handleViewProfile(records)}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}></p>
        </>
      );
    };

    const columns = [
      {
        title: strings.type_profile,
        dataIndex: "type",
        key: "type",

        render: (cellData, records) => {
          return renderCellItem(
            <div>
              <span>{this.getProfileType(getString(records, "type", ""))}</span>
            </div>,
            records
          );
        },
        defaultSortOrder: "descend",
        sorter: (a, b) =>
          getString(a, "type", "").length - getString(b, "type", "").length,
      },
      {
        title: strings.avatar,
        dataIndex: "avatar",
        key: "avatar",
        align: "center",
        width: "15%",
        render: (cellData, records) => {
          return renderCellItem(
            <Avatar size={48} src={IMAGE_URL + cellData} />,
            records
          );
        },
      },
      {
        title: strings.name,
        dataIndex: "name",
        key: "name",
        sorter: (a, b) =>
          getString(a, "name", "").length - getString(b, "name", "").length,

        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.address,
        dataIndex: "fulladdress",
        key: "fulladdress",
        width: "25%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.phone,
        dataIndex: "phone",
        key: "phone",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.farm_area,
        dataIndex: "grossarea",
        key: "grossarea",
        sorter: (a, b) =>
          getNumber(a, "grossarea", 0) - getNumber(b, "grossarea", 0),
        align: "right",
        render: (record, records) => {
          return renderCellItem(
            `${getNumber(record, undefined, 0)} ${getString(
              records,
              "grossAreaUnit",
              strings.hectare
            )}`,
            records
          );
        },
      },
    ];

    const { profiles, viewAsBlock = false } = this.props;

    return (
      <ContentWrapper>
        {viewAsBlock ? (
          <ProfileItem
            profiles={profiles}
            getProfileType={this.getProfileType}
            handleViewProfile={this.handleViewProfile}
          />
        ) : (
          <UITable
            scroll={{ x: 1000 }}
            dataSource={profiles || []}
            rowClassName="cursor-pointer"
            columns={columns}
          />
        )}
      </ContentWrapper>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getAllProfiles,
    deletePersonalProfile,
  }),
  withState("profiles", "setProfiles", []),
  withHandlers({
    fetchAllProfiles: (props) => () => {
      let type = props.match.params.id;

      const { getAllProfiles, setProfiles } = props;
      getAllProfiles(type)
        .then(({ res }) => {
          setProfiles(getArray(res, undefined, []));
        })
        .catch((err) => showError(err));
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchAllProfiles } = this.props;
      fetchAllProfiles();
    },
  })
)(AllProfiles);
