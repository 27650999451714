import styled, { css } from "styled-components";
import { Button } from "antd";
import Color from "~/views/utilities/layout/color";

export default styled(Button)`
  border-radius: ${(props) => (props.border ? props.border + "px" : "4px")};
  min-width: 125px;
  height: 35px;
  text-transform: uppercase;
  ${(props) => {
    let cBorder = "none",
      cBackgroundColor = "#428079",
      cColor = Color.gray;
    if (!props.disabled) {
      switch (props.type) {
        case "primary":
          if (props.ghost) {
            cBorder = `solid 1px ${Color.green}`;
            cBackgroundColor = `${Color.white} !important`;
            cColor = Color.gray;
          } else {
            cColor = Color.white;
          }
          break;
        case "secondary":
          if (props.ghost) {
            cBorder = `solid 1px ${Color.yellowGreen}`;
            cBackgroundColor = `${Color.white} !important`;
            cColor = Color.yellowGreen;
          } else {
            cBackgroundColor = Color.yellowGreen;
          }
          break;
        default:
          return null;
      }
    }
    return css`
      && {
        background-color: ${cBackgroundColor};
        color: ${cColor};
        border: ${cBorder};
        &:hover {
          opacity: 0.7;
          border: ${Color.cBorder};
          background-color: ${cBackgroundColor};
          color: ${cColor};
        }
      }
    `;
  }};
`;
