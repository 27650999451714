import React from "react";
import {
  Wrapper,
  ContentWrapper,
  ContainerContent,
  LogoStyle,
  LeftContent,
  TextContent,
  FooterStyle,
} from "./styles";
import { LoginForm, OTPForm } from "~/views/presentation/AuthForm";
import { LogoInTro } from "~/static/images";
import {
  IntroduceForm,
  ChangePassForm,
  RegisterForm,
  RegisterFinishForm,
} from "../../presentation/AuthForm";
import RecoveryInputInfo from "../../presentation/AuthForm/RecoveryInputInfo";
import { withRouter } from "react-router-dom";
import {
  LOGIN_PATH,
  ACCOUNT_RECOVERY_PATH,
  RECOVERY_OTP_PATH,
  RECOVERY_CHANGE_PASSWORD_PATH,
  REGISTER_PATH,
  REGISTER_COMPLETED_PATH,
  REGISTER_OTP_PATH,
  APP_DEFAULT_PATH,
} from "~/configs/routesConfig";
import Animate from "react-smooth";
import { compose } from "recompose";
import { connect } from "react-redux";
import strings from "~/localization";

class Login extends React.PureComponent {
  renderRightContent = () => {
    switch (this.props.match.path) {
      case LOGIN_PATH: {
        return <LoginForm />;
      }
      case ACCOUNT_RECOVERY_PATH: {
        return <RecoveryInputInfo />;
      }
      case RECOVERY_OTP_PATH: {
        return <OTPForm />;
      }
      case REGISTER_OTP_PATH: {
        return <OTPForm />;
      }
      case RECOVERY_CHANGE_PASSWORD_PATH: {
        return <ChangePassForm />;
      }
      case REGISTER_PATH: {
        return <RegisterForm />;
      }
      case REGISTER_COMPLETED_PATH: {
        return <RegisterFinishForm />;
      }
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const { user, history } = this.props;
    if (user) {
      history.push(APP_DEFAULT_PATH);
    }
  }
  render() {
    const steps = [
      {
        style: {
          opacity: 0,
        },
        duration: 200,
      },
      {
        style: {
          opacity: 1,
        },
        duration: 200,
      },
      {
        style: {
          opacity: 0.9,
        },
        duration: 100,
      },
    ];

    return (
      <Wrapper>
        <LogoStyle>
          <img src={LogoInTro} alt="logo" />
        </LogoStyle>
        <ContentWrapper className="row mx-0">
          <ContainerContent>
            <span className="title">{strings.welcom_to}</span>
            <span className="des">{strings.Farm_management_backoffice}</span>
            <LeftContent className="col-sm-12 col-md-12 col-lg-4 p-0">
              <Animate steps={steps}>
                <div style={{ margin: "3rem" }} className="w-100">
                  {this.renderRightContent()}
                </div>
              </Animate>
            </LeftContent>
            <TextContent>
              <div>
                <h1>{strings.app_name}</h1>
                <h3>{strings.app_title.toUpperCase()}</h3>
                <p>{strings.app_des}</p>
              </div>
            </TextContent>
          </ContainerContent>
        </ContentWrapper>
        <FooterStyle>
          <span className="coppyright">
            {strings.coppyright}
            <span className="breakword">{" | "}</span>
            {strings.powered}
            <span className="decoration">{strings.edenhub_jsc}</span>
          </span>
          <span className="rules">{strings.rules}</span>
        </FooterStyle>
      </Wrapper>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      user: state["authUser"].user,
    }),
    {}
  ),
  withRouter
)(Login);
