import React from "react";

import _ from "lodash";
import {connect} from "react-redux";
import {compose, lifecycle, withHandlers, withState} from "recompose";
import strings from "~/localization";
import {TableEditable} from "~/views/presentation/ui/tables";
import {getNumber} from "~/views/utilities/helpers/utilObject";

class OthersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {dataSource: this.props.otherCosts || []};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.otherCosts != this.state.dataSource) {
      this.setState({dataSource: nextProps.otherCosts || []});
    }
  }

  /**
   * OnChange any field of table
   * @param {*} index // 0
   * @param {*} dataIndex // "typeId"
   * @param {*} dataSource // row data
   */
  handleChangedData = (index, dataIndex, dataSource) => {
    const {onChange} = this.props;
    onChange && onChange(dataSource);

    // Calculator money
    if (dataIndex === "quantity" || dataIndex === "price") {
      let row = dataSource[index];
      let price = getNumber(dataSource[index], "price", 0);
      let quantity = getNumber(dataSource[index], "quantity", 0);
      row.amount = quantity * price;
      this.setState({dataSource});
    }
  };

  render() {
    const {editable} = this.props;

    let columns = [
      {
        title: strings.other_cost_detail,
        dataIndex: "description",
        editable: editable,
        required: true,
        width: "20%",
        placeholder: strings.other_cost_detail,
        type: "text",
        nameData: "value",
        filterOption: true,
        optionFilterProp: "label",
        renderOpts: {
          value: "value",
          labelSelect: "label",
          disabled: "disabled",
        },
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.procedure_unit_price,
        dataIndex: "price",
        editable: editable,
        required: true,
        width: "20%",
        placeholder: strings.procedure_unit_price,
        type: "number",
        suffix: " đ",
        nameData: "value",
        filterOption: true,
        optionFilterProp: "label",
        renderOpts: {
          value: "value",
          labelSelect: "label",
          disabled: "disabled",
        },
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.count,
        dataIndex: "quantity",
        editable: editable,
        width: "20%",
        required: true,
        placeholder: strings.count,
        min: 0,
        max: 2147483647,
        type: "number",
      },
      {
        title: strings.into_money,
        dataIndex: "amount",
        editable: false,
        width: "20%",
        required: true,
        placeholder: strings.into_money,
        type: "numberReadOnly",
        suffix: "đ",
        rules: [
          {
            required: true,
            message: strings.required,
          },
        ],
      },
      {
        title: strings.note,
        dataIndex: "note",
        editable: editable,
        width: "20%",
        required: false,
        placeholder: strings.note,
        type: "text",
      },
    ];

    return (
      <div>
        <TableEditable
          // scroll={{x: 1600}}
          editable={editable}
          changedData={this.handleChangedData}
          dataSource={this.state.dataSource || []}
          columns={columns}
        />
      </div>
    );
  }
}

export default compose(
  connect(null, {}),
  withState("dataSource", "setDataSource", []),

  withHandlers({}),
  lifecycle({
    componentDidMount() {
      const {} = this.props;
    },
  })
)(OthersForm);
