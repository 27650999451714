import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withHandlers } from "recompose";
import {
  getProcessActivitiesRecent,
  getProcessActivitiesUpcoming,
} from "~/state/ducks/appApis/actions";
import { TitleCustom } from "~/views/presentation/ui/dashboard";
import strings from "~/localization";
import { ListLoadMore } from "~/views/container/components";

class FarmSeasonActivities extends React.Component {
  render() {
    const { getProcessActivitiesRecent, getProcessActivitiesUpcoming, match } =
      this.props;
    return (
      <div className="row mt-4 pl-15 w-100">
        <div className="col-12 col-lg-6 m-0 p-0">
          <div className=" col-12 ">
            <TitleCustom title={strings.recent_farming_activities} />
          </div>
          <div className="farm-season__activity col-12">
            <div className="list-item">
              <ListLoadMore
                fetch={getProcessActivitiesRecent}
                isToday={true}
                id={match.params.idSeason}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 m-0 p-0">
          <div className=" col-12 ">
            <TitleCustom title={strings.upcoming_farming_schedule} />
          </div>
          <div className="farm-season__activity col-12">
            <div className="list-item">
              <ListLoadMore
                fetch={getProcessActivitiesUpcoming}
                isTomorrow={true}
                id={match.params.idSeason}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getProcessActivitiesRecent,
    getProcessActivitiesUpcoming,
  }),
  withHandlers({}),
  lifecycle({
    componentDidMount() {},
  })
)(FarmSeasonActivities);
