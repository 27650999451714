import { getCookie } from "~/state/utils/session";
import {
	getNumber,
	getObject,
	getBool,
	getString,
	getArray,
} from "../../../views/utilities/helpers/utilObject";
import _ from 'lodash'

const cartPath = "cart";

export const getProductRecentAdded = (state) =>
	state[cartPath].productRecentAdded;
export const getNotifyCart = (state) => state[cartPath].notify;
export const getTotalAmountCart = (state) => getNumber(state,"cart.clientCart.totalPrice", 0 )
export const getTotalItemsCart = (state) => getNumber(state, "cart.clientCart.totalItem", 0)
export const getCart = (state) => getObject(  state[cartPath], "clientCart" ) || {};
export const getRequestBuyNow = (state) =>
	getObject(state[cartPath], "requestBuyNow");
export const responseBuyNow = (state) =>
	getObject(state[cartPath], "responseBuyNow");
export const getPreviousProduct = (state) =>
	getObject(state[cartPath], "previousProduct");
export const getSearchInCategory = (state) =>
	getString(state[cartPath], "searchInCategory");

export const getCarts = (state) => {
	return {
		clientCart: getObject(state[cartPath], "clientCart") || {},
		isAskMergeCart: getBool(state[cartPath], "isAskMergeCart") || false,
	};
};

export const getCartItems = (state) => {
	return state.cart.clientCart.cartItems;
};

export const zipCodeInfo = (state) => getObject(state[cartPath], "zipCodeInfo");

export const getDefaultAddress = (state) => {
	let addresses = getArray(state[cartPath], "addresses") || [];
	return addresses.find((item) => getBool(item, "isDefault") === true);
};

export const getAdvanceSearchResult = (state) =>
	getObject(state[cartPath], "advanceSearchResult");
export const getCriterionSearch = (state) =>
	getObject(state[cartPath], "criterionSearch");

export const getPayingOrder = (state) =>
	getObject(state[cartPath], "payingOrder");
