import React, { PureComponent } from "react";
import { Row, Col, Button } from "antd";
import enhance from "./withForm";
import { InputField } from "~/views/presentation/ui/fields";
import { UIButton } from "~/views/presentation/ui/buttons";
import strings from "../../../../localization";
import { ACCOUNT_RECOVERY_PATH, REGISTER_PATH } from "~/configs/routesConfig";

import { FormStyled } from "../styles";

class LoginForm extends PureComponent {
  render() {
    const {
      handleSubmit,
      values,
      handleChange,
      handleBlur,
      touched,
      errors,
      isValid,
      isSubmitting,
      history,
    } = this.props;

    return (
      <FormStyled onFinish={handleSubmit}>
        <h3 className="login">{strings.login.toUpperCase()}</h3>
        <p className="login_anotation mb-5">{strings.login_anotation}</p>
        <InputField
          validatestatus={
            touched.username && errors.username ? "error" : undefined
          }
          help={touched.username && errors.username}
          name="username"
          iconStart="user"
          style={{ borderRadius: "4px" }}
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={strings.login_placeholder}
        />
        <InputField
          validatestatus={
            touched.password && errors.password ? "error" : undefined
          }
          help={touched.password && errors.password}
          name="password"
          style={{ borderRadius: "4px" }}
          type="password"
          iconEnd="password_end"
          iconStart="password_start"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={strings.password}
          typeIcon="pass"
          size="large"
        />

        <Col span={24} className="forgot-text-moblie">
          <a
            onClick={() => {
              history.push(ACCOUNT_RECOVERY_PATH);
            }}>
            {strings.forgot_password}
          </a>
        </Col>
        <Row type="flex" align="middle" className="button-submit">
          <UIButton
            border={50}
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            disabled={!isValid}>
            <span>{strings.login}</span>
          </UIButton>
        </Row>
        <Row type="flex" className="mt-3">
          <Col span={24} className="forgot-text-desktop">
            <a
              onClick={() => {
                history.push(ACCOUNT_RECOVERY_PATH);
              }}>
              {strings.forgot_password}
            </a>
          </Col>
          <Col span={24} className="register-text">
            <span>{strings.dont_have_acount} </span>
            <a onClick={() => history.push(REGISTER_PATH)}>
              {strings.register_an_account}
            </a>
          </Col>
        </Row>
      </FormStyled>
    );
  }
}

export default enhance(LoginForm);
