import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { showError } from "~/configs/ServerErrors";
import { getAllSeasonHistory } from "~/state/ducks/appApis/actions";
import { getArray } from "~/views/utilities/helpers/utilObject";

import FarmSeasonLedgerTable from "./FarmSeasonLedgerTable";

class FarmLedgerSeasons extends React.Component {
  render() {
    const { farmingHistory, editable, nameFarm } = this.props;
    return (
      <div className="farm-season__table__item shadow">
        <FarmSeasonLedgerTable editable={editable} nameFarm={nameFarm} farmingHistory={farmingHistory} />
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getAllSeasonHistory,
  }),
  withState("farmingHistory", "setFarmingHistory", []),
  withHandlers({
    fetchGetSeasonHistory: (props) => () => {
      const { setFarmingHistory, getAllSeasonHistory, match } = props;
      getAllSeasonHistory(match.params.idSeason)
        .then(({ res }) => {
          setFarmingHistory(getArray(res, undefined, []));
        })
        .catch((err) => {
          console.error("fetchGetSeasonHistory -> err", err);
          showError(err);
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchGetSeasonHistory } = this.props;
      fetchGetSeasonHistory();
    },
  })
)(React.memo(FarmLedgerSeasons));
