import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { moveOnSupply } from "~/state/utils/session";
import styled from "styled-components";
import { getString, getArray } from "~/views/utilities/helpers/utilObject";
import { Menu } from "antd";
import { head } from "lodash";
import { COMMERCIAL } from "~/configs";
import { isCheckPath } from "./Navbar";
const { SubMenu } = Menu;

const SubMenuStyled = styled(SubMenu)`
  .active {
    color: #fff;
    font-weight: 500;
  }
  .non-style {
  }
  .non-style:hover,
  .non-style:active,
  .non-style:focus {
    text-decoration: none;
  }
`;

function NavbarFarmBar(props) {
  const { history, location, match } = props;

  return (
    <Menu
      defaultSelectedKeys={"/"}
      selectedKeys={[location.pathname]}
      style={{ height: "100%" }}
      className="menu-header"
      mode={props.topNav.mode || "horizontal"}>
      {getArray(props, "topNav.slot", []).map((item, index) => {
        return (
          <SubMenuStyled
            title={
              !item.redirect ? (
                <div
                  className={isCheckPath(item, match) ? "active word" : "word"}>
                  {item.title}
                </div>
              ) : (
                <a
                  href={"#"}
                  onClick={() => {
                    moveOnSupply(COMMERCIAL);
                  }}
                  className={
                    isCheckPath(item, match) ? "active word" : "word non-style"
                  }
                  target="_blank"
                  rel="noopener noreferrer">
                  {item.title}
                </a>
              )
            }
            key={index}
            onTitleClick={() => {
              if (item.redirect) return false;
              if (item.path !== "#") history.push(item.path);
            }}>
            {getArray(item, "subTitle", []).map((sub) => {
              return (
                <Menu.ItemGroup title={sub.name} key={sub.path}>
                  {getArray(sub, "option", []).map((options, index) => {
                    return (
                      <Menu.Item nameKey={index} key={options.path}>
                        {options.name}
                      </Menu.Item>
                    );
                  })}
                </Menu.ItemGroup>
              );
            })}
          </SubMenuStyled>
        );
      })}
    </Menu>
  );
}

export default compose(withRouter)(NavbarFarmBar);
