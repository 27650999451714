import React from "react";
import { Card, Button, message } from "antd";
import { connect } from "react-redux";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";

import { withRouter } from "react-router-dom";
import * as PATH from "~/configs/routesConfig";
import {
  createFormPost,
  getDetailFormPost,
  deleteFormPost,
  updateFormPost,
} from "~/state/ducks/authUser/actions";

import strings from "~/localization";
import ActivityForm from "~/views/container/ActivityForm";
import Loading from "~/views/presentation/ui/loading";
import FormPost from "./FormPost";
import { StepsFormContainerStyle } from "../../styles";

import _ from "lodash";

import { showError } from "~/configs/ServerErrors";
import {
  getArray,
  getString,
  getObject,
  getNumber,
  mapArrayObjectToAPI,
} from "~/views/utilities/helpers/utilObject";
import styled from "styled-components";
import { withRouterInnerRef } from "~/views/container/commons";

const WrapPostForm = styled.div`
  display: flex;
  transition: 0.5s;
  .profile-view {
    flex: 1;
    padding: 24px 16px 8px 16px;
    // min-height: 100vh;
    overflow-x: scroll;
    position: absolute;
    width: calc(100% - 450px);
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
  .events-view-form-post {
    height: 100vh;
    background-color: #fff;
    width: 450px;
    position: fixed;
    right: 0px;
  }

  .ant-card:hover {
    overflow: auto !important;
  }
  .ant-card {
    overflow: hidden !important;
  }
  .ant-card-body {
    // height: 100vh !important;
    // width: 2500px !important;
  }
  .not-active {
    transition: 0.5s;
    transition-property: all;

    transform: translateX(2000px) !important;
  }
  active {
    transition: 0.5s;
    transition-property: all;
    transform: translateX(0px) !important;
  }
  .butt {
    position: fixed;
    right: 0px;
  }
  .wi {
    width: 100% !important;
  }
  .over {
    overflow: hidden !important;
    .ant-card-body {
      height: unset !important;
      width: unset !important;
    }
  }
`;
const emptyObject = () => ({
  name_organization: undefined,
  product: [],
  errors: {},
  serviceIds: [],
});
const CHANGE_NAME_CREATE = [
  { currName: "dateShell", changeName: "publishDate" },
  { currName: "name_organization", changeName: "salerName" },
  { currName: "Km", changeName: "tradingRadius" },
  { currName: "trading", changeName: "tradingRange" },
  { currName: "transaction", changeName: "transactionType" },
  { currName: "cultivation", changeName: "type" },
  { currName: "numberWeek", changeName: "week" },
  { currName: "timeShell", changeName: "durationDate" },
  { currName: "certifications", changeName: "certification" },
  { currName: "role", changeName: "transactionRole" },
  { currName: "typeExpect", changeName: "publishType" },
];
const CHANGE_NAME_GET_DETAIL = [
  { currName: "publishDate", changeName: "dateShell" },
  { currName: "salerName", changeName: "name_organization" },
  { currName: "tradingRadius", changeName: "Km" },
  { currName: "tradingRange", changeName: "trading" },
  { currName: "transactionType", changeName: "transaction" },
  { currName: "type", changeName: "cultivation" },
  { currName: "week", changeName: "numberWeek" },
  { currName: "durationDate", changeName: "timeShell" },
  { currName: "certification", changeName: "certifications" },
  { currName: "publishType", changeName: "typeExpect" },

  { currName: "transactionRole", changeName: "role" },
];

class UpdateProce extends React.PureComponent {
  constructor(props) {
    super(props);
    let editable = true;
    let create = false;
    let view = false;
    const { match } = this.props;
    switch (match.path) {
      case PATH.VIEW_FARM_FORM_PATH:
        {
          editable = false;
          view = true;
        }
        break;

      case PATH.EDIT_FARM_FORM_PATH: {
        {
          editable = true;
          view = true;
        }
        break;
      }
      case PATH.CREATE_FARM_FORM_PATH:
        {
          create = true;
        }
        break;
    }

    this.state = {
      viewAndEdit: false,
      editable: editable,
      create,
      view,
      procedures: {},
      active: false,
      loading: false,
      info: {},
    };
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);

    // this.onDelete = this.onDelete.bind(this);
  }

  onDelete() {
    const { match, deleteFormPost } = this.props;
    let processId = +match.params.idForm;
    deleteFormPost(processId)
      .then(({ res }) => {
        message.success(strings.delete_form_sucessful);
        this.props.history.push(
          PATH.ALL_FARM_FORM_PATH.replace(
            ":idSeason",
            match.params.idSeason
          ).replace(":id", match.params.id)
        );
      })
      .catch((err) => showError(err));
  }
  mapObjectToRepopnse(objec) {
    let newOb = mapArrayObjectToAPI(objec, CHANGE_NAME_CREATE);
    newOb = {
      ...newOb,
      placeOfPlace: {
        address1: getString(newOb, "address", ""),
        address2: getString(newOb, "town_village", ""),
        districtId: getNumber(newOb, "districtId", undefined),
        provinceId: getNumber(newOb, "provinceId", undefined),
        wardsId: getNumber(newOb, "wardsId", undefined),
      },
      grossArea: {
        value: getNumber(newOb, "grossArea", undefined),
        unitId: getNumber(newOb, "unitId", null),
      },
      serviceIds: getArray(newOb, "serviceIds", []),
      productPublishes: mapArrayObjectToAPI(newOb.product, [
        { currName: "STT", changeName: "" },
        { currName: "qr_code", changeName: "qrCode" },
        { currName: "productName", changeName: "name" },
        { currName: "seeds", changeName: "seed" },
        //phan tram tam
        { currName: "plate", changeName: "crustedPercentages" },
        //
        { currName: "accountName", changeName: "packagedForm" },
        { currName: "unitName", changeName: "unitId" },
        { currName: "amount", changeName: "quantity" },
        { currName: "formality", changeName: "preservation" },

        { currName: "unitPrice", changeName: "price" },
        { currName: "type", changeName: "typeId" },
        { currName: "toPriceShell", changeName: "amout" },
        { currName: "cost_servicePercent", changeName: "costPercentages" },
        { currName: "const_serviceToMoney", changeName: "serviceCosts" },
        { currName: "week", changeName: "deliverWeek" },
        { currName: "delivery_date", changeName: "deliverDate" },
        { currName: "deposit", changeName: "depositPercentages" },
        { currName: "payment", changeName: "paymentMethod" },
        { currName: "lestBuy", changeName: "limitQuantity" },
      ]),
    };
    return newOb;
  }

  _handleFecthFormPost() {
    const { match, getDetailFormPost } = this.props;
    let idForm = +match.params.idForm;
    if (!idForm) {
      this.setState({ info: emptyObject() });
    } else {
      getDetailFormPost(idForm)
        .then(({ res }) => {
          let object = mapArrayObjectToAPI(res, CHANGE_NAME_GET_DETAIL);

          object = {
            ...object,
            product: mapArrayObjectToAPI(object.productPublishes, [
              { currName: "qrCode", changeName: "qr_code" },
              { currName: "name", changeName: "productName" },
              { currName: "seed", changeName: "seeds" },
              //phan tram tam
              { currName: "crustedPercentages", changeName: "plate" },
              { currName: "typeId", changeName: "type" },
              { currName: "preservation", changeName: "formality" },

              //
              { currName: "packagedForm", changeName: "accountName" },
              { currName: "unitId", changeName: "unitName" },
              { currName: "quantity", changeName: "amount" },

              { currName: "price", changeName: "unitPrice" },
              { currName: "amout", changeName: "toPriceShell" },
              {
                currName: "costPercentages",
                changeName: "cost_servicePercent",
              },
              { currName: "serviceCosts", changeName: "const_serviceToMoney" },
              { currName: "deliverWeek", changeName: "week" },
              { currName: "deliverDate", changeName: "delivery_date" },
              {
                currName: "depositPercentages",
                changeName: "cost_servicePercent",
              },
              { currName: "depositPercentages", changeName: "deposit" },
              { currName: "paymentMethod", changeName: "payment" },
              { currName: "limitQuantity", changeName: "lestBuy" },
              //remove
              { currName: "productPublishes", changeName: "" },
            ]),
          };

          this.setState({
            info: {
              ...object,
              // address:getString(object,"placeOfPlace.address1")
            },
          });
        })
        .catch((err) => {
          console.error("_handleFecthFormPost -> err", err);
        });
    }
  }

  // componentDidUpdate() {
  //     const { farm } = this.props;
  //     let { info } = this.state;
  //     if (farm) {
  //       info.name_organization= getString(farm, "name", "");
  //       this.setState({ info });
  //     }
  // }

  componentDidMount() {
    const { create } = this.state;
    const { farm, season } = this.props;
    this.setState({ info: { farm, season } });
    this._handleFecthFormPost();
    // this.fetchSesionProfile();
  }

  onCreate() {
    let object = this.mapObjectToRepopnse(this.state.info);
    const errorMess = getObject(this.state.info, "errors", {});
    const { match } = this.props;
    const { idSeason } = match.params;
    if (Object.keys(errorMess).length) {
      message.error(Object.values(errorMess)[0]);
      return;
    }
    let err = false;
    getArray(object, "productPublishes", []).map((item) => {
      if (!item.typeId) {
        message.error(strings.type_requried);
        err = true;
        return;
      }
      if (!item.measure) {
        message.error(strings.measure_must_than_zero);
        err = true;
        return;
      }
      if (!item.quantity) {
        message.error(strings.total_than_zero);
        err = true;
        return;
      }
      if (!item.price) {
        message.error(strings.procedure_unit_price_more_than_zero);
        err = true;
        return;
      }
    });
    if (!getArray(object, "productPublishes", []).length) {
      message.error(strings.must_post_lest_one);
      return;
    }
    if (err) return;
    object = mapArrayObjectToAPI(object, [
      { currName: "errors", changeName: "" },
      { currName: "wardsId", changeName: "" },
      { currName: "districtId", changeName: "" },
      { currName: "provinceId", changeName: "" },
      { currName: "typeExpect", changeName: "" },
      { currName: "unitId", changeName: "" },
      { currName: "product", changeName: "" },
      { currName: "address", changeName: "" },
    ]);

    this.props
      .createFormPost(object, idSeason)
      .then(({ res }) => {
        message.success(strings.create_post_sale_sucsessfull);
        this.props.history.push(
          PATH.ALL_FARM_FORM_PATH.replace(":idSeason", idSeason).replace(
            ":id",
            match.params.id
          )
        );
      })
      .catch((err) => showError(err));
  }
  onSave() {
    const { updateFormPost, history } = this.props;
    let object = this.mapObjectToRepopnse(this.state.info);

    const errorMess = getObject(this.state.info, "errors", {});
    const { match } = this.props;
    const { idSeason } = match.params;
    if (Object.keys(errorMess).length) {
      message.error(Object.values(errorMess)[0]);
      return;
    }
    let err = false;
    getArray(object, "productPublishes", []).map((item) => {
      if (!item.typeId) {
        message.error(strings.type_requried);
        err = true;
        return;
      }
      if (!item.measure) {
        message.error(strings.measure_must_than_zero);
        err = true;
        return;
      }
      if (!item.quantity) {
        message.error(strings.total_than_zero);
        err = true;
        return;
      }
      if (!item.price) {
        message.error(strings.procedure_unit_price_more_than_zero);
        err = true;
        return;
      }
    });
    if (!getArray(object, "productPublishes", []).length) {
      message.error(strings.must_post_lest_one);
      return;
    }
    if (err) return;
    object = mapArrayObjectToAPI(object, [
      { currName: "errors", changeName: "" },
      { currName: "wardsId", changeName: "" },
      { currName: "districtId", changeName: "" },
      { currName: "provinceId", changeName: "" },
      { currName: "typeExpect", changeName: "" },
      { currName: "unitId", changeName: "" },
      { currName: "product", changeName: "" },
      { currName: "address", changeName: "" },
    ]);

    updateFormPost({ ...object, id: match.params.idForm })
      .then(({ res }) => {
        message.success(strings.update_post_sale_sucsessfull);
        this.props.history.push(
          PATH.ALL_FARM_FORM_PATH.replace(":idSeason", idSeason).replace(
            ":id",
            match.params.id
          )
        );
      })
      .catch((err) => showError(err));
  }
  _handleCheckRadio = (e) => {
    const { setFieldValue } = this.props;
    setFieldValue("type", e.target.value);
  };
  render() {
    const { active, info } = this.state;
    const { farm, season } = this.props;
    return (
      <StepsFormContainerStyle>
        <div className={`profile-view`}>
          <Card bordered={false} className={!active && "over"}>
            <FormPost
              info={info}
              formPost={{ farm, season }}
              editable={this.state.editable}
              view={this.state.view}
            />
            {this.state.loading && <Loading />}
          </Card>
        </div>
        <div className="events-view">
          <ActivityForm />
        </div>
      </StepsFormContainerStyle>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      createFormPost,
      updateFormPost,
      getDetailFormPost,
      deleteFormPost,
      // getFarmProfile,
      // getFarmSeasonCRUD,
    },
    null,
    {
      forwardRef: true,
    }
  )(UpdateProce)
);
