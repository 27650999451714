import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";

function CardWrapper(props) {
  const { children, title, tools, mb } = props;
  const CardBody = styled.div`
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    h2 {
      font-size: 24px;
      color: #595959;
      text-decoration: none;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      white-space: nowrap;
      margin-top: 16px;
    }
    h3 {
      font-size: 20px;
      color: #595959;
      text-decoration: none;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      white-space: nowrap;
      margin-top: 10px;
    }
    h4 {
      font-size: 16px;
      color: #595959;
      text-decoration: none;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      white-space: nowrap;
      margin-top: 10px;
    }
    .line-pro {
      border: 0;
      height: 1px;
      width: 100%;
      background-image: -webkit-linear-gradient(
        left,
        #f0f0f0,
        #8c8b8b,
        #f0f0f0
      );
      background-image: -moz-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
      background-image: -ms-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
      background-image: -o-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    }
    &.card-body {
      padding: 1.25rem;
    }
    @media (max-width: 575px) {
      &.card-body {
        padding: 0rem 0rem;
      }
    }
  `;
  return (
    <div
      className={classNames("card", "mb-" + mb)}
      style={{ borderRadius: "4px" }}>
      <CardBody className="card-body">
        {(title || tools) && (
          <div>
            {title && <span>{title}</span>}
            {tools && <span className="card-tools-2">{tools}</span>}
          </div>
        )}
        {children}
      </CardBody>
    </div>
  );
}

CardWrapper.propTypes = {
  title: PropTypes.string,
  tools: PropTypes.any,
  mb: PropTypes.number,
};

export default CardWrapper;
