import React from "react";

import { withFormik } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withHandlers } from "recompose";
import styled from "styled-components";
import strings from "~/localization";
import { FilesUpload, ImagesUpload } from "~/views/presentation/ui/upload";

import { FileOutlined } from "@ant-design/icons";

const ContainerStyled = styled.div`
width:100%;
  padding: 8px 0 8px 8px;
  max-height: 180px;
  overflow-y: scroll;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    height: 6px !important;
    width: 6px !important;
    background: #f0f0f0 !important;
  }
  ::-webkit-scrollbar-thumb:vertical {
    background: #afafaf !important;
    border-radius: 8px !important;
  }
`;

class ActivityAttach extends React.PureComponent {
  render() {
    const { media, docs, user, profileType, onChangeMedia, onChangeDocs, onDeleteFile } = this.props;
    // id upload follow
    let profileId = this.props.match.params.id;
    if (profileType === "FARM_SEASON" || profileType === "ACTUAL_HARVEST" || profileType === "EXPECTED_HARVEST")
      profileId = this.props.match.params.idSeason;
    if (profileType === "SEASON_PROCESS")
      profileId = this.props.match.params.idProcedure;
    if (profileType === "PUBLISH_PRODUCT")
      profileId = this.props.match.params.idForm;
    if (profileType === "PACKAGING_COST")
      profileId = this.props.match.params.idPackagingCost;
    if (profileType === "MANUFACTURING_COST")
      profileId = this.props.match.params.idProductCost;
    if (profileType === "PRICE_STRUCTURE")
      profileId = this.props.match.params.idsalePrice;

    return (
      <ContainerStyled>
        <div className="attach-files">
          <ImagesUpload
            files={media}
            nameUpload="files"
            listType="picture-card"
            user={user}
            showRemoveIcon={true}
            showPreviewIcon={true}
            isUploadButton={true}
            profileId={profileId}
            profileType={profileType}
            onChange={onChangeMedia}
            onDeleteFile={onDeleteFile}
            autoPlayVideo={true}
            controlsVideo={true}
            multiple={true}
          />
          <span className="ml-1">
            <FileOutlined />
            {` ${strings.upload_docs_title}:`}
          </span>
          <FilesUpload
            files={docs}
            nameUpload="files"
            listType="text"
            user={user}
            showRemoveIcon={true}
            showDownloadIcon={false}
            downloadIcon={`${strings.download_file} `}
            isUploadButton={true}
            profileId={profileId}
            profileType={profileType}
            onChange={onChangeDocs}
            onDeleteFile={onDeleteFile}
            autoPlayVideo={true}
            controlsVideo={true}
            multiple={true}
          />
        </div>
      </ContainerStyled>
    );
  }
}
export default compose(
  withRouter,
  connect(
    (state) => ({
      user: state["authUser"].user,
    }),
    {}
  ),
  withHandlers({
    handleCancelChange: () => (props) => () => {
      const { setValues, setTouched } = props;
      setValues({});
      setTouched({});
    },
  }),
  withFormik({
    displayName: "licenceForm",
    mapPropsToValues: () => ({}),
    handleSubmit: async (values) => { },
  }),
  lifecycle({
    componentDidMount() { },
  })
)(ActivityAttach);
