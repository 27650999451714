import React from "react";
import strings from "~/localization";
import _ from "lodash";
import { compose, lifecycle, withState, withHandlers } from "recompose";
import { connect } from "react-redux";
import moment from "moment";
import { PAYMENT } from "~/configs/const";
import { withRouter } from "react-router-dom";

import { message } from "antd";
import { getMassUnits, getType } from "~/state/ducks/appApis/actions";
import {
  getArray,
  getNumber,
  getString,
} from "~/views/utilities/helpers/utilObject";
import { showError } from "~/configs/ServerErrors";
import { Table, Typography } from "antd";
import TableEditable from "./TableEditFormPost";
import { numberFormatDecimal } from "~/views/utilities/helpers/currency";
import * as PATH from "~/configs/routesConfig";

const { Text } = Typography;

class BanksForm extends React.Component {
  constructor(props) {
    super(props);
    let view = false;
    let edit = false;
    const { match } = this.props;

    switch (match.path) {
      case PATH.VIEW_FARM_FORM_PATH:
        {
          view = true;
        }
        break;
      case PATH.EDIT_FARM_FORM_PATH:
        {
          view = true;
          edit = true;
        }
        break;
    }
    this.state = {
      dataSource: this.props.bankAccounts || [],
      dataReal: [],
      dataOptionsPayment: [],
      typeOptions: [],
      history: [],
      view,
      edit,
    };
  }
  mapPayment(pay) {
    switch (pay) {
      case PAYMENT.CASH:
        return { label: strings.cash, value: PAYMENT.CASH };
      case PAYMENT.TRANSFER:
        return { label: strings.transfer, value: PAYMENT.TRANSFER };
      case PAYMENT.DEBIT:
        return { label: strings.debit, value: PAYMENT.DEBIT };
      default:
        return { label: strings.cash, value: PAYMENT.CASH };
    }
  }
  componentDidMount() {
    let dataOptionsPayment = [
      this.mapPayment(PAYMENT.CASH),
      this.mapPayment(PAYMENT.TRANSFER),
      this.mapPayment(PAYMENT.DEBIT),
    ];
    this.setState({ dataOptionsPayment });
  }

  handleChangedData = (index, dataIndex, dataSource) => {
  

    this.props.onChange && this.props.onChange(dataSource);
    if(getArray(this.state,"dataSource",[]).length > getArray(dataSource,undefined,[]).length){
      this.setState({dataSource})
      return
    }
    if (dataIndex) var row = dataSource[index];
    let option = this.state.typeOptions;
    let history = this.state.history;
    const type = getNumber(row, "type", 0);
    if (type) {
      option = this.state.typeOptions.map((data) => {
        if (data.value === type) {
          return {
            ...data,
            index,
            disabled: !data.disabled,
          };
        } else {
          return {
            ...data,
          };
        }
      });
      let check = option.filter(
        (val) => getNumber(val, "index", undefined) === index
      );
      if (check.length > 1)
        option = option.map((val, indexOp) => {
          if (val.value !== type && index === val.index) {
            return {
              ...val,
              disabled: false,
            };
          } else {
            return {
              ...val,
            };
          }
        });
    }
    option = getArray(option, undefined, []).map((val) => {
      let right = true;
      dataSource.map((da) => {
        if (val.value === da.type) {
          right = false;
        }
      });
      if (right)
        return {
          ...val,
          disabled: false,
        };
      else return { ...val };
    });
    const dataR = this.state.dataReal.find(
      (val) => getNumber(val, "classifyId") === type
    );
    if (getString(dataR, "productCode", ""))
      row["harvestId"]=dataR.id
      row.qr_code = getString(dataR, "productCode", "");
    if (getString(dataR, "name", ""))
      row.productName = getString(dataR, "name", "");
    if (getNumber(dataR, "unitId", 0))
      row.unitName = getNumber(dataR, "unitId", 0);
    if (getNumber(row, "amount", 0)) {
      if (getNumber(dataR, "gross", -1))
        if (getNumber(row, "amount", 0) > getNumber(dataR, "gross", 0)) {
          row.amount = undefined;
          // this.props.info.errors={...this.props.info.errors,error1:strings.amount_must_large_t_gross}
          message.error(strings.amount_must_large_t_gross);
        }
    }
    if (getNumber(row, "lestBuy", undefined)) {
      if (getNumber(row, "lestBuy", 0) > getNumber(row, "amount", -1)) {
        // this.props.info.errors={...this.props.info.errors,error2:strings.lesBuy_must_lagre}

        message.error(strings.lesBuy_must_lagre);
        row.lestBuy = undefined;
      }
    }
    history.push({ ...option.find((val) => val.value === type), index });
    if (_.has(row, "delivery_date")) {
      row.week = moment(row.delivery_date).isoWeek();
      if (!row.delivery_date) row.week = 0;
    }
    if (getNumber(row, "measure", 0) && getNumber(row, "amount", 0))
      row.total = row.measure * row.amount || 0;
    if (getNumber(row, "unitPrice", 0) && getNumber(row, "total", 0))
      row.toPriceShell = row.unitPrice * row.total || 0;
    if (
      getNumber(row, "toPriceShell", 0) &&
      getNumber(row, "cost_servicePercent", 0)
    )
      row.const_serviceToMoney =
        row.toPriceShell * (row.cost_servicePercent / 100) || 0;
    this.setState(
      { dataSource: dataSource, typeOptions: option, history },
      () => {
      }
    );
  };
  UNSAFE_componentWillReceiveProps(prevProps) {
    if (prevProps.dataSrc != this.props.dataSrc) {
      if (this.state.edit) {
        let typeOptions = this.state.typeOptions;
        const tempt = typeOptions;

        getArray(prevProps, "dataSrc", []).map((data) => {
          tempt.map((type, index) => {
            if (data.type === type.value) {
              typeOptions[index] = {
                ...typeOptions[index],
                disabled: true,
              };
            }
          });
        });
        this.setState({ typeOptions, dataSource: prevProps.dataSrc || [] });
      } else {

        this.setState({ dataSource: prevProps.dataSrc || [] });
      }
    }
    if (prevProps.option !== this.props.option) {
      let typeOptions;
      typeOptions = getArray(prevProps.option, undefined, []).map((data) => {
        return {
          value: getNumber(data, "classifyId"),
          label: getString(data, "classifyName"),
        };
      });

      this.setState({ dataReal: prevProps.option, typeOptions });
    }
  }
  render() {
    const { editable } = this.props;

    let columns = [
      {
        title: strings.STT,
        dataIndex: "STT",
        width: "2%",
        align: "center",
        placeholder: strings.STT,
        type: "text",
      },
      {
        title: strings.products_offered_for_sale,
        editable: true,

        children: [
          {
            title: strings.qr_code,
            dataIndex: "qr_code",
            width: 120,
            editable: false,
            placeholder: strings.qr_code,

            type: "text",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
          {
            title: strings.product,
            dataIndex: "productName",
            width: 120,
            // editable: true,
            required: false,
            // options: [
            //   { value: 1, label: "viet" },
            //   { value: 2, label: "viet2" },
            //   { value: 3, label: "viet1" },
            // ],
            placeholder: strings.product,
            type: "text",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
          {
            title: strings.characteristic,
            dataIndex: "feature",
            width: 120,
            editable: editable,
            required: false,
            placeholder: strings.characteristic,
            type: "text",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
        ],
      },
      {
        title: strings.technical_specifications_of_products,
        editable: true,

        children: [
          {
            title: strings.formality,
            dataIndex: "formality",
            width: 120,
            editable: true,
            required: false,
            placeholder: strings.formality,
            type: "text",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
          {
            title: strings.income_type,
            dataIndex: "type",
            width: 120,
            editable: true,
            options: this.state.typeOptions,
            required: true,
            placeholder: strings.income_type,
            type: "select",
            rules: [
              {
                required: true,
                message: strings.required,
              },
            ],
          },
          {
            title: strings.size_product,
            dataIndex: "size",
            width: 120,
            editable: true,
            required: false,
            placeholder: strings.size_product,
            type: "text",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
          {
            title: strings.number_of_seeds,
            dataIndex: "seeds",
            width: "4%",
            editable: true,
            required: false,
            placeholder: strings.number_of_seeds,
            type: "number",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
          {
            title: strings.plate,
            dataIndex: "plate",
            width: "4%",
            editable: true,
            required: false,
            placeholder: strings.plate,
            type: "number",
            suffix: "%",
            rules: [
              {
                pattern: /^0*(?:[1-9][0-9]?|100)$/,
                message: strings.percent_check,
              },
            ],
          },
          {
            title: strings.color_sharp,
            dataIndex: "color",
            width: "4%",
            editable: true,
            required: false,
            placeholder: strings.color_sharp,
            type: "text",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
          {
            title: strings.humidity,
            dataIndex: "humidity",
            width: "4%",
            editable: true,
            suffix: "%",
            max: 100,
            required: false,
            placeholder: strings.humidity,
            type: "number",
            rules: [
              {
                required: false,
                pattern: /^0*(?:[1-9][0-9]?|100)$/,
                message: strings.percent_check,
              },
            ],
          },
        ],
      },
      {
        title: strings.number_of_offers,
        editable: true,

        children: [
          {
            title: strings.closed_package,
            dataIndex: "accountName",
            width: "4%",
            editable: true,
            required: false,
            placeholder: strings.closed_package,
            type: "text",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
          {
            title: strings.measure,
            dataIndex: "measure",
            width: "4%",
            editable: true,
            required: true,
            placeholder: strings.measure,
            type: "number5",
            rules: [
              {
                required: true,
                pattern: /^[1-9][0-9]*$/,
                message: strings.measure_must_than_zero,
              },
            ],
            maxSlice:5,

          },
          {
            title: strings.ĐVT,
            dataIndex: "unitName",
            width: "4%",
            required: false,
            options: this.props.unitMass,
            placeholder: strings.ĐVT,
            type: "select",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
          {
            title: strings.count,
            dataIndex: "amount",
            width: "4%",
            editable: true,
            required: true,
            placeholder: strings.count,
            type: "number5",
            rules: [
              {
                pattern: /^[1-9][0-9]*$/,
                required: true,
                message: strings.total_than_zero,
              },
            ],
          },
          {
            title: strings.limit_total_buy,
            dataIndex: "lestBuy",
            width: "4%",
            editable: true,
            required: false,
            placeholder: strings.limit_total_buy,
            type: "number",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
          {
            title: strings.total,
            dataIndex: "total",
            width: "4%",
            placeholder: strings.total,
            type: "number",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
        ],
      },
      {
        title: strings.offering_price_vnd,
        editable: true,

        children: [
          {
            title: strings.procedure_unit_price,
            dataIndex: "unitPrice",
            width: "4%",
            editable: true,
            required: true,
            suffix: "đ",
            placeholder: strings.procedure_unit_price,
            type: "decimalInput",
            rules: [
              {
                pattern: /^[1-9][0-9]*$/,
                required: true,
                message: strings.procedure_unit_price_more_than_zero,
              },
            ],
          },
          {
            title: strings.into_money,
            dataIndex: "toPriceShell",
            width: "4%",
            required: false,
            suffix: "đ",
            placeholder: strings.into_money,
            type: "decimalInput",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
        ],
      },
      {
        title: strings.service_fee_vnd,
        editable: true,

        children: [
          {
            title: "%",
            dataIndex: "cost_servicePercent",
            width: "4%",
            editable: true,
            required: false,
            placeholder: "%",
            suffix: "%",
            type: "number",
            rules: [
              {
                pattern: /^0*(?:[1-9][0-9]?|100)$/,
                message: strings.percent_check,
              },
            ],
          },
          {
            title: strings.into_money,
            dataIndex: "const_serviceToMoney",
            width: "4%",
            editable: false,
            required: false,
            suffix: "đ",
            placeholder: strings.into_money,
            type: "decimalInput",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
        ],
      },
      {
        title: strings.delivery_time,
        editable: true,

        children: [
          {
            title: strings.week_number,
            dataIndex: "week",
            width: "4%",
            required: false,
            placeholder: strings.week_number,
            type: "text",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
          {
            title: strings.delivery_date,
            dataIndex: "delivery_date",
            width: "4%",
            editable: true,
            required: false,
            placeholder: strings.delivery_date,
            type: "date",
            disabledDate: (current) =>
              current && current < moment().endOf("day"),
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
        ],
      },
      {
        title: strings.payments,
        editable: true,
        children: [
          {
            title: strings.deposit,
            dataIndex: "deposit",
            width: "4%",
            editable: true,
            suffix: "%",

            required: false,
            placeholder: strings.deposit,
            type: "number",
            rules: [
              {
                pattern: /^0*(?:[1-9][0-9]?|100)$/,
                message: strings.percent_check,
              },
            ],
          },
          {
            title: strings.Pay,
            dataIndex: "payment",
            width: "4%",
            editable: true,
            options: this.state.dataOptionsPayment,
            required: false,
            placeholder: strings.Pay,
            type: "select",
            rules: [
              {
                required: false,
                message: strings.required,
              },
            ],
          },
        ],
      },
    ];
    return (
      <div style={{ width: "100%" }}>
        <TableEditable
          headerAdd
          bordered
          dataReal={this.state.dataReal}
          editable={editable}
          changedData={this.handleChangedData}
          dataSource={this.state.dataSource}
          columns={columns}
          summary={(pageData) => {

            let totalToPriceShell = 0;
            let totalToPriceService = 0;
            if (getArray(pageData, undefined, []).length)
              pageData.map((val) => {
                totalToPriceShell =
                  totalToPriceShell + getNumber(val, "toPriceShell", 0);
                totalToPriceService =
                  totalToPriceService +
                  getNumber(val, "const_serviceToMoney", 0);
              });
            this.props.info["serviceCosts"] = totalToPriceService;
            this.props.info["amout"] = totalToPriceShell;

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong> {strings.total}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {this.state.view && (
                      <Text strong>
                        {`${numberFormatDecimal(totalToPriceShell, "đ")}`}
                      </Text>
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {!this.state.view && (
                      <Text strong>
                        {`${numberFormatDecimal(totalToPriceShell, "đ")}`}
                      </Text>
                    )}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell>
                    {this.state.view && (
                      <Text strong>
                        {`${numberFormatDecimal(totalToPriceService, "đ")} `}
                      </Text>
                    )}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell>
                    {!this.state.view && (
                      <Text strong>
                        {`${numberFormatDecimal(totalToPriceService, "đ")} `}
                      </Text>
                    )}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  {pageData.length > 0 ||
                    (!this.state.view && (
                      <Table.Summary.Cell></Table.Summary.Cell>
                    ))}
                </Table.Summary.Row>
              </>
            );
          }}

          // footer={this._handleRenderFooter}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(null, { getMassUnits, getType }),
  withState("banks", "setBanks", []),
  withState("unitMass", "setUnitMass", []),
  withState("typeOption", "setOptionType", []),
  withHandlers({
    fetchTypeCulti: (props) => () => {
      const { getType, setOptionType, setFieldValue } = props;
      getType("PERCENT_SIZE")
        .then(({ res }) => {
          res.unshift({ name: strings.personal });
          setOptionType(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name,
            }))
          );
        })
        .catch((err) => showError(err));
    },
    fecthUnitMass: (props) => () => {
      const { setUnitMass, getMassUnits } = props;
      getMassUnits()
        .then(({ res }) => {
          setUnitMass(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => showError(err));
    },
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps(nextProps) {},
    componentDidMount() {
      const { fecthUnitMass, fetchTypeCulti } = this.props;
      fecthUnitMass();
      fetchTypeCulti();
    },
  })
)(BanksForm);
