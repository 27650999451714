import React from "react";
import { connect } from "react-redux";

// import strings from "~/localization";
import TableAll from "./ContentAllStructure";
import {
  getFarmProfile,
  deleteFarmProfile,
} from "~/state/ducks/appApis/actions";

import TreeMenu from "~/views/presentation/ui/navigation/Sidebar";
import { withRouterInnerRef } from "~/views/container/commons";
import { ContentStructureStyle } from "../../styles";

class AllStucture extends React.Component {
  render() {
    return (
      <div>
        <ContentStructureStyle>
          <div className="right-content">
            <TableAll viewAsBlock={this.props.viewAsBlock} />
          </div>
        </ContentStructureStyle>
        <TreeMenu
          className={this.props.className}
          menus={this.props.treeMenu}
        />
      </div>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      getFarmProfile,
      deleteFarmProfile,
    },
    null,
    {
      forwardRef: true,
    }
  )(AllStucture)
);
