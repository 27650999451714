import React from "react";

import { showError } from "~/configs/ServerErrors";
import strings from "~/localization";
import { ContentWrapper } from "~/views/presentation/ui/container";
import * as PATH from "~/configs/routesConfig";
import UITable from "~/views/presentation/ui/tables/Table";
import { message } from "antd";
import {
  getString,
  getNumber,
  getArray,
} from "~/views/utilities/helpers/utilObject";
import { PROFILE_TYPES, PROCESS_RATING } from "~/configs/const";
import { VIEW_FARM_PRODUCT_COST_PATH } from "~/configs/routesConfig";
import enhance from "./script";
import { GridStyled } from "./style";
import { Empty } from "antd";
class AllStructure extends React.PureComponent {
  getProfileType = (type) => {
    switch (type) {
      case PROFILE_TYPES.PERSONAL_PROFILE:
        return strings.persional_profile;
      case PROFILE_TYPES.FARM_PROFILE:
        return strings.farm_profile;
      case PROFILE_TYPES.ENTERPRISE:
        return strings.enterprise_profile;
      case PROFILE_TYPES.COOPERATIVE:
        return strings.cooperative_profile;
      case PROFILE_TYPES.COOPERATIVE_VENTURE:
        return strings.cooperative_venture_profile;
      case PROFILE_TYPES.ORGANIZATION:
        return strings.organization_profile;
      default:
        return "";
    }
  };

  replaceId(path, idProfile) {
    const { match } = this.props;

    const { id } = match.params;
    const idSeason = match.params.idSeason;
    const idProcedure = match.params.idProcedure;
    const idsalePrice = match.params.idsalePrice;

    return path
      .replace(":id", id)
      .replace(":idSeason", idSeason)
      .replace(":idProcedure", idProcedure)
      .replace(":idPackagingCost", idProfile)
      .replace(":idSale", idProfile)
      .replace(":idProductCost", idProfile)
      .replace(":idsalePrice", idProfile);
  }
  handleViewProfile = (profile) => {
    const { history, match } = this.props;
    switch (match.path) {
      case PATH.ALL_FARM_PRODUCT_COST_PATH:
        history.push(
          this.replaceId(PATH.VIEW_FARM_PRODUCT_COST_PATH, profile.id)
        );
        break;
      case PATH.ALL_FARM_SALE_PRICE_PATH:
        history.push(
          this.replaceId(PATH.VIEW_FARM_SALE_PRICE_PATH, profile.id)
        );
        break;
      case PATH.ALL_FARM_PACKAGING_COST_PATH:
        history.push(
          this.replaceId(PATH.VIEW_FARM_PACKAGING_COST_PATH, profile.id)
        );
        break;

      default:
        break;
    }
  };

  handleDeleteProfile = (profile) => () => {
    const { deletePersonalProfile, fetchAllProcedure } = this.props;
    let profileId = getNumber(profile, "id");
    const getAPIDelete = () => {
      switch (getString(profile, "type")) {
        case PROFILE_TYPES.PERSONAL_PROFILE:
          {
            return deletePersonalProfile(profileId);
          }
          break;
        case PROFILE_TYPES.COOPERATIVE_PROFILE:
          {
            return deletePersonalProfile(profileId);
          }
          break;
        default:
          break;
      }
    };
    getAPIDelete()
      .then(() => {
        message.success(strings.delete_profile_success);
        fetchAllProcedure();
      })
      .catch((err) => showError(err));
  };
  getNameFist = () => {
    const { match } = this.props;

    switch (match.path) {
      case PATH.ALL_FARM_PRODUCT_COST_PATH:
        return strings.best_product_cost;
      case PATH.ALL_FARM_SALE_PRICE_PATH:
        return strings.best_famr_sale;
      case PATH.ALL_FARM_PACKAGING_COST_PATH:
        return strings.best_pagkaging_cost;

      default:
        return "";
    }
  };
  getNameSecond = () => {
    const { match } = this.props;

    switch (match.path) {
      case PATH.ALL_FARM_PRODUCT_COST_PATH:
        return strings.sum_product_cost;
      case PATH.ALL_FARM_SALE_PRICE_PATH:
        return strings.sum_farm_sale;
      case PATH.ALL_FARM_PACKAGING_COST_PATH:
        return strings.sum_pakaging_cost;

      default:
        return "";
    }
  };
  render() {
    /**
     * event cell click -> view profile detail
     */
    const renderCellItem = (data, records) => {
      return (
        <>
          <span>{data}</span>
          <p
            onClick={() => this.handleViewProfile(records)}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}></p>
        </>
      );
    };
    const columns = [
      {
        title: strings.name,
        dataIndex: "title",
        key: "title",
        width: "15%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.procedure_status,
        dataIndex: "status",
        key: "status",
        width: "15%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: strings.type_tree,
        dataIndex: "type",
        key: "type",
        width: "10%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: this.getNameFist(),
        dataIndex: "decription",
        key: "decription",
        width: "25%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
      {
        title: this.getNameSecond(),
        dataIndex: "step",
        key: "step",
        align: "center",

        width: "10%",
        render: (record, records) => {
          return renderCellItem(record, records);
        },
      },
    ];

    const { procedure, viewAsBlock = true } = this.props;

    return (
      <ContentWrapper>
        {viewAsBlock ? (
          <GridStyled className="row p-0">
            {getArray(procedure, undefined, []).map((item, index) => (
              <div className="item" key={index}>
                <div className="item-container">
                  <div
                    className="left"
                    onClick={() => this.handleViewProfile(item)}></div>
                  <div
                    className="right"
                    onClick={() => this.handleViewProfile(item)}>
                    <h4 style={{ fontWeight: "500" }}>
                      {getString(item, "title", "")}
                    </h4>
                    <div className="">
                      <span
                        className="color-text-status"
                        style={
                          getString(item, "status") === "ACTIVATED"
                            ? { color: "green" }
                            : null
                        }>
                        {strings[getString(item, "status", "DEACTIVATED")]}
                      </span>
                    </div>

                    <div style={{ display: "flex" }}>
                      <div className="title">{strings.type_tree} </div>
                      <div
                        className="des value"
                        style={
                          getString(item, "type", "None") === "None"
                            ? { color: "gray" }
                            : null
                        }>
                        {getString(item, "type", "None")}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="title">{this.getNameFist()}</div>
                      <div
                        className="des value"
                        style={
                          getString(item, "price", "None") === "None"
                            ? { color: "gray" }
                            : null
                        }>
                        {getString(item, "price", "None")}
                      </div>
                    </div>
                    <div style={{ display: "flex mb-0" }}>
                      <div className="title mb-0">{this.getNameSecond()}</div>
                      <div
                        className="des value mb-0"
                        style={
                          getString(item, "total", "None") === "None"
                            ? { color: "gray" }
                            : null
                        }>
                        {getString(item, "total", "None", 0)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {!getArray(procedure, undefined, []).length && <Empty />}
          </GridStyled>
        ) : (
          <UITable
            scroll={{ x: 1000 }}
            dataSource={getArray(procedure, undefined, [])}
            rowClassName="cursor-pointer"
            columns={columns}
          />
        )}
      </ContentWrapper>
    );
  }
}

export default enhance(AllStructure);
