import _ from 'lodash'
const parseCurrency = (value, sign = 'đ', fractionDigits = 0) => {
    
  if(_.isNumber(value)){
      let formatedValue = value.toLocaleString(undefined, {minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits})
      return [formatedValue, sign].filter(item => (item || '').trim().length > 0).join(' ')
  }
  return ''
}

export default parseCurrency;

const parseCurrency2 = (str, sign = "đ") => {
  if (str === "FreeShipping") return str;
  if (str !== null && str !== "undefined") {
    let tmp = parseFloat(str);
    return `${(tmp || 0).toLocaleString("vi", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })} ${sign}`;
  } else {
    return "";
  }
};
export { parseCurrency2 };
