import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { withRouter } from "react-router-dom";
import { getAuthUser } from "~/state/ducks/authUser/selectors";
import { authActions } from "~/state/ducks/authUser";
import { APP_DEFAULT_PATH } from "~/configs/routesConfig";
import { CheckWidth } from "~/views/utilities/CheckWidth";
import { Link } from "react-router-dom";
import { head } from "lodash";

import { getString } from "~/views/utilities/helpers/utilObject";

import strings from "~/localization";
import { Avatar, Button, Dropdown, Menu } from "antd";
import NavbarFarmBar from "./NavbarFarmBar";
import {
  DownOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import logo from "~/static/images/Logo.svg";
import logoLocation from "~/static/images/220-vietnam.svg";
import {
  MenuStyled,
  DropDownStyled,
  MenuDropDownStyle,
  NarvarStyle,
  UserActionStyle,
  ModalUserInfor,
} from "./styles";

export const MenuSize = {
  small: "small",
  medium: "medium",
  large: "large",
};

function Navbar(props) {
  const device = CheckWidth();
  const [collapsedUser, setCollapsedUser] = useState(false);
  const [showModalUserInfor, setShowModalUserInfor] = useState(false);

  const menu = (
    <Menu>
      <Menu.Item>
        {/* <Button
          type="link"
          onClick={() => {
            history.push(PERSONAL_PROFILE_PATH);
          }}
        >
          <i className="fa fa-user mr-2" />
          {strings.personal_profile}
        </Button> */}
      </Menu.Item>
      <Menu.Item>
        <Button type="link" onClick={props.handleLogout}>
          <i className="fa fa-sign-out mr-2" />
          {strings.sign_out}
        </Button>
      </Menu.Item>
    </Menu>
  );

  return device.innerWidth >= 1200 ? (
    <NavbarDesktop {...props} menu={menu} />
  ) : (
    <NavbarMobile
      {...props}
      menu={menu}
      collapsedUser={collapsedUser}
      setCollapsedUser={setCollapsedUser}
      showModalUserInfor={showModalUserInfor}
      setShowModalUserInfor={setShowModalUserInfor}
    />
  );
}

export default compose(
  withRouter,
  connect(
    (state) => ({
      user: getAuthUser(state),
    }),
    {
      logout: authActions.logout,
    }
  ),
  withHandlers({
    handleLogout:
      ({ logout }) =>
      () => {
        logout();
      },
    handleChangeSize:
      () =>
      ({ onResize, size }) =>
      () => {
        switch (size) {
          case MenuSize.large:
            {
              onResize && onResize(MenuSize.medium);
            }
            break;
          case MenuSize.medium:
            {
              onResize && onResize(MenuSize.small);
            }
            break;
          case MenuSize.small:
            {
              onResize && onResize(MenuSize.large);
            }
            break;
        }
      },
  })
)(Navbar);

const NavbarDesktop = (props) => {
  return (
    <header className="clearfix">
      <div className="branding">
        <a
          role="button"
          tabIndex={0}
          className="offcanvas-toggle visible-xs-inline">
          <i className="fa fa-bars" />
        </a>
      </div>
      {/* Branding end */}

      {/* Search */}
      <div className="search" id="main-search">
        <div className="list-app">
          <a onClick={() => props.history.push(APP_DEFAULT_PATH)}>
            <img src={logo} alt="logo" style={{ width: 42 }} />
          </a>
        </div>
        <div className="top-navigation">
          <h4
            onClick={() => props.history.push(APP_DEFAULT_PATH)}
            style={{ cursor: "pointer" }}
            className="title">
            {getString(props.topNav, "pageName", "")}
          </h4>
          {/* menu */}
          <MenuStyled>
            <NavbarFarmBar topNav={props.topNav} />
          </MenuStyled>
        </div>
      </div>
      {/* Search end */}
      {/* Right-side navigation */}
      <ul className="nav-right pull-right list-inline">
        <li className=" users">
          <a className="" data-toggle="dropdown">
            <i className="fa fa-bell" />
          </a>
          <div
            className="dropdown-menu pull-right with-arrow panel panel-default animated littleFadeInUp"
            role="menu">
            <div className="panel-footer"></div>
          </div>
        </li>
        <li className="messages">
          <a className="">
            <i className="fa fa-comments" />
          </a>
        </li>
        <li className="notifications">
          <a className="">
            <i className="fa fa-cog" />
          </a>
        </li>
        <li className=" nav-profile">
          <DropDownStyled
            className="d-flex justify-content-center align-items-center"
            data-toggle="dropdown">
            <Dropdown overlay={props.menu} placement="bottomRight">
              <a
                className="ant-dropdown-link text-white d-flex justify-content-center align-items-center"
                onClick={(e) => e.preventDefault()}>
                <Avatar
                  style={{ backgroundColor: "#87d068" }}
                  size={32}
                  icon={<UserOutlined />}
                />
                {getString(props.user, "name", strings.no_name)}
                <DownOutlined />
              </a>
            </Dropdown>
          </DropDownStyled>
        </li>
      </ul>
      {/* Right-side navigation end */}
    </header>
  );
};

const UserInfor = (props) => {
  return (
    <ModalUserInfor>
      <div className="card-title">
        {" "}
        <Avatar size={40}>{sliceName(props.user.name)}</Avatar>
        {props.user.name}
      </div>
      <div className="card-infor row">
        <div className="col-1">
          <i class="fa fa-id-card-o" aria-hidden="true"></i>
        </div>
        <div className="col-9">
          <span className="title-text">{strings.myinfor}</span>
          <span className="des-text">{strings.myinfor_des}</span>
        </div>
      </div>
      <div className="card-changepw">
        <div className="row">
          <div className="col-1">
            <i class="fa fa-shield" aria-hidden="true"></i>
          </div>
          <div className="col-9">
            <span className="title-text">{strings.changepw}</span>
            <span className="des-text">{strings.changepw_des}</span>
          </div>
        </div>
      </div>
      <div className="card-button">
        <Button onClick={props.handleLogout}>{strings.sign_out}</Button>
      </div>
    </ModalUserInfor>
  );
};

const sliceName = (name) => {
  let i = name.length - 1;
  while (i > 0) {
    if (Number(name[i - 1]) === 0) {
      return name[i];
    }
    i = i - 1;
  }
  return name[0];
};

export const isCheckPath = (item, match) => {
  let pathSidebar = getString(item, "path", "")
    .split(/[//]/)
    .filter((e) => {
      return e;
    });
  let pathHead = getString(match, "path")
    .split(/[//]/)
    .filter((e) => {
      return e;
    });
  if (head(pathHead) == head(pathSidebar)) {
    return true;
  } else {
    return false;
  }
};

const NavbarMobile = (props) => {
  const menuHeader = props.topNav.slot.map((m) => {
    return (
      <a className={isCheckPath(m, props.match) ? "active" : ""} href={m.path}>
        {m.title}
      </a>
    );
  });

  return (
    <div style={{ height: 0 }}>
      <UserActionStyle collapsed={props.collapsedUser}>
        <Avatar size={30} className="alert-icon">
          <i className="fa fa-bell" />
        </Avatar>
        <Avatar size={30} className="alert-icon">
          <i className="fa fa-comments" />
        </Avatar>
        <Avatar size={30} className="alert-icon">
          <i className="fa fa-cog" />
        </Avatar>
        <Avatar
          size={30}
          style={{ backgroundImage: `url("${logoLocation}")` }}></Avatar>
        <Avatar
          size={30}
          onClick={() => {
            props.setShowModalUserInfor(!props.showModalUserInfor);
          }}>
          {sliceName(props.user.name)}
        </Avatar>
      </UserActionStyle>
      {props.collapsedUser && props.showModalUserInfor && (
        <UserInfor {...props} />
      )}

      <MenuDropDownStyle collapsed={props.collapsed}>
        <div class="vertical-menu">{menuHeader}</div>
      </MenuDropDownStyle>
      <NarvarStyle>
        <Link to="/">
          <img alt="logo" style={{ height: 40 }} src={logo} />
        </Link>
        <div className="d-flex">
          <Avatar
            onClick={() => props.onCollapse(!props.collapsed)}
            style={{ backgroundColor: "#5d9c14" }}
            size={40}
            icon={
              props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
            }
          />
          <Avatar
            style={{ backgroundColor: "#5d9c14" }}
            size={40}
            icon={<UserOutlined />}
            onClick={() => {
              props.setCollapsedUser(!props.collapsedUser);
              if (props.collapsedUser) props.setShowModalUserInfor(false);
            }}
          />
        </div>
      </NarvarStyle>
    </div>
  );
};
