import React, {useState} from "react";
import {Avatar as AntAvatar} from "antd";
import emptyImage from "~/static/images/empty_image.jpg";

const Avatar = ({src, size, className}) => {
  const [componentURL, setComponentURL] = useState(src);
  return (
    <AntAvatar
      className={className}
      src={componentURL}
      size={size}
      onError={() => {
        setComponentURL(emptyImage);
      }}
    />
  );
};
export default Avatar;
