import React, { PureComponent } from "react";
import { UIButton } from "~/views/presentation/ui/buttons";
import { UICollapsePanel } from "~/views/presentation/ui/collapse";
import enhance from "./withEnhance";
import { List, Form, Row, Col } from "antd";
import { parseCurrency } from "~/views/utilities/helpers";
import { getArray } from "~views/utilities/helpers/utilObject";
import { InputField } from "~views/presentation/ui/fields";
// import { PURCHASES_LIST_SHOP, PURCHASES_SHIPPING_ADDRESS } from "~/configs/routesConfig";
import strings from '~/localization/vi';
import _ from 'lodash';

class ShopCart extends PureComponent {


    handleChangeCoupon = (e) => {
        const { setErros, setCoupon } = this.props;
        let value = typeof e === "number" || typeof e === "string" ? e : e.target.value;
        setErros({});
        setCoupon(value);
    };
    render() {
        const {
            cartItems,
            totalAmountCart,
            coupon,
            handleBlur,
            applyCoupon,
            history,
            totalItemCart
        } = this.props;
         

        // const { warnings, hasIssue } = getWarningCart(cartItems || []);
        let totalamount = totalAmountCart || 0;
        let discount = 0;

        // let discount = parseFloat(totalamount) * (parseFloat(couponDiscount.couponDiscount)/100) || 0;
        // let removeCartEmpty = getArray(cartItems,undefined,[]).filter(item => getArray(item,"products",[]).length != 0)
        return (
            <div className="bg-gradient shopping-container">
                <div className="row d-flex m-auto justify-content-between">
                    {/* <CartNotify /> */}

                    <div className="col-xs-12 col-md-9 mb-xs-3 px-0 pr-md-3 cart-list-item">
                        <div className="bg-white">
                            <label className="lbl-shopping-cart">
                                Giỏ hàng
                                    <span>
                                    {`(có ${totalItemCart} sản phẩm)`}
                                </span>
                            </label>
                            {totalItemCart >= 0 && (
                                <div>
                                    <List
                                        style={{ marginTop: "0px" }}
                                        className="px-0"
                                        dataSource={getArray(cartItems, undefined, [])}
                                        renderItem={(items, key) => {
                                            return (
                                                <div className="list-item-cart" key={key}>
                                                    <UICollapsePanel data={items} defaultActive={[1]} />
                                                </div>
                                            );
                                        }}
                                    />
                                    <UIButton
                                        className="btn-buy m-4"
                                        ghost
                                        type="primary"
                                        onClick={() => {
                                            // history.push(PURCHASES_LIST_SHOP)
                                        }}
                                    >
                                        {strings.button_buy}
                                    </UIButton>
                                </div>
                            ) || (
                                    <div className="cart-empty p-4">
                                        <span>{strings.shopping_cart_empty}</span>
                                        <UIButton
                                            type="primary"
                                            className="btn-buy ml-3"
                                            onClick={() => {
                                                // history.push(PURCHASES_LIST_SHOP)
                                            }}
                                        >
                                            {strings.button_buy}
                                        </UIButton>
                                    </div>
                                )}
                        </div>
                    </div>



                    <div className="col-xs-12 col-md-3 mt-4 mt-md-0">
                        {totalItemCart >= 0 && (
                            <div className="checkout-box p-4 bg-white mb-3">
                                <Row gutter={4} className="border-bottom">
                                    <Col span={12} className="checkout-label">
                                        Tạm tính
                                    </Col>
                                    <Col span={12} className="checkout-amount">
                                        {parseCurrency(totalamount)}
                                    </Col>
                                </Row>
                                {/* { 0 < 1 && (
                                    <Row gutter={4} className="border-bottom">
                                        <Col span={12} className="checkout-label">
                                            {(strings.SHOPPING_CART_LABLE_DISCOUNT)}
                                        </Col>
                                        <Col span={12} className="checkout-amount">
                                            {parseCurrency(discount)}
                                        </Col>
                                    </Row>
                                )} */}
                                <Row gutter={4}>
                                    <Col span={12} className="checkout-label">
                                        Thuế VAT(10%)
                                    </Col>
                                    <Col span={12} className="checkout-amount">
                                        {parseCurrency(0)}
                                    </Col>
                                </Row>

                                <Row gutter={4}>
                                    <Col span={12} className="checkout-label">
                                        Thành tiền
                                    </Col>
                                    <Col span={12} className="amount checkout-amount">
                                        {parseCurrency(parseFloat(totalamount) - parseFloat(discount))}
                                    </Col>
                                </Row>
                                <div className="text-center btn-payment">
                                    {/* <UIButton
                                        //  history.push(PURCHASES_SHIPPING_ADDRESS)
                                        disabled={totalAmountCart > 0 ? false : true}>
                                        Tiến hành thanh toán
                                    </UIButton> */}
                                </div>

                                {/* {hasIssue && (
                                    <div className="text-danger pt-5">
                                        Sorry, this item cant be shipped to your selected address. Learn more. You may either change the
                                        shipping address or delete the item from your order. (TO BE REWORKED AGAINST THIS CASE)
                                    </div>
                                )} */}
                            </div>
                        )}
                        {totalItemCart >= 0 && (
                            <div className="checkout-box p-4 block-cart-coupon bg-white">
                                <div className="code-discount-title">
                                    <span>Mã giảm giá</span>
                                </div>
                                <div className="code-discount-input">
                                    <Form.Item>
                                        <InputField value={coupon} placeholder="Nhập mã giảm giá" onBlur={handleBlur} onChange={this.handleChangeCoupon} maxLength={20} />
                                    </Form.Item>
                                    <UIButton style={{height:40}} type="secondary" disabled={!coupon && true} onClick={() => applyCoupon()}>
                                        Nhập
                                    </UIButton>
                                </div>
                                {/* {
                                    cartInfoItems.couponCode && <div className="tag-coupon mt-3">
                                        <Tag
                                            color="geekblue"
                                            closable
                                            afterClose={() => {
                                                clearCouponClientCart()
                                            }}
                                        >
                                            0
                                        </Tag>
                                    </div>
                                } */}

                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default enhance(ShopCart);
