import styled from "styled-components";
import { Color } from "~/views/utilities/layout";

export const WrapDashboardContent = styled.div`
  width: 100%;
  .farming-calendar {
    background: #fff;
    padding: 0;
  }
  .title {
    background-color: ${Color.white};
    margin: 0;
    padding: 8px;
    border-bottom: 1px solid ${Color.divider};
    color: ${Color.black} !important;
    font-size: 16px;
  }
  .list-item.divider-top {
    border-top: 0.1rem solid ${Color.divider};
  }
  .list-item {
    overflow: auto;
    max-height: 480px;
    padding: 0 0 8px 0;
    margin: 0 0 16px 0;
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border: 0px none #3c3434;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #3c3434;
    }
    ::-webkit-scrollbar-track:hover {
      background: #595959;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #3c3434;
    }
    ::-webkit-scrollbar-track:active {
      background: #595959;
    }
    ::-webkit-scrollbar-track {
      background: #d9d9d9;
      border: 0px none #ffffff;
      border-radius: 50px;
    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
  .item-title {
    padding: 8px 8px;
    font-weight: 500;
    position: sticky;
    top: 0;
    z-index: 99;
    background: white;
    color: ${Color.secondaryText};
    border-left: 1px solid ${Color.divider};
    background-color: ${Color.gray2};
  }
  .no-activity {
    padding: 0 0 0 16px;
    color: ${Color.secondaryText} !important;
  }
  .title-page {
    color: #9b9b9b;
    text-decoration: none;
    font-variant: normal;
    text-transform: none;
  }

  .right-content h3 {
    padding-left: 15px;
  }
  .right-content {
    @media (min-width: 1300px) {
      width: 80%;
    }
    @media (min-width: 960px) and (max-width: 1299px) {
      width: 70%;
    }
    @media (min-width: 600px) and (max-width: 959px) {
      width: 60%;
    }
    @media (max-width: 599px) {
      width: 100%;
    }
  }
  .table-page {
    -webkit-box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.1);
    margin: 16px 16px 16px 0;
  }

  .ant-form-item {
    margin-bottom: 0px !important;
  }

  color-gray {
    color: #757575;
  }
  .color-b {
    color: #1e1e1e !important;
    box-sizing: border-box;
    margin: 0px;
    font-weight: 400;
    font-size: 20px !important;
    line-height: 28px;
  }
  .fist-name {
    box-sizing: border-box;
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #757575;
  }
  .per-ecom {
    .row {
      border-left: 1px solid #ddd;
      border-top: 1px solid #ddd;
      margin: unset !important;
    }
    .square {
      font-size: 25px;
      color: #1e1e1e;
      padding: 5px;
    }
    width: 100%;
    .item-ecom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 24px;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      height: 150px;
      background: white;
    }
    .item-ecom:hover {
      background-color: #f0f0f0;
      // .fist-name {
      //   color:#5D9C14 !important;
      // }
    }
    .last {
      background-color: #f8f8f8;
    }
    .ecom-fist {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 50%;
    }
  }
  .custom-chart {
    border-left: 1px solid #ccc;
    padding-left: 16px;
    margin-left: 16px;
  }
  .type-chart {
    font-size: 26px;
    cursor: pointer;
    padding: 5px;
  }
  .type-chart:hover {
    background: white;
  }
  .active-chart {
    background: white;
    color: #5d9c14 !important;
    border: 1px solid;
  }
  .wrap-background {
    // padding: 24px;
  }
  .ant-btn-icon-only {
    padding: 6px 7px;
  }
`;

export const ContentStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ContentStructureStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  .right-content {
    @media (min-width: 1300px) {
      width: 80%;
    }
    @media (min-width: 960px) and (max-width: 1299px) {
      width: 70%;
    }
    @media (min-width: 600px) and (max-width: 959px) {
      width: 60%;
    }
    @media (max-width: 599px) {
      width: 100%;
    }
`;
