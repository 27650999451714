import React from "react";
import {Form, DatePicker} from "antd";
import {compose, withState, withHandlers, pure, lifecycle} from "recompose";
import {withRouter} from "react-router-dom";

import strings from "~/localization";
import styled from "styled-components";
import {UIButton} from "~/views/presentation/ui/buttons";
import moment from "moment";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import UtilDate from "~/views/utilities/helpers/UtilDate";

const WrapStyleForm = styled.div`
  // .ant-picker {
  //   height: 30px !important;
  // }
  .ant-picker,
  .ant-picker-middle,
  .ant-picker-focused {
    height: 38px !important;
  }
`;

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};
const config = {
  rules: [{type: "object", required: true, message: strings.required}],
};

const Reschedule = (props) => {
  const onFinish = (fieldsValue) => {
    props.handleFinish && props.handleFinish({...fieldsValue});
  };

  return (
    <WrapStyleForm>
      <Form name='time_related_controls' {...formItemLayout} onFinish={onFinish}>
        <Form.Item name='startDate' {...config}>
          <DatePicker
            size='middle'
            showTime
            format={UtilDate.formatDatesTimeLocal}
            defaultValue={moment(props.defaultValue).set("hours", props.startHour).set("minutes", 0)}
            disabledDate={(current) => {
              // Can not select days before today and today
              return current && current < moment().endOf("day").subtract(1, "days");
            }}
          />
        </Form.Item>
        <div className='row mt-1 mb-1 justify-content-end align-items-end'>
          <UIButton icon={<CloseOutlined />} onClick={() => props.setVisible(false)}>
            {strings.cancel}
          </UIButton>
          <UIButton type='primary' htmlType='submit' icon={<SaveOutlined />} className='mr-3 ml-3'>
            {strings.save}
          </UIButton>
        </div>
      </Form>
    </WrapStyleForm>
  );
};

const initState = {
  data: [],
  value: undefined,
  fetching: false,
};

export default compose(
  withRouter,
  pure,
  withState("state", "setState", (props) => (!!props.defaultValue ? props.defaultValue : initState)),
  withHandlers({
    handleChange: () => () => {},
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps() {},
    componentDidUpdate() {},
    componentDidMount() {},
  })
)(Reschedule);
