import React from "react";
import _ from "lodash";
import strings from "~/localization";
import AppContentWrapper from "~/views/presentation/ui/container/AppContentWrapper";
import MenuSideBar from "~/views/presentation/ui/navigation/MenuSideBar";
import AllProfiles from "~/views/container/Profiles/AllProfiles";
import PersonalProfile from "~/views/container/Profiles/PersonalProfile";
import FarmProfile from "~/views/container/Profiles/FarmProfile";
import EnterpriseProfile from "~/views/container/Profiles/EnterpriseProfile";
import CooperativeProfile from "~/views/container/Profiles/CooperativeProfile";
import CooperativeVentureProfile from "~/views/container/Profiles/CooperativeVentureProfile";
import OrganizationProfile from "~/views/container/Profiles/OrganizationProfile";
import ProfileNav from "../Navigation/ProfileNav";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { PROFILE_TYPES } from "~/configs/const";
import {
  ALL_PROFILES_PATH,
  LIST_PROFILES_PATH,
  VIEW_PERSONAL_PROFILE_PATH,
  EDIT_PERSONAL_PROFILE_PATH,
  EDIT_FARM_PROFILE_PATH,
  EDIT_ENTERPRISE_PROFILE_PATH,
  VIEW_FARM_PROFILE_PATH,
  CREATE_PERSONAL_PROFILE_PATH,
  CREATE_FARM_PROFILE_PATH,
  CREATE_ENTERPRISE_PROFILE_PATH,
  VIEW_ENTERPRISE_PROFILE_PATH,
  EDIT_COOPERATIVE_PROFILE_PATH,
  VIEW_COOPERATIVE_PROFILE_PATH,
  CREATE_COOPERATIVE_PROFILE_PATH,
  EDIT_COOPERATIVE_VENTURE_PROFILE_PATH,
  VIEW_COOPERATIVE_VENTURE_PROFILE_PATH,
  CREATE_COOPERATIVE_VENTURE_PROFILE_PATH,
  EDIT_ORGANIZATION_PROFILE_PATH,
  VIEW_ORGANIZATION_PROFILE_PATH,
  CREATE_ORGANIZATION_PROFILE_PATH,
} from "~/configs/routesConfig";
import { ContainerNavStyled } from "../Navigation/styles";

const menus = [
  { path: ALL_PROFILES_PATH, name: strings.all_profiles },
  {
    path: ALL_PROFILES_PATH + "/" + PROFILE_TYPES.PERSONAL_PROFILE,
    name: strings.persional_profile,
  },
  {
    path: ALL_PROFILES_PATH + "/" + PROFILE_TYPES.FARM_PROFILE,
    name: strings.farm,
  },
  {
    path: ALL_PROFILES_PATH + "/" + PROFILE_TYPES.ENTERPRISE,
    name: strings.business,
  },
  {
    path: ALL_PROFILES_PATH + "/" + PROFILE_TYPES.COOPERATIVE,
    name: strings.cooperative,
  },
  {
    path: ALL_PROFILES_PATH + "/" + PROFILE_TYPES.COOPERATIVE_VENTURE,
    name: strings.union_of_cooperative,
  },
  {
    path: ALL_PROFILES_PATH + "/" + PROFILE_TYPES.ORGANIZATION,
    name: strings.organization,
  },
];

class ProfileManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewAsBlocks: true,
      showLeftMenu: true,
      hidden: false,
      device: window.innerWidth,
    };
    this.ref = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  }
  changeViewStyle = (viewAsBlocks) => {
    this.setState({ viewAsBlocks: viewAsBlocks });
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  handleHiddenMenu = (e) => {
    this.setState({ showLeftMenu: e });
  };

  handleScroll(e) {
    if (window.scrollY > 22) {
      this.setState({ hidden: true });
    } else {
      this.setState({ hidden: false });
    }
  }
  UNSAFE_componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    if (
      (this.props.match.path !== ALL_PROFILES_PATH &&
        this.props.match.path !== LIST_PROFILES_PATH) ||
      window.innerWidth < 1000
    ) {
      this.setState({ showLeftMenu: false });
    }
  }

  renderContent = () => {
    switch (this.props.match.path) {
      case ALL_PROFILES_PATH:
        return <AllProfiles viewAsBlock={this.state.viewAsBlocks} />;
      case LIST_PROFILES_PATH:
        return <AllProfiles viewAsBlock={this.state.viewAsBlocks} />;
      case VIEW_PERSONAL_PROFILE_PATH:
        return <PersonalProfile ref={(r) => (this.editView = r)} />;
      case EDIT_PERSONAL_PROFILE_PATH:
        return <PersonalProfile ref={(r) => (this.editView = r)} />;
      case CREATE_PERSONAL_PROFILE_PATH:
        return <PersonalProfile ref={(r) => (this.createView = r)} />;
      case VIEW_FARM_PROFILE_PATH:
        return <FarmProfile ref={(r) => (this.editView = r)} />;
      case EDIT_FARM_PROFILE_PATH:
        return <FarmProfile ref={(r) => (this.editView = r)} />;
      case EDIT_ENTERPRISE_PROFILE_PATH:
        return <EnterpriseProfile ref={(r) => (this.editView = r)} />;
      case CREATE_FARM_PROFILE_PATH:
        return <FarmProfile ref={(r) => (this.createView = r)} />;
      case CREATE_ENTERPRISE_PROFILE_PATH:
        return <EnterpriseProfile ref={(r) => (this.createView = r)} />;
      case VIEW_ENTERPRISE_PROFILE_PATH:
        return <EnterpriseProfile ref={(r) => (this.editView = r)} />;
      // cooperative
      case VIEW_COOPERATIVE_PROFILE_PATH:
        return <CooperativeProfile ref={(r) => (this.editView = r)} />;
      case EDIT_COOPERATIVE_PROFILE_PATH:
        return <CooperativeProfile ref={(r) => (this.editView = r)} />;
      case CREATE_COOPERATIVE_PROFILE_PATH:
        return <CooperativeProfile ref={(r) => (this.createView = r)} />;
      // cooperative venture
      case VIEW_COOPERATIVE_VENTURE_PROFILE_PATH:
        return <CooperativeVentureProfile ref={(r) => (this.editView = r)} />;
      case EDIT_COOPERATIVE_VENTURE_PROFILE_PATH:
        return <CooperativeVentureProfile ref={(r) => (this.editView = r)} />;
      case CREATE_COOPERATIVE_VENTURE_PROFILE_PATH:
        return <CooperativeVentureProfile ref={(r) => (this.createView = r)} />;
      // organization
      case VIEW_ORGANIZATION_PROFILE_PATH:
        return <OrganizationProfile ref={(r) => (this.editView = r)} />;
      case EDIT_ORGANIZATION_PROFILE_PATH:
        return <OrganizationProfile ref={(r) => (this.editView = r)} />;
      case CREATE_ORGANIZATION_PROFILE_PATH:
        return <OrganizationProfile ref={(r) => (this.createView = r)} />;
      default:
        return null;
    }
  };

  getBreadcrumb = () => {
    const { match } = this.props;
    switch (match.path) {
      case ALL_PROFILES_PATH:
        return [{ title: strings.profile }];
      case LIST_PROFILES_PATH:
        return [{ title: strings.profile }];
      case VIEW_PERSONAL_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.personal },
        ];
      case VIEW_FARM_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.farm },
        ];
      case VIEW_ENTERPRISE_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.business },
        ];
      case EDIT_PERSONAL_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.personal },
        ];
      case EDIT_FARM_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.farm },
        ];
      case EDIT_ENTERPRISE_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.business },
        ];
      case CREATE_PERSONAL_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.personal },
          { title: strings.create },
        ];
      case CREATE_FARM_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.farm },
          { title: strings.create },
        ];
      case CREATE_ENTERPRISE_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.business },
          { title: strings.create },
        ];
      // cooperative
      case VIEW_COOPERATIVE_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.cooperative },
        ];
      case EDIT_COOPERATIVE_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.cooperative },
        ];
      case CREATE_COOPERATIVE_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.cooperative },
          { title: strings.create },
        ];
      // cooperative
      case VIEW_COOPERATIVE_VENTURE_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.union_of_cooperative },
        ];
      case EDIT_COOPERATIVE_VENTURE_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.union_of_cooperative },
        ];
      case CREATE_COOPERATIVE_VENTURE_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.union_of_cooperative },
          { title: strings.create },
        ];
      // organization
      case VIEW_ORGANIZATION_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.organization },
        ];
      case EDIT_ORGANIZATION_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.organization },
        ];
      case CREATE_ORGANIZATION_PROFILE_PATH:
        return [
          { title: strings.profile, href: ALL_PROFILES_PATH },
          { title: strings.organization },
          { title: strings.create },
        ];
    }
    return [];
  };

  onSave = () => {
    this.editView.onSave();
  };

  onCreate = () => {
    console.error("ref", this.createView);
    this.createView.onCreate();
  };

  onDelete = () => {
    this.editView.onDelete();
  };

  render() {
    return (
      <AppContentWrapper
        showMainSideBar={false}
        topNav={{
          pageName: strings.profile,
          links: [
            { link: "#", name: strings.profile },
            { link: "#", name: strings.configuration, disbled: true },
          ],
        }}>
        <ContainerNavStyled>
          <ProfileNav
            onSave={this.onSave}
            onDelete={this.onDelete}
            onCreate={this.onCreate}
            className={`profile-nav ${this.state.hidden && "shadow"}`}
            changeViewStyle={this.changeViewStyle}
            breadcrumb={this.getBreadcrumb()}
          />
          <MenuSideBar
            menus={menus}
            handleHiddenMenu={this.handleHiddenMenu}
            showLeftMenu={this.state.showLeftMenu}
          />
          <div
            className={`content ${
              (!this.state.showLeftMenu || this.state.device < 1024) &&
              "none-menu"
            }`}
            onKeyDown={(e) => {
              if (e.keyCode === 13) e.preventDefault();
            }}>
            {this.renderContent()}
          </div>
        </ContainerNavStyled>
      </AppContentWrapper>
    );
  }
}

export default compose(withRouter)(ProfileManager);
