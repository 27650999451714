import React from 'react';
import {withRouter} from 'react-router-dom';
import {message} from 'antd';
import _ from 'lodash';
// redux
import {connect} from 'react-redux';
import {getCooperativeVentureProfile, deleteCooperativeVentureProfile, getBranches} from '~/state/ducks/appApis/actions';
import {updateCooperativeVentureProfile, createCooperativeVentureProfile} from '~/state/ducks/authUser/actions';
// config || common
import {showError} from '~/configs/ServerErrors';
import {
  VIEW_COOPERATIVE_VENTURE_PROFILE_PATH,
  CREATE_COOPERATIVE_VENTURE_PROFILE_PATH,
  LIST_COOPERATIVE_VENTURE_PROFILES_PATH,
} from '~/configs/routesConfig';
import strings from '~/localization';
import {getArray, getString, getNumber, getObject, getBool} from '~/views/utilities/helpers/utilObject';
// component
import CooperativeVentureProfileForms from '~/views/container/Profiles/CooperativeVentureProfileForms';
import ActivityForm from '../../ActivityForm';
import {StepsFormContainerStyle} from '~/views/container/styles';
import {withRouterInnerRef} from '~/views/container/commons';

const emptyObject = () => ({
  name: undefined,
  avatar: undefined,
  images: undefined,
  surrogate: undefined,
  phone: undefined,

  email: undefined,
  code: undefined,
  status: undefined,
  member: undefined, // Tổng số thành viên
  kyc: undefined,

  revenueYear: undefined, //Doanh thu năm
  costYear: undefined, //Chi phí năm
  vat: undefined,
  profitYear: undefined,
  incomeTax: undefined, /// Thuế thu nhập cá nhân

  grossArea: undefined,
  grossYield: undefined,
  farmingSeason: undefined,

  formOfUseIds: [],
  marketIds: [],
  bankAccounts: [],
  businessLicenses: [],
  certifications: [],

  //custom
  address: {},
  provinceId: undefined,
  wardsId: undefined,
  districtId: undefined,
  provinceIdDetailInfo: undefined,
  wardsIdDetailInfo: undefined,
  districtIdDetailInfo: undefined,
  errors: {},
});
class CooperativeVentureProfile extends React.Component {
  constructor(props) {
    super(props);
    let editable = true;
    let isCreateProfile = false;
    const {match} = this.props;
    switch (match.path) {
      case VIEW_COOPERATIVE_VENTURE_PROFILE_PATH:
        editable = false;
        break;
      case CREATE_COOPERATIVE_VENTURE_PROFILE_PATH:
        isCreateProfile = true;
    }
    this.state = {
      editable,
      isCreateProfile,
      cooperativeVenture: {},
    };
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete() {
    const {deleteCooperativeVentureProfile, match, history} = this.props;
    let id = match.params.id;
    deleteCooperativeVentureProfile(id)
      .then(() => {
        message.success(strings.delete_profile_success);
        history.push(LIST_COOPERATIVE_VENTURE_PROFILES_PATH);
      })
      .catch((err) => {
        return showError(err);
      });
  }

  componentDidMount() {
    this.fetchProfile();
  }

  getMapObjectToResponseCooperative = () => {
    let {cooperativeVenture} = this.state;
    if (Object.keys(getObject(cooperativeVenture, 'errors', {})).length) return cooperativeVenture;

    let cooperativeVentureProfile = {
      //step 1
      address: {
        address1: getString(cooperativeVenture, 'address1', ''),
        provinceId: getNumber(cooperativeVenture, 'provinceId', ''),
        districtId: getNumber(cooperativeVenture, 'districtId', ''),
        wardsId: getNumber(cooperativeVenture, 'wardsId', ''),
      },
      name: getString(cooperativeVenture, 'name', undefined),
      surrogate: getString(cooperativeVenture, 'surrogate', undefined),

      avatar: getString(cooperativeVenture, 'avatar', undefined),
      images: getString(cooperativeVenture, 'images', undefined),
      phone: cooperativeVenture.phone || undefined,

      email: getString(cooperativeVenture, 'email'),
      code: getNumber(cooperativeVenture, 'code', undefined),
      member: getNumber(cooperativeVenture, 'member', 0),
      kyc: getBool(cooperativeVenture, 'kyc', false),

      revenueYear: {
        value: getNumber(cooperativeVenture, 'revenueYear', 0),
        unit: strings.unit_price,
      },
      costYear: {
        value: getNumber(cooperativeVenture, 'costYear', 0),
        unit: strings.unit_price,
      },
      vat: {
        value: getNumber(cooperativeVenture, 'vat', 0),
        unit: strings.unit_price,
      },
      profitYear: {
        value: getNumber(cooperativeVenture, 'profitYear', 0),
        unit: strings.unit_price,
      },
      incomeTax: {
        value: getNumber(cooperativeVenture, 'incomeTax', 0),
        unit: strings.unit_price,
      },

      grossArea: {
        unitId: cooperativeVenture.unitId,
        value: cooperativeVenture.grossArea,
      },
      grossYield: {
        unitName: strings.unit_gross_yield,
        value: cooperativeVenture.grossYield,
      },
      farmingSeason: {
        unitName: strings.unit_farming_season,
        value: cooperativeVenture.farmingSeason,
      },
      formOfUseIds: getArray(cooperativeVenture, 'formOfUseIds', []).map((item) => {
        return item;
      }),
      marketIds: getArray(cooperativeVenture, 'marketIds', []).map((item) => {
        return item;
      }),
      bankAccounts: getArray(cooperativeVenture, 'bankAccounts', []).map((item) => {
        return {
          accountName: item.accountName,
          accountNumber: item.accountNumber,
          bankId: item.bankId,
          branchId: item.branchId,
        };
      }),

      //step 2
      businessLicenses: getArray(cooperativeVenture, 'businessLicenses', []).map((item) => {
        return {
          images: getString(item, 'images', undefined),
          activateDate: item.activateDate,
          address: {
            address1: item.address1Licenses,
            provinceId: item.provinceIdLicenses,
            districtId: item.districtIdLicenses,
            wardsId: item.wardsIdLicenses,
          },
          businessTypeIds: getArray(item, 'businessTypeIds', []).map((item) => {
            return item;
          }),
          productionTypeIds: getArray(item, 'productionTypeIds', []).map((item) => item),
          groupManagementIds: getArray(item, 'groupManagementIds', []).map((item) => item),
          issuedDate: item.issuedDate,
          issuedPlace: item.issuedPlace,
          licenseCode: item.licenseCode,
          type: item.type,
          taxCode: item.taxCode,
          kyc: getBool(item, 'kyc', false),
        };
      }),
      // step 3
      certifications: getArray(cooperativeVenture, 'certifications', []).map((item) => {
        return {
          issuedDate: item.issuedDate,
          expirationDate: item.expirationDate,
          images: getString(item, 'images', ''),
          reassessmentDate: item.reassessmentDate,
          evaluationDate: item.evaluationDate,
          issuedBy: item.issuedBy,
          typeId: item.typeId,
          kyc: getBool(item, 'kyc', false),
        };
      }),
    };
    return cooperativeVentureProfile;
  };

  onSave() {
    const {updateCooperativeVentureProfile, history, match} = this.props;
    const cooperativeVenture = this.getMapObjectToResponseCooperative(true);
    const errorMess = getObject(cooperativeVenture, 'errors', {});

    if (Object.keys(errorMess).length) {
      message.error(Object.values(cooperativeVenture.errors)[0]);
      return;
    }
    updateCooperativeVentureProfile({id: match.params.id, ...cooperativeVenture})
      .then(() => {
        message.success(strings.update_profile_successful);
        history.push(LIST_COOPERATIVE_VENTURE_PROFILES_PATH);
      })
      .catch((err) => {
        return showError(err);
      });
  }
  onCreate = () => {
    const {createCooperativeVentureProfile, history} = this.props;
    const cooperativeVenture = this.getMapObjectToResponseCooperative(false);
    const errorMess = getObject(cooperativeVenture, 'errors', {});

    if (Object.keys(errorMess).length) {
      message.error(Object.values(cooperativeVenture.errors)[0]);
      return;
    }
    createCooperativeVentureProfile(cooperativeVenture)
      .then(() => {
        message.success(strings.create_profile_successful);
        history.push(LIST_COOPERATIVE_VENTURE_PROFILES_PATH);
      })
      .catch((err) => {
        return showError(err);
      });
  };

  fetchProfile = () => {
    const {match, getCooperativeVentureProfile} = this.props;

    let profileId = +match.params.id;
    // Create profile
    if (_.isNaN(profileId)) {
      this.setState({cooperativeVenture: emptyObject()});
      return;
    }

    // Update profile
    getCooperativeVentureProfile(profileId)
      .then(async ({res}) => {
        let mappedObject = {
          ...res,
          //step 1
          name: getString(res, 'name', undefined),
          surrogate: getString(res, 'surrogate', undefined),

          avatar: getString(res, 'avatar', undefined),
          images: getString(res, 'images', undefined),
          phone: res.phone || undefined,
          email: getString(res, 'email', undefined),
          code: getNumber(res, 'code', undefined),
          member: getNumber(res, 'member'),

          address1: getString(res, 'address.address1', ''),
          provinceId: getNumber(res, 'address.provinceId'),
          districtId: getNumber(res, 'address.districtId'),
          wardsId: getNumber(res, 'address.wardsId'),
          kyc: getBool(res, 'kyc', false),

          revenueYear: getNumber(res, 'revenueYear.value'),
          costYear: getNumber(res, 'costYear.value'),
          vat: getNumber(res, 'vat.value'),
          profitYear: getNumber(res, 'profitYear.value'),
          incomeTax: getNumber(res, 'incomeTax.value'),

          grossArea: getNumber(res, 'grossArea.value', undefined),
          unitId: getNumber(res, 'grossArea.unitId'),
          grossYield: getNumber(res, 'grossYield.value'),
          farmingSeason: getNumber(res, 'farmingSeason.value'),
          formOfUseIds: getArray(res, 'formOfUseIds', []).map((item) => {
            return item;
          }),
          marketIds: getArray(res, 'marketIds', []).map((item) => {
            return item;
          }),

          bankAccounts: getArray(res, 'bankAccounts', []).map((item) => {
            return {
              ...item,
              id: undefined,
            };
          }),

          //step 2
          businessLicenses: getArray(res, 'businessLicenses', []).map((licenses) => {
            return {
              ...licenses,
              address1Licenses: licenses.address.address1,
              provinceIdLicenses: licenses.address.provinceId,
              districtIdLicenses: licenses.address.districtId,
              wardsIdLicenses: licenses.address.wardsId,
            };
          }),
          // step 3
          certifications: getArray(res, 'certifications', []),
        };

        const {getBranches} = this.props;
        const mapMasterDataToBankAccounts = (bankAccounts) => {
          return new Promise((resolve) => {
            let mappedBankAccount = [];
            if ((bankAccounts || []).length === 0) {
              resolve([]);
              return;
            }
            try {
              (bankAccounts || []).forEach(async (item) => {
                let bankId = getNumber(item, 'bankId');
                if (bankId > 0) {
                  try {
                    let response = await getBranches(bankId);
                    let branches = getArray(response, 'res', []).map((branch) => ({
                      value: branch.id,
                      label: branch.name,
                    }));
                    mappedBankAccount.push({
                      ...item,
                      branches: branches,
                    });
                  } catch (error) {
                    mappedBankAccount.push(item);
                  }
                } else {
                  mappedBankAccount.push(item);
                }
                if ((mappedBankAccount || []).length === (bankAccounts || []).length) {
                  resolve(mappedBankAccount);
                }
              });
            } catch (error) {
              console.error('mapMasterDataToBankAccounts -> error', error);
            }
          });
        };

        let bankAccounts = getArray(mappedObject, 'bankAccounts', []);
        let mappedBankAccount = await mapMasterDataToBankAccounts(bankAccounts);
        mappedObject.bankAccounts = mappedBankAccount;

        this.setState({cooperativeVenture: mappedObject});
      })
      .catch((err) => {
        console.error('CooperativeVentureProfile -> err', err);
        return showError(err);
      });
  };

  render() {
    return (
      <StepsFormContainerStyle>
        <div className='row' style={{width: '100%'}}>
          <div className='profile-view'>
            <CooperativeVentureProfileForms
              info={this.state.cooperativeVenture}
              editable={this.state.editable}
              isCreateProfile={this.state.isCreateProfile}
              showHeader={false}
              showFooter={false}
              onCreate={this.onCreate}
            />
          </div>
          <div className='events-view'>
            <ActivityForm editable={this.state.editable} info={this.state.cooperativeVenture} />
          </div>
        </div>
      </StepsFormContainerStyle>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      createCooperativeVentureProfile,
      getCooperativeVentureProfile,
      updateCooperativeVentureProfile,
      deleteCooperativeVentureProfile,
      getBranches,
    },
    null,
    {
      forwardRef: true,
    }
  )(CooperativeVentureProfile)
);
