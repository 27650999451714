import React from 'react'
import styled from 'styled-components'
import strings from '~/localization';
import { compose } from 'recompose';
import PersonalProfileForms from '~/views/container/Profiles/PersonalProfileForms'

const ContainerStyle = styled.div`
  justify-content: center;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  .container {
    max-width: 1220px !important;
    background: #fff;
    border-radius: 5px;
    .copyright{
      display:block;
      right:0;
      width:100%;
      margin-bottom: 20px;
    }
  }
  .title-value{
    font-weight: bold;
    color:rgb(102, 102, 102);
    font-size: 16px
  }
  .thermometer__statistics{
    li{
      font-style:normal;
    }
  }
  @media (min-width: 1200px){ .container { max-width: 1220px; } }
  @media (min-width: 1200px) { .container { max-width: 1140px; } }
  @media (min-width: 992px) { .container { max-width: 960px; } }
  @media (min-width: 768px) { .container { max-width: 720px; } }
  @media (min-width: 576px) { .container { max-width: 540px; } }
`

class SetUpProfile extends React.PureComponent{
  render(){
    return <ContainerStyle>
        <div className="container">
              <PersonalProfileForms />
              <div className='text-center copyright'><span className='font-weight-bold'>{strings.copy_right}</span></div>
        </div>
    </ContainerStyle>
  }
}

export default compose()(SetUpProfile)