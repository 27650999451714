import * as types from "./types";
import produce from "immer";
import {
  addProduct,
  changeProductQuantity,
  removeProduct,
} from "~/views/utilities/helpers/cartActions";
import { mapDataCartServer } from "~/views/utilities/helpers/mapData";
import { LOGOUT } from "../authUser/types";
import _  from 'lodash'

const defaultCart = {
  cartItems: [],
  totalPrice: undefined,
  totalItem: undefined,
  idempotencyKey: undefined,
};
const initState = {
  clientCart: defaultCart,
  productRecentAdded: {},
  notify: false,
  isAskMergeCart: false,
  requestBuyNow: undefined,
  responseBuyNow: undefined,
  searchInCategory: undefined,
  addresses: undefined,
  zipCodeInfo: undefined,
  previousProduct: undefined,
  advanceSearchResult: [],
  criterionSearch: [],
  payingOrder: undefined,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {

    case types.GET_ORDER_DETAIL: {
      
      return draft.clientCart
    }
    case types.LOAD_ADVANCE_SEARCH_RESULT_SUCCESS: {
      draft.advanceSearchResult = payload;
      return;
    }
    case types.UPDATE_PAYING_ORDER_DETAIL: {
      draft.payingOrder = payload;
      draft.requestBuyNow = undefined;
      draft.responseBuyNow = undefined;
      return;
    }
    case types.UPDATE_ADVANCE_SEARCH_RESULT: {
      draft.advanceSearchResult = payload;
      return;
    }
    case types.UPDATE_CRITERION_SEARCH: {
      draft.criterionSearch = payload;
      return;
    }
    case types.GET_ADDRESSES_SUCCESS: {
      draft.addresses = payload;
      return;
    }
    case types.UPDATE_SEARCH_IN_CATEGORY:
      draft.searchInCategory = payload;
      return;
    case types.ADD_BUY_NOW_ITEM:
      draft.requestBuyNow = payload;
      draft.responseBuyNow = undefined;
      return;
    case types.UPDATE_RESPONSE_BUY_NOW:
      draft.responseBuyNow = payload;
      draft.payingOrder = undefined;
      return;
    case types.POST_BUY_NOW_CONFIRM_SUCCESS:
      draft.responseBuyNow = payload;
      return;
    case types.LOAD_CART_SERVER_SUCCESS:
      draft.serverCart = mapDataCartServer(payload || {});
      draft.payingOrder = undefined;
      return;
    case types.CART_MERGE_SUCCESS:
      draft.serverCart = mapDataCartServer(payload || {});
      draft.clientCart = defaultCart;
      return;
    case types.UPDATE_NEW_ITEM_ADD:
      draft.productRecentAdded = payload;
      draft.clientCart = addProduct(draft.clientCart, payload);

      draft.notify = true;

      return;
    case types.CLOSE_NOTIFY:
      draft.notify = false;
      return;
    case types.ADD_TO_CART:
      draft.productRecentAdded = payload;
      draft.clientCart = addProduct(draft.clientCart, payload);
      draft.notify = true;
      return;
    case types.REMOVE_ITEM_CART:
      draft.clientCart = removeProduct(draft.clientCart, payload.product);

      return;
    case types.CHANGE_QUANTITY:

      draft.clientCart = changeProductQuantity(
        draft.clientCart,
        payload.product,
        payload.qty 
      );

      return;
    case types.CLEAR_SERVER_CARD:
      draft.serverCart = defaultCart;
      return;

    case types.ASK_TO_MERGE_CART:
      draft.isAskMergeCart = payload;
      return;
    case LOGOUT:
      draft.addresses = undefined;
      return;
    case types.UPDATE_ZIP_CODE:
      draft.zipCodeInfo = payload;
      return;
    case types.STORE_PREVIOUS_PRODUCT:
      draft.previousProduct = payload;

      return;
    case types.CLEAR_CART:
      draft.clientCart = defaultCart;
      return;
    default:
      return draft;
  }
}, initState);

export default reducer;
