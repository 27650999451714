import { compose, withHandlers, lifecycle, withState } from "recompose";
import { connect } from "react-redux";
import * as yup from "yup";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import strings from "~/localization";

import {
  getType,
  getUnitMaterial,
  getAllFarmCrops,
  getAllSizeClassifications,
} from "~/state/ducks/appApis/actions";
import _ from "lodash";
import {
  stringNRFieldValidate,
  numberValidate,
  numberValidateIntType,
} from "~/views/utilities/validation/input";

import { showError } from "~/configs/ServerErrors";
import {
  getArray,
  getString,
  getNumber,
} from "~/views/utilities/helpers/utilObject";
import {PRODUCT_TYPE} from '~/configs/const';

const validationSchema = yup.object().shape({
  avrSeason: numberValidateIntType(),
  avrYield:  numberValidateIntType(),
  surveyPrice: numberValidate,
  modelGroup:  stringNRFieldValidate(),
  seedDensity:  numberValidateIntType(),
  avrQuantity:  numberValidateIntType(),
  expectedPrice: numberValidate,
  rateOfProfit:  numberValidateIntType(),
  minLinkArea:  numberValidateIntType(),
  maxLinkGroup:  numberValidateIntType(),

  name: stringNRFieldValidate(),
});
export default compose(
  withRouter,
  connect(null, {
    getType,
    getAllFarmCrops,
    getUnitMaterial,
    getAllSizeClassifications,
  }),
  withState("product", "setProduct", []),
  withState("loadingTypeProcess", "setLoadingType", false),
  withState("dataSource", "setDataSource", []),
  withState("sizeClass", "setSizeClass", []),
  withState("percentSizeArr", "setPercentSizeArr", []),
  withState("typeId", "setAllocationPerTree", []),
  withState("expalin", "setExpalin", []),
  withState("unit", "setUnit", []),

  withHandlers({
    fetchPercentSize: (props) => () => {
         
      const { getType, setAllocationPerTree, setPercentSizeArr } = props;
      getType("PERCENT_SIZE")
        .then(({ res }) => {
          props.pakaging.percentSize = getArray(res, undefined, []).map(
            (item) => ({
              value: item.id,
              label: item.name || "",
            })
          );
          setAllocationPerTree(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
          if (getArray(props.pakaging, "classifications", []).length === 0)
            setPercentSizeArr(() => {
              let idTemp = 0;
              let tempArr = [];
              tempArr.push({
                id: idTemp,
                ["typeId" + idTemp]: undefined,
                ["value" + idTemp]: 0,
                ["percentMax" + idTemp]: 100,
                ["dataOptions" + idTemp]: getArray(res, undefined, []).map(
                  (item) => ({
                    value: item.id,
                    label: item.name || "",
                  })
                ),
                ["validateOption" + idTemp]: strings.please_choose_a_category,
                ["validateValue" +
                  idTemp]: strings.total_allocations_should_be_100_percent,
              });

              props.pakaging.classifications = [...tempArr];
              return tempArr;
            });
        })
        .catch((err) => {
          console.error("fetchPercentSize -> err", err);
          showError(err);
        });
    },
    fetchAllProduct: (props) => () => {
      const { getAllFarmCrops, setProduct, setExpalin } = props;
      getAllFarmCrops(PRODUCT_TYPE.PRODUCT)
        .then(({ res }) => {
          setProduct(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );

        })
        .catch((err) => {
          showError(err);
        });
    },
    fetchType: (props) => () => {
      const { getType, setExpalin } = props;
      getType("INFLUENCE")
        .then(({ res }) => {
          setExpalin(
            getArray(res, undefined, []).map((item) => ({
              value: item.name,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => {
          showError(err);
        });
    },
    fetchAllSizeClass: (props) => (id) => {
      const { getAllSizeClassifications, setSizeClass } = props;
      getAllSizeClassifications(id)
        .then(({ res }) => {
          setSizeClass(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: `${item.name} (${item.fromValue}-${item.toValue} ${item.unitName})` || "",
            }))
          );
        })
        .catch((err) => {
          showError(err);
        });
    },
    fecthAllUnit: (props) => () => {
      const { getUnitMaterial, setUnit } = props;
      getUnitMaterial("MASS")
        .then(({ res }) => {
          setUnit(
            getArray(res, undefined, []).map((item) => ({
              value: item.id,
              label: item.name || "",
            }))
          );
        })
        .catch((err) => {
          showError(err);
        });
    },
  }),
  withFormik({
    displayName: "process",
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      return {
        "name":getString(props.pakaging,"name"),
        "avrSeason": getNumber(props.pakaging,"avrSeason"),
        "minLinkArea": getNumber(props.pakaging,"minLinkArea"),
        "maxLinkGroup": getNumber(props.pakaging,"maxLinkGroup"),
        "avrYield": getNumber(props.pakaging,"avrYield"),
        "seedDensity": getNumber(props.pakaging,"seedDensity"),
        "expectedPrice": getNumber(props.pakaging,"expectedPrice"),
        "surveyPrice": getNumber(props.pakaging,"surveyPrice"),
        "modelGroup": getString(props.pakaging,"modelGroup"),
        "sizeClassificationsIds": getArray(props.pakaging,"sizeClassificationsIds"),
        "avrQuantity": getNumber(props.pakaging,"avrQuantity"),
        "rateOfProfit": getNumber(props.pakaging,"rateOfProfit"),
    
        "productId": getNumber(props.pakaging,"productId"),
      };
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        fetchAllProduct,
        fetchPercentSize,
        fetchType,
        fecthAllUnit,
      } = this.props;
      fetchAllProduct();
      fetchPercentSize();
      fetchType();
      fecthAllUnit();
    },
  })
);
