import _ from "lodash";
import { getString, getArray, getNumber } from "./utilObject";

export function mapImageSize(images, size = 70, sizeY = 0) {
  // sizeY !== 70 ? sizeY = sizeY : sizeY = size;

  let imgURL = (images || "").split("|").find(_, idx => idx === 0);
  let indexOfDot = imgURL.lastIndexOf(".");
  let resizeImgURL = "";
  if (indexOfDot >= 0) {
    let imgName = imgURL.substr(0, indexOfDot);
    let imgExtension = imgURL.substr(indexOfDot + 1);
    resizeImgURL = `${imgName}_${size}x${sizeY}.${imgExtension}`;
  }
  return resizeImgURL;
}
export function getDiffPriceAndPriceNew(product) {
  let unitPriceNew = getNumber(product, "unitPriceNew") || 0;
  let unitPrice = getNumber(product, "unitPrice") || 0;
  return Math.abs(unitPriceNew - unitPrice);
}
export function getPriceOfProductCartItem(product) {
  let unitPriceNew = getNumber(product, "unitPriceNew");
  return !!unitPriceNew ? unitPriceNew : getNumber(product, "unitPrice");
}
export function mapDataCartServer(serverCart) {
  var cartItems = getArray(serverCart, "cartItems") || [];
  cartItems = cartItems.map((productsVendor) => {
    return {
      ...productsVendor,
      products: (getArray(productsVendor, "products") || []).map((product) => {
        return {
          ...product,
          vendorId: getNumber(productsVendor, "vendorId") || -1,
          cartItemId: getNumber(product, "id"),
        };
      }),
    };
  });

  let result = {...serverCart, cartItems: cartItems};
  //
  return result;
}