import React from 'react';

import {message} from 'antd';
import _ from 'lodash';
// redux
import {connect} from 'react-redux';
import {FARM_DETAIL_PATH, VIEW_SEASON_CRUD_FARM_PATH} from '~/configs/routesConfig';
// config || common
import {showError} from '~/configs/ServerErrors';
import strings from '~/localization';
import {deleteFarmSeasonExpectedHarvest, getAllProfiles, getFarmSeasonExpectedHarvest, getFarmSeasonCRUD} from '~/state/ducks/appApis/actions';
import {createFarmSeasonExpectedHarvest, updateFarmSeasonExpectedHarvest} from '~/state/ducks/authUser/actions';
import ActivityForm from '~/views/container/ActivityForm';
// component
import FarmSeasonExpectedHarvestForms from '~/views/container/Farm/FarmSeasonExpectedHarvestForms';
import {getArray, getNumber, getObject, getString} from '~/views/utilities/helpers/utilObject';

import {StepsFormContainerStyle} from '~/views/container/styles';
import {withRouterInnerRef} from '~/views/container/commons';

const emptyObject = () => ({
  harvestDate: undefined,
  farmId: undefined,
  id: undefined,
  expectedHarvest: [],

  // commons:
  nameFarm: undefined,
  errors: {},
});

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const scrollToBottom = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: 'smooth',
  });
};

class FarmSeasonExpectedHarvest extends React.Component {
  constructor(props) {
    super(props);
    let editable = true;
    this.state = {
      editable,
      farmHarvest: {},
      farmProfiles: [],
      farmProfile: {},
      createHarvest: false,
    };
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete() {
    const {deleteFarmSeasonExpectedHarvest, match, history} = this.props;
    let id = match.params.idSeason;
    deleteFarmSeasonExpectedHarvest(id)
      .then(() => {
        message.success(strings.delete_profile_success);
        history.push(FARM_DETAIL_PATH.replace(':id', match.params.id));
      })
      .catch((err) => {
        return showError(err);
      });
  }

  componentDidMount() {
    this.fetchFarmSeasonExpectedHarvest();
    this.fetchFarmProfile();
  }

  getMapObjectToResponseFarmSeason = () => {
    const {farmHarvest} = this.state;

    let cropListTemp = getArray(farmHarvest, 'expectedHarvest', []);
    let errorsTemp = getObject(farmHarvest, 'errors', {});
    let errorsValidate = getObject(farmHarvest, 'errorsValidate', {});
    if (cropListTemp.length === 0) {
      message.error(strings.need_at_least_one_crop_for_this_season, 3);
      scrollToBottom();
      return;
    }
    if (Object.keys(errorsValidate).length !== 0) {
      if (!_.isEmpty(errorsValidate)) {
        message.error(Object.values(errorsValidate)[0], 3);
        scrollToTop();
        return;
      }
    }
    if (Object.keys(errorsTemp).length !== 0) {
      if (!_.isEmpty(errorsTemp)) {
        message.error(Object.values(errorsTemp)[0], 3);
        scrollToTop();
        return false;
      }
    }

    if (!_.isEmpty(farmHarvest.errors)) return farmHarvest;

    const {id} = this.props.match.params;
    let farmHarvestInfo = {
      ...farmHarvest,
      //step 1
      farmId: parseInt(id),
      name: getString(farmHarvest, 'name', ''),
      sowingDate: farmHarvest.sowingDate,
      harvestDate: farmHarvest.harvestDate,

      // only product - crops:
      // expectedHarvest.
      productId: getNumber(farmHarvest, 'productId', undefined),
      grossArea: {
        value: getNumber(farmHarvest, 'grossArea', undefined),
        unitName: strings.hectare,
      },
      expectedHarvest: getArray(farmHarvest, 'expectedHarvest', []).filter((item) => {
        if (getNumber(item, 'classifyId', undefined) && getNumber(item, 'unitId', undefined) && getNumber(item, 'productId', undefined)) return true;
        return false;
      }),
    };
    return farmHarvestInfo;
  };

  onSave() {
    const {match, history, updateFarmSeasonExpectedHarvest} = this.props;
    const farmHarvest = this.getMapObjectToResponseFarmSeason();
    if (!farmHarvest) return;
    const errorMess = getObject(farmHarvest.errors, undefined, {});

    if (!_.isEmpty(errorMess)) {
      message.error(Object.values(farmHarvest.errors)[0]);
      return;
    }
    const response = {
      id: +match.params.idSeason,
      ...this.getMapObjectToResponseFarmSeason(),
    };
    updateFarmSeasonExpectedHarvest(response)
      .then(({}) => {
        message.success(strings.expected_inventory_entry_success);
        this.setState({farmHarvest: emptyObject()});
        history.push(VIEW_SEASON_CRUD_FARM_PATH.replace(':idSeason', match.params.idSeason).replace(':id', match.params.id));
      })
      .catch((err) => {
        console.error('FarmSeasonExpectedHarvest -> onEdit -> err', err);
        return showError(err);
      });
  }

  fetchFarmProfile = () => {
    const {match, getAllProfiles} = this.props;
    getAllProfiles('FARM_PROFILE')
      .then(async ({res}) => {
        this.setState({farmProfiles: getArray(res, undefined, [])});
        const index = _.findIndex(getArray(res, undefined, []), (item) => parseInt(item.id) === parseInt(match.params.id));
        if (index !== -1) {
          this.setState({farmProfile: getArray(res[index], undefined, [])});
        }
      })
      .catch((err) => {
        console.error('FarmSeasonExpectedHarvest -> getAllProfiles -> err', err);
        return showError(err);
      });
  };

  fetchFarmSeasonExpectedHarvest = () => {
    const {match, getFarmSeasonExpectedHarvest, getFarmSeasonCRUD} = this.props;
    let idSeason = +match.params.idSeason;
    // Create farm season
    if (_.isNaN(idSeason)) {
      this.setState({farmHarvest: emptyObject()});
      return;
    }

    // Update farm season
    getFarmSeasonExpectedHarvest(idSeason)
      .then(async ({res}) => {
        let mappedObject = {
          ...res,
          farmGrossArea: getNumber(res, 'grossArea.value', undefined),
        };
        // Nếu đã có rồi expectedHarvest rồi thì chính nó ngược lại thì lấy thông tin ban đầu từ farm season
        if (getArray(res, 'expectedHarvest', []).length > 0) this.setState({farmHarvest: {...mappedObject}});
        else {
          getFarmSeasonCRUD(idSeason)
            .then(async ({res}) => {
              let mappedSeasonObject = {
                ...res,
                farmGrossArea: getNumber(res, 'grossArea.value', undefined),
              };
              this.setState({farmHarvest: {...mappedSeasonObject}, createHarvest: true});
            })
            .catch((err) => {
              console.error('fetchFarmSeasonExpectedHarvest -> err', err);
              return showError(err);
            });
        }
      })
      .catch((err) => {
        console.error('FarmSeasonExpectedHarvest -> getFarmSeasonExpectedHarvest -> err', err);
        return showError(err);
      });
  };

  render() {
    return (
      <StepsFormContainerStyle>
        <div className='profile-view'>
          <FarmSeasonExpectedHarvestForms
            editable={this.state.editable}
            farmProfile={this.state.farmProfile}
            farmProfiles={this.state.farmProfiles}
            info={this.state.farmHarvest}
            onCreate={this.onCreate}
            onSave={this.onSave}
            showFooter={false}
            showHeader={false}
          />
        </div>
        <div className='events-view'>
          <ActivityForm editable={this.state.editable} info={this.state.farmHarvest} />
        </div>
      </StepsFormContainerStyle>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      createFarmSeasonExpectedHarvest,
      getFarmSeasonExpectedHarvest,
      updateFarmSeasonExpectedHarvest,
      deleteFarmSeasonExpectedHarvest,
      getAllProfiles,
      getFarmSeasonCRUD,
    },
    null,
    {
      forwardRef: true,
    }
  )(FarmSeasonExpectedHarvest)
);
