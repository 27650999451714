import React from "react";
import QtyChanger from "./QtyChanger";
import enhance from "./withItemCart";
import { Popconfirm } from "antd";
import { parseCurrency } from "~/views/utilities/helpers";
import { UIButton } from "~/views/presentation/ui/buttons";
import { getNumber, getObject, getString, firstImage_godi } from "~/views/utilities/helpers/utilObject";
import { DeleteOutlined } from '@ant-design/icons';
import avatar from "~/static/images/favicon.png";


const ItemCart = (props) => {
    const { name, image, quantity, productId, quantityInStock } = props.data;

    const { changeQuantity, removeItemCart, history } = props;

    let productVariation = getObject(props.data, "productVariation.variations");

    if (!!productVariation) {
    }

    return (
        <div className="row shopping-cart-item px-0">
            <div className="col-md-2 col-4">
                <div className="col-xs-3 m-auto img-thumnail-custom">
                    <a onClick={() => { }}>
                        <img className="img-responsive" src={image ? firstImage_godi(getString(image)) : avatar} />
                    </a>
                </div>
            </div>
            <div className="row col-md-10 col-8 item-info">
                <div className="col-md-6 col-xs-12 col-12 box-info-product d-flex flex-column align-self-center">
                    <a onClick={() => { }}>
                        <p className="name">{name}</p>
                    </a>
                    <p className="price-cart">{`${parseCurrency(getNumber(props, "data.unitPrice"))}/${getString(props, "data.unitName", "kg")}`}</p>
                </div>
                <div className="col-md-3 col-xs-12 col-12  mb-1 d-flex justify-content-end align-items-center">
                    <QtyChanger
                        value={quantity}
                        maximumValue={quantityInStock}
                        getValue={(value) => {
                            changeQuantity(props.data, value);
                        }}
                    />
                    <Popconfirm
                        placement="topRight"
                        title={"Bạn chắc chắn muốn xóa ?"}
                        onConfirm={async () => {
                            removeItemCart(props.data);
                        }}
                        okText="Có"
                        cancelText="Không"
                    >
                        <UIButton className="btn-delete ml-4" width="38px"><DeleteOutlined /></UIButton>
                    </Popconfirm>
                </div>
            </div>
        </div>
    );
};

export default enhance(ItemCart);
