import React from "react";

import { Button, message } from "antd";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { VIEW_SEASON_CRUD_FARM_PATH } from "~/configs/routesConfig";
import { showError } from "~/configs/ServerErrors";
import strings from "~/localization";
import { getAllFarmSeasonByYear } from "~/state/ducks/appApis/actions";
import {
  getArray,
  getNumber,
  getString,
} from "~/views/utilities/helpers/utilObject";

import FarmSeasonTable from "./FarmSeasonTable";
import UtilDate from "~/views/utilities/helpers/UtilDate";

class FarmSeasons extends React.Component {
  handleButtonClick() {
    message.info("Click on left button.");
  }
  handleMenuClick() {
    message.info("Click on menu item.");
  }
  /**
   * Description: Link to farm season detail
   * @param {*} e event
   * @param {*} record contain id
   */
  onClickFarmSeason = (e, record) => {
    // {id: 76001, ...record}
    const { history, match } = this.props;
    history.push(
      VIEW_SEASON_CRUD_FARM_PATH.replace(
        ":idSeason",
        getNumber(record, "id")
      ).replace(":id", match.params.id)
    );
  };
  render() {
    const columns = [
      {
        title: strings.farm_season_name,
        dataIndex: "name",
        align: "left",
        key: "name",
        width: "",
        render: (text, record) => {
          return (
            <Button
              className="m-0 p-0"
              type="link"
              onClick={(e) => this.onClickFarmSeason(e, record)}>
              {text == "" ? strings.no_name : text}
            </Button>
          );
        },
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ["descend", "ascend"],
      },

      {
        title: strings.start_date,
        dataIndex: "sowingDate",
        align: "center",
        width: "",
        key: "sowingDate",
        sorter: (a, b) => a.sowingDate.length - b.sowingDate.length,
        sortDirections: ["descend", "ascend"],
        render: (date) => {
          return moment(date, UtilDate.formatDateTimeServer, true).isValid()
            ? moment
                .utc(date, UtilDate.formatDateTimeServer)
                .format(UtilDate.formatDateLocal)
            : "";
        },
      },
      {
        title: strings.acreage_farming,
        dataIndex: "grossArea",
        align: "right",
        width: "",
        key: "grossArea",
      },
      {
        title: strings.crops_type,
        dataIndex: "productName",
        align: "left",
        width: "",
        key: "productName",
      },
      {
        title: strings.expected_harvest_date,
        dataIndex: "harvestDate",
        align: "center",
        width: "",
        key: "harvestDate",
        render: (date) => {
          return moment(date, UtilDate.formatDateTimeServer, true).isValid()
            ? moment
                .utc(date, UtilDate.formatDateTimeServer)
                .format(UtilDate.formatDateLocal)
            : "";
        },
      },
      {
        title: strings.expected_output,
        dataIndex: "grossYield",
        align: "right",
        width: "",
        key: "grossYield",
      },
      {
        title: strings.harvest_date,
        dataIndex: "actualHarvestDate",
        align: "center",
        width: "",
        key: "actualHarvestDate",
        render: (date) => {
          return moment(date, UtilDate.formatDateTimeServer, true).isValid()
            ? moment
                .utc(date, UtilDate.formatDateTimeServer)
                .format(UtilDate.formatDateLocal)
            : "";
        },
      },
      {
        title: strings.yield,
        dataIndex: "sumGrossYield",
        align: "right",
        width: "",
        key: "sumGrossYield",
      },
    ];

    const { farmingSeason, editable, nameFarm } = this.props;
    return (
      <>
        {farmingSeason.map((item) => {
          return (
            <>
              <div className="farm-season__title d-flex flex-row justify-content-between align-items-baseline ">
                <p className="font-weight-bold">
                  {strings.year} {item.year}
                </p>
              </div>
              <div className="table-page">
                <FarmSeasonTable
                  editable={editable}
                  nameFarm={nameFarm}
                  farmingSeason={item.details}
                  columns={columns}
                />
              </div>
            </>
          );
        })}
      </>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getAllFarmSeasonByYear,
  }),
  withState("farmingSeason", "setFarmingSeason", []),
  withHandlers({
    fetchGetAllFarmingSeasons: (props) => () => {
      const { setFarmingSeason, getAllFarmSeasonByYear, match } = props;
      getAllFarmSeasonByYear(match.params.id)
        .then(({ res }) => {
          setFarmingSeason(
            getArray(res, undefined, []).map((row) => {
              return {
                year: getNumber(row, "year"),
                details: getArray(row, "details", []).map((item) => {
                  const sumGrossYield = getNumber(
                    _.sumBy(getArray(item, "actualHarvest", []), "input"),
                    undefined,
                    0
                  );
                  return {
                    ...item,
                    id: getNumber(item, "id", ""),
                    name: getString(item, "name", ""),
                    sowingDate: item.sowingDate || undefined,
                    grossArea:
                      getNumber(item, "grossArea.value", 0) +
                      " " +
                      getString(item, "grossArea.unitName", strings.hectare),
                    productName: getString(item, "productName", ""),
                    harvestDate: item.harvestDate || undefined,
                    actualHarvestDate: item.actualHarvestDate || undefined,
                    grossYield:
                      getNumber(item, "grossYield.value", 0) +
                      " " +
                      getString(item, "grossYield.unitName", strings.ton),
                    sumGrossYield:
                      sumGrossYield +
                      " " +
                      getString(item, "grossYield.unitName", strings.ton),
                  };
                }),
              };
            })
          );
        })
        .catch((err) => {
          console.error("fetchGetAllFarmingSeasons -> err", err);
          showError(err);
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchGetAllFarmingSeasons } = this.props;
      fetchGetAllFarmingSeasons();
    },
  })
)(React.memo(FarmSeasons));
