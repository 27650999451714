import React from "react";
import {compose, withHandlers, lifecycle, withState} from "recompose";
import {withFormik} from "formik";
import * as yup from "yup";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {Tabs, Form} from "antd";
// redux
import {connect} from "react-redux";
import {
   getWards,
   getDistrict,
   getLiteracies,
   getProfessions,
   getType,
   getMassUnits,
   getProvinces,
} from "~/state/ducks/appApis/actions";
// config || common
import strings from "~/localization";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import {showError} from "~/configs/ServerErrors";
import {getArray, getObject} from "~/views/utilities/helpers/utilObject";
// component
import AvatarUpload from "~/views/presentation/ui/upload/AvatarUpload";
import {UIButton} from "~/views/presentation/ui/buttons";
import {InputField, InputNumberPro, SelectField} from "~/views/presentation/ui/fields";
import BanksForm from "./BanksForm";
import {
   emailNRValidate,
   stringNRFieldValidate,
   numberValidate,
   phoneNRValidate,
} from "~/views/utilities/validation/input";

const {TabPane} = Tabs;

const validationSchema = yup.object().shape({
   phone: phoneNRValidate,
   email: emailNRValidate,
   name: stringNRFieldValidate(),
   surrogate: stringNRFieldValidate(),
   costYear: numberValidate,
   member: numberValidate,
   grossYield: numberValidate,
   farmingSeason: numberValidate,
   grossProductivity: numberValidate,
   unitId: numberValidate,
   grossArea: numberValidate,
   incomeTax: numberValidate,
   profitYear: numberValidate,
   vat: numberValidate,
   revenueYear: numberValidate,
   productivityUnit: numberValidate,
   unitForMass: numberValidate,
   unitForFarmSeason: numberValidate,
   provinceId: numberValidate,
   wardsId: numberValidate,
   districtId: numberValidate,
   address1: stringNRFieldValidate(),
});

class CooperativeVentureInfoForms extends React.PureComponent {
   /**
    *
    * @param {*} prvDataSource - the dataSource will be updated
    */

   updateDataSource = (prvDataSource) => (dataSource) => {
      while (prvDataSource.length) {
         prvDataSource.shift();
      }
      dataSource.forEach((item) => {
         prvDataSource.push(item);
      });
   };

   handleChangeAvatar = (imageName) => {
      const {setFieldValue} = this.props;
      setFieldValue("avatar", imageName);
      this.props.info["avatar"] = imageName;
   };
   handleChangeNumber = (e, name) => {
      const {setFieldValue} = this.props;
      setFieldValue(name, e);
      this.props.info[name] = e;
   };
   handleChangeAddress = (e) => {
      const {handleChange} = this.props;
      handleChange(e);
      let name = e.target.name;
      this.props.info[name] = e.target.value;
      this.props.info.address[name] = e.target.value;
   };
   handleChangeInputValue = (e) => {
      const {handleChange} = this.props;
      handleChange(e);
      let name = e.target.name;
      this.props.info[name] = e.target.value;
   };
   handleChangeSelectValue = (name) => (value) => {
      const {setFieldValue} = this.props;
      setFieldValue(name, value);
      this.props.info[name] = value;
   };
   handleChangeDate = (name) => (date) => {
      const {setFieldValue} = this.props;
      if (!_.isNil(date)) {
         setFieldValue(name, date.format(UtilDate.formatDateTimeServer));
         this.props.info[name] = date.format(UtilDate.formatDateTimeServer);
      } else {
         setFieldValue(name, undefined);
         this.props.info[name] = undefined;
      }
   };

   render() {
      const {
         acreAge,
         values,
         handleBlur,
         touched,
         errors,
         setFieldValue,
         setFieldTouched,
         wardData,
         editable,
         marketIds,
         provinceData,
         districtData,
         setWard,
         setDistrict,
      } = this.props;

      return (
         <div>
            <Form>
               <div className="row d-flex flex-row-reverse">
                  <div className="col-12 col-md-6 d-flex justify-content-center">
                     <AvatarUpload disabled={!editable} avatarUrl={values.avatar} onChange={this.handleChangeAvatar} />
                  </div>
                  <div className="col-12 col-md-6">
                     <InputField
                        validatestatus={touched.name && errors.name ? "error" : undefined}
                        help={touched.name && errors.name ? errors.name : ""}
                        name="name"
                        autoFocus={true}
                        disabled={!editable}
                        value={values.name}
                        onChange={this.handleChangeInputValue}
                        onBlur={handleBlur}
                        placeholder={strings.cooperative_venture_name}
                        label={strings.cooperative_venture_name}
                     />
                     <InputField
                        validatestatus={touched.surrogate && errors.surrogate ? "error" : undefined}
                        help={touched.surrogate && errors.surrogate ? errors.surrogate : ""}
                        name="surrogate"
                        disabled={!editable}
                        value={values.surrogate}
                        onChange={this.handleChangeInputValue}
                        onBlur={handleBlur}
                        placeholder={strings.placeholder_name_representative}
                        label={strings.name_representative}
                     />
                  </div>
               </div>

               <div className="row">
                  <div className="col-12 col-md-6">
                     <InputField
                        validatestatus={touched.phone && errors.phone ? "error" : undefined}
                        help={touched.phone && errors.phone ? errors.phone : ""}
                        name="phone"
                        onInput={(e) => (e.target.value = e.target.value.slice(0, 12))}
                        disabled={!editable}
                        value={values.phone}
                        onChange={this.handleChangeInputValue}
                        onBlur={handleBlur}
                        placeholder={strings.placeholder_phone}
                        label={strings.phone}
                     />
                     <InputField
                        validatestatus={touched.email && errors.email ? "error" : undefined}
                        help={touched.email && errors.email ? errors.email : ""}
                        name="email"
                        disabled={!editable}
                        value={values.email}
                        onChange={this.handleChangeInputValue}
                        onBlur={handleBlur}
                        placeholder={strings.placeholder_email}
                        label={strings.email}
                     />
                     <div className="row">
                        <div className="col-6 pr-2">
                           <InputField
                              validatestatus={touched.address1 && errors.address1 ? "error" : undefined}
                              help={touched.address1 && errors.address1 ? errors.address1 : ""}
                              name="address1"
                              disabled={!editable}
                              value={values.address1}
                              onChange={this.handleChangeAddress}
                              onBlur={handleBlur}
                              placeholder={strings.placeholder_address}
                              label={strings.address}
                           />
                        </div>
                        <div className="col-6 pl-2">
                           <SelectField
                              validateStatus={touched.provinceId && errors.provinceId ? "error" : undefined}
                              disabled={!editable}
                              help={touched.provinceId && errors.provinceId ? errors.provinceId : ""}
                              data={provinceData || []}
                              value={values.provinceId}
                              onChange={(value) => {
                                 setFieldValue("provinceId", value);
                                 setFieldValue("districtId", undefined);
                                 setFieldValue("wardsId", undefined);
                                 setFieldTouched("provinceId");

                                 this.props.info.address["provinceId"] = value;
                                 this.props.info.provinceId = value;
                                 this.props.info.districtId = undefined;
                                 this.props.info.wardsId = undefined;

                                 setDistrict([]);
                                 setWard([]);

                                 this.props.fetchDistrict(value);
                              }}
                              iconEnd="caret-down"
                              placeholder={strings.city_or_province}
                              label={strings.city_or_province}
                           />
                        </div>
                        <div className="col-6 pr-2">
                           <SelectField
                              validateStatus={touched.districtId && errors.districtId ? "error" : undefined}
                              disabled={!editable}
                              help={touched.districtId && errors.districtId ? errors.districtId : ""}
                              data={districtData || []}
                              value={values.districtId}
                              onChange={(value) => {
                                 setFieldValue("districtId", value);
                                 setFieldValue("wardsId", undefined);
                                 setFieldTouched("districtId");

                                 this.props.info.address["districtId"] = value;
                                 this.props.info.districtId = value;
                                 this.props.info.wardsId = undefined;

                                 setWard([]);

                                 this.props.fetchWard(value);
                              }}
                              iconEnd="caret-down"
                              placeholder={strings.district}
                              label={strings.district}
                           />
                        </div>
                        <div className="col-6 pl-2">
                           <SelectField
                              validateStatus={touched.wardsId && errors.wardsId ? "error" : undefined}
                              disabled={!editable}
                              help={touched.wardsId && errors.wardsId ? errors.wardsId : ""}
                              data={wardData || []}
                              value={values.wardsId}
                              onChange={(value) => {
                                 setFieldValue("wardsId", value);
                                 setFieldTouched("wardsId");

                                 this.props.info.wardsId = value;
                                 this.props.info.address["wardsId"] = value;
                              }}
                              iconEnd="caret-down"
                              placeholder={strings.ward}
                              label={strings.ward}
                           />
                        </div>
                     </div>
                     <InputNumberPro
                        min={0}
                        validatestatus={errors.revenueYear ? "error" : undefined}
                        help={errors.revenueYear ? errors.revenueYear : undefined}
                        name="revenueYear"
                        disabled={!editable}
                        suffix={` ${strings.unit_price_d}`}
                        value={values.revenueYear}
                        onChange={(e) => this.handleChangeNumber(e, "revenueYear")}
                        onBlur={handleBlur}
                        inputStyle={{with: "100% !important"}}
                        label={strings.revenue_yeah}
                     />
                     <InputNumberPro
                        min={0}
                        validatestatus={touched.costYear && errors.costYear ? "error" : undefined}
                        help={touched.costYear && errors.costYear ? errors.costYear : undefined}
                        name="costYear"
                        disabled={!editable}
                        suffix={` ${strings.unit_price_d}`}
                        value={values.costYear}
                        onChange={(e) => this.handleChangeNumber(e, "costYear")}
                        onBlur={handleBlur}
                        inputStyle={{with: "100% !important"}}
                        label={strings.cost_yeah}
                     />
                     <InputNumberPro
                        min={0}
                        validatestatus={touched.vat && errors.vat ? "error" : undefined}
                        help={touched.vat && errors.vat ? errors.vat : undefined}
                        name="vat"
                        disabled={!editable}
                        suffix={` ${strings.unit_price_d}`}
                        value={values.vat}
                        onChange={(e) => this.handleChangeNumber(e, "vat")}
                        onBlur={handleBlur}
                        inputStyle={{with: "100% !important"}}
                        label={strings.tax_GTGT_yeah}
                     />
                  </div>
                  <div className="col-12 col-md-6">
                     <InputNumberPro
                        min={0}
                        validatestatus={touched.profitYear && errors.profitYear ? "error" : undefined}
                        help={touched.profitYear && errors.profitYear ? errors.profitYear : undefined}
                        name="profitYear"
                        disabled={!editable}
                        suffix={` ${strings.unit_price_d}`}
                        value={values.profitYear}
                        onChange={(e) => this.handleChangeNumber(e, "profitYear")}
                        onBlur={handleBlur}
                        inputStyle={{with: "100% !important"}}
                        label={strings.annual_profit}
                     />
                     <InputNumberPro
                        validatestatus={touched.incomeTax && errors.incomeTax ? "error" : undefined}
                        help={touched.incomeTax && errors.incomeTax ? errors.incomeTax : undefined}
                        min={0}
                        name="incomeTax"
                        disabled={!editable}
                        suffix={` ${strings.unit_price_d}`}
                        value={values.incomeTax}
                        onChange={(e) => this.handleChangeNumber(e, "incomeTax")}
                        onBlur={handleBlur}
                        inputStyle={{with: "100% !important"}}
                        label={strings.tax_TNDN}
                     />
                     <div className="row">
                        <div className="col-8 pr-2">
                           <InputField
                              disabled={!editable}
                              validatestatus={touched.grossArea && errors.grossArea ? "error" : undefined}
                              help={touched.grossArea && errors.grossArea ? errors.grossArea : ""}
                              name="grossArea"
                              min={0}
                              type="number"
                              value={values.grossArea}
                              onChange={this.handleChangeInputValue}
                              onBlur={handleBlur}
                              placeholder={strings.placeholder_sum_acreage}
                              label={strings.sum_acreage_mana}
                           />
                        </div>
                        <div className="col-4 pl-2">
                           <SelectField
                              validateStatus={touched.unitId && errors.unitId ? "error" : undefined}
                              disabled={!editable}
                              help={touched.unitId && errors.unitId ? errors.unitId : ""}
                              data={acreAge || []}
                              value={values.unitId}
                              onChange={(value) => {
                                 this.props.info.unitId = value;
                                 setFieldValue("unitId", value);
                                 setFieldTouched("unitId");
                              }}
                              iconEnd="caret-down"
                              placeholder={strings.unit}
                              label={strings.unit}
                           />
                        </div>
                     </div>
                     <SelectField
                        validateStatus={touched.formOfUseIds && errors.formOfUseIds ? "error" : undefined}
                        disabled={!editable}
                        help={touched.formOfUseIds && errors.formOfUseIds ? errors.formOfUseIds : ""}
                        mode="multiple"
                        data={this.props.formOfUseIds || []}
                        value={values.formOfUseIds}
                        onChange={(value) => {
                           this.props.info.formOfUseIds = value;
                           setFieldValue("formOfUseIds", value);
                           setFieldTouched("formOfUseIds");
                        }}
                        iconEnd="caret-down"
                        placeholder={strings.placeholder_status_land}
                        label={strings.status_land}
                     />
                     <InputNumberPro
                        min={0}
                        validatestatus={touched.member && errors.member ? "error" : undefined}
                        help={touched.member && errors.member ? errors.member : undefined}
                        name="member"
                        disabled={!editable}
                        suffix={""}
                        value={values.member}
                        onChange={(value) => {
                           this.props.info.member = value;
                           setFieldValue("member", value);
                           setFieldTouched("member");
                        }}
                        onBlur={handleBlur}
                        placeholder={"1"}
                        label={strings.cooperative_sum_member}
                     />
                     <div className="row">
                        <div className="col-6 pr-2">
                           <InputNumberPro
                              min={0}
                              validatestatus={touched.farmingSeason && errors.farmingSeason ? "error" : undefined}
                              help={touched.farmingSeason && errors.farmingSeason ? errors.farmingSeason : undefined}
                              name="farmingSeason"
                              disabled={!editable}
                              suffix={` ${strings.unit_farming_season}`}
                              value={values.farmingSeason}
                              onChange={(value) => {
                                 this.props.info.farmingSeason = value;
                                 setFieldValue("farmingSeason", value);
                                 setFieldTouched("farmingSeason");
                              }}
                              onBlur={handleBlur}
                              placeholder={"1"}
                              label={strings.number_seasons}
                           />
                        </div>
                        <div className="col-6 pl-2">
                           <InputNumberPro
                              min={0}
                              validatestatus={touched.grossYield && errors.grossYield ? "error" : undefined}
                              help={touched.grossYield && errors.grossYield ? errors.grossYield : undefined}
                              name="grossYield"
                              disabled={!editable}
                              suffix={` ${strings.unit_gross_yield}`}
                              value={values.grossYield}
                              onChange={(value) => {
                                 this.props.info.grossYield = value;
                                 setFieldValue("grossYield", value);
                                 setFieldTouched("grossYield");
                              }}
                              onBlur={handleBlur}
                              placeholder={"1"}
                              label={strings.sum_output}
                           />
                        </div>
                     </div>
                     <SelectField
                        validateStatus={touched.marketIds && errors.marketIds ? "error" : undefined}
                        mode="multiple"
                        disabled={!editable}
                        help={touched.marketIds && errors.marketIds ? errors.marketIds : ""}
                        data={marketIds || []}
                        value={values.marketIds}
                        onChange={(value) => {
                           this.props.info.marketIds = value;
                           setFieldValue("marketIds", value);
                           setFieldTouched("marketIds");
                        }}
                        iconEnd="caret-down"
                        placeholder={strings.placeholder_consumption_market}
                        label={strings.consumption_market}
                     />
                  </div>
               </div>
               <div className="mt-4">
                  <Tabs defaultActiveKey="1" type="card" size={"middle"}>
                     <TabPane tab={strings.bank} key="1">
                        <BanksForm
                           editable={editable}
                           onChange={this.updateDataSource(this.props.info.bankAccounts)}
                           bankAccounts={this.props.info.bankAccounts}
                        />
                        <div style={{marginTop: "16px"}}>
                           <UIButton
                              style={{float: "right"}}
                              type="primary"
                              className="ml-2"
                              htmlType="button"
                              disabled={!editable}
                              onClick={() => this.props.nextStep(1)}
                           >
                              <span>{strings.countinue}</span>
                           </UIButton>
                        </div>
                     </TabPane>
                  </Tabs>
               </div>
            </Form>
         </div>
      );
   }
}
export default compose(
   withRouter,
   connect(null, {
      getWards,
      getLiteracies,
      getProfessions,
      getMassUnits,
      getProvinces,
      getDistrict,
      getType,
   }),
   withState("acreAge", "setAcreAge", []),
   withState("unit", "setUnit", []),
   withState("unitMass", "setUnitMass", []),
   withState("unitGrossYield", "setUnitGrossYield", []),
   withState("cultivation", "setCultivation", []),
   withState("landUseStatus", "setLandUseStatus", []),
   withState("formOfUseIds", "setFormOfUseIds", []),
   withState("marketIds", "setMarket", []),
   withState("provinceData", "setProvince", []),
   withState("districtData", "setDistrict", []),
   withState("wardData", "setWard", []),
   withHandlers({
      fetchWard: (props) => (id) => {
         const {getWards, setWard} = props;
         if (id)
            getWards(id)
               .then(({res}) => {
                  setWard(
                     getArray(res, undefined, []).map((item) => {
                        return {
                           value: item.id,
                           label: item.name || "",
                        };
                     })
                  );
               })
               .catch((err) => showError(err));
      },
      fetchDistrict: (props) => (id) => {
         const {getDistrict, setDistrict} = props;
         if (id)
            getDistrict(id)
               .then(({res}) => {
                  setDistrict(
                     getArray(res, undefined, []).map((item) => {
                        return {
                           value: item.id,
                           label: item.name || "",
                        };
                     })
                  );
               })
               .catch((err) => showError(err));
      },
      fetchGetProvince: (props) => () => {
         const {getProvinces, setProvince} = props;
         getProvinces(1)
            .then(({res}) => {
               setProvince(
                  getArray(res, undefined, []).map((item) => {
                     return {
                        value: item.id,
                        label: item.name || "",
                     };
                  })
               );
            })
            .catch((err) => showError(err));
      },
      fetchTypeCultivation: (props) => () => {
         const {getType, setCultivation} = props;
         getType("CULTIVATION")
            .then(({res}) => {
               setCultivation(
                  getArray(res, undefined, []).map((item) => ({
                     value: item.id,
                     label: item.name || "",
                  }))
               );
            })
            .catch((err) => showError(err));
      },
      fetchTypeProfess: (props) => () => {
         const {getType, setLandUseStatus} = props;
         getType("PRODUCT")
            .then(({res}) => {
               setLandUseStatus(
                  getArray(res, undefined, []).map((item) => ({
                     value: item.id,
                     label: item.name || "",
                  }))
               );
            })
            .catch((err) => showError(err));
      },
      fetchMarket: (props) => () => {
         const {getType, setMarket} = props;
         getType("MARKET")
            .then(({res}) => {
               setMarket(
                  getArray(res, undefined, []).map((item) => ({
                     value: item.id,
                     label: item.name || "",
                  }))
               );
            })
            .catch((err) => showError(err));
      },

      fetchAcreage: (props) => () => {
         const {setAcreAge, getMassUnits} = props;
         getMassUnits("ACREAGE")
            .then(({res}) => {
               setAcreAge(
                  getArray(res, undefined, []).map((item) => ({
                     value: item.id,
                     label: item.name || "",
                  }))
               );
            })
            .catch((err) => showError(err));
      },
      fetchUnitMass: (props) => () => {
         const {setUnitMass, getMassUnits} = props;
         getMassUnits()
            .then(({res}) => {
               setUnitMass(
                  getArray(res, undefined, []).map((item) => ({
                     value: item.id,
                     label: item.name || "",
                  }))
               );
            })
            .catch((err) => showError(err));
      },
      fetchUnitGrossYield: (props) => () => {
         const {setUnitGrossYield, getMassUnits} = props;
         getMassUnits("MASS")
            .then(({res}) => {
               setUnitGrossYield(
                  getArray(res, undefined, []).map((item) => ({
                     value: item.id,
                     label: item.name || "",
                  }))
               );
            })
            .catch((err) => showError(err));
      },
      fetchFormOfUseIds: (props) => () => {
         const {getType, setFormOfUseIds} = props;
         getType("FORM_OF_USES")
            .then(({res}) => {
               setFormOfUseIds(
                  getArray(res, undefined, []).map((item) => ({
                     value: item.id,
                     label: item.name || "",
                  }))
               );
            })
            .catch((err) => showError(err));
      },
   }),
   withFormik({
      displayName: "infoCooperative",
      enableReinitialize: true,
      validationSchema,
      mapPropsToValues: (props) => {
         return {
            phone: props.info.phone,
            email: props.info.email,
            name: props.info.name,
            surrogate: props.info.surrogate,
            costYear: props.info.costYear,
            member: props.info.member,
            grossYield: props.info.grossYield,
            farmingSeason: props.info.farmingSeason,
            grossProductivity: props.info.grossProductivity,
            unitId: props.info.unitId,
            grossArea: props.info.grossArea,
            incomeTax: props.info.incomeTax,
            profitYear: props.info.profitYear,
            vat: props.info.vat,
            revenueYear: props.info.revenueYear,
            productivityUnit: props.info.productivityUnit,
            unitForFarmSeason: props.info.unitForFarmSeason,
            cultivation: props.info.cultivation,
            unitForMass: props.info.unitForMass,
            address1: props.info.address1,
            provinceId: props.info.provinceId,
            wardsId: props.info.wardsId,
            districtId: props.info.districtId,
            marketIds: props.info.marketIds,
            formOfUseIds: props.info.formOfUseIds,
            avatar: props.info.avatar,
            productionTypesIds: props.info.productionTypesIds,
         };
      },
      // handleSubmit: async (values, { props, setSubmitting }) => {}
   }),
   lifecycle({
      componentDidMount() {
         const {
            fetchAcreage,
            fetchGetProvince,
            fetchTypeCultivation,
            fetchTypeProfess,
            fetchMarket,
            fetchUnitMass,
            fetchUnitGrossYield,
            fetchFormOfUseIds,
            fetchDistrict,
            fetchWard,
            //
            info,
         } = this.props;
         fetchAcreage();
         fetchTypeCultivation();
         fetchTypeProfess();
         fetchMarket();
         fetchUnitMass();
         fetchUnitGrossYield();
         fetchGetProvince();
         fetchFormOfUseIds();
         // fetch address when load - update profile
         const {districtId, provinceId} = getObject(info, "address");
         if (provinceId) fetchDistrict(provinceId);
         if (districtId) {
            fetchWard(districtId);
         }
      },
      getDefaultUnit(name, nameUnit, nextProps) {
         const {setFieldValue, setFieldTouched} = this.props;
         if (getArray(this.props, name, []).length !== 0 && this.props.info[nameUnit] === undefined) {
            this.props.info[nameUnit] = nextProps[name][0].value;
            setFieldValue(nameUnit, nextProps[name][0].value);
            setFieldTouched(nameUnit);
         }
      },
      UNSAFE_componentWillReceiveProps(nextProps) {
         const {fetchWard, fetchDistrict} = this.props;

         // set default unit
         try {
            this.getDefaultUnit("acreAge", "unitId", nextProps);
            this.getDefaultUnit("unitMass", "unitForMass", nextProps);
            this.getDefaultUnit("unitGrossYield", "unitForFarmSeason", nextProps);
            if (nextProps.info.provinceId !== this.props.info.provinceId)
               if (nextProps.info.provinceId) fetchDistrict(nextProps.info.provinceId);
            if (nextProps.info.districtId !== this.props.info.districtId)
               if (nextProps.info.districtId) {
                  fetchWard(nextProps.info.districtId);
               }
         } catch (err) {
            console.error("UNSAFE_componentWillReceiveProps -> err", err);
            showError(err);
         }
      },
   })
)(CooperativeVentureInfoForms);
