import { useEffect, useState } from "react";

export const CheckWidth = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const value = {
    isMobile: windowSize.innerWidth <= 480,
    isTablet: windowSize.innerWidth <= 768 && windowSize.innerWidth > 480,
    isDesktop: windowSize.innerWidth > 768,
    innerWidth: windowSize.innerWidth,
  };

  return value;
};

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
