import React, { useState } from "react";
import { compose, withState, withHandlers, pure, lifecycle } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import strings from "~/localization";
import { getString, getNumber } from "~/views/utilities/helpers/utilObject";
import styled from "styled-components";
import { UIButton } from "~/views/presentation/ui/buttons";
import { Color } from "~/views/utilities/layout";
import { message, Popconfirm, Tooltip } from "antd";
import Reschedule from "./Reschedule";
import Modal from "antd/lib/modal/Modal";
import InfoItem from "./InfoItem";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import moment from "moment";
import { deleteSeasonProcessStep, updateFinishSeasonProcessStep, rescheduleSeasonProcessStep } from "~/state/ducks/appApis/actions";
import { VIEW_SEASON_CRUD_FARM_PATH, FARM_DETAIL_PATH } from "~/configs/routesConfig";
import { showError } from "~/configs/ServerErrors";
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined, WarningOutlined } from "@ant-design/icons";

const WrapActivityItem = styled.div`
  -webkit-transition: all 0.4s 0s ease;
  -moz-transition: all 0.4s 0s ease;
  -o-transition: all 0.4s 0s ease;
  transition: all 0.4s 0s ease;
  width: 100%;
  padding: 8px;
  // margin: 12px 0px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
  background-color: white;

  .before {
    width: 4px;
    height: auto;
    background-color: ${Color.black};
    margin: 0px 4px 0px 0px;
  }
  .info {
    background-color: ${Color.success};
  }
  .warning {
    background-color: ${Color.warning};
  }
  .error {
    background-color: ${Color.error};
  }
  .ht-title {
    font-size: 1em;
    color: ${Color.green};
    font-weight: 750;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    margin: 0;
  }
  .btn-activity {
    padding: 0 !important;
    margin-right: 4px !important;
    height: auto;
    width: auto;
    min-width: auto !important;
    width: auto !important;
  }
  .season-name {
    width: 100%;
    padding: 0 4px 0 0;
    margin: 0px;
    text-align: right;
    font-size: 12px;
    color: ${Color.secondaryText};
    font-weight: normal;
    text-decoration: none;
    font-style: italic;
    font-variant: normal;
    text-transform: none;
  }
  .season-name:hover{
    cursor: pointer;
    color: ${Color.link}
    text-decoration: underline solid ${Color.divider};
  }
  .btn-action span{
    white-space: pre-wrap;
    text-align: left;
  }
  .disable{
    cursor: no-drop;
    color: ${Color.secondaryText}
  }
  .status{
    width: 32px;
    height: 100%;
    font-size: 20px;
    text-align: center;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .status-container{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
`;

const STATUS = {
  DONE: { key: "DONE", title: strings.DONE_ACTIVITY, color: Color.success, icon: <CheckCircleOutlined /> },
  CANCELED: { key: "CANCELED", title: strings.CANCEL, color: Color.error, icon: <CloseCircleOutlined /> },
  LATE: { key: "LATE", title: strings.LATE, color: Color.warning, icon: <WarningOutlined /> },
  RUNNING: { key: "RUNNING", title: strings.RUNNING, color: Color.info, icon: <SyncOutlined spin /> },
  ACTIVATED: { key: "ACTIVATED", title: "", color: null, icon: null },
};

const ActivityItem = (props) => {
  const { info, history, match, isActivityFarm, isDone = true, isStatus = true } = props;

  const [visible, setVisible] = useState(false);

  const statusUI = getString(info, "statusUI", undefined);

  const handleFinish = (values) => {
    const { info, rescheduleSeasonProcessStep, match } = props;
    const sdR = moment(values.startDate);
    const data = {
      id: info.id,
      name: info.name,
      startDate: sdR,
      startHour: parseInt(sdR.get("hours")),
      endDate: info.endDate,
      description: info.description,
      note: info.note,
      type: info.type,
      nextStepId: info.nextStepId,
      cultivationProcessId: info.cultivationProcessId,
      seasonProcessId: info.seasonProcessId,
    };
    rescheduleSeasonProcessStep(data)
      .then(({}) => {
        message.success(strings.successfully_rescheduled);
        if (match.path === VIEW_SEASON_CRUD_FARM_PATH)
          history.push(VIEW_SEASON_CRUD_FARM_PATH.replace(":idSeason", match.params.idSeason).replace(":id", match.params.id));
        else history.push(FARM_DETAIL_PATH.replace(":id", match.params.id));
      })
      .catch((err) => {
        console.error("rescheduleSeasonProcessStep -> err", err);
        showError(err);
      });
  };

  const handleDestroy = () => {
    const { info, deleteSeasonProcessStep } = props;
    deleteSeasonProcessStep(info.id)
      .then(({}) => {
        message.success(strings.successfully_canceled);
        if (match.path === VIEW_SEASON_CRUD_FARM_PATH)
          history.push(VIEW_SEASON_CRUD_FARM_PATH.replace(":idSeason", match.params.idSeason).replace(":id", match.params.id));
        else history.push(FARM_DETAIL_PATH.replace(":id", match.params.id));
      })
      .catch((err) => {
        console.error("deleteSeasonProcessStep -> err", err);
        showError(err);
      });
  };

  const handleDone = () => {
    const { info, updateFinishSeasonProcessStep } = props;
    updateFinishSeasonProcessStep(info.id, info.farmingSeasonId)
      .then(({}) => {
        message.success(strings.successfully_updated);
        if (match.path === VIEW_SEASON_CRUD_FARM_PATH)
          history.push(VIEW_SEASON_CRUD_FARM_PATH.replace(":idSeason", match.params.idSeason).replace(":id", match.params.id));
        else history.push(FARM_DETAIL_PATH.replace(":id", match.params.id));
      })
      .catch((err) => {
        console.error("updateFinishSeasonProcessStep -> err", err);
        showError(err);
      });
  };

  const mTooltip = (status, type) => {
    switch (status) {
      case STATUS.DONE.key:
        return STATUS.DONE[type];
      case STATUS.CANCELED.key:
        return STATUS.CANCELED[type];
      case STATUS.LATE.key:
        return STATUS.LATE[type];
      case STATUS.RUNNING.key:
        return STATUS.RUNNING[type];
      default:
        return STATUS.ACTIVATED[type];
    }
  };
  const status = getString(info, "status", "");
  return (
    <WrapActivityItem>
      <div className="d-flex flex-row justify-content-start align-items-stretch align-content-center">
        {statusUI !== undefined && <div className={`before ${statusUI}`}></div>}
        <div className="d-flex flex-column justify-content-start align-items-start align-content-center" style={{ minWidth: 195 }}>
          <p className="ht-title">
            {getString(info, "title", "")} {getString(info, "calendar", "")} {getString(info, "hour", "")}
          </p>
          <div className="d-flex flex-row justify-content-start align-items-start align-content-center">
            {status !== "DONE" && status !== "CANCELED" ? (
              <>
                <Popconfirm
                  placement="bottomRight"
                  title={strings.you_have_completed_this_step_in_the_process}
                  onConfirm={handleDone}
                  okText={strings.have}
                  cancelText={strings.not}
                  disabled={!isDone}
                >
                  <UIButton type="link" className={`btn-activity btn-done ${!isDone ? "disable" : ""}`}>
                    {strings.ACCOMPLISHED}
                  </UIButton>
                </Popconfirm>
                <span style={{ marginRight: 4 }}>|</span>
                <UIButton type="link" className="btn-activity btn-reschedule" onClick={() => setVisible(true)}>
                  {strings.RESCHEDULE}
                </UIButton>
                <span style={{ marginRight: 4 }}>|</span>
                <Popconfirm
                  placement="bottomRight"
                  title={strings.are_you_sure_you_want_to_cancel_this_step_in_the_process}
                  onConfirm={handleDestroy}
                  okText={strings.have}
                  cancelText={strings.not}
                >
                  <UIButton type="link" className="btn-activity btn-remove">
                    {strings.CANCELED}
                  </UIButton>
                </Popconfirm>
              </>
            ) : (
              <p className="p-0 m-0 no-activity">{mTooltip(status, "title")}</p>
            )}
          </div>
        </div>
        <div className="d-flex flex-column justify-content-start align-items-start align-content-center ml-3 w-100">
          <p className="m-0">
            {/* <Button
              className='btn-action'
              style={{display: "inline", height: "auto", padding: 0}}
              type='link'
              onClick={() => {
                history.push(
                  VIEW_FARM_SEASON_PROCEDURE_PATH.replace(":idProcedure", info.cultivationProcessId)
                    .replace(":idSeason", match.params.idSeason ? match.params.idSeason : info.farmingSeasonId)
                    .replace(":id", match.params.id)
                );
              }}> */}
            <span>
              {getString(info, "seasonProcessName", strings.no_name)} - {getString(info, "name", strings.no_name)}
            </span>
            {/* </Button> */}- {getString(info, "description", "")}
          </p>
          {isActivityFarm ? (
            <p
              className="season-name"
              onClick={() => {
                history.push(VIEW_SEASON_CRUD_FARM_PATH.replace(":idSeason", info.farmingSeasonId).replace(":id", match.params.id));
              }}
            >
              {getString(info, "farmingSeasonName", "")}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="status-container">
          {isStatus && (
            <Tooltip title={mTooltip(status, "title")} color={mTooltip(status, "color")} placement="top">
              <div className="status" style={{ color: mTooltip(status, "color") }}>
                {mTooltip(status, "icon")}
              </div>
            </Tooltip>
          )}
        </div>
      </div>

      <Modal title={strings.RESCHEDULE} centered visible={visible} footer={null} onCancel={() => setVisible(false)}>
        <InfoItem
          line={false}
          info={[
            { label: strings.procedure_name, content: getString(info, "name", "") },
            {
              label: strings.time,
              content:
                moment.utc(getNumber(info, "startHour") * 3600 * 1000).format("HH:mm") !== "Invalid date"
                  ? moment.utc(getNumber(info, "startHour") * 3600 * 1000).format("HH:mm")
                  : "",
            },
            { label: strings.description, content: getString(info, "description", "") },
            {
              label: strings.start_date,
              content: moment(getString(info, "startDate"), UtilDate.formatDateTimeServer, true).isValid()
                ? moment.utc(getString(info, "startDate"), UtilDate.formatDateTimeServer).format(UtilDate.formatDateLocal)
                : "",
            },
            {
              label: strings.end_date,
              content: moment(getString(info, "endDate"), UtilDate.formatDateTimeServer, true).isValid()
                ? moment.utc(getString(info, "endDate"), UtilDate.formatDateTimeServer).format(UtilDate.formatDateLocal)
                : "",
            },
          ]}
        />
        <div className="row mt-1 mb-1">
          <div className="col-4 font-weight-bold">{strings.RESCHEDULE}:</div>
          <div className="col-8 pl-0">
            <Reschedule
              handleFinish={handleFinish}
              setVisible={setVisible}
              defaultValue={moment.utc(getString(info, "startDate"), UtilDate.formatDateTimeServer).format(UtilDate.formatDateTimeServer)}
              startHour={getNumber(info, "startHour")}
            />
          </div>
        </div>
      </Modal>
    </WrapActivityItem>
  );
};

const initState = {
  data: [],
  value: undefined,
  fetching: false,
};

export default compose(
  withRouter,
  connect(null, {
    deleteSeasonProcessStep,
    updateFinishSeasonProcessStep,
    rescheduleSeasonProcessStep,
  }),
  pure,
  withState("state", "setState", (props) => (!!props.defaultValue ? props.defaultValue : initState)),
  withHandlers({}),
  lifecycle({
    componentDidMount() {},
  })
)(ActivityItem);
