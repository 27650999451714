import React from "react";
import {Modal} from "antd";

class ConfirmModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      profile_type: undefined,
    };
  }

  render() {
    const {children, title, message, okText, cancelText, onOk} = this.props;
    return (
      <>
        {React.cloneElement(children, {
          onClick: () => {
            this.setState({visible: true});
          },
        })}
        <Modal
          title={title}
          visible={this.state.visible}
          okText={okText}
          cancelText={cancelText}
          onOk={() => {
            this.setState({visible: false});
            onOk && onOk();
          }}
          onCancel={() => this.setState({visible: false})}
        >
          <p>{message}</p>
        </Modal>
      </>
    );
  }
}

export default ConfirmModal;
