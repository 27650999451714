import strings from "~/localization";

export const PRODUCT_TYPE={
  PRODUCT:"PRODUCT",
  MATERIAL:"MATERIAL",
  UNCATEGORIZED:"UNCATEGORIZED"
}

export const PROFILE_TYPES = {
  PERSONAL_PROFILE: "PERSONAL_PROFILE",
  FARM_PROFILE: "FARM_PROFILE",
  ENTERPRISE: "ENTERPRISE",
  COOPERATIVE: "COOPERATIVE",
  COOPERATIVE_VENTURE: "COOPERATIVE_VENTURE",
  ORGANIZATION: "ORGANIZATION",
};
export const FARM_TYPES = {
  FARM_PROCEDURE: "FARM_PROCEDURE",
  FARM_SEASON: "FARM_SEASON",
  FARM_PROCESS: "SEASON_PROCESS",
  ACTUAL_HARVEST: "ACTUAL_HARVEST",
  EXPECTED_HARVEST: "EXPECTED_HARVEST",
  PUBLISH_PRODUCT:"PUBLISH_PRODUCT",
  PACKAGING_COST:"PACKAGING_COST",
  PRICE_STRUCTURE:"MANUFACTURING_COST",
  MANUFACTURING_COST:"PRICE_STRUCTURE",

};
export const PROCESS_RATING = {
  EXCELLENT: "EXCELLENT",
  VERY_GOOD: "VERY_GOOD",
  GOOD: "GOOD",
  FAIR: "FAIR",
  POOR: "POOR",
};

export const PROCESS_STATUS = {
  WAITING: "WAITING",
  FINISH: "FINISH",
  PAUSE: "PAUSE",
  RUNNING: "RUNNING",
  CANCELED: "CANCELED",
};

export const PUBLISHSTATUS = {
  CREATING: "CREATING",
  WAITING_FOR_APPROVAL: "WAITING_FOR_APPROVAL",
  DELETED: "DELETED",
  DEACTIVATED: "DEACTIVATED",
  ACTIVATED: "ACTIVATED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  CANCELED: "CANCELED",
  CONNECTED: "CONNECTED",
  EXPIRED: "EXPIRED",
};
export const PAYMENT = {
  CASH:"CASH",
  DEBIT:"DEBIT",
  TRANSFER:"TRANSFER",
};
export const PROCESS_TYPE = {};
export const CATEGORY_VALUE = {
  PRODUCT :"PRODUCT",
  MATERIAL: "MATERIAL"
}
export const CATEGORY_TYPE = [
  {
      value: CATEGORY_VALUE.PRODUCT,
      label: strings.sidebar_ecom_ari
  },
  {
      value: CATEGORY_VALUE.MATERIAL,
      label: strings.sidebar_ecom_goods_ari
  }
]