import * as types from './types';
import apiAction, { defaultAction } from '../utils/createAction';
import parseObjToQuery from '~/views/utilities/helpers/parseObjToQuery'

/**
 * Services
 */

 let services = {
   sys: '/services/sys',
   godi:'/services/godi-services'
 }


/**
 * identity type resource
 */
export const getIdentityTypes = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/types?type=IDENTITY', {}, true)
/**
 * getPerSionNalProfile
 */
export const getPersionnalProfile = () => apiAction('get')(types.COMMON, services.sys + '/api/v1/profiles/filter', {}, true)

/**
 * locations
 */
export const getCountries = () => apiAction('get')(types.COMMON, services.sys + '/api/countries', {}, true)
export const getProvinces = (countryId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/provinces?countryId=' + (countryId > 0 ? countryId: '')  , {}, true)
export const getDistrict = (provinceId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/districts?provinceId=' + provinceId, {}, true)
export const getWards = (district) => apiAction('get')(types.COMMON, services.sys + '/api/v1/wards?districtId=' + district, {}, true)
export const getAllRegions = () => apiAction('get')(types.COMMON, services.sys + '/api/regions', {}, true)
export const getAllProvinOfRegion = (params) => apiAction('get')(types.COMMON, services.sys + '/api/v1/provinces/of-regions?regionIds='+params, {}, true)
export const getAllDistricsOfProvin = (params) => apiAction('get')(types.COMMON, services.sys + '/api/v1/districts/of-provinces?provinceIds='+params, {}, true)
export const getAllWardOf = (params) => apiAction('get')(types.COMMON, services.sys + '/api/v1/wards/of-districts?districtIds='+params, {}, true)


/**
 * addresses
 */
export const getAddressTypes = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/types?type=ADDRESS', {}, true)

/**
 * degree
 */
export const getDegreeTypes = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/types?type=DEGREE', {}, true)

/**
 * specializes
 */
export const getSpecializes = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/types?type=SPECIALIZE', {}, true)

/**
 * ranking
 */
export const getRankings = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/types?type=RANKING', {}, true)

/**
 * income
 */
export const getIncomes = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/types?type=INCOME', {}, true)

/**
 * relation ship
 */
export const getRelationShips = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/types?type=RELATION', {}, true)
/**
 * MATERIAL
 */
export const getTypeMaterials = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/types?type=MATERIAL', {}, true)
/**
 * MATERIAL_SUPPLIER
 */
export const getMaterialSuppliers = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/types?type=MATERIAL_SUPPLIER', {}, true)

/**
 * banks
 */
export const getBanks = () => apiAction('get')(types.COMMON, services.sys + '/api/banks', {}, true)
export const getBranches = (bankId) => apiAction('get')(types.COMMON, services.sys + '/api/bank-branches?bankId=' + bankId, {}, true)

/**
 * unit 
 */
export const getMassUnits = (type="MASS") => apiAction('get')(types.COMMON, services.sys + `/api/units?type=${type}`, {}, true)

/**
 * Type of agriculture
 */
export const getAgricultureTypes = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/cultivation-type', {}, true)

/**
 * Quanlity
 */
export const getQuanlities = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/quality-type', {}, true)

/**
 * Product Types
 */

export const getProductTypes = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/product-type', {}, true)

/**
 * Certification types
 */
/**
 * Get Types
 */
export const getType = (type) => apiAction('get')(types.COMMON, services.sys + `/api/master-data/types?type=${type}`, {}, true)
export const getCertificationTypes = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/types?type=CERTIFICATION', {}, true)

/**
 * Literacy 
 */
export const getLiteracies = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/types?type=LITERACY', {}, true)

/**
 * professions
 */
export const getProfessions = () => apiAction('get')(types.COMMON, services.sys + '/api/master-data/types?type=PROFESSION', {}, true)

/**
 * Profiles
 */
export const getAllProfiles = (type) => apiAction('get')(types.COMMON, services.sys + `/api/v1/profile/all${type ? '?type=' + type : ''}`, {}, true)
// personal
export const getPersonalProfile = (profileId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/sys-accounts/profile/' + profileId, {}, true)
export const deletePersonalProfile = (profileId) => apiAction('delete')(types.COMMON, services.sys + '/api/v1/sys-accounts/profile/' + profileId, {}, true)
// farm
export const getFarmProfile = (farmId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/farms/' + farmId, {}, true)
export const getEnterpriseProfile = (enterID) => apiAction('get')(types.COMMON, services.sys + '/api/v1/enterprises/' + enterID, {}, true)
//
export const deleteFarmProfile = (farmID) => apiAction('delete')(types.COMMON, services.sys + '/api/v1/farms/' + farmID, {}, true)
//
export const deleteEnterpriseProfile = (enter) => apiAction('delete')(types.COMMON, services.sys + '/api/v1/enterprises/' + enter, {}, true)
// cooperation - in tms is cooperative
export const getCooperativeProfile = (cooperationId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/cooperation/' + cooperationId, {}, true)
export const deleteCooperativeProfile = (cooperationId) => apiAction('delete')(types.COMMON, services.sys + '/api/v1/cooperation/' + cooperationId, {}, true)
// cooperation-venture - in tms is cooperative-venture
export const getCooperativeVentureProfile = (cooperationVentureId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/cooperation-ventures/' + cooperationVentureId, {}, true)
export const deleteCooperativeVentureProfile = (cooperationVentureId) => apiAction('delete')(types.COMMON, services.sys + '/api/v1/cooperation-ventures/' + cooperationVentureId, {}, true)
// organization
export const getOrganizationProfile = (organizationId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/organizations/' + organizationId, {}, true)
export const deleteOrganizationProfile = (organizationId) => apiAction('delete')(types.COMMON, services.sys + '/api/v1/organizations/' + organizationId, {}, true)

/**
 * upload files
 */ 
export const getFilesProfile = (profileId, type) => apiAction('get')(types.COMMON, services.sys + `/api/v1/files?profileId=${profileId}&profileType=${type}`, {}, true)
export const deleteFileProfile = (path) => apiAction('delete')(types.COMMON, services.sys + `/api/v1/files/${path}`, {}, true)


/**
 * activity logs
 */
export const getActivityLogs = (params) => apiAction('get')(types.COMMON, services.sys + '/api/v1/logs' + parseObjToQuery(params) , {}, true)



/**
 * FARM MANAGEMENT
 */

export const getExportInventoryOfHarvest = (farmingSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/export/excel?farmingSeasonId=' + farmingSeasonId, {}, true)
 
export const getFarmSeasonExpectedHarvest = (farmSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/harvests/expected/' + farmSeasonId, {}, true)
export const deleteFarmSeasonExpectedHarvest = (expectedHarvestId) => apiAction('delete')(types.COMMON, services.sys + '/api/v1/harvests/' + expectedHarvestId, {}, true)

export const getFarmSeasonHarvest = (farmSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/harvests/actual/' + farmSeasonId, {}, true)
export const deleteFarmSeasonHarvest = (harvestId) => apiAction('delete')(types.COMMON, services.sys + '/api/v1/harvests/' + harvestId, {}, true)

export const getFarmSeasonCRUD = (farmSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/farming-seasons/' + farmSeasonId, {}, true)
export const deleteFarmSeasonCRUD = (farmSeasonId) => apiAction('delete')(types.COMMON, services.sys + '/api/v1/farming-seasons/' + farmSeasonId, {}, true)
export const createCultivationProcess =  (body) => apiAction('post')(types.COMMON, services.sys + '/api/v1/cultivation-processes', body, true)
export const editCultivationProcess =  (body) => apiAction('put')(types.COMMON, services.sys + '/api/v1/cultivation-processes', body, true)
export const getAllProces = (idType="") => apiAction('get')(types.COMMON, services.sys + '/api/v1/cultivation-processes?typeId='+idType, {}, true)

//cultivation-step-resource
export const createCultivationStep = (body) => apiAction('post')(types.COMMON, services.sys + '/api/cultivation-steps', body, true)

/**
 *  farm season
 *  */
// product - farm crops
export const getAllFarmCrops = (type) => apiAction('get')(types.COMMON, services.sys + `/api/products/filter?type=${type}`  , {}, true)
// thửa đất
export const getAllCertificateOfLands = (idFarm) => apiAction('get')(types.COMMON, services.sys + `/api/v1/farms/${idFarm}/certificate-of-lands`  , {}, true)
// farm processes
export const getAllCultivationProcesses = () => apiAction('get')(types.COMMON, services.sys + `/api/v1/cultivation-processes`  , {}, true)
// material
export const getAllMaterialInventories = () => apiAction('get')(types.COMMON, services.sys + `/api/v1/material-inventories`  , {}, true)
export const getMaterialInventories = (idMaterial) => apiAction('get')(types.COMMON, services.sys + `/api/v1/material-inventories/${idMaterial}`  , {}, true)


// unit Material 
export const getUnitMaterial = (type="MATERIAL") => apiAction('get')(types.COMMON, services.sys + `/api/units?type=${type}`, {}, true)

export const getAllFarmSeason = () => apiAction('get')(types.COMMON, services.sys + '/api/v1/farming-seasons'  , {}, true)
export const getAllFarmSeasonByYear = (farmId) => apiAction('get')(types.COMMON, services.sys + `/api/v1/farming-seasons/group-by-year-harvest?farmId=${farmId}`  , {}, true)

//ton kho
export const getExpected = (params) =>{ return   apiAction('get')(types.COMMON, services.sys + `/api/v1/harvests/expected/${params}`  , {}, true)}
export const getActual = (params) =>{ return   apiAction('get')(types.COMMON, services.sys + `/api/v1/harvests/actual/${params}`  , {}, true)}
//create form dang ban
export const createFormPost=(body) =>{ return   apiAction('post')(types.COMMON, services.sys + `/api/v1/publishes`  , body, true)}
export const createManufacturingCost=(body) =>{ return   apiAction('post')(types.COMMON, services.sys + `/api/manufacturing-costs`  , body, true)}
/// service 
/**Viet */
export const getAllPublishServiceLists  = () => apiAction('get')(types.COMMON, services.sys + '/api/v1/publish-service-lists'  , {}, true)
export const getAllPublishCostconfig  = () => apiAction('get')(types.COMMON, services.sys + '/api/publish-cost-configs'  , {}, true)
/** Viet!end--- ♥♥♥ */

// dashboard

export const getProcessActivitiesRecent = (query) => apiAction('get')(types.COMMON, services.sys + `/api/v1/season-processes/id/recent${parseObjToQuery(query)}`  , {}, true)
export const getProcessActivitiesUpcoming = (query) => apiAction('get')(types.COMMON, services.sys + `/api/v1/season-processes/id/upcomming${parseObjToQuery(query)}`  , {}, true)
export const getFarmProcessActivitiesRecent = (query) => apiAction('get')(types.COMMON, services.sys + `/api/v1/season-processes/farm/recent${parseObjToQuery(query)}`  , {}, true)
export const getFarmProcessActivitiesUpcoming = (query) => apiAction('get')(types.COMMON, services.sys + `/api/v1/season-processes/farm/upcomming${parseObjToQuery(query)}`  , {}, true)

export const deleteSeasonProcessStep = (idStep) => apiAction('delete')(types.COMMON, services.sys + `/api/v1/season-processes/steps/${idStep}`  , {}, true)
export const updateFinishSeasonProcessStep = (idStep, farmingSeasonId) => apiAction('put')(types.COMMON, services.sys + `/api/v1/season-processes/done/${idStep}/${farmingSeasonId}`  , {}, true)
export const rescheduleSeasonProcessStep = (body) => apiAction('put')(types.COMMON, services.sys + `/api/v1/season-processes/`, body, true)
export const createPakagingCode  = (body) => apiAction('post')(types.COMMON, services.sys + '/api/v1/packaging-costs/'  , body, true)
export const updatePakagingCode  = (body) => apiAction('put')(types.COMMON, services.sys + '/api/v1/packaging-costs/'  , body, true)
export const getAllPakaingCode  = (farmingSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/packaging-costs?farmingSeasonId='+ farmingSeasonId  , {}, true)
export const getDetailPakaingCost  = (farmingSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/packaging-costs/'+ farmingSeasonId  , {}, true)
export const deletePakaingCost  = (farmingSeasonId) => apiAction('delete')(types.COMMON, services.sys + '/api/v1/packaging-costs/'+ farmingSeasonId  , {}, true)
//

export const getAllProductCost  = (farmingSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/manufacturing-costs?farmingSeasonId='+ farmingSeasonId  , {}, true)
export const getDetailProductCost  = (farmingSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/manufacturing-costs/'+ farmingSeasonId  , {}, true)
export const updateProductCost = (body) => apiAction('put')(types.COMMON, services.sys + '/api/manufacturing-costs'  , body, true)
export const deleteProductCost  = (farmingSeasonId) => apiAction('put')(types.COMMON, services.sys + '/api/manufacturing-costs/delete/'+ farmingSeasonId  , {}, true)


export const createPriceStructures  = (body) => apiAction('post')(types.COMMON, services.sys + `/api/v1/price-structures`, body, true)



export const updatePricesStructures  = (body) => apiAction('put')(types.COMMON, services.sys + `/api/v1/price-structures`, body, true)

// export const updatePricesStructures  = (body) => apiAction('put')(types.COMMON, services.sys + `​/api/v1/price-structures`  , body, true)
// export const getDetailPriceStructures  = (id) => apiAction('get')(types.COMMON, services.sys + '​/api​/v1​/price-structures/'+ id,{}, true)
export const deletePricesStructures  = (id) => apiAction('delete')(types.COMMON, services.sys + '/api/v1/price-structures/'+ id,{}, true)

export const getAllPriceStructures  = (farmingSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/price-structures?farmingSeasonId='+ farmingSeasonId, {}, true)
// export const getDetailPriceStructures  = (id) => apiAction('get')(types.COMMON, services.sys + '/api​/v1​/price-structures/'+ id, {}, true)

export const getDetailPriceStructures  = (farmingSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/price-structures/'+ farmingSeasonId  , {}, true)


export const getAllSizeClassifications  = (farmingSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/size-classifications?productId='+ farmingSeasonId  , {}, true)

// process of farm season applied

export const getAllProcessSeasonApplied = (farmingSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/cultivation-processes?farmingSeasonId='+farmingSeasonId, {}, true)

export const getAllSeasonHistory = (farmingSeasonId) => apiAction('get')(types.COMMON, services.sys + '/api/v1/cultivation-histories?farmingSeasonId='+farmingSeasonId, {}, true)
// purchase
export const getAllCategoryPurChase=(type) => apiAction('get')(types.COMMON, services.sys + '/api/v1/categories/filter?type=MATERIAL', {}, true)
export const getAllSupplier=(type) => apiAction('get')(types.COMMON, services.godi + '/api/v1/manufacturer/filter', {}, true)
export const getAlllProductPurchase = (type) => apiAction('get')(types.COMMON, services.godi + 'api/v1/manufacturer/product/all'+type, {}, true)
export const getSearchMaterialInventories = (params) =>{ return   apiAction('get')(types.COMMON, services.godi + `/api/v1/manufacturer/material/filter?${params &&params }`  , {}, true)}
export const getNotSearchMaterialInventories = (params) =>{ return   apiAction('get')(types.COMMON, services.godi + `/api/v1/manufacturer/material/filter`  , {}, true)}

export const getAllPerformanceFarm = (idFarm, params) => apiAction('get')(types.COMMON, services.sys + `/api/v1/farms/${idFarm}/year-dashboad${parseObjToQuery(params)}`, {}, true)
export const getAllPerformanceSeason = (idSeason, params) => apiAction('get')(types.COMMON, services.sys + `/api/v1/farms/${idSeason}/season-dashboad${parseObjToQuery(params)}`, {}, true)



export const getStrockQuantity = (params,unit) =>{ return   apiAction('get')(types.COMMON, services.sys + `/api/v1/material-inventories/quantity?productId=${params}&unitId=${unit}`  , {}, true)}
