import React from 'react';
import FarmProfileForms from '~/views/container/Profiles/FarmProfileForms';
import {connect} from 'react-redux';

import {VIEW_FARM_PROFILE_PATH, ALL_PROFILES_PATH, EDIT_FARM_PROFILE_PATH} from '~/configs/routesConfig';
import {withRouter} from 'react-router-dom';
import strings from '~/localization';
import {message} from 'antd';
import {showError} from '~/configs/ServerErrors';
import {getFarmProfile, deleteFarmProfile, getBranches} from '~/state/ducks/appApis/actions';
import _ from 'lodash';

import {updateFarmProfile, createFarmProfile} from '~/state/ducks/authUser/actions';

import {getArray, getString, getNumber, getObject} from '~/views/utilities/helpers/utilObject';
import {StepsFormContainerStyle} from '~/views/container/styles';
import ActivityForm from '../../ActivityForm';
import {withRouterInnerRef} from '~/views/container/commons';

const emptyObject = () => ({
  status: undefined,
  errors: {},
  surrogate: undefined,
  email: undefined,
  employee: undefined, // tong so thanh vien
  farmingSeasonNumberId: undefined, // so mua vu
  avatar: undefined,
  images: undefined,
  name: undefined, // ten trang trai
  phone: undefined,
  address: {},
  costYear: undefined, // chi phi hang nam
  grossArea: undefined, // tong dien tich
  grossProductivity: undefined, // tong nang suat
  grossYield: undefined, // tong san luong
  personalIncomeTax: undefined, // thue thu nhap ca nhan
  profitYear: undefined, // loi nhuan
  revenueYear: undefined, // doanh thu
  vat: undefined, // thue GTGT
  productionTypesIds: [],
  marketsIds: [], // thi truong tieu thu
  bankAccounts: [],
  businessTypesIds: [],
  certifications: [],
  certifycateOfLands: [], // cac loai giay chung nhan

  //custom
  provinceId: undefined,
  wardsId: undefined,
  districtId: undefined,
});
class FarmProfile extends React.Component {
  constructor(props) {
    super(props);
    let editable = true;
    let isEdit = false;
    const {match} = this.props;
    switch (match.path) {
      case VIEW_FARM_PROFILE_PATH:
        {
          editable = false;
        }
        break;
      case EDIT_FARM_PROFILE_PATH:
        {
          isEdit = true;
        }
        break;
    }
    // switch(match.path){
    //   case VIEW_PERSONAL_PROFILE_PATH: {editable = false} break
    // }
    this.state = {
      editable,
      farm: {},
      isEdit,
    };
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }
  onDelete() {
    const {deleteFarmProfile, match, history} = this.props;
    let farmProfile = match.params.id;
    deleteFarmProfile(farmProfile)
      .then(() => {
        message.success(strings.delete_profile_success);
        history.push(ALL_PROFILES_PATH);
      })
      .catch((err) => showError(err));
  }
  componentDidMount() {
    this.fetchProfile();
  }
  getMapObjectToReponseFarm = () => {
    let {farm} = this.state;
    if (Object.keys(farm.errors).length) return farm;
    let farmProfile = {
      address: {
        address1: getString(farm, 'address1', ''),
        countryId: getNumber(farm, 'countryId', null),
        districtId: getNumber(farm, 'districtId', null),
        wardsId: getNumber(farm, 'wardsId', null),
        provinceId: getNumber(farm, 'provinceId', null),
      },
      avatar: getString(farm, 'avatar'),
      bankAccounts: getArray(farm, 'bankAccounts', []).map((item) => {
        return {
          accountName: item.accountName,
          accountNumber: item.accountNumber,
          bankId: item.bankId,
          branchId: item.branchId,
        };
      }),

      businessTypesIds: getArray(farm, 'cultivation', []).map((item) => {
        return item;
      }),
      costYear: farm.costYear,
      email: getString(farm, 'email'),
      employee: farm.employee,
      farmingSeasonNumber: farm.farmingSeasonNumberId,
      grossArea: {
        unitId: farm.unitId,
        value: farm.grossArea,
      },
      grossProductivity: {
        interval: 'YEAR',
        unitId: farm.productivityUnit,
        value: farm.grossProductivity,
      },
      grossYield: {
        interval: 'YEAR',
        unitId: farm.unitForMass,
        value: farm.grossYield,
      },
      marketsIds: getArray(farm, 'marketsIds', []).map((item) => {
        return item;
      }),
      // images: "string",
      // marketsIds: [1100, 1101, 1102],

      name: getString(farm, 'name'),
      personalIncomeTax: farm.personalIncomeTax,
      phone: farm.phone || undefined,
      productionTypesIds: getArray(farm, 'productionTypesIds', []).map((item) => {
        return item;
      }),
      profitYear: farm.profitYear,
      revenueYear: farm.revenueYear,
      status: 'ACTIVATED' || undefined,
      surrogate: farm.surrogate || undefined,
      vat: farm.vat,
      certifications: getArray(farm, 'certifications', []).map((item) => {
        if (this.state.isEdit)
          return {
            id: item.id,
            issuedDate: item.issuedDate,
            expirationDate: item.expirationDate,
            images: item.images,
            reassessmentDate: item.dateReEvaluate,
            issuedBy: item.issuedPlace,
            typeId: item.typeId,
            evaluationDate: item.dateEvaluate,
          };
        else
          return {
            issuedDate: item.issuedDate,
            expirationDate: item.expirationDate,
            images: item.images,
            reassessmentDate: item.dateReEvaluate,
            issuedBy: item.issuedPlace,
            typeId: item.typeId,
            evaluationDate: item.dateEvaluate,
          };
      }),
      certifycateOfLands: getArray(farm, 'certifycateOfLands', []).map((item) => {
        if (this.state.isEdit)
          return {
            id: item.id,
            landLotNo: item.idLand,
            address: {
              address1: item.address1,
              provinceId: item.provinceId,
              districtId: item.districtId,
              wardsId: item.wardsId,
            },
            areage: {
              unitId: item.unitId,
              value: item.acreage,
            },
            formOfUsesIds: getArray(item, 'formOfUsesIds', []).map((item) => {
              return item;
            }),
            images: item.images,
            ownerId: item.hisLand,
          };
        else
          return {
            landLotNo: item.idLand,
            address: {
              address1: item.address1,
              provinceId: item.provinceId,
              districtId: item.districtId,
              wardsId: item.wardsId,
            },
            areage: {
              unitId: item.unitId,
              value: item.acreage,
            },
            formOfUsesIds: getArray(item, 'formOfUsesIds', []).map((item) => {
              return item;
            }),
            images: item.images,
            ownerId: item.hisLand,
          };
      }),
    };
    return farmProfile;
  };
  onSave() {
    const {updateFarmProfile, history, match} = this.props;
    const farm = this.getMapObjectToReponseFarm();
    const errorMess = getObject(farm.errors, undefined, {});

    // if (Object.keys(errorMess).length) {
    //   message.error(Object.values(farm.errors)[0]);
    //   return;
    // }
    const reponse = {
      ...this.getMapObjectToReponseFarm(),
      id: +match.params.id,
    };
    updateFarmProfile(reponse)
      .then(() => {
        message.success(strings.update_profile_successful);
        history.push(ALL_PROFILES_PATH);
      })
      .catch((err) => showError(err));
  }
  onCreate = () => {
    const {createFarmProfile, history} = this.props;
    const farm = this.getMapObjectToReponseFarm();
    const errorMess = getObject(farm.errors, undefined, {});

    if (Object.keys(errorMess).length) {
      message.error(Object.values(farm.errors)[0]);
      return;
    }

    createFarmProfile(farm)
      .then(() => {
        message.success(strings.create_profile_successful);
        history.push(ALL_PROFILES_PATH);
      })
      .catch((err) => showError(err));
  };
  fetchProfile = () => {
    const {match, getFarmProfile} = this.props;

    let profileId = +match.params.id;
    /**
     * trường hợp tạo profile
     */
    if (_.isNaN(profileId)) {
      this.setState({farm: emptyObject()});
      return;
    }

    /**
     * trường hợp cập nhật profile
     */
    getFarmProfile(profileId)
      .then(async ({res}) => {
        // map data từ response về format chuẩn để hiển thị trên views
        let mappedObject = {
          ...res,
          email: getString(res, 'email'),
          address1: getString(res, 'address.address1'),
          countryId: getNumber(res, 'address.country.id'),
          provinceId: getNumber(res, 'address.province.id'),
          districtId: getNumber(res, 'address.district.id'),
          wardsId: getNumber(res, 'address.wards.id'),
          costYear1: getNumber(res, 'costYear.value'),
          revenueYear1: getNumber(res, 'revenueYear.value'),
          vat1: getNumber(res, 'vat.value'),
          personalIncomeTax1: getNumber(res, 'personalIncomeTax.value'),
          profitYear1: getNumber(res, 'profitYear.value'),
          grossArea: getNumber(res, 'grossArea.value'),

          unitId: getNumber(res, 'grossArea.unitId'),
          farmingSeasonNumberId: getNumber(res, 'farmingSeasonNumber'),
          grossProductivity: getNumber(res, 'grossProductivity.value'),
          productivityUnit: getNumber(res, 'grossProductivity.unitId'),
          grossYield: getNumber(res, 'grossYield.value'),
          unitForMass: getNumber(res, 'grossYield.unitId'),
          cultivation: getArray(res, 'businessTypes', []).map((item) => {
            return item.id;
          }),
          marketsIds: getArray(res, 'markets', []).map((item) => {
            return item.id;
          }),
          productionTypesIds: getArray(res, 'productionTypes', []).map((item) => {
            return item.id;
          }),
          avatar: res.avatar,
          certifications: getArray(res, 'certifications', []).map((item) => {
            return {
              id: item.id,
              issuedDate: item.issuedDate,
              expirationDate: item.expirationDate,
              images: item.images,
              dateReEvaluate: item.reassessmentDate,
              issuedPlace: item.issuedBy,
              typeId: item.typeId,
              dateEvaluate: item.evaluationDate,
              kyc: item.kyc === null ? false : true,
              typeId: item.type.id,
            };
          }),
          certifycateOfLands: getArray(res, 'certifycateOfLands', []).map((item) => {
            return {
              id: item.id,

              provinceId: getNumber(item, 'address.province.id', undefined),
              districtId: getNumber(item, 'address.district.id', undefined),
              wardsId: getNumber(item, 'address.wards.id', undefined),
              idLand: item.landLotNo,
              address1: item.address.address1,
              unitId: item.areage.unitId,
              acreage: item.areage.value,
              formOfUsesIds: getArray(item, 'formOfUses', []).map((val) => val.id),
              images: item.images,
              hisLand: item.ownerId,
              // formOfUsesIdsText:getArray(item,"formOfUses",[]).map(val=>{
              //   return `${val.name},`
              // }),
            };
          }),
          bankAccounts: getArray(res, 'bankAccounts', []).map((item) => ({
            ...item,
            id: undefined,
            bankId: getNumber(item, 'bank.id'),
            branchId: getNumber(item, 'branch.id'),
          })),
        };
        const {getBranches} = this.props;
        const mapMasterDataToBankAccounts = (bankAccounts) => {
          return new Promise((resolve) => {
            let mappedBankAccount = [];
            if ((bankAccounts || []).length === 0) {
              resolve([]);
              return;
            }
            try {
              (bankAccounts || []).forEach(async (item) => {
                let bankId = getNumber(item, 'bankId');
                if (bankId > 0) {
                  try {
                    let response = await getBranches(bankId);
                    let branches = getArray(response, 'res', []).map((branch) => ({
                      value: branch.id,
                      label: branch.name,
                    }));
                    mappedBankAccount.push({
                      ...item,
                      branches: branches,
                    });
                  } catch (error) {
                    mappedBankAccount.push(item);
                  }
                } else {
                  mappedBankAccount.push(item);
                }
                if ((mappedBankAccount || []).length === (bankAccounts || []).length) {
                  resolve(mappedBankAccount);
                }
              });
            } catch (error) {
              console.error(JSON.stringify(error));
            }
          });
        };

        let bankAccounts = getArray(mappedObject, 'bankAccounts', []);
        let mappedBankAccount = await mapMasterDataToBankAccounts(bankAccounts);
        mappedObject.bankAccounts = mappedBankAccount;

        this.setState({farm: mappedObject});
      })
      .catch((err) => showError(err));
  };

  render() {
    return (
      <StepsFormContainerStyle>
        <div className='row' style={{width: '100%'}}>
          <div className='profile-view'>
            <FarmProfileForms info={this.state.farm} editable={this.state.editable} showHeader={false} showFooter={false} onCreate={this.onCreate} />
          </div>
          <div className='events-view'>
            <ActivityForm editable={this.state.editable} info={this.state.farm} />
          </div>
        </div>
      </StepsFormContainerStyle>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      createFarmProfile,
      getFarmProfile,
      updateFarmProfile,
      deleteFarmProfile,
      getBranches,
    },
    null,
    {
      forwardRef: true,
    }
  )(FarmProfile)
);
