import React, {PureComponent} from "react";
import {Row, Col} from "antd";
import enhance from "./withForm";
import styled from "styled-components";
import strings from "~/localization";
import {LOGIN_PATH} from "~/configs/routesConfig";
import Color from "~/views/utilities/layout/color";

import {FormStyled} from "../styles";

const ThanksStyled = styled.div`
  p {
    color: ${Color.brow};
    font-weight: 500;
    margin-bottom: 20px;
  }
`;
const DownloadStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  div {
    background-color: rgb(159, 197, 248);
    display: flex;
    padding: 0px 10px;
    border-radius: 4px;
    a {
      height: 45px;
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-right: 10px;
      span {
        color: #fff;
        margin-left: 5px;
        font-size: 15px;
        font-weight: bold;
      }
      i {
        color: #fff;
        font-size: 25px;
      }
    }
  }
`;

class RegisterFinishForm extends PureComponent {
  render() {
    const {handleSubmit, history} = this.props;

    return (
      <FormStyled onFinish={handleSubmit}>
        <h3 className="login">{strings.register}</h3>
        <ThanksStyled className="mt-5" dangerouslySetInnerHTML={{__html: strings.completed_registry}}></ThanksStyled>

        <DownloadStyled>
          <div>
            <a>
              <i className="fa fa-apple" aria-hidden="true"></i>
              <span>{strings.download}</span>
            </a>
            <a>
              <i className="fa fa-google" aria-hidden="true"></i>
              <span>{strings.download}</span>
            </a>
          </div>
        </DownloadStyled>
        <Row type="flex" className="mt-3">
          <Col>
            <button
              className="btn btn-link mx-0 px-0"
              type="button"
              onClick={() => {
                history.push(LOGIN_PATH);
              }}
            >
              {strings.wanna_login}
            </button>
          </Col>
        </Row>
      </FormStyled>
    );
  }
}

export default enhance(RegisterFinishForm);
