const COLOR = {
  white: '#fff',
  black: '#000',
  red: '#d0011b',
  gray: '#6c757d', // text color
  blue: '#189eff',
  lightgray: '#dddddd',
  yellow: '#f6821f',
  graytheme: '#f1f1f1', //theme color
  grayhover: '#678db2',
  green: '#5D9C14',
  greenhover: '#8db95b',
  yellowGreen: '#E8C01E',
  brow: '#44413a',
  tableBgHead: '#fafafa',
  tableTextHead: '#6c757d',
  browLight: '#44413ab8',
  blueTitle: '#91d5ff',
  blueDeep: '#096dd9',
  warning: '#fadb14',
  success: '#52c41a',
  error: '#f5222d',
  info: '#1890ff',
  primary: '#388E3C',
  link: '#52c41a',
  lightPrimary: '#C8E6C9',
  accent: '#FF5722',
  primaryText: '#595959',
  secondaryText: '#757575',
  thirdText: '#bfbfbf',
  divider: '#d9d9d9',

  // background:
  bgEven: '#d9f7be',
  bgOdd: '#e6f7ff',

  gray1: "#fafafa",
  gray2: "#f5f5f5",
  gray3: "#f0f0f0",
  gray4: "#d9d9d9",
  gray5: "#bfbfbf",
  gray6: "#8c8c8c",

};
export default COLOR;
