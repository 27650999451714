import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import * as PATH from "~/configs/routesConfig";
import AuthorizedRoute from "./AuthorizedRoute";
import { Login } from "~/views/container/AuthPage";
import SetUpProfile from "~/views/container/SetUpProfile";
const AppRouter = (props) => {
  return (
    <Router>
      <Switch>
        <Route path={PATH.LOGIN_PATH} exact component={() => <Login />} />
        <Route path={PATH.ACCOUNT_RECOVERY_PATH} exact component={() => <Login />} />
        <Route path={PATH.RECOVERY_OTP_PATH} exact component={() => <Login />} />
        <Route path={PATH.REGISTER_OTP_PATH} exact component={() => <Login />} />
        <Route path={PATH.RECOVERY_CHANGE_PASSWORD_PATH} exact component={() => <Login />} />
        <Route path={PATH.REGISTER_PATH} exact component={() => <Login />} />
        <Route path={PATH.REGISTER_COMPLETED_PATH} exact component={() => <Login />} />
        <Route path={PATH.SETUP_PROFILE_PATH} exact component={() => <SetUpProfile />} />
        <AuthorizedRoute path={PATH.DASHBOARD_PATH} />
        <AuthorizedRoute path={PATH.REPORTS_PATH} />
        <AuthorizedRoute path={PATH.CONFIGS_PATH} />
        <AuthorizedRoute path={PATH.APPLICATIONS_PATH} />
        <AuthorizedRoute path={PATH.ALL_PROFILES_PATH} />
        <AuthorizedRoute path={PATH.LIST_PROFILES_PATH} />
        <AuthorizedRoute path={PATH.VIEW_PERSONAL_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.EDIT_PERSONAL_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.CREATE_PERSONAL_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.CREATE_FARM_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.VIEW_FARM_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.VIEW_ENTERPRISE_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.EDIT_FARM_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.EDIT_ENTERPRISE_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.CREATE_ENTERPRISE_PROFILE_PATH} />
        {/* cooperative */}
        <AuthorizedRoute path={PATH.CREATE_COOPERATIVE_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.VIEW_COOPERATIVE_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.EDIT_COOPERATIVE_PROFILE_PATH} />
        {/* cooperative venture */}
        <AuthorizedRoute path={PATH.CREATE_COOPERATIVE_VENTURE_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.VIEW_COOPERATIVE_VENTURE_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.EDIT_COOPERATIVE_VENTURE_PROFILE_PATH} />
        {/* cooperative venture */}
        <AuthorizedRoute path={PATH.CREATE_ORGANIZATION_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.VIEW_ORGANIZATION_PROFILE_PATH} />
        <AuthorizedRoute path={PATH.EDIT_ORGANIZATION_PROFILE_PATH} />
        {/*
        FARM MANAGEMENT
        */}
        <AuthorizedRoute path={PATH.OVERVIEW_LEDGER_FARM_PATH} />
        {/* harvest (inventory) */}
        <AuthorizedRoute path={PATH.ENTER_HARVEST_FARM_PATH} />
        <AuthorizedRoute path={PATH.OVERVIEW_SEASON_HARVEST_FARM_PATH} />
        <AuthorizedRoute path={PATH.EDIT_SEASON_HARVEST_FARM_PATH} />
        <AuthorizedRoute path={PATH.EDIT_SEASON_EXPECTED_HARVEST_FARM_PATH} />
        {/**procedure */}
        <AuthorizedRoute path={PATH.FARM_PROCEDURE_PATH} />
        <AuthorizedRoute path={PATH.VIEW_FARM_PROCEDURE_PATH} />
        <AuthorizedRoute path={PATH.ALL_FARM_PROCEDURE_PATH} />
        <AuthorizedRoute path={PATH.EDIT_FARM_PROCEDURE_PATH} />
        <AuthorizedRoute path={PATH.CREATE_FARM_PROCEDURE_PATH} />

        <AuthorizedRoute path={PATH.FARM_HOME_PATH} />
        <AuthorizedRoute path={PATH.FARM_INPUT_PATH} />
        <AuthorizedRoute path={PATH.FARM_PRODUCT_PATH} />
        {/* procedure for season */}
        <AuthorizedRoute path={PATH.ALL_FARM_SEASON_PROCEDURE_PATH} />
        <AuthorizedRoute path={PATH.EDIT_FARM_SEASON_PROCEDURE_PATH} />
        <AuthorizedRoute path={PATH.VIEW_FARM_SEASON_PROCEDURE_PATH} />
        {/** */}
        <AuthorizedRoute path={PATH.ALL_FARM_PATH} />
        <AuthorizedRoute path={PATH.FARM_DETAIL_PATH} />
        {/* season CRUD */}
        <AuthorizedRoute path={PATH.FARM_SEASON_CRUD_PATH} />
        <AuthorizedRoute path={PATH.CREATE_SEASON_CRUD_FARM_PATH} />
        <AuthorizedRoute path={PATH.VIEW_SEASON_CRUD_FARM_PATH} />
        <AuthorizedRoute path={PATH.EDIT_SEASON_CRUD_FARM_PATH} />
        <AuthorizedRoute path={PATH.CREATE_FARM_FORM_PATH} />
        <AuthorizedRoute path={PATH.VIEW_FARM_FORM_PATH} />
        <AuthorizedRoute path={PATH.EDIT_FARM_FORM_PATH} />
        <AuthorizedRoute path={PATH.VIEW_SEASON_CRUD_FARM_PATH} />
        {/** COMMERCE_HOME_PATH */}
        <AuthorizedRoute path={PATH.COMMERCE_HOME_PATH} />
        {/** viet  */}
        <AuthorizedRoute path={PATH.ALL_FARM_PRODUCT_COST_PATH} />
        <AuthorizedRoute path={PATH.CREATE_FARM_PRODUCT_COST_PATH} />
        <AuthorizedRoute path={PATH.EDIT_FARM_PRODUCT_COST_PATH} />
        <AuthorizedRoute path={PATH.VIEW_FARM_PRODUCT_COST_PATH} />
        <AuthorizedRoute path={PATH.ALL_FARM_SALE_PRICE_PATH} />
        <AuthorizedRoute path={PATH.CREATE_FARM_SALE_PRICE_PATH} />
        <AuthorizedRoute path={PATH.EDIT_FARM_SALE_PRICE_PATH} />
        <AuthorizedRoute path={PATH.VIEW_FARM_SALE_PRICE_PATH} />
        <AuthorizedRoute path={PATH.ALL_FARM_SALE_PRICE_PATH} />
        <AuthorizedRoute path={PATH.ALL_FARM_PACKAGING_COST_PATH} />
        <AuthorizedRoute path={PATH.CREATE_FARM_PACKAGING_COST_PATH} />
        <AuthorizedRoute path={PATH.EDIT_FARM_PACKAGING_COST_PATH} />
        <AuthorizedRoute path={PATH.VIEW_FARM_PACKAGING_COST_PATH} />
        {/** end-viet !----  */}
        <Redirect to={PATH.APP_DEFAULT_PATH} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
