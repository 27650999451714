import React from "react";

import {Card} from "antd";
import _ from "lodash";

// redux
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose, lifecycle, withHandlers} from "recompose";
import {LOGIN_PATH} from "~/configs/routesConfig";
import strings from "~/localization";

// component
import ExpectedHarvestInfoForms from "./ExpectedHarvestInfoForms";

class FarmSeasonCRUDForm extends React.PureComponent {
  componentDidMount() {
    if (!this.props.user) {
      this.props.history.push(LOGIN_PATH);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user) {
      this.props.history.push(LOGIN_PATH);
    }
  }

  render() {
    const {showHeader = true, editable, farmProfile, info, farmProfiles, onCreate, onSave} = this.props;

    return (
      <>
        <Card title={showHeader ? strings.farm_setup : undefined} bordered={false}>
          <ExpectedHarvestInfoForms editable={editable} farmProfile={farmProfile} info={info} profiles={farmProfiles} onCreate={onCreate} onSave={onSave} />
        </Card>
      </>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      user: state["authUser"].user,
    }),
    {}
  ),
  withRouter,
  withHandlers({}),
  lifecycle({
    componentDidUnmount() {},
  })
)(FarmSeasonCRUDForm);
