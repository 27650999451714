import React from "react";

import {} from "~/state/ducks/appApis/actions";
import { updateProfile, createProfile } from "~/state/ducks/authUser/actions";
import { connect } from "react-redux";
import {
  getProvinces,
  getDistrict,
  getBranches,
  deletePersonalProfile,
  getAllPerformanceSeason,
  getAllPerformanceFarm,
} from "~/state/ducks/appApis/actions";
import strings from "~/localization";

import FarmSeasons from "./FarmSeasons";
import { MenuAction } from "~/views/container/components";
import { Alert, TitleCustom } from "~/views/presentation/ui/dashboard";

import { Performance } from "~/views/container/components";

import {
  WrapDashboardContent,
  ContentStyle,
} from "~/views/container/Farm/styles";
import FarmActivities from "./FarmActivities";

import { withRouterInnerRef } from "~/views/container/commons";

const dataSouceNotice = [
  // {
  //   message: 'Thông báo từ hệ thống AgriTech',
  //   type: 'info',
  // },
  // {
  //   message: 'Thông báo từ hệ thống AgriTech',
  //   type: 'warning',
  // },
  // {
  //   message: 'Thông báo từ hệ thống AgriTech',
  //   type: 'success',
  // },
  // {
  //   message: 'Thông báo từ hệ thống AgriTech',
  //   type: 'error',
  // },
];

class FarmOverview extends React.PureComponent {
  renderFarmSeason() {
    const { editable, nameFarm } = this.props;
    return (
      <div className="w-100">
        <div className="farm-season__table col-12 col-lg-8">
          <TitleCustom title={strings.seasons} slot={<MenuAction />} />
          <FarmSeasons editable={editable} nameFarm={nameFarm} />
        </div>
        <div className="col-12 col-lg-4 pl-0">
          <FarmActivities />
        </div>
      </div>
    );
  }

  renderPerformance() {
    const arrPerformance = [
      {
        title: strings.total_production,
        unit: strings.ton,
        subTitle: strings.previous_period,
      },
      {
        title: strings.production_by_season,
        unit: strings.unit_price_d,
        subTitle: strings.season_ago,
      },
      {
        title: strings.medium_quantity,
        unit: "",
        subTitle: strings.season_ago,
      },
      {
        title: strings.cost,
        unit: strings.unit_price_d,
        subTitle: strings.season_ago,
      },
      {
        title: strings.revenue,
        unit: strings.unit_price_d,
        subTitle: strings.season_ago,
      },
      {
        title: strings.profit,
        unit: strings.unit_price_d,
        subTitle: strings.season_ago,
      },
    ];

    const { getAllPerformanceFarm } = this.props;

    return (
      <Performance
        title={strings.farm_performance}
        arrPerformance={arrPerformance}
        fetchData={getAllPerformanceFarm}
      />
    );
  }

  renderNotification() {
    return <Alert dataSouce={dataSouceNotice} />;
  }

  state = {
    collapsed: false,
  };

  render() {
    return (
      <ContentStyle>
        <div className="w-100">
          <WrapDashboardContent>
            {this.renderNotification()}
            {this.renderPerformance()}
            {this.renderFarmSeason()}
          </WrapDashboardContent>
        </div>
      </ContentStyle>
    );
  }
}

export default withRouterInnerRef(
  connect(
    null,
    {
      getDistrict,
      getBranches,
      updateProfile,
      createProfile,
      getProvinces,
      deletePersonalProfile,
      getAllPerformanceFarm,
      getAllPerformanceSeason,
    },
    null,
    { forwardRef: true }
  )(React.memo(FarmOverview))
);
