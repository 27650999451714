import React from 'react'
import { compose, withHandlers, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import moment from 'moment'
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import { InputField, DatePickerField, SelectField } from '~/views/presentation/ui/fields';
import strings from '~/localization';
import UtilDate from '~/views/utilities/helpers/UtilDate';
import AvatarUpload from '~/views/presentation/ui/upload/AvatarUpload';
import DocumentsForm from './DocumentsForm'
import AddressesForm from './AddressesForm'
import CertificatesForm from './CertificatesForm'
import IncomeForm from './IncomeForm'
import BanksForm from './BanksForm'
import FamilyForm from './FamilyForm'
import { Tabs, Form } from 'antd';
import _ from 'lodash'
import {phoneNRValidate, emailNRValidate, stringNRFieldValidate} from '~/views/utilities/validation/input'
import {getLiteracies, getProfessions} from '~/state/ducks/appApis/actions'
import { showError } from '~/configs/ServerErrors';
import { getArray, getString, getNumber } from '~/views/utilities/helpers/utilObject';
const { TabPane } = Tabs;


const validationSchema = yup.object().shape({
  phone: phoneNRValidate,
  email: emailNRValidate,
  name: stringNRFieldValidate(),
  
});

class PersonalInfoForms extends React.PureComponent{

  /**
   * 
   * @param {*} prvDataSource - the dataSource will be updated
   */
  updateDataSource=(prvDataSource)=>(dataSource)=>{
    while(prvDataSource.length){
      prvDataSource.shift()
    }
    dataSource.forEach(item=>{
      prvDataSource.push(item)
    })    
  }

  handleChangeAvatar=(imageName)=>{
    const {setFieldValue} = this.props
    setFieldValue('avatar', imageName)
    this.props.info['avatar'] = imageName
  }

  handleChangeInputValue =(e)=>{
    const {handleChange} = this.props
    handleChange(e)
    let name = e.target.name
    this.props.info[name] = e.target.value
  }

  handleChangeSelectValue = (name) => (value)=>{
    const {setFieldValue} = this.props
    setFieldValue(name, value)
    this.props.info[name] = value
  }

  handleChangeDate= (name) => date=> {
    const {setFieldValue} = this.props
    if(!_.isNil(date)){ 
      setFieldValue(name, date.format(UtilDate.formatDateTimeServer))
      this.props.info[name] = date.format(UtilDate.formatDateTimeServer)
    }
    else {
      setFieldValue(name, undefined)
      this.props.info[name] = undefined
    }
  }

  render(){
    const {
			values,
			handleBlur,
			touched,
			errors,
			literacies,
      professions,
      editable
    } = this.props;

    let birthday = moment.utc(values.birthday, UtilDate.formatDateTimeServer).local()

    return <div >
              <Form className='row d-flex flex-row-reverse' >
                <div className='col-12 col-md-6 d-flex justify-content-center'>
                  <AvatarUpload disabled={!editable} avatarUrl={values.avatar} onChange={this.handleChangeAvatar}/>
                </div>
                <div className='col-12 col-md-6'>
                  <InputField
                      validatestatus={touched.name && errors.name ? 'error' : undefined}
                      help={touched.name && errors.name ? errors.name : ''}
                      name="name"
                      autoFocus
                      disabled={!editable}
                      value={values.name}
                      onChange={this.handleChangeInputValue}
                      onBlur={handleBlur}
                      placeholder={strings.full_name}
                      label={strings.full_name}
                    />
                    <DatePickerField
                          mode='date'
                          name="birthday"
                          disabled={!editable}
                          value={birthday.isValid() ? birthday : undefined}
                          format={UtilDate.formatDateLocal}
                          label={strings.birthday_placeholder}
                          placeholder={UtilDate.formatDateLocal}
                          onChange={this.handleChangeDate('birthday')}
                      />
                </div>
              </Form>
              <div className='row'>
                  <div className='col-12 col-md-6'>
                    <InputField
                      validatestatus={touched.phone && errors.phone ? 'error' : undefined}
                      help={touched.phone && errors.phone ? errors.phone : ''}
                      name="phone"
                      disabled={!editable}
                      value={values.phone}
                      onChange={this.handleChangeInputValue}
                      onBlur={handleBlur}
                      placeholder={strings.phone}
                      label={strings.phone}
                    />
                    <InputField
                      validatestatus={touched.email && errors.email ? 'error' : undefined}
                      help={touched.email && errors.email ? errors.email : ''}
                      name="email"
                      disabled={!editable}
                      value={values.email}
                      onChange={this.handleChangeInputValue}
                      onBlur={handleBlur}
                      placeholder={strings.email}
                      label={strings.email}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <SelectField
                        data={literacies}
                        disabled={!editable}
                        value={values.literacyId}
                        onChange={this.handleChangeSelectValue('literacyId')}
                        iconEnd="caret-down"
                        placeholder={strings.level}
                        label={strings.level}
                    />
                    <SelectField
                        data={professions}
                        disabled={!editable}
                        value={values.professionId}
                        onChange={this.handleChangeSelectValue('professionId')}
                        iconEnd="caret-down"
                        placeholder={strings.career}
                        label={strings.career}
                    />
                  </div>
          </div>
          <div className='mt-4'>
          <Tabs defaultActiveKey="1" type="card" size={'middle'}>
            <TabPane tab={strings.identification_documents} key="1">
              <DocumentsForm editable={editable} onChange={this.updateDataSource(this.props.info.identityPapers)} identityPapers={this.props.info.identityPapers || []}/>
            </TabPane>
            <TabPane tab={strings.address} key="2">
              <AddressesForm editable={editable} onChange={this.updateDataSource(this.props.info.addresses)} addresses={this.props.info.addresses || []}/>
            </TabPane>
            <TabPane tab={strings.degree} key="3">
              <CertificatesForm editable={editable} onChange={this.updateDataSource(this.props.info.degrees)} degrees={this.props.info.degrees}/>
            </TabPane>
            <TabPane tab={strings.income}  key="4">
              <IncomeForm editable={editable} onChange={this.updateDataSource(this.props.info.incomes)} incomes={this.props.info.incomes}/>
            </TabPane>
            <TabPane tab={strings.family} key="5">
              <FamilyForm editable={editable} onChange={this.updateDataSource(this.props.info.familyRegisters)} familyRegisters={this.props.info.familyRegisters}/>
            </TabPane>
            <TabPane tab={strings.bank} key="6">
              <BanksForm editable={editable} onChange={this.updateDataSource(this.props.info.bankAccounts)} bankAccounts={this.props.info.bankAccounts}/>
            </TabPane>
           
          </Tabs>
          </div>
    </div>
  }
}
export default compose(
	withRouter,
	connect(
		null,
		{
      getLiteracies,
      getProfessions
    }
  ),
  withState('literacies','setLiteracies',[]),
  withState('professions','setProfessions',[]),
  withHandlers({
  
    fetchLiteracies: props =>()=>{
      const {getLiteracies, setLiteracies} = props
      getLiteracies()
      .then(({res})=>{
        setLiteracies(getArray(res, undefined, []).map(item=>({
          value: item.id,
          label: item.name || ''
        })))
      })
      .catch(err=> showError(err))
    },
    fetchProfessions: props =>()=>{
      const {getProfessions, setProfessions} = props
      getProfessions()
      .then(({res})=>{
        setProfessions(getArray(res, undefined, []).map(item=>({
          value: item.id,
          label: item.name || ''
        })))
      })
      .catch(err=> showError(err))
    }
  }),
	withFormik({
    displayName: 'personalInfoForm',
    enableReinitialize: true,
    validationSchema: validationSchema,
    mapPropsToValues: props=>{
       return {
         name: getString(props,'info.name'),
         birthday: getString(props,'info.birthday'),
         phone: getString(props,'info.phone'),
         avatar: getString(props,'info.avatar'),
         email: getString(props,'info.email'),
         literacyId: getNumber(props,'info.literacyId'),
         professionId: getNumber(props,'info.professionId'),
       }
    },
		// handleSubmit: async (values, { props, setSubmitting }) => {}
  }),
  lifecycle({
    componentDidMount(){
      const {fetchLiteracies, fetchProfessions} = this.props
      fetchLiteracies()
      fetchProfessions()
    }
  })
)(PersonalInfoForms)
