import * as ACTION from "./type";

const initialState = {
  procedure: [],
};

export default function procedure(state = initialState, action) {

  switch (action.type) {
    case ACTION.CREATE_TYPE_PROCEDURE:
      return { ...state, procedure: action.data };
    case ACTION.GET_TYPE_PROCEDURE:
      return state
    default:
      return state;
  }
}