import React from "react";
import * as PATH from "~/configs/routesConfig";
import Applications from "~/views/container/Applications";
import Dashboard from "~/views/container/Dashboard";
import Reports from "~/views/container/Reports";
import Configs from "~/views/container/Configs";
import ProfileManager from "~/views/container/Profiles/index";
import CommerceManager from "~/views/container/Commerce";
import FarmManager from "~/views/container/Farm";

export default [
  {
    path: PATH.DASHBOARD_PATH,
    component: () => <Dashboard />,
    exact: true,
  },
  {
    path: PATH.REPORTS_PATH,
    component: () => <Reports />,
    exact: true,
  },
  {
    path: PATH.CONFIGS_PATH,
    component: () => <Configs />,
    exact: true,
  },
  {
    path: PATH.APPLICATIONS_PATH,
    component: () => <Applications />,
    exact: true,
  },

  /**
   * Farm
   */
  //
  {
    path: PATH.VIEW_FARM_PACKAGING_COST_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.ALL_FARM_PACKAGING_COST_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.CREATE_FARM_PACKAGING_COST_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_FARM_PACKAGING_COST_PATH,
    component: () => <FarmManager />,
    exact: true,
  },

  {
    path: PATH.ALL_FARM_PRODUCT_COST_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_SEASON_HARVEST_FARM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.OVERVIEW_SEASON_HARVEST_FARM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.ENTER_HARVEST_FARM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.OVERVIEW_LEDGER_FARM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_SEASON_EXPECTED_HARVEST_FARM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  /**viet */
  {
    path: PATH.CREATE_FARM_PRODUCT_COST_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_FARM_PRODUCT_COST_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.VIEW_FARM_PRODUCT_COST_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.ALL_FARM_SALE_PRICE_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.CREATE_FARM_SALE_PRICE_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_FARM_SALE_PRICE_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.VIEW_FARM_SALE_PRICE_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  /**viet!end--- */
  {
    path: PATH.CREATE_FARM_FORM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.VIEW_FARM_FORM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_FARM_FORM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_FARM_PROCEDURE_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.FARM_HOME_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.CREATE_FARM_PROCEDURE_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.VIEW_FARM_PROCEDURE_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.ALL_FARM_PROCEDURE_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.VIEW_FARM_SEASON_PROCEDURE_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_FARM_SEASON_PROCEDURE_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.ALL_FARM_SEASON_PROCEDURE_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.ALL_FARM_FORM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.FARM_INPUT_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.FARM_PROCEDURE_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.FARM_PRODUCT_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.ALL_FARM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.FARM_DETAIL_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  // SEASON CRUD
  {
    path: PATH.FARM_SEASON_CRUD_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_SEASON_CRUD_FARM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.CREATE_SEASON_CRUD_FARM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.VIEW_SEASON_CRUD_FARM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },
  {
    path: PATH.VIEW_SEASON_CRUD_FARM_PATH,
    component: () => <FarmManager />,
    exact: true,
  },

  /**
   * Profiles
   */
  {
    path: PATH.ALL_PROFILES_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.LIST_PROFILES_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.CREATE_PERSONAL_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },

  {
    path: PATH.VIEW_PERSONAL_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_PERSONAL_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  //create ENTERPRISE
  {
    path: PATH.CREATE_ENTERPRISE_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.VIEW_ENTERPRISE_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_ENTERPRISE_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  ///Create Farm
  {
    path: PATH.CREATE_FARM_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.VIEW_FARM_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_FARM_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  // Cooperative profile
  {
    path: PATH.CREATE_COOPERATIVE_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.VIEW_COOPERATIVE_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_COOPERATIVE_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  // Cooperative-venture profile
  {
    path: PATH.CREATE_COOPERATIVE_VENTURE_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.VIEW_COOPERATIVE_VENTURE_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_COOPERATIVE_VENTURE_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  // Organization profile
  {
    path: PATH.CREATE_ORGANIZATION_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.VIEW_ORGANIZATION_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },
  {
    path: PATH.EDIT_ORGANIZATION_PROFILE_PATH,
    component: () => <ProfileManager />,
    exact: true,
  },

  //commerge
  {
    path: PATH.COMMERCE_HOME_PATH,
    component: () => <CommerceManager />,
    exact: true,
  },
];
