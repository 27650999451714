import React from 'react';

import {
  Button,
  Card,
  Divider,
  message,
  Steps,
} from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  compose,
  lifecycle,
  withHandlers,
  withState,
} from 'recompose';
import {
  APP_DEFAULT_PATH,
  LOGIN_PATH,
} from '~/configs/routesConfig';
import { showError } from '~/configs/ServerErrors';
import strings from '~/localization';
import {
  createProfile,
  skipFirstSetUpProfile,
} from '~/state/ducks/authUser/actions';
import { UIButton } from '~/views/presentation/ui/buttons';
import { removeEmptyArrayItem } from '~/views/utilities/helpers/utilObject';

import CompletedSetUpForm from './CompletedSetUpForm';
import FarmingExperienceForm from './FarmingExperienceForm';
import LicenceForm from './LicenceForm';
import PersonalInfoForms from './PersonalInfoForm';

const { Step } = Steps;


const emptyObject = () => ({
  "name": undefined,
  "shortName": undefined,
  "birthday": undefined,
  "phone": undefined,
  "email": undefined,
  "profession": undefined,
  "gender": undefined,
  "avatar": undefined,
  "logo": undefined,
  "website": undefined,
  "description": undefined,
  "professionId": undefined,
  "literacyId": undefined,
  "addresses": [],
  "familyRegisters": [],
  "identityPapers": [],
  "degrees": [],
  "farmingExperiences": [],
  "incomes": [],
  "certifications": [],
  "bankAccounts": []
})
class SetUpProfile extends React.Component{
  state = {
    current: 0,
  }
  onChange = current => {
    if(this.state.current < 3 && current < 3){
      this.setState({ current });
    }   
  }
  renderStepContent=()=>{
    const {personalInfo, editable = true} = this.props
    switch(this.state.current){
      case 0: return <PersonalInfoForms editable={editable} info={personalInfo}/>
      case 1: return <FarmingExperienceForm  editable={editable} info={personalInfo}/>
      case 2: return <LicenceForm  editable={editable} info={personalInfo}/>
      case 3: return <CompletedSetUpForm />
    }
  }


  componentDidMount(){
    if(!this.props.user){ this.props.history.push(LOGIN_PATH)}
  }

  componentWillUnmount(){
    const {setPersonalInfo} = this.props
    setPersonalInfo(emptyObject())
  }

  UNSAFE_componentWillReceiveProps(nextProps){
     if(!nextProps.user){ this.props.history.push(LOGIN_PATH) }
     const {setPersonalInfo} = this.props
     
      if(this.props.info !== nextProps.info){
        setPersonalInfo(nextProps.info)
      }
  }

  handleNext=()=>{
    if(this.state.current === 3){
      this.props.handleCreateProfile(this.props)
    }
    else if(this.state.current <= 2){
      this.setState({current: this.state.current + 1})
    }
  }

  render(){
    const { current } = this.state;
    const {handleSkip, showHeader = true, showFooter = true} = this.props
    return <div style={{paddingBottom: '20px'}}>
              <Card title={ showHeader ? strings.setup_profile: undefined} bordered={false} >
                <Steps current={current}  onChange={this.onChange} >
                  <Step title={strings.personal_info}  />
                  <Step title={strings.farming_experience}  />
                  <Step title={strings.certificate}  />
                  {showFooter && <Step title={strings.completed}  />}
                  
                </Steps>
                <Divider className='mb-5'/>
                {this.renderStepContent()}
              </Card>
              { showFooter && (
                <div className='d-flex justify-content-end mb-5' style={{paddingRight: '24px'}}>
                    {this.state.current !== 3 && <Button type='dashed' onClick={handleSkip}>{strings.skip_setup}</Button>}
                    <UIButton
                      type="primary"
                      className='ml-2'
                      onClick={this.handleNext}
                      htmlType="button">
                      <span>{ this.state.current === 3?  strings.completed: strings.continue}</span>
                  </UIButton>      
                </div>
              )}
          </div>
  }
}

export default compose(
  connect((state)=>({
    user: state['authUser'].user
  }), {
    createProfile,
    skipFirstSetUpProfile
  }),
  withRouter,
  withState('personalInfo','setPersonalInfo', emptyObject()),
  withHandlers({
    handleCreateProfile: () => (props)=>{
      const {createProfile, personalInfo, history, setPersonalInfo} = props
      createProfile(removeEmptyArrayItem(personalInfo))
      .then(()=>{
        message.success(strings.update_profile_successful)
        setPersonalInfo(emptyObject())
        history.push(APP_DEFAULT_PATH)
      })
      .catch(err=>{
        showError(err)
      })
    },
    handleSkip: props =>()=>{
      const {skipFirstSetUpProfile, history} = props
      skipFirstSetUpProfile()
      .then(()=>{
        history.push(APP_DEFAULT_PATH)
      })
      .catch(err=> showError(err))
    }
  }),
  lifecycle({
    componentDidMount(){
      const {setPersonalInfo} = this.props
      setPersonalInfo(emptyObject)
    }
  })
)(SetUpProfile)