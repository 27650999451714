import React from "react";
import { List, Button, Skeleton } from "antd";
import { showError } from "~/configs/ServerErrors";
import _ from "lodash";
import moment from "moment";
import { getArray } from "~/views/utilities/helpers/utilObject";
import { ActivityItem } from ".";
import styled from "styled-components";
import strings from "~/localization";
import { DoubleRightOutlined } from "@ant-design/icons";

const WrapList = styled(List)`
   .ant-list-item {
      padding: 8px 0px;
      margin-right: 8px;
   }
   margin: 8px 0px;

   .ant-list-empty-text {
      display: ${(props) => (props.none ? "none" : "block")};
   }
`;

const defaultPageSize = 5;

export default class LoadMoreList extends React.Component {
   state = {
      initLoading: true,
      loading: false,
      data: [],
      list: [],
      pageSize: defaultPageSize,
      pageNo: 0,
      isMaxLength: false,
   };

   componentDidMount() {
      this.getData((res) => {
         this.setState({
            initLoading: false,
            data: res,
            list: res,
            pageNo: getArray(res, undefined, []).length !== 0 ? this.state.pageNo + 1 : this.state.pageNo,
            isMaxLength: getArray(res, undefined, []).length !== 0 && getArray(res, undefined, []).length === this.state.pageSize ? false : true,
         });
      });
   }

   getData = (callback) => {
      const { pageSize, pageNo } = this.state;
      const { fetch, id, isToday, isTomorrow } = this.props;
      const query = {
         Id: id,
         pageSize,
         pageNo,
      };
       
      fetch(query)
         .then(({ res }) => {
             
            const resTemp = getArray(res, undefined, []).map((v) => {
               const startTime = { hour: 0, minute: 0, second: 0, milliseconds: 0 };
               const time = v.startHour ? moment(v.startHour.toString(), "HH").format("HH:mm") : "";
               const sd = moment(v.startDate).set(startTime);
               const ed = moment(v.endDate).set({ hour: 23, minute: 59, second: 0, milliseconds: 0 });
               return {
                  ...v,
                  hour: time,
                  calendar: sd.set(startTime).calendar(),
                  sort: sd,
                  isYesterday: false,
               };
            });
            let result = [];
            if (isTomorrow) {
               result = _.sortBy(resTemp, ["sort", "startHour", "hour"]);
               callback(result);
               return;
            }
            if (isToday) {
               const ar = getArray(res, undefined, []);
               const cr = moment();
               let yesterday = [];
               let today = [];
               for (const v of ar) {
                  const startTime = { hour: 0, minute: 0, second: 0, milliseconds: 0 };
                  const time = v.startHour ? moment(v.startHour.toString(), "HH").format("HH:mm") : "";
                  const sd = moment(v.startDate).set(startTime);
                  const ed = moment(v.endDate).set({ hour: 23, minute: 59, second: 0, milliseconds: 0 });
                  if (cr.isAfter(ed)) {
                     yesterday.push({
                        ...v,
                        hour: time,
                        calendar: ed.set(startTime).calendar(),
                        sort: ed,
                        isYesterday: true,
                     });
                  }
                  if (cr.isBetween(sd, ed, undefined, "[]")) {
                     today.push({
                        ...v,
                        hour: time,
                        calendar: sd.set(startTime).calendar(),
                        sort: sd,
                        isYesterday: false,
                     });
                  }
               }
               today = today.concat(yesterday);
               if (isToday) result = _.sortBy(today, ["sort", "startHour", "hour"]);
               callback(result);
               return;
            }
         })
         .catch((err) => {
            console.error("getData -> err", err);
            showError(err);
         });
   };

   onLoadMore = () => {
      this.setState({
         loading: true,
         list: this.state.data.concat([...new Array(this.state.pageSize)].map(() => ({ loading: true }))),
      });
      this.getData((res) => {
         const data = this.state.data.concat(res);
         this.setState(
            {
               data,
               list: data,
               loading: false,
               pageNo: getArray(res, undefined, []).length !== 0 ? this.state.pageNo + 1 : this.state.pageNo,
               isMaxLength: getArray(res, undefined, []).length !== 0 && getArray(res, undefined, []).length === this.state.pageSize ? false : true,
            },
            () => window.dispatchEvent(new Event("resize"))
         );
      });
   };

   mRenderItem(v, i) {
      const { isToday, isTomorrow, isActivityFarm } = this.props;
      if (isToday)
         return (
            <ActivityItem
               key={i}
               info={{ ...v, status: v.status === "ACTIVATED" ? "RUNNING" : v.status, statusUI: "info" }}
               isActivityFarm={isActivityFarm}
            />
         );
      if (v.isYesterday) return <ActivityItem key={i} info={{ ...v, status: "LATE", statusUI: "error" }} isActivityFarm={isActivityFarm} />;
      if (isTomorrow) return <ActivityItem isDone={false} key={i} info={{ ...v, statusUI: "warning" }} isActivityFarm={isActivityFarm} />;
   }

   render() {
      const { initLoading, loading, list, pageSize, isMaxLength } = this.state;
      const { isYesterday } = this.props;
      const loadMore =
         !initLoading && !loading && list.length > 0 && !isMaxLength ? (
            <div
               style={{
                  textAlign: "right",
                  marginTop: 12,
                  marginRight: 8,
                  height: 32,
                  lineHeight: "32px",
               }}
            >
               <Button icon={<DoubleRightOutlined />} onClick={this.onLoadMore}>
                  {strings.load_more}
               </Button>
            </div>
         ) : null;

      return (
         <WrapList
            none={isYesterday && list.length === 0 ? true : false}
            loading={initLoading}
            itemLayout="horizontal"
            loadMore={list.length % pageSize === 0 ? loadMore : undefined}
            dataSource={list}
            renderItem={(v, i) => (
               <List.Item>
                  <Skeleton title={false} loading={v.loading} active>
                     {this.mRenderItem(v, i)}
                  </Skeleton>
               </List.Item>
            )}
         />
      );
   }
}
